import {makeAutoObservable} from 'mobx';
import { TripComparisonRouteSequenceItem } from '../../../../../Model/TripComparison/TripComparisonRouteSequenceItem';
import {Waypoint} from '../../../../../Model/Waypoint/Waypoint';
import { TimeMatrixSource } from "../TimeMatrixSource/TimeMatrixSource";

export class RouteInfo {
    public id: number | undefined;
    public name: string;
    public isRouteSet: boolean;
    public isManual: boolean;
    public withLoadingStore: boolean;
    public withDriverPlace: boolean;
    public timeMatrixSource: TimeMatrixSource;
    public canBeBuilt: boolean;
    public isStale: boolean;
    public carCapacity: number;
    public shipmentCount: number;
    public sequence: TripComparisonRouteSequenceItem[];
    public comment: string | undefined;
    public routeSimilarity: number | undefined;
    private readonly _yandexWaypoints: Waypoint[];
    private readonly _yandexDrivingTime: number | undefined;
    private readonly _yandexDistance: number | undefined;
    private _yandexDistanceAdjustment: number | undefined;
    private _yandexDrivingTimeAdjustment: number | undefined;
    private readonly _tGisWaypoints: Waypoint[];
    private readonly _tGisDrivingTime: number | undefined;
    private readonly _tGisDistance: number | undefined;
    private _tGisDistanceAdjustment: number | undefined;
    private _tGisDrivingTimeAdjustment: number | undefined;
    private _isYandexRoute = true;

    public constructor(
        id: number | undefined,
        name: string,
        isRouteSet: boolean,
        isManual: boolean,
        withLoadingStore: boolean,
        withDriverPlace: boolean,
        timeMatrixSource: TimeMatrixSource,
        canBeBuilt: boolean,
        isStale: boolean,
        carCapacity: number,
        shipmentCount: number,
        yandexWaypoints: undefined | Waypoint[],
        yandexDistance: number | undefined,
        yandexDrivingTime: number | undefined,
        yandexDistanceAdjustment: number | undefined,
        yandexDrivingTimeAdjustment: number | undefined,
        tGisWaypoints: undefined | Waypoint[],
        tGisDistance: number | undefined,
        tGisDrivingTime: number | undefined,
        tGisDistanceAdjustment: number | undefined,
        tGisDrivingTimeAdjustment: number | undefined,
        sequence: TripComparisonRouteSequenceItem[] | undefined,
        comment: string | undefined,
        routeSimilarity: number | undefined
    ) {
        this.id = id;
        this.name = name;
        this.isRouteSet = isRouteSet;
        this.isManual = isManual;
        this.withLoadingStore = withLoadingStore;
        this.withDriverPlace = withDriverPlace;
        this.timeMatrixSource = timeMatrixSource;
        this.canBeBuilt = canBeBuilt;
        this.isStale = isStale;
        this.carCapacity = carCapacity;
        this.shipmentCount = shipmentCount;
        this._yandexWaypoints = yandexWaypoints || [];
        this._yandexDistance = yandexDistance;
        this._yandexDrivingTime = yandexDrivingTime;        
        this._yandexDistanceAdjustment = yandexDistanceAdjustment;
        this._yandexDrivingTimeAdjustment = yandexDrivingTimeAdjustment;
        this._tGisWaypoints = tGisWaypoints || [];
        this._tGisDistance = tGisDistance;
        this._tGisDrivingTime = tGisDrivingTime;        
        this._tGisDistanceAdjustment = tGisDistanceAdjustment;
        this._tGisDrivingTimeAdjustment = tGisDrivingTimeAdjustment;
        this.sequence = sequence || [];
        this.comment = comment;
        this.routeSimilarity = routeSimilarity;

        makeAutoObservable(this);
    }

    public setComment(value: string) {
        this.comment = value;
    }

    public setIsYandexRoute(value: boolean) {
        this._isYandexRoute = value;
    }

    public setDistanceAdjustment(value: number) {
        if (this._isYandexRoute) {
            this._yandexDistanceAdjustment = value;
        } else {
            this._tGisDistanceAdjustment = value;
        }
    }

    public setDrivingTimeAdjustment(value: number) {
        if (this._isYandexRoute) {
            this._yandexDrivingTimeAdjustment = value;
        } else {
            this._tGisDrivingTimeAdjustment = value;
        }
    }

    public get waypoints(): Waypoint[] {
        return this._isYandexRoute ? this._yandexWaypoints : this._tGisWaypoints;
    }

    public get distance(): number | undefined {
        return this._isYandexRoute ? this._yandexDistance : this._tGisDistance;
    }

    public get drivingTime(): number | undefined {
        return this._isYandexRoute ? this._yandexDrivingTime : this._tGisDrivingTime;
    }

    public get distanceAdjustment(): number | undefined {
        return this._isYandexRoute ? this._yandexDistanceAdjustment : this._tGisDistanceAdjustment;
    }

    public get drivingTimeAdjustment(): number | undefined {
        return this._isYandexRoute ? this._yandexDrivingTimeAdjustment : this._tGisDrivingTimeAdjustment;
    }
}