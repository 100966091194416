import * as React from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { LatLngExpression } from 'leaflet';
import { Place } from '../../Model/Place/Place';
import { ProductOperation } from '../../Model/ProductOperation/ProductOperation';
import { ProductOperationType } from '../../Model/ProductOperation/ProductOperationType';
import { TripExecutor } from '../../Model/TripExecutor/TripExecutor';
import { CarList } from '../CarList/CarList';
import MapControl from '../MapContainer/MapControl';
import { ProductOperationDealType } from '../../Model/ProductOperation/ProductOperationDealType';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { PlaceSelect } from '../PlaceSelect/PlaceSelect';
import MapContainer from '../MapContainer/MapContainer';
import { Geocode } from '../../Model/Geocode/Geocode';
import { GeocodingApi } from '../../Backend/Api/Geocoding/GeocodingApi';
import { useCompany } from '../AppProviders/Providers/CompanyProvider';
import { DriverPlace } from '../../Model/Place/DriverPlace';
import { PointsOnMap } from '../PointsOnMap/PointsOnMap';
import './OrderPlacePicker.css';

interface IProductOperationListProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header: string;
    operations: ReadonlyArray<ProductOperation>;
}

const ProductOperationList = (props: IProductOperationListProps): JSX.Element => {
    const { header, operations, ...divProps } = props;

    return (
        <div className="product-operation-list" {...divProps}>
            <div className="product-operation-list__header">{header}</div>
            {operations.map((productOperation, index) => (
                <div key={index} className="product-operation-list__order-product">
                    <div className="product-operation-list__order-product__name">
                        {productOperation.product.name}{' '}
                        {productOperation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                    </div>
                    <div className="product-operation-list__order-product__count">
                        x{productOperation.estimatedCount}
                    </div>
                </div>
            ))}
        </div>
    );
};

export interface IOrderPlacePickerProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onCancel: () => void;
    onPick: (place: Place) => Promise<void>;
    orderExecutor: TripExecutor;
    orderExecutorLoadingInfo: TripExecutorsLoadingInfo;
    orderProductOperations: ReadonlyArray<ProductOperation>;
    places: ReadonlyArray<Place>;
    driverPlace: DriverPlace | undefined;
    isConfirmationMarker: boolean;
    unlinkAddressFromDriverPlace: (addressId: string, driverPlaceId: number) => void;
}

export const OrderPlacePicker = (props: IOrderPlacePickerProps): JSX.Element => {
    const { onCancel,
        onPick,
        orderExecutor,
        orderExecutorLoadingInfo,
        orderProductOperations,
        places,
        driverPlace,
        className,
        unlinkAddressFromDriverPlace,
        ...divProps } = props;
    const company = useCompany();

    const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

    const center: LatLngExpression = places.length > 0 && places[0].latitude
        ? { lat: places[0].latitude!, lng: places[0].longitude! }
        : { lat: company.company!.centerLatitude, lng: company.company!.centerLongitude};

    const searchAddress = React.useCallback(async (address: string): Promise<{geocode: Geocode, isExactly: boolean} | undefined> => {
        try {
            const result = await GeocodingApi.geocodeAddress(address);
            const coords = new Geocode({latitude: result.geocode.latitude, longitude: result.geocode.longitude});

            return {geocode: coords, isExactly: result.isExactly};
        } catch {
            return undefined;
        }
    }, []);

    const unlinkAddress = () => {
        if (places === [] || !places[0].addressId || !driverPlace) {
            return;
        }
        unlinkAddressFromDriverPlace(places[0].addressId, driverPlace.id)
    }

    return (
        <div className={`order-place-picker ${className}`}>
            <Button className="order-place-picker__close-button" onClick={onCancel}>
                <CloseOutlined />
                Отменить
            </Button>
            <div className="order-place-picker__car-info">
                <CarList
                    hideUnknownDriver={true}
                    hidePagination={true}
                    executors={orderExecutor ? [orderExecutor] : []}
                    executorsLoadingInfo={orderExecutorLoadingInfo}
                />
            </div>
            <div className="order-place-picker__order-info">
                <ProductOperationList
                    header="Отвезти"
                    operations={orderProductOperations.filter(
                        (operation) => operation.type === ProductOperationType.Shipment,
                    )}
                />
                <ProductOperationList
                    header="Привезти"
                    operations={orderProductOperations.filter(
                        (operation) => operation.type === ProductOperationType.Return,
                    )}
                />
            </div>
            <MapContainer center={center}>
                <PointsOnMap<DriverPlace>
                    points={props.driverPlace ? [props.driverPlace] : []}
                    getCoordinate={(x) => x.coords}
                >
                    {driverPlace &&
                        <MapControl position='topleft' separate={true}>
                            <Popover
                                visible={isPopoverVisible}
                                onVisibleChange={() => setIsPopoverVisible(prev => !prev)}
                                content={
                                    <div
                                        style={{cursor: 'pointer'}}
                                        onClick={unlinkAddress}
                                    >
                                        Отвязать водительскую точку
                                    </div>
                                }
                                trigger="click"
                            >
                                <a><MenuOutlined /></a>
                            </Popover>
                        </MapControl>
                    }
                </PointsOnMap>
                <PlaceSelect
                    places={places}
                    onSelectPlace={onPick}
                    onSearchAddress={searchAddress}
                />
            </MapContainer>
        </div>
    );
};
