import { DateTime } from './DateTime';

export class Time {
    public static readonly millisecondsInSecond = 1000;
    public static readonly minutesInHour = 60;
    public static readonly secondsInMinute = 60;
    public static fromDate(date: Date): Time {
        return new Time(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    public readonly hours: number;
    public readonly milliseconds: number;
    public readonly minutes: number;
    public readonly seconds: number;

    public constructor(hours: number, minutes: number, seconds: number = 0, milliseconds: number = 0) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.milliseconds = milliseconds;
    }

    public toDate(): Date {
        const dateTime = DateTime.fromDate(new Date());

        return dateTime.cloneWithTime(this).toDate();
    }

    public toMilliseconds(): number {
        const minutes = this.hours * Time.minutesInHour + this.minutes;
        const seconds = minutes * Time.secondsInMinute + this.seconds;

        return seconds * Time.millisecondsInSecond + this.milliseconds;
    }

    public toString(): string {
        const limit = 10;
        const hours = this.hours < limit ? `0${this.hours}` : this.hours;
        const minutes = this.minutes < limit ? `0${this.minutes}` : this.minutes;

        return `${hours}:${minutes}`;
    }
}
