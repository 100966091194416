import * as React from 'react';

import {
    CartesianGrid,
    ResponsiveContainer,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceLine,
    DotProps,
  } from 'recharts'
import { formatTime } from '../../Std/formatTime';
import { DateTime } from '../../Std/DateTime';
import { IHorizontalLine } from './Model/IHorizontalLine';

import './Chart.css';
import { ChartProps } from './ChartProps';
import { ITimeSeriesFact } from './Model/ITimeSeriesFact';

export interface ITimesSeriesLineChartProps extends ChartProps {
    data: ITimeSeriesFact[];
    names?: string[];
    strokes?: string[];
    formatTimeTick?: (tick: number) => string;
    horizontalLines?: IHorizontalLine[];
    dot?: React.ReactElement<SVGElement> | ((props: any) => React.ReactElement<SVGElement>) | DotProps | boolean;
    className?: string;
}

export const TimesSeriesLineChart = (props: ITimesSeriesLineChartProps) : JSX.Element => {
    const chartDefaultHeight = 400;
    const referenceLineDefaultWidth = 2;

    const formatTimeTick = (tick: number): string => props.formatTimeTick
        ? props.formatTimeTick(tick)
        : formatTime(DateTime.fromDate(new Date(tick)));

    return (
        <div className={`chart ${props.className}`}>
            {props.title !== undefined && (
                <h3 className="chart-title">{props.title}</h3>
            )}
            {props.data.length > 0 && (
                <ResponsiveContainer width={props.chartWidth || '100%'} height={props.chartHeight || chartDefaultHeight}>
                    <LineChart data={props.data} margin={{left: -20}}>
                        <XAxis
                            dataKey='tick'
                            type='number'
                            domain={['dataMin', 'dataMax']}                        
                            tickFormatter={formatTimeTick}
                            scale="time" />

                        <YAxis
                            type='number'
                            domain={['auto', 'auto']} />

                        <Tooltip labelFormatter={formatTimeTick} />

                        <CartesianGrid stroke="#f5f5f5" />

                        {props.data.length && props.data[0].values.map((value: number | undefined, index: number) => (
                            <Line
                                key={index}
                                dataKey={`values.${index}`}
                                name={props.names && index < props.names.length ? props.names[index] : "value"}
                                stroke={props.strokes && index < props.strokes.length ? props.strokes[index] : "#1a85a0"}
                                dot={props.dot} />
                        ))}

                        {props.horizontalLines && props.horizontalLines.map((line: IHorizontalLine, index: number) => (
                            <ReferenceLine
                                key={index}
                                y={line.y}
                                label={{value: line.y, position: line.position || 'top'}}
                                ifOverflow='extendDomain'
                                stroke={line.color || '#c80000'}
                                strokeDasharray='6 4'
                                strokeWidth={line.width || referenceLineDefaultWidth}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};