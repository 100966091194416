import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, Spin } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { IntervalPatternStore } from '@/Store/IntervalPatternStore/IntervalPatternStore';
import { CompanyApiInterval, CompanyApiTimeInterval } from '@/ApiClient/YosoCompany/models';
import DaysOfWeekSelector from '@/Components/DaysOfWeekSelector/DaysOfWeekSelector';
import DeliveryTimeIntervalSelector from '../DeliveryTimeIntervalSelector/DeliveryTimeIntervalSelector';

import styles from './RegularIntervalEditor.module.css';

interface RegularIntervalEditorProps {
    store: IntervalPatternStore | undefined;
    saveIntervals: () => Promise<void>;
}

const RegularIntervalEditor = observer((props: RegularIntervalEditorProps) => {
    const { store, saveIntervals } = props;
    const [saving, setSaving] = useState(false);
    const [anyChanges, setAnyChanges] = useState(false);

    const saveIntervalsWrap = () => {
        setSaving(true);
        saveIntervals()
            .then((x) => {
                setAnyChanges(false);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <div className={styles.container}>
            <h2>Шаблон</h2>
            {!store || saving ? (
                <Spin className={styles.spin} />
            ) : (
                <>
                    <div className={styles.itemContainer}>
                        {store.rows.map((row: CompanyApiInterval, i) => (
                            <div className={styles.item} key={i}>
                                <div className={styles.daysOfWeekSelectorWrap}>
                                    <div className={styles.daysOfWeekSelector}>
                                        <DaysOfWeekSelector
                                            values={row.daysOfWeek}
                                            onChange={(v) => {
                                                store.setDaysOfWeek(row, v);
                                                setAnyChanges(true);
                                            }}
                                            allowEmpty={true}
                                        />
                                    </div>
                                    <CloseCircleOutlined
                                        className={styles.delete}
                                        onClick={() => {
                                            if (row.intervals.length > 0 || row.id > 0) {
                                                Modal.confirm({
                                                    title: 'Удалить?',
                                                    icon: <ExclamationCircleOutlined />,
                                                    okText: 'Да',
                                                    okType: 'primary',
                                                    cancelText: 'Нет',
                                                    onOk: () => {
                                                        store.removeRow(row);
                                                        setAnyChanges(true);
                                                    },
                                                    onCancel: () => {
                                                        /* */
                                                    },
                                                });
                                            } else {
                                                store.removeRow(row);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={styles.intervalSelector}>
                                    <DeliveryTimeIntervalSelector<CompanyApiTimeInterval>
                                        intervals={row.intervals}
                                        getBegin={(x) => x.begin}
                                        getEnd={(x) => x.end}
                                        onRemove={(interval) => {
                                            store.removeInterval(row, interval);
                                            setAnyChanges(true);
                                        }}
                                        onBeginChange={(interval, v) => {
                                            store.setIntervalBegin(row, interval, v);
                                            setAnyChanges(true);
                                        }}
                                        onEndChange={(interval, v) => {
                                            store.setIntervalEnd(row, interval, v);
                                            setAnyChanges(true);
                                        }}
                                    />
                                </div>
                                <PlusOutlined
                                    className={styles.addInterval}
                                    onClick={() => {
                                        store.addInterval(row);
                                        setAnyChanges(true);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={styles.addContainer}>
                        <Button
                            className={styles.addButton}
                            onClick={() => {
                                store.addRow();
                                setAnyChanges(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </div>
                    <div className={styles.saveContainer}>
                        {store.errors.length > 0 && (
                            <div className={styles.errors}>
                                {store.errors.map((x, i) => (
                                    <div className={styles.error} key={i}>
                                        {x}
                                    </div>
                                ))}
                            </div>
                        )}
                        <Button
                            className={styles.saveButton}
                            type={anyChanges ? 'primary' : 'default'}
                            disabled={store.errors.length > 0}
                            onClick={saveIntervalsWrap}
                        >
                            Сохранить
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});

export default RegularIntervalEditor;
