import { DraftApiOrder, DraftApiPlace, DraftApiProductOperation } from '../../../../ApiClient/YosoDraft/models';
import { Car } from '../../../../Model/Car/Car';
import { DraftOrder } from '../../../../Model/DraftOrder/DraftOrder';
import { Driver } from '../../../../Model/Driver/Driver';
import { Geocode } from '../../../../Model/Geocode/Geocode';
import { OrderOperations } from '../../../../Model/OrderOperations/OrderOperations';
import { DriverPlace } from '../../../../Model/Place/DriverPlace';
import { GeocodeQuality } from '../../../../Model/Place/GeocodeQuality';
import { GeocodeSource } from '../../../../Model/Place/GeocodeSource';
import { Place } from '../../../../Model/Place/Place';
import { Product } from '../../../../Model/Product/Product';
import { ProductOperation } from '../../../../Model/ProductOperation/ProductOperation';
import { TripExecutor } from '../../../../Model/TripExecutor/TripExecutor';
import { geocodeQualityDeserializer } from '../../Delivery/Model/Place/GeocodeQualityDeserializer';
import { geocodeSourceDeserializer } from '../../Delivery/Model/Place/GeocodeSourceDeserializer';
import { DateTimePeriodMapper } from '../../Mapper/DateTimePeriodMapper';

import { ProductOperationMapper } from './ProductOperationMapper';

export class DraftOrderMapper {
    public static mapFromApi(
        apiOrder: DraftApiOrder,
        orderId: number,
        products: Product[],
        drivers: Driver[],
        cars: Car[],
    ): DraftOrder {
        return new DraftOrder(
            orderId,
            apiOrder.publicId,
            apiOrder.code,
            DraftOrderMapper.createPlaces(apiOrder),
            new OrderOperations(
                apiOrder.productOperations.map(
                    (op: DraftApiProductOperation): ProductOperation => ProductOperationMapper.mapFromApi(op, products),
                )
            ),
            apiOrder.comment,
            apiOrder.addressComment,
            DateTimePeriodMapper.mapFromApi(apiOrder.desiredTimeBegin, apiOrder.desiredTimeEnd),
            undefined,
            new TripExecutor(
                cars.find((car: Car) => car.carNumber === apiOrder.carNumber)!,
                drivers.find((driver: Driver) => driver.code === apiOrder.driverCode)!,
                undefined
            ),
            apiOrder.price,
            apiOrder.paymentMethod,
            apiOrder.phoneNumber,
            apiOrder.additionalPhoneNumbers,
            apiOrder.clientName,
            apiOrder.addressFull,
            apiOrder.corporate,
            apiOrder.departPeriod,
            apiOrder.driverPlace ? new DriverPlace(apiOrder.driverPlace.id,
                new Geocode({latitude: apiOrder.driverPlace.latitude, longitude: apiOrder.driverPlace.longitude})) : undefined,
            apiOrder.isInternal
        );
    }

    private static createPlaces(apiOrder: DraftApiOrder): Place[] {
        if (apiOrder.resolvedPlaces === undefined) {
            return [
                new Place(
                    apiOrder.addressShort,
                    apiOrder.addressFull,
                    undefined,
                    undefined,
                    GeocodeQuality.None,
                    GeocodeSource.Unknown,
                    apiOrder.addressId,
                ),
            ];
        }

        return apiOrder.resolvedPlaces.map(
            (apiPlace: DraftApiPlace): Place =>
                new Place(
                    apiOrder.addressShort,
                    apiOrder.addressFull,
                    apiPlace.latitude,
                    apiPlace.longitude,
                    geocodeQualityDeserializer.deserialize(apiPlace.geocodeQuality),
                    geocodeSourceDeserializer.deserialize(apiPlace.geocodeSource),
                    apiOrder.addressId,
                ),
        );
    }
}
