import { ApiItineraryProductMovement, DeliveryApiProduct } from "../../../../../ApiClient/Yoso/models";
import { ItineraryProductMovement } from "../../../../../Model/Itinerary/ItineraryProductMovement";
import { productMovementRowDeserializer } from "./ProductMovementRowDeserializer";

export class ProductMovementDeserializer {
    public deserialize(response: ApiItineraryProductMovement, products: DeliveryApiProduct[]): ItineraryProductMovement {
        return new ItineraryProductMovement(
            response.title,
            response.rows.map(row => productMovementRowDeserializer.deserialize(row, products))
        )
    }
}

export const productMovementDeserializer = new ProductMovementDeserializer();