import { Skeleton } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { DeliveryApiCar } from '../../../../ApiClient/Yoso/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { CarEntityEdit } from '../../../CarEntityEdit/CarEntityEdit';
import { Page } from '../../../Page/Page';

export interface ICarEditRouteProps extends RouteComponentProps {
    car: DeliveryApiCar | undefined;
    id: number | undefined;
}

export const CarEditRoute = withRouter(
    (props: ICarEditRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(props.id !== undefined);
        const [car, setCar] = React.useState(props.car);

        const fetchData = React.useCallback(async (id: number): Promise<undefined> => {
            setLoading(true);
            try {
                const response = await CompanyApi.getCar(id);
                if (destroyed.current) {
                    return;
                }
                setCar(response.car);
            } catch (error) {
                if (destroyed.current) {
                    return;
                }
                alert(String(error));
            }
            setLoading(false);
        }, []);

        React.useEffect((): void => {
            if (props.id !== undefined) {
                fetchData(props.id).then(
                    () => {
                        //
                    },
                    () => {
                        //
                    },
                );
            }
        }, [fetchData, props.id]);

        const handleSaveCar = React.useCallback((): void => {
            props.history.push(`/cars`);
        }, [props.history]);

        return (
            <Page
                header={
                    props.id === undefined ? 'Новый автомобиль' : car === undefined ? '' : `Автомобиль ${car.number}`
                }
            >
                {loading ? (
                    <Skeleton active round title={{ width: 400 }} />
                ) : (
                    <CarEntityEdit car={car} onSave={handleSaveCar} />
                )}
            </Page>
        );
    },
);
