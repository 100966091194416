import { DateTime } from './DateTime';
import { formatNumber } from './FormatNumber';

const formatDateComponent = (value: number): string =>
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    formatNumber(value).padStart(2, '0');

const adjustDateTimeForView = (date: DateTime): DateTime => {
    const d = date.toDate();
    d.setTime(d.getTime() + DateTime.millisecondsInSecond * DateTime.getCompanyAndLocalDiff());

    return DateTime.fromDate(d);
};

export const formatDate = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${formatDateComponent(date.date)}.${formatDateComponent(date.month + 1)}.${date.year}`;
};

export const formatDateReverse = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${date.year}.${formatDateComponent(date.month + 1)}.${formatDateComponent(date.date)}`;
};

export const formatDateShort = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${formatDateComponent(date.date)}.${formatDateComponent(date.month + 1)}`;
};

export const formatDateTime = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${formatDateComponent(date.date)}.${formatDateComponent(date.month + 1)}.${date.year}  
    ${formatDateComponent(date.hours)}:${formatDateComponent(date.minutes)}`;
};

export const formatDateTimeShort = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${formatDateComponent(date.date)}.${formatDateComponent(date.month + 1)}  
    ${formatDateComponent(date.hours)}:${formatDateComponent(date.minutes)}`;
};

export const formatDateIso = (date: DateTime): string => {
    date = adjustDateTimeForView(date);

    return `${date.year}-${formatDateComponent(date.month + 1)}-${formatDateComponent(date.date)}`;
};
