import React from 'react';
import {Button} from 'antd';
import { DateTime } from '../../../../Std/DateTime';
import { formatDate } from '../../../../Std/formatDate';
import styles from './Trip.module.css';

interface TripProps {
    name: string;
    date: DateTime;
    onClick: () => void;
}

const Trip = (props: TripProps) => {
    const [isVisible, setIsVisible] = React.useState(styles.invisible);

    return (
        <div
            className={styles.trip}
            onMouseEnter={() => setIsVisible(styles.visible)}
            onMouseLeave={() => setIsVisible(styles.invisible)}
        >
            <span className={styles.tripName}>{props.name}</span>
            <span>{formatDate(props.date)}</span>
            <Button
                className={`${styles.button} ${isVisible}`}
                type='primary'
                onClick={() => props.onClick()}
            >
                Перейти
            </Button>
        </div>
    );
};

export default Trip;