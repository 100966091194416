import * as React from 'react';
import { formatTimePeriod } from '../../../Std/FormatTimePeriod';
import { TimePeriod } from '../../../Std/TimePeriod';

export interface IFormattedTimePeriodProps {
    dateTimePeriod: TimePeriod | undefined;
}

export const FormattedTimePeriod = (props: IFormattedTimePeriodProps): JSX.Element => {
    const { dateTimePeriod } = props;

    return <> ({dateTimePeriod === undefined ? undefined : formatTimePeriod(dateTimePeriod)})</>;
};
