import {
    DeliveryApiDraftDistrict,
} from '../../../../../ApiClient/Yoso/models';
import { Group } from '../../../../../ApiClient/YosoCompany/models';
import { District } from "../../../../../Model/District/District";
import {
    DistrictGroupCode
} from '../../../../../Store/District/DistrictGroupCode';

export class DraftDistrictSerializer {
    public serialize(district: District): DeliveryApiDraftDistrict {
        if (!district.id) {
            throw new Error('No id in district')
        }

        return {
            id: district.id,
            name: district.name,
            polygonPoints: district.geocodes,
            color: district.color,
            group: this.serializeDistrictGroup(district.group),
            driverId: district.drivers[0].id
        }
    }

    private serializeDistrictGroup(group: DistrictGroupCode): Group {
        switch(group) {
            case DistrictGroupCode.Morning:
                return Group.MORNING;
            case DistrictGroupCode.Evening:
                return Group.EVENING;
            case DistrictGroupCode.DayOff:
                return Group.DAYOFF;
            default:
                throw new Error(`Unknown district group ${group}`)
        }
    }
}

export const draftDistrictSerializer = new DraftDistrictSerializer()