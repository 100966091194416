import React from 'react'
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import { ReactComponent as UserIconComponent } from '../../icons/user.svg';
import { ReactComponent as EditCoordsIconComponent } from '../../icons/edit.svg';
import { ReactComponent as BottleIconComponent } from '../../icons/bottle-product.svg';
import { ReactComponent as CommentBigIconComponent } from '../../icons/comment-big.svg';
import { ReactComponent as LocationBigIconComponent } from '../../icons/location-big.svg';
import { ReactComponent as PersonCardIconComponent } from '../../icons/person-card.svg';
import { ReactComponent as PhoneBigIconComponent } from '../../icons/phone-big.svg';
import { ReactComponent as TimeBigIconComponent } from '../../icons/time-big.svg';
import { TripWorkingShift } from "../../Model/Trip/TripWorkingShift";
import { formatTime } from '../../Std/formatTime';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import { ProductOperationType } from '../../Model/ProductOperation/ProductOperationType';
import { ProductOperationDealType } from '../../Model/ProductOperation/ProductOperationDealType';
import { PlanStore } from '../../Store/PlanStore/PlanStore';
import { EntityList, EntityListColumnAlign } from '../EntityList/EntityList';
import { Trip } from '../../Model/Trip/Trip';
import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import './OrderInfo2.css';

interface OrderInfoProps {
    planStore: PlanStore;
    onEditPlace: () => void;
}

enum ViewOrder {
    OrderInfo,
    OrderTransfer
}

const OrderInfo2 = observer((props: OrderInfoProps) => {
    const [viewOrder, setViewOrder] = React.useState<ViewOrder>(ViewOrder.OrderInfo);
    const [isVisibleOrder, setIsVisibleOrder] = React.useState(true);
    const [selectedTrip, setSelectedTrip] = React.useState<Trip | undefined>(undefined);

    React.useEffect(() => {
        setIsVisibleOrder(true);
        setViewOrder(ViewOrder.OrderInfo);
    }, [props.planStore.selectedTripItem])

    const cancelOrder = React.useCallback(() => {
        Modal.confirm({
            title: 'Подтвердите действие',
            content: 'Вы действительно хотите отменить заказ?',
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => {
                props.planStore.cancelOrder();
            },
            onCancel: () => {
                /* */
            },
        });
    }, [props.planStore])

    const closeOrderInfo = React.useCallback(() => {
        if (viewOrder === ViewOrder.OrderInfo) {
            props.planStore.removeSelectedTripItem();
        }

        if (viewOrder === ViewOrder.OrderTransfer) {
            setSelectedTrip(undefined);
            setViewOrder(ViewOrder.OrderInfo);
        }
    }, [props.planStore, viewOrder])

    const toggleSizeViewOrder = React.useCallback(() => {
        setIsVisibleOrder(!isVisibleOrder);
    }, [isVisibleOrder])

    const transferOrder = React.useCallback(() => {
        if (selectedTrip) {
            props.planStore.changeTripForSelectedOrder(selectedTrip.id);
        }
    }, [props.planStore, selectedTrip])

    const executorListWithoutCurrent = React.useCallback(() => (
        props.planStore.trips.filter(
            trip => trip.id !== props.planStore.selectedTripId
                && trip.workingShift === props.planStore.selectedTrip?.workingShift
        )
), [props.planStore])

    if (!(props.planStore.selectedTripItem instanceof OrderRoutePoint)) {
        return null;
    }

    const shipmentOperations = props.planStore.selectedTripItem.order.operations.productOperations.filter(
        (productOperation) => productOperation.type === ProductOperationType.Shipment,
    );

    const returnOperations = props.planStore.selectedTripItem.order.operations.productOperations.filter(
        (productOperation) => productOperation.type === ProductOperationType.Return,
    );

    return (
        <div className={isVisibleOrder ? 'order-info' : 'order-info order-info_min'}>
            <div className='order-info__buttons-group'>
                <div className={`order-info__buttons-group_button-${isVisibleOrder ? 'line' : 'square'}`} onClick={toggleSizeViewOrder} />
                <div className='order-info__buttons-group_button-close' onClick={closeOrderInfo} />
            </div>
            {
                isVisibleOrder && viewOrder === ViewOrder.OrderInfo && (
                    <>
                        <div className='order-info__line2'>
                            <UserIconComponent className='order-info__icon' />
                            <div className='order-info__text-client'>
                                {props.planStore.selectedTripItem.order.clientName}
                            </div>
                        </div>
                        <div className='order-info__line2'>
                            <LocationBigIconComponent className='order-info__icon' />
                            <div>{props.planStore.selectedTripItem.address}</div>
                            <span className='order-info__secondary-text2'>
                                {props.planStore.selectedTripItem.geocode.format()}
                            </span>
                            <span>
                                <EditCoordsIconComponent
                                    className='order-info__icon-button_small'
                                    onClick={props.onEditPlace}
                                />
                            </span>
                        </div>
                        <div className='order-info__line2'>
                            <TimeBigIconComponent className='order-info__icon' />
                            <div>
                                {
                                    props.planStore.selectedTripItem.expectedArrivalDateTime
                                        ? formatTime(props.planStore.selectedTripItem.expectedArrivalDateTime)
                                        : ''
                                }
                            </div>
                            <span className='order-info__secondary-text2'>
                                {
                                    props.planStore.selectedTripItem.desiredDateTimePeriod
                                        ? formatTimePeriod(props.planStore.selectedTripItem.desiredDateTimePeriod)
                                        : ''
                                }
                            </span>
                        </div>
                        <div className='order-info__line2'>
                            <PhoneBigIconComponent className='order-info__icon' />
                            <div>{props.planStore.selectedTripItem.order.phoneNumber ? props.planStore.selectedTripItem.order.phoneNumber : '-'}</div>
                        </div>
                        <div className='order-info__line2'>
                            <CommentBigIconComponent className='order-info__icon' />
                            <div>{props.planStore.selectedTripItem.order.comment ? props.planStore.selectedTripItem.order.comment : '-'}</div>
                        </div>

                        <div className='order-info__block-subheader2'>Доставить</div>
                        {shipmentOperations?.map((operation, index) => (
                            <div key={index} className='order-info__operation-block'>
                                <BottleIconComponent className='order-info__operation-block-icon' />
                                <div className='order-info__operation-block-text'>
                                    {operation.product.name}{' '}
                                    {operation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                                </div>
                                <div className='order-info__operation-block-text'>x{operation.estimatedCount}</div>
                            </div>
                        ))}

                        <div className='order-info__block-subheader2'>Забрать</div>
                        {returnOperations?.map((operation, index) => (
                            <div key={index} className='order-info__operation-block'>
                                <BottleIconComponent className='order-info__operation-block-icon' />
                                <div className='order-info__operation-block-text'>
                                    {operation.product.name}{' '}
                                    {operation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                                </div>
                                <div className='order-info__operation-block-text'>x{operation.estimatedCount}</div>
                            </div>
                        ))}

                        <div className='order-info__block_text-hidden'>{props.planStore.selectedTripItem.order.code}</div>
                        <div className='order-info__block order-info__executor-data2'>
                            {`${props.planStore.selectedTrip?.executor.car.carNumber} ${props.planStore.selectedTrip?.executor.driver.name}`}
                        </div>

                        {
                            props.planStore.selectedTripItem.order.canBeTransferred() && (
                                <Button
                                    type='primary' className='order-info__main-button'
                                    onClick={() => setViewOrder(ViewOrder.OrderTransfer)}
                                    disabled={props.planStore.trips.length <= 1}
                                >
                                    {props.planStore.selectedTripItem.order.isActive() ? 'Передать заказ' : 'Восстановить и передать заказ'}
                                </Button>
                            )
                        }
                        {
                            props.planStore.selectedTripItem.order.canBeCancelled() && (
                                <Button
                                    type='link' danger className='order-info__cancel-button'
                                    onClick={cancelOrder}
                                >
                                    Отменить заказ
                                </Button>
                            )
                        }
                    </>
                )
            }
            {
                isVisibleOrder && viewOrder === ViewOrder.OrderTransfer && (
                    <>
                        <div className='change-executor__block-subheader'>Выберите водителя</div>
                        <div className={'change-executor__executor-list'}>
                            <EntityList<Trip>
                                data={executorListWithoutCurrent()}
                                actions={{
                                    item: {
                                        click: (id: number) => (): undefined => {
                                            const foundTrip = props.planStore.trips.find(trip => trip.id === id);

                                            if (foundTrip !== undefined) {
                                                setSelectedTrip(foundTrip)
                                            }

                                            return;
                                        },
                                    },
                                }}
                                id={(trip) => trip.id}
                                columns={[
                                    {
                                        key: 'executorCardIcon',
                                        render: () => (
                                            <PersonCardIconComponent className='change-executor__executor-list__person-card-icon' />
                                        ),
                                        align: EntityListColumnAlign.Center,
                                    },
                                    {
                                        key: 'carNumber',
                                        render: (trip) => <div>{trip.executor.car.carNumber}</div>,
                                        align: EntityListColumnAlign.Left,
                                        noWrap: true,
                                    },
                                    {
                                        key: 'executorName',
                                        render: (trip) => (
                                            <div className="change-executor__executor-list__executorName">
                                                {trip.executor.driver.name}
                                            </div>
                                        ),
                                        align: EntityListColumnAlign.Left,
                                        noWrap: true,
                                    },
                                ]}
                                small={true}
                                selectedItems={selectedTrip}
                                rowClass={(trip) => trip.id !== selectedTrip?.id && trip.workingShift === TripWorkingShift.EVENING
                                    ? 'change-executor__executor_gray' : ''}
                            />
                        </div>
                        <Button type='primary' className='order-info__main-button' onClick={transferOrder}>Передать заказ</Button>
                    </>
                )
            }
        </div>
    )
})

export default OrderInfo2