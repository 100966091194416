import {Geocode} from '../Geocode/Geocode';

export class Address {
    public address: string;
    public coords: Geocode;
    public driverPlaceId?: number;

    public constructor(address: string, coords: Geocode, driverPlaceId?: number) {
        this.address = address;
        this.coords = coords;
        this.driverPlaceId = driverPlaceId;
    }
}