import React from "react";
import L from "leaflet";
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { MapInterface } from "./MapInterface";
import { LeafletDrawContext } from "./LeafletDrawContext";

export class LeafletMap implements MapInterface {
  private readonly mapContainer: any;
  private readonly clickMapActions: ((latlng: L.LatLng) => void)[];

  public constructor(mapContainer: any) {
    this.mapContainer = mapContainer;
    this.clickMapActions = [];
    this.mapContainer.on('click', (e: any) => {
      this.clickMapActions.forEach((a) => a(e.latlng));
    });
  }

  public getDrawContext() {
    return new LeafletDrawContext(this.mapContainer);
  }

  public getZoom(): number {
    return this.mapContainer.getZoom();
  }

  public setCenter(coords: IGeocode, zoom?: number) {
    this.mapContainer.setView(new L.LatLng(coords.latitude, coords.longitude), zoom);
  }

  public setBounds(coords: IGeocode[]) {
    const bounds = L.latLngBounds(coords.map(c => new L.LatLng(c.latitude, c.longitude)));

    this.mapContainer.fitBounds(bounds);
  }

  public onMapClick(action: (e: any) => void): void {
    this.clickMapActions.push(action);
  }
}