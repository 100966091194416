import {Input} from "antd";
import L from "leaflet";
import {observer} from "mobx-react";
import React from 'react';
import {Marker} from "react-leaflet";
import {IGeocode} from "../../Model/Geocode/IGeocode";
import {WarehouseStore} from "../../Store/WarehouseStore/WarehouseStore";
import PopupOnMap from "../MapContainer/components/PopupOnMap";
import {MapContext} from "../MapContainer/MapContextProvider";
import './StoreSelect.css'

interface StoreSelectProps {
    store: WarehouseStore;
}

const StoreSelect = observer((props: StoreSelectProps) => {
    const geometryContext = React.useContext(MapContext)?.editorOnMap;
    if (!geometryContext) {
        throw new Error("Map context is undefined");
    }

    React.useEffect(() => {
        geometryContext.enableMarkerDraw(true);
        geometryContext.onMarkerCreate((id: number, coords: IGeocode) => {
            props.store.addWarehouse(id, coords);
        });
    }, []);

    const icon = L.divIcon({
        iconSize: [20, 20], // eslint-disable-line
        className: "store-select__marker"
    });

    const mainIcon = L.divIcon({
        iconSize: [20, 20], // eslint-disable-line
        className: "store-select__marker store-select__marker_main"
    });

    return (
        <>
            {props.store.warehouses.filter(w => !w.isDeleted).map(w => (
                <Marker
                    icon={w.isMain ? mainIcon : icon} key={w.id}
                    position={{lat: w.coordinates.latitude, lng: w.coordinates.longitude}}
                    eventHandlers={{
                        click: () => {
                            props.store.setSelectedWarehouse(w.id);
                        },
                    }}
                />
            ))}
            <PopupOnMap
                position={props.store.selectedWarehouse ? {
                    lat: props.store.selectedWarehouse.coordinates.latitude,
                    lng: props.store.selectedWarehouse.coordinates.longitude
                } : undefined}
                customIcon={icon}
            >
                <Input
                    className='district-popup__input'
                    value={props.store.selectedWarehouse?.address}
                    onChange={(e) => props.store.selectedWarehouse?.setAddress(e.target.value)}
                />
            </PopupOnMap>
        </>
    );
})

export default StoreSelect;