import * as React from 'react';
import { Alert, Button, Radio, RadioChangeEvent, Space, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Dot } from 'recharts';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { IActualRouteItem } from '../../Model/TripAnalysis/IActualRouteItem';
import { IRouteItem } from '../../Model/TripAnalysis/IRouteItem';
import { ITripAnalysisData } from '../../Model/TripAnalysis/ITripAnalysisData';
import { Duration } from '../../Std/Duration';
import { MathUtils } from '../../Std/MathUtils';
import { formatDistanceInKilometers } from '../../Std/FormatDistanceInKilometers';
import { formatDurationHoursMinutes } from '../../Std/formatDurationHoursMinutes';
import { EntityList } from '../EntityList/EntityList';
import { formatTimePeriod, formatTimePeriodWithMarkup } from '../../Std/FormatTimePeriod';
import { formatDateTime } from '../../Std/formatDateTime';
import { defaultColorPalette as colorScheme } from '../../Shared/ColorPalette';
import { OrderBadgeStatus } from '../OrderBadgeStatus/OrderBadgeStatus';
import { TimesSeriesLineChart } from '../Chart/TimesSeriesLineChart';
import { ITimeSeriesFact } from '../Chart/Model/ITimeSeriesFact';
import { DateTime } from '../../Std/DateTime';
import { IRouteCongruenceIndex } from '../../Model/TripAnalysis/IRouteCongruenceIndex';
import MapContainer from '../MapContainer/MapContainer';
import { GeocodePolyline } from '../GeocodePolyline/GeocodePolyline';
import { StopAndGoLocations } from '../PlaceSelect/StopAndGoLocations';
import { EntityListColumnAlign } from '../EntityList/EntityListBase';
import { formatTime } from '../../Std/formatTime';
import './TripAnalysis.css';
import { OrderStatus } from '../../Model/Order/OrderStatus';

export interface ITripAnalysisProps {
    tripId: number | undefined;
}

enum RouteType {
    Actual,
    DriverFormed,
    Estimated,
}

enum RouteVisualComparingMode {
    DriverFormedAndActual,
    DriverFormedAndEstimated,
    EstimatedAndActual,
}

export const TripAnalysis = (props: ITripAnalysisProps): JSX.Element => {
    const [tripAnalysis, setTripAnalysis] = React.useState(undefined as ITripAnalysisData | undefined);
    const [loading, setLoading] = React.useState(false as boolean | undefined);
    const [mapVisible, setMapVisible] = React.useState(false as boolean | undefined);
    const [arrivalDiffChartData, setArrivalDiffChartData] = React.useState([] as ITimeSeriesFact[]);
    const [routeVisualComparingMode, setRouteVisualComparingMode] = React.useState(RouteVisualComparingMode.DriverFormedAndActual,);
    const [isEstimatedRouteVisible, setIsEstimatedRouteVisible] = React.useState<boolean>(false);

    const loadData = React.useCallback(async () => {
        if (!props.tripId) {
            return;
        }

        setLoading(true);

        try {
            setTripAnalysis(await DeliveryApi.getTripAnalysis(props.tripId));
        } catch (error) {
            alert(String(error));
        }

        setLoading(false);
    }, [props.tripId]);

    React.useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        loadData();
    }, [loadData]);

    const pageSize = 1000;

    React.useEffect(() => {
        if (tripAnalysis !== undefined) {

            const chartData = tripAnalysis.actualRoute.map((routeItem: IActualRouteItem): ITimeSeriesFact => {
                let value;
                let windowStart;
                let windowEnd;
                const stayPeriodStartDate = routeItem.stayPeriod.start.toDate();
                const stayPeriodEndDate = routeItem.stayPeriod.end.toDate();
                if (routeItem.point.promisedDateTimePeriod && routeItem.point.expectedArrivalTime) {
                    value = stayPeriodStartDate.getTime() - routeItem.point.expectedArrivalTime.toDate().getTime();
                    windowStart = routeItem.point.promisedDateTimePeriod.start.toDate().getTime()
                        - routeItem.point.expectedArrivalTime.toDate().getTime();
                    windowEnd = routeItem.point.promisedDateTimePeriod.end.toDate().getTime()
                        - routeItem.point.expectedArrivalTime.toDate().getTime();

                    value = Math.round(value / (DateTime.secondsInMinute * DateTime.millisecondsInSecond));
                    windowStart = Math.round(windowStart / (DateTime.secondsInMinute * DateTime.millisecondsInSecond));
                    windowEnd = Math.round(windowEnd / (DateTime.secondsInMinute * DateTime.millisecondsInSecond));
                }

                return {
                    values: [windowEnd, value, windowStart],
                    tick: stayPeriodEndDate.getTime()
                }
            });

            setArrivalDiffChartData(chartData);
        }
    }, [tripAnalysis]);

    const getOverallInfo = React.useCallback(
        (routeType: RouteType): [Duration | undefined, number | undefined, number | undefined, [string, number | undefined][]] => {
            let duration;
            let distance;
            let length;
            let routeSimilarity: [string, number | undefined][] = [];
            switch (routeType) {
                case RouteType.Actual:
                    duration = tripAnalysis?.actualRouteDuration;
                    distance = tripAnalysis?.actualRouteDistance;
                    length = tripAnalysis?.actualRoute.length;
                    routeSimilarity = [
                        ["с водит.", tripAnalysis?.actualRouteSimilarity],
                        ["с автом.", tripAnalysis?.actualRouteVsEstimatedSimilarity]
                    ];
                    break;
                case RouteType.DriverFormed:
                    duration = tripAnalysis?.routeDuration;
                    distance = tripAnalysis?.routeDistance;
                    length = tripAnalysis?.route.length;
                    routeSimilarity = [
                        ["с автом.", tripAnalysis?.routeSimilarity]
                    ];
                    break;
                case RouteType.Estimated:
                    duration = tripAnalysis?.estimatedRouteDuration;
                    distance = tripAnalysis?.estimatedRouteDistance;
                    length = tripAnalysis?.estimatedRoute.length;
                    break;
                default:
                // pass
            }

            return [duration, distance, length, routeSimilarity];
        },
        [tripAnalysis],
    );

    const OverallInfoBlock = (routeType: RouteType): JSX.Element => {
        const [duration, distance, length, routeSimilarity] = getOverallInfo(routeType);
        const similarityPrecision = 2;

        return (
            <div className="trip-analysis__summary">
                <div className="trip-analysis__summary-column">
                    <div className="trip-analysis__summary-title">Время</div>
                    <div className="trip-analysis__summary-data">
                        {duration === undefined ? '\u2013' : formatDurationHoursMinutes(duration)}
                    </div>
                </div>
                <div className="trip-analysis__summary-column">
                    <div className="trip-analysis__summary-title">Пробег</div>
                    <div className="trip-analysis__summary-data">
                        {distance === undefined ? '\u2013' : formatDistanceInKilometers(distance)}
                    </div>
                </div>
                <div className="trip-analysis__summary-column">
                    <div className="trip-analysis__summary-title">Точек</div>
                    <div className="trip-analysis__summary-data">
                        {length === undefined ? '\u2013' : length}
                    </div>
                </div>
                {routeSimilarity.some((x) => x[1] !== undefined) && (
                    <div className="trip-analysis__summary-column trip-analysis__similarity-column">
                        <div className="trip-analysis__summary-title">Совпадение</div>
                        {routeSimilarity.map((x, i) => (x[1] !== undefined && (
                            <div key={i} className="trip-analysis__summary-data trip-analysis__similarity-data">
                                {x[0]} {MathUtils.round(x[1], similarityPrecision)}
                            </div>
                        ))
                        )}
                    </div>
                )}
            </div>
        );
    };

    const AddressComponent = (routeItem: IRouteItem): JSX.Element => {
        const distanceStyle = routeItem.firstPoint ? 'trip-analysis__hide-block' : 'trip-analysis__distance-block';

        return (
            <>
                <div className={distanceStyle}>{formatDistanceInKilometers(routeItem.arrivalDistance)}</div>
                <div className="trip-analysis__route__address">
                    {routeItem.point.address} {routeItem.id.startsWith('lunch_point') ? '(Обед)' : ''}
                </div>
            </>
        );
    };

    const DoneAtAddressComponent = (routeItem: IRouteItem): JSX.Element => {
        if (routeItem.point.doneAtAddress === undefined) {
            return <></>;
        }

        return (
            <>
                {routeItem.point.doneAtAddress ? <CheckOutlined /> :
                <>
                    <CloseOutlined />
                    {routeItem.point.doneAfterAddressTime &&
                        <span>
                            ({MathUtils.round(routeItem.point.doneAfterAddressTime / DateTime.secondsInMinute)} мин.)
                        </span>
                    }
                </>}
            </>
        );
    };

    const DeliverySizeComponent = (routeItem: IRouteItem): JSX.Element => {
        if (!routeItem.deliverySize || !routeItem.totalDeliverySize) {
            return <></>;
        }

        return (
            <>
                +{routeItem.deliverySize} = {routeItem.totalDeliverySize}
            </>
        );
    };

    const RouteItemTimeComponent = (routeItem: IRouteItem, notActiveTimeStart?: boolean): JSX.Element => {
        const timeStartStyle = notActiveTimeStart ? "trip-analysis__route__time-start-not-active" : undefined;

        return (
            <div className={timeStartStyle} style={{ color: colorScheme.renderColor(routeItem.color).rgbaString }}>
                {formatTimePeriodWithMarkup(routeItem.stayPeriod)}
            </div>
        );
    };

    const RouteItemIndexComponent = (routeItems: readonly IRouteItem[] | undefined, routeItem: IRouteItem): number | undefined => {
        if (!routeItems) {
            return undefined;
        }

        return routeItems.indexOf(routeItem) + 1;
    }

    const RouteItemDesiredPeriodComponent = (routeItem: IRouteItem): string =>
        routeItem.point.desiredDateTimePeriod ? formatTimePeriod(routeItem.point.desiredDateTimePeriod) : ""

    const RouteItemPromisedPeriodComponent = (routeItem: IRouteItem): JSX.Element | string => {
        if (!routeItem.point.promisedDateTimePeriod) {
            return "";
        }

        const color: string = !routeItem.point.shouldSendTimeNotification ? 'trip-analysis__disable-label' : '';

        return <span className={color}>{formatTimePeriod(routeItem.point.promisedDateTimePeriod)}</span>
    }

    const RouteItemCompleteOrderServerTimeComponent = (routeItem: IRouteItem): JSX.Element | string => {
        if (!routeItem.completeOrderServerTime) {
            return "";
        }

        const serverTime: number = routeItem.completeOrderServerTime.toDate().getTime();
        const lastTimeOnPoint: number = routeItem.stayPeriod.getTimePeriod().end.toDate().getTime();
        const difference = Math.abs((serverTime - lastTimeOnPoint) / 60000); // eslint-disable-line

        let color: string;
        if (difference <= 1) {
            color = 'trip-analysis__success-label';
        } else if (difference < 5) { // eslint-disable-line
            color = 'trip-analysis__attention-label';
        } else {
            color = 'trip-analysis__warning-label';
        }

        return <span className={color}>{formatTime(routeItem.completeOrderServerTime)}</span>;
    }

    const getRouteRowClass = React.useCallback((routeType: RouteType, index: number): string => {
        let estimatedIndices: IRouteCongruenceIndex[] | undefined;
        let driverIndices: IRouteCongruenceIndex[] | undefined;
        let actualIndices: IRouteCongruenceIndex[] | undefined;

        switch (routeVisualComparingMode) {
            case RouteVisualComparingMode.DriverFormedAndActual:
                driverIndices = tripAnalysis?.actualRouteCongruence.indices;
                actualIndices = tripAnalysis?.actualRouteCongruence.actualIndices;
                break;
            case RouteVisualComparingMode.DriverFormedAndEstimated:
                driverIndices = tripAnalysis?.estimatedRouteCongruence.indices;
                estimatedIndices = tripAnalysis?.estimatedRouteCongruence.estimatedIndices;
                break;
            case RouteVisualComparingMode.EstimatedAndActual:
                estimatedIndices = tripAnalysis?.estimatedAndActualRouteCongruence.estimatedIndices;
                actualIndices = tripAnalysis?.estimatedAndActualRouteCongruence.actualIndices;
                break;
            default:
            // pass
        }

        let indices: IRouteCongruenceIndex[] | undefined;

        switch (routeType) {
            case RouteType.Estimated:
                indices = estimatedIndices;
                break;
            case RouteType.DriverFormed:
                indices = driverIndices;
                break;
            case RouteType.Actual:
                indices = actualIndices;
                break;
            default:
            // pass
        }

        if (indices === undefined || indices[index] === undefined || indices[index].group < 0) {
            return '';
        }

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return indices[index].group % 2 ? 'odd-group' : 'even-group'
    }, [routeVisualComparingMode, tripAnalysis]);

    const onRouteVisualComparingModeChange = (e: RadioChangeEvent) => {
        setRouteVisualComparingMode(e.target.value);
    };

    const defaultMarkersGeocodePolyline = (elem: IRouteItem, index: number) => {
        const desiredDelivery = elem.point.desiredDateTimePeriod;

        if (desiredDelivery && !desiredDelivery?.inPeriod(elem.stayPeriod.start)) {
            return "geocode-polyline__marker_red";
        }

        return "";
    }

    const detailedMarkersGeocodePolyline = (elem: IRouteItem, index: number) => {
        const desiredDelivery = elem.point.desiredDateTimePeriod;
        const promisedDelivery = elem.point.promisedDateTimePeriod;

        if (promisedDelivery?.inPeriod(elem.stayPeriod.start)) {
            return "geocode-polyline__marker_green";
        }

        if (desiredDelivery?.inPeriod(elem.stayPeriod.start)) {
            return "geocode-polyline__marker_orange";
        }

        return "geocode-polyline__marker_red";
    }

    const getTooltip = (elem: IRouteItem, index: number) => {
        const tooltip = `${formatTimePeriod(elem.stayPeriod)}<br>` +
            `${Math.round(elem.stayPeriod.totalMinutes)} min<br>` +
            `[${elem.point.desiredDateTimePeriod ? formatTimePeriod(elem.point.desiredDateTimePeriod) : ''}]`;

        return tooltip;
    }

    const getArrivalDiffChartDot = React.useCallback((dotProps: any): React.ReactElement<SVGElement> => {
        const { cx, cy, index, value, name} = dotProps;

        if (tripAnalysis === undefined || value === undefined || name !== "Значение") {
            return <span key={index} />;
        }

        const point = tripAnalysis.actualRoute[index].point;

        if (point.shouldSendTimeNotification) {
            const stroke = point.status === OrderStatus.Done ? "#1a85a0" : "#cf1322";
            const strokeWidth = point.timeNotificationSent ? 1+1 : 1;

            return <Dot {...dotProps} stroke={stroke} strokeWidth={strokeWidth}/>;
        }

        const offset = 5;
        const size = 10;

        return (
            <svg key={index} x={cx - offset} y={cy - offset} width={size} height={size} fill="#1a85a0" viewBox="0 0 1024 1024">
                <path d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z"  />
            </svg>
          );
    }, [tripAnalysis?.actualRoute]);

    return (
        <div className="trip-analysis">
            <div className="trip-analysis__chart-block">
                <TimesSeriesLineChart
                    data={arrivalDiffChartData}
                    names={["Верхняя граница", "Значение", "Нижняя граница"]}
                    strokes={["#fe0000", "#509252", "#fe0000"]}
                    dot={getArrivalDiffChartDot}
                    formatTimeTick={tick => formatTime(DateTime.fromDate(new Date(tick)))}
                    title={<>
                        График опозданий&nbsp;
                        <Tooltip title={() => (
                            <>
                            Крестики - смс не надо отправлять (юрлица и без номера телефона)<br/>
                            Кружки - смс надо отправить<br/>
                            &nbsp;&nbsp;&nbsp;жирные - смс были отправлены<br/>
                            &nbsp;&nbsp;&nbsp;красные - заказ не выполнен<br/>
                            </>
                        )} placement='right' overlayStyle={{maxWidth: '480px'}}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </>} />
            </div>
            <div className="trip-analysis__trip-info-block">
                <span>Отправлен:</span> {tripAnalysis?.approvedAt && formatDateTime(tripAnalysis?.approvedAt)}<br />
                <span>Начат:</span> {tripAnalysis?.startedAt && formatDateTime(tripAnalysis?.startedAt)}<br />
                <span>Завершен:</span> {tripAnalysis?.finishedAt && formatDateTime(tripAnalysis?.finishedAt)}<br />
            </div>
            {mapVisible === true && (
                <div className="trip-analysis__maps-group">
                    <MapContainer
                        center={undefined}
                        zoomValue={13}
                        fullscreen={true}
                    >
                        <GeocodePolyline<IActualRouteItem>
                            data={tripAnalysis?.estimatedRoute || []}
                            getGeocode={elem => elem.point.geocode}
                            getClassname={defaultMarkersGeocodePolyline}
                            getTooltip={getTooltip}
                        />
                    </MapContainer>
                    <MapContainer
                        center={undefined}
                        zoomValue={13}
                        fullscreen={true}
                    >
                        <GeocodePolyline<IActualRouteItem>
                            data={tripAnalysis?.route || []}
                            getGeocode={elem => elem.point.geocode}
                            getClassname={defaultMarkersGeocodePolyline}
                            getTooltip={getTooltip}
                        />
                    </MapContainer>
                    <MapContainer
                        center={undefined}
                        zoomValue={13}
                        fullscreen={true}
                    >
                        <GeocodePolyline<IActualRouteItem>
                            data={tripAnalysis?.actualRoute || []}
                            getGeocode={elem => elem.point.geocode}
                            getClassname={detailedMarkersGeocodePolyline}
                            getTooltip={getTooltip}
                            isVisibilityControl={true}
                        />
                        <StopAndGoLocations
                            moveAndStopRoute={tripAnalysis?.moveAndStopLocations || []}
                            isVisibilityControl={true}
                        />
                    </MapContainer>
                </div>
            )}
            {mapVisible === true ? (
                <Button
                    className="trip-analysis__map-button"
                    type="primary"
                    onClick={() => {
                        setMapVisible(false);
                    }}
                >
                    Скрыть карту
                </Button>
            ) : (
                <Button
                    className="trip-analysis__map-button"
                    type="primary"
                    onClick={() => {
                        setMapVisible(true);
                    }}
                >
                    Показать карту
                </Button>
            )}
            <div style={{display: 'flex', gap: 20}}>
                <div className="trip-analysis__route-block">
                    <div className="trip-analysis__route">
                        <div className="trip-analysis__route-title">
                            {isEstimatedRouteVisible &&
                                <>
                                    <MinusSquareOutlined
                                        style={{marginRight: 5, cursor: 'pointer'}}
                                        onClick={() => setIsEstimatedRouteVisible(false)}
                                    />
                                    <span>Сформировано автоматически</span>
                                </>
                            }
                            {!isEstimatedRouteVisible &&
                                <PlusSquareOutlined
                                    style={{marginRight: 5, cursor: 'pointer'}}
                                    onClick={() => setIsEstimatedRouteVisible(true)}
                                />
                            }
                        </div>
                        {isEstimatedRouteVisible &&
                            <>
                                {OverallInfoBlock(RouteType.Estimated)}
                                <EntityList<IRouteItem>
                                    data={tripAnalysis?.estimatedRoute || []}
                                    loading={loading}
                                    id={(routeItem) => routeItem.id}
                                    columns={[
                                        {
                                            key: 'index',
                                            render: (routeItem) => RouteItemIndexComponent(tripAnalysis?.estimatedRoute, routeItem),
                                            title: '#',
                                        },
                                        {
                                            key: 'address',
                                            render: AddressComponent,
                                            title: 'Адрес',
                                        },
                                        {
                                            key: 'desiredDateTimePeriod',
                                            render: RouteItemDesiredPeriodComponent,
                                            title: 'Окно дост.',
                                            noWrap: true
                                        },
                                        {
                                            key: 'arrivedAt',
                                            render: RouteItemTimeComponent,
                                            title: 'Время на тчк',
                                            noWrap: true
                                        },
                                    ]}
                                    pageSize={pageSize}
                                    rowClass={(routeItem, index) => getRouteRowClass(RouteType.Estimated, index)}
                                />
                            </>
                        }
                    </div>
                    <div className="trip-analysis__route">
                        <div className="trip-analysis__route-title">Сформировано водителем</div>
                        {OverallInfoBlock(RouteType.DriverFormed)}
                        <EntityList<IRouteItem>
                            data={tripAnalysis?.route || []}
                            loading={loading}
                            id={(routeItem) => routeItem.id}
                            columns={[
                                {
                                    key: 'index',
                                    render: (routeItem) => RouteItemIndexComponent(tripAnalysis?.route, routeItem),
                                    title: '#',
                                },
                                {
                                    key: 'address',
                                    render: AddressComponent,
                                    title: 'Адрес',
                                },
                                {
                                    key: 'desiredDateTimePeriod',
                                    render: RouteItemDesiredPeriodComponent,
                                    title: 'Окно дост.',
                                    noWrap: true
                                },
                                {
                                    key: 'arrivedAt',
                                    render: RouteItemTimeComponent,
                                    title: 'Время на тчк',
                                    noWrap: true
                                },
                            ]}
                            pageSize={pageSize}
                            rowClass={(routeItem, index) => getRouteRowClass(RouteType.DriverFormed, index)}
                        />
                    </div>
                    <div className="trip-analysis__route trip-analysis__route__actual">
                        <div className="trip-analysis__route-title">Фактический маршрут</div>
                        {OverallInfoBlock(RouteType.Actual)}
                        {tripAnalysis !== undefined && tripAnalysis.actualRoute?.length > 0 ? (
                            <EntityList<IActualRouteItem>
                                data={tripAnalysis?.actualRoute || []}
                                loading={loading}
                                id={(routeItem) => routeItem.point.id}
                                columns={[
                                    {
                                        key: 'index',
                                        render: (routeItem) => RouteItemIndexComponent(tripAnalysis?.actualRoute, routeItem),
                                        title: '#',
                                    },
                                    {
                                        key: 'address',
                                        render: AddressComponent,
                                        title: 'Адрес',
                                    },
                                    {
                                        key: 'doneAtAddress',
                                        render: DoneAtAddressComponent,
                                        title: 'Закрыт на тчк',
                                        align: EntityListColumnAlign.Center,
                                        noWrap: true
                                    },
                                    {
                                        key: 'deliverySize',
                                        render: DeliverySizeComponent,
                                        title: 'Объем',
                                    },
                                    {
                                        key: 'status',
                                        render: (routeItem) => OrderBadgeStatus({ status: routeItem.point.status, deferred: false, fullMode: true }),
                                        title: 'Статус',
                                    },
                                    {
                                        key: 'promisedDateTimePeriod',
                                        render: RouteItemPromisedPeriodComponent,
                                        title: <>
                                            Обещ. инт.&nbsp;
                                            <Tooltip title="
                                                Серым - смс не надо отправлять (юрлица и без номера телефона)
                                                " placement='right' overlayStyle={{maxWidth: '480px'}}>
                                                    <InfoCircleOutlined />
                                            </Tooltip>
                                        </>,
                                        noWrap: true
                                    },
                                    {
                                        key: 'desiredDateTimePeriod',
                                        render: RouteItemDesiredPeriodComponent,
                                        title: 'Окно дост.',
                                        noWrap: true
                                    },
                                    {
                                        key: 'serverDateTimePeriod',
                                        render: RouteItemCompleteOrderServerTimeComponent,
                                        title: 'Сервер. время',
                                        noWrap: true,
                                        align: EntityListColumnAlign.Center
                                    },
                                    {
                                        key: 'arrivedAt',
                                        render: (routeItem) => RouteItemTimeComponent(routeItem, true),
                                        title: 'Время на тчк',
                                        noWrap: true
                                    },
                                ]}
                                pageSize={pageSize}
                                isEntityWithWarning={(routeItem) => !!routeItem.warnRouteItem}
                                rowClass={(routeItem, index) => getRouteRowClass(RouteType.Actual, index)}
                            />
                        ) : (
                            <Alert
                                className="trip-analysis__alert"
                                message="Пока не выполнен ни один заказ."
                                type="warning"
                            />
                        )}
                    </div>
                </div>
                <Radio.Group
                    value={routeVisualComparingMode}
                    onChange={onRouteVisualComparingModeChange}
                    optionType='button'
                    className="trip-analysis__route-vis-mode">
                    <Space direction="vertical">
                        <Radio value={RouteVisualComparingMode.DriverFormedAndEstimated}>1-2</Radio>
                        <Radio value={RouteVisualComparingMode.DriverFormedAndActual}>2-3</Radio>
                        <Radio value={RouteVisualComparingMode.EstimatedAndActual}>1-3</Radio>
                    </Space>
                </Radio.Group>
            </div>
        </div>
    );
};
