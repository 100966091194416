import * as React from 'react';
import {Modal, Checkbox, Button, Spin, Alert} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {Driver} from '../../Model/Driver/Driver';
import {DateTime} from '../../Std/DateTime';
import {useCompany} from "../AppProviders/Providers/CompanyProvider";
import {useTripPlanData} from './TripPlanDataProvider';
import {TripPlanDatePicker} from './TripPlanDatePicker'

export interface ITripPlanDriversPickerProps {
    loading: boolean;
    drivers: Driver[];
    isModalVisible: boolean;
    handleOk: (driverCodes: string[]) => void;
    handleCancel: () => void;
    handleExecutionDateChange: (date: moment.Moment | null) => void;
    handleStartTimeChange: (time: moment.Moment | null) => void;
    handleEndTimeChange: (time: moment.Moment | null) => void;
}

export const TripPlanDriversPicker = (props: ITripPlanDriversPickerProps): JSX.Element => {
    const context = useTripPlanData();
    const company = useCompany();

    let isAlternativePlanAllowed: boolean | undefined;

    if (company) {
        isAlternativePlanAllowed = company.company?.alternativePlanAllowedToUse;
    }

    const [chosenDriverCodes, setChosenDriverCodes] = React.useState(new Set() as Set<string>);

    React.useEffect(() => {
        selectAll();
    }, [props.drivers])

    const handleOk = () => {
        props.handleOk([...chosenDriverCodes]);
    }

    const setChosenDriverCodesOnChange = React.useCallback((e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            chosenDriverCodes.add(e.target.value);
        } else {
            chosenDriverCodes.delete(e.target.value);
        }
        setChosenDriverCodes(new Set(chosenDriverCodes));
    }, [chosenDriverCodes]);

    const selectAll = React.useCallback(() => {
        setChosenDriverCodes(new Set(props.drivers.map((driver: Driver) => driver.code)));
    }, [chosenDriverCodes, props.drivers]);

    const unselectAll = React.useCallback(() => {
        setChosenDriverCodes(new Set());
    }, [chosenDriverCodes]);

    const sort = (a: Driver, b: Driver) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }

    return (
        <Modal title="Выбор времени" visible={props.isModalVisible}
               onOk={handleOk} onCancel={props.handleCancel} width="384px">
            <Spin spinning={props.loading}/>
            {!props.loading && (
                <TripPlanDatePicker
                    executionDate={context.executionDate}
                    startTime={context.startTime}
                    endTime={context.endTime}
                    handleExecutionDateChange={props.handleExecutionDateChange}
                    handleStartTimeChange={props.handleStartTimeChange}
                    handleEndTimeChange={props.handleEndTimeChange}
                    isAlternativePlanAllowed={isAlternativePlanAllowed}
                    planId={context.planId}
                />
            )}
            {!!props.drivers.length && !isAlternativePlanAllowed && (
                    <div className="new-plan__modal-content">
                        <div className="new-plan__modal-content-buttons">
                            <Button type="link" size="small"
                                    onClick={selectAll}
                            >
                                Выбрать всё
                            </Button>
                            <Button type="link" size="small"
                                    onClick={unselectAll}
                            >
                                Сбросить
                            </Button>
                        </div>
                        {props.drivers.sort(sort).map((driver) => (
                            <div key={driver.id}>
                                <Checkbox
                                    checked={chosenDriverCodes.has(driver.code)}
                                    value={driver.code}
                                    onChange={setChosenDriverCodesOnChange}>
                                    {driver.name}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                )}
            {!props.drivers.length && !props.loading && (!isAlternativePlanAllowed || context.planId) && (
                <Alert
                    className={"new-plan__modal-content"}
                    message="Новых заказов не обнаружено."
                    type="warning"
                />
            )}
        </Modal>
    )
}