import { GeocodeQuality as ApiGeocodeQuality } from '../../../../../ApiClient/Yoso/models';
import { GeocodeQuality } from '../../../../../Model/Place/GeocodeQuality';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class GeocodeQualitySerializer implements IApiModelSerializer<GeocodeQuality, ApiGeocodeQuality> {
    public serialize(obj: GeocodeQuality): ApiGeocodeQuality {
        const result =
            obj === GeocodeQuality.JustValidated
                ? ApiGeocodeQuality.JUSTVALIDATED
                : obj === GeocodeQuality.Validated
                ? ApiGeocodeQuality.VALIDATED
                : obj === GeocodeQuality.Exactly
                ? ApiGeocodeQuality.EXACTLY
                : obj === GeocodeQuality.Near
                ? ApiGeocodeQuality.NEAR
                : obj === GeocodeQuality.None
                ? ApiGeocodeQuality.NONE
                : obj === GeocodeQuality.Street
                ? ApiGeocodeQuality.STREET
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown GeocodeQuality '${obj?.toString()}'`);
        }

        return result;
    }
}
export const geocodeQualitySerializer = new GeocodeQualitySerializer();
