import moment from 'moment';
import { Button, Modal, Skeleton, Table } from 'antd';
import * as React from 'react';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { CompanyApiDriverCoefCorrection } from '../../../../ApiClient/YosoCompany/models';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import './DriverCoefs.css';

/* eslint-disable @typescript-eslint/naming-convention */

export interface IDriverCoefsRouteProps {
    driverId: number;
}

export const DriverCoefs = (props: IDriverCoefsRouteProps): JSX.Element => {
    const [loading, setLoading] = React.useState(true);
    const [graphsVisible, setGraphsVisible] = React.useState(false);
    const [graphsContent, setGraphsContent] = React.useState('');
    const [corrections, setCorrections] = React.useState([] as CompanyApiDriverCoefCorrection[]);

    React.useEffect(() => {
        setLoading(true);
        CompanyApi.getDriverCoefCorrections(props.driverId).then((result) => {
            setCorrections(ArrayUtils.sort(result, x => x.createdAt, true, false))
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    const showGraphs = React.useCallback((id: number) => {
        setLoading(true);
        CompanyApi.getDriverCoefCorrectionGraphs(props.driverId, id).then((response) => {
            setGraphsContent(response.result)
        })
        .catch((err) => {
            setGraphsContent(String(err))
        })
        .finally(() => {
            setLoading(false);
            setGraphsVisible(true)
        })
    }, [])
        
    return (
        <div>
            <Table loading={loading} dataSource={corrections} rowKey="id" columns={[{
                title: "Дата проверки",
                dataIndex: "createdAt",
                render: (value) => moment(value).format('YYYY-MM-DD HH:mm')
            },
            {
                title: "Коэффициент движения",
                dataIndex: "drivingFactor"
            },
            {
                title: "Коэффициент разгрузки",
                dataIndex: "unloadingFactor"
            },
            {
                title: "Графики",
                dataIndex: "id",
                render: (value) => <Button onClick={() => showGraphs(value)}>Показать</Button>
            }]} />
            <Modal
                className="graphs-modal"
                title="Графики"
                visible={graphsVisible}
                onOk={() => setGraphsVisible(false)}
                onCancel={() => setGraphsVisible(false)}
            >
                <div dangerouslySetInnerHTML={{ __html: graphsContent }} />
            </Modal>
        </div>
    );
}
