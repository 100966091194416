import React from 'react';
import { Alert, Spin, Table, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import {
    Area,
    CartesianGrid,
    Cell,
    ComposedChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    XAxis,
    YAxis,
} from 'recharts';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import { usePlanPredictions } from '../../hooks/usePlanPredictions';
import { usePredictionChart } from '../../hooks/usePredictionChart';
import { Trip } from '../../Model/Trip/Trip';
import { TripWorkingShift } from '../../Model/Trip/TripWorkingShift';
import { TripPlan } from '../../Model/TripPlan/TripPlan';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { ReactComponent as MoonImageComponent } from '../../icons/moon.svg';
import { ReactComponent as SunImageComponent } from '../../icons/sun.svg';
import { formatTime, formatTimestamp } from '../../Std/formatTime';
import styles from './WhereMyWater.module.css';

interface WhereMyWaterProps extends RouteComponentProps {
    tripPlan: TripPlan;
}

const WhereMyWater = (props: WhereMyWaterProps): JSX.Element => {
    const trips: Trip[] = React.useMemo(() => {
        const tripsWithOrders = props.tripPlan.trips.filter(i => i.ordersCount > 0);

        return ArrayUtils.sort(tripsWithOrders, by => by.executor.driver.name);
    }, [props.tripPlan]);

    const scrollRef = useHorizontalScroll();

    const {
        tripPredictions,
        loadingPlanPredictions
    } = usePlanPredictions(props.tripPlan.id);

    const {
        predictionChartData,
        fetchPrediction,
        loadingPredictionChartData
    } = usePredictionChart()

    const [selectedTripId, setSelectedTripId] = React.useState<number | null>(null);
    const [selectedPredictionId, setSelectedPredictionId] = React.useState<number | null>(null);

    const selectedTripPrediction = React.useMemo(() => {
        const found =  tripPredictions.find(i => i.tripId === selectedTripId);
        if (found) {
            found.predictions.sort((a, b) => a.createdAt.compareTo(b.createdAt));
        }

        return found;
    }, [selectedTripId, tripPredictions]);

    const formatSeconds = (seconds: number): string => {
        const sign = seconds < 0 ? "-" : "";
        seconds = Math.abs(seconds);
        const hours = Math.floor(seconds / 3600); // eslint-disable-line
        const minutes = Math.floor((seconds % 3600) / 60); // eslint-disable-line

        return `${sign}${hours}ч${minutes}м`;
    }

    const selectTrip = (tripId: number) => {
        if (tripId === selectedTripId) {
            return;
        }
        setSelectedTripId(tripId);
        setSelectedPredictionId(null)
    }

    const selectPrediction = (predictionId: number) => {
        fetchPrediction(predictionId);
        setSelectedPredictionId(predictionId);
    }

    React.useEffect(() => {
        if (!scrollRef.current) {
            return undefined;
        }

        const listener = scrollRef.current.addEventListener("wheel", (e: WheelEvent) => {
            e.preventDefault();
            scrollRef.current.scrollLeft += e.deltaY;
        })

        return scrollRef.current.removeEventListener("wheel", listener);
    }, [selectedTripId])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.table}>
                    <h2 className={styles.title}>Маршруты</h2>
                    <Table
                        dataSource={trips}
                        rowKey={(record) => record.id}
                        columns={[
                            {
                                key: 'icon',
                                render: (record: Trip) =>
                                    <div className={classNames(
                                        styles.icon,
                                        !record.executor.driver.enableDeliveryPrediction ? styles.notActive : ''
                                    )}>
                                        {record.workingShift === TripWorkingShift.MORNING &&
                                            <SunImageComponent
                                                className={styles.yellow} />}
                                        {record.workingShift === TripWorkingShift.EVENING &&
                                            <MoonImageComponent
                                                className={styles.blue} />}
                                    </div>,
                            },
                            {
                                key: 'id',
                                render: (record: Trip) => <div
                                    className={classNames(
                                        styles.name,
                                        !record.executor.driver.enableDeliveryPrediction ? styles.notActive : ''
                                    )}>{record.executor.name}</div>,
                            },
                        ]}
                        rowClassName={(record, index) => classNames(styles.row, { [styles.selected]: record.id === selectedTripId })}
                        onRow={(record) => ({
                            onClick: () => selectTrip(record.id),
                        })}
                        pagination={false}
                        showHeader={false}
                        size={'small'}
                    />
                </div>
                <div className={styles.charts}>
                    <Spin spinning={loadingPlanPredictions}>
                        {!selectedTripId &&
                            <Alert message='Выберите маршрут' type='info' />}
                        {selectedTripId && !selectedTripPrediction &&
                            <Alert message='Нет предсказаний' type='info' />}
                        {selectedTripPrediction && (
                            <>
                                <Button type="link" href={`/plans/${props.tripPlan.id}/analysis/${selectedTripId}`} target='_blank'>
                                    Сравнение
                                </Button>
                                <div className={styles.times} ref={scrollRef}>
                                    {selectedTripPrediction.predictions.map((i) =>
                                        <div
                                            key={i.createdAt.toDate().toString()}
                                            className={classNames(styles.time, { [styles.selected]: i.id === selectedPredictionId })}
                                            onClick={() => selectPrediction(i.id)}
                                        >
                                            {formatTime(i.createdAt)}
                                        </div>,
                                    )}
                                </div>
                                <Spin spinning={loadingPredictionChartData}>
                                    {selectedPredictionId && predictionChartData &&
                                        <ResponsiveContainer height={400}>
                                            <ComposedChart
                                               data={predictionChartData.area}
                                               margin={{ left: 30 }}
                                            >
                                                <CartesianGrid />
                                                <XAxis type='number' dataKey='x'
                                                       name='X'
                                                       domain={['auto', 'auto']}
                                                       tickFormatter={formatTimestamp} />
                                                <YAxis type='number' dataKey='y'
                                                       name='Разница времени'
                                                       tickFormatter={formatSeconds}/>
                                                <Line
                                                    data={predictionChartData.linePoints}
                                                    dataKey='y' stroke='red' />
                                                <ReferenceLine
                                                    x={predictionChartData.createdAt}
                                                    stroke='blue' />
                                                <Area dataKey="y" stroke="#8884d8" fill="#8884d8" />
                                                <Scatter data={predictionChartData.allPoints}>
                                                    {predictionChartData.allPoints
                                                        .map((entry, index) =>
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={entry.color ? entry.color : 'black'}
                                                            />
                                                        )
                                                    }
                                                </Scatter>
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    }
                                </Spin>
                            </>
                        )}
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default WhereMyWater;