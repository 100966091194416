import {SelectValue} from 'antd/lib/select';
import React from 'react';
import {
    Button,
    ConfigProvider,
    DatePicker,
    Pagination,
    Select,
    Spin
} from 'antd';
import {useHistory, withRouter} from 'react-router-dom';
// eslint-disable-next-line import/no-unassigned-import
import 'moment/locale/ru';
import ru_RU from 'antd/es/locale/ru_RU';
import {Moment} from 'moment';
import {DeliveryApi} from '../../../Backend/Api/Delivery/DeliveryApi';
import {Page} from '../../Page/Page';
import {
    TripComparisonListItem
} from '../../../Model/TripComparison/TripComparisonListItem';
import Trip from './Trip/Trip';
import styles from './TripComparisonsSelectorRoute.module.css';

interface ITripComparisonsList {
    trips: TripComparisonListItem[];
    total: number;
}

interface OptionType {
    value: string;
    label: string;
}

export const TripComparisonsSelectorRoute = withRouter(() => {
    const history = useHistory();

    const elementsOnPage = 10;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [tripsByDate, setTripsByDate] = React.useState<OptionType[] | undefined>(undefined);
    const [selectedTripByDate, setSelectedTripByDate] = React.useState<string>('');

    const [analyzedTrips, setAnalyzedTripsTrips] = React.useState<ITripComparisonsList | undefined>(undefined);
    const [numPage, setNumPage] = React.useState(1);

    React.useEffect(() => {
        getTripComparisons(numPage, elementsOnPage);
    }, [numPage])

    const getTripComparisons = async (page: number, size: number) => {
        await DeliveryApi.getTripComparisons(size, (page - 1) * size)
            .then((response) => {
                setAnalyzedTripsTrips({
                    trips: response.trips,
                    total: response.total
                });
            }).catch(error => {
                alert(String(error));
            });
    }

    const handleSelectDate = (value: Moment | null) => {
        setSelectedDate(value?.toDate());
    }

    const handleButtonClick = async () => {
        if (selectedDate) {
            setLoading(true);
            try {
                const response = await DeliveryApi.getTripsOnDate(selectedDate);
                setTripsByDate(response.map(t => ({value: t.id.toString(), label: t.name})));
            } catch (e) {
                alert(e)
            }
            setLoading(false);
        }
    }

    const handleChangeLocation = (id: string) => {
        history.push(`/trip-comparisons/${id}/place-select`)
    }

    const handleSelectOption = (value: SelectValue) => {
        setSelectedTripByDate(value.toString());
    }

    return (
        <Page header="Сравнение маршрутов">
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.dateSelector}>
                        <ConfigProvider locale={ru_RU}>
                            <DatePicker onChange={handleSelectDate}/>
                        </ConfigProvider>
                        <Button
                            type='primary'
                            onClick={handleButtonClick}
                            disabled={!selectedDate || loading}
                        >
                            Получить маршруты
                        </Button>
                    </div>
                    {loading && <Spin spinning={loading}/>}
                    {!loading &&
                        <div className={styles.tripsByDate}>
                            {tripsByDate === undefined &&
                                <div className={styles.noTrips}>
                                    Дата не выбрана
                                </div>
                            }
                            {tripsByDate?.length === 0 &&
                                <div className={styles.noTrips}>
                                    Нет маршрутов на выбранную дату
                                </div>
                            }
                            {tripsByDate && tripsByDate?.length > 0 &&
                                <div>
                                    <Select
                                        className={styles.select}
                                        placeholder='Выберите маршрут'
                                        options={tripsByDate}
                                        onSelect={handleSelectOption}
                                    />
                                    <Button
                                        type='primary'
                                        disabled={!selectedTripByDate}
                                        onClick={() => handleChangeLocation(selectedTripByDate)}
                                    >
                                        Перейти
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                    <div className={styles.analyzedTrips}>
                        <Pagination
                            showSizeChanger={false}
                            current={numPage}
                            pageSize={elementsOnPage}
                            total={analyzedTrips?.total}
                            onChange={setNumPage}
                            className={styles.pagination}
                        />
                        {analyzedTrips?.trips.map(t =>
                            <Trip
                                key={t.id}
                                name={t.name}
                                date={t.date}
                                onClick={() => handleChangeLocation(t.tripId.toString())}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Page>
    );
});
