export class Types {
    public static assertUnreachable(value: never): never {
        return undefined as never;
    }

    public static trustDefined<T>(value: T | undefined, message?: string): T {
        if (value === undefined) {
            const baseMessage = 'Unexpected undefined';
            const fullMessage = message === undefined ? `${baseMessage}: ${message}.` : `${baseMessage}.`;

            throw new Error(fullMessage);
        }

        return value;
    }
}
