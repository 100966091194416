import React from 'react'
import { ThunderboltOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { RoutePoint } from '../../Map/Route/RoutePoint/RoutePoint';
import { Geocode } from '../../Model/Geocode/Geocode';
import { TripPointType } from '../../Model/TripPointId/TripPointType';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { ByIndexColorPicker } from '../../Std/ColorScheme/ByIndexColorPicker';
import { formatTime } from '../../Std/formatTime';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import { MapColor } from '../Map/MapColor';
import { MapContext } from '../MapContainer/MapContextProvider';
import MapControl from '../MapContainer/MapControl';
import { Marker } from '../MapContainer/model/Marker';
import { Path } from '../MapContainer/model/Path';
import OrderInfo2 from '../OrderInfo/OrderInfo2';
import MapContent from '../MapContainer/MapContent';
import { PlanStore } from '../../Store/PlanStore/PlanStore';
import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import './TripsOnMap.css'

interface TripsOnMapProps {
    planStore: PlanStore;
    colorScheme: ByIndexColorPicker<MapColor>;
    onEditPlace: () => void;
}

enum PathType {
    Line,
    Polyline
}

export const TripsOnMap = observer((props: TripsOnMapProps) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const [pathType, setPathType] = React.useState<PathType>(PathType.Polyline);

    const getHexColor = React.useCallback((index: number) => {
        const color = props.colorScheme.get(index);
        const stringColor = props.colorScheme.renderColor(color, true);

        return stringColor.rgbaString;
    }, [])

    const isStorePoint = React.useCallback((point: RoutePoint) => (
        point.type === TripPointType.Store || point.type === TripPointType.LoadingStore
    ), [])

    const createMarkers = (points: ReadonlyArray<RoutePoint>, color: string, tripId: number, isMainMarkers: boolean) => (
        ArrayUtils.defined(points.map((point, index) => {
            if (isStorePoint(point)) {
                return undefined;
            }

            const markersClassname = isMainMarkers ? "trips-map__marker" : "trips-map__micro-marker";
            const markerContent = isMainMarkers ? String(index + 1) : " ";

            let bgcolor = "";

            if (index === props.planStore.selectedTripItemIndex && isMainMarkers) {
                bgcolor = "#fffc66";
            }

            if (isMainMarkers && point instanceof OrderRoutePoint && point.order._isAdded) {
                bgcolor = "#ffd591";
            }

            const marker: Marker = new Marker(
                point.geocode.latitude,
                point.geocode.longitude,
                isMainMarkers ? markerContent : " ",
                true,
                {
                    text:
                        `${point.expectedArrivalDateTime === undefined
                            ? ''
                            : `<b>${formatTime(point.expectedArrivalDateTime)}</b><br/>`
                        }
                                    ${point.desiredDateTimePeriod === undefined
                            ? ''
                            : formatTimePeriod(point.desiredDateTimePeriod.getTimePeriod())
                        }`
                },
                `border-color: ${color}; 
                background: ${bgcolor};
                color: ${!point.isOwn && "red"};`,
                markersClassname,
            )

            marker.onClick(() => props.planStore.updateSelectedTripItemIndex(tripId, index));

            return marker;
        }))
    )

    const createLinesPath = (points: Geocode[], color?: string, isSelectedPath?: boolean) => {
        const lines: Path[] = [];

        for (let i = 0; i < points.length; ++i) {
            const classname = i === props.planStore.selectedTripItemIndex ? "trip-on-map__path_bold" : "trip-on-map__path_default";

            if (i + 1 < points.length) {
                const line: Path = new Path(
                    [
                        { latitude: points[i].latitude, longitude: points[i].longitude },
                        { latitude: points[i + 1].latitude, longitude: points[i + 1].longitude }
                    ],
                    classname,
                    color
                );

                lines.push(line);
            }
        }

        return lines;
    }

    const createPolylinePath = (points: Geocode[], color?: string, isSelectedPath?: boolean) => {
        const classname = isSelectedPath ? "trip-on-map__path_bold" : "trip-on-map__path_default";

        return [new Path(points, classname, color)];
    }

    React.useEffect(() => {
        const trips = ArrayUtils.defined(props.planStore.trips.map((trip) => trip.isRouteSet() ? trip : undefined));

        let allMarkers: Marker[] = [];
        let allPaths: Path[] = [];

        for (let i = 0; i < trips.length; ++i) {
            const colorTrip = getHexColor(i);

            const route = trips[i].route;

            const isMainMarkersGroup = i === props.planStore.selectedTripIndex || props.planStore.selectedTripIndex === undefined;

            const markers = createMarkers(route.routePoints, colorTrip, trips[i].id, isMainMarkersGroup);

            allMarkers = [...allMarkers, ...markers];

            if (!isMainMarkersGroup) {
                continue;
            }

            if (pathType === PathType.Line) {
                const points = route.points.map(p => p.geocode);
                const linePath = createLinesPath(points, colorTrip);
                allPaths = [...allPaths, ...linePath];
            }

            if (pathType === PathType.Polyline) {
                for (let j = 0; j < route.legs.length; ++j) {
                    const waypoints = route.legs[j]?.waypoints;

                    if (waypoints) {
                        const cleanWaypoints = ArrayUtils.defined(waypoints);

                        const detailedPath = createPolylinePath(
                            cleanWaypoints, colorTrip, j === props.planStore.selectedTripItemIndex ? true : undefined
                        );

                        allPaths = [...allPaths, ...detailedPath];
                    }
                }
            }
        }

        layoutContext.renderMarkers(allMarkers);
        layoutContext.renderPath(allPaths);
    }, [props.planStore.trips, props.planStore.selectedTripIndex, props.planStore.selectedTripItemIndex, pathType])

    return (
        <>
            <MapControl position='topleft' separate={false}>
                <a onClick={() => setPathType(pathType === PathType.Line ? PathType.Polyline : PathType.Line)} style={{ fontSize: '17px' }}>
                    <ThunderboltOutlined />
                </a>
            </MapControl>
            <MapContent position='topright'>
                <OrderInfo2
                    planStore={props.planStore}
                    onEditPlace={props.onEditPlace}
                />
            </MapContent>
        </>
    );
})
