import React from 'react';
import styles from './CellBar.module.css'

interface CellBarProps {
    widthPercentage: number;
    title: string;
}

const CellBar = (props: CellBarProps) => {
    const a = 1;

    return (
        <div
            className={styles.cellBar}
            style={{width: `${props.widthPercentage}%`}}
        >
            <span className={styles.title}>
                {props.title}
            </span>
        </div>
    );
};

export default CellBar;