import { makeAutoObservable } from "mobx";
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { DistrictInterval } from "./DistrictInterval";
import { DistrictStrictInterval } from "./DistrictStrictInterval";

export class DistrictStrictIntervalStore {
  public entities: DistrictStrictInterval[] = [];
  public onEntityDeleteActions: ((id: number) => void)[];
  public onEntityChangeIdActions: ((oldId: number, newId: number) => void)[];
  public onEntityNameChangeActions: ((id: number) => void)[];
  public onIntervalsChangeActions: ((entity: DistrictStrictInterval) => void)[];
  public selectedEntityId: number | undefined = undefined;
  public selectedEntity: DistrictStrictInterval | undefined = undefined;

  public constructor() {
    makeAutoObservable(this);
    this.onEntityDeleteActions = [];
    this.onEntityChangeIdActions = [];
    this.onEntityNameChangeActions = [];
    this.onIntervalsChangeActions = [];
  }

  public setSelectedEntityId(id: number | undefined) {
    this.selectedEntityId = this.selectedEntityId === id ? undefined : id;
    this.selectedEntity = this.entities.find(entity => entity.id === this.selectedEntityId);
  }

  public addEntities = (arr: DistrictStrictInterval[]) => {
    this.entities = this.entities.concat(arr);
  }

  public addEntity = (id: number, coords: IGeocode[], name: string, color: string, intervals: DistrictInterval[]): void => {
    this.entities.push(new DistrictStrictInterval(id, coords, name, color, intervals));
  }

  public editEntity = (id: number, newCoords: IGeocode[]): void => {
    const index = this.entities.findIndex(district => district.id === id);
    this.entities[index].coords = newCoords;
  }

  public removeEntity = (id: number): void => {
    this.entities.splice(this.entities.findIndex(district => district.id === id), 1);

    this.onEntityDeleteActions.forEach((a) => a(id));
  }

  public onEntityRemove = (action: (id: number) => void): void => {
    this.onEntityDeleteActions.push(action);
  }

  public changeEntityId = (district: DistrictStrictInterval, newId: number) => {
    const oldId = district.id;
    district.id = newId;
    this.onEntityChangeIdActions.forEach(a => a(oldId, newId))
  }

  public onEntityChangeId = (action: (oldId: number, newId: number) => void): void => {
    this.onEntityChangeIdActions.push(action);
  }

  public removeInterval = (district: DistrictStrictInterval, interval: DistrictInterval) => {
    district.intervals.splice(district.intervals.findIndex(intrvl => intrvl === interval), 1);

    this.onIntervalsChangeActions.forEach((a) => a(district));
  }

  public addInterval = (district: DistrictStrictInterval, interval: DistrictInterval) => {
    district.intervals = [...district.intervals, interval];

    this.onIntervalsChangeActions.forEach((a) => a(district));
  }

  public onIntervalsChange = (action: (district: DistrictStrictInterval) => void): void => {
    this.onIntervalsChangeActions.push(action);
  }
}