import { Group } from '../../../../ApiClient/YosoCompany/models';
import { DraftApiDistrict } from '../../../../ApiClient/YosoDraft/models';
import { District } from '../../../../Model/District/District';
import { DraftDriver } from '../../../../Model/DraftDriver/DraftDriver';
import {
    DistrictGroupCode
} from '../../../../Store/District/DistrictGroupCode';

export class DistrictMapper {
    public static deserialize(apiDistrict: DraftApiDistrict, drivers: DraftDriver[]): District {
        const district = new District(
            apiDistrict.name,
            apiDistrict.polygonPoints.map((geocode) => ({
                latitude: geocode.latitude!,
                longitude: geocode.longitude!
            })),
            apiDistrict.color!,
            DistrictMapper.deserializeDistrictGroup(apiDistrict.group));

        return district
            .setId(apiDistrict.id)
            .setCityDistrict(apiDistrict.cityDistrict)
            .setDrivers(drivers);
    }

    private static deserializeDistrictGroup(group: Group): DistrictGroupCode {
        switch(group) {
            case Group.MORNING:
                return DistrictGroupCode.Morning;
            case Group.EVENING:
                return DistrictGroupCode.Evening;
            case Group.DAYOFF:
                return DistrictGroupCode.DayOff;
            default:
                throw new Error(`Unknown district group ${group}`)
        }
    }
}