import * as React from 'react';
import { RoutePoint } from '../../../Map/Route/RoutePoint/RoutePoint';
import {FormattedTime} from '../RouteInfoFormattedValues/FormattedTime';
import '../RouteInfo.css';

export interface TerminalRoutePointContentProps {
    point: RoutePoint;
}

export const TerminalRoutePointContent = (props: TerminalRoutePointContentProps): JSX.Element => {
    const { point } = props;

    return (
        <>
            <div className="route-info__address">{point.address}</div>
            <div>
                <FormattedTime date={point.expectedArrivalDateTime} />
            </div>
        </>
    );
};
