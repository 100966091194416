import { ApiPlace } from '../../../../../ApiClient/Yoso/models';
import { Place } from '../../../../../Model/Place/Place';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

import { geocodeQualitySerializer } from './GeocodeQualitySerializer';
import { geocodeSourceSerializer } from './GeocodeSourceSerializer';

export class PlaceSerializer implements IApiModelSerializer<Place, ApiPlace> {
    public serialize(obj: Place): ApiPlace {
        return {
            address: obj.address,
            fullAddress: obj.fullAddress,
            geocodeQuality: geocodeQualitySerializer.serialize(obj.geocodeQuality),
            geocodeSource: geocodeSourceSerializer.serialize(obj.geocodeSource),
            latitude: obj.latitude,
            longitude: obj.longitude,
            addressId: obj.addressId,
        };
    }
}

export const placeSerializer = new PlaceSerializer();
