import React from 'react';
import { observer } from 'mobx-react';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import {
    CityDistrict,
    DistrictStore,
} from '../../../../Store/District/DistrictStore';
import DriverListItem from './DriverListItem';

interface DriverGroupProps {
    districtStore: DistrictStore;
    cityDistrict: CityDistrict;
}

const DriverGroup = observer((props: DriverGroupProps) => {
    const { districtStore, cityDistrict } = props;

    return (
        <div key={cityDistrict.name}>
            <div
                className={`district-list__city-district ${districtStore.selectedCityDistricts.some(i => i === cityDistrict.name)
                    ? 'district-list__city-district_selected' : ''}`}
                onClick={() => districtStore.toggleSelectedCityDistricts(cityDistrict.name)}
            >
                {cityDistrict.name}
            </div>
            {districtStore.selectedCityDistricts.some(d => d === cityDistrict.name) &&
                <div>
                    {ArrayUtils.sort(districtStore.districtDrivers, (d) => d.name)
                        .filter(d => d.districts.some(i => i.cityDistrictName && i.cityDistrictName === cityDistrict.name))
                        .map((d) => (
                            <DriverListItem key={d.name} districtStore={districtStore} driver={d} />
                        )
                    )}
                </div>
            }
        </div>
    );
});

export default DriverGroup;