import React from 'react';
import L, {LatLngBoundsLiteral} from 'leaflet';
import { Modal, Spin } from 'antd';
import {getLeafletBoundsFromIGeocodes} from "../../../../Std/LeafletUtils";
import { useCompany } from "../../../AppProviders/Providers/CompanyProvider";
import { IGeocode } from '../../../../Model/Geocode/IGeocode';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import ZoneMapEditor from '../../../ZoneMapEditor/ZoneMapEditor';
import './ServiceZone.css'
import MapContainer from '../../../MapContainer/MapContainer';

const ServiceZone = (): JSX.Element => {
    const companyContext = useCompany();
    const { company } = companyContext;

    const zoomLevel = 11;

    const [center, setCenter] = React.useState<L.LatLngExpression | undefined>(undefined);
    const [mapBounds, setMapBounds] = React.useState<LatLngBoundsLiteral>([]);
    const [zone, setZone] = React.useState<IGeocode[]>([]);
    const [pageLoaded, setPageLoaded] = React.useState(false);

    React.useEffect(() => {
        if (!company) {
            return undefined;
        }

        setCenter({
            lat: company.centerLatitude,
            lng: company.centerLongitude
        });
    }, [company]);

    React.useEffect(() => {
        if (!company) {
            return undefined;
        }

        const loadServiceZone = async () => {
            CompanyApi.getServiceZone().then((apiGeocodes: IGeocode[]) => {
                setZone(apiGeocodes);
                setMapBounds(getLeafletBoundsFromIGeocodes(apiGeocodes));
                setPageLoaded(true);
            });
        }

        loadServiceZone();
    }, [company]);

    const onZoneSave = React.useCallback((): Promise<void> =>
        CompanyApi.postServiceZone(zone)
            .then(() => {
                Modal.success({
                    content: 'Изменения сохранены',
                });
            })
            .catch((e) => {
                alert(String(e))
            }),
        [zone]);

    const onZoneDelete = React.useCallback((): Promise<void> => 
        CompanyApi.deleteServiceZone()
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                alert(String(e))
            }),
        []);

    return (
        <Spin spinning={!pageLoaded}>
            <div className="zone__page">
                {center && pageLoaded && (
                    <MapContainer center={center} bounds={mapBounds.length === 0 ? undefined : mapBounds} zoomValue={zoomLevel}>
                        <ZoneMapEditor
                            zone={zone}
                            setZone={setZone}
                            onZoneSave={onZoneSave}
                            onZoneDelete={onZoneDelete}
                        />
                    </MapContainer>
                )}
            </div>
        </Spin>
    )
}

export default ServiceZone;