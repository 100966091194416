import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { LunchRoutePoint } from '../../../Map/Route/RoutePoint/LunchRoutePoint';
import { ReactComponent as DragHandleIconComponent } from '../../../icons/drag-handle.svg';
import { ReactComponent as SmallOkIconComponent } from '../../../icons/small-ok.svg';
import {FormattedTime} from '../RouteInfoFormattedValues/FormattedTime';
import { FormattedTimePeriod } from '../RouteInfoFormattedValues/FormattedTimePeriod';


interface RouteInfoLunchPointProps {
    point: LunchRoutePoint;
    dragHandleProps: DraggableProvidedDragHandleProps;
}

export const RouteInfoLunchPoint = (props: RouteInfoLunchPointProps) => {
    const { point } = props;

    return (
        <>
            <div className="route-info-block__marker_route-point">
                <div className="route-info-block__wrapper-circle">
                    <div className="route-info-block__inner-circle route-info-block__inner-circle_done">
                        <SmallOkIconComponent className="route-info-block__inner-icon_ok" />
                    </div>
                </div>
                <div className="route-info-block__distance">
                    {/* {point.expectedDistanceTo && renderDistance(point.expectedDistanceTo)} */}
                </div>
            </div>
            <div {...props.dragHandleProps} className="route-info-block__drag">
                <DragHandleIconComponent />
            </div>
            <div className="route-info-block__content">
                <div>
                    <div>{point.address}</div>
                    <div>
                        <FormattedTime date={point.lunch.expectedArrivalDateTime} />
                        <FormattedTimePeriod dateTimePeriod={point.lunch.desiredDateTimePeriod.getTimePeriod()} />
                    </div>
                </div>
            </div>
        </>
    )
}