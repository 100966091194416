import React from 'react';
import { WarningOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { DraftApi } from '../../Backend/Api/Draft/DraftApi';
import { Driver } from '../../Model/Driver/Driver';
import './AddOrdersBlock.css';

export interface IAddOrdersBlockProps extends RouteComponentProps {
    className?: string;
    href1s: string;
    hrefExcel: string;
    planId?: number
}

export const AddOrdersBlock = withRouter(React.memo((props: IAddOrdersBlockProps) => {
    const [additionalDrivers, setAdditionalDrivers] = React.useState<Driver[]>([]);

    const fetchAdditionalDriver = () => {
        if (!props.planId) {
            return
        }
        DraftApi.getAdditionalDrivers(props.planId)
            .then(setAdditionalDrivers);
    }

    React.useEffect(() => {
        fetchAdditionalDriver()
        const interval = setInterval(
            fetchAdditionalDriver,
            60000); //eslint-disable-line

        return () => clearInterval(interval)
    }, [props.planId]);

    return (
        <div className={props.className}>
            <Link to={props.href1s}>
                <Button type="primary">
                    {additionalDrivers.length > 0 &&
                        <WarningOutlined
                            title="Необходимо добавить заказы через кнопку «1С»"
                            className='add-orders-block__warning'
                        />
                    }
                    Добавить заказы 1С
                </Button>
            </Link>
        </div>
    )
}));
