import Icon from '@ant-design/icons';
import classNames from 'classnames';
import * as React from 'react';

import { productOperationTypeFormatter } from '../../../Formatter/ProductOperationTypeFormatter';
import { ReactComponent as BottleIconComponent } from '../../../icons/bottle-product.svg';
import { ReactComponent as ReturnIconComponent } from '../../../icons/return-operation.svg';
import { ProductOperation } from '../../../Model/ProductOperation/ProductOperation';
import { ProductOperationType } from '../../../Model/ProductOperation/ProductOperationType';

import './ProductOperationComponent.css';

export interface IProductOperationProps {
    className?: string;
    productOperation: ProductOperation;
}

export const ProductOperationComponent = (props: IProductOperationProps): JSX.Element => {
    const icon =
        props.productOperation.type === ProductOperationType.Shipment ? (
            <Icon className="product-operation__icon product-operation__icon_bottle" component={BottleIconComponent} />
        ) : props.productOperation.type === ProductOperationType.Return ? (
            <Icon className="product-operation__icon product-operation__icon_return" component={ReturnIconComponent} />
        ) : (
            '???'
        );

    const title = `${productOperationTypeFormatter.default(props.productOperation.type)}: ${
        props.productOperation.product.name
    }`;

    return (
        <div className={classNames('product-operation', props.className)} title={title}>
            {icon} x{props.productOperation.estimatedCount}
        </div>
    );
};
