import { ArrayUtils } from '../../Std/ArrayUtils';
import { MathUtils } from '../../Std/MathUtils';
import { ProductOperation } from '../ProductOperation/ProductOperation';
import { ProductOperationType } from '../ProductOperation/ProductOperationType';

export class OrderOperations {
    public readonly adjustmentCount: number;
    public readonly productOperations: ReadonlyArray<ProductOperation>;
    public readonly shipmentProductCount: number;

    private readonly productShipmentOperations: ReadonlyArray<ProductOperation>;

    public constructor(
        productOperations: ReadonlyArray<ProductOperation>
    ) {
        this.productOperations = productOperations;

        this.productShipmentOperations = productOperations.filter(
            (productOperation) => productOperation.type === ProductOperationType.Shipment,
        );

        this.adjustmentCount = ArrayUtils.count(
            this.productOperations,
            (productOperation) =>
                productOperation.actualCount !== undefined &&
                productOperation.actualCount !== productOperation.estimatedCount,
        );

        this.shipmentProductCount = MathUtils.sum(
            this.productShipmentOperations.map((productOperation) => productOperation.estimatedCount),
        );
    }
}
