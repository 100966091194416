import { useEffect, useState } from 'react';
import { CompanyApi } from '../Backend/Api/Company/CompanyApi';
import { Warehouse } from '../Model/Store/Warehouse';

export const useLoadingWarehouses = () => {
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        CompanyApi.getStoreList()
            .then(setWarehouses)
            .finally(() => setLoading(false));
    }, []);

    return {
        warehouses,
        loading,
    };
};
