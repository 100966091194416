import { Typography } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import { setWindowTitle } from '../../Std/SetWindowTitle';

import './Page.css';

export interface IPageProps {
    children: React.ReactNode;
    className?: string;
    header: string;
}

export const Page = (props: IPageProps): JSX.Element => {
    React.useEffect((): void => {
        setWindowTitle(props.header);
    }, [props.header]);

    return (
        <div className={classNames('page', props.className)}>
            <Typography.Title className="page__title" level={1}>
                {props.header}
            </Typography.Title>
            <div className="page__content">
                <div className="page__content-wrapper">{props.children}</div>
            </div>
        </div>
    );
};
