import {
    DeliveryApiCar,
    DeliveryApiDriver,
    DeliveryApiOrder,
    DeliveryApiProduct,
} from '../../../../../ApiClient/Yoso/models';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { Order } from '../../../../../Model/Order/Order';
import { OrderTransfer } from '../../../../../Model/Order/OrderTransfer';
import { OrderOperations } from '../../../../../Model/OrderOperations/OrderOperations';
import { ProductOperation } from '../../../../../Model/ProductOperation/ProductOperation';
import { TripWorkingShift } from '../../../../../Model/Trip/TripWorkingShift';
import { TripExecutor } from '../../../../../Model/TripExecutor/TripExecutor';
import { Types } from '../../../../../Std/Types';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import { carDeserializer } from '../Car/CarDeserializer';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { driverDeserializer } from '../Driver/DriverDeserializer';
import { orderPaidMethodDeserializer } from '../OrderPaidMethod/OrderPaidMethodDeserializer';
import { orderStatusDeserializer } from '../OrderStatus/OrderStatusDeserializer';
import { placeDeserializer } from '../Place/PlaceDeserializer';
import { productOperationDealTypeDeserializer } from '../ProducOperationDealType/ProductOperationDealTypeDeserializer';
import { productOperationTypeDeserializer } from '../ProductOperationType/ProductOperationTypeDeserializer';

export class OrderDeserializer {
    public deserialize(
        serializedObject: DeliveryApiOrder,
        cars: ReadonlyArray<DeliveryApiCar>,
        carId: number,
        drivers: ReadonlyArray<DeliveryApiDriver>,
        driverId: number,
        products: ReadonlyArray<DeliveryApiProduct>,
        workingShift: TripWorkingShift | undefined
    ): Order {
        let executorCar: DeliveryApiCar | undefined;
        let executorDriver: DeliveryApiDriver | undefined;
        if (carId !== undefined && driverId !== undefined) {
            executorCar = cars.find((car) => car.id === carId);
            if (executorCar === undefined) {
                throw new Error(`Unknown car id: ${carId}`);
            }
            executorDriver = drivers.find((driver: DeliveryApiDriver) => driver.id === driverId);
            if (executorDriver === undefined) {
                throw new Error(`Unknown driver id: ${driverId}`);
            }
        }

        return new Order(
            serializedObject.id,
            serializedObject.code,
            placeDeserializer.deserialize(serializedObject.place),
            new OrderOperations(
                serializedObject.productOperations.map(
                    (productOperation) =>
                        new ProductOperation(
                            productOperationTypeDeserializer.deserializeFromString(productOperation.type),
                            Types.trustDefined(products.find((product) => product.id === productOperation.productId)),
                            productOperation.estimatedCount,
                            productOperation.actualCount,
                            productOperation.price,
                            productOperation.adjustedPrice,
                            productOperationDealTypeDeserializer.deserializeFromString(productOperation.dealType),
                        ),
                ),
            ),
            serializedObject.comment,
            serializedObject.cancellationReason,
            serializedObject.cancellationReasonComment,
            dateTimePeriodDeserializer.deserialize(serializedObject.dateTimePeriod),
            serializedObject.rescheduledDateTimePeriod === undefined
                ? undefined
                : dateTimePeriodDeserializer.deserialize(serializedObject.rescheduledDateTimePeriod),
            serializedObject.expectedArrivalDateTime === undefined
                ? undefined
                : dateTimeDeserializer.deserialize(serializedObject.expectedArrivalDateTime),
            serializedObject.actualArrivalDateTime === undefined
                ? undefined
                : dateTimeDeserializer.deserialize(serializedObject.actualArrivalDateTime),
            executorCar !== undefined && executorDriver !== undefined
                ? new TripExecutor(
                      carDeserializer.deserialize(executorCar),
                      driverDeserializer.deserialize(executorDriver),
                      workingShift
                  )
                : undefined,
            serializedObject.phoneNumber,
            serializedObject.clientName,
            serializedObject.fullAddress,
            orderStatusDeserializer.deserialize(serializedObject.status),
            serializedObject.price,
            serializedObject.paid,
            serializedObject.paidMethod === undefined
                ? undefined
                : orderPaidMethodDeserializer.deserialize(serializedObject.paidMethod),
            serializedObject.deferred === undefined ? false : serializedObject.deferred,
            serializedObject.transfer !== undefined ? new OrderTransfer(
                serializedObject.transfer.toDriverId,
                serializedObject.transfer.toDriverName) : undefined,
            serializedObject.shipmentCount,
            serializedObject.totalShipmentCount,
            !!serializedObject.isAdded,
            serializedObject.driverPlace ? new Geocode({
                latitude: serializedObject.driverPlace.latitude,
                longitude: serializedObject.driverPlace.longitude
            }) : undefined,
            serializedObject.paymentMethod,
            serializedObject.isInternal
        );
    }
}

export const orderDeserializer = new OrderDeserializer();
