import L, { LayerGroup } from 'leaflet';
import classNames from 'classnames';
import { ClassDictionary } from 'classnames/types';
// eslint-disable-next-line import/no-unassigned-import
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { isEqualsByPrecision } from '../../helpers/compareNumbers/isEqualsByPrecision';
import { Dictionary } from '../../helpers/Dictionary/Dictionary';
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { Path } from "./model/Path";
import { Marker } from "./model/Marker";
import { DrawInterface } from "./DrawInterface";
import { Polygon } from "./model/Polygon";

export class LeafletDrawContext implements DrawInterface {
    private readonly mapContainer: any;
    private readonly markersLayer: LayerGroup;
    private readonly pathLayer: LayerGroup;
    private readonly polygonsLayer: LayerGroup;
    private readonly markersClusterLayer: LayerGroup;
    private readonly mapMarkers = Dictionary();
    private readonly mapMarkerPaths = Dictionary();

    public constructor(
        mapContainer: any,
    ) {
        this.mapContainer = mapContainer;
        this.markersLayer = L.layerGroup().addTo(this.mapContainer);
        this.pathLayer = L.layerGroup().addTo(this.mapContainer);
        this.polygonsLayer = L.layerGroup().addTo(this.mapContainer);
        this.markersClusterLayer = L.layerGroup().addTo(this.mapContainer);
    }

    public renderMarkers(markers: Marker[]): void {
        this.markersLayer.clearLayers();
        this.mapMarkers.clear();

        const leafletMarkers : L.Marker[] = [];
        markers.forEach(marker => {
            const leafletMarker = this.createMarker(marker);
            this.mapMarkers.put(marker, leafletMarker)
            leafletMarker.addTo(this.markersLayer);
            leafletMarkers.push(leafletMarker);
        })
    }

    public renderClusterMarkers(markers: Marker[], disableClusteringAtZoom? : number): void {
        this.markersLayer.clearLayers();

        const clusters = L.markerClusterGroup({pmIgnore: true, disableClusteringAtZoom: disableClusteringAtZoom});
        markers.forEach(marker => {
            clusters.addLayer(this.createMarker(marker));
        })

        clusters.addTo(this.markersClusterLayer);
    }

    public renderPath(path: Path[]): void {
        this.pathLayer.clearLayers();
        this.mapMarkerPaths.clear();

        path.map(line => {
            const latlngs = this.geocodeToLatLngExpression(line.geocodes);

            const leafletPolyline = L.polyline(latlngs, { className: line.className, color: line.colorStyle }).addTo(this.pathLayer);
            this.mapMarkerPaths.put(line, leafletPolyline);
            leafletPolyline.on('click', () => {
                for (const handler of line.getOnClickHandlers()) {
                    handler();
                }
            });
        })
    }

    public renderPolygons(arr: ReadonlyArray<Polygon>): void {
        this.polygonsLayer.clearLayers();

        arr.map(polygon => {
            const geoArr: IGeocode[] = polygon.geocodes.map((geocode) => ({ latitude: geocode.latitude, longitude: geocode.longitude }));
            const latlngs = this.geocodeToLatLngExpression(geoArr);
            const polygonToMap = L.polygon(latlngs, { color: polygon.color, weight: 1 }).addTo(this.polygonsLayer);
            if (polygon.name) {
                polygonToMap.bindTooltip(polygon.name, {permanent: true});
            }
            polygonToMap.addTo(this.polygonsLayer);
        })
    }

    public clear(): void {
        this.markersLayer.clearLayers();
        this.pathLayer.clearLayers();
        this.polygonsLayer.clearLayers();
        this.markersClusterLayer.clearLayers();
    }

    public getMarkersBoundingBox(markers: Marker[]): DOMRect[] {
        const boundingBoxes: DOMRect[] = [];

        for (const marker of markers) {
            const leafletMarker = this.mapMarkers.getValue(marker) as L.Marker;
            const boundingBox = leafletMarker.getElement()!.getBoundingClientRect();
            boundingBoxes.push(boundingBox);
        }

        return boundingBoxes;
    }

    public updatePaths(pathsToUpdate: Path[]): void {
        for (const path of pathsToUpdate) {
            const leafletPath = this.mapMarkerPaths.getValue(path) as L.Polyline
            leafletPath.setLatLngs(this.geocodeToLatLngExpression(path.geocodes))
        }
    }

    public updateMarkers(markersToUpdate: Marker[]): void {
         for (const markerToUpdate of markersToUpdate) {
             const leafletMarker = this.mapMarkers.getValue(markerToUpdate) as L.Marker;
             if (leafletMarker) {
                 this.setIcon(leafletMarker, markerToUpdate.content, markerToUpdate.className, markerToUpdate.style)

                 const equalByPrecision = 6;
                 const compareLat = isEqualsByPrecision(leafletMarker.getLatLng().lat, markerToUpdate.latitude, equalByPrecision);
                 const compareLng = isEqualsByPrecision(leafletMarker.getLatLng().lng, markerToUpdate.longitude, equalByPrecision);
                 if (compareLat && compareLng) {
                     continue;
                 }
                 leafletMarker.setLatLng([markerToUpdate.latitude, markerToUpdate.longitude])
             }
         }
    }

    private geocodeToLatLngExpression(arr: IGeocode[]): L.LatLngExpression[] {
        return arr.map((coord) => ({lat: coord.latitude, lng: coord.longitude}));
    }

    private createMarker(marker: Marker): L.Marker {
        const leafletMarker: L.Marker = L.marker([marker.latitude, marker.longitude], {
            interactive: marker.clickable, zIndexOffset: marker.zIndex, draggable: marker.isDraggable
        });

        if (marker.tooltip !== undefined) {
            leafletMarker.bindTooltip(marker.tooltip.text, {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                offset: [15, 0],
                direction: 'right',
                permanent: marker.tooltip.isPermanent === undefined ? false : marker.tooltip.isPermanent,
                className: marker.tooltip.className,
                interactive: marker.tooltip.isInteractive,
            });
        }

        if (marker.zIndex !== undefined) {
            leafletMarker.setZIndexOffset(marker.zIndex);
        }

        this.setIcon(leafletMarker, marker.content, marker.className, marker.style);

        leafletMarker
            .on('click', () => {
                for (const handler of marker.getOnClickHandlers()) {
                    handler();
                }
            })
            .on('contextmenu', () => {
                for (const handler of marker.getOnRightClickHandlers()) {
                        handler();
                    }
                })
            .on('drag', (e) => {
                const coords = e.target.getLatLng();
                const sourceMarker = this.mapMarkers.getKey(e.target);
                if (sourceMarker) {
                    for (const handler of marker.getOnDragHandlers()) {
                        handler(
                            sourceMarker as Marker,
                            {latitude: coords.lat, longitude: coords.lng});
                    }
                }
            })
            .on('dragend', (e) => {
                const sourceMarker = this.mapMarkers.getKey(e.target) as Marker;
                for (const handler of marker.getOnDragEndHandlers()) {
                    handler(sourceMarker);
                }
            });

        return leafletMarker;
    }

    private setIcon(
        leafletMarker: L.Marker,
        elementHtml: string | undefined,
        className: string | ClassDictionary,
        style: string | undefined,
    ): void {
        leafletMarker.setIcon(
            L.divIcon({
                className: classNames(className),
                html: `<div style="${style ?? ''}">${elementHtml ? elementHtml : ''}</div>`,
            }),
        );
    }
}