import { Skeleton } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { CompanyApiAdminResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { CompanyAdminEntityEdit } from '../../../CompanyAdminEntityEdit/CompanyAdminEntityEdit';
import { Page } from '../../../Page/Page';

export interface ICompanyAdminEditRouteProps extends RouteComponentProps {
    companyId: number | undefined;
    id: number | undefined;
}

export const CompanyAdminEditRoute = withRouter(
    (props: ICompanyAdminEditRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(props.id !== undefined);
        const [companyAdmin, setCompanyAdmin] = React.useState(undefined as CompanyApiAdminResponse | undefined);

        const fetchData = React.useCallback(
            async (id: number): Promise<undefined> => {
                setLoading(true);
                try {
                    const response =
                        props.companyId === undefined
                            ? await CompanyApi.getAdmin(id)
                            : await CompanyApi.getCompanyAdmin(props.companyId, id);
                    if (destroyed.current) {
                        return;
                    }
                    setCompanyAdmin(response);
                } catch (error) {
                    if (destroyed.current) {
                        return;
                    }
                    alert(String(error));
                }
                setLoading(false);
            },
            [props.companyId],
        );

        React.useEffect((): void => {
            if (props.id !== undefined) {
                fetchData(props.id).then(
                    () => {
                        //
                    },
                    () => {
                        //
                    },
                );
            }
        }, [fetchData, props.id]);

        const handleSaveCompanyAdmin = React.useCallback((): void => {
            const url = props.companyId === undefined ? `/admins/` : `/companies/${props.companyId}`;
            props.history.push(url);
        }, [props.companyId, props.history]);

        return (
            <Page
                header={
                    props.id === undefined
                        ? 'Новый администратор компании'
                        : companyAdmin === undefined
                        ? ''
                        : props.companyId === undefined
                        ? `Администратор ${companyAdmin.employee!.name}`
                        : `Администратор компании «${companyAdmin.employee!.name}»`
                }
            >
                {loading ? (
                    <Skeleton active round title={{ width: 600 }} />
                ) : (
                    <CompanyAdminEntityEdit
                        companyId={props.companyId}
                        companyAdmin={
                            companyAdmin === undefined || props.id === undefined
                                ? undefined
                                : {
                                      companyAdmin: companyAdmin,
                                      id: props.id,
                                  }
                        }
                        onSave={handleSaveCompanyAdmin}
                    />
                )}
            </Page>
        );
    },
);
