import { IGeocode } from "../../../Model/Geocode/IGeocode";

export class Path {
    public geocodes: IGeocode[];
    public readonly className: string | undefined;
    public readonly colorStyle?: string;
    public pointId?: number;
    private readonly onClickHandlers: Array<() => void> = [];

    public constructor(geocodes: IGeocode[], className: string | undefined, colorStyle?: string) {
        this.geocodes = geocodes;
        this.className = className;
        this.colorStyle = colorStyle;
    }

    public onClick(handler: () => void): void {
        this.onClickHandlers.push(handler);
    }

    public getOnClickHandlers(): Array<() => void> {
        return this.onClickHandlers;
    }
}