import { Status, Status2 } from '../../../../../ApiClient/Yoso/models';
import { TripStatus } from '../../../../../Model/Trip/TripStatus';
import { Types } from '../../../../../Std/Types';

export class TripStatusDeserializer {
    public deserialize(serializedObj: Status): TripStatus {
        return serializedObj === Status.DONE
            ? TripStatus.Done
            : serializedObj === Status.INPROGRESS
            ? TripStatus.InProgress
            : serializedObj === Status.DRIVERAPPROVEMENT
            ? TripStatus.DriverApprovement
            : serializedObj === Status.LOGISTAPPROVEMENT
            ? TripStatus.LogistApprovement
            : serializedObj === Status.NEW
            ? TripStatus.New
            : serializedObj === Status.ROUTEBUILDFAILED
            ? TripStatus.RouteBuildFailed
            : Types.assertUnreachable(serializedObj);
    }

    public deserialize2(serializedObj: Status2): TripStatus {
        return serializedObj === Status2.DONE
            ? TripStatus.Done
            : serializedObj === Status2.INPROGRESS
            ? TripStatus.InProgress
            : serializedObj === Status2.DRIVERAPPROVEMENT
            ? TripStatus.DriverApprovement
            : serializedObj === Status2.LOGISTAPPROVEMENT
            ? TripStatus.LogistApprovement
            : serializedObj === Status2.NEW
            ? TripStatus.New
            : serializedObj === Status2.ROUTEBUILDFAILED
            ? TripStatus.RouteBuildFailed
            : Types.assertUnreachable(serializedObj);
    }
}

export const tripStatusDeserializer = new TripStatusDeserializer();
