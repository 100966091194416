import React from "react";
import { useLeafletContext, LeafletContextInterface } from "@react-leaflet/core";
import { EditorOnMapInterface } from "./EditorOnMapInterface";
import { MapInterface } from "./MapInterface";
import { LeafletMap } from "./LeafletMap";
import { GeomanEditor } from "./GeomanEditor";

interface IMapContextProps {
    children?: React.ReactNode;
    containerRef: React.RefObject<HTMLDivElement>;
}

export const MapContext = React.createContext<
    {
        map: MapInterface,
        editorOnMap: EditorOnMapInterface,
        containerRef: React.RefObject<HTMLDivElement>
    } | undefined
>(undefined);

const MapContextProvider = (props: IMapContextProps) => {
    const context: LeafletContextInterface = useLeafletContext();
    const mapContainer: any = context.layerContainer || context.map;

    const mapContext = React.useMemo(() => ({
        map: new LeafletMap(mapContainer),
        editorOnMap: new GeomanEditor(mapContainer),
        containerRef: props.containerRef
    }), [mapContainer]);

    return (
        <MapContext.Provider value={mapContext}>
            {props.children}
        </MapContext.Provider>
    )
}

export default MapContextProvider;