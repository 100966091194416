import { GeocodeQuality as ApiGeocodeQuality } from '../../../../../ApiClient/Yoso/models';
import { GeocodeQuality } from '../../../../../Model/Place/GeocodeQuality';

export class GeocodeQualityDeserializer {
    public deserialize(serializedObject: ApiGeocodeQuality | undefined): GeocodeQuality {
        const result =
            serializedObject === undefined
                ? GeocodeQuality.None
                : serializedObject === ApiGeocodeQuality.JUSTVALIDATED
                ? GeocodeQuality.JustValidated
                : serializedObject === ApiGeocodeQuality.VALIDATED
                ? GeocodeQuality.Validated
                : serializedObject === ApiGeocodeQuality.EXACTLY
                ? GeocodeQuality.Exactly
                : serializedObject === ApiGeocodeQuality.NEAR
                ? GeocodeQuality.Near
                : serializedObject === ApiGeocodeQuality.NONE
                ? GeocodeQuality.None
                : serializedObject === ApiGeocodeQuality.STREET
                ? GeocodeQuality.Street
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown ApiGeocodeQuality '${serializedObject?.toString()}'`);
        }

        return result;
    }
}

export const geocodeQualityDeserializer = new GeocodeQualityDeserializer();
