import { WorkingShift1 } from '../../../../../ApiClient/Yoso/models';
import { TripWorkingShift } from '../../../../../Model/Trip/TripWorkingShift';

export class TripWorkingShiftSerializer {
    public serialize(workingShift: TripWorkingShift): WorkingShift1 {
        switch (workingShift) {
            case TripWorkingShift.MORNING:
                return WorkingShift1.MORNING
            case TripWorkingShift.EVENING:
                return WorkingShift1.EVENING
            case TripWorkingShift.DAYOFF:
                return WorkingShift1.DAYOFF
            default: throw new Error('Unknown working shift');
        }
    }
}

export const tripWorkingShiftSerializer = new TripWorkingShiftSerializer();
