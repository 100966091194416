import classNames from 'classnames';
import * as React from 'react';

import { orderOperationsFormatter } from '../../Formatter/OrderOperationsFormatter';
import { ReactComponent as BottleIconComponent } from '../../icons/bottle-product.svg';
import { ReactComponent as CalendarIconComponent } from '../../icons/calendar.svg';
import { ReactComponent as CommentIconComponent } from '../../icons/comment.svg';
import { ReactComponent as FailedIconComponent } from '../../icons/failed.svg';
import { ReactComponent as OkIconComponent } from '../../icons/ok.svg';
import { ReactComponent as PhoneIconComponent } from '../../icons/phone.svg';
import { ReactComponent as QrCodeIconComponent } from '../../icons/qr-code.svg';
import { ReactComponent as TimeIconComponent } from '../../icons/time.svg';
import { ReactComponent as UserIconComponent } from '../../icons/user.svg';
import { ReactComponent as WalletIconComponent } from '../../icons/wallet.svg';
import { ReactComponent as WarningIconComponent } from '../../icons/warning.svg';
import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import { OrderPaidMethod } from '../../Model/Order/OrderPaidMethod';
import { OrderStatus } from '../../Model/Order/OrderStatus';
import { ProductOperationType } from '../../Model/ProductOperation/ProductOperationType';
import { ProductOperationDealType } from '../../Model/ProductOperation/ProductOperationDealType';
import { formatDateTimeWithImpliedDate } from '../../Std/formatDateTimeWithImpliedDate';
import { formatPrice } from '../../Std/formatPrice';
import { formatTime } from '../../Std/formatTime';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import { formatDateTimePeriodShort } from '../../Std/formatDateTimePeriodShort';
import { EntityList, EntityListColumnAlign } from '../EntityList/EntityList';
import PaymentMethodIcon from '../PaymentMethodIcon/PaymentMethodIcon';
import './OrdersReport.css';

export interface IOrdersReportProps {
    checkpoints: ReadonlyArray<OrderRoutePoint>;
    className?: string;
    onClickOrder?: (checkpoint: OrderRoutePoint) => void;
    selectedIndices?: ReadonlyArray<number> | undefined;
}

const pageSize = 100;

export const OrdersReport = (props: IOrdersReportProps): JSX.Element => {
    const filterCheckpoints = React.useCallback(
        (checkpointsArray: ReadonlyArray<OrderRoutePoint>) =>
            checkpointsArray.filter((checkpoint) => checkpoint.order.isOwn()),
        [],
    );

    const [checkpoints, setCheckpoints] = React.useState(filterCheckpoints(props.checkpoints));

    React.useEffect(() => {
        setCheckpoints(filterCheckpoints(props.checkpoints));
    }, [filterCheckpoints, props.checkpoints]);

    const getSelectedOrders = React.useCallback(() => props.selectedIndices?.map((index) => props.checkpoints[index]), [
        props.selectedIndices,
        props.checkpoints,
    ]);
    const [selectedOrders, setSelectedOrders] = React.useState(getSelectedOrders());
    React.useEffect(() => {
        setSelectedOrders(getSelectedOrders());
    }, [getSelectedOrders]);

    const [ordersFilterSelectItems, setOrdersFilterSelectItems] = React.useState(
        {} as Record<string, { title: string; value: string }[]>,
    );

    React.useEffect(() => {
        setOrdersFilterSelectItems({
            main: [
                {
                    value: 'all',
                    title: 'Все заказы',
                },
                {
                    value: 'deviations-from-plan',
                    title: 'Все отклонения от плана',
                },
                {
                    value: 'adjusted',
                    title: 'Только измененные заказы',
                },
                {
                    value: 'delay',
                    title: 'Только опоздания',
                },
                {
                    value: 'failed',
                    title: 'Только недоставленные заказы',
                },
            ],
        });
    }, [setOrdersFilterSelectItems]);

    const [ordersFilterSelectValues, setOrdersFilterSelectValues] = React.useState({ main: 'all' } as Record<
        string,
        string
    >);

    const handleOrdersFilterSelect = React.useCallback((values: Record<string, string>) => {
        setOrdersFilterSelectValues(values);
    }, []);

    const filterOrders = React.useCallback((order: OrderRoutePoint, selectKey: string, selectValue: string) => {
        if (selectKey !== 'main') {
            throw new Error("SelectKey is not 'main'");
        }

        let result: boolean;

        switch (selectValue) {
            case 'all':
                result = true;
                break;
            case 'deviations-from-plan':
                result = order.hasDeviationsFromPlan();
                break;
            case 'adjusted':
                result = order.hasAdjustments();
                break;
            case 'delay':
                result = order.hasArrivalDelay();
                break;
            case 'failed':
                result = order.isFailed();
                break;
            default:
                throw new Error(`Unknown SelectValue '${selectValue.toString()}'`);
        }

        return result;
    }, []);

    const orderHasDeviationsFromPlan = React.useCallback((order: OrderRoutePoint) => order.hasDeviationsFromPlan(), []);

    return (
        <div className={classNames('orders-report', props.className)}>
            <EntityList<OrderRoutePoint>
                data={checkpoints}
                id={(checkpoint) => checkpoint.originalId}
                actions={{
                    item: {
                        click: (id) => (): undefined => {
                            if (props.onClickOrder !== undefined) {
                                const checkpoint = checkpoints.find(
                                    (checkpointIterator) => checkpointIterator.originalId === id,
                                );
                                if (checkpoint !== undefined) {
                                    props.onClickOrder(checkpoint);
                                }
                            }

                            return;
                        },
                    },
                }}
                filter={{
                    select: {
                        items: ordersFilterSelectItems,
                        values: ordersFilterSelectValues,
                        onChange: handleOrdersFilterSelect,
                        filter: filterOrders,
                    },
                }}
                columns={[
                    {
                        key: 'address',
                        render: (checkpoint) => checkpoint.address,
                        title: 'Адрес',
                        stretch: true,
                    },
                    {
                        key: 'products',
                        render: (checkpoint) => (
                            <span title={orderOperationsFormatter.default(checkpoint.order.operations)}>
                                <BottleIconComponent className="orders-report__bottle-icon" />x
                                {checkpoint.order.operations.shipmentProductCount}
                            </span>
                        ),
                        title: 'Товары',
                        align: EntityListColumnAlign.Center,
                        stretch: true,
                    },
                    {
                        key: 'desiredTimePeriod',
                        render: (checkpoint) =>
                            checkpoint.order.desiredDateTimePeriod === undefined
                                ? '\u2013'
                                : formatTimePeriod(checkpoint.order.desiredDateTimePeriod.getTimePeriod()),
                        title: 'Желаемое время',
                        align: EntityListColumnAlign.Center,
                        stretch: true,
                    },
                    {
                        key: 'time',
                        render: (checkpoint) => (
                            <div>
                                {checkpoint.expectedArrivalDateTime === undefined
                                    ? '\u2013'
                                    : checkpoint.actualArrivalDateTime === undefined
                                    ? formatTime(checkpoint.expectedArrivalDateTime)
                                    : formatDateTimeWithImpliedDate(
                                          checkpoint.expectedArrivalDateTime,
                                          checkpoint.actualArrivalDateTime,
                                      )}{' '}
                                /{' '}
                                <span
                                    className={classNames(
                                        checkpoint.hasArrivalDelay() && 'orders-report__warning-text',
                                    )}
                                >
                                    {checkpoint.actualArrivalDateTime === undefined
                                        ? '\u2013'
                                        : checkpoint.expectedArrivalDateTime === undefined
                                        ? formatTime(checkpoint.actualArrivalDateTime)
                                        : formatDateTimeWithImpliedDate(
                                              checkpoint.actualArrivalDateTime,
                                              checkpoint.expectedArrivalDateTime,
                                          )}
                                </span>
                            </div>
                        ),
                        title: 'Время план/факт',
                        stretch: true,
                    },
                    {
                        key: 'adjustments',
                        render: (checkpoint) =>
                            checkpoint.order.adjustmentCount > 0 ? (
                                <WarningIconComponent
                                    className="orders-report__warning-icon"
                                    title={checkpoint.order.adjustmentCount.toString()}
                                />
                            ) : undefined,
                        title: 'Изменён',
                        align: EntityListColumnAlign.Center,
                        stretch: true,
                    },
                    {
                        key: 'money',
                        render: (checkpoint) =>
                            `${formatPrice(checkpoint.order.price)} / ${
                                checkpoint.order.paid !== undefined &&
                                checkpoint.order.status === OrderStatus.Done
                                    ? formatPrice(checkpoint.order.paid)
                                    : '\u2013'
                            }`,
                        title: 'Деньги план/факт',
                        stretch: true,
                    },
                    {
                        key: 'qr',
                        render: (checkpoint) => (checkpoint.order.paidMethod === OrderPaidMethod.Qr ? '+' : '-'),
                        title: 'QR',
                        stretch: true,
                    },
                    {
                        key: 'status',
                        render: (checkpoint) =>
                            checkpoint.order.status === OrderStatus.Done ? (
                                <div title="Выполнен">
                                    <OkIconComponent className="orders-report__status-icon-ok" />
                                </div>
                            ) : checkpoint.order.status === OrderStatus.Failed ? (
                                <div title="Не выполнен">
                                    <FailedIconComponent className="orders-report__status-icon-failed" />
                                </div>
                            ) : checkpoint.order.status === OrderStatus.Rescheduled ? (
                                <div title="Перенесен">
                                    <CalendarIconComponent className="orders-report__status-icon-rescheduled" />
                                </div>
                            ) : (
                                <div title="В процессе">
                                    <TimeIconComponent className="orders-report__status-icon-unknown" />
                                </div>
                            ),
                        title: 'Статус',
                        align: EntityListColumnAlign.Center,
                        stretch: true,
                    },
                ]}
                selectedItems={selectedOrders}
                isEntityWithWarning={orderHasDeviationsFromPlan}
                renderRowExtendedContent={(checkpoint) => (
                    <div className="orders-report__order-details">
                        <div className="orders-report__order-details-column">
                            <div className="orders-report__order-details-column-header">Детали заказа</div>
                            <div className="orders-report__order-details-item">
                                <UserIconComponent />
                                {checkpoint.order.clientName ? checkpoint.order.clientName : '\u2013'}
                            </div>
                            <div className="orders-report__order-details-item">
                                <PhoneIconComponent />
                                {checkpoint.order.phoneNumber ? checkpoint.order.phoneNumber : '\u2013'}
                            </div>
                            <div className="orders-report__order-details-item">
                                <CommentIconComponent />
                                {checkpoint.order.comment ? checkpoint.order.comment : '\u2013'}
                            </div>
                        </div>
                        <div className="orders-report__order-details-column">
                            <div className="orders-report__order-details-column-header">Заказано</div>
                            {checkpoint.order.operations.productOperations
                                .filter((productOperation) => productOperation.type === ProductOperationType.Shipment)
                                .map((productOperation, index) => (
                                    <div className="orders-report__order-details-item" key={index}>
                                        <BottleIconComponent />
                                        {productOperation.product.name}{' '}
                                        {productOperation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                                        <span className="orders-report__order-details-item-count">
                                            x{productOperation.estimatedCount}
                                        </span>
                                    </div>
                                ))}
                            <div className="orders-report__order-details-column-subheader">Забрать</div>
                            {checkpoint.order.operations.productOperations.filter(
                                (productOperation) => productOperation.type === ProductOperationType.Return,
                            ).length === 0 ? (
                                <div className="orders-report__order-details-item">{'\u2013'}</div>
                            ) : (
                                checkpoint.order.operations.productOperations
                                    .filter((productOperation) => productOperation.type === ProductOperationType.Return)
                                    .map((productOperation, index) => (
                                        <div className="orders-report__order-details-item" key={index}>
                                            <BottleIconComponent />
                                            {productOperation.product.name}{' '}
                                            {productOperation.dealType === ProductOperationDealType.Renting &&
                                                '(Аренда)'}
                                            <span className="orders-report__order-details-item-count">
                                                x{productOperation.estimatedCount}
                                            </span>
                                        </div>
                                    ))
                            )}
                            <div className="orders-report__order-details-column-subheader">Оплата</div>
                            <div className="orders-report__order-details-item">
                                <PaymentMethodIcon
                                    method={checkpoint.order.paymentMethod}
                                    classname="orders-report__order-details-wallet"
                                />
                                {formatPrice(checkpoint.order.price)}
                            </div>
                        </div>
                        <div className="orders-report__order-details-column">
                            <div className="orders-report__order-details-column-header">Фактически доставлено</div>
                            {checkpoint.order.status === OrderStatus.Done ||
                            checkpoint.order.status === OrderStatus.Failed ? (
                                <div>
                                    {checkpoint.order.operations.productOperations
                                        .filter(
                                            (productOperation) =>
                                                productOperation.type === ProductOperationType.Shipment,
                                        )
                                        .map((productOperation, index) => (
                                            <div className="orders-report__order-details-item" key={index}>
                                                <BottleIconComponent />
                                                {productOperation.product.name}{' '}
                                                {productOperation.dealType === ProductOperationDealType.Renting &&
                                                    '(Аренда)'}
                                                <span
                                                    className={classNames(
                                                        'orders-report__order-details-item-count',
                                                        productOperation.actualCount !==
                                                            productOperation.estimatedCount &&
                                                            'orders-report__warning-text',
                                                    )}
                                                >
                                                    {productOperation.actualCount === undefined
                                                        ? '\u2013'
                                                        : `x${productOperation.actualCount}`}
                                                </span>
                                            </div>
                                        ))}
                                    <div className="orders-report__order-details-column-subheader">Забрать</div>
                                    {checkpoint.order.operations.productOperations.filter(
                                        (productOperation) => productOperation.type === ProductOperationType.Return,
                                    ).length === 0 ? (
                                        <div className="orders-report__order-details-item">{'\u2013'}</div>
                                    ) : (
                                        checkpoint.order.operations.productOperations
                                            .filter(
                                                (productOperation) =>
                                                    productOperation.type === ProductOperationType.Return,
                                            )
                                            .map((productOperation, index) => (
                                                <div className="orders-report__order-details-item" key={index}>
                                                    <BottleIconComponent />
                                                    {productOperation.product.name}{' '}
                                                    {productOperation.dealType === ProductOperationDealType.Renting &&
                                                        '(Аренда)'}
                                                    <span
                                                        className={classNames(
                                                            'orders-report__order-details-item-count',
                                                            productOperation.actualCount !==
                                                                productOperation.estimatedCount &&
                                                                'orders-report__warning-text',
                                                        )}
                                                    >
                                                        {productOperation.actualCount === undefined
                                                            ? '\u2013'
                                                            : `x${productOperation.actualCount}`}
                                                    </span>
                                                </div>
                                            ))
                                    )}
                                    <div className="orders-report__order-details-column-subheader">Оплата</div>
                                    <div className="orders-report__order-details-item">
                                        <WalletIconComponent className="orders-report__order-details-wallet" />
                                        <span
                                            className={classNames(
                                                (checkpoint.order.paid || 0) !== checkpoint.order.price &&
                                                    'orders-report__warning-text',
                                            )}
                                        >
                                            {checkpoint.order.paid === undefined
                                                ? '\u0030 \u20bd'
                                                : formatPrice(checkpoint.order.paid)}
                                        </span>
                                        {checkpoint.order.paidMethod === OrderPaidMethod.Qr && (
                                            <span className="orders-report__qr-payment">
                                                <QrCodeIconComponent />
                                                Оплата по QR-коду
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="orders-report__order-details-item">{'\u2013'}</div>
                            )}
                        </div>
                        {(checkpoint.order.status === OrderStatus.Failed ||
                            checkpoint.order.status === OrderStatus.Rescheduled) && (
                            <div className="orders-report__order-details-column">
                                <div className="orders-report__order-details-column-header">Статус доставки</div>
                                <div className="orders-report__order-details-column-subheader">Недоставлено</div>
                                <div>
                                    <WarningIconComponent className="orders-report__warning-icon" />
                                    {checkpoint.order.cancellationReasonComment ?? checkpoint.order.cancellationReason}
                                </div>
                                {checkpoint.order.status === OrderStatus.Rescheduled && (
                                <>
                                    <div className="orders-report__order-details-column-subheader">Новое время доставки</div>
                                    <div>
                                        <CalendarIconComponent className="orders-report__calendar-icon"/>
                                        {checkpoint.order.rescheduledDateTimePeriod && (
                                            <div className="orders-report__reschedule-datetime">
                                            {formatDateTimePeriodShort(checkpoint.order.rescheduledDateTimePeriod)}
                                            </div>
                                        )}
                                    </div>
                                </>
                                )}
                            </div>
                        )}
                    </div>
                )}
                pageSize={pageSize}
                isRowReverse
            />
        </div>
    );
};
