import * as React from 'react';
import { Duration } from '../../Std/Duration';

export interface IAnalysisCellBarProps {
    values: (number | Duration | undefined)[];
    valueFormatter?: (value: any) => string;
    labels?: string[];
}

export const AnalysisCellBars = (props: IAnalysisCellBarProps): JSX.Element => {
    const values = props.values.map((v): number => v !== undefined ? v instanceof Duration ? v.toSeconds() : v : 0);
    const maxValue = Math.max(...values);
    const p100 = 100;

    const getCssWidth = (dividend: number, divider: number): string => {
        if (divider === 0) {
            return "0";
        }

        return `${dividend / divider * p100}%`
    }

    return (
        <>
            {values.map((v, i) => {
                const value = props.valueFormatter && props.values[i] !== undefined ? props.valueFormatter(props.values[i]) : `${v}`;
                
                return (
                    <div key={i} className="cell-bar" style={{
                        width: getCssWidth(v, maxValue)
                    }} title={value}>
                        {props.labels && (
                            <div className="label">
                                {props.labels[i]}
                            </div>
                        )}
                        {value}
                    </div>
                )
            })}
        </>
    )
}