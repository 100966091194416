import {
    DeliveryApiBasketsResponse
} from '../../../../../ApiClient/Yoso/models';
import { Basket } from '../../../../../Model/Basket/Basket';
import { DateTime } from '../../../../../Std/DateTime';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class GetBasketsResponseDeserializer {
    public deserialize(response: DeliveryApiBasketsResponse): { baskets: Basket[], date: DateTime } {
        return {
            baskets: response.baskets.map(b => new Basket(b.id, b.driverName, b.departPeriod, b.ordersCount, b.index)),
            date: dateTimeDeserializer.deserialize(response.date)
        }
    }
}

export const getBasketsResponseDeserializer = new GetBasketsResponseDeserializer();