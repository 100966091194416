import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { StatsApiDriver } from '../../../ApiClient/YosoStats/models';
import { ArrayUtils } from '../../../Std/ArrayUtils';
import { ChartDriver } from '../../ChartPages/ChartDriver';
import { ChartDriverList } from '../../ChartPages/ChartDriverList';
import { CompanyLogs } from '../../Logs/CompanyLogs';

import { Page } from '../../Page/Page';

export interface ILogsRouteProps{
}

export const LogsRoute = withRouter((props: ILogsRouteProps): JSX.Element => (
    <Page header="Ошибки">
        <CompanyLogs />
    </Page>
))