import React from 'react';
import { Tag } from 'antd';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {OrderRoutePoint} from '../../../Map/Route/RoutePoint/OrderRoutePoint';
import {
    formatDistanceInKilometers
} from '../../../Std/FormatDistanceInKilometers';
import { OrderStatus } from '../../../Model/Order/OrderStatus';
import { formatDistance } from '../../../Std/FormatDistance';
import {OrderBadgeStatus} from '../../OrderBadgeStatus/OrderBadgeStatus';
import { FormattedTime } from '../RouteInfoFormattedValues/FormattedTime';
import { formatTimePeriod } from '../../../Std/FormatTimePeriod';
import { ReactComponent as DragHandleIconComponent } from '../../../icons/drag-handle.svg';
import { ReactComponent as SmallOkIconComponent } from '../../../icons/small-ok.svg';
import { ReactComponent as SmallCancelIconComponent } from '../../../icons/small-cancel.svg';
import '../RouteInfo2.css';

interface RouteInfoOrderPointProps {
    point: OrderRoutePoint;
    dragHandleProps: DraggableProvidedDragHandleProps;
    scheduled?: boolean;
}

const RouteInfoOrderPoint = (props: RouteInfoOrderPointProps) => {
    const { point } = props;

    let classname = "route-info-block__inner-circle";

    if (props.scheduled) {
        classname += " route-info-block__inner-circle_scheduled"
    }

    switch (point.order.status) { // eslint-disable-line
        case OrderStatus.Cancelled:
        case OrderStatus.Failed:
        case OrderStatus.Rescheduled: {
            classname += " route-info-block__inner-circle_fail";
            break;
        }
        case OrderStatus.Moved: {
            break;
        }
        case OrderStatus.Done: {
            classname += " route-info-block__inner-circle_done";
        }
    }

    const renderDistance = (distance: number) => {
        const metersInkilometer = 1000;

        return distance >= metersInkilometer
            ? `${formatDistanceInKilometers(distance, 1, false)}км`
            : `${formatDistance(distance, false)}м`
    }

    return (
        <>
            <div className="route-info-block__marker_route-point">
                {point.order.status !== OrderStatus.Moved && (
                <div className="route-info-block__wrapper-circle">
                    <div className={classname}>
                        {
                            point.order.status === OrderStatus.Done
                            && <SmallOkIconComponent className="route-info-block__inner-icon" />
                        }
                        {
                            (point.order.status === OrderStatus.Cancelled
                                || point.order.status === OrderStatus.Failed
                                || point.order.status === OrderStatus.Rescheduled)
                            && <SmallCancelIconComponent className="route-info-block__inner-icon route-info-block__inner-icon_fail" />
                        }
                    </div>
                </div>)}
                <div className="route-info-block__distance">
                    {point.expectedDistanceTo && renderDistance(point.expectedDistanceTo)}
                </div>
            </div>
            <div {...props.dragHandleProps} className="route-info-block__drag">
                {point.order.canBeDone() && <DragHandleIconComponent />}
            </div>
            <div className="route-info-block__content">
                <div>
                    <div>
                        <OrderBadgeStatus
                            className="route-info-block__order-status"
                            deferred={point.order.deferred}
                            status={point.order.status}
                        />
                        {point.order.transfer?.toDriverName !== undefined && point.order.status === OrderStatus.Moved && (
                            <Tag className="route-info-block__order-status">{point.order.transfer.toDriverName}</Tag>
                        )}
                    </div>
                    <div className="route-info-block__client-name">{point.order.clientName}</div>
                    <div>{point.address}</div>
                    <div>
                        <FormattedTime date={point.expectedArrivalDateTime} />
                        {` (${formatTimePeriod(point.order.desiredDateTimePeriod!)})`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RouteInfoOrderPoint