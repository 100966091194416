import React, { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { Modal, TimePicker } from 'antd';
import { ExclamationCircleOutlined, MinusOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './DeliveryTimeIntervalSelector.module.css';

interface DeliveryTimeIntervalSelectorProps<TEntity> {
    intervals: TEntity[];
    getBegin: (entity: TEntity) => Moment | Date | string;
    getEnd: (entity: TEntity) => Moment | Date | string;
    onRemove: (interval: TEntity) => void;
    onBeginChange: (interval: TEntity, value: Moment) => void;
    onEndChange: (interval: TEntity, value: Moment) => void;
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const beginDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 22, 23];
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const endDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7];

const DeliveryTimeIntervalSelector = <TEntity extends unknown>(props: DeliveryTimeIntervalSelectorProps<TEntity>) => {
    const { intervals, getBegin, getEnd, onRemove, onBeginChange, onEndChange } = props;

    const onBeginChangeWrap = useCallback((interval: TEntity, time: Moment | null) => {
        if (!time) {
            return;
        }
        onBeginChange(interval, time);
    }, []);

    const onEndChangeWrap = useCallback((interval: TEntity, time: Moment | null) => {
        if (!time) {
            return;
        }
        onEndChange(interval, time);
    }, []);

    return (
        <div className={styles.container}>
            {intervals.map((x, i) => (
                <div className={styles.item} key={i}>
                    <MinusOutlined
                        className={styles.removeInterval}
                        onClick={() => {
                            Modal.confirm({
                                title: 'Удалить?',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Да',
                                okType: 'primary',
                                cancelText: 'Нет',
                                onOk: () => {
                                    onRemove(x);
                                },
                                onCancel: () => {
                                    /* */
                                },
                            });
                        }}
                    />
                    <TimePicker
                        format="HH:mm"
                        value={moment(getBegin(x))}
                        onChange={(time) => onBeginChangeWrap(x, time)}
                        allowClear={false}
                        minuteStep={30}
                        showNow={false}
                        disabledHours={() => beginDisabledHours}
                    />
                    &nbsp;&ndash;&nbsp;
                    <TimePicker
                        format="HH:mm"
                        value={moment(getEnd(x))}
                        onChange={(time) => onEndChangeWrap(x, time)}
                        allowClear={false}
                        minuteStep={30}
                        showNow={false}
                        disabledHours={() => endDisabledHours}
                    />
                </div>
            ))}
        </div>
    );
};

export default DeliveryTimeIntervalSelector;
