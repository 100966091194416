import {
    DeliveryApiCar,
    DeliveryApiDriver,
    DeliveryApiLoadingStore,
    DeliveryApiLunch,
    DeliveryApiOrder,
    DeliveryApiProduct,
    DeliveryApiTrip,
    FinishPointType, ProblemRoute,
} from '@/ApiClient/Yoso/models';
import { CarTrackedLocation } from '@/Model/CarTrackedLocation/CarTrackedLocation';
import { Geocode } from '@/Model/Geocode/Geocode';
import { ProblemRouteType } from '@/Model/Trip/ProblemRouteType';
import { Trip } from '@/Model/Trip/Trip';
import { TripExecutor } from '@/Model/TripExecutor/TripExecutor';
import { DateTimePeriod } from '@/Std/DateTimePeriod';
import { deserializerUtils } from '@/Std/DeserializerUtils';
import { Duration } from '@/Std/Duration';
import { Route } from '@/Map/Route/Route';
import { Lunch } from '@/Model/Lunch/Lunch';
import { Order } from '@/Model/Order/Order';
import { ILeg } from '@/Backend/Types/ILeg';
import { LoadingStore } from '@/Model/Store/LoadingStore';
import { TripPointType } from '@/Model/TripPointId/TripPointType';
import { carDeserializer } from '../Car/CarDeserializer';
import { driverDeserializer } from '../Driver/DriverDeserializer';
import { tripStatusDeserializer } from '../TripStatus/TripStatusDeserializer';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { lunchDeserializer } from '../Lunch/LunchDeserializer';
import { legDeserializer } from '../Leg/LegDeserializer';
import { orderDeserializer } from '../Order/OrderDeserializer';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import { storeDeserializer } from '../Store/StoreDeserializer';
import {
    tripWorkingShiftDeserializer
} from "../TripWorkingShift/TripWorkingShiftDeserializer";

export class TripDeserializer {
    private static getById<T extends { id?: number }>(collection: ReadonlyArray<T>, id: number): T | undefined {
        return collection.find((element: T): boolean => element.id === id);
    }

    public deserialize(
        trip: DeliveryApiTrip,
        cars: ReadonlyArray<DeliveryApiCar>,
        drivers: ReadonlyArray<DeliveryApiDriver>,
        orders: ReadonlyArray<DeliveryApiOrder>,
        dateTimePeriod: DateTimePeriod,
        products: ReadonlyArray<DeliveryApiProduct>,
        loadingStores?: ReadonlyArray<DeliveryApiLoadingStore>,
        lunches?: ReadonlyArray<DeliveryApiLunch>,
    ): Trip {        
        const car = TripDeserializer.getById(cars, trip.carId);
        if (car === undefined) {
            throw new Error(`Can not find car with id ${trip.carId}`);
        }

        const driver = TripDeserializer.getById(drivers, trip.driverId);
        if (driver === undefined) {
            throw new Error(`Can not find driver with id ${trip.driverId}`);
        }

        return new Trip(
            trip.id,
            new TripExecutor(
                carDeserializer.deserialize(car),
                driverDeserializer.deserialize(driver),
                tripWorkingShiftDeserializer.deserialize(trip.workingShift)
            ),
            trip.ordersCount,
            trip.completedOrdersCount,
            trip.failedOrdersCount,
            trip.rescheduledOrdersCount,
            this.createRoute(trip, car, driver, orders, loadingStores || [], products, lunches),
            trip.isRouteBuilt,
            trip.actualRoute === undefined ? [] : this.deserializeActualRoute(trip.actualRoute),
            trip.estimatedDistance,
            undefined,
            dateTimePeriod,
            trip.estimatedDuration === undefined ? undefined : Duration.fromSeconds(trip.estimatedDuration),
            undefined,
            tripStatusDeserializer.deserialize(trip.status),
            trip.problemRoute
                ? this.deserializeProblemRouteType(trip.problemRoute)
                : undefined,
            tripWorkingShiftDeserializer.deserialize(trip.workingShift)
        );
    }
    
    public deserializeProblemRouteType(problemRoute: ProblemRoute): ProblemRouteType | undefined {
        switch (problemRoute) {
            case ProblemRoute.LATE30:
                return ProblemRouteType.LATE30
            case ProblemRoute.LATE60:
                return ProblemRouteType.LATE60
            default:
                return undefined
        }
    }

    public deserializeActualRoute(serializedActualRoute: string): ReadonlyArray<CarTrackedLocation> {
        const packedActualRoute = atob(serializedActualRoute);
        const actualRoute: CarTrackedLocation[] = [];
        let i = 0;
        while (i < packedActualRoute.length) {
            const dateTime = deserializerUtils.deserializeDateTime(packedActualRoute, i);
            i += deserializerUtils.DATE_TIME_SIZE;
            const latitude = deserializerUtils.deserializeFloat7(packedActualRoute, i);
            i += deserializerUtils.FLOAT7_SIZE;
            const longitude = deserializerUtils.deserializeFloat7(packedActualRoute, i);
            i += deserializerUtils.FLOAT7_SIZE;
            actualRoute.push(new CarTrackedLocation(new Geocode({ latitude, longitude }), dateTime));
        }

        return actualRoute;
    }

    private createRoute(
        trip: DeliveryApiTrip,
        car: DeliveryApiCar,
        driver: DeliveryApiDriver,
        orders: ReadonlyArray<DeliveryApiOrder>,
        loadingStores: ReadonlyArray<DeliveryApiLoadingStore>,
        products: ReadonlyArray<DeliveryApiProduct>,
        lunches?: ReadonlyArray<DeliveryApiLunch>): Route | undefined {

        if (!trip.start || !trip.finish) {
            return undefined;
        }
        
        const legs = trip.legs ? trip.legs : [];

        const startLeg = legs[0] || undefined;
        const finishLeg = legs[legs.length-1] || undefined;

        return new Route(
            {
                actualArrivalDateTime:
                    trip.actualStartDateTime === undefined
                        ? undefined
                        : dateTimeDeserializer.deserialize(trip.actualStartDateTime),
                address: trip.start.address,
                id: 10000, // TODO: remove!
                type: TripPointType.Store,
                latitude: trip.start.latitude!,
                longitude: trip.start.longitude!,
                expectedArrivalDateTime: startLeg && startLeg.period
                    ? dateTimePeriodDeserializer.deserialize(startLeg.period).start
                    : undefined,
            },
            {
                actualArrivalDateTime:
                    trip.actualFinishDateTime === undefined
                        ? undefined
                        : dateTimeDeserializer.deserialize(trip.actualFinishDateTime),
                address: trip.finish.address,
                id: 20000, // TODO: remove!
                type: trip.finishPointType === FinishPointType.TRIPFINISHPOINT ? TripPointType.TripFinishPoint : TripPointType.Store,
                latitude: trip.finish.latitude!,
                longitude: trip.finish.longitude!,
                expectedArrivalDateTime: finishLeg && finishLeg.period
                    ? dateTimePeriodDeserializer.deserialize(finishLeg.period).end
                    : undefined,
            },
            orders
                .filter((apiOrder) => apiOrder.tripId === trip.id)
                .map((apiOrder: DeliveryApiOrder): Order =>
                    orderDeserializer.deserialize(apiOrder, [car], car.id!, [driver], driver.id!, products, tripWorkingShiftDeserializer.deserialize(trip.workingShift)),
            ),
            loadingStores.map((store): LoadingStore => storeDeserializer.deserializeLoadingStore(store)),            
            lunches ? lunches.map((lunch): Lunch => lunchDeserializer.deserialize(lunch)) : [],
            legs.map((leg): ILeg => legDeserializer.deserialize(leg)),
        );
    }
}

export const tripDeserializer = new TripDeserializer();
