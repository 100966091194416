import React from 'react';
import { Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { DeliveryApi } from '@/Backend/Api/Delivery/DeliveryApi';
import { Car } from '@/Model/Car/Car';
import { Trip } from '@/Model';
import { TripStatus } from '@/Model/Trip/TripStatus';
import { TripPlan } from '@/Model/TripPlan/TripPlan';
import { Itinerary } from '@/Model/Itinerary/Itinerary';
import { ArrayUtils } from '@/Std/ArrayUtils';
import { formatDateTimePeriodShort } from '@/Std/formatDateTimePeriodShort';
import { Page } from '../../../Page/Page';
import { RoutedTabs } from '../../../RoutedTabs/RoutedTabs';
import { TripAnalysis } from '../../../TripAnalysis/TripAnalysis';
import { TripPlanAnalysis } from '../../../TripPlanAnalysis/TripPlanAnalysis';
import { TripPlanRealtime } from '../../../TripPlanRealtime/TripPlanRealtime';
import { TripPlanReport } from '../../../TripPlanReport/TripPlanReport';
import { TripReport } from '../../../TripReport/TripReport';
import { ItineraryReport } from '../../../ItineraryReport/ItineraryReport';
import TripPlanDetails2 from '../../../TripPlanDetails/TripPlanDetails2';
import WhereMyWater from '../../../WhereMyWater/WhereMyWater';
import './TripPlanItemViewRoute.css';

export interface ITripPlanItemViewRouteProps extends RouteComponentProps {
    analysisTripId?: number;
    cars?: ReadonlyArray<Car>;
    onPlanChanged: (plan: TripPlan | undefined) => void;
    planId: number;
    reportTripId?: number;
    itineraryId?: number;
    tripPlan?: TripPlan;
}

const TripPlanItemViewRouteComponent = (props: ITripPlanItemViewRouteProps): JSX.Element => {
    const { history, planId, onPlanChanged } = props;
    const { pathname } = history.location;

    const [loading, setLoading] = React.useState(props.tripPlan === undefined);
    const [tripPlan, setTripPlan] = React.useState(props.tripPlan);

    React.useEffect(() => {
        setTripPlan(props.tripPlan);
    }, [props.tripPlan]);

    React.useEffect(() => {
        onPlanChanged(tripPlan);
    }, [onPlanChanged, tripPlan]);

    const [reportTripId, setReportTripId] = React.useState(props.reportTripId);

    const [reportTrip, setReportTrip] = React.useState(undefined as Trip | undefined);

    const [itinerary, setItinerary] = React.useState(undefined as Itinerary | undefined);

    const [itineraryId, setItineraryId] = React.useState(props.itineraryId);

    const [analysisTripId, setAnalysisTripId] = React.useState(props.analysisTripId);

    const [analysisTrip, setAnalysisTrip] = React.useState(undefined as Trip | undefined);

    const [isEditingTabVisible, setIsEditingTabVisible] = React.useState(false);

    const [isWatchingTabsVisible, setIsWatchingTabsVisible] = React.useState(false);

    const [selectedTripId, setSelectedTripId] = React.useState(undefined as number | undefined);

    const [withActualRoute, setWithActualRoute] = React.useState(undefined as boolean | undefined);

    const selectReportTrip = React.useCallback(
        async (plan?: TripPlan) => {
            let rt =
                plan !== undefined && reportTripId !== undefined
                    ? plan.trips.find((trip) => trip.id === reportTripId)
                    : undefined;

            if (rt && !rt.isRouteSet()) {
                rt = await getTripReportExtension(rt.id, withActualRoute ? withActualRoute : false);
            }

            setReportTrip(rt);
        },
        [reportTripId, withActualRoute],
    );

    const selectAnalysisTrip = React.useCallback(
        (plan?: TripPlan) => {
            setAnalysisTrip(
                !plan || !analysisTripId ? undefined : plan.trips.find((trip) => trip.id === analysisTripId),
            );
        },
        [analysisTripId],
    );

    const selectItineraryTrip = React.useCallback(
        async (plan?: TripPlan) => {
            if (plan === undefined || props.itineraryId === undefined) {
                return;
            }

            setItinerary(await DeliveryApi.getItinerary(props.itineraryId));
        },
        [props.itineraryId],
    );

    const fetchData = React.useCallback(async (): Promise<void> => {
        setLoading(true);
        try {
            const response = await DeliveryApi.getPlan(planId, selectedTripId, withActualRoute);
            const plan = response.plan;
            setTripPlan(plan);
            await selectReportTrip(plan);
            selectAnalysisTrip(plan);
            await selectItineraryTrip(plan);
        } catch (error) {
            alert(String(error));
        } finally {
            setLoading(false);
        }
    }, [planId, selectAnalysisTrip, selectReportTrip, selectItineraryTrip, selectedTripId, withActualRoute]);

    React.useEffect(() => {
        const init = async (): Promise<void> => {
            if (tripPlan === undefined || tripPlan.id !== planId) {
                await fetchData();
            } else {
                selectReportTrip(tripPlan);
                selectAnalysisTrip(tripPlan);
                selectItineraryTrip(tripPlan);
            }
        };
        init();
    }, [tripPlan, selectAnalysisTrip, selectReportTrip, selectItineraryTrip, planId]);

    const handleReportTripClick = React.useCallback(
        async (tripId): Promise<void> => {
            history.push(`/plans/${planId}/report/${tripId}`);
            setReportTripId(tripId);
        },
        [history, planId],
    );

    const handleAnalysisTripClick = React.useCallback(
        async (tripId): Promise<void> => {
            history.push(`/plans/${planId}/analysis/${tripId}`);
            setAnalysisTripId(tripId);
        },
        [history, planId],
    );

    const handleItineraryTripClick = React.useCallback(async (id: number) => {
        try {
            setItinerary(await DeliveryApi.getItinerary(id));
            setItineraryId(id);
            history.push(`/plans/${planId}/itinerary/${id}`);
        } catch (error) {
            alert(String(error));
        }
    }, []);

    const handleTripAnalysisClose = React.useCallback(() => {
        const isPathConsistsAnalysis = pathname.includes('analysis');
        const currentLocation = isPathConsistsAnalysis ? `/plans/${planId}/analysis` : pathname;

        setAnalysisTripId(undefined);
        setAnalysisTrip(undefined);
        history.push(currentLocation);
    }, [history, planId, pathname]);

    const handleItineraryReportClose = React.useCallback(() => {
        const isPathConsistsReport = pathname.includes('itinerary');
        const currentLocation = isPathConsistsReport ? `/plans/${planId}/realtime` : pathname;

        setItinerary(undefined);
        setItineraryId(undefined);
        history.push(currentLocation);
    }, [history, planId, itinerary, itineraryId, pathname]);

    const handleTripReportClose = React.useCallback(() => {
        const isPathConsistsReport = pathname.includes('report');
        const currentLocation = isPathConsistsReport ? `/plans/${planId}/report` : pathname;

        setReportTripId(undefined);
        setReportTrip(undefined);
        history.push(currentLocation);
    }, [history, planId, pathname]);

    const getTripReportExtension = React.useCallback(
        (tripId: number, withAR: boolean): Promise<Trip> =>
            new Promise<Trip>((resolve) => {
                setSelectedTripId(tripId);
                setWithActualRoute(withAR);
                DeliveryApi.getPlan(planId, tripId, withAR).then((planResponse) => {
                    const trip = planResponse.plan.trips.find((x) => x.id === tripId);
                    if (!trip) {
                        throw new Error(`No trip ${tripId} in response`);
                    }
                    resolve(trip);
                });
            }),
        [planId],
    );

    const canEditingTrip = (trip: Trip) =>
        trip.status === TripStatus.LogistApprovement || trip.status === TripStatus.New;

    const canWatchingTrip = (trip: Trip) =>
        trip.status === TripStatus.Done ||
        trip.status === TripStatus.InProgress ||
        trip.status === TripStatus.DriverApprovement ||
        trip.status === TripStatus.RouteBuildFailed;

    React.useEffect(() => {
        if (props.tripPlan !== undefined && props.tripPlan.id === planId) {
            let needEditingTab = false;
            let needWatchingTabs = false;

            props.tripPlan.trips.forEach((trip) => {
                needEditingTab = needEditingTab || canEditingTrip(trip);
                needWatchingTabs = needWatchingTabs || canWatchingTrip(trip);
            });
            setIsEditingTabVisible(needEditingTab);
            setIsWatchingTabsVisible(needWatchingTabs);

            if (pathname === `/plans/${planId}/view` && !needEditingTab) {
                history.replace(`/plans/${planId}/realtime`);
            }
        }
    }, [props.tripPlan, planId]);

    return (
        <Page
            className="trip-plan-item-view-route"
            header={props.tripPlan ? `План рейсов ${formatDateTimePeriodShort(props.tripPlan.dateTimePeriod)}` : ''}
        >
            <Spin spinning={loading}>
                {!loading && tripPlan !== undefined && (
                    <RoutedTabs
                        className="trip-plan-item-view-route__tabs"
                        tabs={ArrayUtils.defined([
                            !isEditingTabVisible
                                ? undefined
                                : {
                                      title: 'Редактирование',
                                      url: `/plans/${planId}/view`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <TripPlanDetails2
                                              {...routeComponentProps}
                                              className="trip-plan-item-view-route__trip-plan-details"
                                              onUpdatePlan={fetchData}
                                              plan={tripPlan}
                                          />
                                      ),
                                  },
                            !isWatchingTabsVisible
                                ? undefined
                                : {
                                      title: 'Текущая ситуация',
                                      url: `/plans/${planId}/realtime`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <div className="trip-plan-item-view-route__trip-plan-realtime-container">
                                              <TripPlanRealtime
                                                  {...routeComponentProps}
                                                  className="trip-plan-item-view-route__trip-plan-realtime"
                                                  getItinerary={handleItineraryTripClick}
                                                  tripPlan={tripPlan}
                                              />
                                          </div>
                                      ),
                                  },
                            !isWatchingTabsVisible
                                ? undefined
                                : {
                                      title: 'Отчёт',
                                      url: `/plans/${planId}/report`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <TripPlanReport
                                              {...routeComponentProps}
                                              id={planId}
                                              onTripClick={handleReportTripClick}
                                          />
                                      ),
                                  },
                            reportTrip === undefined
                                ? undefined
                                : {
                                      onClose: handleTripReportClose,
                                      loading: false,
                                      title: `Отчёт ${reportTrip.executor.driver.name}`,
                                      url: `/plans/${planId}/report/${reportTripId}`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <TripReport {...routeComponentProps} trip={reportTrip} />
                                      ),
                                  },
                            itinerary === undefined
                                ? undefined
                                : {
                                      onClose: handleItineraryReportClose,
                                      loading: false,
                                      title: `Маршрутный лист ${itinerary.driverName}`,
                                      url: `/plans/${planId}/itinerary/${itineraryId}`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <ItineraryReport {...routeComponentProps} itinerary={itinerary} />
                                      ),
                                  },
                            !isWatchingTabsVisible
                                ? undefined
                                : {
                                      title: 'Сравнение',
                                      url: `/plans/${planId}/analysis`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <TripPlanAnalysis
                                              {...routeComponentProps}
                                              planId={planId}
                                              onTripClick={handleAnalysisTripClick}
                                          />
                                      ),
                                  },
                            analysisTrip === undefined
                                ? undefined
                                : {
                                      onClose: handleTripAnalysisClose,
                                      loading: false,
                                      title: `Сравнение ${analysisTrip.executor.driver.name}`,
                                      url: `/plans/${planId}/analysis/${analysisTripId}`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <TripAnalysis {...routeComponentProps} tripId={analysisTripId} />
                                      ),
                                  },
                            !isWatchingTabsVisible
                                ? undefined
                                : {
                                      title: 'Где моя вода?',
                                      url: `/plans/${planId}/where-my-water`,
                                      render: (routeComponentProps: RouteComponentProps) => (
                                          <WhereMyWater {...routeComponentProps} tripPlan={tripPlan} />
                                      ),
                                  },
                        ])}
                    />
                )}
            </Spin>
        </Page>
    );
};

export const TripPlanItemViewRoute = withRouter(TripPlanItemViewRouteComponent);
