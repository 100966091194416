import React from 'react';
import { Button, Modal } from 'antd';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import DropdownList from '@/Components/DropdownList/DropdownList';
import { Employee } from '@/Model/Employee/Employee';
import { TripPlan } from '@/Model/TripPlan/TripPlan';
import { DeliveryApi } from '@/Backend/Api/Delivery/DeliveryApi';
import { CarTrackedLocations, TripWorkingShift } from '@/Model';
import { EditOrderDto } from '@/Model/Order/EditOrderDto';
import { RescheduleOrderDto } from '@/Model/Order/RescheduleOrderDto';
import { Trip } from '@/Model/Trip/Trip';
import { AddOrdersBlock } from '../AddOrdersBlock/AddOrdersBlock';
import { PlanMap } from '../PlanMap/PlanMap';
import { OrderInfo } from './ui/OrderInfo/OrderInfo';
import { PlanOrderSearcher } from './ui/PlanOrderSearcher/PlanOrderSearcher';
import { TripList } from './ui/TripList/TripList';
import { useAvailableDriversToAddPlan } from './hooks/useAvailableDriversToAddPlan';
import { useDistrictsBySelectedGroup } from './hooks/useDistrictsBySelectedGroup';
import { canWatchingTrip } from './utils/tripPlanRealtimeUtils';
import './TripPlanRealtime.css';

interface TripPlanRealtimeProps extends RouteComponentProps {
    className?: string;
    getItinerary: (id: number) => void;
    tripPlan: TripPlan
}

export const TripPlanRealtime = (props: TripPlanRealtimeProps): JSX.Element => {
    const {tripPlan, getItinerary} = props

    const [loading, setLoading] = React.useState(false);
    const [watchedTrips, setWatchedTrips] = React.useState<Trip[]>(tripPlan.trips.filter(canWatchingTrip))
    const [selectedTripId, setSelectedTripId] = React.useState<number | undefined>(undefined);
    const [selectedOrderId, setSelectedOrderId] = React.useState<number | undefined>(undefined);
    const [selectedTrip, setSelectedTrip] = React.useState<Trip | undefined>(undefined);
    const [withActualRoute, setWithActualRoute] = React.useState(false);

    const availableDriversToAddPlan = useAvailableDriversToAddPlan(watchedTrips, tripPlan.isDayOff)
    const districts = useDistrictsBySelectedGroup(tripPlan.districts, selectedTrip?.workingShift)

    const updateWatchedTrips = (tripPlanId: number, tripId?: number) => {
        DeliveryApi.getPlan(tripPlanId, tripId, withActualRoute).then(res => {
            setWatchedTrips(res.plan.trips.filter(canWatchingTrip));
            setSelectedTrip(res.plan.trips.find(i => i.id === tripId));
        });
    };

    React.useEffect(() => {
        const interval = 30000
        const refreshByInterval = setInterval(() => {
            updateWatchedTrips(tripPlan.id, selectedTripId);
        }, interval)

        return () => clearInterval(refreshByInterval)
    }, [tripPlan.id, selectedTripId, withActualRoute]);

    const selectTrip = (tripId?: number) => {
        if (tripId && tripId === selectedTripId) {
            setSelectedTrip(undefined)
            setSelectedTripId(undefined)
            setWatchedTrips([...watchedTrips])

            return;
        }

        const trip = watchedTrips.find(i => i.id === tripId)
        setSelectedTrip(trip)
        setSelectedTripId(tripId)

        setLoading(true)
        DeliveryApi.getPlan(tripPlan.id, tripId, withActualRoute)
            .then(res => {
                setWatchedTrips(res.plan.trips.filter(canWatchingTrip))
                setSelectedTrip(res.plan.trips.find(i => i.id === tripId));
                setLoading(false)
            })
    }

    const selectedOrder = React.useMemo(() => {
        const foundTrip = watchedTrips.find(i => i.id === selectedTripId)

        if (!foundTrip || !foundTrip.isRouteSet()) {
            return undefined
        }

        return foundTrip.route.orders.find(i => i.originalId === selectedOrderId)
    }, [watchedTrips, selectedTripId, selectedOrderId])

    const restoreOrder = async (orderId: number) => {
        Modal.confirm({
            title: 'Вы действительно хотите ВОССТАНОВИТЬ заказ?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.restoreOrder(orderId)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined);
                    Modal.success({
                        content: 'Заказ успешно восстановлен'
                    })
                })
                .catch(alert)
        });
    };

    const editDoneOrder = (orderId: number, dto: EditOrderDto) => {
        Modal.confirm({
            title: 'Вы действительно хотите ИЗМЕНИТЬ завершенный заказ?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.editOrder(orderId, dto)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Заказ успешно изменен'
                    })
                })
                .catch(alert)
        });
    };

    const resumeTrip = (tripId: number) => {
        Modal.confirm({
            title: 'Вы действительно хотите ВОЗОБНОВИТЬ маршрут?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.resumeTrip(tripId)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, tripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Маршрут успешно возобновлен'
                    })
                })
                .catch(alert)
        });
    }

    const onAddDriver = async (driver: {driver: Employee, workingShift: TripWorkingShift }) => {
        DeliveryApi.addPlanDriver(tripPlan.id, driver.driver.driver.id, driver.workingShift)
            .then(() => {
                updateWatchedTrips(tripPlan.id, selectedTripId);
                Modal.success({
                    content: `Водитель ${driver.driver.driver.name} доступен для передачи заказа`,
                })
            })
            .catch(() => alert('Не удается добавить водителя'));
    }

    const transferOrder = async (orderId: number, tripId: number) => {
        Modal.confirm({
            title: 'Вы действительно хотите ПЕРЕДАТЬ заказ?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.transferOrder(orderId, tripId)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Заказ успешно передан'
                    })
                })
                .catch(alert)
        });
    }

    const rescheduleOrder = async (orderId: number, dto: RescheduleOrderDto) => {
         Modal.confirm({
            title: 'Вы действительно хотите ПЕРЕНЕСТИ заказ?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.rescheduleOrder(orderId, dto)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Заказ успешно перенесен'
                    })
                })
                .catch(alert)
        });
    }

    const failOrder = (orderId: number, reason: string, subReason?: string) => {
        Modal.confirm({
            title: 'Вы действительно хотите ОТМЕНИТЬ заказ?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.failOrder(orderId, reason, subReason)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Заказ успешно отменен'
                    })
                })
                .catch(alert)
        });
    }

    const replaceDriverInTrip = async (tripId: number, driverId: number) => {
        Modal.confirm({
            title: 'Вы действительно хотите ИЗМЕНИТЬ водителя на маршруте?',
            centered: true,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => DeliveryApi.replaceDriverInTrip(tripId, driverId)
                .then(() => {
                    updateWatchedTrips(tripPlan.id, selectedTripId);
                    setSelectedOrderId(undefined)
                    Modal.success({
                        content: 'Водитель успешно изменен'
                    })
                })
                .catch(alert)
        });
    }

    const transferOrders = (toTripId: number, orderIds: number[]) => {
        DeliveryApi.transferOrders(orderIds, toTripId)
            .then(() => {
                updateWatchedTrips(tripPlan.id, selectedTripId);
                Modal.success({
                    content: 'Заказы успешно переданы'
                })})
            .catch(alert)
    }

    return (
        <div className={classNames('trip-plan-realtime', props.className)}>
            <div className="trip-plan-realtime__sidebar">
                <div>
                    <div className='trip-plan-realtime__top'>
                        <AddOrdersBlock
                            hrefExcel={`/plans/${tripPlan.id}/add-orders`}
                            href1s={`/plans/${tripPlan.id}/add-1s-orders?returnUrl=${window.location.pathname}`}
                            planId={tripPlan.id}
                        />
                        <DropdownList
                            list={availableDriversToAddPlan}
                            getItem={i => i.driver.driver.name}
                            getClassname={i => i.workingShift === TripWorkingShift.EVENING ? 'trip-plan-realtime_gray-gradient' : ''}
                            onSelect={onAddDriver}
                        >
                            <Button type='primary'>Добавить маршрут</Button>
                        </DropdownList>
                    </div>
                    <div className='trip-plan-realtime__top'>
                        <PlanOrderSearcher
                            planId={tripPlan.id}
                            onSelectTrip={selectTrip}
                            onSelectOrder={setSelectedOrderId}
                        />
                    </div>
                </div>
                <div className='trip-plan-realtime__sidebar-list'>
                    <TripList
                        tripPlan={tripPlan}
                        loading={loading}
                        onOpenItinerary={getItinerary}
                        onResumeTrip={resumeTrip}
                        trips={watchedTrips}
                        selectedTripId={selectedTripId}
                        onSelectTrip={selectTrip}
                        onSelectOrder={setSelectedOrderId}
                        selectedOrderId={selectedOrderId}
                        onTransferOrders={transferOrders}
                        onReplaceDriverInTrip={replaceDriverInTrip}
                    />
                </div>
            </div>
            <div className='trip-plan-realtime__map-container'>
                {selectedOrder && selectedTrip && (
                    <div
                        className='trip-plan-realtime__order-details-container'>
                        <OrderInfo
                            checkpoint={selectedOrder}
                            onFailOrder={failOrder}
                            onRestoreOrder={restoreOrder}
                            onEditOrder={editDoneOrder}
                            onRescheduleOrder={rescheduleOrder}
                            onCloseOrderDetails={() => setSelectedOrderId(undefined)}
                            onTransferOrder={transferOrder}
                            trip={selectedTrip}
                            trips={tripPlan.trips}
                        />
                    </div>
                )}
                <PlanMap
                    className="trip-plan-realtime__map"
                    actualRoutes={watchedTrips.map(trip =>
                        new CarTrackedLocations(trip,
                            trip.executor.car.carNumber,
                            trip.executor.driver.name,
                            trip.problemRoute)
                    )}
                    routes={watchedTrips.map(i => i.isRouteSet() ? i.route : undefined)}
                    stores={tripPlan.stores}
                    districts={districts}
                    selectedItemIndex={selectedOrderId}
                    selectedTripIndex={watchedTrips.findIndex(i => i.id === selectedTripId)}
                    onItemSelect={setSelectedOrderId}
                    onTripSelect={selectTrip}
                    showOnlySelectedTrip={false}
                    withActualRoute={withActualRoute}
                    setWithActualRoute={setWithActualRoute}
                />
            </div>
        </div>
    );
};
