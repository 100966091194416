import {
    GeocodeSource as ApiGeocodeSource,
    GeocodeSource1 as ApiGeocodeSource1,
} from '../../../../../ApiClient/Yoso/models';
import { GeocodeSource } from '../../../../../Model/Place/GeocodeSource';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class GeocodeSourceSerializer implements IApiModelSerializer<GeocodeSource, ApiGeocodeSource> {
    public serialize(obj: GeocodeSource): ApiGeocodeSource {
        const result =
            obj === GeocodeSource.DaData
                ? ApiGeocodeSource.DADATA
                : obj === GeocodeSource.Excel
                ? ApiGeocodeSource.EXCEL
                : obj === GeocodeSource.Nominatim
                ? ApiGeocodeSource.NOMINATIM
                : obj === GeocodeSource.Unknown
                ? ApiGeocodeSource.UNKNOWN
                : obj === GeocodeSource.User
                ? ApiGeocodeSource.USER
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown GeocodeSource '${obj?.toString()}'`);
        }

        return result;
    }

    public serialize1(obj: GeocodeSource): ApiGeocodeSource1 {
        const result =
            obj === GeocodeSource.DaData
                ? ApiGeocodeSource1.DADATA
                : obj === GeocodeSource.Excel
                ? ApiGeocodeSource1.EXCEL
                : obj === GeocodeSource.Nominatim
                ? ApiGeocodeSource1.NOMINATIM
                : obj === GeocodeSource.Unknown
                ? ApiGeocodeSource1.UNKNOWN
                : obj === GeocodeSource.User
                ? ApiGeocodeSource1.USER
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown GeocodeSource '${obj?.toString()}'`);
        }

        return result;
    }
}
export const geocodeSourceSerializer = new GeocodeSourceSerializer();
