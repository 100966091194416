import {DateTimePeriod} from '../../Std/DateTimePeriod';
import {Geocode} from '../Geocode/Geocode';
import {TripDriverPlace} from './TripDriverPlace';

export class TripComparisonOrder {
    public id: number;
    public address: string;
    public buildingAddress: string;
    public clientName: string;
    public dateTimePeriod: DateTimePeriod;
    public coords: Geocode;
    public shipmentCount: number;
    public driverPlace: TripDriverPlace | undefined;

    public constructor(
        id: number,
        address: string,
        buildingAddress: string,
        clientName: string,
        dateTimePeriod: DateTimePeriod,
        coords: Geocode,
        shipmentCount: number,
        driverPlace: TripDriverPlace | undefined
    ) {
        this.id = id;
        this.address = address;
        this.buildingAddress = buildingAddress;
        this.clientName = clientName;
        this.dateTimePeriod = dateTimePeriod;
        this.coords = coords;
        this.shipmentCount = shipmentCount;
        this.driverPlace = driverPlace;
    }
}