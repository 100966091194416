import { User } from '../../../../../Model/User/User';
import { UserRole } from '../../../../../Model/User/UserRole';

import { IAuthStorage } from './IAuthStorage';

enum AuthLocalStorageKey {
    Token = 'yoso_token',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    UserRole = 'yoso_user_role',
    UserUsername = 'yoso_user_username',
}

// TODO: move into app state
export class AuthLocalStorageStorage implements IAuthStorage {
    private static getValue(key: AuthLocalStorageKey): string | undefined {
        const value = window.localStorage.getItem(key);

        return value === null ? undefined : value;
    }

    private static removeValue(key: AuthLocalStorageKey): void {
        window.localStorage.removeItem(key);
    }

    private static setValue(key: AuthLocalStorageKey, value: string | undefined): void {
        window.localStorage.setItem(key, value === undefined ? '' : value);
    }

    private _user: User | undefined;

    public constructor() {
        const userUsername = AuthLocalStorageStorage.getValue(AuthLocalStorageKey.UserUsername);
        const token = AuthLocalStorageStorage.getValue(AuthLocalStorageKey.Token);
        const userRoleString = AuthLocalStorageStorage.getValue(AuthLocalStorageKey.UserRole);
        if (userUsername !== undefined && token !== undefined && userRoleString !== undefined) {
            this._user = new User(userUsername, token, UserRole.fromString(userRoleString));
        }
    }

    public get user(): User | undefined {
        return this._user;
    }

    public set user(value: User | undefined) {
        this._user = value;

        if (this._user === undefined) {
            AuthLocalStorageStorage.removeValue(AuthLocalStorageKey.UserUsername);
            AuthLocalStorageStorage.removeValue(AuthLocalStorageKey.UserRole);
            AuthLocalStorageStorage.removeValue(AuthLocalStorageKey.Token);
        } else {
            AuthLocalStorageStorage.setValue(AuthLocalStorageKey.UserUsername, this._user.username);
            AuthLocalStorageStorage.setValue(AuthLocalStorageKey.UserRole, this._user.role.roleToString());
            AuthLocalStorageStorage.setValue(AuthLocalStorageKey.Token, this._user.token);
        }
    }
}
