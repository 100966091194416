import {
    ApiCarMoveAndStopLocation,
    ApiGeocode,
    DeliveryApiTripPoint,
    DeliveryApiTripRouteItem,
    DeliveryTripAnalysis,
} from '../../../../../ApiClient/Yoso/models';
import { MapColor } from '../../../../../Components/Map/MapColor';
import { CarMoveAndStopLocation } from '../../../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocation';
import { CarTrackedLocation } from '../../../../../Model/CarTrackedLocation/CarTrackedLocation';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { IRouteItem } from '../../../../../Model/TripAnalysis/IRouteItem';
import { ITripAnalysisData } from '../../../../../Model/TripAnalysis/ITripAnalysisData';
import { ITripPoint } from '../../../../../Model/TripAnalysis/ITripPoint';
import { DateTime } from '../../../../../Std/DateTime';
import { DateTimePeriod } from '../../../../../Std/DateTimePeriod';
import { Duration } from '../../../../../Std/Duration';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import { dateTimePeriodDeserializer } from '../../Model/DateTimePeriod/DateTimePeriodDeserializer';
import { orderStatusDeserializer } from '../../Model/OrderStatus/OrderStatusDeserializer';

interface ITripPointsById {
    [id: string]: ITripPoint;
}

export class GetTripAnalysisDeserializer implements IResponseDeserializer<DeliveryTripAnalysis, ITripAnalysisData> {
    public deserialize(apiTripAnalysis: DeliveryTripAnalysis): ITripAnalysisData {
        const points = this.mapTripPoints(apiTripAnalysis.points);

        return {
            approvedAt: apiTripAnalysis.tripApprovedAt ? dateTimeDeserializer.deserialize(apiTripAnalysis.tripApprovedAt) : undefined,
            startedAt: apiTripAnalysis.tripStartedAt ? dateTimeDeserializer.deserialize(apiTripAnalysis.tripStartedAt) : undefined,
            finishedAt: apiTripAnalysis.tripFinishedAt ? dateTimeDeserializer.deserialize(apiTripAnalysis.tripFinishedAt) : undefined,
            actualRoute: this.mapRoute(apiTripAnalysis.actualRoute, points, true),
            actualRouteDistance: apiTripAnalysis.actualRouteDistance,
            actualRouteDuration: Duration.fromSeconds(apiTripAnalysis.actualRouteDuration),
            actualRouteSimilarity: apiTripAnalysis.actualRouteSimilarity,
            actualRouteVsEstimatedSimilarity: apiTripAnalysis.actualRouteVsEstimatedSimilarity,
            actualRouteTrack: this.mapActualRouteTrack(apiTripAnalysis.carTrackedLocations),
            moveAndStopLocations: this.mapMoveAndStopLocations(apiTripAnalysis.carMoveAndStopLocations),
            estimatedRoute: this.mapRoute(apiTripAnalysis.estimatedRoute, points, false),
            estimatedRouteDistance: apiTripAnalysis.estimatedRouteDistance,
            estimatedRouteDuration: Duration.fromSeconds(apiTripAnalysis.estimatedRouteDuration),
            route: this.mapRoute(apiTripAnalysis.route, points, false),
            routeDistance: apiTripAnalysis.routeDistance,
            routeDuration: Duration.fromSeconds(apiTripAnalysis.routeDuration),
            routeSimilarity: apiTripAnalysis.routeSimilarity,
            actualRouteCongruence: {
                indices: apiTripAnalysis.actualRouteCongruence.first,
                actualIndices: apiTripAnalysis.actualRouteCongruence.second
            },
            estimatedRouteCongruence: {
                indices: apiTripAnalysis.estimatedRouteCongruence.first,
                estimatedIndices: apiTripAnalysis.estimatedRouteCongruence.second
            },
            estimatedAndActualRouteCongruence: {
                estimatedIndices: apiTripAnalysis.estimatedAndActualRouteCongruence.first,
                actualIndices: apiTripAnalysis.estimatedAndActualRouteCongruence.second
            }
        };
    }

    private mapActualRouteTrack(apiActualRouteTrack: any[]) {
        return apiActualRouteTrack.map(
            (location) =>
                new CarTrackedLocation(
                    new Geocode({
                        latitude: location.geocode.latitude,
                        longitude: location.geocode.longitude,
                    }),
                    dateTimeDeserializer.deserialize(location.dateTime),
                ),
        );
    }

    private mapMoveAndStopLocations(apiLocations: ApiCarMoveAndStopLocation[]) {
        return apiLocations.map(
            (location) =>
                new CarMoveAndStopLocation(
                    dateTimeDeserializer.deserialize(location.startTime),
                    location.endTime ? dateTimeDeserializer.deserialize(location.endTime) : undefined,
                    new Geocode({
                        latitude: location.geocode.latitude!,
                        longitude: location.geocode.longitude!,
                    }),
                    location.label
                ),
        );
    }

    private mapRoute(apiRoute: DeliveryApiTripRouteItem[], points: ITripPointsById, inPromisedPeriodColor: boolean): IRouteItem[] {
        return apiRoute.map(
            (apiRouteItem: DeliveryApiTripRouteItem, index: number): IRouteItem => ({
                id: apiRouteItem.pointId,
                point: points[apiRouteItem.pointId],
                stayPeriod: new DateTimePeriod(
                    dateTimeDeserializer.deserialize(apiRouteItem.arrivedAt),
                    dateTimeDeserializer.deserialize(apiRouteItem.departureAt),
                ),
                arrivalDistance: apiRouteItem.distanceFromPrevPoint,
                arrivalWaypoints: apiRouteItem.waypointsFromPrevPoint.map(
                    (apiGeocode: ApiGeocode): Geocode =>
                        new Geocode({
                            latitude: apiGeocode.latitude!,
                            longitude: apiGeocode.longitude!,
                        }),
                ),
                firstPoint: index === 0,
                warnRouteItem: apiRouteItem.isAddedAfterStart,
                color: this.getRouteItemColor(points[apiRouteItem.pointId], dateTimeDeserializer.deserialize(apiRouteItem.arrivedAt), inPromisedPeriodColor),
                deliverySize: apiRouteItem.deliverySize,
                totalDeliverySize: apiRouteItem.totalDeliverySize,
                completeOrderServerTime: apiRouteItem.completeOrderServerTime
                    ? dateTimeDeserializer.deserialize(apiRouteItem.completeOrderServerTime)
                    : undefined
            }));
    }

    private getRouteItemColor(point: ITripPoint, arrivedAt: DateTime, inPromisedPeriodColor: boolean): MapColor | undefined {
        if (inPromisedPeriodColor && point.promisedDateTimePeriod) {
            if (point.promisedDateTimePeriod.inPeriod(arrivedAt)) {
                return MapColor.Green;
            } else if (point.desiredDateTimePeriod && !point.desiredDateTimePeriod.inPeriod(arrivedAt)) {
                return MapColor.Red;
            } else {
                return MapColor.Orange;
            }
        } 

        if (point.desiredDateTimePeriod && !point.desiredDateTimePeriod.inPeriod(arrivedAt)) {
            return MapColor.Red;
        }

        return undefined;
    }

    private mapTripPoints(apiTripPoints: DeliveryApiTripPoint[]): ITripPointsById {
        const points: ITripPointsById = {};
        apiTripPoints.forEach((apiTripPoint) => {
            points[apiTripPoint.id] = {
                address: apiTripPoint.address,
                geocode: new Geocode({
                    latitude: apiTripPoint.geocode.latitude!,
                    longitude: apiTripPoint.geocode.longitude!,
                }),
                id: apiTripPoint.id,
                status: orderStatusDeserializer.deserialize(apiTripPoint.status),
                desiredDateTimePeriod: apiTripPoint.desiredDateTimePeriod
                    ? dateTimePeriodDeserializer.deserialize(apiTripPoint.desiredDateTimePeriod)
                    : undefined,
                promisedDateTimePeriod: apiTripPoint.promisedDateTimePeriod
                    ? dateTimePeriodDeserializer.deserialize(apiTripPoint.promisedDateTimePeriod)
                    : undefined,
                expectedArrivalTime: apiTripPoint.expectedArrivalTime
                    ? dateTimeDeserializer.deserialize(apiTripPoint.expectedArrivalTime)
                    : undefined,
                shouldSendTimeNotification: apiTripPoint.shouldSendTimeNotification,
                isTimeNotification: apiTripPoint.isTimeNotification,
                timeNotificationSent: apiTripPoint.timeNotificationSent,
                doneAtAddress: apiTripPoint.doneAtAddress,
                doneAfterAddressTime: apiTripPoint.doneAfterAddressTime
            };
        });

        return points;
    }
}

export const getTripAnalysisDeserializer = new GetTripAnalysisDeserializer();
