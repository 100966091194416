import * as React from 'react';
import { Button, Table } from 'antd';
import { observer } from 'mobx-react';
import { ColumnsType } from 'antd/lib/table';
import {
    RatingStatistics
} from '../../Model/RatingStatistics/RatingStatistics';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { MathUtils } from '../../Std/MathUtils';
import { ChartPage, ChartsStore } from '../../Store/ChartsStore/ChartsStore';
import CellBar from '../CellBar/CellBar';
import styles from './ChartDriverRatingList.module.css';

export interface IChartDriverRatingListProps {
    store: ChartsStore
}

export const ChartDriverRatingList = observer((props: IChartDriverRatingListProps): JSX.Element => {
    const {store} = props
    const requiredEmployedDays = 12;

    React.useEffect(() => {
        store.loadChartPage(ChartPage.Shipment);
    }, []);

    const formatValueAndScore = (value: number, score: number) => `${value ? value : ''}${score > 0 ? ` (${score})` : ''}`;

    const columns: ColumnsType<RatingStatistics> = [
        {
            title: 'Имя',
            dataIndex: 'driverName',
            key: 'driverName',
            sorter: (a, b) => a.driverName.localeCompare(b.driverName),
        },
        {
            title: 'Отработано, дни',
            dataIndex: 'employedDays',
            key: 'employedDays',
            sorter: (a, b) => a.employedDays - b.employedDays,
        },
        {
            title: 'Развоз, экв. бут',
            key: 'shipment',
            render: (a, record) =>
                MathUtils.round(record.doneOrdersBottlesCount + record.doneOrdersNotBottlesCount, 1),
            sorter: (a, b) => (a.doneOrdersBottlesCount + a.doneOrdersNotBottlesCount) - (b.doneOrdersBottlesCount + b.doneOrdersNotBottlesCount),
        },
        // {
        //     title: 'Развоз, бут',
        //     dataIndex: 'doneOrdersBottlesCount',
        //     key: 'doneOrdersBottlesCount',
        // },
        {
            title: 'Рейтинг по развозу',
            key: 'rating',
            render: (a, record) => formatValueAndScore(record.rating, record.ratingScore)
        },
        {
            title: 'Выполнено заказов, шт.',
            key: 'doneOrdersCount',
            dataIndex: 'doneOrdersCount',
            sorter: (a, b) => a.doneOrdersCount - b.doneOrdersCount,
        },
        {
            title: 'Рейтинг по кол-ву заказов',
            key: 'ordersCountRating',
            render: (a, record) => formatValueAndScore(record.doneOrdersRating, record.doneOrdersRatingScore)
        },
        {
            title: '% не выполненных заказов',
            key: 'uncompletedOrdersCountPercentage',
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            sorter: (a, b) => (a.uncompletedOrdersPercentage ?? 100) - (b.uncompletedOrdersPercentage ?? 100),
            render: (a, record) => {
                if (a.uncompletedOrdersPercentage === undefined) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={a.uncompletedOrdersPercentage}
                        title={`${a.uncompletedOrdersPercentage}% (${record.uncompletedOrdersScore})`}
                    />
                )
            }
        },
        // {
        //     title: 'Рейтинг по невыполненным заказам',
        //     key: 'uncompletedOrdersRating',
        //     sorter: (a, b) => a.uncompletedOrdersRating - b.uncompletedOrdersRating,
        //     render: (a, record) => formatValueAndScore(record.uncompletedOrdersRating, record.uncompletedOrdersRatingScore)
        // },
        {
            title: 'Чужие заказы, кол-во',
            key: 'receivedOrders',
            render: (a, record) => formatValueAndScore(record.receivedDoneOrdersCount, record.receivedDoneOrdersScore)
        },
        {
            title: '% отправленных смс',
            key: 'notificationSendRatio',
            render: (a, record) => {
                if (!record.notificationSendRatio) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={record.notificationSendRatio}
                        title={`${record.notificationSendRatio}%`}
                    />
                )
            }
        },
        {
            title: '% попаданий в смс',
            key: 'inNotificationPeriodRatio',
            sorter: (a, b) => (a.inNotificationPeriodRatio || -1) - (b.inNotificationPeriodRatio || -1),
            render: (a, record) => {
                if (record.inNotificationPeriodRatio === undefined) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={record.inNotificationPeriodRatio}
                        title={`${record.inNotificationPeriodRatio}% (${record.doneOrdersInNotificationPeriodScore})`}
                    />
                )
            }
        },
        {
            title: '% проставленных тчк',
            key: 'driverPlaceSetRatio',
            render: (a, record) => {
                if (!record.driverPlaceSetRatio) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={record.driverPlaceSetRatio}
                        title={`${record.driverPlaceSetRatio}% (${record.driverPlacesSetScore})`}
                    />
                )
            }
        },
        {
            title: 'Баллы',
            key: 'totalScore',
            sorter: (a, b) => a.totalScore - b.totalScore,
            render: (a, record) => <div className={styles.bold}>
                {record.totalScore}
            </div>
        },
        {
            title: 'Закрыто не на тчк',
            key: 'doneAtAddressRatio',
            render: (a, record) => {
                if (!record.doneAtAddressRatio) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={record.doneAtAddressRatio}
                        title={`${record.doneAtAddressRatio}%`}
                    />
                )
            }
        },
        {
            title: 'Сомнительные тчк',
            key: 'suspectedDriverPlaceSetRatio',
            render: (a, record) => {
                if (!record.suspectedDriverPlaceSetRatio) {
                    return '';
                }

                return (
                    <CellBar
                        widthPercentage={record.suspectedDriverPlaceSetRatio}
                        title={`${record.suspectedDriverPlaceSetRatio}%`}
                    />
                )
            }
        }
    ];

    const downloadCsv = () => {
        let csv = '\uFEFF' + 'Имя;1с код;Отработано дни;Развоз экв. бут;Баллы за развоз;Выполнено заказов шт.;Баллы за количество выполненных заказов;' +
            '% не выполненных заказов;Баллы за выполнение заказов;Чужие заказы кол-во;Баллы за выполнение чужих заказов;% отправленных смс;' +
            '% попаданий в смс;Баллы за попадание в смс;% проставленных тчк;% сомнительных тчк;Баллы за проставленные тчк;Баллы итого;' +
            '% Закрыто не на тчк\n'
        ArrayUtils.sort(store.shipmentStats, by => by.totalScore, true, false).forEach((row) => {
            const rowStr = `${row.driverName};${row.driverCode};${row.employedDays};${row.doneOrdersShipmentCount};${row.ratingScore};${row.doneOrdersCount};${row.doneOrdersRatingScore};${row.uncompletedOrdersPercentage ?? ''};${row.uncompletedOrdersScore};${row.receivedDoneOrdersCount};${row.receivedDoneOrdersScore};${row.notificationSendRatio ?? ''};${row.inNotificationPeriodRatio ?? ''};${row.doneOrdersInNotificationPeriodScore};${row.driverPlaceSetRatio ?? ''};${row.suspectedDriverPlaceSetRatio ?? ''};${row.driverPlacesSetScore};${row.totalScore};${row.doneAtAddressRatio ?? ''}\n`
            csv += rowStr.replace(/\./g, `,`)
        })
        const element = document.createElement('a')
        const file = new Blob([csv], {type: 'text/csv;charset=utf-16'})
        element.href = URL.createObjectURL(file)
        element.download = `${store.startDate.format('YYYY-MM-DD')}_${store.endDate.format('YYYY-MM-DD')}.csv`
        document.body.appendChild(element)
        element.click()
    }
    
    return (
        <div className={styles.wrapper}>
            <Button type='primary' className={styles.download} onClick={downloadCsv}>Скачать</Button>
            <div className={styles.container}>
                <Table<RatingStatistics>
                    size='small'
                    pagination={false}
                    dataSource={ArrayUtils.sort(props.store.shipmentStats, [(x) => -x.totalScore, (x) => x.rating])}
                    columns={columns}
                    loading={props.store.loading}
                    rowKey='driverName'
                    rowClassName={(record) => record.employedDays < requiredEmployedDays ? styles.gray : ''}
                    onRow={(record) => ({
                        onClick: () => {
                            const shipmentStat = props.store.shipmentStats.find((st) => st.driverId === record.driverId);
                            if (shipmentStat) {
                                props.store.loadDriverPage(shipmentStat.driverId, shipmentStat.driverName);
                            }
                        }
                    })}
                />
            </div>
        </div>
    )
})