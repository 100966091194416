import { DistrictGroupCode } from "./DistrictGroupCode"

export class DistrictGroup {
    public key: DistrictGroupCode
    public label: string

    public constructor(key: DistrictGroupCode, label: string) {
        this.key = key
        this.label = label
    }
}