import {makeAutoObservable} from 'mobx';
import moment from 'moment';
import {RouteInfo} from '../../Backend/Api/Delivery/Model/RouteInfo/RouteInfo';
import {DriverPoint} from '../../Model/DriverPoint/DriverPoint';
import {Geocode} from '../../Model/Geocode/Geocode';
import {Store} from '../../Model/Store/Store';
import {TripComparison} from '../../Model/TripComparison/TripComparison';
import {
    TripComparisonOrder
} from "../../Model/TripComparison/TripComparisonOrder";
import { TripComparisonRouteSequenceItem } from '../../Model/TripComparison/TripComparisonRouteSequenceItem';
import {TripDriverPlace} from '../../Model/TripComparison/TripDriverPlace';
import {TripPointType} from '../../Model/TripPointId/TripPointType';
import { ArrayUtils } from '../../Std/ArrayUtils';
import {DateTime} from '../../Std/DateTime';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import { TimePeriod } from '../../Std/TimePeriod';
import {DriverRouteEditing} from './DriverRouteEditing';

interface RouteSequenceItem { point: Geocode, pathToPoint: Geocode[], content?: string, tooltip?: string, distance: number, duration: number }

export class TripComparisonStore {
    public name: string | undefined = undefined;
    public date: DateTime | undefined = undefined;
    public orders: TripComparisonOrder[] = [];
    public driverPlaces: TripDriverPlace[] = [];
    public selectedOrder: TripComparisonOrder | undefined;
    public startPoints: DriverPoint[] = [];
    public finishPoints: DriverPoint[] = [];
    public intermediatePoints: DriverPoint[] = [];
    public stores: Store[] = [];
    public routes: RouteInfo[] = [];

    public startPoint: DriverPoint | undefined = undefined;
    public finishPoint: DriverPoint | undefined = undefined;
    public firstPoint: DriverPoint | TripComparisonOrder | undefined = undefined;
    public startTime: DateTime | undefined = undefined;
    public selectedRoute: RouteInfo | undefined = undefined;
    public routeEditing: DriverRouteEditing | undefined = undefined;

    private _isYandexRoutes = true;

    public constructor() {
        makeAutoObservable(this);
    }

    public setTrip(trip: TripComparison): void {
        this.name = trip.getName();
        this.date = trip.getDate();
        this.orders = trip.getOrders();
        this.driverPlaces = trip.getDriverPlaces();
        this.routes = trip.getRoutes();
        this.startPoints = trip.getStartPoints();
        this.finishPoints = trip.getFinishPoints();
        this.intermediatePoints = trip.getIntermediatePoints();
        this.startTime = trip.getStartTime();
        this.startPoint = trip.getStartPoint();
        this.finishPoint = trip.getFinishPoint();
        this.firstPoint = trip.getFirstPoint();
        this.stores = trip.getStores();

        this.orders.forEach(order => {
            if (!order.driverPlace) {
                return;
            }
            const driverPlace = this.driverPlaces.find(dp => order.driverPlace === dp);
            if (!driverPlace) {
                return;
            }
        });
    }

    public set isYandexRoutes(value: boolean) {
        this._isYandexRoutes = value;
        this.routes.map(r => r.setIsYandexRoute(value));
    }

    public get isYandexRoutes() {
        return this._isYandexRoutes;
    }

    public selectOrder(id: number | undefined): void {
        this.selectedOrder = this.orders?.find(o => o.id === id);
    }

    public addNewDriverPlace(coords: Geocode): void {
        if (!this.selectedOrder) {
            return;
        }

        const driverPlace = new TripDriverPlace(coords);
        this.selectedOrder.driverPlace = driverPlace;
        this.driverPlaces.push(driverPlace);
    }

    public addDriverPlace(place: TripDriverPlace): void {
        if (!this.selectedOrder) {
            return;
        }

        this.selectedOrder.driverPlace = place;
        this.driverPlaces.push(place);
    }

    public removeDriverPlace(driverPlace: TripDriverPlace): void {
        if (driverPlace.id) {
            return;
        }

        const order = this.orders.find(o => o.driverPlace === driverPlace)
        if (order) {
            order.driverPlace = undefined;
        }

        this.driverPlaces = this.driverPlaces.filter(p => p !== driverPlace);
    }

    public selectRouteEditing(route: RouteInfo | undefined): void {
        this.routeEditing = route
            ? new DriverRouteEditing(
                route, this.startPoint,
                this.finishPoint,
                this.startTime ? moment(this.startTime) : null
            ) : undefined;
    }

    public selectRoute(route: RouteInfo): void {
        this.selectedRoute = this.selectedRoute !== route ? route : undefined;
    }

    public setFirstPoint(point: DriverPoint | TripComparisonOrder | undefined): void {
        this.firstPoint = point;
    }

    public get routeBySequence(): RouteSequenceItem[]  {
        if (!this.selectedRoute
            || !this.selectedRoute.sequence.length
            || !this.selectedRoute.waypoints.length) {
            return [];
        }

        const route: RouteSequenceItem[] = [];
        let prevDriverPlace: TripDriverPlace | undefined;
        let driverPlaceIndex = 0;
        let items: TripComparisonRouteSequenceItem[] = [];

        const addSequenceItem = (lastItemIndex: number) => {
            const lastItem = items[items.length - 1];

            const lastPoint: TripComparisonOrder | Store | undefined = lastItem.type === TripPointType.Order
                ? this.orders.find(o => o.id === lastItem.id)
                : this.stores.find(s => s.id === lastItem.id);
            const firstWaypoints = this.selectedRoute?.waypoints[lastItemIndex - items.length + 1];
            const lastWaypoints = this.selectedRoute?.waypoints[lastItemIndex];

            if (!lastPoint || !firstWaypoints || !lastWaypoints) {
                return;
            }

            const arrivalTime = firstWaypoints.arrivalTime;
            const departureTime = lastWaypoints.departureTime;            

            let coords: Geocode;
            let content: string;
            let address: string;

            if (lastPoint instanceof TripComparisonOrder) {
                coords = lastPoint.driverPlace ? lastPoint.driverPlace.coords : lastPoint.coords;
                content = (driverPlaceIndex + 1).toString();                
                driverPlaceIndex += 1;
                address = lastPoint.buildingAddress;
            } else {
                coords = lastPoint.coordinates;
                content = 'С';
                address = lastPoint.address;
            }
            
            const period = formatTimePeriod(new TimePeriod(arrivalTime, departureTime));
            const periods = ArrayUtils.defined(items.map(item => item.period ? `[${formatTimePeriod(item.period)}]` : undefined)).join(', ');
            const shipmentChanges = items.map(item => item.shipmentChange > 0 ? `+${item.shipmentChange}` : `${item.shipmentChange}`).join('');

            let tooltip = `${period}<br>${periods}<br><b>${shipmentChanges}`;
            if (this.selectedRoute?.withLoadingStore) {
                tooltip += `=${lastItem.currentShipment}`;
            }
            tooltip += `</b><br>${address}`;

            route.push({
                point: coords,
                pathToPoint: firstWaypoints.geocodes,
                distance: firstWaypoints.distance,
                duration: firstWaypoints.duration,
                content: content,
                tooltip: tooltip
            })
        }

        this.selectedRoute.sequence.forEach((value, index) => {
            const point: TripComparisonOrder | Store | undefined = value.type === TripPointType.Order
                ? this.orders.find(o => o.id === value.id)
                : this.stores.find(s => s.id === value.id);

            if (index === 0 || ((point instanceof TripComparisonOrder) && point.driverPlace === prevDriverPlace)) {
                prevDriverPlace = (point instanceof TripComparisonOrder) ? point.driverPlace : undefined;
                items.push(value);

                return;
            }

            addSequenceItem(index - 1);

            prevDriverPlace = (point instanceof TripComparisonOrder) ? point.driverPlace : undefined;
            items = [];
            items.push(value);
        })

        addSequenceItem(this.selectedRoute.sequence.length - 1);

        if (this.finishPoint) {
            const waypoints = this.selectedRoute.waypoints[this.selectedRoute.waypoints.length-1];
            route.push({
                point: this.finishPoint.coords,
                pathToPoint: waypoints.geocodes,
                distance: waypoints.distance,
                duration: waypoints.duration,
                content: 'Ф',
                tooltip: this.finishPoint.address
            })
        }

        return route;
    }

    public get orderDriverPlaces(): TripDriverPlace[] {
        return [...new Set(this.orders.filter(o => o.driverPlace).map(item => item.driverPlace!))];
    }
}