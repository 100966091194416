export enum DistrictGroupCode {
    Morning = "MORNING",
    Evening = "EVENING",
    DayOff = "DAY_OFF",
}

type DistrictGroupCodeValues = keyof typeof DistrictGroupCode;

const reverseMapping: Record<string, DistrictGroupCodeValues> = {};
Object.keys(DistrictGroupCode).forEach(key => {
    const value = DistrictGroupCode[key as DistrictGroupCodeValues];
    reverseMapping[value] = key as DistrictGroupCodeValues;
});

export const getDistrictGroupCodeFromValue = (value: string): DistrictGroupCode | undefined => {
    const districtGroupCodeValue = reverseMapping[value];
    if (districtGroupCodeValue !== undefined) {
        return DistrictGroupCode[districtGroupCodeValue];
    }

    return undefined;
}

export const getDistrictGroupCodeName = (code: DistrictGroupCode): string => {
    switch (code) {
        case DistrictGroupCode.Morning:
            return "День";
        case DistrictGroupCode.Evening:
            return "Вечер";
        case DistrictGroupCode.DayOff:
            return "Выходной";
        default:
            return "Неизвестен";
    }
}