import React from 'react';
import {observer} from "mobx-react";
import {District} from '../../../Store/District/District';
import styles from './DistrictName.module.css';

interface DistrictNameProps {
    district: District;
}

const DistrictName = observer((props: DistrictNameProps): JSX.Element => (
    <>
        <div className={props.district.name ? '' : styles.red}>
            {props.district.name ? props.district.name : 'Без названия'}
        </div>
        {props.district.drivers.length > 0 &&
            <div className={styles.driverNames}>
                {props.district.drivers.map(x => (
                    <div key={x.id}>
                        {x.name}<br/>
                    </div>
                ))}
            </div>
        }
    </>
));

export default DistrictName;