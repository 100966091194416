import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactComponent as CheckpointIconComponent } from '../../icons/checkpoint.svg';
import { ReactComponent as LoadingIconComponent } from '../../icons/loading.svg';
import { ReactComponent as LocationBigIconComponent } from '../../icons/location-big.svg';
import { ReactComponent as TimeBigIconComponent } from '../../icons/time-big.svg';
import { Trip } from '../../Model/Trip/Trip';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { ByIndexColorPicker } from '../../Std/ColorScheme/ByIndexColorPicker';
import { formatDistance } from '../../Std/FormatDistance';
import { formatDistanceInKilometers } from '../../Std/FormatDistanceInKilometers';
import { formatDurationHoursMinutes } from '../../Std/formatDurationHoursMinutes';
import { formatNumber } from '../../Std/FormatNumber';
import { EntityList, EntityListColumnAlign } from '../EntityList/EntityList';
import { LoadingInfo } from '../LoadingInfo/LoadingInfo';
import { MapColor } from '../Map/MapColor';
import RouteInfo2 from '../RouteInfo/RouteInfo2';
import { PlanStore } from '../../Store/PlanStore/PlanStore';
import { TripStatus } from '../../Model/Trip/TripStatus';
import './TripList.css';
import { TripWorkingShift } from '../../Model/Trip/TripWorkingShift';

export interface ITripListProps {
    planStore: PlanStore;
    className?: string;
    colorScheme?: ByIndexColorPicker<MapColor>;
    loadingInfo: TripExecutorsLoadingInfo;
}

export const TripList2 = observer(
    (props: ITripListProps): JSX.Element => {
        const { loadingInfo } = props;

        const renderRowExtendedContent = (trip: Trip) => (
            <div className="trip-plan-details__route-info-container">
                <div className="trip-plan-details__route-info-container_title">
                    Заказы ({trip.route.orders.length})
                    {trip.isRouteBuilt && trip.estimatedDistance !== undefined && (
                        <div>Запланировано: {formatDistance(trip.estimatedDistance)}</div>
                    )}
                    {trip.isRouteBuilt && trip.actualDistance !== undefined && (
                        <div>Проехано: {formatDistance(trip.actualDistance)}</div>
                    )}
                </div>
                <RouteInfo2 planStore={props.planStore} />
            </div>
        );

        const workingShiftToString = (workingShift: TripWorkingShift | undefined): JSX.Element | undefined => {
            let shift: JSX.Element | undefined;

            switch (workingShift) {
                case undefined:
                case TripWorkingShift.MORNING:
                    shift = <div className="trip-list__day-route">День</div>;
                    break;
                case TripWorkingShift.EVENING:
                    shift = <div className="trip-list__evening-route">Вечер</div>;
                    break;
                case TripWorkingShift.DAYOFF:
                    shift = <div>Короткий</div>;
                    break;
                default:
                    shift = undefined;
            }

            return shift;
        };

        const columns = [
            {
                key: 'executor',
                render: (trip: Trip) => (
                    <div className="trip-list__driver-row">
                        <div className="trip-list__driver-name">{trip.executor.driver.name}</div>
                        <div className="trip-list__driver-number">
                            {trip.executor.car.carNumber !== ''
                                ? trip.executor.car.carNumber
                                : workingShiftToString(trip.workingShift)}
                        </div>
                    </div>
                ),
                stretch: true,
            },
            {
                key: 'orders',
                render: (trip: Trip) => (
                    <div className="trip-list__trip-parameter">
                        <CheckpointIconComponent className="trip-list__trip-parameter-icon" />
                        <div className="trip-list__trip-parameter-text">{trip.ordersCount}</div>
                    </div>
                ),
                align: EntityListColumnAlign.Center,
                stretch: true,
            },
            {
                key: 'distance',
                render: (trip: Trip) => (
                    <div className="trip-list__trip-parameter">
                        <LocationBigIconComponent className="trip-list__trip-parameter-icon" />
                        <div className="trip-list__trip-parameter-text">
                            {trip.estimatedDistance === undefined || !trip.isRouteBuilt
                                ? '\u2013'
                                : formatDistanceInKilometers(trip.estimatedDistance, 0)}
                        </div>
                    </div>
                ),
                align: EntityListColumnAlign.Center,
                stretch: true,
            },
            {
                key: 'duration',
                render: (trip: Trip) => (
                    <div className="trip-list__trip-parameter">
                        <TimeBigIconComponent className="trip-list__trip-parameter-icon" />
                        <div className="trip-list__trip-parameter-text">
                            {trip.estimatedDuration === undefined || !trip.isRouteBuilt
                                ? '\u2013'
                                : formatDurationHoursMinutes(trip.estimatedDuration)}
                        </div>
                    </div>
                ),
                align: EntityListColumnAlign.Center,
                stretch: true,
            },
            {
                key: 'loading',
                render: (trip: Trip) => (
                    <div className="trip-list__trip-parameter">
                        <LoadingIconComponent className="trip-list__trip-parameter-icon" />
                        <div className="trip-list__trip-parameter-text trip-list__trip-parameter-text-loading">
                            <LoadingInfo
                                actual={loadingInfo.get(trip.executor) ?? 0}
                                actualBottles={loadingInfo.getBottles(trip.executor) ?? 0}
                                total={trip.executor.car.capacity}
                            />
                        </div>
                    </div>
                ),
                align: EntityListColumnAlign.Center,
                stretch: true,
            },
        ];

        const renderCellContent = React.useCallback((columnConfig, entity) => {
            const value = columnConfig.render(entity);

            return value === undefined ? '\u2013' : typeof value === 'number' ? formatNumber(value) : value;
        }, []);

        const renderTripListRow = React.useCallback(
            (entity: unknown) => (
                <td className="trip-list__table">
                    <div className="trip-list__upper-row">
                        {columns.map(
                            (column) =>
                                column.key === 'executor' && (
                                    <div
                                        key={column.key}
                                        className={classNames(
                                            'trip-list__driver-cell',
                                            'entity-list__cell',
                                            `entity-list__cell_align-${column.align ?? 'left'}`,
                                            'entity-list__data-cell',
                                            column.stretch && 'entity-list__cell_stretch',
                                        )}
                                    >
                                        {renderCellContent(column, entity)}
                                    </div>
                                ),
                        )}
                    </div>
                    <div className="trip-list__lower-row">
                        {columns.map(
                            (column) =>
                                column.key !== 'executor' && (
                                    <div
                                        key={column.key}
                                        className={classNames(
                                            'entity-list__cell',
                                            `entity-list__cell_align-${column.align ?? 'left'}`,
                                            'entity-list__data-cell',
                                            column.stretch && 'entity-list__cell_stretch',
                                        )}
                                    >
                                        {renderCellContent(column, entity)}
                                    </div>
                                ),
                        )}
                    </div>
                </td>
            ),
            [renderCellContent, columns],
        );

        return (
            <div className={classNames('trip-list', props.className)}>
                <EntityList<Trip>
                    small={true}
                    data={props.planStore.trips}
                    actions={{
                        item: {
                            click: (id: number) => (): undefined => {
                                props.planStore.updateSelectedTripId(id);

                                return;
                            },
                        },
                    }}
                    selectedItems={props.planStore.selectedTrip}
                    id={(trip) => trip.id}
                    rowColorMarker={
                        props.colorScheme === undefined
                            ? undefined
                            : (trip, index) => props.colorScheme!.renderColor(props.colorScheme!.get(index)).rgbaString
                    }
                    columns={columns}
                    renderRowExtendedContent={renderRowExtendedContent}
                    pageSize={0}
                    renderRow={renderTripListRow}
                    rowClass={(trip) => (trip.ordersCount === 0 ? 'trip-plan-details__none' : '')}
                    isEntityWithWarning={(trip) => trip.status === TripStatus.RouteBuildFailed}
                />
            </div>
        );
    },
);
