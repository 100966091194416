import { DeliveryApiLeg } from '../../../../../ApiClient/Yoso/models';
import { IGeocode } from '../../../../../Model/Geocode/IGeocode';
import { deserializerUtils } from '../../../../../Std/DeserializerUtils';
import { ILeg } from '../../../../Types/ILeg';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';

export class LegDeserializer {
    public deserialize(serializedObject: DeliveryApiLeg): ILeg {
        return {
            distance: serializedObject.distance,
            startId: serializedObject.startId,
            startType: serializedObject.startType,
            targetId: serializedObject.targetId,
            targetType: serializedObject.targetType,
            waypoints: this.deserializeWaypoints(serializedObject.waypoints),
            period:
                serializedObject.period === undefined
                    ? undefined
                    : dateTimePeriodDeserializer.deserialize(serializedObject.period),
        };
    }

    public deserializeWaypoints(serializedWaypoints: string): ReadonlyArray<IGeocode> {
        const packedWaypoints = atob(serializedWaypoints);
        const waypoints: IGeocode[] = [];
        let i = 0;
        while (i < packedWaypoints.length) {
            const latitude = deserializerUtils.deserializeFloat7(packedWaypoints, i);
            i += deserializerUtils.FLOAT7_SIZE;
            const longitude = deserializerUtils.deserializeFloat7(packedWaypoints, i);
            i += deserializerUtils.FLOAT7_SIZE;
            waypoints.push({ latitude, longitude });
        }

        return waypoints;
    }
}

export const legDeserializer = new LegDeserializer();
