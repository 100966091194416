import {
    DraftApiCar,
    DraftApiDistrictGroup,
    DraftApiDriver,
    DraftApiDrivers,
    DraftApiOrder,
    DraftApiPlanData,
    DraftApiProduct,
} from '../../../../ApiClient/YosoDraft/models';
import { Car } from '../../../../Model/Car/Car';
import { DraftOrder } from '../../../../Model/DraftOrder/DraftOrder';
import { DraftPlanData } from '../../../../Model/DraftPlanData/DraftPlanData';
import { Driver } from '../../../../Model/Driver/Driver';
import { Product } from '../../../../Model/Product/Product';
import { WarningMessage } from '../../../../Model/WarningMessage/WarningMessage';
import { DistrictGroup } from '../../../../Store/District/DistrictGroup';
import { dateTimeDeserializer } from '../../Mapper/DateTimeDeserializer';

import { CarMapper } from './CarMapper';
import { DistrictGroupMapper } from './DistrictGroupMapper';
import { DraftOrderMapper } from './DraftOrderMapper';
import { DriverMapper } from './DriverMapper';
import { ProductMapper } from './ProductMapper';

export class PlanDataMapper {
    public static mapFromApi(apiPlanData: DraftApiPlanData): DraftPlanData {
        const products = apiPlanData.products.map(
            (apiProduct: DraftApiProduct): Product => ProductMapper.mapFromApi(apiProduct),
        );
        const drivers = apiPlanData.drivers.map(
            (apiDriver: DraftApiDriver): Driver => DriverMapper.mapFromApi(apiDriver),
        );
        const cars = apiPlanData.cars.map((apiCar: DraftApiCar): Car => CarMapper.mapFromApi(apiCar));
        const orders = apiPlanData.orders.map(
            (apiOrder: DraftApiOrder, index: number): DraftOrder =>
                DraftOrderMapper.mapFromApi(apiOrder, index, products, drivers, cars),
        );
        const districtGroups = apiPlanData.districtGroups.map(
            (apiGroups: DraftApiDistrictGroup): DistrictGroup => DistrictGroupMapper.mapFromApi(apiGroups)
        );
        const warningMessages: WarningMessage[] = apiPlanData.warnings.map(
            (apiMessage: string) => new WarningMessage(apiMessage)
        );

        const uploadedAt = dateTimeDeserializer.deserialize(apiPlanData.uploadedAt);

        return new DraftPlanData(orders, products, drivers, cars, districtGroups, warningMessages, uploadedAt);
    }

    public static mapDriversFromApi(drivers: DraftApiDrivers): Driver[] {
        return drivers.drivers.map(
            (apiDriver: DraftApiDriver): Driver => DriverMapper.mapFromApi(apiDriver),
        );
    }
}
