import React from 'react';
import { CloseOutlined, MinusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Button, List, Modal, Popover } from 'antd';
import { observer } from 'mobx-react';
import { DraftOrder } from '../../Model/DraftOrder/DraftOrder';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { MapContent } from '../MapContainer/MapContent';
import { DraftDriver } from '../../Model/DraftDriver/DraftDriver';
import { PlanValidationStore } from './PlanValidationStore';
import styles from './PlanValidation.module.css';

interface OrderValidationProps {
    store: PlanValidationStore;
}

const OrderValidation = (props: OrderValidationProps) => {
    const [ordersToMove, setOrdersToMove] = React.useState(props.store.selectedOrdersDrivers);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOrdersToMove(props.store.selectedOrdersDrivers);
    }, [props.store.selectedOrdersDrivers]);

    if (ordersToMove.length === 0) {
        return null;
    }

    const removeOrder = (o: { order: DraftOrder; driver: DraftDriver }) => {
        setOrdersToMove(prev => prev.filter(i => i.order.id !== o.order.id));
    }

    const moveOrders = (driver: DraftDriver) => {
        props.store.setOrdersToDriver(driver, ordersToMove.map(i => i.order));
        setOpen(false);
    }

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (
        <MapContent position='topright'>
            <CloseOutlined
                className={styles.infoClose}
                onClick={() => props.store.setSelectedOrders([])}
            />
            <div className={styles.infoContainer}>
                {Array.from(ArrayUtils.groupBy(ordersToMove, x => x.driver)).map((entry) => {
                    const [draftDriver, orders] = entry;

                    return (
                        <div key={draftDriver?.id || 0} className={styles.infoDriverBlock}>
                            <div className={styles.infoBlock}>
                                <div className={styles.infoTitle}>Водитель:</div>
                                <div className={`${styles.infoName}`}>{draftDriver?.name || "Без водителя"}</div>
                            </div>
                            <List
                                size="small"
                                bordered
                                className={styles.orderList}
                                dataSource={orders}
                                renderItem={(item) =>
                                    <List.Item className={styles.orderListItemContainer}>
                                        <div>
                                            <div className={styles.lineHeight}>{item.order.clientName}</div>
                                            <div className={`${styles.bolder} ${styles.lineHeight}`}>{item.order.fullAddress}</div>
                                        </div>
                                        <MinusOutlined onClick={() => removeOrder(item)} />
                                    </List.Item>
                                }
                            />
                        </div>
                    )
                })}
                <Popover
                    trigger='click'
                    visible={open}
                    onVisibleChange={handleOpenChange}
                    content={
                        <div className={styles.popover}>
                            <strong>Выберите водителя</strong>
                            {props.store.allDrivers.map(d =>
                                <div
                                    key={d.id}
                                    className={classNames(styles.popoverItem, { [styles.popoverInactive]: !d.isActive })}
                                    onClick={() => moveOrders(d)}
                                >
                                    {d.name}
                                </div>
                            )}
                        </div>
                    }
                >
                    <Button
                        type='primary'
                        className={styles.btnMoveOrders}
                    >
                        Передать заказы
                    </Button>
                </Popover>
            </div>
        </MapContent>
    );
};

export default observer(OrderValidation);