import { makeAutoObservable } from "mobx";
import { DeliveryApi } from "../../Backend/Api/Delivery/DeliveryApi";
import { OrderRoutePoint } from "../../Map/Route/RoutePoint/OrderRoutePoint";
import { RoutePoint } from "../../Map/Route/RoutePoint/RoutePoint";
import { Place } from "../../Model/Place/Place";
import { Trip } from "../../Model/Trip/Trip";
import { TripStatus } from "../../Model/Trip/TripStatus";
import { TripPlan } from "../../Model/TripPlan/TripPlan";
import { TripPointId } from "../../Model/TripPointId/TripPointId";
import { TripPointType } from "../../Model/TripPointId/TripPointType";

export class PlanStore {
    public selectedTripId: number | undefined = undefined;
    public selectedTripItemIndex: number | undefined = undefined;
    public updatePlan: () => void;
    private readonly _planId: number;
    private _planTrips: Trip[];

    public constructor(plan: TripPlan, updatePlan: () => void) {
        this._planId = plan.id;
        this._planTrips = plan.trips.map(x => x);
        this.updatePlan = updatePlan;

        makeAutoObservable(this);
    }

    public get trips() {
        return this._planTrips.filter(this.visibleInEditingTrip);
    }

    public updateSelectedTripId = (tripId: number) => {
        this.selectedTripId = this.selectedTripId === tripId ? undefined : tripId;
        this.selectedTripItemIndex = undefined;
    }

    public updateSelectedTripItemIndex = (tripId: number, index: number) => {
        this.selectedTripId = tripId;
        this.selectedTripItemIndex = index;
    }

    public get selectedTripIndex() {
        const index = this._planTrips.findIndex(trip => trip.id === this.selectedTripId);

        return index > -1 ? index : undefined;
    }

    public get selectedTrip() {
        return this._planTrips.find(trip => trip.id === this.selectedTripId)
    }

    public get selectedRoute() {
        return this._planTrips.find(trip => trip.id === this.selectedTripId)?.route;
    }

    public get selectedTripItem() {
        if (this.selectedTrip && this.selectedTripItemIndex !== undefined) {
            return this.selectedTrip.route.routePoints[this.selectedTripItemIndex]
        }

        return undefined
    }

    public get selectedPlace() {
        if (this.selectedTripItem instanceof OrderRoutePoint) {
            return [this.selectedTripItem.order.place];
        }
    }

    public removeSelectedTripItem = () => {
        this.selectedTripItemIndex = undefined;
    }    

    public cancelOrder = async() => {
        if (!(this.selectedTripItem instanceof OrderRoutePoint)) {
            return Promise.resolve(undefined);
        }

        const order = this.selectedTripItem.order;

        if (order === undefined) {
            throw new Error('Can\'t cancel. Order is undefined');
        }

        try {
            await DeliveryApi.removeOrder(order);
            this.updatePlan();
        } catch (error) {
            alert(String(error));
        }
    };

    public readonly changeTripForSelectedOrder = async(tripId: number) => {
        if (!(this.selectedTripItem instanceof OrderRoutePoint)) {
            return Promise.resolve(undefined);
        }

        const order = this.selectedTripItem.order;

        if (order === undefined) {
            throw new Error('Can\'t cancel. Order is undefined');
        }

        try {
            await DeliveryApi.transferOrder(order.id, tripId);
            this.updatePlan();
        } catch (error) {
            alert(String(error));
        }

        return;
    };

    public readonly sendPlanToDrivers = async() => {
        try {
            await DeliveryApi.approvePlan(this._planId);
            this.updatePlan();
        } catch (error) {
            alert(String(error));
        }

        return;
    };

    public isFailedTrips = () => (
        this.trips.some((t: Trip) => t.status === TripStatus.RouteBuildFailed)
    )

    public reorderLegsInSelectedTrip = (routePoints: RoutePoint[]): Promise<void> => {
        if (!this.selectedTrip || !this.selectedTripId) {
            return Promise.reject();
        }

        const tripPointIds = routePoints.map((checkpoint) => new TripPointId(checkpoint.originalId, TripPointType.Order));

        return new Promise((resolve, reject) => {
            if (!this.selectedTripId) {
                reject();

                return;
            }
            DeliveryApi.patchTripOrders(this.selectedTripId, tripPointIds)
                .then((response) => {
                    if (!this.selectedTrip) {
                        return;
                    }
                    
                    this.selectedTrip.setRoute(this.selectedTrip.route.setLegs(response.legs));

                    this._planTrips = this._planTrips.map(trip => trip)

                    resolve();
                })
                .catch((error) => {
                    alert(String(error));
                    reject(error);
                })
        });
    }

    public setPlaceToCurrentOrder = async(place: Place): Promise<void> => {
        try {
            if (!(this.selectedTripItem instanceof OrderRoutePoint)) {
                return Promise.resolve(undefined);
            }
    
            const order = this.selectedTripItem.order;
    
            if (order === undefined) {
                throw new Error('Can\'t edit place. Order is undefined');
            }

            await DeliveryApi.setNewOrderPlace(order.id, place);
            this.updatePlan();
        } catch (error) {
            alert(String(error));
        }
    };

    private readonly visibleInEditingTrip = (trip: Trip) =>
        trip.status === TripStatus.LogistApprovement ||
        trip.status === TripStatus.New ||
        trip.status === TripStatus.RouteBuildFailed;
}