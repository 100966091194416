import { formatDistanceInKilometers } from './FormatDistanceInKilometers';
import { formatNumber } from './FormatNumber';
import { MathUtils } from './MathUtils';

const metersInKilometer = 1000;

export const formatDistance = (distanceInMeters: number, needM: boolean = true): string =>
    distanceInMeters >= metersInKilometer
        ? formatDistanceInKilometers(distanceInMeters)
        : `${formatNumber(MathUtils.round(distanceInMeters))}${needM ? ' м' : ''}`;
