import React from 'react';
import { DeliveryApi } from '../Backend/Api/Delivery/DeliveryApi';
import { TripPredictions } from '../Model/TripPredictions/TripPredictions';

export const usePlanPredictions = (planId: number) => {
    const [tripPredictions, setTripPredictions] = React.useState<TripPredictions[]>([]);
    const [loadingPlanPredictions, setLoadingPlanPredictions] = React.useState(false);

    React.useEffect(() => {
        setLoadingPlanPredictions(true);
        DeliveryApi.getPlanPredictions(planId)
            .then(setTripPredictions)
            .finally(() => setLoadingPlanPredictions(false));
    }, [planId]);

    return {
        tripPredictions,
        loadingPlanPredictions,
    };
};
