import { DealType } from '../../../../../ApiClient/Yoso/models';
import { ProductOperationDealType } from '../../../../../Model/ProductOperation/ProductOperationDealType';
import { Types } from '../../../../../Std/Types';

export class ProductOperationDealTypeDeserializer {
    public deserialize(serializedObj: DealType): ProductOperationDealType {
        return serializedObj === DealType.RENTING
            ? ProductOperationDealType.Renting
            : serializedObj === DealType.SELLING
            ? ProductOperationDealType.Selling
            : Types.assertUnreachable(serializedObj);
    }

    public deserializeFromString(serializedObj: string): ProductOperationDealType {
        const result =
            serializedObj === 'RENTING'
                ? ProductOperationDealType.Renting
                : serializedObj === 'SELLING'
                ? ProductOperationDealType.Selling
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown serialized ProductOperationDealType '${serializedObj?.toString()}'`);
        }

        return result;
    }
}

export const productOperationDealTypeDeserializer = new ProductOperationDealTypeDeserializer();
