import { IGeocode } from "../../../Model/Geocode/IGeocode";

export class Polygon {
    public readonly geocodes: IGeocode[];
    public readonly color: string | undefined;
    public readonly name?: string;
    public readonly isSelected: boolean;
    public readonly onClick?: () => void;

    public constructor(
        geocodes: ReadonlyArray<IGeocode>,
        color: string | undefined,
        name?: string,
        isSelected?: boolean,
        onClick?: (polygon: Polygon) => void) {
        this.geocodes = [...geocodes];
        this.color = color;
        this.name = name;
        this.isSelected = isSelected || false;
        this.onClick = onClick ? () => onClick(this) : undefined;
    }
}