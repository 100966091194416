import * as React from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Route } from '@/Map/Route/Route';
import { LunchRoutePoint } from '../../Map/Route/RoutePoint/LunchRoutePoint';
import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import { RoutePoint } from '../../Map/Route/RoutePoint/RoutePoint';
import { StartRoutePoint } from '../../Map/Route/RoutePoint/StartRoutePoint';
import { LunchStatusActual } from '../../Model/Lunch/LunchStatusActual';
import { OrderStatus } from '../../Model/Order/OrderStatus';
import { PointMarkers } from './PointMarkers';
import RoutePointContent from './RoutePointContent/RoutePointContent';
import './RouteInfo.css';

export interface IRouteInfoSelectorProps {
    selectedPointIds: Set<string>;
    selectingDisabled: boolean;
    onTogglePoint: (pointId: string) => void;
    className?: string;
    route: Route;
}

export const RouteInfoSelector = (props: IRouteInfoSelectorProps): JSX.Element => {
    const { onTogglePoint, selectedPointIds, selectingDisabled, route } = props;

    const [allPoints, setAllPoints] = React.useState([route.startPoint, ...route.points, route.noLegPoints, route.finishPoint]);

    React.useEffect(() => {
        setAllPoints([route.startPoint, ...route.points, route.noLegPoints, route.finishPoint]);
    }, [route.startPoint, route.points, route.noLegPoints, route.finishPoint]);

    const renderPointItem = React.useCallback(
        (
            point: RoutePoint,
            index: number,
            isFirst: boolean,
            isLast: boolean,
            isFirstScheduledOrder: boolean,
        ): JSX.Element => (
            <div
                data-id={point.id}
                className={classNames(
                    'route-info__item',
                    'route-info__item_clickable',
                    point instanceof StartRoutePoint && 'route-info__item_done',
                    point instanceof OrderRoutePoint &&
                        point.order.status === OrderStatus.Done &&
                        'route-info__item_done',
                    point instanceof OrderRoutePoint &&
                        point.order.status === OrderStatus.Moved &&
                        'route-info__item_moved',
                    point instanceof OrderRoutePoint &&
                        point.order.status === OrderStatus.Failed &&
                        'route-info__item_failed',
                    point instanceof OrderRoutePoint &&
                        point.order.status === OrderStatus.Rescheduled &&
                        'route-info__item_rescheduled',
                    point instanceof OrderRoutePoint &&
                        isFirstScheduledOrder &&
                        'route-info__item_first-scheduled-order',
                    point instanceof LunchRoutePoint &&
                        point.lunch.status === LunchStatusActual.Done &&
                        'route-info__item_done',
                    isFirst && 'route-info__item_first',
                    isLast && 'route-info__item_last',
                )}
                key={point.id}
            >
                <PointMarkers distanceIsSelected={false} point={point} />
                <div className="route-info__item-content-compose">
                    {point instanceof OrderRoutePoint && point.order.canBeTransferred() ? (
                        <Checkbox
                            checked={selectedPointIds.has(point.id)}
                            onChange={() => {
                                onTogglePoint(point.id);
                            }}
                            disabled={selectingDisabled}
                        >
                            <div className="route-info__item-content_text">
                                <RoutePointContent point={point} />
                            </div>
                        </Checkbox>
                    ) : (
                        <div className="route-info__item-content_text">
                            <RoutePointContent point={point} />
                        </div>
                    )}
                </div>
            </div>
        ),
        [onTogglePoint, selectedPointIds, selectingDisabled],
    );

    const [firstScheduledOrderIndex, setFirstScheduledOrderIndex] = React.useState(undefined as number | undefined);

    React.useEffect(() => {
        setFirstScheduledOrderIndex(
            allPoints.findIndex((point) => point instanceof OrderRoutePoint && point.order.canBeDone()),
        );
    }, [allPoints]);

    return (
        <div className={classNames('route-info', props.className)}>
            {route.noLegPoints.map((point, index) =>
                renderPointItem(point, index, false, false, false))}
            {renderPointItem(route.startPoint, 0, true, false, false)}
            {route.routePoints.map((point, index) =>
                renderPointItem(point, index + 1, false, false, index + 1 === firstScheduledOrderIndex),
            )}
            {renderPointItem(route.finishPoint, allPoints.length - 1, false, true, false)}
        </div>
    );
};
