import { TargetType } from '../../ApiClient/Yoso/models';
import { DateTime } from '../../Std/DateTime';
import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { Types } from '../../Std/Types';
import { Geocode } from '../Geocode/Geocode';
import { OrderOperations } from '../OrderOperations/OrderOperations';
import { Place } from '../Place/Place';
import { TripExecutor } from '../TripExecutor/TripExecutor';

import { OrderPaidMethod } from './OrderPaidMethod';
import { OrderStatus } from './OrderStatus';
import { OrderTransfer } from './OrderTransfer';

export class Order {
    public readonly actualArrivalDateTime: DateTime | undefined;
    public readonly clientName: string | undefined;
    public readonly code: string | undefined;
    public readonly comment: string | undefined;
    public readonly cancellationReason: string | undefined;
    public readonly cancellationReasonComment: string | undefined;
    public readonly deferred: boolean;
    public readonly desiredDateTimePeriod: DateTimePeriod | undefined;
    public readonly rescheduledDateTimePeriod: DateTimePeriod | undefined;

    public readonly executor: TripExecutor | undefined;
    public readonly expectedArrivalDateTime: DateTime | undefined;
    public readonly fullAddress: string;
    public readonly id: number;
    public readonly operations: OrderOperations;
    public readonly paid: number | undefined;
    public readonly paymentMethod: string;
    public readonly paidMethod: OrderPaidMethod | undefined;
    public readonly phoneNumber: string | undefined;
    public readonly place: Place;
    public readonly price: number;
    public readonly status: OrderStatus | undefined;
    public readonly shipmentCount: number;
    public readonly totalShipmentCount: number;
    public readonly _isAdded: boolean;
    public readonly driverPlace: Geocode | undefined;
    public readonly transfer: OrderTransfer | undefined;
    public readonly isInternal: boolean | undefined;

    public constructor(
        id: number,
        code: string | undefined,
        place: Place,
        operations: OrderOperations,
        comment: string | undefined,
        cancellationReason: string | undefined,
        cancellationReasonComment: string | undefined,
        desiredDateTimePeriod: DateTimePeriod | undefined,
        rescheduledDateTimePeriod: DateTimePeriod | undefined,
        expectedArrivalDateTime: DateTime | undefined,
        actualArrivalDateTime: DateTime | undefined,
        executor: TripExecutor | undefined,
        phoneNumber: string | undefined,
        clientName: string | undefined,
        fullAddress: string,
        status: OrderStatus | undefined,
        price: number,
        paid: number | undefined,
        paidMethod: OrderPaidMethod | undefined,
        deferred: boolean,
        transfer: OrderTransfer | undefined,
        shipmentCount: number,
        totalShipmentCount: number,
        isAdded: boolean,
        driverPlace: Geocode | undefined,
        paymentMethod: string,
        isInternal: boolean | undefined
    ) {
        this.id = id;
        this.code = code;
        this.place = place;
        this.operations = operations;
        this.comment = comment;
        this.cancellationReason = cancellationReason;
        this.cancellationReasonComment = cancellationReasonComment;
        this.desiredDateTimePeriod = desiredDateTimePeriod;
        this.rescheduledDateTimePeriod = rescheduledDateTimePeriod;
        this.expectedArrivalDateTime = expectedArrivalDateTime;
        this.actualArrivalDateTime = actualArrivalDateTime;
        this.executor = executor;
        this.phoneNumber = phoneNumber;
        this.clientName = clientName;
        this.fullAddress = fullAddress;
        this.status = status;
        this.price = price;
        this.paid = paid;
        this.paidMethod = paidMethod;
        this.deferred = deferred;
        this.transfer = transfer;
        this.shipmentCount = shipmentCount;
        this.totalShipmentCount = totalShipmentCount;
        this._isAdded = isAdded;
        this.driverPlace = driverPlace;
        this.paymentMethod = paymentMethod;
        this.isInternal = isInternal;
    }

    public getKey(): string {
        return `${this.id.toString()}_${TargetType.ORDER.toString()}`;
    }

    public get adjustmentCount(): number {
        const isPriceAdjusted = !this.isActive() && (this.paid || 0) !== this.price;
        const isRescheduled = this.status === OrderStatus.Rescheduled;

        return this.operations.adjustmentCount + (isPriceAdjusted ? 1 : 0) + (isRescheduled ? 1 : 0);
    }

    public canBeCancelled(): boolean {
        return this.isActive();
    }

    public canBeTransferred(): boolean {
        return this.isActive()
            || this.status === OrderStatus.Cancelled
            || this.status === OrderStatus.Failed
            || this.status === OrderStatus.Rescheduled;
    }

    public canBeRescheduled(): boolean {
        return this.isActive()
            || this.status === OrderStatus.Cancelled
            || this.status === OrderStatus.Failed;
    }

    public canBeDone(): boolean {
        switch (this.status) {
            case undefined:
            case OrderStatus.Cancelled:
            case OrderStatus.Done:
            case OrderStatus.Failed:
            case OrderStatus.Moved:
            case OrderStatus.Rescheduled:
                return false;
            case OrderStatus.New:
            case OrderStatus.NewAdded:
            case OrderStatus.NewAssigned:
            case OrderStatus.Assigned:
            case OrderStatus.AssignedAdded:
            case OrderStatus.Scheduled:
            case OrderStatus.ScheduledAdded:
            case OrderStatus.Delivering:
                return true;
            default:
                Types.assertUnreachable(this.status);
        }
    }

    public isDone(): boolean {
        return this.status === OrderStatus.Done;
    }

    public isOwn(): boolean {
        switch (this.status) {
            case undefined:
            case OrderStatus.Cancelled:
            case OrderStatus.Moved:
                return false;
            case OrderStatus.Done:
            case OrderStatus.Failed:
            case OrderStatus.New:
            case OrderStatus.NewAdded:
            case OrderStatus.NewAssigned:
            case OrderStatus.Assigned:
            case OrderStatus.AssignedAdded:
            case OrderStatus.Scheduled:
            case OrderStatus.ScheduledAdded:
            case OrderStatus.Delivering:
            case OrderStatus.Rescheduled:
                return true;
            default:
                Types.assertUnreachable(this.status);
        }
    }

    public isAdded(): boolean {
        return this.status === OrderStatus.AssignedAdded || this.status === OrderStatus.ScheduledAdded;
    }

    public setExpectedArrivalDateTime(expectedArrivalDateTime: DateTime | undefined): Order {
        return new Order(
            this.id,
            this.code,
            this.place,
            this.operations,
            this.comment,
            this.cancellationReason,
            this.cancellationReasonComment,
            this.desiredDateTimePeriod,
            this.rescheduledDateTimePeriod,
            expectedArrivalDateTime,
            this.actualArrivalDateTime,
            this.executor,
            this.phoneNumber,
            this.clientName,
            this.fullAddress,
            this.status,
            this.price,
            this.paid,
            this.paidMethod,
            this.deferred,
            this.transfer ? new OrderTransfer(this.transfer.toDriverId, this.transfer.toDriverName) : undefined,
            this.shipmentCount,
            this.totalShipmentCount,
            this._isAdded,
            this.driverPlace,
            this.paymentMethod,
            this.isInternal
        );
    }

    public isActive(): boolean {
        switch (this.status) {
            case undefined:
            case OrderStatus.Cancelled:
            case OrderStatus.Done:
            case OrderStatus.Failed:
            case OrderStatus.Moved:
            case OrderStatus.Rescheduled:
                return false;
            case OrderStatus.New:
            case OrderStatus.NewAdded:
            case OrderStatus.NewAssigned:
            case OrderStatus.Assigned:
            case OrderStatus.AssignedAdded:
            case OrderStatus.Scheduled:
            case OrderStatus.ScheduledAdded:
            case OrderStatus.Delivering:
                return true;
            default:
                Types.assertUnreachable(this.status);
        }
    }
}
