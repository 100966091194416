import {DeliveryApiWorkingDayInfo} from "../../../../../ApiClient/Yoso/models";
import {WorkingDay} from "../../../../../Model/WorkingDay/WorkingDay";

export class WorkingDayInfoDeserializer {
    public deserialize(response: DeliveryApiWorkingDayInfo): WorkingDay {
        return new WorkingDay(
            response.id,
            response.date,
            response.isShort,
            response.isRestDay,
            response.createdAt
        )
    }
}

export const workingDayInfoDeserializer = new WorkingDayInfoDeserializer();