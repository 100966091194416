import React from "react";
import { Button, Form, Input, Switch } from "antd";
import { CompanyProduct } from "../../Model/CompanyProduct/CompanyProduct";
import { CompanyApi } from "../../Backend/Api/Company/CompanyApi";
import './ProductEntityEdit.css'

interface IProductEntityEditProps {
    product: CompanyProduct | undefined;
    onSave: () => void;
}

export const ProductEntityEdit = (props: IProductEntityEditProps) => {
    const { product, onSave } = props;

    if (!product) {
        return null;
    }

    const [shortName, setShortName] = React.useState(product.shortName);
    const [isEmptyBottle, setIsEmptyBottle] = React.useState(product.isEmptyBottle);
    const [isDefaultBottle, setIsDefaultBottle] = React.useState(product.isDefaultBottle);
    const [isMainBottle, setIsMainBottle] = React.useState(product.isMainBottle);
    const [addable, setAddable] = React.useState(product.addable);
    const [isActive, setIsActive] = React.useState(product.isActive);

    const handleSaveButtonClick = async () => {
        try {
            await CompanyApi.putCompanyProduct(
                product.id,
                {
                    code: product.code,
                    name: product.name,
                    volume: product.volume,
                    addable: addable,
                    shortName: shortName,
                    isMainBottle: isMainBottle,
                    isDefaultBottle: isDefaultBottle,
                    isEmptyBottle: isEmptyBottle,
                    isActive: isActive
                });

            onSave();
        } catch (error) {
            alert(String(error));
        }
    }

    const toggleMainBottle = () => {
        setIsMainBottle(!isMainBottle);
        if (isDefaultBottle) {
            setIsDefaultBottle(!isDefaultBottle)
        }
    }

    return (
        <div className="product-entity-edit">
            <div className="product-entity-edit__input-block">
                <Form.Item label="Наименование" className="product-entity-edit__input">
                    <Input
                        style={{ width: 300 }}
                        defaultValue={product.name}
                        disabled
                    />
                </Form.Item>
                <Form.Item label="Короткое наименование" className="product-entity-edit__input">
                    <Input
                        style={{ width: 300 }}
                        defaultValue={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Эквивалент" className="product-entity-edit__input">
                    <Input
                        style={{ width: 150 }}
                        defaultValue={product.volume}
                        disabled
                    />
                </Form.Item>
            </div>

            <div className="product-entity-edit__double-checkbox">
                <Form.Item label="Бутыль с водой 18,9 л" className="product-entity-edit__input">
                    <Switch
                        checked={isMainBottle}
                        onChange={toggleMainBottle}
                    />
                </Form.Item>
                <Form.Item label="По умолчанию">
                    <Switch
                        checked={isDefaultBottle}
                        onChange={() => setIsDefaultBottle(!isDefaultBottle)}
                        disabled={!isMainBottle}
                    />
                </Form.Item>
            </div>

            <Form.Item label="Пустая бутыль" className="product-entity-edit__input">
                <Switch
                    checked={isEmptyBottle}
                    onChange={() => setIsEmptyBottle(!isEmptyBottle)}
                />
            </Form.Item>
            <Form.Item label="Можно добавлять в заказ" className="product-entity-edit__input">
                <Switch
                    checked={addable}
                    onChange={() => setAddable(!addable)}
                />
            </Form.Item>
            <Form.Item label="Активность" className="product-entity-edit__input">
                <Switch
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                />
            </Form.Item>
            <Button type="primary" onClick={handleSaveButtonClick}>
                Сохранить
            </Button>
        </div>
    );
}