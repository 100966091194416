import * as React from 'react';

import { AppConstants } from '../../AppConstants';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { IDeliveryPlanResponse } from '../../Backend/Api/Delivery/Model/DeliveryPlanResponse/DeliveryPlanResponseDeserializer';
import { DraftApi } from '../../Backend/Api/Draft/DraftApi';
import { GeocodingApi } from '../../Backend/Api/Geocoding/GeocodingApi';
import { Car } from '../../Model/Car/Car';
import { District } from '../../Model/District/District';
import { DraftOrder } from '../../Model/DraftOrder/DraftOrder';
import { DraftOrderDepartPeriodCode } from '../../Model/DraftOrder/DraftOrderDepartPeriodCode';
import { Driver } from '../../Model/Driver/Driver';
import { DriverPlace } from '../../Model/Place/DriverPlace';
import { Place } from '../../Model/Place/Place';
import { Product } from '../../Model/Product/Product';
import { TripExecutor } from '../../Model/TripExecutor/TripExecutor';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { TripPlan } from '../../Model/TripPlan/TripPlan';
import { WarningMessage } from '../../Model/WarningMessage/WarningMessage';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { DateTime } from '../../Std/DateTime';
import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { DictionaryUtils } from '../../Std/DictionaryUtils';
import { MapBounds } from '../../Std/MapBounds/MapBounds';
import { Time } from '../../Std/Time';
import { ValueTitle } from '../../Std/ValueTitle';
import { DistrictGroup } from '../../Store/District/DistrictGroup';

const createBounds = (orders: DraftOrder[]): MapBounds => {
    const orderGeocodes = ArrayUtils.flatten(
        orders.map((order) =>
            ArrayUtils.defined(
                order.places.map((place) =>
                    place.latitude === undefined || place.longitude === undefined
                        ? undefined
                        : {
                              latitude: place.latitude,
                              longitude: place.longitude,
                          },
                ),
            ),
        ),
    );

    return orderGeocodes.length === 0 ? AppConstants.defaultMapBounds : new MapBounds(orderGeocodes as any);
};

const readDepartPeriods = (orders: DraftOrder[]): ValueTitle[] => {
    let departPeriods = ArrayUtils.unique<ValueTitle>(
        orders.map(
            (order) => new ValueTitle(order.departPeriod, DraftOrderDepartPeriodCode.getName(order.departPeriod)),
        ),
        (a, b) => a.value === b.value,
    );

    const sortOrder = [
        DraftOrderDepartPeriodCode.morning,
        DraftOrderDepartPeriodCode.afternoon,
        DraftOrderDepartPeriodCode.evening,
    ];

    departPeriods = departPeriods.sort((a, b) => {
        const i1 = sortOrder.indexOf(a.value);
        const i2 = sortOrder.indexOf(b.value);

        if (i1 < 0) {
            return 1;
        }
        if (i2 < 0) {
            return -1;
        }

        return i1 - i2;
    });

    return departPeriods;
};

const createExecutors = (orders: ReadonlyArray<DraftOrder>): TripExecutor[] => {
    const executors: Record<number, TripExecutor> = {};
    orders.forEach((order) => {
        const executorId: number = order.executor!.getId();
        if (order.executor !== undefined && !(executorId in executors)) {
            executors[executorId] = new TripExecutor(order.executor.car, order.executor.driver, undefined);
        }
    });

    return Object.values(executors).sort((executor1, executor2) =>
        executor1.driver.compareDriverNames(executor2.driver),
    );
};

export const TripPlanDataContext = React.createContext({
    loadPlanData: async (
        startAt: DateTime,
        finishAt: DateTime,
        driverCodes: string[] | undefined,
    ): Promise<undefined> => undefined,
    loadAdditionalPlanData: async (planId: number, driverCodes: string[] | undefined): Promise<undefined> => undefined,
    addOrders: async (orders: ReadonlyArray<DraftOrder>): Promise<IDeliveryPlanResponse | undefined> => undefined,
    cars: undefined as ReadonlyArray<Car> | undefined,
    defaultBounds: createBounds([]),
    drivers: undefined as ReadonlyArray<Driver> | undefined,
    errorOrders: undefined as ReadonlyArray<DraftOrder> | undefined,
    errorOrdersExist: ((): boolean => false) as () => boolean,
    endTime: undefined as Time | undefined,
    executionDate: undefined as DateTime | undefined,
    handleOrdersSearch: (value: string) => {
        /* */
    },
    handleOrdersFilterSelect: (values: Record<string, string>) => {
        /* */
    },
    isDirty: false as boolean,
    isInputDataValid: ((): boolean => false) as () => boolean,
    isOrdersValid: ((): boolean => false) as () => boolean,
    loading: false as boolean,
    loadPlan: async (id: number | undefined) => {
        /* */
    },
    onSelectOrder: (order: DraftOrder) => {
        /* */
    },
    orders: undefined as ReadonlyArray<DraftOrder> | undefined,
    ordersSearchString: '' as string,
    ordersFilterSelectItems: {} as Record<string, { title: string; value: string }[]>,
    ordersFilterSelectValues: {} as Record<string, string>,
    planId: undefined as number | undefined,
    products: undefined as ReadonlyArray<Product> | undefined,
    saveAndBuildRoutes: async (
        orders: ReadonlyArray<DraftOrder>,
        departPeriods: string[],
        districtGroup: string,
        districts?: District[],
    ): Promise<IDeliveryPlanResponse | undefined> => undefined,
    saving: false as boolean,
    selectedOrder: undefined as DraftOrder | undefined,
    setEndTime: (time: Time | undefined) => {
        /* */
    },
    setExecutionDate: (date: DateTime | undefined) => {
        /* */
    },
    setOrdersFilterSelectItems: (value: Record<string, { title: string; value: string }[]>) => {
        /* */
    },
    applySelectedOrderPlace: async (place: Place) => {
        /* */
    },
    applyDriverLogin: (driverCode: string, login: string) => {
        /* */
    },
    cancelAddressGeocodeSelection: () => {
        /* */
    },
    setStartTime: (time: Time | undefined) => {
        /* */
    },
    unlinkAddressFromDriverPlace: (addressId: string, driverPlaceId: number) => {
        /* */
    },
    startTime: undefined as Time | undefined,
    tripPlan: undefined as TripPlan | undefined,
    executorsLoadingInfo: undefined as TripExecutorsLoadingInfo | undefined,
    executors: undefined as ReadonlyArray<TripExecutor> | undefined,
    departPeriods: undefined as ReadonlyArray<ValueTitle> | undefined,

    districtGroups: undefined as ReadonlyArray<DistrictGroup> | undefined,
    setDistrictGroups: (value: ReadonlyArray<DistrictGroup>) => {
        /* */
    },

    warningMessages: undefined as ReadonlyArray<WarningMessage> | undefined,
    setWarningMessages: (value: ReadonlyArray<WarningMessage>) => {
        /* */
    },
    driverPlace: undefined as DriverPlace | undefined,
});

export interface ITripPlanDataProviderProps {}

const createTodayTime = (hours: number, minutes: number): DateTime =>
    DateTime.now().cloneWithTime(new Time(hours, minutes));

const defaultStartTime = createTodayTime(AppConstants.defaultStartHours, 0).getTime();
const defaultEndTime = createTodayTime(AppConstants.defaultEndHours, 0).getTime();

export const TripPlanDataProvider = (props: ITripPlanDataProviderProps) => {
    const [cars, setCars] = React.useState(undefined as ReadonlyArray<Car> | undefined);
    const [executorsLoadingInfo, setExecutorsLoadingInfo] = React.useState(
        undefined as TripExecutorsLoadingInfo | undefined,
    );
    const [drivers, setDrivers] = React.useState(undefined as ReadonlyArray<Driver> | undefined);
    const [executors, setExecutors] = React.useState(undefined as ReadonlyArray<TripExecutor> | undefined);
    const [loading, setLoading] = React.useState(false as boolean);
    const [saving, setSaving] = React.useState(false as boolean);
    const [orders, setOrders] = React.useState(undefined as ReadonlyArray<DraftOrder> | undefined);
    const [errorOrders, setErrorOrders] = React.useState(undefined as ReadonlyArray<DraftOrder> | undefined);
    const [defaultBounds, setDefaultBounds] = React.useState(createBounds([]));

    const [products, setProducts] = React.useState(undefined as ReadonlyArray<Product> | undefined);
    const [destroyed, setDestroyed] = React.useState(false as boolean);

    const [executionDate, setExecutionDate] = React.useState(createTodayTime(0, 0) as DateTime | undefined);

    const [startTime, setStartTime] = React.useState(defaultStartTime as Time | undefined);
    const [endTime, setEndTime] = React.useState(defaultEndTime as Time | undefined);

    const [planId, setPlanId] = React.useState(undefined as number | undefined);
    const [tripPlan, setTripPlan] = React.useState(undefined as TripPlan | undefined);

    const [departPeriods, setDepartPeriods] = React.useState(undefined as ReadonlyArray<ValueTitle> | undefined);

    const [districtGroups, setDistrictGroups] = React.useState(undefined as ReadonlyArray<DistrictGroup> | undefined);
    const [warningMessages, setWarningMessages] = React.useState(
        undefined as ReadonlyArray<WarningMessage> | undefined,
    );
    const [uploadedAt, setUploadedAt] = React.useState(undefined as DateTime | undefined);
    const [driverPlace, setDriverPlace] = React.useState(undefined as DriverPlace | undefined);

    React.useEffect(
        () => () => {
            setDestroyed(true);
        },
        [],
    );

    const loadPlan = React.useCallback(async (id: number | undefined): Promise<undefined> => {
        setPlanId(id);
        setTripPlan(id === undefined ? undefined : (await DeliveryApi.getPlan(id)).plan);
        setCars(undefined);
        setDrivers(undefined);
        setExecutors(undefined);
        setExecutorsLoadingInfo(undefined);
        setLoading(false);
        setSaving(false);
        setOrders(undefined);
        setDepartPeriods(undefined);
        setErrorOrders(undefined);
        setDefaultBounds(createBounds([]));
        setProducts(undefined);
        setStartTime(defaultStartTime);
        setEndTime(defaultEndTime);
        setWarningMessages(undefined);
        setDistrictGroups(undefined);
        setUploadedAt(undefined);

        return;
    }, []);

    const isInputDataValid = React.useCallback(
        (): boolean =>
            executionDate !== undefined &&
            startTime !== undefined &&
            endTime !== undefined &&
            endTime.toMilliseconds() > startTime.toMilliseconds(),
        [executionDate, startTime, endTime],
    );

    const updateExecutors = React.useCallback((planOrders: ReadonlyArray<DraftOrder>): void => {
        const newExecutors = createExecutors(planOrders);
        setExecutors(newExecutors);
        setExecutorsLoadingInfo(new TripExecutorsLoadingInfo(newExecutors, planOrders));
    }, []);

    const loadPlanData: (
        startAt: DateTime,
        finishAt: DateTime,
        driverCodes: string[] | undefined,
    ) => Promise<undefined> = React.useCallback(
        async (startAt: DateTime, finishAt: DateTime, driverCodes: string[] | undefined): Promise<undefined> => {
            if (!isInputDataValid()) {
                throw new Error('LoadPlanData. Date or times is not valid');
            }

            setLoading(true);

            try {
                const planData = await DraftApi.getPlanData(startAt, finishAt, driverCodes);

                if (destroyed) {
                    return;
                }

                const newOrders = planData.orders.slice();
                setOrders(newOrders);
                setErrorOrders(newOrders.filter((order) => order.needValidateDraftOrder()));
                setCars(planData.cars);
                setDrivers(planData.drivers);

                updateExecutors(newOrders);

                setProducts(planData.products);
                setDefaultBounds(createBounds(newOrders));

                setDepartPeriods(readDepartPeriods(newOrders));

                setDistrictGroups(planData.districtsGroups);
                setWarningMessages(planData.warningMessages);
                setUploadedAt(planData.uploadedAt);
            } catch (error) {
                if (!destroyed) {
                    alert(String(error));
                    throw error;
                }
            }

            setLoading(false);
        },
        [destroyed, isInputDataValid, updateExecutors],
    );

    const loadAdditionalPlanData: (
        id: number,
        driverCodes: string[] | undefined,
    ) => Promise<undefined> = React.useCallback(
        async (id: number, driverCodes: string[] | undefined): Promise<undefined> => {
            if (!isInputDataValid()) {
                throw new Error('LoadAdditionalPlanData. Date or times is not valid');
            }

            setLoading(true);

            try {
                const planData = await DraftApi.getAdditionalPlanData(id, driverCodes);

                if (destroyed) {
                    return;
                }

                const newOrders = planData.orders.slice();
                setOrders(newOrders);
                setErrorOrders(newOrders.filter((order) => order.needValidateDraftOrder()));
                setCars(planData.cars);
                setDrivers(planData.drivers);

                updateExecutors(newOrders);

                setProducts(planData.products);
                setDefaultBounds(createBounds(newOrders));

                setDepartPeriods(readDepartPeriods(newOrders));
            } catch (error) {
                if (!destroyed) {
                    alert(String(error));
                    throw error;
                }
            }

            setLoading(false);
        },
        [destroyed, isInputDataValid, updateExecutors],
    );

    const [selectedOrder, setSelectedOrder] = React.useState(undefined as DraftOrder | undefined);

    const onSelectOrder = React.useCallback((order: DraftOrder) => {
        setSelectedOrder(order);
        setDriverPlace(order.driverPlace);
    }, []);

    const [ordersSearchString, setOrdersSearchString] = React.useState('' as string);
    const handleOrdersSearch = React.useCallback((value: string) => {
        setOrdersSearchString(value);
    }, []);

    const [isDirty, setIsDirty] = React.useState(false as boolean);

    const [ordersFilterSelectItems, setOrdersFilterSelectItems] = React.useState(
        {} as Record<string, { title: string; value: string }[]>,
    );

    const [ordersFilterSelectValues, setOrdersFilterSelectValues] = React.useState({} as Record<string, string>);
    const handleOrdersFilterSelect = React.useCallback((values: Record<string, string>) => {
        setOrdersFilterSelectValues(values);
    }, []);

    React.useEffect(() => {
        setOrdersFilterSelectValues(
            DictionaryUtils.objectFromPairs(
                Object.keys(ordersFilterSelectItems).map((orderFilterSelectKey) => [
                    orderFilterSelectKey,
                    ordersFilterSelectItems[orderFilterSelectKey][0].value,
                ]),
            ),
        );
    }, [ordersFilterSelectItems]);

    const unlinkAddressFromDriverPlace = React.useCallback(
        async (addressId: string, driverPlaceId: number) => {
            await DeliveryApi.unlinkAddressFromDriverPlace(addressId, driverPlaceId).then(() => {
                selectedOrder?.setDriverPlace(undefined);
                setDriverPlace(undefined);
            });
        },
        [selectedOrder],
    );

    const applySelectedOrderPlace = React.useCallback(
        async (place: Place) => {
            await GeocodingApi.postValidatedPlace(place);

            if (orders !== undefined && selectedOrder !== undefined) {
                setIsDirty(true);
                const newOrders = orders.map((order) => {
                    if (order === selectedOrder) {
                        return order.cloneSetValidPlace(place);
                    } else {
                        return order;
                    }
                });

                setSelectedOrder(undefined);
                setOrders(newOrders);
                setErrorOrders(newOrders.filter((order) => order.needValidateDraftOrder()));
            }
        },
        [orders, selectedOrder],
    );

    const applyDriverLogin = React.useCallback(
        (driverCode: string, login: string): void => {
            const driver = drivers?.find((x) => x.code === driverCode);
            if (orders === undefined || driver === undefined) {
                return;
            }
            setIsDirty(true);
            setSelectedOrder(undefined);
            const newDriver = driver.cloneSetLogin(login);
            const newOrders = orders.map((order) => {
                if (order.executor?.driver.code === driver.code) {
                    return order.cloneSetDriver(newDriver);
                }

                return order;
            });
            setSelectedOrder(undefined);
            setOrders(newOrders);
            setErrorOrders(newOrders.filter((order) => order.needValidateDraftOrder()));
            if (cars !== undefined && drivers !== undefined) {
                const newDrivers = drivers.map((d) => (d.id !== driver.id ? d : d.cloneSetLogin(login)));
                setDrivers(newDrivers);
                updateExecutors(newOrders);
            }
        },
        [orders, cars, drivers, updateExecutors],
    );

    const cancelAddressGeocodeSelection = React.useCallback(() => {
        setSelectedOrder(undefined);
    }, []);

    const errorOrdersExist = React.useCallback(
        (): boolean => orders !== undefined && orders.findIndex((order) => order.needValidateDraftOrder()) > -1,
        [orders],
    );

    const unknownDriversExist = React.useCallback(
        (): boolean =>
            orders !== undefined &&
            orders.findIndex((order) => {
                if (order.executor === undefined) {
                    return true;
                }
                if (order.executor.driver === undefined) {
                    return true;
                }

                return order.executor.driver.login === undefined;
            }) > -1,
        [orders],
    );

    const isOrdersValid = React.useCallback(
        (): boolean => orders !== undefined && orders.length > 0 && !errorOrdersExist() && !unknownDriversExist(),
        [orders, errorOrdersExist, unknownDriversExist],
    );

    const addOrders: (
        filteredOrders: ReadonlyArray<DraftOrder>,
    ) => Promise<IDeliveryPlanResponse | undefined> = React.useCallback(
        async (filteredOrders: ReadonlyArray<DraftOrder>): Promise<IDeliveryPlanResponse | undefined> => {
            if (planId === undefined) {
                throw new Error('addOrders: planId is undefined.');
            }

            setSaving(true);

            try {
                if (
                    filteredOrders === undefined ||
                    cars === undefined ||
                    drivers === undefined ||
                    products === undefined
                ) {
                    throw new Error('filteredOrders or cars or drivers or products is undefined');
                }
                if (filteredOrders.length === 0) {
                    throw new Error('No orders.');
                }
                if (errorOrdersExist()) {
                    throw new Error('There are error orders.');
                }
                const result = await DeliveryApi.addPlanOrders(planId, filteredOrders, cars, drivers, products);

                if (!destroyed) {
                    setIsDirty(false);
                }

                return destroyed ? undefined : result;
            } catch (error) {
                if (destroyed) {
                    return undefined;
                }
                alert(String(error));
            }
        },
        [cars, destroyed, drivers, errorOrdersExist, planId, products],
    );

    const saveAndBuildRoutes: (
        filteredOrders: ReadonlyArray<DraftOrder>,
        planDepartPeriods: string[],
        districtGroup: string,
        districts?: District[],
    ) => Promise<IDeliveryPlanResponse | undefined> = React.useCallback(
        async (
            filteredOrders: ReadonlyArray<DraftOrder>,
            planDepartPeriods: string[],
            districtGroup: string,
            districts?: District[],
        ): Promise<IDeliveryPlanResponse | undefined> => {
            if (!isInputDataValid()) {
                throw new Error('Invalid data.');
            }

            setSaving(true);

            try {
                if (
                    filteredOrders === undefined ||
                    cars === undefined ||
                    drivers === undefined ||
                    products === undefined
                ) {
                    throw new Error('filteredOrders or cars or drivers or products is undefined');
                }
                if (filteredOrders.length === 0) {
                    throw new Error('No orders.');
                }
                if (errorOrdersExist()) {
                    throw new Error('There are error orders.');
                }

                if (!uploadedAt) {
                    throw new Error('No uploaded date for orders');
                }

                const result = await DeliveryApi.buildPlan(
                    new DateTimePeriod(
                        executionDate!.cloneWithTime(startTime!),
                        executionDate!.cloneWithTime(endTime!),
                    ),
                    filteredOrders,
                    cars,
                    drivers,
                    products,
                    planDepartPeriods,
                    districtGroup,
                    uploadedAt,
                    districts,
                );

                if (!destroyed) {
                    setIsDirty(false);
                }

                return destroyed ? undefined : result;
            } catch (error) {
                if (destroyed) {
                    return undefined;
                }
                alert(String(error));
            }
        },
        [
            isInputDataValid,
            cars,
            drivers,
            products,
            errorOrdersExist,
            executionDate,
            startTime,
            endTime,
            destroyed,
            uploadedAt,
        ],
    );

    return (
        <TripPlanDataContext.Provider
            value={{
                loadPlanData,
                loadAdditionalPlanData,
                addOrders,
                cars,
                drivers,
                defaultBounds,
                endTime,
                errorOrders,
                errorOrdersExist,
                executionDate,
                handleOrdersFilterSelect,
                handleOrdersSearch,
                isDirty,
                isInputDataValid,
                isOrdersValid,
                loading,
                onSelectOrder,
                orders,
                ordersFilterSelectItems,
                setOrdersFilterSelectItems,
                ordersFilterSelectValues,
                ordersSearchString,
                planId,
                products,
                saveAndBuildRoutes,
                saving,
                selectedOrder,
                setEndTime,
                setExecutionDate,
                loadPlan,
                applySelectedOrderPlace,
                applyDriverLogin,
                setStartTime,
                startTime,
                tripPlan,
                cancelAddressGeocodeSelection,
                executorsLoadingInfo,
                executors,
                departPeriods,
                districtGroups,
                setDistrictGroups,
                warningMessages,
                setWarningMessages,
                unlinkAddressFromDriverPlace,
                driverPlace,
            }}
            {...props}
        />
    );
};

export const useTripPlanData = () => React.useContext(TripPlanDataContext);
