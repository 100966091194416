export class DictionaryUtils {
    public static objectFromPairs<TValue>(pairs: [string, TValue][]): Record<string, TValue> {
        const result: Record<string, TValue> = {};
        pairs.forEach((pair) => {
            result[pair[0]] = pair[1];
        });

        return result;
    }
}
