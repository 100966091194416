import React from 'react';
import { Map } from '@/Components/Map/Map';
import { TripPlan } from '@/Model/TripPlan/TripPlan';
import { Trip } from '@/Model';
import { TripCard } from '../TripCard/TripCard';

interface TripListProps {
    tripPlan: TripPlan
    loading: boolean
    onOpenItinerary: (id: number) => void;
    onResumeTrip: (id: number) => void;
    trips: Trip[];
    selectedTripId?: number;
    selectedOrderId?: number;
    onSelectTrip: (tripId: number) => void;
    onSelectOrder: (orderId: number) => void;
    onTransferOrders: (toTripId: number, orderIds: number[]) => void;
    onReplaceDriverInTrip: (tripId: number, driverId: number) => void;
}

export const TripList = (props: TripListProps) => {
    const {
        loading,
        tripPlan,
        trips,
        onOpenItinerary,
        onResumeTrip,
        selectedTripId,
        selectedOrderId,
        onSelectTrip,
        onSelectOrder,
        onTransferOrders,
        onReplaceDriverInTrip
    } = props;

    const colorScheme = Map.colorScheme;

    return (
        <>
            {trips.map((trip, index) =>
                <div key={trip.id} style={{width: '100%'}}>
                    <TripCard
                        color={colorScheme.renderColor(colorScheme.get(index)).rgbaString}
                        loading={loading}
                        trip={trip}
                        trips={tripPlan.trips.map(i => i)}
                        selectedOrderId={selectedOrderId}
                        planDrivers={tripPlan.executors}
                        onSelect={onSelectTrip}
                        getItinerary={onOpenItinerary}
                        onResumeTrip={onResumeTrip}
                        selectedTripId={selectedTripId}
                        onReplaceDriverInTrip={onReplaceDriverInTrip}
                        onSelectOrder={onSelectOrder}
                        onTransferOrders={onTransferOrders}
                    />
                </div>
            )}
        </>
    );
};
