import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import { CompanyApiCompany } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './CompanyListRoute.css'

export interface ICompanyListRouteProps extends RouteComponentProps {}

export const CompanyListRoute = withRouter(
    (props: ICompanyListRouteProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [companies, setCompanies] = React.useState([] as CompanyApiCompany[]);

        const deleteCompany = React.useCallback(
            (id: number): (() => Promise<undefined>) => async (): Promise<undefined> => {
                setLoading(true);

                try {
                    await CompanyApi.deleteCompany(id);
                    const response = await CompanyApi.getCompanyList();
                    setCompanies(response.companies!);
                } catch (error) {
                    alert(String(error));
                }

                setLoading(false);

                return;
            },
            [],
        );

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getCompanyList();
                    if (cancelled) {
                        return;
                    }

                    setCompanies(response.companies!);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, []);

        return (
            <Page header="Компании">
                <Link to="/companies/new">
                    <Button type="primary" className="company-list-route__button" icon={<PlusOutlined />}>
                        Добавить компанию
                    </Button>
                </Link>

                <EntityList<CompanyApiCompany>
                    data={companies}
                    loading={loading}
                    id={(company) => company.id!}
                    actions={{
                        item: {
                            click: (id) => `/companies/${id}`,
                        },
                    }}
                    columns={[
                        {
                            key: 'name',
                            title: 'Название',
                            render: (company) => company.name,
                        },
                    ]}
                />
            </Page>
        );
    },
);
