import { MathUtils } from '../../Std/MathUtils';

import { IGeocode } from './IGeocode';

export class Geocode implements IGeocode {
    public static fromLatLon(latitude: number, longitude: number): Geocode {
        return new Geocode({latitude: latitude, longitude: longitude})
    }
    
    public static formatComponent(value: number): string {
        return MathUtils.numberToString(value, Geocode.fractionSize);
    }

    private static readonly fractionSize: number = 6;

    private static roundComponent(value: number): number {
        return MathUtils.round(value, Geocode.fractionSize);
    }

    public readonly latitude: number;
    public readonly longitude: number;

    public constructor(coordinates: Readonly<IGeocode>) {
        this.latitude = coordinates.latitude;
        this.longitude = coordinates.longitude;
    }

    public equals(geocode: Geocode): boolean {
        return this.latitude === geocode.latitude && this.longitude === geocode.longitude;
    }

    public format(): string {
        return `${Geocode.formatComponent(this.latitude)}, ${Geocode.formatComponent(this.longitude)}`;
    }

    public round(): Geocode {
        return new Geocode({
            latitude: Geocode.roundComponent(this.latitude),
            longitude: Geocode.roundComponent(this.longitude),
        });
    }

    public getIGeocode(): IGeocode {
        return {latitude: this.latitude, longitude: this.longitude}
    }
}
