import { useState, useCallback } from 'react';

export const useInput = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);

    const onChange = useCallback(setValue, []);

    return {
        value,
        onChange,
    };
};
