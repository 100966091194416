import * as React from 'react';

import { User } from '../../../Model/User/User';

import { useAuth } from './AuthProvider/AuthProvider';

export const UserContext = React.createContext({
    user: undefined as User | undefined,
});

export interface IUserProviderProps {}

export const UserProvider = (props: IUserProviderProps) => (
    <UserContext.Provider
        value={{
            user: useAuth().data.user,
        }}
        {...props}
    />
);

export const useUser = () => React.useContext(UserContext);
