import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import { CompanyApiAdminResponse } from '../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../EntityList/EntityList';
import './CompanyAdminList.css'

export interface ICompanyAdminListProps extends RouteComponentProps {
    companyId: number;
}

export const CompanyAdminList = withRouter(
    (props: ICompanyAdminListProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [admins, setAdmins] = React.useState([] as CompanyApiAdminResponse[]);

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getCompanyAdminList(props.companyId);
                    if (cancelled) {
                        return;
                    }

                    setAdmins(response);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, [props.companyId]);

        const deleteAdmin = React.useCallback(
            (id: number): (() => Promise<undefined>) => async (): Promise<undefined> => {
                setLoading(true);

                try {
                    await CompanyApi.deleteCompanyAdmin(props.companyId, id);
                    const response = await CompanyApi.getCompanyAdminList(props.companyId);
                    setAdmins(response);
                } catch (error) {
                    alert(String(error));
                }

                setLoading(false);

                return;
            },
            [props.companyId],
        );

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getCompanyAdminList(props.companyId);
                    if (cancelled) {
                        return;
                    }

                    setAdmins(response);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, [props.companyId]);

        return (
            <div className="company-admin-list">
                <Link to={`/companies/${props.companyId}/admins/new`}>
                    <Button type="primary" className="company-admin-list__button" icon={<PlusOutlined />}>
                        Добавить администратора
                    </Button>
                </Link>

                <EntityList<CompanyApiAdminResponse>
                    data={admins}
                    loading={loading}
                    id={(admin) => admin.employee!.id}
                    actions={{
                        item: {
                            click: (id) => `/companies/${props.companyId}/admins/${id}`,
                            delete: {
                                action: deleteAdmin,
                                title: 'Удалить администратора',
                            },
                        },
                    }}
                    columns={[
                        {
                            key: 'name',
                            title: 'Имя',
                            render: (admin) => admin.employee!.name,
                        },
                        {
                            key: 'username',
                            title: 'Логин',
                            render: (admin) => admin.employee!.credentials!.username,
                        },
                    ]}
                />
            </div>
        );
    },
);
