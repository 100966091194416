import React, { useMemo } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import styles from './FailReasonSelector.module.css';

interface FailReasonSelectorProps {
    onChange: (isCorrect: boolean, reason: string, subReason?: string) => void;
}

enum CancellationReason {
    CompanyRequest = 'Отменен по просьбе компании',
    ClientRequest = 'Отменен по просьбе клиента',
    ExecutionImpossible = 'Невозможно выполнить заказ',
}

enum SubCancellationReason {
    NobodyHome = 'Никого нет на месте',
    NoRoad = 'Невозможно проехать',
}

const reasons = Object.values(CancellationReason);
const subReasons = Object.values(SubCancellationReason);

export const FailReasonSelector = React.memo((props: FailReasonSelectorProps): JSX.Element => {
    const [cancellationReason, setCancellationReason] = React.useState<string | undefined>(undefined);
    const [cancellationSubReason, setCancellationSubReason] = React.useState<string | undefined>(undefined);
    const isSubReasonsVisible = useMemo(() => cancellationReason === CancellationReason.ExecutionImpossible, [
        cancellationReason,
    ]);

    const onChangeCancellationReason = (e: RadioChangeEvent) => {
        const selectedReason = e.target.value;
        setCancellationReason(selectedReason);
        setCancellationSubReason(undefined);
        props.onChange(selectedReason !== CancellationReason.ExecutionImpossible, selectedReason);
    };

    const onChangeSubCancellationReason = (e: RadioChangeEvent) => {
        const selectedSubReason = e.target.value;
        setCancellationSubReason(selectedSubReason);
        if (cancellationReason) {
            props.onChange(selectedSubReason !== undefined, cancellationReason ,selectedSubReason);
        }
    };

    return (
        <div>
            <Radio.Group
                onChange={onChangeCancellationReason}
                value={cancellationReason}
                className={styles.reasons}
            >
                {reasons.map(i => <Radio key={i} value={i}>{i}</Radio>)}
            </Radio.Group>
            {isSubReasonsVisible &&
                <div className={styles.subreasonsContainer}>
                    <div className={styles.subtitle}>Уточните:</div>
                    <Radio.Group
                        onChange={onChangeSubCancellationReason}
                        value={cancellationSubReason}
                        className={styles.reasons}
                    >
                        {subReasons.map(i => <Radio key={i} value={i}>{i}</Radio>)}
                    </Radio.Group>
                </div>
            }
        </div>
    )
});
