import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { MapSource } from '../../../Components/MapContainer/MapSource';

import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { IMapRenderer } from '../IMapRenderer';
import { IMapRendererMarker } from '../IMapRendererMarker';
import { IMapRendererMarkerTooltip } from '../IMapRendererMarkerTooltip';
import { IMapRendererPath } from '../IMapRendererPath';
import { IMapRendererPolygon } from '../IMapRendererPolygon';
import { LatLong } from '../LatLong';

import { LeafletMarker } from './LeafletMarker';
import { LeafletPath } from './LeafletPath';
import { LeafletPolygon } from './LeafletPolygon';

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
const LF = require('../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js');

import './LeafletStyles.css';

export class LeafletMapRenderer implements IMapRenderer {
    private static getPointLatLong(point: IGeocode): LatLong {
        return [point.latitude, point.longitude];
    }

    private static getPointsLatLong(points: ReadonlyArray<IGeocode>): ReadonlyArray<LatLong> {
        return points.map((point: IGeocode): LatLong => LeafletMapRenderer.getPointLatLong(point));
    }
    public readonly markers: LeafletMarker[] = [];
    public readonly paths: LeafletPath[] = [];
    public readonly polygons: LeafletPolygon[] = [];

    private readonly _clickHandlers: ((geocode: IGeocode) => void)[] = [];

    private map: L.Map | undefined;

    public addMarker(
        geocode: IGeocode,
        iconElement: string | undefined,
        width: number,
        height: number,
        iconPinX: number,
        iconPinY: number,
        className: string,
        style: string | undefined,
        clickable: boolean = false,
        tooltip?: IMapRendererMarkerTooltip,
    ): IMapRendererMarker {
        if (this.map === undefined) {
            throw new Error('AddMarker. Map is undefined');
        }

        const marker: LeafletMarker = new LeafletMarker(
            geocode.latitude,
            geocode.longitude,
            iconElement,
            width,
            height,
            iconPinX,
            iconPinY,
            className,
            clickable,
            style,
            tooltip,
        );
        marker.addTo(this.map);

        this.markers.push(marker);

        return marker;
    }

    public addPath(points: ReadonlyArray<IGeocode>, color: string, width: number): IMapRendererPath {
        if (this.map === undefined) {
            throw new Error('AddPath. Map is undefined');
        }

        const path: LeafletPath = new LeafletPath(points, color, width);
        path.addTo(this.map);

        this.paths.push(path);

        return path;
    }

    public addPolygon(points: ReadonlyArray<IGeocode>, color: string, width: number): IMapRendererPolygon {
        if (this.map === undefined) {
            throw new Error('AddPolygon. Map is undefined');
        }

        const polygon: LeafletPolygon = new LeafletPolygon(points, color, width);
        polygon.addTo(this.map);

        this.polygons.push(polygon);

        return polygon;
    }

    public clear(): void {
        this.markers.forEach((marker: LeafletMarker) => {
            marker.remove();
        });
        this.markers.length = 0;

        this.paths.forEach((path: LeafletPath) => {
            path.remove();
        });
        this.paths.length = 0;

        this.polygons.forEach((polygon: LeafletPolygon) => {
            polygon.remove();
        });
        this.polygons.length = 0;
    }

    public destroy(): void {
        if (this.map !== undefined) {
            try {
                this.map.remove();
            } catch (e) {
                //
            }
        }
    }

    public fitBounds(points: ReadonlyArray<IGeocode>): void {
        if (this.map === undefined) {
            throw new Error('FitBounds. Map is undefined');
        }
        if (points.length > 0) {
            this.map.fitBounds([...LeafletMapRenderer.getPointsLatLong(points)]);
        }
    }

    public init(element: HTMLElement): Promise<undefined> {
        this.map = L.map(element, {
            preferCanvas: true,
            fullscreenControl: true,
            attributionControl: false
        });

        this.map.on('click', (event: L.LeafletEvent): void => {
            const latLng = (event as L.LeafletMouseEvent).latlng;
            for (const clickHandler of this._clickHandlers) {
                clickHandler({
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                });
            }
        });

        // L.tileLayer('http://tile4.maps.2gis.com/tiles?x={x}&y={y}&z={z}').addTo(this.map);
        L.tileLayer(MapSource.TwoGis, {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);

        return Promise.resolve(undefined);
    }

    public onClick(handler: (geocode: IGeocode) => void): void {
        this._clickHandlers.push(handler);
    }
}
