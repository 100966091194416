import React, { ComponentType } from 'react';
import { Menu } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { Role } from '../../ApiClient/YosoAuth/models';
import { useUser } from '../AppProviders/Providers/UserProvider';
import AdminAppMenu from './AdminAppMenu/AdminAppMenu';
import LogisticianAppMenu from './LogisticianAppMenu/LogisticianAppMenu';
import SuperAdminAppMenu from './SuperAdminAppMenu/SuperAdminAppMenu';
import './AppMenu.css';

export interface IAppMenuProps extends RouteComponentProps {}

export interface AppMenuProps {
  selectedKeys: string[];
}

const AppMenuComponent = (props: IAppMenuProps): JSX.Element => {
  const selectedKeys = React.useMemo(() => {
    const match = props.location.pathname.match(/\/[^/]+/);

    return match === null ? [] : [match[0]];
  }, [props.location.pathname]);

  const userRole = useUser().user!.role;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  let AppMenu: ComponentType<any>;
  switch (userRole.role) {
    case Role.ROLESUPERADMIN:
      AppMenu = SuperAdminAppMenu;
      break;
    case Role.ROLEADMIN:
      AppMenu = AdminAppMenu;
      break;
    case Role.ROLELOGISTICIAN:
      AppMenu = LogisticianAppMenu;
      break;
    default:
      AppMenu = Menu;
  }

  return <AppMenu className="app-menu" mode="inline" theme="dark" selectedKeys={selectedKeys} />;
};

export const AppMenu = withRouter(AppMenuComponent);
