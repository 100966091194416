import React from 'react';
import { Button } from 'antd';
import { FailReasonSelector } from '@/Components/FailReasonSelector';
import styles from './CancelOrder.module.css'

interface CancelOrderProps {
    onFailOrder: (reason: string, subReason?: string) => void;
}

export const CancelOrder = (props: CancelOrderProps) => {
    const {onFailOrder} = props

    const [selectedReason, setSelectedReason] = React.useState<{reason?: string, subReason?: string, isCorrect: boolean}>({isCorrect: false});

    const changeReason = (isCorrect: boolean, reason: string, subReason?: string) => {
        setSelectedReason({reason, subReason, isCorrect})
    }

    const failOrder = () => {
        if (selectedReason.isCorrect && selectedReason.reason) {
            onFailOrder(selectedReason.reason, selectedReason.subReason)
        }
    }

    return (
        <>
            <div className={styles.header}>Выберите причину отмены</div>
            <FailReasonSelector
                onChange={changeReason}
            />
            <Button
                danger
                className={styles.btn}
                onClick={failOrder}
                disabled={!selectedReason.isCorrect}
            >
                Отменить заказ
            </Button>
        </>
    );
};
