import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { PauseCircleOutlined, PlayCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DeliveryApiInterval, State } from '@/ApiClient/Yoso/models';
import { DeliveryIntervalStore } from '@/Store/DeliveryInterval/DeliveryIntervalStore';
import { daysOfWeekOptions } from '@/Components/DaysOfWeekSelector/DaysOfWeekSelector';
import DeliveryTimeIntervalAdjustment from '../DeliveryTimeIntervalAdjustment/DeliveryTimeIntervalAdjustment';

import styles from './SpecialIntervalEditor.module.css';

interface SpecialIntervalEditorProps {
    store: DeliveryIntervalStore | undefined;
    saveAdjustments: () => Promise<void>;
}

const SpecialIntervalEditor = observer((props: SpecialIntervalEditorProps) => {
    const { store, saveAdjustments } = props;
    const [saving, setSaving] = useState(false);

    const saveAdjustmentsWrap = () => {
        setSaving(true);
        saveAdjustments().finally(() => {
            setSaving(false);
        });
    };

    const todayStr = moment().format('YYYYMMDD');

    return (
        <div className={styles.container}>
            <h2>
                Исключения <QuestionCircleOutlined title="Нажмите на дату для редактирования" />
            </h2>
            {!store || saving ? (
                <Spin className={styles.spin} />
            ) : (
                <div className={styles.calendarContainer}>
                    <div className={styles.calendar}>
                        {store.calendar.map((month) => (
                            <div className={styles.calendarMonth} key={month.date.toISOString()}>
                                <div className={styles.monthTitle}>{month.date.format('MMMM')}</div>
                                <div className={styles.calendarDOWLabels}>
                                    {daysOfWeekOptions.map((x) => (
                                        <div
                                            className={classNames({
                                                [styles.calendarDOW]: true,
                                            })}
                                            key={x.value}
                                        >
                                            {x.label}
                                        </div>
                                    ))}
                                </div>
                                {month.weeks.map((week, wi) => (
                                    <div
                                        className={classNames({
                                            [styles.calendarWeek]: true,
                                            [styles.calendarFirstWeek]: wi === 0,
                                        })}
                                        key={week.date.toISOString()}
                                    >
                                        {week.days.map((day) => (
                                            <div
                                                className={classNames({
                                                    [styles.calendarDay]: true,
                                                    [styles.calendarDayNoIntervals]: day.activeIntervals.length === 0,
                                                    [styles.calendarDayEditable]: store.dateIsEditable(day.date),
                                                    [styles.hasAdjustments]: store.adjustmentsByDate.has(
                                                        day.date.format('YYYYMMDD'),
                                                    ),
                                                    [styles.isToday]: day.date.format('YYYYMMDD') === todayStr,
                                                })}
                                                key={day.date.toISOString()}
                                                onClick={() => {
                                                    if (store.dateIsEditable(day.date)) {
                                                        store.setSelectedDay(day);
                                                    }
                                                }}
                                            >
                                                {day.date.format('D')}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <Modal
                        visible={!!store.selectedDay}
                        cancelText="Отменить"
                        okText="Сохранить"
                        onCancel={() => store.setSelectedDay(undefined)}
                        footer={
                            <>
                                <Button onClick={() => store.setSelectedDay(undefined)}>Отменить</Button>
                                <Button onClick={saveAdjustmentsWrap} type="primary" disabled={store.errors.length > 0}>
                                    Сохранить
                                </Button>
                            </>
                        }
                    >
                        {store.selectedDay ? (
                            <>
                                <div className={styles.titleContainer}>
                                    <h2>{store.selectedDay.date.format('DD MMMM')}</h2>
                                </div>
                                <DeliveryTimeIntervalAdjustment<DeliveryApiInterval>
                                    intervals={store.selectedDayIntervals}
                                    getBegin={(x) => x.beginTime}
                                    getEnd={(x) => x.endTime}
                                    onRemove={store.removeSelectedDayInterval}
                                    canRemove={(x) => x.id === 0}
                                    switchAvailability={store.switchSelectedDayIntervalAvailability}
                                    canSwitchAvailability={(interval) => interval.state !== State.Expired}
                                    isAvailable={(interval) => interval.state === State.Active}
                                    onBeginChange={store.onSelectedDayIntervalBeginChange}
                                    onEndChange={store.onSelectedDayIntervalEndChange}
                                />
                                <PlusOutlined className={styles.addInterval} onClick={store.addSelectedDayInterval} />
                                {store.errors.length > 0 && (
                                    <div className={styles.errors}>
                                        {store.errors.map((x, i) => (
                                            <div className={styles.error} key={i}>
                                                {x}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Modal>
                </div>
            )}
        </div>
    );
});

export default SpecialIntervalEditor;
