import React from 'react';
import { Button, Form, Select } from 'antd';
import { EditOrderDto } from '@/Model/Order/EditOrderDto';
import { Order } from '@/Model/Order/Order';
import { OrderPaidMethod } from '@/Model/Order/OrderPaidMethod';

interface OrderEditorProps {
    order: Order;
    onSave: (dto: EditOrderDto) => void;
}

export const OrderEditor = (props: OrderEditorProps): JSX.Element => {
    const [selectedPaidMethod, setSelectedPaidMethod] = React.useState((props.order.paidMethod as string) ?? '');

    React.useEffect(() => {
        setSelectedPaidMethod((props.order.paidMethod as string) ?? '');
    }, [props.order.paidMethod]);

    const paidMethods = React.useMemo(() => {
        const arr = Object.values(OrderPaidMethod).map((i) => ({
            value: i.toString(),
            label: i.toString(),
        }));
        arr.unshift({ value: '', label: '' });

        return arr;
    }, []);

    const handleChangePaidMethod = (value: string) => {
        setSelectedPaidMethod(value);
    };

    const onFinish = (values: any) => {
        let paidMethod;
        const keys = Object.keys(OrderPaidMethod) as (keyof typeof OrderPaidMethod)[];

        for (const key of keys) {
            if (OrderPaidMethod[key] === selectedPaidMethod) {
                paidMethod = OrderPaidMethod[key];
            }
        }

        props.onSave({ paidMethod });
    }

    return (
      <Form
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign="left"
          onFinish={onFinish}
      >
          <Form.Item label='Способ оплаты'>
              <Select
                  value={selectedPaidMethod}
                  onSelect={handleChangePaidMethod}
                  options={paidMethods} />
          </Form.Item>
          <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: '0px' }}
          >
              <Button
                  type="primary"
                  htmlType='submit'
                  style={{ float: 'right' }}
              >
                  Сохранить
              </Button>
          </Form.Item>
      </Form>
    );
};
