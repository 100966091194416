import React from 'react';
import { Employee } from '@/Model/Employee/Employee';
import { CompanyApi } from '../Backend/Api/Company/CompanyApi';

export const useAllDrivers = () => {
    const [drivers, setDrivers] = React.useState<Employee[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchDrivers = async () => {
        try {
            setLoading(true);
            const allDrivers = await CompanyApi.getDriverList();
            setDrivers(allDrivers);
        } catch (e) {
            alert('Ошибка загрузки водителей');
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchDrivers();
    }, []);

    return {
        drivers,
        loading,
        fetchDrivers,
    };
};
