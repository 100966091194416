import React from 'react';
import {Button, Spin} from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {RouteComponentProps, withRouter} from 'react-router';
import {Page} from "../Page/Page";
import {DeliveryApi} from "../../Backend/Api/Delivery/DeliveryApi";
import {Itinerary} from "../../Model/Itinerary/Itinerary";
import {ItineraryReport} from "../ItineraryReport/ItineraryReport";
import {DateTime} from "../../Std/DateTime";
import {ArrayUtils} from "../../Std/ArrayUtils";
import './ItinerariesListReports.css';

interface ItinerariesListProps extends RouteComponentProps {
    date: string;
    driverIds: number[];
    returnBack: () => void;
}

export const ItinerariesListReports = withRouter((props: ItinerariesListProps): JSX.Element => {
    const [itineraries, setItineraries] = React.useState<Itinerary[]>([]);
    const [pageLoaded, setPageLoaded] = React.useState(false);

    React.useEffect(() => {
        const getItineraries = async () => {
            const dateArr = props.date.split('.').map(x => +x);
            const dateTime = new DateTime(dateArr[0], dateArr[1] - 1, dateArr[2], 12); // eslint-disable-line

            const result = await DeliveryApi.getDayItinerary(dateTime, props.driverIds);

            setItineraries(result);
            setPageLoaded(true);
        }

        getItineraries();
    }, [props.date, props.driverIds])

    return (
        <Page header='Маршрутные листы'>
            <Spin spinning={!pageLoaded}>
                <div className='itineraries-list__block'>
                    <Button type='primary'
                            onClick={props.returnBack}><ArrowLeftOutlined/></Button>
                    <div>
                        {
                            ArrayUtils.sort(itineraries, i => i.driverName).map((itinerary, index) => (
                                <div key={index} className='itineraries-list__item'>
                                    <ItineraryReport itinerary={itinerary}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Spin>
        </Page>
    );
})