/* eslint-disable complexity */
import React from 'react';
import {observer} from 'mobx-react';
import {HomeOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import {Button, Input, Modal} from 'antd';
import {DeliveryApi} from '../../../Backend/Api/Delivery/DeliveryApi';
import {
  RouteInfo
} from '../../../Backend/Api/Delivery/Model/RouteInfo/RouteInfo';
import {
  TimeMatrixSource
} from '../../../Backend/Api/Delivery/Model/TimeMatrixSource/TimeMatrixSource';
import {Duration} from '../../../Std/Duration';
import {
  formatDistanceInKilometers
} from '../../../Std/FormatDistanceInKilometers';
import {
  TripComparisonStore
} from '../../../Store/TripComparisonStore/TripComparisonStore';
import { MathUtils } from '../../../Std/MathUtils';
import { formatDurationMinutes } from '../../../Std/formatDurationMinutes';
import styles from './TripComparisonsRoutes.module.css';

interface TripComparisonsRoutesProps {
  store: TripComparisonStore;
  saving: boolean;
  handleConfirmOrder: () => void;
  handleBuildRoute: (route: RouteInfo) => void;
  enableModal: (value: boolean) => void;
}

const TripComparisonsRoutes = observer((props: TripComparisonsRoutesProps) => {
  const {store} = props;

  const handleSaveClick = async () => {
    if (!store.selectedRoute || !store.selectedRoute.id) {
      throw new Error('Not selected route for saving adjustments');
    }

    DeliveryApi.postAdjustmentsTripComparisonRoute(
      store.selectedRoute.id,
      store.selectedRoute.comment,
      store.selectedRoute.distanceAdjustment,
      store.selectedRoute.drivingTimeAdjustment
    ).then(() => Modal.success({
        title: 'Изменения сохранены'
      }))
      .catch(() => Modal.error({
          title: 'Ошибка при сохранении'
      }))
  }

  const handleRebuildRoute = async (route: RouteInfo) => {
    if (!route.id) {
        return;
    }
    await DeliveryApi.rebuildTripComparisonRoute(route.id);

    window.location.reload();
  }

  return (
    <>
      {store.routes.map(r =>
        <div
          key={r.name}
          className={`${styles.wrapper} ${store.selectedRoute === r ? styles.selected : ''}`}
        >
          <div
            className={styles.elem}
            onClick={() => store.selectRoute(r)}
          >
            <div className={styles.name}>
              {r.isManual ? "Ручной" : TimeMatrixSource[r.timeMatrixSource]}
              {!r.isManual && r.withDriverPlace &&
                  <span className={styles.plus}> +</span>}
            </div>
            {r.isRouteSet &&
                <div className={styles.routeMetrics}>
                  {r.drivingTime &&
                      <div>
                        {formatDurationMinutes(Duration.fromSeconds(r.drivingTime), !r.drivingTimeAdjustment)}
                        {!!r.drivingTimeAdjustment &&
                          <span> / {formatDurationMinutes(Duration.fromSeconds(r.drivingTime+r.drivingTimeAdjustment))}</span>
                        }
                      </div>
                  }
                  {r.distance &&
                      <div>
                        {formatDistanceInKilometers(r.distance, 1, !r.distanceAdjustment)}
                        {!!r.distanceAdjustment &&
                          <span> / {formatDistanceInKilometers(r.distance+r.distanceAdjustment)}</span>
                        }
                      </div>
                  }
                  {r.routeSimilarity !== undefined &&
                      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                      <b>{MathUtils.round(r.routeSimilarity, 2)}</b>
                  }
                </div>
            }
            <div className={styles.storeIcon}>
              {r.withLoadingStore && <HomeOutlined/>}
            </div>
            <div className={styles.buttons}>
              {r.isManual && !store.routeEditing &&
                  <Button
                      size='small'
                      disabled={props.saving || !r.canBeBuilt}
                      onClick={() => {
                        store.selectRouteEditing(r);
                        props.enableModal(true);
                      }}
                  >
                    {!r.isRouteSet ? 'Указать' : 'Указать заново'}
                  </Button>
              }
              {r.isManual && store.routeEditing?.selectedRoute === r &&
                  <Button
                      size='small'
                      type='primary'
                      disabled={!store.routeEditing.isComplete(store.orders.length)}
                      onClick={props.handleConfirmOrder}
                  >
                      Сохранить порядок
                  </Button>
              }
              {!r.isManual &&
                  <Button
                      size='small'
                      disabled={props.saving}
                      type='primary'
                      onClick={() => props.handleBuildRoute(r)}
                  >
                    {!r.isRouteSet ? 'Построить' : 'Перестроить'}
                    {r.isStale ? ' (уст.)' : ''}
                  </Button>
              }
              {r.isRouteSet && store.routeEditing?.selectedRoute !== r &&
                  <Button
                      size='small'
                      disabled={props.saving}
                      onClick={() => handleRebuildRoute(r)}
                  >
                      Пересчитать
                  </Button>
              }
            </div>
          </div>
          {r === store.selectedRoute && r.id &&
              <div className={styles.inputs}>
                  <div className={styles.flex}>

                      <label className={styles.comment}>
                          Комментарий
                          <TextArea value={r.comment}
                                    onChange={(e) => r.setComment(e.target.value)}
                                    className={styles.textarea}/>
                      </label>

                      <div className={styles.adjustments}>
                          <label>
                              Корр-ка расс-ния
                              <Input
                                  type='number'
                                  value={r.distanceAdjustment}
                                  onChange={(e) => r.setDistanceAdjustment(e.target.valueAsNumber)}
                              />
                          </label>
                          <label>
                              Корр-ка времени
                              <Input
                                  type='number'
                                  value={r.drivingTimeAdjustment}
                                  onChange={(e) => r.setDrivingTimeAdjustment(e.target.valueAsNumber)}
                              />
                          </label>
                          <Button
                              className={styles.saveButton}
                              disabled={!store.selectedRoute.id}
                              onClick={handleSaveClick}
                          >
                              Сохранить
                          </Button>
                      </div>
                  </div>
              </div>
          }
        </div>
      )}
    </>
  )
});

export default TripComparisonsRoutes;