import {
    DraftApiDistrictsResponse
} from '../../../../../ApiClient/YosoDraft/models';
import { District } from '../../../../../Model/District/District';
import { DraftDriver } from '../../../../../Model/DraftDriver/DraftDriver';
import { DistrictMapper } from '../../Model/DistrictMapper';

export class GetDistrictsResponseDeserializer {
    public deserialize(apiResponse: DraftApiDistrictsResponse): District[] {
        const districts = apiResponse.response.flatMap(i => i.districts);

        const driversById = new Map<number, DraftDriver>();
        for (const apiDistrict of districts) {
            for (const apiDriver of apiDistrict.drivers) {
                if (driversById.has(apiDriver.id)) {
                    continue;
                }
                driversById.set(apiDriver.id, new DraftDriver(apiDriver.id, apiDriver.name, apiDriver.isActive));
            }
        }

        return districts.map(district => {
            const driverIds = district.drivers.map(d => d.id);
            const districtDrivers: DraftDriver[] = [];

            driverIds.forEach(d => {
                const driver = driversById.get(d);
                if (driver) {
                    districtDrivers.push(driver);
                }
            })

            return DistrictMapper.deserialize(district, districtDrivers);
        });
    }
}

export const getDistrictsResponseDeserializer = new GetDistrictsResponseDeserializer();