import "leaflet"; // eslint-disable-line
import L from "leaflet"; // eslint-disable-line
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { RoundRobinColorPicker } from "./model/RoundRobinColorPicker";
import { MapMode } from "./model/MapMode";
import { EditorOnMapInterface } from "./EditorOnMapInterface";
import "@geoman-io/leaflet-geoman-free" // eslint-disable-line
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css"

export class GeomanEditor implements EditorOnMapInterface {
  private readonly mapContainer: any;
  private readonly polygonCreateActions: ((id: number, geocodeCoords: IGeocode[], color: string) => void)[];
  private readonly markerCreateActions: ((id: number, coords: IGeocode) => void)[];
  private palette: RoundRobinColorPicker | undefined;
  private mapMode: MapMode;
  private readonly icon = L.icon({
    iconUrl: "none",
  });

  public constructor(mapContainer: any) {
    this.mapContainer = mapContainer;
    this.mapContainer.pm.addControls({
      position: 'topright',
      drawCircleMarker: false,
      drawMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawCircle: false,
      rotateMode: false,
      dragMode: false,
      cutPolygon: false,
      editMode: false,
      drawPolygon: false,
      removalMode: false
    })
    this.polygonCreateActions = [];
    this.markerCreateActions = [];
    this.palette = undefined;
    this.mapMode = MapMode.NormalMode;
    mapContainer.on('pm:globalremovalmodetoggled', (e: any) => {
      this.mapMode = e.enabled ? MapMode.DeleteMode : MapMode.NormalMode;
    });
    this.mapContainer.pm.setPathOptions({
      weight: 1
    });
    mapContainer.doubleClickZoom.disable();
  }

  public setSelfIntersection(selfIntersection: boolean) {
    this.mapContainer.pm.setGlobalOptions({
      pmIgnore: false,
      allowSelfIntersection: selfIntersection,
    })
  }

  public setPalette(usedColors: string[]) {
    this.palette = new RoundRobinColorPicker(usedColors);
  }

  public enablePolygonDraw(value: boolean) {
    this.mapContainer.pm.addControls({
      drawPolygon: value
    })
    this.subscribeOnPolygonCreate();
  }

  public enableEditing(value: boolean) {
    this.mapContainer.pm.addControls({
      editMode: value
    })
  }

  public enableDeleting(value: boolean) {
    this.mapContainer.pm.addControls({
      removalMode: value
    })
  }

  public enableMarkerDraw(value: boolean) {
    this.mapContainer.pm.addControls({
      drawMarker: value
    })
    this.mapContainer.pm.setGlobalOptions({markerStyle: {icon: this.icon}})
    this.subscribeOnMarkerCreate();
  }

  public setCenter(coords: IGeocode) {
    this.mapContainer.flyTo({
      lat: coords.latitude,
      lon: coords.longitude
    });
  }

  public getCurrentMapMode() {
    return this.mapMode;
  }

  public onPolygonCreate(action: (id: number, coords: IGeocode[], color: string) => void): void {
    this.polygonCreateActions.push(action);
  }

  public onMarkerCreate(action: (id: number, coords: IGeocode) => void): void {
    this.markerCreateActions.push(action);
  }

  private subscribeOnMarkerCreate() {
    this.mapContainer.on('pm:create', (e: any) => {
      if (e.shape !== "Marker") {
        return
      }

      this.mapContainer.pm.disableDraw('Marker');

      const coords = e.layer.toGeoJSON().geometry.coordinates;
      const latlng = {latitude: coords[1], longitude: coords[0]}

      this.markerCreateActions.forEach((a) => a(-e.layer._leaflet_id, latlng));
    })
  }

  private subscribeOnPolygonCreate() {
    this.mapContainer.on('pm:create', (e: any) => {
      if (e.shape !== "Polygon") {
        return
      }

      const polygonColor = this.palette ? this.palette.getNextColor() : "#3388ff";

      const coords: IGeocode[] = this.latLngToGeocode(e.layer.getLatLngs()[0]);

      this.polygonCreateActions.forEach((a) => a(-e.layer._leaflet_id, coords, polygonColor));

      e.layer.remove();
    })
  }

  private latLngToGeocode(arrLatLng: any): IGeocode[] {
    const geocode: IGeocode[] = arrLatLng.map((item: any) => ({ latitude: item.lat, longitude: item.lng }));

    return geocode;
  }
}