import { DeliveryApiDraftOrder2 } from '../../../../../ApiClient/Yoso/models';
import { DraftOrder } from '../../../../../Model/DraftOrder/DraftOrder';
import { IApiModelSerializer } from '../../../IApiModelSerializer';
import { dateTimePeriodSerializer } from '../DateTimePeriod/DateTimePeriodSerializer';
import { draftProductOperationSerializer } from '../DraftProductOperation/DraftProductOperationSerializer';
import { placeSerializer } from '../Place/PlaceSerializer';

export class DraftOrder2Serializer implements IApiModelSerializer<DraftOrder, DeliveryApiDraftOrder2> {
    public serialize(obj: DraftOrder): DeliveryApiDraftOrder2 {
        if (obj.places.length !== 1) {
            throw new Error('DraftOrder places length not equal to 1');
        }

        return {
            place: placeSerializer.serialize(obj.places[0]),
            carId: obj.executor!.car.id,
            comment: obj.comment ?? '',
            addressComment: obj.addressComment ?? '',
            desiredDateTimePeriod: dateTimePeriodSerializer.serialize(obj.desiredDateTimePeriod!),
            driverId: obj.executor!.driver.id,
            productOperations: obj.operations.productOperations.map((productOperation) =>
                draftProductOperationSerializer.serialize(productOperation),
            ),
            publicId: obj.publicId,
            code: obj.code!,
            price: obj.price,
            paymentMethod: obj.paymentMethod,
            phoneNumber: obj.phoneNumber,
            additionalPhoneNumbers: obj.additionalPhoneNumbers,
            clientName: obj.clientName,
            fullAddress: obj.fullAddress,
            corporate: obj.corporate,
            departPeriod: obj.departPeriod,
            isInternal: obj.isInternal
        };
    }
}

export const draftOrder2Serializer = new DraftOrder2Serializer();
