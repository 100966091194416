import {
    GeocodeSource as GeocodingApiGeocodeSource,
    GeocodeSource1 as GeocodingApiGeocodeSource1,
} from '../../../../ApiClient/Yoso/models';
import { GeocodeSource } from '../../../../Model/Place/GeocodeSource';

export class GeocodeSourceMapper {
    public static mapToApi(source: GeocodeSource): GeocodingApiGeocodeSource {
        const result =
            source === GeocodeSource.DaData
                ? GeocodingApiGeocodeSource.DADATA
                : source === GeocodeSource.Excel
                ? GeocodingApiGeocodeSource.EXCEL
                : source === GeocodeSource.Nominatim
                ? GeocodingApiGeocodeSource.NOMINATIM
                : source === GeocodeSource.Unknown
                ? GeocodingApiGeocodeSource.UNKNOWN
                : source === GeocodeSource.User
                ? GeocodingApiGeocodeSource.USER
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown mapToApi GeocodeSource '${source?.toString()}'`);
        }

        return result;
    }

    public static mapToApi1(obj: GeocodeSource): GeocodingApiGeocodeSource1 {
        const result =
            obj === GeocodeSource.DaData
                ? GeocodingApiGeocodeSource1.DADATA
                : obj === GeocodeSource.Excel
                ? GeocodingApiGeocodeSource1.EXCEL
                : obj === GeocodeSource.Nominatim
                ? GeocodingApiGeocodeSource1.NOMINATIM
                : obj === GeocodeSource.Unknown
                ? GeocodingApiGeocodeSource1.UNKNOWN
                : obj === GeocodeSource.User
                ? GeocodingApiGeocodeSource1.USER
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown mapToApi1 GeocodeSource '${obj?.toString()}'`);
        }

        return result;
    }

    public static mapFromApi(
        apiSource: GeocodingApiGeocodeSource | GeocodingApiGeocodeSource1 | undefined,
    ): GeocodeSource {
        return apiSource === undefined
            ? GeocodeSource.Unknown
            : apiSource === GeocodingApiGeocodeSource.DADATA
            ? GeocodeSource.DaData
            : apiSource === GeocodingApiGeocodeSource.EXCEL
            ? GeocodeSource.Excel
            : apiSource === GeocodingApiGeocodeSource.NOMINATIM
            ? GeocodeSource.Nominatim
            : apiSource === GeocodingApiGeocodeSource.UNKNOWN
            ? GeocodeSource.Unknown
            : apiSource === GeocodingApiGeocodeSource.USER
            ? GeocodeSource.User
            : GeocodeSource.Unknown;
    }
}
