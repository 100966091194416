import * as React from 'react';
import { formatTime } from '../../../Std/formatTime';
import { DateTime } from '../../../Std/DateTime';

export interface IFormattedTimeProps {
    date: DateTime | undefined;
}

export const FormattedTimeStr = (date: DateTime | undefined): string => date === undefined ? '?' : formatTime(date);

export const FormattedTime = (props: IFormattedTimeProps): JSX.Element => (
    <>{FormattedTimeStr(props.date)}</>
)
