import { IResponseDeserializer } from '../../../IResponseDeserializer';
import {Itinerary} from "../../../../../Model/Itinerary/Itinerary";
import {DeliveriesApiDayItinerary} from "../../../../../ApiClient/Yoso/models";
import {getItineraryResponseDeserializer} from "../GetItinerary/GetItineraryResponseDeserializer";

export class GetDayItineraryResponseDeserializer implements IResponseDeserializer<DeliveriesApiDayItinerary, Itinerary[]> {
    public deserialize(response: DeliveriesApiDayItinerary): Itinerary[] {
        return response.itineraries.map(itinerary => getItineraryResponseDeserializer.deserialize(itinerary))
    }
}

export const getDayItineraryResponseDeserializer = new GetDayItineraryResponseDeserializer();
