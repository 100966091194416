import {
    DeliveryApiDriverNoticeMailsResponse,
} from '../../../../../ApiClient/Yoso/models';
import {
    DriverNoticeMails
} from '../../../../../Model/DriverNoticeMails/DriverNoticeMails';
import {
    noticeMailDeserializer
} from '../../Model/NoticeMail/NoticeMailDeserializer';

export class GetDriversNoticeMailsDeserializer {
    public deserialize(response: DeliveryApiDriverNoticeMailsResponse): DriverNoticeMails[] {
        const driverNoticeMails: DriverNoticeMails[] = [];

        for (const item of response.mails) {
            const mail = noticeMailDeserializer.deserialize(item)

            const isFound = driverNoticeMails.find(i => i.driver.id === item.driveId)
            if (isFound) {
                isFound.mails.push(mail);
            } else {
                driverNoticeMails.push({
                    driver: { id: item.driveId, name: item.driverName },
                    mails: [mail]
                })
            }
        }

        return driverNoticeMails;
    }
}

export const getDriversNoticeMailsDeserializer = new GetDriversNoticeMailsDeserializer()