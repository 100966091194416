import React from "react";
import { Modal, Spin } from "antd";
import { LatLng, LatLngBoundsLiteral, LatLngTuple } from "leaflet";
import { useCompany } from "../../../AppProviders/Providers/CompanyProvider";
import { CompanyApi } from "../../../../Backend/Api/Company/CompanyApi";
import { DistrictStrictIntervalStore } from "../../../../Store/DistrictStrictInterval/DistrictStrictIntervalStore";
import DistrictStrictIntervalMapEditor from "../../../DistrictIntervalListMapEditor/DistrictStrictIntervalMapEditor";
import { DistrictStrictInterval } from "../../../../Store/DistrictStrictInterval/DistrictStrictInterval";
import MapContainer from "../../../MapContainer/MapContainer";
import DistrictStrictIntervalEditor from "../../../DistrictIntervalListMapEditor/DistrictStrictIntervalEditor";
import "./DistrictStrictIntervalList.css";

interface DistrictStrictIntervalListProps {
}

const DistrictStrictIntervalList = (props: DistrictStrictIntervalListProps) => {
  const companyContext = useCompany();
  const { company } = companyContext;
  const store = React.useMemo(() => new DistrictStrictIntervalStore(), []);

  const [center, setCenter] = React.useState<LatLng | undefined>(undefined);
  const [bounds, setBounds] = React.useState<LatLngBoundsLiteral | undefined>(undefined);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!company) {
      return undefined;
    }

    setCenter(new LatLng(company.centerLatitude, company.centerLongitude));
  }, [company]);

  React.useEffect(() => {
    const loadDistrictsAndDrivers = async () => {
      Promise.all([
        CompanyApi.getDistrictStrictIntervals()
      ]).then((responses) => {
        const districts = responses[0];
        store.addEntities(districts);

        let coordsBounds: LatLngBoundsLiteral = [];

        districts.forEach((district) => {
          const districtBounds: LatLngTuple[] = district.coords.map(coord => ([coord.latitude, coord.longitude]));
          coordsBounds = [...coordsBounds, ...districtBounds];
        })

        setBounds(coordsBounds);
        setPageLoaded(true);
      });
    }

    loadDistrictsAndDrivers();
  }, []);

  const onSave = React.useCallback((district: DistrictStrictInterval): Promise<void> =>
    CompanyApi.postDistrictStrictInterval(district)
      .then((apiDistrict) => {
        if (apiDistrict.id) {
          store.changeEntityId(district, apiDistrict.id);
        }
        Modal.success({
          content: 'Изменения сохранены',
        });
      })
      .catch((e) => {
        alert(String(e))
      }),
    [store]);


  const onRemove = React.useCallback((district: DistrictStrictInterval): Promise<void> => new Promise((resolve, reject) => {
    if (!district.isNew()) {
      CompanyApi.deleteDistrictStrictInterval(district.id)
        .then(() => {
          store.removeEntity(district.id);
          resolve();
        })
        .catch((error) => {
          alert(String(error));
          reject();
        });
    }
    else {
      store.removeEntity(district.id);
      resolve();
    }
  }), [store]);

  return (
    <div className="district-strict-interval-list__page">
      <Spin spinning={!pageLoaded}>
        <div className="district-strict-interval-list__container">
          <div className="district-strict-interval-list__editor">
            <DistrictStrictIntervalEditor
              store={store}
              onRemove={onRemove}
            />
          </div>
          <div className="district-strict-interval-list__map-container">
            {
              pageLoaded && center && bounds &&
              <MapContainer center={center} bounds={bounds.length > 0 ? bounds : undefined}>
                <DistrictStrictIntervalMapEditor
                  store={store}
                  centerCompany={center}
                  onSave={onSave}
                />
              </MapContainer>
            }
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default DistrictStrictIntervalList;
