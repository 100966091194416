import { Geocode } from "../../Model/Geocode/Geocode";
// eslint-disable-next-line
const RBush = require('rbush');

export const getNearPoints = <T extends {latitude: number, longitude: number}>(points: T[], distance: number): {point: T, index: number}[][] => {
    const pointsIndex: Map<T, number> = new Map();
    points.forEach((point, index) => {
        pointsIndex.set(point, index);
    })
    const tree = new RBush()

    const rectangles = points.map(((p: T) => ({
        minX: p.longitude - distance,
        minY: p.latitude - distance,
        maxX: p.longitude + distance,
        maxY: p.latitude + distance
    })))

    tree.load(rectangles);

    const intersectedPoints: T[][] = [];

    points.forEach((x: T, index: number) => {
        const intersections = tree.search(rectangles[index]);
        const intersectionPointIndexes = intersections.map((intersection: any) => rectangles.indexOf(intersection));

        intersectedPoints.push(
            intersectionPointIndexes.map((pointIndex: number) => points[pointIndex])
        )
    })

    const pointAndIntersectionIndex = new Map<T, number[]>();

    intersectedPoints.forEach((pointsGroup: T[], index: number) => {
        pointsGroup.forEach((point: T) => {
            if (!pointAndIntersectionIndex.has(point)) {
                pointAndIntersectionIndex.set(point, []);
            }
            pointAndIntersectionIndex.get(point)?.push(index);
        });
    });

    const result: {point: T, index: number}[][] = [];
    const processedIndexes: Set<number> = new Set();

    pointAndIntersectionIndex.forEach((indexes: number[], m: T) => {
        const pointsCluster: Set<T> = new Set();
        let handleIndexes = indexes;
        while(handleIndexes.length > 0) {
            const newIndexes: number[] = [];
            handleIndexes.forEach((index: number) => {
                if (processedIndexes.has(index)) {
                    return;
                }
                intersectedPoints[index].forEach((point: T) => {
                    pointsCluster.add(point)
                    pointAndIntersectionIndex.get(point)?.forEach((newIndex: number) => {
                        newIndexes.push(newIndex)
                    });
                });
                processedIndexes.add(index);
            });
            handleIndexes = newIndexes;
        }
        if (!pointsCluster.size) {
            return;
        }
        result.push(Array.from(pointsCluster.values()).map(x => ({
            point: x,
            index: pointsIndex.get(x)!
        })))
    });

    return result;
}