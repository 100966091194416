import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Page } from '../../Page/Page';

import './PlaceValidationRoute.css';
import { GeocodingApi } from '../../../Backend/Api/Geocoding/GeocodingApi';
import { LazyEntityList as EntityList } from '../../EntityList/LazyEntityList';
import { Result } from '../../../Model/PaginatedEntities/Result';
import { formatDateTimeShort } from '../../../Std/formatDate';
import { PlaceSelect } from '../../PlaceSelect/PlaceSelect';
import { OrderSuspectPlace } from '../../../Backend/Api/Geocoding/Model/OrderSuspectPlace';
import { useCompany } from '../../AppProviders/Providers/CompanyProvider';
import { Place } from '../../../Model/Place/Place';
import { CarMoveAndStopLocation } from '../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocation';
import { CarMoveAndStopLocations } from '../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocations';
import MapContainer from '../../MapContainer/MapContainer';
import { StopAndGoLocations } from '../../PlaceSelect/StopAndGoLocations';
import { Geocode } from '../../../Model/Geocode/Geocode';

export interface IValidationRouteProps extends RouteComponentProps { }

export const PlaceValidationRoute = withRouter((): JSX.Element => {
    const companyContext = useCompany();

    const [suspectOrderPlaces, setSuspectOrderPlaces] = React.useState([] as ReadonlyArray<OrderSuspectPlace>);
    const [places, setPlaces] = React.useState([] as Place[]);
    const [selectedSuspectOrderPlace, setSelectedSuspectOrderPlace] = React.useState(undefined as OrderSuspectPlace | undefined);
    const [allCarLocations, setAllCarLocations] = React.useState([] as ReadonlyArray<CarMoveAndStopLocations>);
    const [carLocations, setCarLocations] = React.useState([] as ReadonlyArray<CarMoveAndStopLocation>);
    const [refresh, setRefresh] = React.useState(0);

    const getSuspectPlaces = (page: number, size: number): Promise<Result<OrderSuspectPlace>> =>
        new Promise<Result<OrderSuspectPlace>>((resolve, reject) => {
            GeocodingApi.getOrderSuspectPlaces(size, page).then((response) => {
                setSuspectOrderPlaces(response.places);
                setAllCarLocations(response.carLocations);
                resolve({
                    entities: response.places,
                    total: response.total,
                });
            }).catch((reason: any) => {
                alert(String(reason));
                reject();
            });
        });

    React.useEffect(() => {
        const id = suspectOrderPlaces.length > 0 && allCarLocations.length > 0 ? suspectOrderPlaces[0].id : 0;

        onPlaceClick(id)();
    }, [suspectOrderPlaces, allCarLocations])

    const onPlaceClick = React.useCallback((id: number) => (): undefined => {
        const place = suspectOrderPlaces.find((x) => x.id === id);

        setSelectedSuspectOrderPlace(place);

        if (place) {
            setPlaces([place.place]);
            const foundCarLocations = allCarLocations.find((x) => x.tripId === place.tripId);
            if (foundCarLocations) {
                setCarLocations(foundCarLocations.locations);
            } else {
                setCarLocations([]);
            }
        } else {
            setPlaces([]);
            setCarLocations([]);
        }

        return;
    }, [suspectOrderPlaces, allCarLocations]);

    const handleSelectAddressPlace = React.useCallback(async (place: Place) => {
        if (selectedSuspectOrderPlace === undefined) {
            return;
        }

        await GeocodingApi.postValidatedPlace(place, selectedSuspectOrderPlace.id);

        setRefresh(refresh + 1);
    }, [selectedSuspectOrderPlace, refresh]);

    const handleSkipAddressPlace = React.useCallback(async () => {
        if (selectedSuspectOrderPlace === undefined) {
            return;
        }

        await GeocodingApi.skipOrderSuspectPlace(selectedSuspectOrderPlace.id);

        setRefresh(refresh + 1);
    }, [selectedSuspectOrderPlace, refresh]);

    const renderDriverNameWithLink = (sp: OrderSuspectPlace): JSX.Element => (
        <>
            <a title="Открыть сравнение в новом окне" href={`/plans/${sp.planId}/analysis/${sp.tripId}`} target="_blank" rel="noreferrer">
                {sp.driverName}
            </a>
        </>
    );

    const searchAddress = React.useCallback(async (address: string): Promise<{geocode: Geocode, isExactly: boolean} | undefined> => {
        try {
            const result = await GeocodingApi.geocodeAddress(address);
            const coords = new Geocode({latitude: result.geocode.latitude, longitude: result.geocode.longitude});

            return {geocode: coords, isExactly: result.isExactly};
        } catch {
            return undefined;
        }
    }, []);

    return (
        <Page header="Проблемные геокоды">
            <div className="place-validation-page-wrapper">
            <div className="place-validation-page">
                <EntityList<OrderSuspectPlace>
                    getEntities={getSuspectPlaces}
                    id={(sp) => sp.id}
                    columns={[
                        {
                            key: 'addressName',
                            title: 'Адрес',
                            render: (sp) => <span>
                                    {sp.place.prettyAddress || sp.place.address}
                                </span>
                        },
                        {
                            key: 'fullAddressName',
                            title: 'Адрес полный',
                            render: (sp) => <span>
                                    {sp.place.fullAddress}
                                </span>
                        },
                        {
                            key: 'clientName',
                            title: 'Клиент',
                            render: (sp) => sp.clientName
                        },
                        {
                            key: 'driverName',
                            title: 'Водитель',
                            render: renderDriverNameWithLink
                        },
                        {
                            key: 'createdAt',
                            title: 'Создан',
                            render: (sp) => formatDateTimeShort(sp.createdAt)
                        },
                    ]}
                    className="place-validation__entity-list"
                    actions={{
                        item: {
                            click: onPlaceClick,
                        }
                    }}
                    selectedItems={selectedSuspectOrderPlace === undefined ? undefined : [selectedSuspectOrderPlace]}
                    refresh={refresh}
                />
                {companyContext.company !== undefined && (
                    <MapContainer
                        center={[companyContext.company.centerLatitude, companyContext.company.centerLongitude]}
                        fullscreen={true}
                        className="place-select__map-container"
                    >
                        <PlaceSelect
                            places={places}
                            onSelectPlace={handleSelectAddressPlace}
                            onSkip={handleSkipAddressPlace}
                            onSearchAddress={searchAddress}
                        />
                        <StopAndGoLocations moveAndStopRoute={carLocations} />
                    </MapContainer>
                )}
            </div>
            </div>
        </Page>

    );
});
