import React from 'react';
import {observer} from 'mobx-react';
import L from 'leaflet';
import {Geocode} from '../../Model/Geocode/Geocode';
import {TripComparisonStore} from '../../Store/TripComparisonStore/TripComparisonStore';
import {MapContext} from '../MapContainer/MapContextProvider';
import { Marker } from '../MapContainer/model/Marker';
import { Path } from '../MapContainer/model/Path';
import './DriverPlaceSelector.css'

interface DriverPlaceSelectorProps {
    store: TripComparisonStore;
}

const DriverPlaceSelector = observer((props: DriverPlaceSelectorProps) => {
    const maxZoom = 18;

    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const drawContext = React.useMemo(() => mapContext.getDrawContext(), []);

    React.useEffect(() => {
        if (!props.store.orders) {
            return undefined;
        }

        const markers = props.store.orders.map(o => {
            const mapMarker = new Marker(
                o.coords.latitude,
                o.coords.longitude,
                undefined,
                true,
                {text: `${o.address}<br/>${o.clientName}`},
                undefined,
                'driver-place-selector__marker ' +
                `${o.id === props.store.selectedOrder?.id 
                    ? 'driver-place-selector__selected-marker ' 
                    : ''
                }` +
                `${o.driverPlace
                    ? 'driver-place-selector__marker-with-driver-place '
                    : ''
                }`,
                o.id === props.store.selectedOrder?.id ? 1 : undefined
            )

            mapMarker.onClick(() => props.store.selectOrder(o.id));

            return mapMarker;
        })

        if (props.store.driverPlaces) {
            const driverPlaces = props.store.driverPlaces.map(p => {
                const driverMarker = new Marker(
                    p.coords.latitude,
                    p.coords.longitude,
                    'в',
                    true,
                    undefined,
                    undefined,
                    'driver-place-selector__marker driver-place-selector__driver-marker ' +
                    `${props.store.selectedOrder?.driverPlace=== p
                        ? 'driver-place-selector__selected-marker'
                        : ''
                    }`
                )

                driverMarker.onClick(() => {
                    if (props.store.selectedOrder) {
                        props.store.addDriverPlace(p);
                        props.store.selectOrder(undefined)
                    }
                });

                driverMarker.onRightClick(() => {
                    props.store.removeDriverPlace(p);
                });

                return driverMarker;
            })

            markers.push(...driverPlaces);
        }

        drawContext.renderPath(props.store.orders
            .filter(o => o.driverPlace !== undefined)
            .map(o => new Path([
                {latitude: o.coords.latitude, longitude: o.coords.longitude},
                {latitude: o.driverPlace!.coords.latitude, longitude: o.driverPlace!.coords.longitude}
            ],
                o === props.store.selectedOrder
                    ? 'driver-place-selector__selected-path'
                    : 'driver-place-selector__path'
                , '#090606')
            ));

        drawContext.renderMarkers(markers);
    }, [props.store.orders, props.store.driverPlaces, props.store.driverPlaces.length, props.store.selectedOrder])

    React.useEffect(() => {
        mapContext.onMapClick((coords: L.LatLng) => {
            props.store.addNewDriverPlace(new Geocode({latitude: coords.lat, longitude: coords.lng}));
            props.store.selectOrder(undefined);
        });

    }, []);

    React.useEffect(() => {
        if (props.store.selectedOrder) {
            mapContext.setCenter(props.store.selectedOrder.coords, maxZoom);
        }
    }, [props.store.selectedOrder])

    return null;
})

export default DriverPlaceSelector;