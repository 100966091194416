import { makeAutoObservable } from 'mobx';
import {DistrictGroupCode} from '../../Store/District/DistrictGroupCode';
import { DraftDriver } from '../DraftDriver/DraftDriver';
import { IGeocode } from "../Geocode/IGeocode";

export class District {
    public id?: number;
    public readonly name: string;
    public readonly geocodes: IGeocode[];
    public color: string;
    public readonly group: DistrictGroupCode;
    public cityDistrict?: string;
    public drivers: DraftDriver[] = [];

    public constructor(name: string, geocodes: ReadonlyArray<IGeocode>, color: string, group: DistrictGroupCode) {
        this.name = name;
        this.geocodes = [...geocodes];
        this.color = color;
        this.group = group;

        makeAutoObservable(this);
    }

    public setId(id: number): this {
        this.id = id;

        return this;
    }

    public setCityDistrict(cityDistrict?: string): this {
        this.cityDistrict = cityDistrict;

        return this;
    }

    public setDrivers(drivers: DraftDriver[]): this {
        this.drivers = drivers;

        return this;
    }

    public setDriver(driver: DraftDriver): void {
        this.drivers = [driver];
    }

    public removeDriver(driver: DraftDriver): void {
        this.drivers = this.drivers.filter(d => d.id !== driver.id);
    }

    public isPointInsideDistrict(point: IGeocode): boolean {
        let inside = false;

        // eslint-disable-next-line no-plusplus
        for (let i = 0, j = this.geocodes.length - 1; i < this.geocodes.length; j = i++) {
            const xi = this.geocodes[i].latitude;
            const yi = this.geocodes[i].longitude;
            const xj = this.geocodes[j].latitude;
            const yj = this.geocodes[j].longitude;

            const intersect =
                yi > point.longitude !== yj > point.longitude &&
                point.latitude < ((xj - xi) * (point.longitude - yi)) / (yj - yi) + xi;

            if (intersect) {
                inside = !inside;
            }
        }

        return inside;
    }

    public findMinDistanceToDistrict(point: IGeocode): number {
        let minDistance = Infinity;

        for (let i = 0; i < this.geocodes.length; i++) {
            const j = (i + 1) % this.geocodes.length;
            const vertex1 = this.geocodes[i];
            const vertex2 = this.geocodes[j];

            const distanceToEdge = this.distanceToSegment(point, vertex1, vertex2);
            minDistance = Math.min(minDistance, distanceToEdge);

            const distanceToVertex = this.distanceBetweenPoints(point, vertex1);
            minDistance = Math.min(minDistance, distanceToVertex);
        }

        return minDistance;
    }

    public setColor(color: string): void {
        this.color = color;
    }

    private distanceBetweenPoints(p1: IGeocode, p2: IGeocode) {
        const R = 6371; // Радиус Земли в километрах
        const dLat = (p2.latitude - p1.latitude) * Math.PI / 180; // eslint-disable-line
        const dLon = (p2.longitude - p1.longitude) * Math.PI / 180; // eslint-disable-line
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) + // eslint-disable-line
            Math.cos(p1.latitude * Math.PI / 180) * Math.cos(p2.latitude * Math.PI / 180) * // eslint-disable-line
            Math.sin(dLon / 2) * Math.sin(dLon / 2); // eslint-disable-line

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // eslint-disable-line

        return R * c;
    }

    private distanceToSegment(p: IGeocode, p1: IGeocode, p2: IGeocode) {
        const segmentLength = this.distanceBetweenPoints(p1, p2);
        if (segmentLength === 0) {
            return this.distanceBetweenPoints(p, p1);
        }

        const t = ((p.latitude - p1.latitude) * (p2.latitude - p1.latitude) + (p.longitude - p1.longitude) * (p2.longitude - p1.longitude)) / (segmentLength * segmentLength);

        if (t < 0) {
            return this.distanceBetweenPoints(p, p1);
        }
        if (t > 1) {
            return this.distanceBetweenPoints(p, p2);
        }

        const projection = {
            latitude: p1.latitude + t * (p2.latitude - p1.latitude),
            longitude: p1.longitude + t * (p2.longitude - p1.longitude)
        };

        return this.distanceBetweenPoints(p, projection);
    }
}