import { Geocode } from '../../../Model/Geocode/Geocode';
import { TripPointType } from '../../../Model/TripPointId/TripPointType';
import { DateTime } from '../../../Std/DateTime';

import { RoutePoint } from './RoutePoint';

export class FinishRoutePoint extends RoutePoint {
    public readonly expectedDistanceTo: number;

    public constructor(
        originalId: number,
        id: string,
        type: TripPointType,
        coordinates: Geocode,
        address: string,
        expectedArrivalDateTime: DateTime | undefined,
        actualArrivalDateTime: DateTime | undefined,
        expectedDistanceTo: number,
    ) {
        super(originalId, id, type, coordinates, undefined, address, expectedArrivalDateTime, undefined, actualArrivalDateTime, undefined, false);

        this.expectedDistanceTo = expectedDistanceTo;
    }
}
