import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ArrayUtils } from '../../../Std/ArrayUtils';
import { Page } from '../../Page/Page';
import { RoutedTabs } from '../../RoutedTabs/RoutedTabs';
import DistrictStrictIntervalList from './DistrictStrictIntervalList/DistrictStrictIntervalList';
import IntervalEditor from './IntervalEditor/IntervalEditor';

export interface IIntervalRouteRouteProps extends RouteComponentProps {}

export const IntervalRoute = withRouter(
  (props: IIntervalRouteRouteProps): JSX.Element => (
    <Page header="Интервалы">
      <RoutedTabs
        className="fullheight"
        tabs={ArrayUtils.defined([
          {
            title: 'Интервалы',
            url: `/interval`,
            render: () => <IntervalEditor />,
            cache: true,
          },
          {
            title: 'Строгие интервалы',
            url: '/interval/strict',
            render: () => <DistrictStrictIntervalList />,
            cache: true,
          },
        ])}
      />
    </Page>
  ),
);
