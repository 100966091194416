import React from 'react';
import { FinishRoutePoint } from '../../../Map/Route/RoutePoint/FinishRoutePoint';
import { LoadingStoreRoutePoint } from '../../../Map/Route/RoutePoint/LoadingStoreRoutePoint';
import { LunchRoutePoint } from '../../../Map/Route/RoutePoint/LunchRoutePoint';
import { OrderRoutePoint } from '../../../Map/Route/RoutePoint/OrderRoutePoint';
import {RoutePoint} from '../../../Map/Route/RoutePoint/RoutePoint';
import { StartRoutePoint } from '../../../Map/Route/RoutePoint/StartRoutePoint';
import { LoadingStoreRoutePointContent } from './LoadingStoreRoutePointContent';
import { LunchRoutePointContent } from './LunchRoutePointContent';
import { OrderRoutePointContent } from './OrderRoutePointContent';
import { TerminalRoutePointContent } from './TerminalRoutePointContent';

interface RoutePointContentProps {
    point: RoutePoint;
}

const RoutePointContent = ({point}: RoutePointContentProps) => {
    switch (point.constructor) {
        case OrderRoutePoint:
            return <OrderRoutePointContent
                point={point as OrderRoutePoint}/>;
        case StartRoutePoint:
        case FinishRoutePoint:
            return <TerminalRoutePointContent point={point}/>;
        case LunchRoutePoint:
            return <LunchRoutePointContent
                point={point as LunchRoutePoint}/>;
        case LoadingStoreRoutePoint:
            return <LoadingStoreRoutePointContent
                point={point as LoadingStoreRoutePoint}/>;
        default:
            return null;
    }
};

export default RoutePointContent;