import * as React from 'react';

export interface ICellBarChartProps {
    values: number[];
    maxValue?: number;
    title?: string;
}

export const CellBarChart = (props: ICellBarChartProps): JSX.Element => {
    const p100 = 100;

    const getCssHeight = (dividend: number, divider: number): string => {
        if (divider === 0) {
            return "0";
        }

        return `${dividend / divider * p100}%`
    }

    let maxValue : number = props.maxValue ? props.maxValue : Math.max(...props.values);
    maxValue = maxValue ? maxValue : 1;

    return (
        <div className="cell-bar-chart" title={props.title}>
            {props.values.map((value: number, index: number) => (
                <div className="bar" key={index} style={{
                    height: getCssHeight(value, maxValue)
                }}>{value}</div>
            ))}
        </div>
    )
}