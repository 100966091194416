import { CompanyApiDistrictStrictInterval, CompanyApiDistrictStrictIntervals, CompanyApiGeocode } from '@/ApiClient/YosoCompany/models';
import { IGeocode } from '@/Model/Geocode/IGeocode';
import { DistrictStrictInterval } from '@/Store/DistrictStrictInterval/DistrictStrictInterval';
import { DistrictInterval } from '@/Store/DistrictStrictInterval/DistrictInterval';

export class DistrictStrictIntervalSerializer {
    public serialize(district: DistrictStrictInterval): CompanyApiDistrictStrictInterval {
        if (!district.name) {
            throw new Error('District strict interval name is undefined');
        }

        const geocodes: CompanyApiGeocode[] = [];

        district.coords.forEach((coord: IGeocode) => {
            geocodes.push({ latitude: coord.latitude, longitude: coord.longitude });
        })

        return {
            id: district.isNew() ? undefined : district.id,
            name: district.name,
            color: district.color,
            polygonPoints: geocodes,
            intervals: district.intervals.map((interval) => (
                {
                    beginHour: interval.beginHour,
                    beginMinute: interval.beginMinute,
                    endHour: interval.endHour,
                    endMinute: interval.endMinute,
                    dayOfWeek: interval.daysOfWeek
                }
            ))
        };
    }

    public deserialize(apiDistricts: CompanyApiDistrictStrictIntervals): DistrictStrictInterval[] {

        return apiDistricts.districts.map((apiDistrict) => {
            if (apiDistrict.id === undefined) {
                throw new Error('Error. No districtId in BD');
            }

            const intervals = apiDistrict.intervals.map((interval) => new DistrictInterval(
                interval.beginHour,
                interval.beginMinute,
                interval.endHour,
                interval.endMinute,
                interval.dayOfWeek
            ));

            const district = new DistrictStrictInterval(
                apiDistrict.id,
                apiDistrict.polygonPoints,
                apiDistrict.name,
                apiDistrict.color,
                intervals
            )

            return district;
        });
    }
}

export const districtStrictIntervalSerializer = new DistrictStrictIntervalSerializer();
