import React from "react";
import { Store } from "../../Model/Store/Store";
import { MapContext } from "../MapContainer/MapContextProvider";
import { Marker } from "../MapContainer/model/Marker";
import './StoresOnMap.css'

interface StoresOnMapProps {
    stores: ReadonlyArray<Store>
}

export const StoresOnMap = (props: StoresOnMapProps) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    React.useEffect(() => {
        layoutContext.renderMarkers(props.stores.map(store => (
            new Marker(
                store.coordinates.latitude,
                store.coordinates.longitude,
                undefined,
                true,
                { text: store.address },
                undefined,
                "store-map-marker"
            )
        )))
    }, [props.stores])

    return null;
};