import React from "react";
import L from "leaflet";
import { Button, Input, Modal } from "antd";
import Search from 'antd/lib/input/Search';
import MapContent from "../MapContainer/MapContent";
import { Place } from "../../Model/Place/Place";
import { MapContext } from "../MapContainer/MapContextProvider";
import { GeocodeQuality } from "../../Model/Place/GeocodeQuality";
import { GeocodeSource } from "../../Model/Place/GeocodeSource";
import { Geocode } from "../../Model/Geocode/Geocode";
import { ArrayUtils } from "../../Std/ArrayUtils";
import { MarkerTooltip } from "../MapContainer/model/MarkerTooltip";
import { PlaceSelectMarker } from "./PlaceSelectMarker";
import './PlaceSelect.css';

export interface IPlaceSelectProps {
    places: ReadonlyArray<Place>;
    onSelectPlace?(place: Place): Promise<void>;
    onSkip?(): Promise<void>;
    onSearchAddress?(address: string): Promise<{ geocode: Geocode, isExactly: boolean } | undefined>;
}

export const PlaceSelect = (props: IPlaceSelectProps) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const [searchString, setSearchString] = React.useState<string>('');
    const [clickedPoint, setClickedPoint] = React.useState<L.LatLng | undefined>(undefined);
    const [userPlace, setUserPlace] = React.useState<Place | undefined>(undefined);
    const [selectedPlace, setSelectedPlace] = React.useState<Place | undefined>(undefined);

    const [coordsInputValue, setCoordsInputValue] = React.useState('');

    React.useEffect(() => {
        setUserPlace(undefined);
        appointSelectedPlace();
        setCoordsInputValue('');

        if (props.places.length < 1) {
            return undefined;
        }

        setSearchString(props.places[0].address);
        if (props.places[0].geocode) {
            mapContext.setCenter(props.places[0].geocode);
        }
    }, [props.places])

    const appointSelectedPlace = React.useCallback(() => {
        setSelectedPlace(undefined);
        if (props.places.length === 1) {
            setSelectedPlace(props.places[0]);

            return;
        }

        let countValidPlaces = 0;
        let indexValidPlace = 0;

        props.places.forEach((place, index) => {
            if ([GeocodeQuality.JustValidated, GeocodeQuality.Validated, GeocodeQuality.Exactly].indexOf(place.geocodeQuality) > -1) {
                countValidPlaces += 1;
                indexValidPlace = index;
            }
        })

        if (countValidPlaces === 1) {
            setSelectedPlace(props.places[indexValidPlace])
        }
    }, [props.places])

    React.useEffect(() => {
        if (!userPlace) {
            return undefined;
        }

        setSelectedPlace(userPlace);
    }, [userPlace])

    React.useEffect(() => {
        if (!clickedPoint) {
            return undefined;
        }

        setUserPlace(createPlace(clickedPoint.lat, clickedPoint.lng));
    }, [clickedPoint])

    React.useEffect(() => {
        mapContext.onMapClick((coord: L.LatLng) => {
            setClickedPoint(coord);
        })
    }, [])

    React.useEffect(() => {
        layoutContext.renderMarkers(createMarkers())
    }, [props.places, userPlace])

    const createPlace = React.useCallback((latitude: number, longitude: number, source?: GeocodeSource, quality?: GeocodeQuality) => (
        new Place(
            props.places[0].address,
            props.places[0].fullAddress,
            latitude,
            longitude,
            quality ? quality : GeocodeQuality.Validated,
            source ? source : GeocodeSource.User,
            props.places[0].addressId
        )
    ), [props.places])

    const createMarkers = React.useCallback((): PlaceSelectMarker[] => {
        let userPlaceInPlaces = false;
        let validated = false;
        const placeMarkers = ArrayUtils.defined(props.places.map(p => {

            if (!p.latitude || !p.longitude) {
                return undefined;
            }

            const tooltip: MarkerTooltip | undefined = p.title ? { text: p.title } : undefined;
            const selected = p === userPlace;
            if (selected) {
                userPlaceInPlaces = true;
            }

            if ([GeocodeQuality.JustValidated, GeocodeQuality.Validated, GeocodeQuality.Exactly].indexOf(p.geocodeQuality) > -1) {
                validated = true;
            }

            const marker = new PlaceSelectMarker(p.latitude, p.longitude, validated, selected, true, tooltip);

            marker.onClick(() => onPlaceClick(p));

            return marker;
        }));

        if (userPlace && !userPlaceInPlaces) {
            placeMarkers.push(new PlaceSelectMarker(
                userPlace.latitude!,
                userPlace.longitude!,
                validated,
                true,
                false,
                undefined
            ));
        }

        return placeMarkers;
    }, [props.places, userPlace]);

    const searchAddress = React.useCallback(async () => {
        setUserPlace(undefined);

        if (!props.onSearchAddress) {
            return;
        }

        const foundAddress = await props.onSearchAddress(searchString);

        if (!foundAddress) {
            Modal.warning({
                title: "Не удалось найти адрес"
            });

            return;
        }

        if (searchGeocodeInPlaces(foundAddress.geocode, props.places)) {
            Modal.warning({
                title: 'Координаты полностью совпадают с выбранной позицией на карте'
            });
            
            return;
        }

        setUserPlace(createPlace(
            foundAddress.geocode.latitude,
            foundAddress.geocode.longitude,
            GeocodeSource.DaData,
            foundAddress.isExactly ? GeocodeQuality.Exactly : GeocodeQuality.None
        ));
    }, [searchString])

    const onPlaceClick = React.useCallback((place: Place) => {
        setUserPlace(place);
    }, [props.places])

    const skipPlaceButton = React.useCallback((): void => {
        if (!props.onSkip) {
            return;
        }

        props.onSkip();
    }, [props.places]);

    const applyPlaceButton = React.useCallback((): void => {
        if (!props.onSelectPlace) {
            return;
        }

        if (selectedPlace) {
            props.onSelectPlace(selectedPlace);
        }
    }, [props.places, selectedPlace]);

    const createMarkerByCoordsInput = React.useCallback((e: any) => {
        setUserPlace(undefined);
        const coords = e.target.value.split(',').map((x: string) => +x);
        if (coords.some(isNaN) || coords.length !== 2) { // eslint-disable-line
            Modal.warning({
                title: 'Неверно заданы координаты'
            });

            return;
        }

        const newGeocode = new Geocode({ latitude: coords[0], longitude: coords[1] });

        if (searchGeocodeInPlaces(newGeocode, props.places)) {
            Modal.warning({
                title: 'Координаты полностью совпадают с выбранной позицией на карте'
            });

            return;
        }

        setUserPlace(createPlace(coords[0], coords[1]));
        mapContext.setCenter({latitude: coords[0], longitude: coords[1]});
    }, [props.places])

    const searchGeocodeInPlaces = (geocode: Geocode, places: ReadonlyArray<Place>): boolean => {
        for (const place of places) {
            if (place.geocode?.round().equals(geocode.round())) {
                return true;
            }
        }

        return false;
    }

    return (
        <>
            <MapContent position="topleft">
                <Search
                    placeholder="Введите адрес"
                    className="place-select__search_input"
                    value={searchString}
                    onSearch={searchAddress}
                />
            </MapContent>

            <MapContent position="topright">
                <Input
                    className="place-select__coords_input"
                    value={coordsInputValue}
                    placeholder="Координаты через запятую"
                    onChange={(e) => setCoordsInputValue(e.target.value)}
                    onPressEnter={createMarkerByCoordsInput}
                />
                <div className="place-select__buttons" >
                    {props.onSkip && (
                        <Button onClick={skipPlaceButton}>Пропустить</Button>)}
                    {props.onSelectPlace && (
                        <Button
                            type="primary"
                            onClick={applyPlaceButton}
                            disabled={!selectedPlace}
                        >
                            Сохранить
                        </Button>)}
                </div>
            </MapContent>
        </>
    );
};