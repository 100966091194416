import { ApiItineraryEmptyBottle, DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { ItineraryEmptyBottle } from '../../../../../Model/Itinerary/ItineraryEmptyBottle';

export class EmptyBottleDeserializer {
    public deserialize(response: ApiItineraryEmptyBottle, products: DeliveryApiProduct[]): ItineraryEmptyBottle {
        return new ItineraryEmptyBottle(
            response.productId,
            products.find(p => p.id === response.productId)?.shortName || "",
            response.quantity
        )
    }
}

export const emptyBottleDeserializer = new EmptyBottleDeserializer();
