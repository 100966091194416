import * as React from 'react';
import {
    LoadingStoreRoutePoint
} from '../../../Map/Route/RoutePoint/LoadingStoreRoutePoint';
import { FormattedTime } from '../RouteInfoFormattedValues/FormattedTime';
import '../RouteInfo.css';

export interface ILoadingStoreRoutePointContentProps {
    point: LoadingStoreRoutePoint;
}

export const LoadingStoreRoutePointContent = (props: ILoadingStoreRoutePointContentProps): JSX.Element => {
    const { point } = props;

    return (
        <>
            <div className="route-info__address">{point.address} (Склад)</div>
            <div>
                <FormattedTime date={point.expectedArrivalDateTime} />
            </div>
        </>
    );
};
