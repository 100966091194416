import { IGeocode } from '../Geocode/IGeocode';

export class Company {
    public readonly id: number | undefined;
    public readonly name: string;
    public readonly cityName: string | undefined;
    public readonly proficiencyCoefficient: number;
    public readonly buildRoutesOnApprove: boolean;
    public readonly alternativePlanAllowedToUse: boolean;
    public readonly centerLatitude: number;
    public readonly centerLongitude: number;
    public readonly timeZoneOffset: number | undefined;
    private readonly mapBoundsOffset = 0.05;

    public constructor(
        id: number | undefined,
        name: string,
        cityName: string | undefined,
        proficiencyCoefficient: number,
        buildRoutesOnApprove: boolean,
        alternativePlanAllowedToUse: boolean,
        centerLatitude: number,
        centerLongitude: number,
        timeZoneOffset: number | undefined) {
        this.id = id;
        this.name = name;
        this.cityName = cityName;
        this.proficiencyCoefficient = proficiencyCoefficient;
        this.buildRoutesOnApprove = buildRoutesOnApprove;
        this.alternativePlanAllowedToUse = alternativePlanAllowedToUse;
        this.centerLatitude = centerLatitude;
        this.centerLongitude = centerLongitude;
        this.timeZoneOffset = timeZoneOffset;
    }

    public getMapBounds(): IGeocode[] {
        return [
            {
                latitude: this.centerLatitude - this.mapBoundsOffset,
                longitude: this.centerLongitude - this.mapBoundsOffset,
            },
            {
                latitude: this.centerLatitude - this.mapBoundsOffset,
                longitude: this.centerLongitude + this.mapBoundsOffset,
            },
            {
                latitude: this.centerLatitude + this.mapBoundsOffset,
                longitude: this.centerLongitude - this.mapBoundsOffset,
            },
            {
                latitude: this.centerLatitude + this.mapBoundsOffset,
                longitude: this.centerLongitude + this.mapBoundsOffset,
            }
        ];
    }
}
