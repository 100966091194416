import classNames from 'classnames';
import * as React from 'react';

import { MathUtils } from '../../Std/MathUtils';

import './LoadingInfo.css';

export interface ICarLoadingInfoProps {
    actual: number;
    actualBottles?: number;
    content?: React.ReactNode;
    total: number;
}

const maxPercents = 100;

export const LoadingInfo = (props: ICarLoadingInfoProps): JSX.Element => {
    const [percents, setPercents] = React.useState(0 as number);
    React.useEffect(() => {
        setPercents(MathUtils.clamp(Math.ceil((props.actual / props.total) * maxPercents), 0, maxPercents));
    }, [props.actual, props.total]);

    return (
        <div className="loading-info">
            <div className="loading-info__value">{props.content ?? `${props.actual}${props.actualBottles !== undefined ? `(${props.actualBottles})` : ''} / ${props.total}`}</div>
            <div className="loading-info__bar">
                <div
                    className={classNames(
                        'loading-info__bar-line',
                        props.actual > props.total && 'loading-info__bar-line_error',
                    )}
                    style={{
                        width: `${percents}%`,
                    }}
                />
            </div>
        </div>
    );
};
