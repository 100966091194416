import { BankOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppMenuProps } from '../AppMenu';

const SuperAdminAppMenu = (props: AppMenuProps) => {
    const {selectedKeys} = props

    return (
        <Menu className="app-menu" mode="inline" theme="dark" selectedKeys={selectedKeys}>
            <Menu.Item key="/companies" className="app-menu__item">
                <BankOutlined />
                <span>Компании</span>
                <Link to="/companies" />
            </Menu.Item>
        </Menu>
    );
};

export default SuperAdminAppMenu;