import {Type2} from '../../../../../ApiClient/Yoso/models';
import {TripPointType} from '../../../../../Model/TripPointId/TripPointType';

export class TripPointTypeSerializer {
    public serialize(obj: TripPointType): Type2 {
        const result =
            obj === TripPointType.LunchPoint ? Type2.LUNCHPOINT : obj === TripPointType.Order ? Type2.ORDER : undefined;

        if (result === undefined) {
            throw new Error(`Unknown TripPointType '${obj?.toString()}'`);
        }

        return result;
    }
}

export const tripPointTypeSerializer = new TripPointTypeSerializer();
