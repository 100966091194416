import { CompanyApiStores } from "../../../../ApiClient/YosoCompany/models";
import {Warehouse} from "../../../../Model/Store/Warehouse";

export class WarehouseSerializer {
    public serialize(request: Warehouse[]): CompanyApiStores {
        return {
            stores: request.map(s => (
                {
                    id: s.id < 0 ? undefined : s.id,
                    address: s.address,
                    latitude: s.coordinates.latitude,
                    longitude: s.coordinates.longitude,
                    main: s.isMain,
                    deleted: s.isDeleted
                }
            ))
        }
    }
}

export const warehouseSerializer = new WarehouseSerializer();