import { dateTimeDeserializer } from '@/Backend/Api/Mapper/DateTimeDeserializer';
import { Employee } from '@/Model/Employee/Employee';
import { DateTime } from '@/Std/DateTime';
import {
    CompanyApiAdminPostRequest,
    CompanyApiAdminPutRequest,
    CompanyApiAdminResponse,
    CompanyApiBotPostRequest,
    CompanyApiBotResponse,
    CompanyApiCar,
    CompanyApiCarListResponse,
    CompanyApiCarResponse,
    CompanyApiCompany,
    CompanyApiCompanyListResponse,
    CompanyApiCompanyPostRequest,
    CompanyApiCompanyProductRequest,
    CompanyApiCompanyProductResponse,
    CompanyApiCompanyProductsResponse,
    CompanyApiCompanyResponse,
    CompanyApiCompanyUpdating,
    CompanyApiDistricts,
    CompanyApiDistrictsControlDate,
    CompanyApiDistrictStrictInterval,
    CompanyApiDistrictStrictIntervals,
    CompanyApiDriverCoefCorrection,
    CompanyApiDriverCoefCorrectionGraphs,
    CompanyApiDriverPostRequest,
    CompanyApiDriverPutRequest,
    CompanyApiDriverResponse,
    CompanyApiEveningDistrictsExists,
    CompanyApiIntervals,
    CompanyApiLogistPostRequest,
    CompanyApiLogistPutRequest,
    CompanyApiLogistResponse,
    CompanyApiLogs,
    CompanyApiOrders,
    CompanyApiServiceZone,
    CompanyApiSettingsRequest,
    CompanyApiSettingsResponse,
    CompanyApiStoresResponse,
    CompanyReportSettings,
    CreateCarResponse,
    DeleteAdminResponse,
    DeleteCarResponse,
    DeleteCompanyAdminResponse,
    DeleteCompanyResponse,
    DeleteDistrictResponse,
    DeleteDistrictStrictIntervalResponse,
    DeleteDriverResponse,
    DeleteLogistResponse,
    GetAdminListResponse,
    PostAdminResponse,
    PostCompanyAdminResponse,
    PostCompanyDistrictsResponse,
    PostCompanyResponse,
    PostCompanyServiceZoneResponse,
    PostCompanyStoresResponse,
    PostDriverResponse,
    PutCompanyAdminResponse,
    PutCompanyProductResponse,
    PutCompanyResponse,
    PutDriverResponse,
    UpdateCarResponse,
    UpdateMyCompanyResponse,
} from '../../../ApiClient/YosoCompany/models';
import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { Warehouse } from '../../../Model/Store/Warehouse';
import { Time } from '../../../Std/Time';
import { DistrictOrder } from '../../../Store/District/DistrictOrder';
import { dateTimeSerializer } from '../Mapper/DateTimeSerializer';
import { timeSerializer } from '../Mapper/TimeSerializer';
import { Api } from '../Api';
import { District as DistrictOnMap, District } from '../../../Store/District/District';
import { DistrictStrictInterval } from '../../../Store/DistrictStrictInterval/DistrictStrictInterval';
import { CompanyProduct } from '../../../Model/CompanyProduct/CompanyProduct';
import {
    getCompanyResponseDeserializer,
    IGetCompanyResponse,
} from './Methods/GetCompany/GetCompanyResponseDeserializer';
import { getCompanyStoresResponseDeserializer } from './Methods/GetCompany/GetCompanyStoresDeserializer';
import { districtSerializer } from './Model/District/DistrictSerializer';
import { companyDeserializer } from './Model/CompanyDeserializer';
import { companySerializer } from './Model/CompanySerializer';
import { districtStrictIntervalSerializer } from './Model/DistrictStrictInterval/DistrictStrictIntervalSerializer';
import { getCompanyProductsDeserializer } from './Methods/GetCompany/GetCompanyProductsDeserializer';
import { companyProductDeserializer } from './Methods/GetCompany/CompanyProductDeserializer';
import { districtOrdersSerializer } from './Model/District/DistrictOrderSerializer';
import { warehouseSerializer } from './Model/WarehouseSerializer';
import { employeeDeserializer } from './Model/EmployeeDeserializer/EmployeeDeserializer';

export class CompanyApi {
    public static async createCar(car: CompanyApiCar): Promise<undefined> {
        await Api.doRequest<CreateCarResponse>(() => Api.companyApi.createCar(car, Api.getRequestOptions()));

        return;
    }

    public static async upsertUserForDriver(employeeCode: string, login: string, pass: string): Promise<undefined> {
        await Api.doRequest<DeleteDriverResponse>(() =>
            Api.companyApi.upsertUserForDriver(
                {
                    employeeCode: employeeCode,
                    credentials: {
                        username: login,
                        password: pass,
                    },
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async deleteAdmin(id: number): Promise<undefined> {
        await Api.doRequest<DeleteAdminResponse>(() => Api.companyApi.deleteAdmin(id, Api.getRequestOptions()));

        return;
    }

    public static async deleteCar(id: number): Promise<undefined> {
        await Api.doRequest<DeleteCarResponse>(() => Api.companyApi.deleteCar(id, Api.getRequestOptions()));

        return;
    }

    public static async deleteCompany(id: number): Promise<undefined> {
        await Api.doRequest<DeleteCompanyResponse>(() => Api.companyApi.deleteCompany(id, Api.getRequestOptions()));

        return;
    }

    public static async deleteCompanyAdmin(companyId: number, id: number): Promise<undefined> {
        await Api.doRequest<DeleteCompanyAdminResponse>(() =>
            Api.companyApi.deleteCompanyAdmin(companyId, id, Api.getRequestOptions()),
        );

        return;
    }

    public static async deleteDriver(id: number): Promise<undefined> {
        await Api.doRequest<DeleteDriverResponse>(() => Api.companyApi.deleteDriver(id, Api.getRequestOptions()));

        return;
    }

    public static async deleteLogistician(id: number): Promise<undefined> {
        await Api.doRequest<DeleteLogistResponse>(() => Api.companyApi.deleteLogist(id, Api.getRequestOptions()));

        return;
    }

    public static async getAdmin(id: number): Promise<CompanyApiAdminResponse> {
        return Api.doRequest<CompanyApiAdminResponse>(() => Api.companyApi.getAdmin(id, Api.getRequestOptions()));
    }

    public static async getAdminList(): Promise<CompanyApiAdminResponse[]> {
        const response = await Api.doRequest<{ [index: number]: CompanyApiAdminResponse } & GetAdminListResponse>(() =>
            Api.companyApi.getAdminList(Api.getRequestOptions()),
        );

        const result: CompanyApiAdminResponse[] = [];

        // eslint-disable-next-line no-underscore-dangle
        const adminCount = JSON.parse(response._response.bodyAsText).length as number;
        for (let i = 0; i < adminCount; i += 1) {
            result.push(response[i]);
        }

        return result;
    }

    public static async getStoreList(): Promise<Warehouse[]> {
        const response = await Api.doRequest<CompanyApiStoresResponse>(() =>
            Api.companyApi.getCompanyStores(Api.getRequestOptions()),
        );

        return getCompanyStoresResponseDeserializer.deserialize(response);
    }

    public static async getCar(carId: number): Promise<CompanyApiCarResponse> {
        return Api.doRequest<CompanyApiCarResponse>(() => Api.companyApi.getCar(carId, Api.getRequestOptions()));
    }

    public static async getCarList(): Promise<CompanyApiCarListResponse> {
        return Api.doRequest<CompanyApiCarListResponse>(() => Api.companyApi.getCarList(Api.getRequestOptions()));
    }

    public static async getCompany(id: number): Promise<CompanyApiCompanyResponse> {
        return Api.doRequest<CompanyApiCompanyResponse>(() => Api.companyApi.getCompany(id, Api.getRequestOptions()));
    }

    public static async getCompanyAdmin(companyId: number, id: number): Promise<CompanyApiAdminResponse> {
        const response = await Api.doRequest<[CompanyApiAdminResponse]>(() =>
            Api.companyApi.getCompanyAdmin(companyId, id, Api.getRequestOptions()),
        );

        return response[0];
    }

    public static async getCompanyAdminList(companyId: number): Promise<CompanyApiAdminResponse[]> {
        return Api.doRequest<CompanyApiAdminResponse[]>(() =>
            Api.companyApi.getCompanyAdminList(companyId, Api.getRequestOptions()),
        );
    }

    public static async getCompanyList(): Promise<CompanyApiCompanyListResponse> {
        return Api.doRequest<CompanyApiCompanyListResponse>(() =>
            Api.companyApi.getCompanyList(Api.getRequestOptions()),
        );
    }

    public static async getDriver(id: number): Promise<Employee> {
        const driver = await Api.doRequest<CompanyApiDriverResponse>(() =>
            Api.companyApi.getDriver(id, Api.getRequestOptions()),
        );

        return employeeDeserializer.mapFromApi(driver);
    }

    public static async getDriverList(): Promise<Employee[]> {
        const apiDrivers = await Api.doRequest<CompanyApiDriverResponse[]>(() =>
            Api.companyApi.getDriverList(Api.getRequestOptions()),
        );

        return apiDrivers.map((i) => employeeDeserializer.mapFromApi(i));
    }

    public static async getLogistician(id: number): Promise<CompanyApiLogistResponse> {
        return Api.doRequest<CompanyApiLogistResponse>(() => Api.companyApi.getLogist(id, Api.getRequestOptions()));
    }

    public static async getLogisticianList(): Promise<CompanyApiLogistResponse[]> {
        return Api.doRequest<CompanyApiLogistResponse[]>(() => Api.companyApi.getLogistList(Api.getRequestOptions()));
    }

    public static async getReportSettings(): Promise<CompanyReportSettings> {
        return Api.doRequest<CompanyReportSettings>(() => Api.companyApi.getReportSettings(Api.getRequestOptions()));
    }

    public static async getMyCompany(): Promise<IGetCompanyResponse> {
        const response = await Api.doRequest<CompanyApiCompanyResponse>(() =>
            Api.companyApi.getMyCompany(Api.getRequestOptions()),
        );

        return getCompanyResponseDeserializer.deserialize(response);
    }

    public static async postCompanyStores(request: Warehouse[]): Promise<undefined> {
        const apiStores = warehouseSerializer.serialize(request);

        await Api.doRequest<PostCompanyStoresResponse>(() =>
            Api.companyApi.postCompanyStores(apiStores, Api.getRequestOptions()),
        );

        return;
    }

    public static async postAdmin(request: CompanyApiAdminPostRequest): Promise<undefined> {
        await Api.doRequest<PostAdminResponse>(() => Api.companyApi.postAdmin(request, Api.getRequestOptions()));

        return;
    }

    public static async postCompany(request: CompanyApiCompanyPostRequest): Promise<undefined> {
        await Api.doRequest<PostCompanyResponse>(() => Api.companyApi.postCompany(request, Api.getRequestOptions()));

        return;
    }

    public static async postCompanyAdmin(companyId: number, request: CompanyApiAdminPostRequest): Promise<undefined> {
        await Api.doRequest<PostCompanyAdminResponse>(() =>
            Api.companyApi.postCompanyAdmin(companyId, request, Api.getRequestOptions()),
        );

        return;
    }

    public static async postDriver(request: CompanyApiDriverPostRequest): Promise<undefined> {
        await Api.doRequest<PostDriverResponse>(() => Api.companyApi.postDriver(request, Api.getRequestOptions()));

        return;
    }

    public static async postLogistician(request: CompanyApiLogistPostRequest): Promise<undefined> {
        await Api.doRequest<CompanyApiLogistResponse>(() =>
            Api.companyApi.postLogist(request, Api.getRequestOptions()),
        );

        return;
    }

    public static async putAdmin(request: CompanyApiAdminPutRequest): Promise<undefined> {
        await Api.doRequest<CompanyApiAdminResponse>(() => Api.companyApi.putAdmin(request, Api.getRequestOptions()));

        return;
    }

    public static async putCompany(companyId: number, request: CompanyApiCompanyUpdating): Promise<undefined> {
        await Api.doRequest<PutCompanyResponse>(() =>
            Api.companyApi.putCompany(companyId, request, Api.getRequestOptions()),
        );

        return;
    }

    public static async putCompanyAdmin(companyId: number, request: CompanyApiAdminPutRequest): Promise<undefined> {
        await Api.doRequest<PutCompanyAdminResponse>(() =>
            Api.companyApi.putCompanyAdmin(companyId, request, Api.getRequestOptions()),
        );

        return;
    }

    public static async putDriver(request: CompanyApiDriverPutRequest): Promise<undefined> {
        await Api.doRequest<PutDriverResponse>(() => Api.companyApi.putDriver(request, Api.getRequestOptions()));

        return;
    }

    public static async putLogistician(request: CompanyApiLogistPutRequest): Promise<undefined> {
        await Api.doRequest<CompanyApiLogistResponse>(() => Api.companyApi.putLogist(request, Api.getRequestOptions()));

        return;
    }

    public static async putReportSettings(
        isDriversBeingLateReportEnabled: boolean,
        reportTime: Time | undefined,
        driversBeingLateReportEmails: string[],
        isDriverFailOrderReportEnabled: boolean,
        driverFailOrderReportEmails: string[],
        isDriverRescheduleOrderReportEnabled: boolean,
        driverRescheduleOrderReportEmails: string[],
    ): Promise<undefined> {
        const driversBeingLateReportTime = reportTime ? timeSerializer.serialize(reportTime) : undefined;
        const settings = {
            isDriversBeingLateReportEnabled,
            driversBeingLateReportTime,
            driversBeingLateReportEmails,
            isDriverFailOrderReportEnabled,
            driverFailOrderReportEmails,
            isDriverRescheduleOrderReportEnabled,
            driverRescheduleOrderReportEmails,
        };
        await Api.doRequest<CompanyReportSettings>(() =>
            Api.companyApi.putReportSettings(settings, Api.getRequestOptions()),
        );

        return;
    }

    public static async updateCar(carId: number, car: CompanyApiCar): Promise<undefined> {
        await Api.doRequest<UpdateCarResponse>(() => Api.companyApi.updateCar(carId, car, Api.getRequestOptions()));

        return;
    }

    public static async updateMyCompany(company: CompanyApiCompany): Promise<UpdateMyCompanyResponse> {
        return Api.doRequest<UpdateMyCompanyResponse>(() =>
            Api.companyApi.updateMyCompany(company, Api.getRequestOptions()),
        );
    }

    public static async getSettings(): Promise<CompanyApiSettingsResponse> {
        return Api.doRequest<CompanyApiSettingsResponse>(() => Api.companyApi.getSettings(Api.getRequestOptions()));
    }

    public static async putSettings(smsSettings: CompanyApiSettingsRequest): Promise<undefined> {
        await Api.doRequest<CompanyApiSettingsRequest>(() =>
            Api.companyApi.putSettings(smsSettings, Api.getRequestOptions()),
        );

        return;
    }

    public static async getCompanyDistricts(
        group: string,
    ): Promise<{ districts: DistrictOnMap[]; controlDate: DateTime }> {
        const response = await Api.doRequest<CompanyApiDistricts>(() =>
            Api.companyApi.getCompanyDistricts(group, Api.getRequestOptions()),
        );

        return {
            districts: districtSerializer.deserializeForMap(response.districts),
            controlDate: dateTimeDeserializer.deserialize(response.controlDate),
        };
    }

    public static async getCompanyOrders(
        dateStart: Date,
        dateEnd: Date,
        ordersGroup: string,
    ): Promise<DistrictOrder[]> {
        const response = await Api.doRequest<CompanyApiOrders>(() =>
            Api.companyApi.getCompanyOrders(
                dateTimeSerializer.serializeDate(dateStart),
                dateTimeSerializer.serializeDate(dateEnd),
                ordersGroup,
                Api.getRequestOptions(),
            ),
        );

        return districtOrdersSerializer.deserializeForMap(response);
    }

    public static async postDistricts(
        districts: District[],
        controlTime: DateTime,
        group: string,
        companyCity?: string,
    ): Promise<undefined> {
        const apiDistricts: CompanyApiDistricts = {
            districts: districts.map((d) => districtSerializer.serializeFromMap(d)),
            controlDate: dateTimeSerializer.serializeDate(controlTime.toDate()),
            group,
        };
        await Api.doRequest<PostCompanyDistrictsResponse>(() =>
            Api.companyApi.postCompanyDistricts(apiDistricts, {
                cityName: companyCity,
                ...Api.getRequestOptions(),
            }),
        );

        return;
    }

    public static async deleteDistrict(id: number, controlDate: DateTime): Promise<DateTime> {
        const res = await Api.doRequest<CompanyApiDistrictsControlDate>(() =>
            Api.companyApi.deleteDistrict(id, dateTimeSerializer.serialize(controlDate), Api.getRequestOptions()),
        );

        return dateTimeDeserializer.deserialize(res.controlDate);
    }

    public static async getDistrictStrictIntervals(): Promise<DistrictStrictInterval[]> {
        const response = await Api.doRequest<CompanyApiDistrictStrictIntervals>(() =>
            Api.companyApi.getCompanyDistrictStrictIntervals(Api.getRequestOptions()),
        );

        return districtStrictIntervalSerializer.deserialize(response);
    }

    public static async postDistrictStrictInterval(
        district: DistrictStrictInterval,
    ): Promise<CompanyApiDistrictStrictInterval> {
        const apiDistrict = districtStrictIntervalSerializer.serialize(district);

        return Api.doRequest<CompanyApiDistrictStrictInterval>(() =>
            Api.companyApi.postCompanyDistrictStrictInterval(apiDistrict, Api.getRequestOptions()),
        );
    }

    public static async deleteDistrictStrictInterval(id: number): Promise<undefined> {
        await Api.doRequest<DeleteDistrictStrictIntervalResponse>(() =>
            Api.companyApi.deleteDistrictStrictInterval(id, Api.getRequestOptions()),
        );

        return;
    }

    public static async getServiceZone(): Promise<IGeocode[]> {
        const response = await Api.doRequest<CompanyApiServiceZone>(() =>
            Api.companyApi.getCompanyServiceZone(Api.getRequestOptions()),
        );

        return companyDeserializer.deserializeServiceZone(response);
    }

    public static async postServiceZone(geocodes: IGeocode[]): Promise<undefined> {
        const apiServiceZone = companySerializer.serializeServiceZone(geocodes);

        await Api.doRequest<PostCompanyServiceZoneResponse>(() =>
            Api.companyApi.postCompanyServiceZone(apiServiceZone, Api.getRequestOptions()),
        );

        return;
    }

    public static async deleteServiceZone(): Promise<undefined> {
        await Api.doRequest<PostCompanyServiceZoneResponse>(() =>
            Api.companyApi.deleteCompanyServiceZone(Api.getRequestOptions()),
        );

        return;
    }

    public static async getLogs(take: number, skip: number): Promise<CompanyApiLogs> {
        return Api.doRequest<CompanyApiLogs>(() =>
            Api.companyApi.getCompanyLogs(take, {
                ...Api.getRequestOptions(),
                skip: skip,
            }),
        );
    }

    public static async getCompanyProducts(): Promise<CompanyProduct[]> {
        const responce = await Api.doRequest<CompanyApiCompanyProductsResponse>(() =>
            Api.companyApi.getCompanyProducts(Api.getRequestOptions()),
        );

        return getCompanyProductsDeserializer.deserialize(responce);
    }

    public static async getCompanyProduct(id: number): Promise<CompanyProduct> {
        const responce = await Api.doRequest<CompanyApiCompanyProductResponse>(() =>
            Api.companyApi.getCompanyProduct(id, Api.getRequestOptions()),
        );

        return companyProductDeserializer.deserialize(responce);
    }

    public static async putCompanyProduct(
        productId: number,
        request: CompanyApiCompanyProductRequest,
    ): Promise<undefined> {
        await Api.doRequest<PutCompanyProductResponse>(() =>
            Api.companyApi.putCompanyProduct(productId, request, Api.getRequestOptions()),
        );

        return;
    }

    public static async getBotList(): Promise<CompanyApiBotResponse[]> {
        return Api.doRequest<CompanyApiBotResponse[]>(() => Api.companyApi.getBotList(Api.getRequestOptions()));
    }

    public static async postBot(request: CompanyApiBotPostRequest): Promise<undefined> {
        await Api.doRequest<CompanyApiBotPostRequest>(() => Api.companyApi.postBot(request, Api.getRequestOptions()));

        return;
    }

    public static async eveningDistrictsExists(): Promise<boolean> {
        const response = await Api.doRequest<CompanyApiEveningDistrictsExists>(() =>
            Api.companyApi.eveningDistrictsExists(Api.getRequestOptions()),
        );

        return response.result;
    }

    public static async getDriverCoefCorrections(driverId: number): Promise<CompanyApiDriverCoefCorrection[]> {
        const response = await Api.doRequest<CompanyApiDriverCoefCorrection[]>(() =>
            Api.companyApi.getCoefCorrections(driverId, Api.getRequestOptions()),
        );

        return response;
    }

    public static async getDriverCoefCorrectionGraphs(
        driverId: number,
        id: number,
    ): Promise<CompanyApiDriverCoefCorrectionGraphs> {
        const response = await Api.doRequest<CompanyApiDriverCoefCorrectionGraphs>(() =>
            Api.companyApi.getCoefCorrectionGraphs(driverId, id, Api.getRequestOptions()),
        );

        return response;
    }

    public static async getCompanyIntervals(): Promise<CompanyApiIntervals> {
        const response = await Api.doRequest<CompanyApiIntervals>(() =>
            Api.companyApi.getCompanyIntervals(Api.getRequestOptions()),
        );

        return response;
    }

    public static async saveCompanyIntervals(request: CompanyApiIntervals): Promise<undefined> {
        await Api.doRequest<CompanyApiIntervals>(() =>
            Api.companyApi.saveCompanyIntervals(request, Api.getRequestOptions()),
        );

        return;
    }
}
