import { GeocodingApiSuspectPlace } from "../../../../ApiClient/YosoGeocoding/models";
import { Place } from "../../../../Model/Place/Place";
import { dateTimeDeserializer } from "../../Mapper/DateTimeDeserializer";
import { OrderSuspectPlace } from "../Model/OrderSuspectPlace";
import { GeocodeQuality } from '../../../../Model/Place/GeocodeQuality';
import { GeocodeSource } from "../../../../ApiClient/Yoso/models";
import { formatTime } from "../../../../Std/formatTime";
import { GeocodeSourceMapper } from "./GeocodeSourceMapper";

export class OrderSuspectPlaceMapper {
    public static mapArrayFromApi(source: GeocodingApiSuspectPlace[]): OrderSuspectPlace[] {
        return source.map(OrderSuspectPlaceMapper.mapFromApi);
    }

    public static mapFromApi(source: GeocodingApiSuspectPlace): OrderSuspectPlace {
        return {
            id: source.id,
            planId: source.planId,
            tripId: source.tripId,
            orderId: source.orderId,
            placeId: source.placeId,
            place: new Place(source.addressName,
                source.detailedAddress,
                source.placeGeocode.latitude,
                source.placeGeocode.longitude,
                GeocodeQuality.None,
                GeocodeSourceMapper.mapFromApi(source.geocodeSource as GeocodeSource),
                source.addressId,
                true,
                source.orderArrivalDateTime
                    ? formatTime(dateTimeDeserializer.deserialize(source.orderArrivalDateTime))
                    : undefined,
                source.prettyAddressName
            ),
            status: source.status,
            driverName: source.driverName,
            clientName: source.clientName,
            createdAt: dateTimeDeserializer.deserialize(source.createdAt),
        }
    }
}