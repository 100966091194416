import { Button, Popover } from 'antd';
import Icon from '@ant-design/icons';
import * as React from 'react';

import { ReactComponent as UserIconComponent } from '../../icons/user_icon.svg';
import { ReactComponent as LogoutIconComponent } from '../../icons/logout.svg';
import { User } from '../../Model/User/User';
import { useAuth } from '../AppProviders/Providers/AuthProvider/AuthProvider';
import { useCompany } from '../AppProviders/Providers/CompanyProvider';

import './AuthenticatedUserInfo.css';

export interface IAuthenticatedUserInfoProps {
    collapsed: boolean | undefined;
    user: User;
}

export const AuthenticatedUserInfo = (props: IAuthenticatedUserInfoProps): JSX.Element => {
    const authContext = useAuth();
    const companyContext = useCompany();
    const { logout } = authContext;
    const { company } = companyContext;

    let siderHeaderStyle = 'authenticated-user-info__sider-header';
    let showCompanyInfo = false;
    if (props.collapsed !== undefined) {
        siderHeaderStyle = props.collapsed
            ? 'authenticated-user-info__sider-header_collapsed'
            : 'authenticated-user-info__sider-header';
        showCompanyInfo = props.collapsed;
    }

    const handleLogOutButtonClick = React.useCallback(async (): Promise<undefined> => {
        await logout();

        return;
    }, [logout]);

    const popoverContent = (
        <div className="authenticated-user-info__popover">
            <div className="authenticated-user-info__collapsed-username">{props.user.username}</div>
            <Button type="link" className="authenticated-user-info__logout-link" onClick={handleLogOutButtonClick}>
                Выйти
            </Button>
        </div>
    );

    return (
        <div className={siderHeaderStyle}>
            {!showCompanyInfo ? (
                <div className="authenticated-user-info">
                    <div className="authenticated-user-info__username-container">
                        <div className="authenticated-user-info__username" title={`${props.user.username}`}>
                            {props.user.username}
                        </div>
                        <div className="authenticated-user-info__company">
                            {company?.name}
                            <br />
                            {company?.cityName}
                        </div>
                    </div>
                    <div className="authenticated-user-info__logout-container">
                        <Button
                            className="authenticated-user-info__logout-button"
                            type="link"
                            size="small"
                            onClick={handleLogOutButtonClick}
                        >
                            <span />
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <Popover content={popoverContent} placement="right">
                        <UserIconComponent className="authenticated-user-info__user-icon" />
                    </Popover>
                </div>
            )}
        </div>
    );
};
