import { Checkbox, Modal } from 'antd';
import * as React from 'react';
import { WorkingDay } from '../../Model/WorkingDay/WorkingDay';

import './WorkingDayEditor.css';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';

export interface IWorkingDayEditorProps {
    workingDay: WorkingDay;
    onCancel: () => void;
    onOk: () => void;
}

export const WorkingDayEditor = (props: IWorkingDayEditorProps): JSX.Element => {
    const [saving, setSaving] = React.useState(false);
    const [isShort, setIsShort] = React.useState(props.workingDay.isShort);
    const [isRestDay, setIsRestDay] = React.useState(props.workingDay.isRestDay);

    const saveWorkingDay = React.useCallback(() => {
        setSaving(true);
        DeliveryApi.saveWorkingDay(props.workingDay.id, isShort, isRestDay)
            .then(() => props.onOk())
            .catch(() => window.alert('Произошла ошибка'))
            .finally(() => setSaving(false));
    }, [isShort, isRestDay, props.workingDay]);

    return (
        <Modal title="Редактирование дня" visible={true} onCancel={props.onCancel} onOk={saveWorkingDay}>
            <Checkbox
                checked={isShort}
                onChange={() => {
                    if (!isShort && isRestDay) {
                        setIsRestDay(false);
                    }
                    setIsShort(!isShort);
                }}
                disabled={saving}
            >
                Короткий день
            </Checkbox>
            <Checkbox
                checked={isRestDay}
                onChange={() => {
                    if (!isRestDay && isShort) {
                        setIsShort(false);
                    }
                    setIsRestDay(!isRestDay);
                }}
                disabled={saving}
            >
                Выходной день
            </Checkbox>
        </Modal>
    );
};
