import {
    DeliveryApiPredictedOrder
} from '../../../../../ApiClient/Yoso/models';
import {
    PredictedOrder
} from '../../../../../Model/PredictedOrder/PredictedOrder';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class PredictedOrderDeserializer {
    public deserialize(serializedObject: DeliveryApiPredictedOrder): PredictedOrder {
        return ({
            id: serializedObject.id,
            expectedTime: dateTimeDeserializer.deserialize(serializedObject.expectedTime),
            predictedTime: dateTimeDeserializer.deserialize(serializedObject.predictedTime),
            predictedTimeBegin: dateTimeDeserializer.deserialize(serializedObject.predictedIntervalBegin),
            predictedTimeEnd: dateTimeDeserializer.deserialize(serializedObject.predictedIntervalEnd),
            actualArrivalTime: serializedObject.actualArrivalTime
                ? dateTimeDeserializer.deserialize(serializedObject.actualArrivalTime)
                : undefined,
        })
    }
}

export const predictedOrderDeserializer = new PredictedOrderDeserializer();