import { CompanyApiCompany, CompanyApiGeocode, CompanyApiServiceZone } from "../../../../ApiClient/YosoCompany/models";
import { Company } from "../../../../Model/Company/Company";
import { IGeocode } from "../../../../Model/Geocode/IGeocode";

export class CompanyDeserializer {
    public deserialize(
        company: CompanyApiCompany
    ): Company {

        return new Company(
            company.id,
            company.name,
            company.cityName,
            company.proficiencyCoefficient,
            company.buildRoutesOnApprove,
            company.alternativePlanAllowedToUse,
            company.centerLatitude,
            company.centerLongitude,
            company.timeZoneOffset);
    }

    public deserializeServiceZone(serviceZone: CompanyApiServiceZone): IGeocode[] {
        return serviceZone.geocodes.map((geocode: CompanyApiGeocode): IGeocode => ({
          latitude: geocode.latitude,
          longitude: geocode.longitude  
        }));
    }
}

export const companyDeserializer = new CompanyDeserializer();
