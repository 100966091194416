import { Alert, Button, Form, Input } from 'antd';
import * as React from 'react';

import { UnauthorizedApiError } from '../../Backend/Api/UnauthorizedApiError';
import { useAuth } from '../AppProviders/Providers/AuthProvider/AuthProvider';

import './LoginForm.css';

export const LoginForm = (): JSX.Element => {
    const authContext = useAuth();
    const { login } = authContext;

    const [errorMessage, setErrorMessage] = React.useState(undefined as string | undefined);
    const [submitting, setSubmitting] = React.useState(false as boolean);

    const handleSubmit = React.useCallback(
        async (values) => {
            setErrorMessage(undefined);

            setSubmitting(true);

            try {
                await login(values.userName, values.password);
            } catch (e2) {
                if (e2 instanceof UnauthorizedApiError) {
                    setErrorMessage('Неверное имя пользователя или пароль.');
                } else {
                    setErrorMessage(e2.message);
                }
            } finally {
                setSubmitting(false);
            }
        },
        [login],
    );

    return (
        <div className="login-form">
            <Form onFinish={handleSubmit}>
                <div className="login-form__inputs-block">
                    <Form.Item
                        name="userName"
                        rules={[
                            {
                                required: true,
                                message: 'Введите логин',
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Логин" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Введите пароль',
                            },
                        ]}
                    >
                        <Input size="large" type="password" placeholder="Пароль" />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="login-form__login-button"
                        loading={submitting}
                    >
                        Войти
                    </Button>
                </Form.Item>
            </Form>
            {errorMessage !== undefined && (
                <Alert className="login-form__alert" message={errorMessage} type="error" showIcon={true} />
            )}
        </div>
    );
};
