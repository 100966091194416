import React from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as CheckpointIcon } from '../../../../icons/checkpoint.svg';
import { ReactComponent as BottleIcon } from '../../../../icons/bottle-product.svg';
import { ReactComponent as BasketIcon } from '../../../../icons/basket.svg';
import { DistrictsDriver, DistrictStore } from '../../../../Store/District/DistrictStore';
import styles from './DistrictList.module.css';

interface DriverListItemProps {
    districtStore: DistrictStore;
    driver: DistrictsDriver;
}

const DriverListItem = observer((props: DriverListItemProps) => {
    const { districtStore, driver } = props;
    const maxBottleCountToDriver = 110;

    const onClick = (item: DistrictsDriver) => {
        if (item.districts.length > 0 && districtStore.selectedDistrictIds.some((i) => i === item.districts[0].id)) {
            districtStore.setSelectedDistrictId([]);
        } else {
            districtStore.setSelectedDistrictId(driver.districts.map((i) => i.id));
        }
    };

    const getBottlesCount = React.useCallback(() => driver.districts.reduce((count, district) =>
        count + district.orders.reduce((orderCount, order) =>
            orderCount + order.shipmentBottlesCount, 0), 0), [districtStore.editDistrictCoords]);

    const getNotBottleCount = React.useCallback(() => driver.districts
        .reduce((count, district) => count + district.orders.reduce(
            (orderCount, order) => orderCount + order.shipmentNonBottlesCount, 0), 0)
        .toFixed(1), [districtStore.editDistrictCoords])

    const bottlesCount = getBottlesCount();
    const notBottleCount = getNotBottleCount();

    return (
        <div
            key={driver.name}
            className={`${styles.driverItem} ${
                driver.districts.length > 0 &&
                districtStore.selectedDistrictIds.some((i) => driver.districts.some((x) => x.id === i))
                    ? styles.selected
                    : ''
            }
                ${bottlesCount > maxBottleCountToDriver ? styles.warning : ''}
            `}
            onClick={() => onClick(driver)}
        >
            <h4 className={styles.name}>{driver.name}</h4>
            <div className={styles.shipment}>
                <div className={styles.shipmentItem}>
                    <CheckpointIcon viewBox={'6 4 13 17'} className={styles.icon} />
                    <div className={styles.count}>
                        {driver.districts.reduce((count, district) => count + district.orders.length, 0)}
                    </div>
                </div>
                <div className={styles.shipmentItem}>
                    <BottleIcon className={styles.icon} />
                    <div className={styles.count}>{bottlesCount}</div>
                </div>
                <div className={styles.shipmentItem}>
                    <BasketIcon className={styles.icon} />
                    <div className={styles.count}>
                        {notBottleCount}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DriverListItem;
