import * as React from 'react';
import { Alert } from 'antd';
import { DraftOrder } from '../../Model/DraftOrder/DraftOrder';
import { Place } from '../../Model/Place/Place';
import { TripExecutor } from '../../Model/TripExecutor/TripExecutor';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { CarList } from '../CarList/CarList';
import { OrderPlacePicker } from '../OrderPlacePicker/OrderPlacePicker';
import { TripPlanDataOrders } from '../TripPlanData/TripPlanDataOrders/TripPlanDataOrders';
import { useTripPlanData } from '../TripPlanData/TripPlanDataProvider';
import './ValidationList.css';

interface IValidationListProps {
    errorOrders: ReadonlyArray<DraftOrder> | undefined;
    executors: ReadonlyArray<TripExecutor> | undefined;
    executorsLoadingInfo: TripExecutorsLoadingInfo | undefined;
    handleSelectAddressPlace: (place: Place) => Promise<void>;
    needSelectFilter: boolean;
    orders: ReadonlyArray<DraftOrder> | undefined;
}

export const ValidationList = (props: IValidationListProps): JSX.Element => {
    const context = useTripPlanData();
    const { cancelAddressGeocodeSelection,
        unlinkAddressFromDriverPlace,
        driverPlace} = context;

    const handleCloseAddressEdit = React.useCallback(() => {
        cancelAddressGeocodeSelection();
    }, [cancelAddressGeocodeSelection]);

    const [selectedExecutor, setSelectedExecutor] = React.useState(undefined as TripExecutor | undefined);

    return (
        <div>
            {props.orders !== undefined &&
                (props.orders.length > 0 ? (
                    <div className="validation-list__orders-block">
                        <div className="validation-list__orders">
                            <TripPlanDataOrders
                                orders={props.orders ?? []}
                                errorOrders={props.errorOrders ?? []}
                                selectedExecutor={selectedExecutor}
                                setSelectedExecutor={setSelectedExecutor}
                                needSelectFilter={props.needSelectFilter}
                            />
                        </div>
                        {context.selectedOrder === undefined ? (
                            props.executors !== undefined && props.executorsLoadingInfo !== undefined && (
                                <CarList
                                    className="validation-list__car-list"
                                    executors={props.executors}
                                    executorsLoadingInfo={props.executorsLoadingInfo}
                                    onClick={setSelectedExecutor}
                                    selectedExecutor={selectedExecutor}
                                />
                            )
                        ) : (
                            <OrderPlacePicker
                                className="validation-list__address-edit"
                                onCancel={handleCloseAddressEdit}
                                onPick={props.handleSelectAddressPlace}
                                orderExecutor={
                                    props.executors?.find(
                                        (executor) => executor.getId() === context.selectedOrder!.executor!.getId(),
                                    )!
                                }
                                orderExecutorLoadingInfo={props.executorsLoadingInfo!}
                                orderProductOperations={context.selectedOrder.operations.productOperations}
                                places={context.selectedOrder.places}
                                driverPlace={driverPlace}
                                unlinkAddressFromDriverPlace={unlinkAddressFromDriverPlace}
                                isConfirmationMarker={true}
                            />
                        )}
                    </div>
                ) : (
                    <Alert
                        className="validation-list__no-new-orders-alert"
                        message="Новых заказов не обнаружено."
                        type="warning"
                    />
                ))}
        </div>
    );
};
