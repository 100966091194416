import { Address } from '../Address/Address';
import { Geocode } from "../Geocode/Geocode";

export class DriverPlace {
    public id: number
    public coords: Geocode;
    public driverId?: number;
    public isSuspected?: boolean;
    public comment?: string;
    public addresses: Address[] = [];

    public constructor(id: number, coords: Geocode) {
        this.id = id;
        this.coords = coords;
    }

    public setIsSuspected(value?: boolean): DriverPlace {
        this.isSuspected = value;

        return this;
    }

    public addAddress(address: Address): DriverPlace {
        this.addresses.push(address);

        return this;
    }

    public setComment(value?: string): DriverPlace {
        this.comment = value;

        return this;
    }
}