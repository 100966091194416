import { Tag } from 'antd';
import * as React from 'react';

import { TripStatus as TripStatusModel } from '../../Model/Trip/TripStatus';
import { Types } from '../../Std/Types';

export interface ITripStatusProps {
    className?: string;
    status: TripStatusModel;
}

export const TripStatusComponent = (props: ITripStatusProps): JSX.Element =>
    props.status === TripStatusModel.Done ? (
        <Tag className={props.className} color="green">
            Завершён
        </Tag>
    ) : props.status === TripStatusModel.InProgress ? (
        <Tag className={props.className} color="blue">
            В процессе
        </Tag>
    ) : props.status === TripStatusModel.New ||
      props.status === TripStatusModel.LogistApprovement ||
      props.status === TripStatusModel.DriverApprovement ? (
        <Tag className={props.className} color="red">
            Не выехал
        </Tag>
    ) : props.status === TripStatusModel.RouteBuildFailed ? (
        <Tag className={props.className} color="grey">
            Произошла ошибка
        </Tag>
    ) : (
        <Tag>Неизвестный статус{Types.assertUnreachable(props.status)}</Tag>
    );
