export const Dictionary = <K, V>() => {
    let valuesByKey: Map<K, V> = new Map();
    let keysByValue: Map<V, K> = new Map();

    return {
        put: (key: K, value: V) => {
            valuesByKey.set(key, value)
            keysByValue.set(value, key);
        },
        getValue: (key: K) => valuesByKey.get(key),
        getKey: (value: V) => keysByValue.get(value),
        clear: () => {
            valuesByKey = new Map();
            keysByValue = new Map();
        },
        keys: () => Array.from(valuesByKey.keys()),
        values: () => Array.from(valuesByKey.values())
    };
}