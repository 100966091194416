export class ArrayUtils {
    public static count<TElement>(array: ReadonlyArray<TElement>, predicate: (element: TElement) => boolean): number {
        let result = 0;

        for (const element of array) {
            if (predicate(element)) {
                result += 1;
            }
        }

        return result;
    }

    public static defined<TElement>(array: ReadonlyArray<TElement | undefined>): TElement[] {
        return array.filter((element: TElement | undefined): element is TElement => element !== undefined);
    }

    public static equals<TElement>(
        array1: ReadonlyArray<TElement>,
        array2: ReadonlyArray<TElement>,
        equality: (element1: TElement, element2: TElement) => boolean = (element1, element2) => element1 === element2,
    ): boolean {
        return array1.length === array2.length && array1.every((element, index) => equality(element, array2[index]));
    }

    public static flatten<TElement>(arrays: ReadonlyArray<ReadonlyArray<TElement>>): TElement[] {
        return ([] as ReadonlyArray<TElement>).concat(...arrays);
    }

    public static move<TElement>(
        array: ReadonlyArray<TElement>,
        element: TElement,
        newIndex: number,
    ): ReadonlyArray<TElement> {
        const index = array.indexOf(element);
        if (index === -1) {
            throw new Error('Element not in array');
        }

        const result = array.slice();
        result.splice(newIndex, 0, result.splice(index, 1)[0]);

        return result;
    }

    public static unique<TElement>(
        array: ReadonlyArray<TElement>,
        equals?: (a: TElement, b: TElement) => boolean,
    ): TElement[] {
        const result: TElement[] = [];

        for (const element of array) {
            const elementExists =
                equals === undefined
                    ? result.includes(element)
                    : result.find((elementIterator) => equals(elementIterator, element)) !== undefined;

            if (!elementExists) {
                result.push(element);
            }
        }

        return result;
    }

    public static sort<TElement>(
        array: TElement[],
        by: ((ab: TElement) => number | string | boolean)[] | ((ab: TElement) => number | string | boolean),
        makeCopy: boolean = true,
        asc: boolean = true,
    ): TElement[] {
        if (makeCopy) {
            array = array.slice();
        }

        const sortBys: ((ab: TElement) => number | string | boolean)[] = Array.isArray(by) ? by : [by];

        if (sortBys.length === 0) {
            return array;
        }

        array.sort((a: TElement, b: TElement) => {
            for (const sortBy of sortBys) {
                const v1 = sortBy(a);
                const v2 = sortBy(b);

                if (v1 !== v2) {
                    return v1 < v2 ? -1 : 1;
                }
            }

            return 0;
        });

        if (!asc) {
            array.reverse();
        }

        return array;
    }

    public static join<TElement>(array: TElement[], by: (ab: TElement) => number | string, delimiter?: string): string {
        const driversNames = array.map(by);
        const str = driversNames.join(delimiter);

        return str;
    }

    public static groupBy<TKey, TElement>(array: TElement[], by: (ab: TElement) => TKey): Map<TKey, TElement[]> {
        return array.reduce((store, item) => {
            const key = by(item);
            if (!store.has(key)) {
                store.set(key, [item]);
            } else {
                store.get(key)!.push(item);
            }

            return store;
        }, new Map<TKey, TElement[]>());
    }
}
