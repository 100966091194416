import {
    DeliveryApiTripComparison,
} from '../../../../../ApiClient/Yoso/models';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import {
    TripComparison
} from '../../../../../Model/TripComparison/TripComparison';
import {
    TripComparisonOrder
} from '../../../../../Model/TripComparison/TripComparisonOrder';
import { TripComparisonRouteSequenceItem } from '../../../../../Model/TripComparison/TripComparisonRouteSequenceItem';
import {
    TripDriverPlace
} from '../../../../../Model/TripComparison/TripDriverPlace';
import {Waypoint} from '../../../../../Model/Waypoint/Waypoint';
import {dateTimeDeserializer} from '../../../Mapper/DateTimeDeserializer';
import {
    dateTimePeriodDeserializer
} from '../../Model/DateTimePeriod/DateTimePeriodDeserializer';
import { RouteInfo } from '../../Model/RouteInfo/RouteInfo';
import {storeDeserializer} from '../../Model/Store/StoreDeserializer';
import { timeMatrixSourceDeserializer } from '../../Model/TimeMatrixSource/TimeMatrixSourceDeserializer';
import {
    tripPointTypeDeserializer
} from '../../Model/TripPointId/TripPointTypeDeserializer';
import {driverPointDeserializer} from '../DriverPoint/DriverPointDeserializer';

export class GetTripComparisonDeserializer {
    public deserialize(response: DeliveryApiTripComparison): TripComparison {
        const driverPlaces = response.driverPlaces.map(p => new TripDriverPlace(
            new Geocode({latitude: p.latitude, longitude: p.longitude}),
            p.id
        ));

        const driverPlacesById = Object.fromEntries(driverPlaces.map(p => [p.id, p]));
        const startPoints = response.startPoints.map(p => driverPointDeserializer.deserialize(p));
        const finishPoints = response.finishPoints.map(p => driverPointDeserializer.deserialize(p));
        const intemediatePoints = response.intermediatePoints.map(p => driverPointDeserializer.deserialize(p));
        const orders = response.orders.map(o => new TripComparisonOrder(
            o.id,
            o.address,
            o.buildingAddress,
            o.clientName,
            dateTimePeriodDeserializer.deserialize(o.dateTimePeriod),
            new Geocode({latitude: o.latitude, longitude: o.longitude}),
            o.shipmentCount,
            o.driverPlaceId ? driverPlacesById[o.driverPlaceId] : undefined
        ));
        const startPoint = response.startPoint ? driverPointDeserializer.deserialize(response.startPoint) : undefined;
        const finishPoint = response.finishPoint ? driverPointDeserializer.deserialize(response.finishPoint) : undefined;
        const firstPoint = response.firstOrder
            ? orders.find(o => o.id === response.firstOrder)
            : response.intermediatePoint
                ? driverPointDeserializer.deserialize(response.intermediatePoint)
                : undefined;

        const tripComparison = new TripComparison(
            response.tripId,
            response.name,
            dateTimeDeserializer.deserialize(response.date),
            response.createdAt ? dateTimeDeserializer.deserialize(response.createdAt) : undefined,
            orders,
            driverPlaces,
            response.stores.map(s => storeDeserializer.deserialize(s)),
            startPoints,
            finishPoints,
            intemediatePoints,
            startPoint ? startPoints.find(x => x.id === startPoint.id && x.entityType === startPoint.entityType) : undefined,
            finishPoint ? finishPoints.find(x => x.id === finishPoint.id && x.entityType === finishPoint.entityType) : undefined,
            firstPoint,
            response.startTime ? dateTimeDeserializer.deserialize(response.startTime) : undefined,
            response.routes.map(r => new RouteInfo(
                r.id,
                r.name,
                r.isRouteSet,
                r.isManual,
                r.withLoadingStore,
                r.withDriverPlace,
                timeMatrixSourceDeserializer.deserialize(r.timeMatrixSource),
                r.canBeBuilt,
                r.isStale,
                response.carCapacity,
                response.shipmentCount,
                r.yandexWaypoints
                    ? r.yandexWaypoints.map(w =>
                    new Waypoint(w.sourceIndex, w.destinationIndex, w.duration, w.distance,
                        dateTimeDeserializer.deserialize(w.arrivalTime),
                        dateTimeDeserializer.deserialize(w.departureTime),
                        w.geocodes.map(g => new Geocode({latitude: g.latitude!, longitude: g.longitude!}))))
                    : undefined,
                r.yandexDistance,
                r.yandexDrivingTime,
                r.yandexDistanceAdjustment,
                r.yandexDrivingTimeAdjustment,
                r.tGisWaypoints
                    ? r.tGisWaypoints.map(w =>
                    new Waypoint(w.sourceIndex, w.destinationIndex, w.duration, w.distance,
                        dateTimeDeserializer.deserialize(w.arrivalTime),
                        dateTimeDeserializer.deserialize(w.departureTime),
                        w.geocodes.map(g => new Geocode({latitude: g.latitude!, longitude: g.longitude!}))))
                    : undefined,
                r.tGisDistance,
                r.tGisDrivingTime,
                r.tGisDistanceAdjustment,
                r.tGisDrivingTimeAdjustment,
                r.sequence
                    ? r.sequence.map(i => new TripComparisonRouteSequenceItem(
                    i.id,
                    tripPointTypeDeserializer.deserialize(i.type),
                    i.shipmentChange,
                    i.currentShipment,
                    i.period ? dateTimePeriodDeserializer.deserialize(i.period) : undefined))
                    : undefined,
                r.comment,
                r.routeSimilarity
            ))
         );

        return tripComparison;
    }
}

export const getTripComparisonDeserializer = new GetTripComparisonDeserializer();