import DaysOfWeekSelector
  from '@/Components/DaysOfWeekSelector/DaysOfWeekSelector';
import { Button, Input, TimePicker } from 'antd';
import L from 'leaflet';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { Moment } from 'moment';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { DistrictStrictInterval } from '../../Store/DistrictStrictInterval/DistrictStrictInterval';
import PopupOnMap from '../MapContainer/components/PopupOnMap';
import { DistrictStrictIntervalStore } from '../../Store/DistrictStrictInterval/DistrictStrictIntervalStore';
import { DistrictInterval } from '../../Store/DistrictStrictInterval/DistrictInterval';
import { IGeocode } from '../../Model/Geocode/IGeocode';

interface IDistrictStrictIntervalPopupProps {
  position: IGeocode;
  store: DistrictStrictIntervalStore;
  onSave: (district: DistrictStrictInterval) => Promise<void>;
}

const DistrictStrictIntervalPopup = observer((props: IDistrictStrictIntervalPopupProps) => {
  const [originalDistrictName, setOriginalDistrictName] = React.useState<string>('');
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState<boolean>(false);
  const [intervalBegin, setIntervalBegin] = React.useState<Moment | null>(null);
  const [intervalEnd, setIntervalEnd] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    setSaveButtonDisabled(!(props.store.selectedEntity && props.store.selectedEntity.getName()));
  }, [props.store.selectedEntity, props.store.selectedEntity?.getName()])

  React.useEffect(() => {
    setOriginalDistrictName(props.store.selectedEntity?.getName() || '');    
  }, [props.store.selectedEntity])  

  const onCancel = React.useCallback(() => {
    if (!props.store.selectedEntity) {
      return;
    }

    props.store.selectedEntity.setName(originalDistrictName);

    setTimeout(() => { // хак, чтобы не падала страница при закрытии попапа
      props.store.setSelectedEntityId(undefined);
    }, 0);
  }, [props.store.selectedEntity, originalDistrictName])

  const onSave = React.useCallback(() => {
    if (!props.store.selectedEntity) {
      return;
    }
    props.onSave(props.store.selectedEntity);
    setOriginalDistrictName(props.store.selectedEntity.name);
    setTimeout(() => { // хак, чтобы не падала страница при закрытии попапа
      props.store.setSelectedEntityId(undefined);
    }, 0);
  }, [props.store.selectedEntity])

  const renameDistrict = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!props.store.selectedEntity) {
      return;
    }
    props.store.selectedEntity.setName(e.target.value);
  }, [props.store.selectedEntity])


  const onBeginChange = React.useCallback((time: any, timeString: string) => {
    setIntervalBegin(time);
  }, []);

  const onEndChange = React.useCallback((time: any, timeString: string) => {
    setIntervalEnd(time);
  }, []);

  const addInterval = React.useCallback(() => {
    if (!props.store.selectedEntity || !intervalBegin || !intervalEnd || intervalBegin >= intervalEnd) {
      return;
    }

    props.store.addInterval(props.store.selectedEntity, new DistrictInterval(
      intervalBegin.hour(),
      intervalBegin.minute(),
      intervalEnd.hour(),
      intervalEnd.minute()));

    setIntervalBegin(null);
    setIntervalEnd(null);
  }, [intervalBegin, intervalEnd, props.store.selectedEntity]);

  const removeInterval = React.useCallback((e: any, interval: DistrictInterval) => {
    e.stopPropagation()
    if (!props.store.selectedEntity) {
      return;
    }

    props.store.removeInterval(props.store.selectedEntity, interval);
  }, [props.store.selectedEntity]);

  const findPosition = (): L.LatLngExpression | undefined => {
    if (!props.store.selectedEntity) {
      return undefined;
    }

    if (props.position) {
      return { lat: props.position.latitude, lng: props.position.longitude };
    }

    const center = props.store.selectedEntity.getCentroid();

    return center ? { lat: center.latitude, lng: center.longitude } : undefined;
  }

  return (
    <PopupOnMap position={findPosition()}>
      <Input
        className='district-strict-interval-popup__input'
        value={props.store.selectedEntity?.getName()}
        onChange={renameDistrict}
      />
      <div className='district-strict-interval-popup__time-input'>
        <TimePicker format="HH:mm" value={intervalBegin} onChange={onBeginChange} />
        &nbsp;-&nbsp;
        <TimePicker format="HH:mm" value={intervalEnd} onChange={onEndChange} />&nbsp;
        <PlusCircleOutlined className='district-strict-interval-popup__plus-button' onClick={addInterval} />
      </div>
      <h3>Интервалы:</h3>
      <div className='district-strict-interval-popup__times'>
        {props.store.selectedEntity && props.store.selectedEntity.intervals.length > 0 ? ArrayUtils.sort(props.store.selectedEntity.intervals, d => d.beginHour, true).map((interval) => (
          <div key={interval.key} className='district-strict-interval-popup__time'>
            <div>
              <span>{interval.formatTime()}</span>
              <DaysOfWeekSelector
                values={interval.daysOfWeek}
                onChange={interval.updateDaysOfWeek}
              />
            </div>
            <CloseOutlined
                className='district-strict-interval-popup__delete-interval-button'
                onClick={(e) => removeInterval(e, interval)} />
          </div>
        )) : '... не заданы ...'}
      </div>
      <div className='district-strict-interval-popup__buttons'>
        <Button type="default" size="small" onClick={onCancel}>
          Отмена
        </Button>
        <Button type="primary" size="small" disabled={saveButtonDisabled} onClick={onSave}>
          Сохранить
        </Button>
      </div>
    </PopupOnMap>
  )
})

export default DistrictStrictIntervalPopup