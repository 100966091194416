import * as React from 'react';

import { TripPlanDataProvider } from '../TripPlanData/TripPlanDataProvider';

import { AuthProvider } from './Providers/AuthProvider/AuthProvider';
import { ClientPreferencesProvider } from './Providers/ClientPreferencesProvider/ClientPreferencesProvider';
import { CompanyProvider } from './Providers/CompanyProvider';
import { UserProvider } from './Providers/UserProvider';

export interface IAppProvidersProps {
    children: React.ReactNode;
}

export const AppProviders = (props: IAppProvidersProps) => (
    <ClientPreferencesProvider>
        <AuthProvider>
            <UserProvider>
                <CompanyProvider>
                    <TripPlanDataProvider>{props.children}</TripPlanDataProvider>
                </CompanyProvider>
            </UserProvider>
        </AuthProvider>
    </ClientPreferencesProvider>
);
