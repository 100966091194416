import { ApiItineraryBalanceRow, DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { ItineraryBalanceRow } from '../../../../../Model/Itinerary/ItineraryBalanceRow';

export class BalanceRowDeserializer {
    public deserialize(response: ApiItineraryBalanceRow, products: DeliveryApiProduct[]): ItineraryBalanceRow {
        return new ItineraryBalanceRow(
            response.productId,
            products.find(p => p.id === response.productId)?.name!,
            response.quantity
        )
    }
}

export const balanceRowDeserializer = new BalanceRowDeserializer();
