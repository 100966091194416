import React from 'react';
import {
    OrderSearchDetails
} from '@/Model/OrderSearchDetails/OrderSearchDetails';
import styles from './OrderSearchDetailsOption.module.css'

interface OrderSearchDetailsOptionProps {
    order: OrderSearchDetails
}

const OrderSearchDetailsOption = (props: OrderSearchDetailsOptionProps) => {
    const {order} = props;

    return (
        <div key={order.orderId} className={styles.details}>
            <div className={styles.driver}>{order.driverName}</div>
            <div>
                {order.clientName} - {order.phone}
                <div>{order.address}</div>
            </div>
        </div>
    );
};

export default OrderSearchDetailsOption;
