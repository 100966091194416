import {DeliveryApiDriverPoint} from '../../../../../ApiClient/Yoso/models';
import {DriverPoint} from '../../../../../Model/DriverPoint/DriverPoint';
import {
    driverPointTypeSerializer
} from '../../Model/DriverPointType/DriverPointTypeSerializer';

export class DriverPointSerializer {
    public serialize(point: DriverPoint): DeliveryApiDriverPoint {
        return {
            id: point.id,
            entityType: driverPointTypeSerializer.serialize(point.entityType),
            name: point.address,
            latitude: point.coords.latitude,
            longitude: point.coords.longitude
        }
    }
}

export const driverPointSerializer = new DriverPointSerializer();