import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IntervalPatternStore } from '@/Store/IntervalPatternStore/IntervalPatternStore';
import { CompanyApi } from '@/Backend/Api/Company/CompanyApi';
import { CompanyApiIntervals } from '@/ApiClient/YosoCompany/models';
import { DeliveryApiIntervals } from '@/ApiClient/Yoso/models';
import { DeliveryApi } from '@/Backend/Api/Delivery/DeliveryApi';
import { DeliveryIntervalStore } from '@/Store/DeliveryInterval/DeliveryIntervalStore';
import RegularIntervalEditor from '../RegularIntervalEditor/RegularIntervalEditor';
import SpecialIntervalEditor from '../SpecialIntervalEditor/SpecialIntervalEditor';

import styles from './IntervalEditor.module.css';

interface IntervalEditorProps {}

const IntervalEditor = observer((props: IntervalEditorProps) => {
    const [intervalPatternStore, setIntervalPatternStore] = useState(undefined as IntervalPatternStore | undefined);
    const [deliveryIntervalStore, setDeliveryIntervalStore] = useState(undefined as DeliveryIntervalStore | undefined);

    useEffect(() => {
        CompanyApi.getCompanyIntervals().then((x) => setIntervalPatternStore(new IntervalPatternStore(x.intervals)));
        DeliveryApi.getIntervals().then((x) =>
            setDeliveryIntervalStore(new DeliveryIntervalStore(x.intervals, x.adjustments)),
        );
    }, []);

    const saveIntervals = (): Promise<void> => {
        if (!intervalPatternStore) {
            return Promise.resolve();
        }

        return CompanyApi.saveCompanyIntervals({ intervals: intervalPatternStore.rows })
            .then(() => Promise.all([CompanyApi.getCompanyIntervals(), DeliveryApi.getIntervals()]))
            .then(([x, y]) => {
                setIntervalPatternStore(new IntervalPatternStore(x.intervals));
                setDeliveryIntervalStore(new DeliveryIntervalStore(y.intervals, y.adjustments));
            });
    };

    const saveAdjustments = (): Promise<void> => {
        if (!deliveryIntervalStore || !deliveryIntervalStore.selectedDay) {
            return Promise.resolve();
        }

        return DeliveryApi.adjustIntervals({
            date: deliveryIntervalStore.selectedDay.date.toISOString(),
            intervals: deliveryIntervalStore.selectedDayIntervals,
        })
            .then(() => DeliveryApi.getIntervals())
            .then((x: DeliveryApiIntervals) =>
                setDeliveryIntervalStore(new DeliveryIntervalStore(x.intervals, x.adjustments)),
            )
            .catch((x) => window.alert(x));
    };

    return (
        <div className={styles.container}>
            <RegularIntervalEditor store={intervalPatternStore} saveIntervals={saveIntervals} />
            <SpecialIntervalEditor store={deliveryIntervalStore} saveAdjustments={saveAdjustments} />
        </div>
    );
});

export default IntervalEditor;
