import {Geocode} from '../Geocode/Geocode';
import {DriverPointType} from './DriverPointType';

export class DriverPoint {
    public id?: string;
    public entityType: DriverPointType;
    public address: string;
    public coords: Geocode;

    public constructor(
        id: string | undefined,
        entityType: DriverPointType,
        address: string,
        coords: Geocode
    ) {
        this.id = id;
        this.entityType = entityType;
        this.address = address;
        this.coords = coords;
    }


    public getTypeAndId(): string {
        return `${this.entityType}-${this.id}`
    }
}