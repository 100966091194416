import React, { useMemo } from 'react';
import { Employee } from '@/Model/Employee/Employee';
import { Button, Form, Input, InputNumber, Select, Switch } from 'antd';
import moment from 'moment';
import { Role } from '../../ApiClient/YosoAuth/models';
import { AppConstants } from '../../AppConstants';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';
import { dateTimeSerializer } from '../../Backend/Api/Mapper/DateTimeSerializer';
import { useLoadingWarehouses } from '../../hooks/useLoadingWarehouses';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { DateTime } from '../../Std/DateTime';
import { useUser } from '../AppProviders/Providers/UserProvider';
import { AverageOrderCountPerHourEdit, PeriodWithValue } from '../SettingsEdit/AverageOrderCountPerHourEdit';
import './DriverEntityEdit.css';

export interface IExistingDriver {
    employee: Employee;
    id: number;
}

export interface IDriverEntityEditProps {
    driver: IExistingDriver | undefined;
    onSave: () => void;
}

export const DriverEntityEdit = (props: IDriverEntityEditProps): JSX.Element => {
    const destroyed = React.useRef(false);

    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const loadingWarehouses = useLoadingWarehouses();
    const loadingWarehousesSelectors = useMemo(() => loadingWarehouses.warehouses
        .sort((a, b) => a.address.localeCompare(b.address))
        .map(i => ({ value: i.id, label: i.address, isMain: i.isMain })), [loadingWarehouses.warehouses])

    const isOnlyProficiencyChangeAllowed = useUser().user!.role.equals(Role.ROLELOGISTICIAN);

    const [name, setName] = React.useState(props.driver === undefined ? '' : props.driver.employee.driver.name);
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState(
        props.driver === undefined ? '' : (props.driver.employee.driver.login || ''),
    );
    const [proficiency, setProficiency] = React.useState(
        (props.driver === undefined ? 1 : props.driver.employee.settings.driverProficiency) as number | undefined,
    );

    const [activity, setActivity] = React.useState(
        (props.driver === undefined ? false : props.driver.employee.driver.isActive) as boolean,
    );
    const [unloadingTimeFactor, setUnloadingTimeFactor] = React.useState(
        (props.driver === undefined ? 1 : props.driver.employee.settings.unloadingTimeFactor) as number | undefined,
    );

    const [selectedWarehouse, setSelectedWarehouse] = React.useState<number | undefined>(props.driver?.employee.settings.loadingStoreId);

    React.useEffect(() => {
        if (props.driver && props.driver.employee.settings.loadingStoreId) {
            setSelectedWarehouse(props.driver.employee.settings.loadingStoreId);

            return undefined;
        }

        const mainWarehouse = loadingWarehousesSelectors.find(i => i.isMain);
        if (mainWarehouse) {
            setSelectedWarehouse(mainWarehouse.value);
        }
    }, [loadingWarehousesSelectors])

    const [averageOrdersCount, setAverageOrdersCount] = React.useState<PeriodWithValue[]>(
        props.driver === undefined ? [] : ArrayUtils.sort(props.driver.employee.averageOrdersCountPerHour, x => x.endTime).map(x=>({
            endTime: DateTime.fromDate(moment(x.endTime).toDate()),
            averageCount: x.value
        }))
    );
    
    const [saving, setSaving] = React.useState(false as boolean);

    const isDataValid = React.useCallback(
        (): boolean =>
            (isOnlyProficiencyChangeAllowed ||
                (Boolean(name) && Boolean(username) && !(props.driver === undefined && !password))) &&
            proficiency !== undefined &&
            proficiency >= AppConstants.minDriverExperienceCoefficient &&
            proficiency <= AppConstants.maxDriverExperienceCoefficient,
        [isOnlyProficiencyChangeAllowed, name, username, props.driver, password, proficiency],
    );

    const handleUsernameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(e.currentTarget.value);
    }, []);

    const handlePasswordChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.currentTarget.value);
    }, []);

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setName(e.currentTarget.value);
    }, []);

    const handleActivityChange = React.useCallback((e: boolean): void => {
        setActivity(e);
    }, []);

    const handleExperienceCoefficientChange = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/tslint/config
        (newExperienceCoefficient: string | number | null | undefined): void => {
            if (typeof newExperienceCoefficient === 'string') {
                setProficiency(undefined);
            } else {
                setProficiency(newExperienceCoefficient ?? undefined);
            }
        },
        [],
    );

    const handleUnloadingTimeFactorChange = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/tslint/config
        (value: string | number | null | undefined): void => {
            if (typeof value === 'string') {
                setUnloadingTimeFactor(undefined);
            } else {
                setUnloadingTimeFactor(value ?? undefined);
            }
        },
        [],
    );

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid() || proficiency === undefined || unloadingTimeFactor === undefined || !selectedWarehouse) {
            return;
        }

        setSaving(true);

        try {
            if (props.driver === undefined) {
                await CompanyApi.postDriver({
                    employee: {
                        credentials: {
                            password: password,
                            username: username,
                        },
                        name: name,
                        isActive: activity
                    },
                    extra: {
                        driverProficiency: proficiency,
                        unloadingTimeFactor: unloadingTimeFactor,
                        loadingStoreId: selectedWarehouse
                    },
                    averageOrdersCountPerHour: averageOrdersCount.map(x=> ({
                        value: x.averageCount,
                        endTime: dateTimeSerializer.serialize(x.endTime)
                    }))                   
                });
            } else {
                await CompanyApi.putDriver({
                    employee: {
                        id: props.driver.id,
                        credentials: {
                            password: password,
                        },
                        name: name,
                        isActive: activity
                    },
                    extra: {
                        driverProficiency: proficiency,
                        unloadingTimeFactor: unloadingTimeFactor,
                        loadingStoreId: selectedWarehouse
                    },
                    averageOrdersCountPerHour: averageOrdersCount.map(x=> ({
                        value: x.averageCount,
                        endTime: dateTimeSerializer.serialize(x.endTime)
                    })) 
                });
            }
            if (destroyed.current) {
                return;
            }
            props.onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [isDataValid, proficiency, props, password, username, name, activity, unloadingTimeFactor, averageOrdersCount, selectedWarehouse]);

    const handleSelectWarehouse = (e: number) => {
        setSelectedWarehouse(e)
    }

    return (
        <div>
            <div className="driver-entity-edit__block">
                <div className="driver-entity-edit__form">
                    <Form.Item label="Логин">
                        <Input
                            defaultValue={username}
                            onChange={handleUsernameChange}
                            disabled={props.driver !== undefined}
                        />
                    </Form.Item>
                    {!isOnlyProficiencyChangeAllowed && (
                        <Form.Item label="Пароль">
                            <Input defaultValue={password} onChange={handlePasswordChange} />
                        </Form.Item>
                    )}
                    <Form.Item label="Имя">
                        <Input
                            defaultValue={name}
                            onChange={handleNameChange}
                            disabled={isOnlyProficiencyChangeAllowed}
                        />
                    </Form.Item>
                    <Form.Item label="Коэффициент движения">
                        <InputNumber
                            defaultValue={proficiency}
                            onChange={handleExperienceCoefficientChange}
                            step={AppConstants.coefficientEditStep}
                        />
                    </Form.Item>
                    <Form.Item label="Коэффициент разгрузки">
                        <InputNumber
                            defaultValue={unloadingTimeFactor}
                            onChange={handleUnloadingTimeFactorChange}
                            step={AppConstants.coefficientEditStep}
                        />
                    </Form.Item>
                    <Form.Item label="Активность">
                        <Switch 
                            checked={activity} 
                            onChange={handleActivityChange} />
                    </Form.Item>
                    <Form.Item label='Среднее количество заказов в час'>
                        <AverageOrderCountPerHourEdit
                            loading={saving}
                            values={averageOrdersCount}
                            setValues={setAverageOrdersCount}
                        />
                    </Form.Item>
                    <Form.Item label='Склад для загрузки'>
                        <Select
                            value={selectedWarehouse}
                            onSelect={handleSelectWarehouse}
                            options={loadingWarehousesSelectors}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="driver-entity-edit__save-button">
                <Button type="primary" loading={saving} onClick={handleSaveButtonClick} disabled={!isDataValid()}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
