import { IGeocode } from '../../Model/Geocode/IGeocode';

interface IMapBoundsBorders {
    maxLatitude: number;
    maxLongitude: number;
    minLatitude: number;
    minLongitude: number;
}

export class MapBounds {
    private static readonly minBoundsGap = 0.015;

    private static calculateBorders(geocodes: ReadonlyArray<IGeocode>): IMapBoundsBorders {
        const bounds: Partial<IMapBoundsBorders> = {
            maxLatitude: undefined,
            maxLongitude: undefined,
            minLatitude: undefined,
            minLongitude: undefined,
        };

        for (const waypoint of geocodes) {
            if (bounds.maxLatitude === undefined || waypoint.latitude > bounds.maxLatitude) {
                bounds.maxLatitude = waypoint.latitude;
            }
            if (bounds.maxLongitude === undefined || waypoint.longitude > bounds.maxLongitude) {
                bounds.maxLongitude = waypoint.longitude;
            }
            if (bounds.minLatitude === undefined || waypoint.latitude < bounds.minLatitude) {
                bounds.minLatitude = waypoint.latitude;
            }
            if (bounds.minLongitude === undefined || waypoint.longitude < bounds.minLongitude) {
                bounds.minLongitude = waypoint.longitude;
            }
        }

        if (
            bounds.maxLatitude === undefined ||
            bounds.maxLongitude === undefined ||
            bounds.minLatitude === undefined ||
            bounds.minLongitude === undefined
        ) {
            throw new Error('Some bound borders are undefined');
        }

        const two = 2;
        if (bounds.maxLatitude - bounds.minLatitude < MapBounds.minBoundsGap) {
            bounds.minLatitude = (bounds.maxLatitude + bounds.minLatitude - MapBounds.minBoundsGap) / two;
            bounds.maxLatitude = (bounds.maxLatitude + bounds.minLatitude + MapBounds.minBoundsGap) / two;
        }

        if (bounds.maxLongitude - bounds.minLongitude < MapBounds.minBoundsGap) {
            bounds.minLongitude = (bounds.maxLongitude + bounds.minLongitude - MapBounds.minBoundsGap) / two;
            bounds.maxLongitude = (bounds.maxLongitude + bounds.minLongitude + MapBounds.minBoundsGap) / two;
        }

        return {
            maxLatitude: bounds.maxLatitude,
            maxLongitude: bounds.maxLongitude,
            minLatitude: bounds.minLatitude,
            minLongitude: bounds.minLongitude,
        };
    }

    public readonly borders: IMapBoundsBorders;

    public constructor(geocodes: ReadonlyArray<IGeocode>) {
        this.borders = MapBounds.calculateBorders(geocodes);
    }

    public getCorners(latitudePaddingRatio: number = 0, longitudePaddingRatio: number = 0): ReadonlyArray<IGeocode> {
        const latitudePadding = (this.borders.maxLatitude - this.borders.minLatitude) * latitudePaddingRatio;
        const longitudePadding = (this.borders.maxLongitude - this.borders.minLongitude) * longitudePaddingRatio;

        return [
            {
                latitude: this.borders.maxLatitude + latitudePadding,
                longitude: this.borders.minLongitude - longitudePadding,
            },
            {
                latitude: this.borders.minLatitude - latitudePadding,
                longitude: this.borders.maxLongitude + longitudePadding,
            },
        ];
    }

    public getLatLngBoundsExpression(): L.LatLngBoundsExpression {
        const corners = this.getCorners();

        return [
            [corners[0].latitude, corners[0].longitude],
            [corners[1].latitude, corners[1].longitude]
        ]
    }
}
