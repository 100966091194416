import {
    DeliveryApiDriverCoefficientsOffer
} from '../../../../../ApiClient/Yoso/models';
import {
    DriverCoefficientOffer
} from '../../../../../Model/DriverCoefficientOffer/DriverCoefficientOffer';

export class DriverCoefficientOfferDeserializer {
    public deserialize(apiOffer: DeliveryApiDriverCoefficientsOffer): DriverCoefficientOffer {
        return ({
            driverId: apiOffer.driverId,
            driverName: apiOffer.driverName,
            currentDriverProficiency: apiOffer.currentDriverProficiency,
            currentUnloadingTimeFactor: apiOffer.currentUnloadingTimeFactor,
            offerDriverProficiency: apiOffer.offerDriverProficiency,
            offerUnloadingTimeFactor: apiOffer.offerUnloadingTimeFactor
        })
    }
}

export const driverCoefficientOfferDeserializer = new DriverCoefficientOfferDeserializer()