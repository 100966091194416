import React from 'react';
import { TripExecutor } from '@/Model/TripExecutor/TripExecutor';
import { Trip } from '@/Model';
import { TripRoute } from '../TripRoute/TripRoute';
import { MassOrderTransfer } from '../../features/MassOrderTransfer/MassOrderTransfer';
import { DriverReplacementConfirm } from '../../features/DriverReplacementConfirm/DriverReplacementConfirm';

interface TripEditorProps {
    trip: Trip;
    planDrivers: ReadonlyArray<TripExecutor>;
    allTrips: Trip[];
    selectedOrderId?: number;
    setTripItemIndex: (value: number) => void;
    transferOrders: (toTripId: number, orderIds: number[]) => void;
    changeDriverTrip: (tripId: number, driverId: number) => void;
}

export const TripEditor = (props: TripEditorProps) => {
    const {
        trip,
        planDrivers,
        allTrips,
        selectedOrderId,
        setTripItemIndex,
        transferOrders
    } = props;

    const [isSelectionMode, setIsSelectionMode] = React.useState(false);
    const [isDriverReplacementMode, setIsDriverReplacementMode] = React.useState(false);

    return (
        <>
            {!isSelectionMode
                ? <TripRoute
                    trip={trip}
                    isSelectionMode={isSelectionMode}
                    selectedOrderId={selectedOrderId}
                    setTripItemIndex={setTripItemIndex}
                    setSelectMode={() => setIsSelectionMode(true)}
                    setDriverReplacementMode={() => setIsDriverReplacementMode(true)}
                />
                : <MassOrderTransfer
                    trip={trip}
                    allTrips={allTrips}
                    onCancel={() => setIsSelectionMode(false)}
                    transferOrders={transferOrders}
                />
            }
            <DriverReplacementConfirm
                isModalVisible={isDriverReplacementMode}
                selectedTrip={trip}
                executors={planDrivers}
                onCancel={() => setIsDriverReplacementMode(false)}
                onConfirm={props.changeDriverTrip}
            />
        </>
    )
};
