import { DraftApiProductOperation } from '../../../../ApiClient/YosoDraft/models';
import { Product } from '../../../../Model/Product/Product';
import { ProductOperation } from '../../../../Model/ProductOperation/ProductOperation';
import { Types } from '../../../../Std/Types';
import { productOperationDealTypeDeserializer } from '../../Delivery/Model/ProducOperationDealType/ProductOperationDealTypeDeserializer';
import { productOperationTypeDeserializer } from '../../Delivery/Model/ProductOperationType/ProductOperationTypeDeserializer';

export class ProductOperationMapper {
    public static mapFromApi(apiProductOperation: DraftApiProductOperation, products: Product[]): ProductOperation {
        const foundProduct = Types.trustDefined<Product>(
            products.find((product: Product): boolean => product.code === apiProductOperation.productCode),
        );

        return new ProductOperation(
            productOperationTypeDeserializer.deserialize(apiProductOperation.type),
            foundProduct,
            apiProductOperation.count,
            undefined,
            0,
            undefined,
            productOperationDealTypeDeserializer.deserialize(apiProductOperation.dealType),
        );
    }
}
