import { Tag } from 'antd';
import * as React from 'react';

import { OrderStatus } from '../../Model/Order/OrderStatus';

export interface IOrderStatusProps {
    className?: string;
    deferred: boolean;
    status: OrderStatus | undefined;
    fullMode?: boolean;
}

export const OrderBadgeStatus = (props: IOrderStatusProps): JSX.Element => 
    props.deferred && props.status === undefined ? (
        props.status === OrderStatus.Scheduled || props.status === OrderStatus.ScheduledAdded ? (
            <Tag className={props.className} color="blue">
                Заказ отложен
            </Tag>
        ) : (
            <></>
        )
    ) : props.status === OrderStatus.ScheduledAdded || props.status === OrderStatus.AssignedAdded ? (
        <Tag className={props.className} color="orange">
            Новый заказ
        </Tag>
    ) : props.status === OrderStatus.Moved ? (
        <Tag className={props.className} color="red">
            Заказ передан
        </Tag>
    ) : props.status === OrderStatus.Cancelled ? (
        <Tag className={props.className} color="red">
            Заказ удален
        </Tag>
    ) : props.status === OrderStatus.Delivering ? (
        <Tag className={props.className} color="blue">
            Заказ выполняется
        </Tag>
    ) : props.status === OrderStatus.Done && props.fullMode ? (
        <Tag className={props.className} color="green">
            Выполнен
        </Tag>
    ) : props.status === OrderStatus.Failed && props.fullMode ? (
        <Tag className={props.className} color="red">
            Не выполнен
        </Tag>
    ) : props.status === OrderStatus.Rescheduled && props.fullMode ? (
        <Tag className={props.className} color="red">
            Перенесен
        </Tag>
    ) : (
        <></>
    );