import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import { AutoComplete, Button, Input } from 'antd';
import { observer } from 'mobx-react-lite'; // eslint-disable-line
import { ArrayUtils } from '../../../Std/ArrayUtils';
import { Driver } from '../../../Store/District/District';
import './DriverList.css'

interface IDriverList {
  allDrivers: Driver[];
  districtDrivers: Driver[];
  setDistrictDrivers: (drivers: Driver[]) => void;
}

interface OptionType {
  value: string;
  label: string;
}

const DriversList = observer((props: IDriverList) => {
  const [text, setText] = React.useState('');
  const [options, setOptions] = React.useState<OptionType[]>([]);

  const updateOptions = React.useCallback((searchString?: string) => {
    const availableOptions: OptionType[] = [];
    const selectedDriverIds = props.districtDrivers.map((d) => d.id);

    let sortedDrivers = ArrayUtils.sort(props.allDrivers, [(x) => -(+ x.isActive), (x) => x.name.toUpperCase()])

    if (searchString) {
      sortedDrivers = sortedDrivers.filter(driver => driver.name.toUpperCase().indexOf(searchString.toUpperCase()) !== -1);
    }

    sortedDrivers.forEach((driver: Driver) => {
      if (selectedDriverIds.indexOf(driver.id) === -1) {
        availableOptions.push({ value: driver.id.toString(), label: driver.name })
      }
    })

    setOptions(availableOptions);
  }, [props.allDrivers])

  React.useEffect(() => {
    updateOptions();
  }, [props.allDrivers]);

  const removeDriver = (driver: Driver) => {
    props.setDistrictDrivers(props.districtDrivers.filter(d => d.id !== driver.id));
    updateOptions();
  }

  const onSearch = (searchText: string) => {
    updateOptions(searchText);
  };

  const onChange = (data: string) => {
    setText(data);
  };

  const onSelect = (value: string) => {
    const foundDriver = props.allDrivers.find(d => d.id === Number(value));
    if (!foundDriver) {
      return;
    }
    props.setDistrictDrivers(props.districtDrivers.concat(foundDriver));
    setText('');
    updateOptions();
  }

  return (
    <div>
        <AutoComplete
          className='driver-list__autocomplete'
          notFoundContent='Не найдено'

          options={options}
          value={text}
          onChange={onChange}
          onSearch={onSearch}
          onSelect={onSelect}
        >
          <Input.Search size='middle' placeholder='Имя водителя' />
        </AutoComplete>
      {props.districtDrivers.length > 0 ? (
        <div className='driver-list__container'>
          {ArrayUtils.sort(props.districtDrivers, d => d.name).map(driver => (
            <div
              key={driver.id}
              className={!driver.isActive
                ? 'driver-list__item driver-list__item_inactive'
                : 'driver-list__item'
              }
            >
              {driver.name}
              <Button
                className='driver-list__remove-button'
                onClick={() => removeDriver(driver)}
              >
                <CloseOutlined />
              </Button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
});

export default DriversList;