import * as React from 'react';
import {formatDistance} from '../../../Std/FormatDistance';

export interface IFormattedDistanceProps {
    className: string | undefined;
    distance: number | undefined;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const FormattedDistance = (props: IFormattedDistanceProps): JSX.Element => {
    const { className, distance, onClick } = props;

    if (distance === undefined) {
        return <></>;
    }

    return (
        <div className={className} onClick={onClick}>
            {formatDistance(distance).replace(' ', '').replace('.', '')}
        </div>
    );
};
