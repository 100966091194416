import classNames from 'classnames';
import * as React from 'react';

import { OrderOperations } from '../../Model/OrderOperations/OrderOperations';

import './OrderOperationsComponent.css';
import { ProductOperationComponent } from './ProductOperation/ProductOperationComponent';

export interface IOrderOperationsComponentProps {
    className?: string;
    orderOperations: OrderOperations;
}

export const OrderOperationsComponent = (props: IOrderOperationsComponentProps): JSX.Element => (
    <div className={classNames('order-operations', props.className)}>
        {props.orderOperations.productOperations.map((productOperation, index) => (
            <ProductOperationComponent
                className="order-operations__item"
                key={index}
                productOperation={productOperation}
            />
        ))}
    </div>
);
