import { DeliveryPlanResponse } from '../../../../../ApiClient/Yoso/models';
import { CompanyApiCompanyResponse } from '../../../../../ApiClient/YosoCompany/models';
import { Company } from '../../../../../Model/Company/Company';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { companyDeserializer } from '../../Model/CompanyDeserializer';

export interface IGetCompanyResponse {
    company: Company;
}

export class GetCompanyResponseDeserializer implements IResponseDeserializer<CompanyApiCompanyResponse, IGetCompanyResponse> {
    public deserialize(response: CompanyApiCompanyResponse): IGetCompanyResponse {
        return {
            company: companyDeserializer.deserialize(response.company)
        };
    }
}

export const getCompanyResponseDeserializer = new GetCompanyResponseDeserializer();
