import { GeocodeSource as ApiGeocodeSource } from '../../../../../ApiClient/Yoso/models';
import { GeocodeSource } from '../../../../../Model/Place/GeocodeSource';

export class GeocodeSourceDeserializer {
    public deserialize(serializedObject: ApiGeocodeSource | undefined): GeocodeSource {
        return serializedObject === undefined
            ? GeocodeSource.Unknown
            : serializedObject === ApiGeocodeSource.DADATA
            ? GeocodeSource.DaData
            : serializedObject === ApiGeocodeSource.EXCEL
            ? GeocodeSource.Excel
            : serializedObject === ApiGeocodeSource.NOMINATIM
            ? GeocodeSource.Nominatim
            : serializedObject === ApiGeocodeSource.UNKNOWN
            ? GeocodeSource.Unknown
            : serializedObject === ApiGeocodeSource.USER
            ? GeocodeSource.User
            : GeocodeSource.Unknown;
    }
}

export const geocodeSourceDeserializer = new GeocodeSourceDeserializer();
