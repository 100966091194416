import { Color } from '../Std/Color/Color';
import { ByIndexColorPicker } from '../Std/ColorScheme/ByIndexColorPicker';
import { MapColor } from './MapColor';

export const defaultColorPalette = new ByIndexColorPicker([
    [MapColor.Blue, Color.rgba('#0000fe')],
    [MapColor.Green, Color.rgba('#008001')],
    [MapColor.Orange, Color.rgba('#ff7f00')],
    [MapColor.Red, Color.rgba('#fe0000')],
    [MapColor.Purple, Color.rgba('#8c01fe')],
    [MapColor.BlueGreen, Color.rgba('#008081')],
    [MapColor.YellowGreen, Color.rgba('#a1b603')],
    [MapColor.YellowOrange, Color.rgba('#ffc000')],
    [MapColor.RedOrange, Color.rgba('#ff4403')],
    [MapColor.RedPurple, Color.rgba('#a81780')],
    [MapColor.BluePurple, Color.rgba('#5a00d4')],
    [MapColor.BluePale, Color.rgba('#5050d4')],
    [MapColor.GreenPale, Color.rgba('#509252')],
    [MapColor.OrangePale, Color.rgba('#d39250')],
    [MapColor.RedPale, Color.rgba('#d65051')],
    [MapColor.PurplePale, Color.rgba('#9851d5')],
    [MapColor.BlueGreenPale, Color.rgba('#509291')],
    [MapColor.YellowGreenPale, Color.rgba('#a1af50')],
    [MapColor.YellowOrangePale, Color.rgba('#d5b351')],
    [MapColor.RedOrangePale, Color.rgba('#d57451')],
    [MapColor.RedPurplePale, Color.rgba('#a75d92')],
    [MapColor.BluePurplePale, Color.rgba('#7e50bd')],
]);
