import moment, { Moment } from 'moment';

import * as React from 'react';
import { DatePicker, Form, TimePicker} from 'antd';
import {Company} from "../../Model/Company/Company";
import { DateTime } from '../../Std/DateTime';
import { Time } from '../../Std/Time';

export interface ITripPlanDatePickerProps {
    executionDate: DateTime | undefined;
    handleExecutionDateChange?: (date: Moment | null) => void;
    startTime: Time | undefined;
    handleStartTimeChange?: (time: Moment | null) => void;
    endTime: Time | undefined;
    handleEndTimeChange?: (time: Moment | null) => void;
    isAlternativePlanAllowed?: boolean | undefined;
    planId?: number | undefined;
}

export const TripPlanDatePicker = (props: ITripPlanDatePickerProps): JSX.Element => { 
    const timeFormat = 'HH:mm';

    return (
    <div className="new-plan__date-picker">
        <Form.Item>
            <DatePicker
                value={moment(props.executionDate === undefined ? undefined: props.executionDate.toDate())}
                onChange={props.handleExecutionDateChange}
                className="new-plan__date-picker-form"
                disabled={!!props.planId}
            />
        </Form.Item>
        <Form.Item>
            <TimePicker
                value={moment(props.startTime === undefined ? undefined : props.startTime.toDate())}
                format={timeFormat}
                disabled={!!props.isAlternativePlanAllowed}
                onChange={props.handleStartTimeChange}
                className="new-plan__time-picker-form"
            />
            <span className="new-plan__dash-character" />
            <TimePicker
                defaultValue={moment(props.endTime === undefined ? undefined : props.endTime.toDate())}
                format={timeFormat}
                disabled={!!props.isAlternativePlanAllowed}
                onChange={props.handleEndTimeChange}
                className="new-plan__time-picker-form"
            />
        </Form.Item>
    </div>
    )
}
