import { DeliveryApiDraftCar } from '../../../../../ApiClient/Yoso/models';
import { Car } from '../../../../../Model/Car/Car';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class DraftCarSerializer implements IApiModelSerializer<Car, DeliveryApiDraftCar> {
    public serialize(obj: Car): DeliveryApiDraftCar {
        return {
            id: obj.id,
            capacity: obj.capacity,
            // eslint-disable-next-line id-blacklist
            number: obj.carNumber,
        };
    }
}

export const draftCarSerializer = new DraftCarSerializer();
