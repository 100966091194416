import { Employee } from '@/Model/Employee/Employee';
import { Skeleton } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { DriverEntityEdit } from '../../../DriverEntityEdit/DriverEntityEdit';
import { Page } from '../../../Page/Page';
import { RoutedTabs } from '../../../RoutedTabs/RoutedTabs';
import { DriverCoefs } from './DriverCoefs';

export interface IDriverEditRouteProps extends RouteComponentProps {
    id: number | undefined;
}

export const DriverEditRoute = withRouter(
    (props: IDriverEditRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(props.id !== undefined);
        const [driver, setDriver] = React.useState(undefined as Employee | undefined);

        const fetchData = async (id: number): Promise<undefined> => {
            setLoading(true);
            try {
                const response = await CompanyApi.getDriver(id);
                if (destroyed.current) {
                    return;
                }
                setDriver(response);
            } catch (error) {
                if (destroyed.current) {
                    return;
                }
                alert(String(error));
            }
            setLoading(false);
        };

        React.useEffect((): void => {
            if (props.id !== undefined) {
                fetchData(props.id).then(
                    () => {
                        //
                    },
                    () => {
                        //
                    },
                );
            }
        }, [props.id]);

        const handleSaveDriver = React.useCallback((): void => {
            props.history.push(`/drivers`);
        }, [props.history]);

        return (
            <Page
                header={
                    props.id === undefined
                        ? 'Новый водитель'
                        : driver === undefined
                        ? ''
                        : `Водитель ${driver.driver.name}`
                }
            >
                {loading ? (
                    <Skeleton active round title={{ width: 400 }} />
                ) : (
                    <RoutedTabs
                    className='fullheight'
                    tabs={[
                        {
                            title: 'Настройки',
                            url: `/drivers/${props.id}`,
                            render: () => <DriverEntityEdit
                                driver={
                                    driver === undefined || props.id === undefined
                                        ? undefined
                                        : {
                                            employee: driver,
                                            id: props.id,   
                                        }
                                }
                                onSave={handleSaveDriver}
                            />,
                        },
                        {
                            title: 'Анализ коэффициентов',
                            url: `/drivers/${props.id}/coefs`,
                            render: () => props.id ? <DriverCoefs driverId={props.id} /> : <></>
                        },
                    ]}
                />
                )}
            </Page>
        );
    },
);
