import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { MarkerTooltip } from './MarkerTooltip';

export class Marker {
    public latitude: number;
    public longitude: number;
    public readonly content: string | undefined;
    public readonly clickable: boolean;
    public readonly tooltip: MarkerTooltip | undefined;
    public readonly style: string | undefined;
    public readonly zIndex: number | undefined;
    public className: {[id: string]: any};
    public isDraggable?: boolean;
    private readonly onClickHandlers: Array<() => void> = [];
    private readonly onRightClickHandlers: Array<() => void> = [];
    private readonly onDragHandlers: Array<(marker: Marker, coords: IGeocode) => void> = [];
    private readonly onDragEndHandlers: Array<(marker: Marker) => void> = [];

    public constructor(
        latitude: number,
        longitude: number,
        content: string | undefined,
        clickable: boolean,
        tooltip: MarkerTooltip | undefined,
        style: string | undefined,
        className: string | {[id: string]: any},
        zIndex?: number | undefined,
    ) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.content = content;
        this.clickable = clickable;
        this.tooltip = tooltip;
        this.style = style;
        this.className = (typeof className === "string") ? { [className]: true } : className;
        this.zIndex = zIndex;
    }

    public setIsDraggable(value: boolean): Marker {
        this.isDraggable = value;

        return this;
    }

    public onClick(handler: () => void): void {
        this.onClickHandlers.push(handler);
    }

    public onRightClick(handler: () => void): void {
        this.onRightClickHandlers.push(handler);
    }

    public onDrag(handler: (marker: Marker, coords: IGeocode) => void): Marker {
        this.onDragHandlers.push(handler);

        return this;
    }

    public onDragEnd(handler: (marker: Marker) => void): Marker {
        this.onDragEndHandlers.push(handler);

        return this;
    }

    public getOnDragHandlers(): Array<(draggableMarker: Marker, coords: IGeocode) => void> {
        return this.onDragHandlers;
    }

    public getOnDragEndHandlers(): Array<(marker: Marker) => void> {
        return this.onDragEndHandlers;
    }

    public getOnClickHandlers(): Array<() => void> {
        return this.onClickHandlers;
    }

    public getOnRightClickHandlers(): Array<() => void> {
        return this.onRightClickHandlers;
    }
}