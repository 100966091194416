import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import bg from '../../../../icons/dragon-scales.png';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import {
    NoticeMailsStore
} from '../../../../Store/NoticeMailsStore/NoticeMailsStore';
import Indicator from './Indicator';
import Mail from './Mail';
import Spinner from './Spinner';
import styles from './Mailbox.module.css';

interface MailboxProps {
    isVisible: boolean;
    onClose: () => void;
    store: NoticeMailsStore;
}

const Mailbox = observer((props: MailboxProps): JSX.Element => {
    const {isVisible, onClose, store} = props;

    const [childHeight, setChildHeight] = React.useState(0)

    const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
    const childScrollableDivRef = React.useRef<HTMLDivElement | null>(null);

    const scrollToBottom = (scrollPos: number | 'max') => {
        if (scrollableDivRef.current === null) {
            return;
        }

        if (scrollPos === 'max') {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
            if (childScrollableDivRef && childScrollableDivRef.current) {
                setChildHeight(childScrollableDivRef.current.offsetHeight);
            }
        } else {
            const offsetHeight = childScrollableDivRef.current?.offsetHeight;
            if (offsetHeight) {
                scrollableDivRef.current.scrollBy({top: offsetHeight - childHeight});
                setChildHeight(offsetHeight)
            }
        }
    };

    React.useEffect(() => {
        if (!store.isMailboxInit || !store.selectedDriverId) {
            return undefined;
        }

        const height = childScrollableDivRef.current?.offsetHeight;

        const driversWhomArchiveReceived = store.driversWhomArchiveReceived.get(store.selectedDriverId) ?? 0;
        if (driversWhomArchiveReceived > 1) {
            if (height) {
                scrollToBottom(height);
            }
        } else {
            scrollToBottom('max')
        }

    }, [store.selectedDriverId, store.isMailboxInit, store.selectedDriverMails.length]);

    const driverMails = React.useMemo(() => ArrayUtils.sort(store.driverMails,
        [by => by.driver.id === store.selectedDriverId, by => !by.hasUnreadMails, by => by.driver.name]
    ), [isVisible])

    return (
        <Drawer
            title='Уведомления'
            onClose={onClose}
            closable={true}
            visible={isVisible}
            bodyStyle={{ padding: 0 }}
            width={'50%'}
        >
            <div className={styles.drawer}>
                <div className={styles.drivers}>
                    {driverMails.map(i =>
                        <div
                            key={i.driver.id}
                            className={classnames(
                                styles.card,
                                {[styles.selectedCard]: store.selectedDriverId === i.driver.id}
                            )}
                            onClick={() => store.setSelectedDriverId(i.driver.id)}
                        >
                            <UserOutlined />
                            <div className={styles.cardMail}>
                                <div className={styles.cardTitle}>
                                    <div className={styles.overflowEllipsis}>
                                        {i.driver.name}
                                    </div>
                                </div>
                                <div className={styles.cardText}>
                                    <div className={styles.overflowEllipsis}>
                                        {i.mails[i.mails.length - 1].text}
                                    </div>
                                </div>
                            </div>
                            {i.hasUnreadMails && <div className={styles.markUnread}/>}
                        </div>
                    )}
                </div>
                <div className={styles.scrollableBlock} style={{backgroundImage: `url(${bg})`}} ref={scrollableDivRef}>
                    {store.selectedDriverId && <div ref={childScrollableDivRef} className={styles.childScrollableBlock}>
                        <Indicator store={store}>
                            <Spinner store={store} />
                        </Indicator>
                        {store.selectedDriverMailsGroupedByDate.map(({ date, mails }) =>
                            <div key={date} className={styles.messagesByDate}>
                                <div className={styles.date}>
                                    <div className={styles.dateTitle}>
                                        {date.replace(/\d+\s/, '')}
                                    </div>
                                </div>
                                <div className={styles.mails}>
                                    {mails.map(i =>
                                        <Mail
                                            key={i.id}
                                            text={i.text}
                                            dateStr={i.date.getTime().toString()}
                                            link={i.planId && i.tripId
                                                ? () => window.open(`/plans/${i.planId}/analysis/${i.tripId}`)
                                                : (i.code === "RECOMMENDED_COEFFICIENTS"
                                                ? () => window.open(`/drivers/${store.selectedDriverId}/coefs`)
                                                : undefined)}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>}
                </div>
            </div>
        </Drawer>
    );
});

export default Mailbox;