import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Car } from '../../../../Model/Car/Car';
import { TripPlan } from '../../../../Model/TripPlan/TripPlan';
import { formatDateTimePeriodShort } from '../../../../Std/formatDateTimePeriodShort';
import { Page } from '../../../Page/Page';
import { useTripPlanData } from '../../../TripPlanData/TripPlanDataProvider';

export interface ITripPlanItemEditRouteProps extends RouteComponentProps {
    id: number | undefined;
    tripPlan: TripPlan | undefined;
    tripPlanData: JSX.Element;
}

export const TripPlanItemEditRoute = withRouter(
    (props: ITripPlanItemEditRouteProps): JSX.Element => {
        const context = useTripPlanData();
        const { loadPlan, tripPlan } = context;

        const [tripPlanDateTimePeriodString, setTripPlanDateTimePeriodString] = React.useState(
            undefined as string | undefined,
        );

        React.useEffect(() => {
            // tslint:disable-next-line: no-floating-promises
            loadPlan(props.id);
        }, [loadPlan, props.id]);

        React.useEffect(() => {
            setTripPlanDateTimePeriodString(
                tripPlan === undefined ? undefined : formatDateTimePeriodShort(tripPlan.dateTimePeriod),
            );
        }, [tripPlan]);

        const tripPlanData = props.tripPlanData;

        return (
            <Page
                header={
                    context.planId === undefined
                        ? 'Новый план рейсов'
                        : tripPlanDateTimePeriodString === undefined
                        ? ''
                        : `Добавление заказов в план ${tripPlanDateTimePeriodString}`
                }
            >
                {tripPlanData}
            </Page>
        );
    },
);
