import { Duration } from '../../Std/Duration';
import { Car } from '../Car/Car';
import { Driver } from '../Driver/Driver';
import { TripStatus } from '../Trip/TripStatus';
import { TripWorkingShift } from '../Trip/TripWorkingShift';

export class TripStatsSummary {
    public readonly actualDistance: number;
    public readonly actualDuration: Duration;
    public readonly actualPrice: number;
    public readonly adjustmentCount: number;
    public readonly car: Car;
    public readonly delayCount: number;
    public readonly doneOrderCount: number;
    public readonly doneProductCount: number;
    public readonly driver: Driver;
    public readonly estimatedDistance: number;
    public readonly estimatedDuration: Duration;
    public readonly estimatedPrice: number;
    public readonly estimatedProductCount: number;
    public readonly failedOrderCount: number;
    public readonly failedProductCount: number;
    public readonly movedOrdersCount: number;
    public readonly receivedOrdersCount: number;

    public readonly id: number;
    public readonly scheduledOrderCount: number;
    public readonly status: TripStatus;
    public readonly workingShift: TripWorkingShift | undefined;

    public constructor(
        id: number,
        car: Car,
        driver: Driver,
        status: TripStatus,
        scheduledOrderCount: number,
        doneOrderCount: number,
        failedOrderCount: number,
        adjustmentCount: number,
        estimatedDistance: number,
        actualDistance: number,
        estimatedDuration: Duration,
        actualDuration: Duration,
        estimatedProductCount: number,
        doneProductCount: number,
        failedProductCount: number,
        delayCount: number,
        estimatedPrice: number,
        actualPrice: number,
        movedOrdersCount: number,
        receivedOrdersCount: number,
        workingShift?: TripWorkingShift
    ) {
        this.id = id;
        this.car = car;
        this.driver = driver;
        this.status = status;
        this.scheduledOrderCount = scheduledOrderCount;
        this.doneOrderCount = doneOrderCount;
        this.failedOrderCount = failedOrderCount;
        this.adjustmentCount = adjustmentCount;
        this.estimatedDistance = estimatedDistance;
        this.actualDistance = actualDistance;
        this.estimatedDuration = estimatedDuration;
        this.actualDuration = actualDuration;
        this.estimatedProductCount = estimatedProductCount;
        this.doneProductCount = doneProductCount;
        this.failedProductCount = failedProductCount;
        this.delayCount = delayCount;
        this.estimatedPrice = estimatedPrice;
        this.actualPrice = actualPrice;
        this.movedOrdersCount = movedOrdersCount;
        this.receivedOrdersCount = receivedOrdersCount;
        this.workingShift = workingShift;
    }
}
