import { DateTime } from '../../Std/DateTime';
import { Geocode } from '../Geocode/Geocode';

export class CarTrackedLocation {
    public readonly dateTime: DateTime;
    public readonly geocode: Geocode;

    public constructor(geocode: Geocode, dateTime: DateTime) {
        this.geocode = geocode;
        this.dateTime = dateTime;
    }
}
