import React from 'react';
import { Button, DatePicker, TimePicker } from 'antd';
import moment, { Moment } from 'moment/moment';
import { FailReasonSelector } from '@/Components/FailReasonSelector';
import { RescheduleOrderDto } from '@/Model/Order/RescheduleOrderDto';
import { DateTimePeriod } from '@/Std/DateTimePeriod';

interface RescheduleOrderProps {
    period: DateTimePeriod;
    onRescheduleOrder: (dto: RescheduleOrderDto) => void;
}

export const RescheduleOrder = (props: RescheduleOrderProps) => {
    const {period, onRescheduleOrder} = props

    const copyDateDay = (from: Date, to: Date): Date => {
        to.setFullYear(from.getFullYear());
        to.setMonth(from.getMonth());
        to.setDate(from.getDate());

        return to;
    }

    const [selectedReason, setSelectedReason] = React.useState<{reason?: string, subReason?: string, isCorrect: boolean}>({isCorrect: false});
    const [rescheduleDate, setRescheduleDate] = React.useState<Moment>(moment(period.start.toDate()).add(1, 'day'));
    const [rescheduleTimeBegin, setRescheduleTimeBegin] = React.useState<Moment>(moment(period.start.toDate()));
    const [rescheduleTimeEnd, setRescheduleTimeEnd] = React.useState<Moment>(moment(period.end.toDate()));

    const handleRescheduleDateChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        setRescheduleDate(value);
    }

    const handleRescheduleTimeBeginChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        setRescheduleTimeBegin(value);
    }

    const handleRescheduleTimeEndChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        setRescheduleTimeEnd(value);
    }

    const rescheduleOrder = () => {
        if (!selectedReason.reason) {
            return;
        }
        onRescheduleOrder({
            timeBegin: copyDateDay(rescheduleDate.toDate(), rescheduleTimeBegin.toDate()),
            timeEnd: copyDateDay(rescheduleDate.toDate(), rescheduleTimeEnd.toDate()),
            reason: selectedReason.reason,
            subReason: selectedReason.subReason
        });
    }

    const changeReason = (isCorrect: boolean, reason: string, subReason?: string) => {
        setSelectedReason({reason, subReason, isCorrect})
    }

    return (
        <>
            <div className="reschedule-order__block-subheader">Выберите дату и интервал доставки</div>
            <div className="reschedule-order_date-selector">
                <DatePicker
                    value={rescheduleDate}
                    disabledDate={d => d.isBefore()}
                    clearIcon={null}
                    onChange={handleRescheduleDateChange}
                    className="reschedule-order_date-picker" />
                <div>
                    <TimePicker
                        format="HH:mm"
                        value={rescheduleTimeBegin}
                        onChange={handleRescheduleTimeBeginChange}
                        clearIcon={null}
                        className="reschedule-order_time-begin-picker" />
                    &nbsp;-&nbsp;
                    <TimePicker
                        format="HH:mm"
                        value={rescheduleTimeEnd}
                        onChange={handleRescheduleTimeEndChange}
                        clearIcon={null}
                        className="reschedule-order_time-end-picker" />
                </div>
            </div>
            <FailReasonSelector onChange={changeReason} />
            <Button
                type='primary'
                className='reschedule-order_button'
                onClick={rescheduleOrder}
                disabled={!selectedReason.isCorrect}
            >
                Перенести заказ
            </Button>
        </>
    );
};
