import React from 'react';
import { Button, Checkbox } from 'antd';
import { RouteInfoSelector } from '@/Components/RouteInfo/RouteInfoSelector';
import { Trip } from '@/Model';
import {
    MassOrderTransferConfirm
} from '../../features/MassOrderTransferConfirm/MassOrderTransferConfirm';

interface MassOrderTransferProps {
    trip: Trip;
    allTrips: Trip[];
    onCancel: () => void;
    transferOrders: (toTripId: number, orderIds: number[]) => void;
}

export const MassOrderTransfer = (props: MassOrderTransferProps) => {
    const {trip, onCancel, allTrips, transferOrders} = props;

    const [selectedOrderIds, setSelectedOrderIds] = React.useState(new Set<string>());
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const clearSelectedPointIds = React.useCallback(() => {
        setSelectedOrderIds(new Set<string>());
    }, []);

    const toggleSelectedOrderId = (pointId: string) => {
        const newSelectedOrderIds = new Set<string>(selectedOrderIds);
        if (newSelectedOrderIds.has(pointId)) {
            newSelectedOrderIds.delete(pointId);
        } else {
            newSelectedOrderIds.add(pointId);
        }
        setSelectedOrderIds(newSelectedOrderIds);
    }

    const transferSelectedOrders = (toTripId: number) => {
        const orderIds = trip.route.orders
            .filter((orderPoint) => selectedOrderIds.has(orderPoint.id))
            .map((orderPoint) => orderPoint.order.id);

        transferOrders(toTripId, orderIds)
    }

    const handleSelect = () => {
        if (selectedOrderIds.size === trip.route.routePoints.length) {
            clearSelectedPointIds();

            return;
        }
        setSelectedOrderIds(new Set<string>(
            trip.route.routePoints.map((point) => point.id))
        );
    }

    return (
        <>
            <div className="trip-plan-realtime__route-info-panel">
                <Button
                    type="link"
                    onClick={onCancel}
                >
                    Отменить передачу
                </Button>
                <Checkbox
                    checked={selectedOrderIds.size === trip.route.routePoints.length}
                    onChange={handleSelect}
                    disabled={isModalOpen}
                >
                    Все
                </Checkbox>
            </div>
            <RouteInfoSelector
                selectedPointIds={selectedOrderIds}
                selectingDisabled={isModalOpen}
                onTogglePoint={toggleSelectedOrderId}
                route={trip.route}
            />
            <div className="trip-plan-realtime__transfer-orders-block">
                <Button
                    type="default"
                    onClick={clearSelectedPointIds}
                    disabled={selectedOrderIds.size === 0}
                >
                    Очистить
                </Button>
                <Button
                    type="primary"
                    onClick={() => setIsModalOpen(true)}
                    disabled={selectedOrderIds.size === 0}
                >
                    Передать заказы
                </Button>
            </div>

            <MassOrderTransferConfirm
                isVisible={isModalOpen}
                fromTrip={trip}
                onCancel={() => setIsModalOpen(false)}
                onConfirm={transferSelectedOrders}
                selectedOrderCount={selectedOrderIds.size}
                trips={allTrips}
            />
        </>
    );
};
