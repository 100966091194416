import { runInAction, makeAutoObservable } from 'mobx';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { Basket } from '../../Model/Basket/Basket';
import { Geocode } from '../../Model/Geocode/Geocode';
import { IGeocode } from '../../Model/Geocode/IGeocode';
import { ImportOrder } from '../../Model/ImportOrder/ImportOrder';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { MapBounds } from '../../Std/MapBounds/MapBounds';
import { DateTime } from '../../Std/DateTime';

export class BasketsStore {
    public date: DateTime | undefined = undefined;
    public baskets: Basket[] = [];
    public selectedBasket: Basket | undefined = undefined;
    public orders: ImportOrder[] = [];

    public constructor() {
        makeAutoObservable(this);
    }

    public setBasketsByWorkingDayId(workingDayId: number): void {
        DeliveryApi.getWorkingDay(workingDayId).then(response => runInAction(() => {
            this.baskets = this.sortBaskets(response.baskets)
            this.date = response.date
        }));
    }

    public setSelectedBasket(basket: Basket): void {
        if (this.selectedBasket?.id === basket.id) {
            this.selectedBasket = undefined;
            this.orders = [];
        } else {
            this.setOrders(basket.id);
            this.selectedBasket = basket;
        }
    }

    public get getSelectedBasketIndex(): number | undefined {
        const index = this.baskets.findIndex(b => b.id === this.selectedBasket?.id);

        return index < 0 ? undefined : index;
    }

    public get ordersBoundingBoxes(): IGeocode[][] {
        const map = new Map<number, Geocode[]>();

        this.orders.map(o => {
            if (!o.nominatimId || !o.nominatimBoundingBox) {
                return;
            }

            map.set(o.nominatimId, o.nominatimBoundingBox);
        })

        return Array.from(map.values());
    }

    public get ordersBounds(): MapBounds | undefined {
        const points: IGeocode[] = ArrayUtils.defined(this.orders.map(o => o.coords));
        if (!points.length) {
            return undefined;
        }

        return new MapBounds(points);
    }

    private setOrders(basketId: number) {
        DeliveryApi.getBasket(basketId).then(response => runInAction(() => { this.orders = response }));
    }

    private sortBaskets(basketArr: Basket[]) {
        return ArrayUtils.sort(basketArr, [b => b.driverName, b => b.index])
    }
}