import { LatLngBoundsLiteral, LatLngTuple } from 'leaflet';
import { Geocode } from '../Model/Geocode/Geocode';
import { IGeocode } from '../Model/Geocode/IGeocode';

export const getLeafletBoundsFromArrGeocodes = (arrGeocodes: IGeocode[][]): LatLngBoundsLiteral =>
    arrGeocodes.reduce((geocodes, item) => {
        const tempBounds: LatLngTuple[] = getLeafletBoundsFromIGeocodes(item);

        return [...geocodes, ...tempBounds];
    }, [] as LatLngBoundsLiteral);

export const getLeafletBoundsFromIGeocodes = (geocodes: IGeocode[]): LatLngTuple[] =>
    geocodes.map((g) => [g.latitude, g.longitude]);

export const getLeafletBoundsFromGeocodes = (geocodes: Geocode[]): LatLngTuple[] =>
    geocodes.map((g) => [g.latitude, g.longitude]);
