import React from 'react';
import {observer} from "mobx-react";
import {District} from '../../../../Store/District/District';
import {DistrictStore} from '../../../../Store/District/DistrictStore';
import DistrictListItem from "../../../DistrictListEditor/DistrictListItem";
import DistrictItemModal from "../../../DistrictListEditor/DistrictItemModal";
import {ArrayUtils} from "../../../../Std/ArrayUtils";
import DistrictGroup from './DistrictGroup';
import './DistrictList.css'

interface DistrictGroupsProps {
  districtStore: DistrictStore;
  onDistrictRemove: (district: District) => Promise<void> | undefined;
}

const DistrictGroups = observer((props: DistrictGroupsProps): JSX.Element => (
    <>
      {ArrayUtils.sort(props.districtStore.unitedDistricts, (by) => by.name).map((union) =>
        <DistrictGroup
            key={union.name}
            cityDistrict={union}
            districtStore={props.districtStore}
            onDistrictRemove={props.onDistrictRemove}
        />
      )}
      {props.districtStore.noUnitedDistricts.length > 0 &&
        props.districtStore.unitedDistricts.length > 0 &&
        <div className='district-list__space'/>}
      {ArrayUtils.sort(props.districtStore.noUnitedDistricts, (by) => by.name).map(d =>
        <DistrictListItem
          key={d.id}
          district={d}
          districtStore={props.districtStore}
          onDistrictRemove={props.onDistrictRemove}
        />
      )}
      {props.districtStore.currentlyBeingEditedDistrict &&
        <DistrictItemModal
          district={props.districtStore.currentlyBeingEditedDistrict}
          districtStore={props.districtStore}
        />
      }
    </>
));

export default DistrictGroups;