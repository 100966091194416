import {makeAutoObservable} from "mobx";
import {CompanyApi} from "../../Backend/Api/Company/CompanyApi";
import {Geocode} from "../../Model/Geocode/Geocode";
import {IGeocode} from "../../Model/Geocode/IGeocode";
import { Warehouse } from "../../Model/Store/Warehouse";

export class WarehouseStore {
    public warehouses: Warehouse[] = [];
    public selectedWarehouse: Warehouse | undefined = undefined;

    public constructor() {
        makeAutoObservable(this);
    }

    public setSelectedWarehouse(id: number) {
        if (this.selectedWarehouse?.id === id) {
            this.selectedWarehouse = undefined;

            return;
        }
        const warehouse = this.warehouses.find(w => w.id === id);
        if (!warehouse) {
            return
        }
        this.selectedWarehouse = warehouse;
    }

    public addWarehouse(id: number, coords: IGeocode) {
        this.selectedWarehouse = undefined;
        this.warehouses.push(new Warehouse(id, new Geocode(coords), '', false, false));
    }

    public async fetchWarehouses() {
        try {
            const response = await CompanyApi.getStoreList();
            this.warehouses = response;
        } catch (error) {
            alert(String(error));
        }
    }

    public async deleteWarehouse(id: number) {
        const warehouse = this.warehouses.find(w => w.id === id);
        if (!warehouse) {
            return
        }
        warehouse.setDeleted();
    }

    public setMain(id: number) {
        const warehouseMain = this.warehouses.find(w => w.isMain);
        const warehouse = this.warehouses.find(w => w.id === id);

        if (!warehouse || warehouseMain?.id === warehouse.id) {
            return
        }

        if (warehouseMain) {
            warehouseMain.setIsMain(false);
        }
        warehouse.setIsMain(true);
    }

    public async save() {
        const clearWarehouses = this.warehouses.filter(w => w.id >= 0 || !w.isDeleted)
        try {
            await CompanyApi.postCompanyStores(clearWarehouses);
            this.selectedWarehouse = undefined;
            await this.fetchWarehouses();
        } catch (e) {
            alert(e)
        }
    }
}