import * as React from 'react';
import { __RouterContext } from 'react-router';

import './EntityList.css';
import { Result } from '../../Model/PaginatedEntities/Result';
import { EntityListBase, EntityListItemAction, IListActionConfig, EntityListItemSafeAction, EntityListAction, IEntityListColumnConfig,
    IEntityListData } from './EntityListBase';

export enum EntityListColumnAlign {
    Center = 'center',
    Left = 'left',
    Right = 'right',
}

export interface IEntityListProps<TEntity, TEntityExtension = TEntity> {
    actions?: {
        item?: {
            click?: EntityListItemAction;
            delete?: IListActionConfig<EntityListItemSafeAction>;
        };
        list?: ReadonlyArray<IListActionConfig<EntityListAction>>;
    };
    className?: string;
    columns: ReadonlyArray<IEntityListColumnConfig<TEntity>>;
    getEntities: (page: number, size: number, searchString?: string, filter?: Record<string, string>) => Promise<Result<TEntity>>;
    getEntityExtension?: (id: number | string) => Promise<TEntityExtension>;
    filter?: {
        additional?: { onClose: () => void; title: string }[];
        search?: {
            filter: (entity: TEntity, searchStringPart: string) => boolean;
            onSearch: (value: string) => void;
            searchString: string;
        };
        select?: {
            filter: (entity: TEntity, selectKey: string, selectValue: string) => boolean;
            items: Record<
                string,
                ReadonlyArray<{
                    title: string;
                    value: string;
                }>
            >;
            onChange: (values: Record<string, string>) => void;
            values: Record<string, string>;
        };
    };
    id: (entity: TEntity) => number | string;
    isEntityWithWarning?: (entity: TEntity) => boolean;
    pageSize?: number;
    renderRow?: (entity: TEntity) => JSX.Element;
    renderRowExtendedContent?: (entity: TEntityExtension) => React.ReactNode;
    rowColorMarker?: (entity: TEntity, index: number) => string;

    selectedItems?: TEntity | ReadonlyArray<TEntity>;
    small?: boolean;
    isRowReverse?: boolean;
    refresh?: number;
}
export const LazyEntityList = <TEntity extends unknown, TEntityExtension = TEntity>(props: IEntityListProps<TEntity, TEntityExtension>): JSX.Element => 
    (
        <EntityListBase<TEntity, TEntityExtension>
            actions={props.actions}
            className={props.className}
            columns={props.columns}
            filter={props.filter}
            id={props.id}
            isEntityWithWarning={props.isEntityWithWarning}
            pageSize={props.pageSize}
            renderRow={props.renderRow}
            renderRowExtendedContent={props.renderRowExtendedContent}
            rowColorMarker={props.rowColorMarker}
            selectedItems={props.selectedItems}
            small={props.small}
            isRowReverse={props.isRowReverse}
            refresh={props.refresh}
            getEntities={props.getEntities}
            getEntityExtension={props.getEntityExtension}
        />
    );