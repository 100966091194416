import { GetPlansOKResponse } from '../../../../../ApiClient/Yoso/models';
import { TripPlanSummary } from '../../../../../Model/TripPlan/TripPlanSummary';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { planInfoDeserializer } from '../../Model/PlanInfo/PlanInfoDeserializer';

export interface IGetPlansResponse {
    plans: ReadonlyArray<TripPlanSummary>;
    total: number;
}

export class GetPlansResponseDeserializer implements IResponseDeserializer<GetPlansOKResponse, IGetPlansResponse> {
    public deserialize(response: GetPlansOKResponse): IGetPlansResponse {
        return {
            plans: response.plans!.map((plan) => planInfoDeserializer.deserialize(plan)),
            total: response.total,
        };
    }
}

export const getPlansResponseDeserializer = new GetPlansResponseDeserializer();
