import * as React from 'react';
import { observer } from 'mobx-react';
import { Space } from 'antd';
import { StatsApiDriverStats, StatsApiDriverLog, StatsApiDriverLogs } from '../../ApiClient/YosoStats/models';
import { dateTimeDeserializer } from '../../Backend/Api/Mapper/DateTimeDeserializer';
import { DateTime } from '../../Std/DateTime';
import { formatDate, formatDateShort } from '../../Std/formatDate';
import { formatTimeSec } from '../../Std/formatTime';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import { TimePeriod } from '../../Std/TimePeriod';
import { StackedBarChart } from '../Chart/StackedBarChart';
import { EntityList } from '../EntityList/EntityList';
import { EntityListColumnAlign } from '../EntityList/EntityListBase';

import './ChartDriver.css';
import { ChartsStore } from '../../Store/ChartsStore/ChartsStore';
import { TimesSeriesLineChart } from '../Chart/TimesSeriesLineChart';
import { MathUtils } from '../../Std/MathUtils';

export interface IChartDriverArrivalProps {
    store: ChartsStore
}

const bars = [
    {fill: '#FAB733', key: 'arrivalLTMinus30', name: 'Раньше'},
    {fill: '#69B34C', key: 'arrivalBtwMinus30Plus30', name: 'Вовремя'},
    {fill: '#FAB733', key: 'arrivalBtwPlus31Plus60', name: 'До получаса'},
    {fill: '#FF4E11', key: 'arrivalGTPlus60', name: 'Дольше получаса'},
];

const barsRouteSimilarity = [
    {fill: '#FAB733', key: 'actualRouteSimilarity', name: 'Схожесть маршрутов'},
]

interface ShipmentChartData {
    doneOrdersShipmentCount: number,
    doneOrdersBottlesCount: number,
    timeNotificationShouldSendCount: number,
    timeNotificationTotalCount: number,
    timeNotificationSentCount: number,
    doneOrdersWithNotificationCount: number,
    doneOrdersInNotificationPeriodCount: number,
    actualRouteSimilarity: number,
    tick: number
}

export const ChartDriver = observer((props: IChartDriverArrivalProps): JSX.Element => {

    const [selectedLogs, setSelectedLogs] = React.useState(undefined as StatsApiDriverLogs | undefined);
    const [driverShipmentStats, setDriverShipmentStats] = React.useState([] as ShipmentChartData[]);

    React.useEffect(() => {
        const result = props.store.driverStats.reduce((r, elem) => {
            r[elem.date] = r[elem.date] || {
                doneOrdersShipmentCount: 0,
                doneOrdersBottlesCount: 0,
                timeNotificationShouldSendCount: 0,
                timeNotificationTotalCount: 0,
                timeNotificationSentCount: 0,
                doneOrdersWithNotificationCount: 0,
                doneOrdersInNotificationPeriodCount: 0,
                actualRouteSimilarity: 0,
                tick: dateTimeDeserializer.deserialize(elem.date).toDate().getTime()
            };
            r[elem.date].doneOrdersShipmentCount += (elem.doneOrdersBottlesCount + elem.doneOrdersNotBottlesCount); // eslint-disable-line
            r[elem.date].doneOrdersBottlesCount += elem.doneOrdersBottlesCount;
            r[elem.date].timeNotificationShouldSendCount += elem.timeNotificationShouldSendCount;
            r[elem.date].timeNotificationTotalCount += elem.timeNotificationTotalCount;
            r[elem.date].timeNotificationSentCount += elem.timeNotificationSentCount;
            r[elem.date].doneOrdersWithNotificationCount += elem.doneOrdersWithNotificationCount;
            r[elem.date].doneOrdersInNotificationPeriodCount += elem.doneOrdersInNotificationPeriodCount;
            r[elem.date].actualRouteSimilarity += elem.actualRouteSimilarity;

            return r;
        }, Object.create(null));
        setDriverShipmentStats(Object.entries(result).map((pair) => pair[1] as ShipmentChartData));
    }, [props.store.driverStats]);

    const formatXLabel = (el: StatsApiDriverStats | {date: string, actualRouteSimilarity: number}) =>
        formatDateShort(dateTimeDeserializer.deserialize(el.date));

    const goToTripAnalysis = (e: any) => {
        if (e.index >= props.store.driverStats.length) {
            return;
        }

        const row = props.store.driverStats[e.index];
        const url = `/plans/${row.planId}/analysis/${row.tripId}`;

        window.open(url, '_blank')!.focus();
    }

    const formatLogPeriod = (log: StatsApiDriverLogs): string => {
        if (!log.records.length) {
            return "";
        }

        const dates = log.records.map((l: StatsApiDriverLog) => new Date(l.createdAt));
        const minDate = dates.reduce((a, b) => a < b ? a : b);
        const maxDate = dates.reduce((a, b) => a > b ? a : b);
        const timePeriod = new TimePeriod(
            DateTime.fromDate(minDate),
            DateTime.fromDate(maxDate));

        return formatTimePeriod(timePeriod);
    }

    return (
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <TimesSeriesLineChart
                    data={driverShipmentStats.map(ds => ({
                        values: [
                            MathUtils.round(ds.doneOrdersShipmentCount, 1),
                            MathUtils.round(ds.doneOrdersBottlesCount, 0),
                            ds.timeNotificationShouldSendCount,
                            ds.timeNotificationTotalCount,
                            ds.timeNotificationSentCount,
                            ds.doneOrdersWithNotificationCount,
                            ds.doneOrdersInNotificationPeriodCount
                        ],
                        tick: ds.tick
                    }))}
                    formatTimeTick={tick => formatDateShort(DateTime.fromDate(new Date(tick)))}
                    names={[
                        "Развоз, экв. бут",
                        "Развоз, бут",
                        "Смс макс., кол-во",
                        "Смс, кол-во",
                        "Смс отправлено, кол-во",
                        "Выполненных заказов с смс, кол-во",
                        "Попаданий в смс, кол-во"]}
                    strokes={[
                        "#0000fe",
                        "#5050d4",
                        "#fe0000",
                        "#ff4403",
                        "#d57451",
                        "#509252",
                        "#69B34C"]}
                    title="Развоз и смс"
                    className='driver-lines' />

            <StackedBarChart<StatsApiDriverStats>
                data={props.store.driverStats}
                bars={bars}
                xLabel={formatXLabel}
                xLabelClick={goToTripAnalysis}
                isExpand={true}
                title="Отклонения от обещанного интервала"
            />
            <StackedBarChart<{date: string, actualRouteSimilarity: number}>
                data={props.store.driverStats.map(i => (
                    {
                        date: i.date,
                        actualRouteSimilarity: MathUtils.ratioToPercentage(Number(i.actualRouteSimilarity.toFixed(1)))
                    }
                ))}
                bars={barsRouteSimilarity}
                xLabel={formatXLabel}
                isYAxis={true}
                title="Соответствие маршруту"
            />
            <div>
                <h3>Ошибки</h3>
                <EntityList<StatsApiDriverLogs>
                    className='driver-logs'
                    data={props.store.driverLogs}
                    id={(log) => log.timestamp}
                    columns={[{
                        key: 'date',
                        title: 'Дата',
                        render: (log) => formatDate(dateTimeDeserializer.deserialize(log.date)),
                    },{
                        key: 'count',
                        title: 'Количество',
                        render: (log) => log.records.length,
                    },{
                        key: 'period',
                        title: 'Период',
                        render: formatLogPeriod,
                        align: EntityListColumnAlign.Right
                    }]}
                    actions={{
                        item: {
                            click: (timestamp: number) => (): undefined => {
                                const selected = props.store.driverLogs.find((l) => l.timestamp === timestamp);
                                setSelectedLogs(selected === selectedLogs ? undefined : selected);

                                return;
                            }
                        }
                    }}
                    selectedItems={selectedLogs}
                    renderRowExtendedContent={(log) => (
                        log.records.map((record) => (
                            <div key={record.id} className="driver-logs__ext-content">
                                <div className="driver-logs__text">
                                    {record.uri && (
                                        <div className="driver-logs__uri">
                                            {record.method && (
                                                <span className={`driver-logs__method ${record.method}`}>{record.method}&nbsp;</span>
                                            )}
                                            {record.uri}
                                        </div>
                                    )}
                                    <div className="driver-logs__message">{record.message}</div>
                                    <pre className="driver-logs__stack-trace">{record.stackTrace}</pre>
                                </div>
                                <div className="driver-logs__time">
                                    {formatTimeSec(dateTimeDeserializer.deserialize(record.createdAt))}
                                </div>
                            </div>
                        ))
                    )}
                    loading={props.store.loading}
                />
            </div>
        </Space>
    )
})