import { Employee } from '@/Model/Employee/Employee';
import { KeyOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Role } from '../../../../ApiClient/YosoAuth/models';
import { useAllDrivers } from '../../../../hooks/useAllDrivers';
import { useInput } from '../../../../hooks/useInput';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import { useUser } from '../../../AppProviders/Providers/UserProvider';
import { EntityList, EntityListColumnAlign } from '../../../EntityList/EntityList';
import './DriversRoute.css';
import { DriverLoginEditor } from '../../../DriverLoginEditor/DriverLoginEditor';

const DriverList = (): JSX.Element => {
    const { user } = useUser();
    const { drivers, loading } = useAllDrivers();
    const { value, onChange } = useInput();
    const [driverToEditLogin, setDriverToEditLogin] = useState(undefined as Employee | undefined);

    const isAdmin = React.useMemo(() => user!.role.equals(Role.ROLEADMIN), [user]);

    const sortedDrivers = React.useMemo(
        () => ArrayUtils.sort(drivers, [(x) => -+!!x.driver.isActive, (x) => x.driver.name.toUpperCase()]),
        [drivers],
    );

    const driverSearchFilter = React.useCallback(
        (driver: Employee, searchStringPart: string) =>
            driver.driver.name.toLowerCase().includes(searchStringPart.toLowerCase()),
        [],
    );

    return (
        <>
            {isAdmin && (
                <Link to="/drivers/new">
                    <Button type="primary" className="driver-list-route__button" icon={<PlusOutlined />}>
                        Добавить водителя
                    </Button>
                </Link>
            )}
            <EntityList<Employee>
                data={sortedDrivers}
                loading={loading}
                id={(driver) => driver.driver.id}
                filter={{
                    search: {
                        filter: driverSearchFilter,
                        onSearch: onChange,
                        searchString: value,
                    },
                }}
                actions={{
                    item: {
                        click: (id) => `/drivers/${id}`,
                    },
                }}
                columns={[
                    {
                        key: 'name',
                        title: 'Имя',
                        render: (driver) => driver.driver.name,
                    },
                    {
                        key: 'username',
                        title: 'Логин',
                        render: (driver) =>
                            driver.driver.login === undefined
                                ? undefined
                                : driver.driver.login,
                    },
                    {
                        key: 'driverProficiency',
                        title: 'Коэффициент движения',
                        render: (driver) => driver.settings.driverProficiency,
                        align: EntityListColumnAlign.Center,
                    },
                    {
                        key: 'unloadingTimeFactor',
                        title: 'Коэффициент разгрузки',
                        render: (driver) => driver.settings.unloadingTimeFactor,
                        align: EntityListColumnAlign.Center,
                    },
                    {
                        key: 'actions',
                        title: 'Действия',
                        render: (driver) => (
                            <Button
                                title="Изменить логин/пароль"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDriverToEditLogin(driver);
                                }}
                            >
                                <KeyOutlined />
                            </Button>
                        ),
                        align: EntityListColumnAlign.Center,
                    },
                ]}
                rowClass={(driver, index) => (driver.driver.isActive ? 'driver__active' : 'driver__inactive')}
            />
            {driverToEditLogin !== undefined && driverToEditLogin.driver.code !== undefined && (
                <DriverLoginEditor
                    driverCode={driverToEditLogin.driver.code}
                    driverName={driverToEditLogin.driver.name}
                    driverLogin={driverToEditLogin.driver.login}
                    onCancel={() => {
                        setDriverToEditLogin(undefined);
                    }}
                    onOk={() => {
                        setDriverToEditLogin(undefined);
                    }}
                />
            )}
        </>
    );
};

export default DriverList;
