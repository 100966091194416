import { Geocode } from '../../../Model/Geocode/Geocode';
import { TripPointType } from '../../../Model/TripPointId/TripPointType';
import { DateTime } from '../../../Std/DateTime';
import { DateTimePeriod } from '../../../Std/DateTimePeriod';

export class RoutePoint {
    public readonly actualArrivalDateTime: DateTime | undefined;
    public readonly address: string;
    public readonly desiredDateTimePeriod: DateTimePeriod | undefined;
    public readonly expectedArrivalDateTime: DateTime | undefined;
    public readonly expectedDepartureDateTime: DateTime | undefined;
    public readonly geocode: Geocode;
    public readonly driverGeocode: Geocode | undefined;
    public readonly id: string;
    public readonly type: TripPointType;
    public readonly originalId: number;
    public readonly isNew: boolean;
    public readonly isOwn: boolean;
    public readonly hasLegs: boolean;

    public constructor(
        originalId: number,
        id: string,
        type: TripPointType,
        geocode: Geocode,
        driverGeocode: Geocode | undefined,
        address: string,
        expectedArrivalDateTime: DateTime | undefined,
        expectedDepartureDateTime: DateTime | undefined,
        actualArrivalDateTime: DateTime | undefined,
        desiredDateTimePeriod: DateTimePeriod | undefined,
        isNew: boolean,
        isOwn?: boolean,
        hasLegs = true
    ) {
        this.originalId = originalId;
        this.id = id;
        this.type = type;
        this.geocode = geocode;
        this.driverGeocode = driverGeocode;
        this.address = address;
        this.expectedArrivalDateTime = expectedArrivalDateTime;
        this.expectedDepartureDateTime = expectedDepartureDateTime;
        this.actualArrivalDateTime = actualArrivalDateTime;
        this.desiredDateTimePeriod = desiredDateTimePeriod;
        this.isNew = isNew;
        this.isOwn = isOwn === undefined ? true : isOwn;
        this.hasLegs = hasLegs;
    }

    public hasArrivalDelay(): boolean {
        const actualArrivalDateTime = this.actualArrivalDateTime;
        const expectedArrivalDateTime = this.expectedArrivalDateTime;

        return actualArrivalDateTime === undefined || expectedArrivalDateTime === undefined
            ? false
            : expectedArrivalDateTime.compareTo(actualArrivalDateTime) === -1;
    }

    public isArrivalNotInTimeWindow(): boolean {
        return this.expectedArrivalDateTime !== undefined && this.desiredDateTimePeriod !== undefined
            && (this.expectedArrivalDateTime.toDate() < this.desiredDateTimePeriod.start.toDate()
                || this.expectedArrivalDateTime.toDate() > this.desiredDateTimePeriod.end.toDate());
    }
}
