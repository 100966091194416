import React from 'react';
import moment from 'moment';
import ru_RU from 'antd/es/locale/ru_RU';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    Button,
    Checkbox,
    ConfigProvider,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Switch,
    TimePicker,
} from 'antd';
import { AppConstants } from '@/AppConstants';
import { CompanyApi } from '@/Backend/Api/Company/CompanyApi';
import {
    dateTimeSerializer
} from '@/Backend/Api/Mapper/DateTimeSerializer';
import { ArrayUtils } from '@/Std/ArrayUtils';
import { DateTime } from '@/Std/DateTime';
import {
    AverageOrderCountPerHourEdit,
    PeriodWithValue,
} from './AverageOrderCountPerHourEdit';
import FormDynamicList from './FormDynamicList';

export interface SettingsEditProps {
    companyName: string | undefined;
    onSave: (companyName: string, proficiencyCoefficient: number) => void;
    proficiencyCoefficient: number | undefined;
}

const SettingsEdit = (props: SettingsEditProps): JSX.Element => {
    const timeFormat = 'HH:mm';

    const [form] = Form.useForm();
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [visibilityDriverLateReport, setVisibilityDriverLateReport] = React.useState(false);
    const [driverFailOrderReportEnabled, setDriverFailOrderReportEnabled] = React.useState(false);
    const [driverRescheduleOrderReportEnabled, setDriverRescheduleOrderReportEnabled] = React.useState(false);
    const [driverDisallowedFailOrder, setDriverDisallowedFailOrder] = React.useState(false);
    const [visibilitySmsSettings, setVisibilitySmsSettings] = React.useState(false);
    const [averageOrdersCount, setAverageOrdersCount] = React.useState<PeriodWithValue[]>([]);

    React.useEffect(() => {
        setLoading(true);
        Promise.all([
            CompanyApi.getSettings(), CompanyApi.getReportSettings()
        ]).then(response => {
            setVisibilityDriverLateReport(response[1].isDriversBeingLateReportEnabled)
            setDriverFailOrderReportEnabled(response[1].isDriverFailOrderReportEnabled)
            setDriverRescheduleOrderReportEnabled(response[1].isDriverRescheduleOrderReportEnabled)
            setDriverDisallowedFailOrder(response[0].driverDisallowedFailOrder)
            setVisibilitySmsSettings(response[0].useNotificationSettings)
            setAverageOrdersCount(ArrayUtils.sort(response[0].averageOrdersCountPerHour, x => x.endTime).map(x=>({
                endTime: DateTime.fromDate(moment(x.endTime).toDate()),
                averageCount: x.value
            })))
            form.setFieldsValue({
                'driverLateReportSendingTime': moment(response[1].driversBeingLateReportTime),
                'driverLateReportRecipients': response[1].driversBeingLateReportEmails,
                'isDriversLateReportEnabled': response[1].isDriversBeingLateReportEnabled,
                'driverFailOrderReportRecipients': response[1].driverFailOrderReportEmails,
                'driverRescheduleOrderReportRecipients': response[1].driverRescheduleOrderReportEmails,
                'drivingNotificationPattern': response[0].drivingNotificationPattern,
                'timeNotificationPattern': response[0].timeNotificationPattern,
                'orderChangedNotificationPattern': response[0].orderChangedNotificationPattern,
                'orderRescheduledNotificationPattern': response[0].orderRescheduledNotificationPattern,
                'orderRescheduledNobodyHomePattern': response[0].orderRescheduledBecauseNobodyHereNotificationPattern,
                'orderRescheduledNoRoadPattern': response[0].orderRescheduledBecauseInabilityToPassNotificationPattern,
                'useNotificationSettings': response[0].useNotificationSettings,
                'isNeedSmsBeforeStartTrip': response[0].drivingNotificationEnabled,
                'isNeedSmsAfterDoneOrder': response[0].orderChangedNotificationEnabled,
                'isNeedSmsRescheduleOrder': response[0].orderRescheduledNotificationEnabled,
                'isNeedMassSms': response[0].timeNotificationEnabled,
                'isDriverFinishTripManually': response[0].tripFinishManually,
                'allowedEditTripAfterStart': response[0].tripAllowEditAfterStart,
                'isNewOrderToEndTrip': response[0].tripInsertNewOrdersInTheEnd,
                'timeCloseEditingTrip': moment(response[0].timeCloseEditingTrip),
                'driverDisallowedTransferOrder': response[0].driverDisallowedTransferOrder,
                'driverDisallowedRescheduleOrder': response[0].driverDisallowedRescheduleOrder
            });
        });
        setLoading(false);
    }, []);

    const onFinish = (values: any) => {
        const error = checkErrorFrom();
        if (error) {
            Modal.error({
                content: error,
            });

            return;
        }
        setSaving(true);
        Promise.all([
            CompanyApi.updateMyCompany({
                cityName: '',
                name: '',
                proficiencyCoefficient: values.proficiencyCoefficient,
                buildRoutesOnApprove: false,
                centerLatitude: 0,
                centerLongitude: 0,
                alternativePlanAllowedToUse: false,
            }),
            CompanyApi.putReportSettings(
                values.isDriversLateReportEnabled,
                values.driverLateReportSendingTime,
                values.driverLateReportRecipients,
                driverFailOrderReportEnabled,
                values.driverFailOrderReportRecipients,
                driverRescheduleOrderReportEnabled,
                values.driverRescheduleOrderReportRecipients
            ),
            CompanyApi.putSettings({
                drivingNotificationPattern: values.drivingNotificationPattern,
                timeNotificationPattern: values.timeNotificationPattern,
                orderChangedNotificationPattern: values.orderChangedNotificationPattern,
                orderRescheduledBecauseInabilityToPassNotificationPattern: values.orderRescheduledNoRoadPattern,
                orderRescheduledBecauseNobodyHereNotificationPattern: values.orderRescheduledNobodyHomePattern,
                useNotificationSettings: values.useNotificationSettings,
                drivingNotificationEnabled: values.isNeedSmsBeforeStartTrip,
                timeNotificationEnabled: values.isNeedMassSms,
                orderChangedNotificationEnabled: values.isNeedSmsAfterDoneOrder,
                tripAllowEditAfterStart: values.allowedEditTripAfterStart,
                tripInsertNewOrdersInTheEnd: values.isNewOrderToEndTrip,
                tripFinishManually: values.isDriverFinishTripManually,
                orderRescheduledNotificationEnabled: values.isNeedSmsRescheduleOrder,
                orderRescheduledNotificationPattern: values.orderRescheduledNotificationPattern,
                timeCloseEditingTrip: values.timeCloseEditingTrip
                    ? values.timeCloseEditingTrip.toDate().toISOString()
                    : undefined,
                averageOrdersCountPerHour: averageOrdersCount.map(x=> ({
                    value: x.averageCount,
                    endTime: dateTimeSerializer.serialize(x.endTime)
                })),
                driverDisallowedFailOrder: driverDisallowedFailOrder,
                driverDisallowedTransferOrder: values.driverDisallowedTransferOrder,
                driverDisallowedRescheduleOrder: values.driverDisallowedRescheduleOrder
            })
        ]).then(() => {
            setSaving(false);
            Modal.success({
                content: 'Изменения сохранены',
                onOk: () => window.location.reload(),
                onCancel: () => window.location.reload()
            });
        });
    };

    const checkErrorFrom = (): string | null => {
        if (form.getFieldValue('isDriversLateReportEnabled') && (
            form.getFieldValue('driverLateReportSendingTime') === null ||
            form.getFieldValue('driverLateReportRecipients').length === 0 ||
            form.getFieldValue('driverLateReportRecipients')[0].trim() === '')) {
            return 'Нужно указать время отправки отчета об опозданиях водителя и минимум один email';
        }
        if (form.getFieldValue('isNeedMassSms') && form.getFieldValue('allowedEditTripAfterStart')) {
            return 'Позволять редактировать после старта и Массовая рассылка SMS не могут быть включены одновременно';
        }
        if (driverFailOrderReportEnabled && (
            form.getFieldValue('driverFailOrderReportRecipients').length === 0 ||
            form.getFieldValue('driverFailOrderReportRecipients')[0].trim() === ''
        )) {
            return 'Добавьте минимум один email для получения отчета об отмене заказа водителем';
        }
        if (driverRescheduleOrderReportEnabled && (
            form.getFieldValue('driverRescheduleOrderReportRecipients').length === 0 ||
            form.getFieldValue('driverRescheduleOrderReportRecipients')[0].trim() === ''
        )) {
            return 'Добавьте минимум один email для получения отчета о переносе заказа водителем';
        }

        return null;
    }
    
    const toggleDriverDisallowedFailOrder = (e: CheckboxChangeEvent) => {
        const value = e.target.checked;
        setDriverDisallowedFailOrder(value)
        if (value) {
            setDriverFailOrderReportEnabled(false);
        }
    }

    return (
        <Form
            form={form}
            size='small'
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            style={{ maxWidth: 600 }}
            labelAlign="left"
            layout='horizontal'
            onFinish={onFinish}
            initialValues={{
                proficiencyCoefficient: props.proficiencyCoefficient
            }}
        >
            <ConfigProvider locale={ru_RU}>
                <Divider orientation='center'><b>Компания</b></Divider>
                <Form.Item label='Название компании' >
                    <Input defaultValue={props.companyName} disabled={true} />
                </Form.Item>
                <Form.Item label='Коэффициент движения' name='proficiencyCoefficient'>
                    <InputNumber
                        min={AppConstants.minProficiencyCoefficient}
                        max={AppConstants.maxProficiencyCoefficient}
                        step={AppConstants.coefficientEditStep}
                    />
                </Form.Item>

                <Divider orientation='center'><b>SMS</b></Divider>
                <h4><b><i>Шаблоны SMS</i></b></h4>
                <Form.Item
                    label='массовая рассылка SMS'
                    name='timeNotificationPattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label='перед доставкой'
                    name='drivingNotificationPattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label='после доставки'
                    name='orderChangedNotificationPattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label='при переносе заказа'
                    name='orderRescheduledNotificationPattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label='перенос, если никого нет дома'
                    name='orderRescheduledNobodyHomePattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    label='перенос, если невозможно проехать'
                    name='orderRescheduledNoRoadPattern'
                    labelCol={{span: 11, offset: 1 }}
                    wrapperCol={{span: 13}}
                >
                    <Input disabled={true} />
                </Form.Item>

                <Form.Item label='Включить отправку SMS водителям' name='useNotificationSettings' valuePropName='checked'>
                    <Switch checkedChildren='Да' unCheckedChildren='Нет' onChange={setVisibilitySmsSettings} />
                </Form.Item>
                <Form.Item
                    label='Отправлять SMS перед доставкой'
                    name='isNeedSmsBeforeStartTrip'
                    valuePropName='checked'
                    hidden={!visibilitySmsSettings}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label='Отправлять SMS после доставки'
                    name='isNeedSmsAfterDoneOrder' valuePropName='checked'
                    hidden={!visibilitySmsSettings}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label='Отправлять SMS при переносе заказа'
                    name='isNeedSmsRescheduleOrder' valuePropName='checked'
                    hidden={!visibilitySmsSettings}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label='Отправлять массовую рассылку SMS'
                    name='isNeedMassSms' valuePropName='checked'
                    hidden={!visibilitySmsSettings}
                >
                    <Checkbox />
                </Form.Item>

                <Divider orientation='center'><b>Среднее количество заказов в час</b></Divider>
                <Form.Item label='Выбор количества заказов в час'>
                    <AverageOrderCountPerHourEdit
                        loading={loading}
                        values={averageOrdersCount}
                        setValues={setAverageOrdersCount}
                    />
                </Form.Item>

                <Divider orientation='center'><b>Маршрут</b></Divider>
                <Form.Item label='Водитель может редактировать заказы до' name='timeCloseEditingTrip'>
                    <TimePicker format={timeFormat}/>
                </Form.Item>
                <Form.Item label='Водитель завершает маршрут вручную' name='isDriverFinishTripManually' valuePropName='checked'>
                    <Checkbox />
                </Form.Item>
                <Form.Item label='Позволять редактировать после старта' name='allowedEditTripAfterStart' valuePropName='checked' hidden>
                    <Checkbox />
                </Form.Item>
                <Form.Item label='Запретить водителю отменять заказы'>
                    <Checkbox checked={driverDisallowedFailOrder} onChange={toggleDriverDisallowedFailOrder} />
                </Form.Item>
                <Form.Item
                    label='Запретить водителю передавать заказы'
                    tooltip={'другим водителям'}
                    name='driverDisallowedTransferOrder'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    label='Запретить водителю переносить заказы'
                    tooltip={'на другие даты'}
                    name='driverDisallowedRescheduleOrder'
                    valuePropName='checked'
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label='Добавление нового заказа в конец маршрута' name='isNewOrderToEndTrip' valuePropName='checked' hidden>
                    <Checkbox />
                </Form.Item>

                <Divider orientation='center'><b>Отчеты</b></Divider>
                <Form.Item label='Отчет об отмене заказа'>
                    <Switch
                        checkedChildren='Да'
                        unCheckedChildren='Нет'
                        checked={driverFailOrderReportEnabled}
                        onChange={setDriverFailOrderReportEnabled}
                        disabled={driverDisallowedFailOrder}
                    />
                </Form.Item>
                <Form.Item label='Email получателей' hidden={!driverFailOrderReportEnabled}>
                    <FormDynamicList formName='driverFailOrderReportRecipients' />
                </Form.Item>

                <Form.Item label='Отчет о переносе заказа'>
                    <Switch
                        checkedChildren='Да'
                        unCheckedChildren='Нет'
                        checked={driverRescheduleOrderReportEnabled}
                        onChange={setDriverRescheduleOrderReportEnabled}
                    />
                </Form.Item>
                <Form.Item label='Email получателей' hidden={!driverRescheduleOrderReportEnabled}>
                    <FormDynamicList formName='driverRescheduleOrderReportRecipients' />
                </Form.Item>

                <Form.Item label='Excel отчет об опозданиях водителей' name='isDriversLateReportEnabled' valuePropName='checked'>
                    <Switch checkedChildren='Да' unCheckedChildren='Нет' onChange={setVisibilityDriverLateReport} />
                </Form.Item>

                <Form.Item label='Время отправки отчета' name='driverLateReportSendingTime' hidden={!visibilityDriverLateReport}>
                    <TimePicker format={timeFormat} />
                </Form.Item>
                <Form.Item label='Email получателей' hidden={!visibilityDriverLateReport}>
                    <FormDynamicList formName='driverLateReportRecipients' />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 20, span: 24 }}>
                    <Button type='primary' htmlType='submit' loading={saving}>
                        Сохранить
                    </Button>
                </Form.Item>
            </ConfigProvider>
        </Form>
    );
};

export default SettingsEdit;