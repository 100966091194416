import React from 'react';

export const useHorizontalScroll = (): React.MutableRefObject<any> => {
    const elRef = React.useRef<any>();

    React.useEffect(() => {
        const el = elRef.current;

        if (el) {
            const onWheel = (e: WheelEvent) => {
                if (e.deltaY === 0) {
                    return;
                }
                e.preventDefault();
                el.scrollTo({
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    left: el.scrollLeft + e.deltaY,
                    behavior: 'smooth',
                });
            };
            el.addEventListener('wheel', onWheel);

            return () => el.removeEventListener('wheel', onWheel);
        }
    }, []);

    return elRef;
};
