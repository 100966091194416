import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { District } from '../District/District';
import { Lunch } from '../Lunch/Lunch';
import { Order } from '../Order/Order';
import { Store } from '../Store/Store';
import { Trip } from '../Trip/Trip';
import { TripExecutor } from '../TripExecutor/TripExecutor';

export class TripPlan {
    public readonly dateTimePeriod: DateTimePeriod;
    public readonly isDayOff: boolean;
    public readonly executors: TripExecutor[];
    public readonly id: number;
    public readonly orders: ReadonlyArray<Order>;
    public readonly stores: ReadonlyArray<Store>;
    public readonly districts: ReadonlyArray<District>;
    public readonly lunches?: ReadonlyArray<Lunch>;
    public readonly alternativePlanId?: number;
    public readonly departPeriods: string[];
    private _trips: Trip[];

    public constructor(
        id: number,
        isDayOff: boolean,
        dateTimePeriod: DateTimePeriod,
        orders: ReadonlyArray<Order>,
        trips: ReadonlyArray<Trip>,
        stores: ReadonlyArray<Store>,
        districts: ReadonlyArray<District>,
        lunches?: ReadonlyArray<Lunch>,
        alternativePlanId?: number,
        departPeriods?: string[]
    ) {
        this.id = id;
        this.isDayOff = isDayOff;
        this.dateTimePeriod = dateTimePeriod;
        this.orders = orders;
        this.stores = stores;
        this.districts = districts;
        this.lunches = lunches;
        this.alternativePlanId = alternativePlanId;

        const sortedTrips = trips
            .slice()
            .sort((trip1, trip2) => trip1.compareTrips(trip2));
        this._trips = sortedTrips;
        this.executors = sortedTrips.map((trip) => trip.executor);

        this.departPeriods = departPeriods ? departPeriods : [];
    }

    public get trips(): ReadonlyArray<Trip> {
        return this._trips;
    }

    public setTrips(trips: Trip[]) {
        this._trips = trips;
    }
}
