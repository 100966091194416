import { UserRole } from './UserRole';

export class User {
    public role: UserRole;
    public token: string;
    public username: string;

    public constructor(userName: string, token: string, role: UserRole) {
        this.username = userName;
        this.token = token;
        this.role = role;
    }
}
