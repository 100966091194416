import ColorLib from 'color';

import { MathUtils } from '../MathUtils';

export class Color {
    public get rgbaString(): string {
        const alpha = Math.round(this.value.alpha() * Color.maxHexadecimalValue);

        return `${this.value.hex().toLowerCase()}${
            alpha === Color.maxHexadecimalValue
                ? ''
                : MathUtils.numberToHexString(alpha, Color.hexadecimalComponentLength)
        }`;
    }

    public static rgba(value: string): Color {
        return Color.fromColorLibObject(ColorLib(value));
    }

    private static readonly hexadecimalComponentLength = 2;
    private static readonly maxHexadecimalValue = 255;

    private static fromColorLibObject(value: ColorLib): Color {
        return new Color(value.red(), value.green(), value.blue(), value.alpha());
    }

    public readonly value: ColorLib;

    public constructor(red: number, green: number, blue: number, alpha: number = 1) {
        this.value = ColorLib({ r: red, g: green, b: blue }).alpha(alpha);
    }

    public darken(value: number): Color {
        return Color.fromColorLibObject(this.value.darken(value));
    }

    public setAlpha(value: number): Color {
        return new Color(this.value.red(), this.value.green(), this.value.blue(), value);
    }
}
