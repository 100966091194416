import { DateTime } from "../../Std/DateTime";
import { Geocode } from "../Geocode/Geocode";

export class Waypoint {
    public sourceIndex: number;
    public destinationIndex: number;
    public duration: number;
    public distance: number;
    public arrivalTime: DateTime;
    public departureTime: DateTime;
    public geocodes: Geocode[];

    public constructor(
        sourceIndex: number,
        destinationIndex: number,
        duration: number,
        distance: number,
        arrivalTime: DateTime,
        departureTime: DateTime,
        geocodes: Geocode[]
    ) {
        this.sourceIndex = sourceIndex;
        this.destinationIndex = destinationIndex;
        this.duration = duration;
        this.distance = distance;
        this.arrivalTime = arrivalTime;
        this.departureTime = departureTime;
        this.geocodes = geocodes;
    }
}