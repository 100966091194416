import { IGeocode } from '../Model/Geocode/IGeocode';

export const isPointInsideShape = (point: IGeocode, shape: IGeocode[]): boolean => {
    let inside = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
        const xi = shape[i].latitude;
        const yi = shape[i].longitude;
        const xj = shape[j].latitude;
        const yj = shape[j].longitude;

        const intersect =
            yi > point.longitude !== yj > point.longitude &&
            point.latitude < ((xj - xi) * (point.longitude - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

export const distanceBetweenCoords = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
    const startingLat = degreesToRadians(lat1);
    const startingLong = degreesToRadians(lng1);
    const destinationLat = degreesToRadians(lat2);
    const destinationLong = degreesToRadians(lng2);

    const radiusEarth = 6571000;

    return (
        Math.acos(
            Math.sin(startingLat) * Math.sin(destinationLat) +
                Math.cos(startingLat) * Math.cos(destinationLat) * Math.cos(startingLong - destinationLong),
        ) * radiusEarth
    );
};
