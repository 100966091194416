import React from 'react';
import {
    TripComparisonOrder
} from '../../../Model/TripComparison/TripComparisonOrder';
import {
    FormattedTimePeriod
} from '../../RouteInfo/RouteInfoFormattedValues/FormattedTimePeriod';
import styles from './Order.module.css'

interface OrderProps {
    order: TripComparisonOrder;
    isSelected: boolean;
    hasDriverPoint?: boolean;
    onClick: () => void;
}

const Order = (props: OrderProps) => (
    <div
        className={`
            ${styles.order} 
            ${props.hasDriverPoint ? styles.confirmed : ''}
            ${props.isSelected ? styles.selected : ''} 
        `}
        onClick={props.onClick}
    >
        <div className={styles.clientName}>{props.order.clientName}</div>
        <div className={styles.period}>
            <FormattedTimePeriod dateTimePeriod={props.order.dateTimePeriod}/>
        </div>
        <hr />
        <div className={styles.address}>{props.order.shipmentCount} бут., {props.order.address}</div>
    </div>
)

export default Order;