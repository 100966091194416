export class Product {
    public readonly code: string;
    public readonly id: number;
    public readonly name: string;
    public readonly volume: number;
    public readonly addable: boolean;
    public readonly isMainBottle: boolean;
    public readonly isDefaultBottle: boolean;
    public readonly isEmptyBottle: boolean;
    public readonly isActive: boolean;

    public constructor(
        id: number,
        code: string,
        name: string,
        volume: number,
        addable: boolean,
        isMainBottle: boolean,
        isDefaultBottle: boolean,
        isEmptyBottle: boolean,
        isActive: boolean) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.volume = volume;
        this.addable = addable;
        this.isMainBottle = isMainBottle;
        this.isDefaultBottle = isDefaultBottle;
        this.isEmptyBottle = isEmptyBottle;
        this.isActive = isActive;
    }
}
