import {
    DeliveryApiPredictionResponse
} from '../../../../../ApiClient/Yoso/models';
import { Prediction } from '../../../../../Model/Prediction/Prediction';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import {
    predictedOrderDeserializer
} from '../../Model/PredictedOrder/PredictedOrderDeserializer';
import {
    trainingOrderDeserializer
} from '../../Model/TrainingOrder/TrainingOrderDeserializer';

export class GetPredictionsDeserializer {
    public deserialize(response: DeliveryApiPredictionResponse): Prediction {
        return ({
            id: response.id,
            createdAt: dateTimeDeserializer.deserialize(response.createdAt),
            bias: response.bias,
            coefficient: response.coefficient,
            xOrigin: response.xOrigin,
            trainingOrders: response.trainingOrders.map(i => trainingOrderDeserializer.deserialize(i)),
            predictedOrders: response.predictedOrders.map(i => predictedOrderDeserializer.deserialize(i))
        })
    }
}

export const getPredictionsDeserializer = new GetPredictionsDeserializer();