import { Geocode } from '../../../Model/Geocode/Geocode';

export class Leg {
    public readonly distance: number;
    public readonly id: string;
    public readonly waypoints: ReadonlyArray<Geocode>;

    public constructor(id: string, waypoints: ReadonlyArray<Geocode>, distance: number) {
        this.id = id;
        this.waypoints = waypoints;
        this.distance = distance;
    }
}
