import * as L from 'leaflet';

import { IMapRendererMarker } from '../IMapRendererMarker';
import { IMapRendererMarkerTooltip } from '../IMapRendererMarkerTooltip';
import { RendererConstants } from '../RendererConstants';

export class LeafletMarker implements IMapRendererMarker {
    public readonly marker: L.Marker;

    private map: L.Map | undefined;
    private readonly onClickHandlers: Array<() => void> = [];

    public constructor(
        latitude: number,
        longitude: number,
        iconElement: string | undefined,
        width: number,
        height: number,
        iconPinX: number,
        iconPinY: number,
        className: string,
        clickable: boolean,
        style: string | undefined,
        tooltip: IMapRendererMarkerTooltip | undefined,
    ) {
        this.marker = L.marker([latitude, longitude], {
            interactive: clickable,
        });

        if (tooltip !== undefined) {
            this.marker.bindTooltip(tooltip.text, {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                offset: [20, 6],
                direction: 'right',
                permanent: tooltip.isPermanent === undefined ? false : tooltip.isPermanent,
                className: tooltip.className,
                interactive: tooltip.isInteractive,
            });
        }

        if (iconElement !== undefined) {
            this.setIcon(iconElement, width, height, iconPinX, iconPinY, className, style);
        }

        this.marker.on('click', () => {
            for (const handler of this.onClickHandlers) {
                handler();
            }
        });
    }

    public get latitude(): number {
        return this.marker.getLatLng().lat;
    }

    public get longitude(): number {
        return this.marker.getLatLng().lng;
    }

    public addTo(map: L.Map): void {
        if (this.map !== undefined) {
            return;
        }

        this.map = map;
        this.marker.addTo(this.map);
    }

    public onClick(handler: () => void): void {
        this.onClickHandlers.push(handler);
    }

    public remove(): void {
        if (this.map === undefined) {
            return;
        }

        this.onClickHandlers.length = 0;
        this.marker.remove();
        this.map = undefined;
    }

    public setForeground(foreground: boolean): void {
        this.marker.setZIndexOffset(foreground ? RendererConstants.foregroundZIndex : 0);
    }

    public setIcon(
        elementHtml: string | undefined,
        width: number,
        height: number,
        pinX: number,
        pinY: number,
        className: string,
        style: string | undefined,
    ): void {
        this.marker.setIcon(
            L.divIcon({
                className,
                iconAnchor: [pinX, pinY],
                iconSize: [width, height],
                html:
                    elementHtml === undefined
                        ? ''
                        : `<div style="${style ?? ''}">
                    ${elementHtml}
                </div>`,
            }),
        );
    }

    public setOpacity(opacity: number): void {
        this.marker.setOpacity(opacity);
    }
}
