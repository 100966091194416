import React from 'react';
import {TripWorkingShift} from '@/Model/Trip/TripWorkingShift';
import {ReactComponent as MoonImageComponent} from '@/icons/moon.svg';
import {ReactComponent as SunImageComponent} from '@/icons/sun.svg';
import styles from './WorkingShiftIcon.module.css';

interface WorkingShiftIconProps {
    workingShift: TripWorkingShift | undefined;
}

export const WorkingShiftIcon = (props: WorkingShiftIconProps): JSX.Element => {
    switch (props.workingShift) {
        case undefined:
        case TripWorkingShift.MORNING:
            return <SunImageComponent className={styles.sun} />
        case TripWorkingShift.EVENING:
            return <MoonImageComponent className={styles.moon} />
        case TripWorkingShift.DAYOFF:
        default: return <></>;
    }
};
