import {
    CompanyApiStoresResponse
} from "../../../../../ApiClient/YosoCompany/models";
import {Geocode} from "../../../../../Model/Geocode/Geocode";
import {Warehouse} from "../../../../../Model/Store/Warehouse";

export class GetCompanyStoresResponseDeserializer {
    public deserialize(response: CompanyApiStoresResponse): Warehouse[] {
        return response.stores.map(store =>
            new Warehouse(
                store.id!,
                new Geocode({
                    latitude: store.latitude,
                    longitude: store.longitude
                }),
                store.address,
                store.main,
                store.deleted
            )
        )
    }
}

export const getCompanyStoresResponseDeserializer = new GetCompanyStoresResponseDeserializer();
