import { DeliveryApiDraftDriver } from '../../../../../ApiClient/Yoso/models';
import { Driver } from '../../../../../Model/Driver/Driver';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class DraftDriverSerializer implements IApiModelSerializer<Driver, DeliveryApiDraftDriver> {
    public serialize(obj: Driver): DeliveryApiDraftDriver {
        return {
            experienceCoefficient: obj.proficiency ?? 1,
            id: obj.id,
            name: obj.name,
            code: obj.code,
            enableDeliveryPrediction: obj.enableDeliveryPrediction
        };
    }
}

export const draftDriverSerializer = new DraftDriverSerializer();
