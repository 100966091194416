import {EntityType} from '../../../../../ApiClient/Yoso/models';
import {
    DriverPointType
} from '../../../../../Model/DriverPoint/DriverPointType';

export class DriverPointTypeSerializer {
    public serialize(value: DriverPointType): EntityType {
        switch (value) {
            case DriverPointType.Store:
                return EntityType.STORE;
            case DriverPointType.DriverPoint:
                return EntityType.DRIVERPOINT;
            default:
                throw new Error('unknown entity type')
        }
    }
}

export const driverPointTypeSerializer = new DriverPointTypeSerializer();