import { DateTime } from "../../Std/DateTime";
import { ItineraryEmptyBottle } from "./ItineraryEmptyBottle";
import { ItineraryProductNote } from "./ItineraryProductNote";

export class ItineraryOrder {
    public orderId: number;
    public clientName: string;
    public address: string;
    public additionalPhoneNumbers: string;
    public bottlesCount: number;
    public emptyBottlesCount: ItineraryEmptyBottle[];
    public intervalBegin: DateTime;
    public intervalEnd: DateTime;
    public isCorporate: boolean;
    public paymentMethod: string;
    public comment?: string;
    public shipmentNotes: ItineraryProductNote[];
    public returnNotes: ItineraryProductNote[];
    public paid?: number;
    public isDone: boolean | undefined;
    public isActive: boolean;
    public isQR: boolean;
    public isTerminal?: boolean;
    public price: number;

    public constructor(
        orderId: number,
        clientName: string,
        address: string,
        additionalPhoneNumbers: string,
        bottlesCount: number,
        emptyBottlesCount: ItineraryEmptyBottle[],
        intervalBegin: DateTime,
        intervalEnd: DateTime,
        isCorporate: boolean,
        paymentMethod: string,
        shipmentNotes: ItineraryProductNote[],
        returnNotes: ItineraryProductNote[],
        isDone: boolean | undefined,
        isActive: boolean,
        isQR: boolean,
        price: number,
        paid?: number,
        comment?: string,
        isTerminal?: boolean,
    ) {
        this.orderId = orderId;
        this.clientName = clientName;
        this.address = address;
        this.additionalPhoneNumbers = additionalPhoneNumbers;
        this.bottlesCount = bottlesCount;
        this.emptyBottlesCount = emptyBottlesCount;
        this.intervalBegin = intervalBegin;
        this.intervalEnd = intervalEnd;
        this.price = price;
        this.paid = paid;
        this.isCorporate = isCorporate;
        this.paymentMethod = paymentMethod;
        this.comment = comment;
        this.shipmentNotes = shipmentNotes;
        this.returnNotes = returnNotes;
        this.isDone = isDone;
        this.isActive = isActive;
        this.isQR = isQR;
        this.isTerminal = isTerminal;
    }
}