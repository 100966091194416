import { ApiItineraryProductNote, DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { ItineraryProductNote } from '../../../../../Model/Itinerary/ItineraryProductNote';

export class ProductNoteDeserializer {
    public deserialize(response: ApiItineraryProductNote, products: DeliveryApiProduct[]): ItineraryProductNote {
        return new ItineraryProductNote(
            response.productId,
            products.find(p => p.id === response.productId)?.name || "",
            response.quantity
        )
    }
}

export const productNoteDeserializer = new ProductNoteDeserializer();
