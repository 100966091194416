import React from 'react';
import { isInViewport } from '../../helpers/isInViewport/isInViewport';
import {Leg} from '../../Map/Route/Leg/Leg';
import {LunchRoutePoint} from '../../Map/Route/RoutePoint/LunchRoutePoint';
import {OrderRoutePoint} from '../../Map/Route/RoutePoint/OrderRoutePoint';
import {RoutePoint} from '../../Map/Route/RoutePoint/RoutePoint';
import {StartRoutePoint} from '../../Map/Route/RoutePoint/StartRoutePoint';
import {LunchStatusActual} from '../../Model/Lunch/LunchStatusActual';
import {OrderStatus} from '../../Model/Order/OrderStatus';
import {PointMarkers} from './PointMarkers';
import RoutePointContent from './RoutePointContent/RoutePointContent';

interface RouteInfoPointItemProps {
    point: RoutePoint;
    isFirst?: boolean;
    isLast?: boolean;
    isFirstScheduledOrder?: boolean;
    isSelected?: boolean;
    isClickable?: boolean;
    handleSelectPoint?: (e: React.MouseEvent<HTMLDivElement>) => void;
    handleSelectDistance?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onSelectDistance?: (leg: Leg) => void;
    distanceIsSelected?: boolean;
}

const RouteInfoPointItem = (props: RouteInfoPointItemProps): JSX.Element => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    let classname = 'route-info__item';
    classname += props.isSelected ? ' route-info__item_selected' : '';
    classname += props.isClickable ? ' route-info__item_clickable' : '';
    classname += props.point instanceof StartRoutePoint ? ' route-info__item_done' : '';
    classname += props.point instanceof LunchRoutePoint && props.point.lunch.status === LunchStatusActual.Done
        ? ' route-info__item_done' : '';
    classname += props.isFirstScheduledOrder ? ' route-info__item_first-scheduled-order' : '';
    classname += props.isFirst ? ' route-info__item_first' : '';
    classname += props.isLast ? ' route-info__item_last' : '';

    if (props.point instanceof OrderRoutePoint) {
        switch (props.point.order.status) {
            case OrderStatus.Done:
                classname += ' route-info__item_done';
                break;
            case OrderStatus.Moved:
                classname += ' route-info__item_moved';
                break;
            case OrderStatus.Failed:
                classname += ' route-info__item_failed';
                break;
            case OrderStatus.Rescheduled:
                classname += ' route-info__item_rescheduled';
                break;
            default:
                classname += '';
        }
    }

    React.useEffect(() => {
        if (!props.isSelected) {
            return undefined;
        }

        if (!ref.current) {
            return undefined;
        }

        if (!isInViewport(ref.current)) {
            ref.current?.scrollIntoView();
        }
    }, [props.isSelected])

    return (
        <div
            data-id={props.point.id}
            ref={ref}
            className={classname}
            onClick={props.handleSelectPoint}
        >
            <PointMarkers
                distanceIsSelected={props.distanceIsSelected}
                onDistanceClick={props.handleSelectDistance}
                point={props.point}
            />
            <div className='route-info__item-content'>
                <RoutePointContent point={props.point}/>
            </div>
        </div>
    )
};

export default RouteInfoPointItem;