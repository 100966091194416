import { observer } from 'mobx-react';
import React from 'react';
import {
    NoticeMailsStore
} from '../../../../Store/NoticeMailsStore/NoticeMailsStore';
import styles from './Mailbox.module.css'

interface SpinnerProps {
    store: NoticeMailsStore
}

const Spinner = observer((props: SpinnerProps) => {
    const {store} = props

    if (!store.loadingMails) {
        return null;
    }

    return (
        <div className={styles.spinner}>
            <div />
            <div />
            <div />
        </div>
    );
});

export default Spinner;