import {
    ApiTripPointId,
    DeliveryApiTripPointId,
    Type3
} from '../../../../../ApiClient/Yoso/models';
import {Store} from '../../../../../Model/Store/Store';
import {
    TripComparisonOrder
} from '../../../../../Model/TripComparison/TripComparisonOrder';
import {TripPointId} from '../../../../../Model/TripPointId/TripPointId';
import {tripPointTypeSerializer} from './TripPointTypeSerializer';

export class TripPointIdSerializer {
    public serialize(obj: TripPointId): ApiTripPointId {
        return {
            id: obj.id,
            type: tripPointTypeSerializer.serialize(obj.type),
        };
    }

    public serializeFromDriverRoute(obj: TripComparisonOrder | Store): DeliveryApiTripPointId {
        const type = obj instanceof TripComparisonOrder ? Type3.ORDER : Type3.LOADINGSTORE;

        return {
            id: obj.id,
            type: type
        }
    }
}

export const tripPointIdSerializer = new TripPointIdSerializer();
