import {DeliveryApiDriverPoint} from '../../../../../ApiClient/Yoso/models';
import {DriverPoint} from '../../../../../Model/DriverPoint/DriverPoint';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import {
    driverPointTypeDeserializer
} from '../../Model/DriverPointType/DriverPointTypeDeserializer';

export class DriverPointDeserializer {
    public deserialize(point: DeliveryApiDriverPoint): DriverPoint {
        return new DriverPoint(
            point.id,
            driverPointTypeDeserializer.deserialize(point.entityType),
            point.name,
            new Geocode({latitude: point.latitude, longitude: point.longitude})
        );
    }
}

export const driverPointDeserializer = new DriverPointDeserializer();