import { IGeocode } from '../../Model/Geocode/IGeocode';

export class DistrictOrder {
  public id: number;
  public address: string;
  public coords: IGeocode;
  public shipmentBottlesCount: number;
  public shipmentNonBottlesCount: number;

  public constructor(
    id: number,
    address: string,
    coords: IGeocode,
    shipmentBottlesCount: number,
    shipmentNonBottlesCount: number
  ) {
    this.id = id;
    this.address = address;
    this.coords = coords;
    this.shipmentBottlesCount = shipmentBottlesCount;
    this.shipmentNonBottlesCount = shipmentNonBottlesCount;
  }
}