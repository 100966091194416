import { Status1 } from '../../../../../ApiClient/Yoso/models';
import { OrderStatus } from '../../../../../Model/Order/OrderStatus';
import { Types } from '../../../../../Std/Types';

export class OrderStatusDeserializer {
    public deserialize(serializedObj: Status1 | string | undefined): OrderStatus | undefined {
        return serializedObj === Status1.CANCELLED
            ? OrderStatus.Cancelled
            : serializedObj === Status1.DONE
            ? OrderStatus.Done
            : serializedObj === Status1.FAILED
            ? OrderStatus.Failed
            : serializedObj === Status1.MOVED
            ? OrderStatus.Moved
            : serializedObj === Status1.NEW
            ? OrderStatus.New
            : serializedObj === Status1.NEWADDED
            ? OrderStatus.NewAdded
            : serializedObj === Status1.NEWASSIGNED
            ? OrderStatus.NewAssigned
            : serializedObj === Status1.SCHEDULED
            ? OrderStatus.Scheduled
            : serializedObj === Status1.SCHEDULEDADDED
            ? OrderStatus.ScheduledAdded
            : serializedObj === Status1.DELIVERING
            ? OrderStatus.Delivering
            : serializedObj === Status1.ASSIGNED
            ? OrderStatus.Assigned
            : serializedObj === Status1.ASSIGNEDADDED
            ? OrderStatus.AssignedAdded
            : serializedObj === Status1.RESCHEDULED
            ? OrderStatus.Rescheduled
            : undefined;
    }
}

export const orderStatusDeserializer = new OrderStatusDeserializer();
