import { DeliveryApiPlanInfo } from '../../../../../ApiClient/Yoso/models';
import { TripPlanSummary } from '../../../../../Model/TripPlan/TripPlanSummary';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class PlanInfoDeserializer {
    public deserialize(serializedObject: DeliveryApiPlanInfo): TripPlanSummary {
        const createdAt = serializedObject.createdAt !== undefined
            ? dateTimeDeserializer.deserialize(serializedObject.createdAt)
            : undefined;

        return new TripPlanSummary(
            serializedObject.id,
            dateTimePeriodDeserializer.deserialize(serializedObject.dateTimePeriod),
            serializedObject.orderCount,
            createdAt
        );
    }
}

export const planInfoDeserializer = new PlanInfoDeserializer();
