import { DeliveryPlanAnalysis } from '../../../../../ApiClient/Yoso/models';
import { TripAnalysisSummary } from '../../../../../Model/TripAnalysisSummary/TripAnalysisSummary';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { tripAnalysisSummaryDeserializer } from '../../Model/TripAnalysisSummary/TripAnalysisSummaryDeserializer';

export interface IGetPlanAnalysis {
    tripAnalyses: TripAnalysisSummary[];
}

export class GetPlanAnalysisDeserializer implements IResponseDeserializer<DeliveryPlanAnalysis, IGetPlanAnalysis> {
    public deserialize(response: DeliveryPlanAnalysis): IGetPlanAnalysis {
        return {
            tripAnalyses: response.tripAnalyses
                .map((tripAnalysisSummary) => tripAnalysisSummaryDeserializer.deserialize(tripAnalysisSummary))
                .sort((tripAnalysis1, tripAnalysis2) => tripAnalysis1.compareTrips(tripAnalysis2)),
        };
    }
}

export const getPlanAnalysisDeserializer = new GetPlanAnalysisDeserializer();
