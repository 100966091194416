import {
    StatsApiRatingStatistics
} from '../../../../ApiClient/YosoStats/models';
import {
    RatingStatistics
} from '../../../../Model/RatingStatistics/RatingStatistics';

export class RatingStatisticsDeserializer {
    public static deserialize(apiStats: StatsApiRatingStatistics): RatingStatistics {
        return {
            driverId: apiStats.driverId,
            driverCode: apiStats.driverCode,
            driverName: apiStats.driverName,
            rating: apiStats.rating,
            ratingScore: apiStats.ratingScore,
            doneOrdersRating: apiStats.doneOrdersRating,
            doneOrdersRatingScore: apiStats.doneOrdersRatingScore,
            uncompletedOrdersPercentage: apiStats.uncompletedOrdersPercentage,
            uncompletedOrdersScore: apiStats.uncompletedOrdersScore,
            employedDays: apiStats.employedDays,
            doneOrdersCount: apiStats.doneOrdersCount,
            doneAtAddressCount: apiStats.doneAtAddressCount,
            doneOrdersBottlesCount: apiStats.doneOrdersBottlesCount,
            doneOrdersNotBottlesCount: apiStats.doneOrdersNotBottlesCount,
            doneOrdersWithNotificationCount: apiStats.doneOrdersWithNotificationCount,
            doneOrdersInNotificationPeriodCount: apiStats.doneOrdersInNotificationPeriodCount,
            doneOrdersInNotificationPeriodScore: apiStats.doneOrdersInNotificationPeriodScore,
            receivedDoneOrdersCount: apiStats.receivedDoneOrdersCount,
            receivedDoneOrdersScore: apiStats.receivedDoneOrdersScore,
            timeNotificationShouldSendCount: apiStats.timeNotificationShouldSendCount,
            timeNotificationSentCount: apiStats.timeNotificationSentCount,
            noDriverPlaceOrdersCount: apiStats.noDriverPlaceOrdersCount,
            driverPlacesSetCount: apiStats.driverPlacesSetCount,
            suspectedDriverPlacesSetCount: apiStats.suspectedDriverPlacesSetCount,
            driverPlacesSetScore: apiStats.driverPlacesSetScore,
            failedOrdersCount: apiStats.failedOrdersCount,
            rescheduledOrdersCount: apiStats.rescheduledOrdersCount,
            totalScore: apiStats.totalScore,
            doneOrdersShipmentCount: apiStats.doneOrdersShipmentCount,
            notificationSendRatio: apiStats.notificationSendRatio,
            inNotificationPeriodRatio: apiStats.inNotificationPeriodRatio,
            driverPlaceSetRatio: apiStats.driverPlaceSetRatio,
            doneAtAddressRatio: apiStats.doneAtAddressRatio,
            suspectedDriverPlaceSetRatio: apiStats.suspectedDriverPlaceSetRatio
        }
    }
}