import React from 'react';
import { Button } from 'antd';
import L from 'leaflet';
import PopupOnMap from '../MapContainer/components/PopupOnMap';
import './ZoneMapEditor.css';

interface IZonePopupProps {
  position: L.LatLngExpression | undefined;
  onSave: () => Promise<void>;
  onDelete: () => Promise<void>;
}

const ZonePopup = (props: IZonePopupProps) => {
  const [popupPosition, setPopupPosition] = React.useState<L.LatLngExpression | undefined>(undefined);

  React.useEffect(() => {
    setPopupPosition(props.position);
  }, [props.position])

  const onSave = React.useCallback(async () => {
    setPopupPosition(undefined);
    await props.onSave();
  }, [props.onSave])

  return (
    <PopupOnMap position={popupPosition}>
      <div className='zone-popup__buttons'>
        <Button type="default" size="small" onClick={props.onDelete}>
          Удалить
        </Button>
        <Button type="primary" size="small" onClick={onSave}>
          Сохранить
        </Button>
      </div>
    </PopupOnMap>
  )
}

export default ZonePopup