import * as React from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../AppProviders/Providers/AuthProvider/AuthProvider';
import { LoginForm } from '../LoginForm/LoginForm';

import './UnauthenticatedApp.css';

export interface IUnauthenticatedAppProps {}

export const UnauthenticatedApp = (props: IUnauthenticatedAppProps): JSX.Element => {
    const authContext = useAuth();

    if (authContext.data.user !== undefined) {
        return <Redirect to="/" />;
    }

    return (
        <div className="unauthenticated-app">
            <div className="unauthenticated-app__header">YOSO</div>
            <div className="unauthenticated-app__login-form">
                <LoginForm />
            </div>
        </div>
    );
};
