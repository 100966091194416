import React from 'react';
import { ReactComponent as WalletIconComponent } from '../../icons/wallet.svg';
import { ReactComponent as OnlinePaymentIconComponent } from '../../icons/online-payment.svg';
import { ReactComponent as QRPaymentIconComponent } from '../../icons/qr-code.svg';
import { OrderPaymentMethod } from '../../Model/Order/OrderPaymentMethod';

interface PaymentMethodIconProps {
    method: string;
    classname?: string;
}

const PaymentMethodIcon = (props: PaymentMethodIconProps): JSX.Element => {
    switch (props.method.toUpperCase()) {
        case OrderPaymentMethod.INTERNET:
        case OrderPaymentMethod.SBERBANK:
            return <OnlinePaymentIconComponent className={props.classname} />
        case OrderPaymentMethod.QR:
            return <QRPaymentIconComponent className={props.classname} />
        default:
            return <WalletIconComponent className={props.classname} />
    }
}

export default PaymentMethodIcon;