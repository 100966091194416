import { TimeMatrixSource as ApiTimeMatrixSource } from "../../../../../ApiClient/Yoso/models";
import { TimeMatrixSource } from "./TimeMatrixSource";


export class TimeMatrixSourceDeserializer {
    public deserialize(value: ApiTimeMatrixSource): TimeMatrixSource {
        switch (value) {
            case ApiTimeMatrixSource.OSM:
                return TimeMatrixSource.OSM;
            case ApiTimeMatrixSource.YANDEX:
                return TimeMatrixSource.Yandex;
            case ApiTimeMatrixSource.TGIS:
                return TimeMatrixSource.TGis;
            default:
                return TimeMatrixSource.NONE;
        }
    }
}

export const timeMatrixSourceDeserializer = new TimeMatrixSourceDeserializer();