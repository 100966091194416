import { DateTime } from "../../Std/DateTime";
import { DateTimePeriod } from "../../Std/DateTimePeriod";
import { TripPointId } from "../TripPointId/TripPointId";
import { TripPointType } from "../TripPointId/TripPointType";

export class TripComparisonRouteSequenceItem extends TripPointId {
    public readonly shipmentChange: number;
    public readonly currentShipment: number;
    public readonly period: DateTimePeriod | undefined;

    public constructor(
        id: number,
        type: TripPointType,
        shipmentChange: number,
        currentShipment: number,
        period: DateTimePeriod | undefined
    ) {
        super(id, type);
        this.shipmentChange = shipmentChange;
        this.currentShipment = currentShipment;
        this.period = period;
    }
}