import { ApiItineraryBalance, DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { ItineraryBalance } from '../../../../../Model/Itinerary/ItineraryBalance';
import { balanceRowDeserializer } from './BalanceRowDeserializer';

export class BalanceDeserializer {
    public deserialize(response: ApiItineraryBalance, products: DeliveryApiProduct[]): ItineraryBalance {
        return new ItineraryBalance(
            response.paidAmount,
            response.cashPaidAmount,
            response.qrPaidAmount,
            response.terminalPaidAmount,
            response.unknownPaidAmount,
            response.rows.map(row => balanceRowDeserializer.deserialize(row, products))
        )
    }
}

export const balanceDeserializer = new BalanceDeserializer();
