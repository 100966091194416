import Icon from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import { ReactComponent as WarningIconComponent } from '../../icons/order-warning.svg';
import { Driver } from '../../Model/Driver/Driver';
import { TripExecutor } from '../../Model/TripExecutor/TripExecutor';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { ByIndexColorPicker } from '../../Std/ColorScheme/ByIndexColorPicker';
import { DriverLoginEditor } from '../DriverLoginEditor/DriverLoginEditor';
import { EntityList } from '../EntityList/EntityList';
import { LoadingInfo } from '../LoadingInfo/LoadingInfo';
import { MapColor } from '../Map/MapColor';
import { useTripPlanData } from '../TripPlanData/TripPlanDataProvider';

import './CarList.css';

export interface ICarListProps {
    className?: string;
    colorScheme?: ByIndexColorPicker<MapColor>;
    executors: ReadonlyArray<TripExecutor>;
    executorsLoadingInfo: TripExecutorsLoadingInfo | undefined;
    hidePagination?: boolean;
    hideUnknownDriver?: boolean;
    onClick?: (car: TripExecutor) => void;
    renderRowExtendedContent?: (executor: TripExecutor) => React.ReactNode;
    selectedExecutor?: TripExecutor | undefined;
}

export const CarList = (props: ICarListProps): JSX.Element => {
    const { applyDriverLogin } = useTripPlanData();

    const [driverToEditLogin, setDriverToEditLogin] = React.useState(undefined as Driver | undefined);

    const [sortedExecutors, setSortedExecutors] = React.useState([] as TripExecutor[]);

    const [driversWithMultipleCars, setDriversWithMultipleCars] = React.useState([] as string[]);

    React.useEffect(() => {
        setSortedExecutors([
            ...props.executors.filter((e) => e.driver.login === undefined),
            ...props.executors.filter((e) => e.driver.login !== undefined),
        ]);
    }, [setSortedExecutors, props, props.executors]);

    const needHideUnknownDriver = (): boolean => props.hideUnknownDriver !== undefined && props.hideUnknownDriver;

    React.useEffect(() => {
        const result: string[] = [];

        Object.entries(
            props.executors.reduce((groups: any, executor: TripExecutor) => {
                groups[executor.driver.name] = groups[executor.driver.name] || [];
                groups[executor.driver.name].push(executor.car.carNumber);

                return groups;
            }, {}),
        ).forEach((entry: any) => {
            const driver = entry[0];
            const cars = entry[1];
            if (cars.length > 1) {
                result.push(`${driver}: ${cars.join(', ')}`);
            }
        });

        if (result.length === 0) {
            setDriversWithMultipleCars([]);
        }

        setDriversWithMultipleCars(result);
    }, [setDriversWithMultipleCars, props, props.executors]);

    return (
        <div className={classNames('car-list', props.className)}>
            <EntityList<TripExecutor>
                small={true}
                data={sortedExecutors}
                actions={{
                    item: {
                        click:
                            props.onClick === undefined
                                ? undefined
                                : (id: number) => (): undefined => {
                                      if (props.onClick !== undefined) {
                                          const foundExecutor = sortedExecutors.find(
                                              (executor) => executor.getId() === id,
                                          );
                                          if (foundExecutor !== undefined) {
                                              props.onClick(foundExecutor);
                                          }

                                          return;
                                      }
                                  },
                    },
                }}
                selectedItems={props.selectedExecutor}
                id={(executor) => executor.getId()}
                rowColorMarker={
                    props.colorScheme === undefined
                        ? undefined
                        : (executor, index) => props.colorScheme!.renderColor(props.colorScheme!.get(index)).rgbaString
                }
                columns={[
                    {
                        key: 'carNumber',
                        render: (executor) => executor.car.carNumber,
                        stretch: true,
                    },
                    {
                        key: 'loading',
                        render: (executor) =>
                            props.executorsLoadingInfo === undefined ? undefined : (
                                <LoadingInfo
                                    actual={props.executorsLoadingInfo.get(executor) ?? 0}
                                    actualBottles={props.executorsLoadingInfo.getBottles(executor) ?? 0}
                                    total={executor.car.capacity}
                                />
                            ),
                    },
                    {
                        key: 'driver',
                        render: (executor) => (
                            <span className="car-list__driver-name">
                                {executor.driver.name}{' '}
                                {needHideUnknownDriver()
                                    ? ''
                                    : `(Логин: ${
                                          executor.driver.login === undefined ? 'не задан' : executor.driver.login
                                      })`}
                            </span>
                        ),
                        stretch: true,
                    },
                    {
                        key: 'proficiency',
                        render: (executor) => (
                            <>
                                <span className="car-list__coefficient-text">коэф.</span>
                                {executor.driver.proficiency === undefined || executor.driver.proficiency === 1
                                    ? '1,0'
                                    : String(executor.driver.proficiency).replace('.', ',')}
                            </>
                        ),
                        stretch: true,
                    },
                    {
                        key: 'unknownDriver',
                        render: (executor) => {
                            if (executor.driver.login !== undefined || needHideUnknownDriver()) {
                                return <div className="car-list__fake-button" />;
                            } else {
                                return (
                                    <Button
                                        className="car-list__warning-button"
                                        type="default"
                                        onClick={() => {
                                            setDriverToEditLogin(executor.driver);
                                        }}
                                    >
                                        <Icon title="Не зарегистрированный водитель" component={WarningIconComponent} />
                                    </Button>
                                );
                            }
                        },
                        stretch: false,
                    },
                ]}
                renderRowExtendedContent={props.renderRowExtendedContent}
                pageSize={props.hidePagination === true ? 0 : undefined}
            />
            {driverToEditLogin !== undefined && (
                <DriverLoginEditor
                    driverCode={driverToEditLogin.code}
                    driverName={driverToEditLogin.name}
                    driverLogin={driverToEditLogin.login}
                    onCancel={() => {
                        setDriverToEditLogin(undefined);
                    }}
                    onOk={(driverCode: string, login: string) => {
                        applyDriverLogin(driverCode, login);
                        setDriverToEditLogin(undefined);
                    }}
                />
            )}
        </div>
    );
};
