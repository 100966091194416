import React from 'react';
import { Icon } from '@ant-design/compatible';
import {
    ReactComponent as OrderWarningIconComponent,
} from '../../../icons/order-warning.svg';
import { DraftOrder } from '../../../Model/DraftOrder/DraftOrder';
import {
    DraftOrderErrorType,
} from '../../../Model/DraftOrder/DraftOrderErrorType';
import { TripExecutor } from '../../../Model/TripExecutor/TripExecutor';
import { ArrayUtils } from '../../../Std/ArrayUtils';
import { formatTimePeriod } from '../../../Std/FormatTimePeriod';
import { EntityList } from '../../EntityList/EntityList';
import {
    OrderOperationsComponent,
} from '../../OrderOperations/OrderOperationsComponent';
import { useTripPlanData } from '../TripPlanDataProvider';

import './TripPlanDataOrders.css';

interface ITripPlanDataOrdersProps {
    errorOrders: ReadonlyArray<DraftOrder>;
    needSelectFilter: boolean;
    orders: ReadonlyArray<DraftOrder>;
    selectedExecutor: TripExecutor | undefined;
    setSelectedExecutor: (executor: TripExecutor | undefined) => void;
}

export const TripPlanDataOrders = (props: ITripPlanDataOrdersProps): JSX.Element => {
    const context = useTripPlanData();
    const { onSelectOrder, setOrdersFilterSelectItems } = context;

    const [orders, setOrders] = React.useState([
        ...props.errorOrders,
        ...props.orders.filter((order) => !props.errorOrders.includes(order)),
    ]);

    React.useEffect(() => {
        setOrders([...props.errorOrders, ...props.orders.filter((order) => !props.errorOrders.includes(order))]);
    }, [props.orders, props.errorOrders]);

    const handleItemClick = React.useCallback(
        (id: number) => (): undefined => {
            onSelectOrder(orders.find((order) => order.id === id)!);

            return;
        },
        [orders, onSelectOrder],
    );

    React.useEffect(() => {
        setOrdersFilterSelectItems({
            issuedGeocode: [
                {
                    value: 'all',
                    title: 'Все заказы',
                },
                {
                    value: 'issued',
                    title: 'С проблемным геокодом',
                },
            ],
        });
    }, [setOrdersFilterSelectItems]);

    const searchFilter = React.useCallback(
        (order: DraftOrder, searchStringPart: string) =>
            order.places[0].address.toLowerCase().includes(searchStringPart.toLowerCase()) &&
            (props.selectedExecutor === undefined || props.selectedExecutor.getId() === order.executor!.getId()),
        [props.selectedExecutor],
    );

    const selectFilter = React.useCallback(
        (order: DraftOrder, selectKey: string, selectValue: string) =>
            (selectKey === 'issuedGeocode' && selectValue === 'issued' ? props.errorOrders.includes(order) : true) &&
            (props.selectedExecutor === undefined ||
                props.selectedExecutor.getId() === order.executor!.getId()),
        [props.selectedExecutor, props.errorOrders],
    );

    const errorTitle = (errors: Set<DraftOrderErrorType>) => {
        const errorMessages = {
            [DraftOrderErrorType.BadGeocode]: 'Требуется валидация геокода',
            [DraftOrderErrorType.BadInterval]: 'Требуется валидация интервала'
        };

        let title = '';
        errors.forEach(e => {
            title += `${errorMessages[e]}\n`;
        });

        return title;
    }

    return (
        <div className="trip-plan-data-orders">
            <EntityList<DraftOrder>
                data={orders}
                id={(order) => order.id}
                filter={{
                    additional: ArrayUtils.defined([
                        props.selectedExecutor === undefined
                            ? undefined
                            : {
                                  onClose: () => {
                                      props.setSelectedExecutor(undefined);
                                  },
                                  title: props.selectedExecutor.driver.name,
                              },
                    ]),
                    search: {
                        filter: searchFilter,
                        onSearch: context.handleOrdersSearch,
                        searchString: context.ordersSearchString,
                    },
                    select: !props.needSelectFilter
                        ? undefined
                        : {
                              items: context.ordersFilterSelectItems,
                              values: context.ordersFilterSelectValues,
                              onChange: context.handleOrdersFilterSelect,
                              filter: selectFilter,
                          },
                }}
                actions={{
                    item: {
                        click: handleItemClick,
                    },
                }}
                selectedItems={context.selectedOrder}
                columns={[
                    {
                        key: 'desiredTime',
                        noWrap: true,
                        render: (order) =>
                            order.desiredDateTimePeriod === undefined
                                ? undefined
                                : formatTimePeriod(order.desiredDateTimePeriod.getTimePeriod()),
                    },
                    {
                        key: 'address',
                        render: (order) => order.fullAddress,
                        stretch: true,
                    },
                    {
                        key: 'operations',
                        render: (order) => (
                            <div className="trip-plan-data-orders__order-icons-container">
                                <OrderOperationsComponent
                                    className="trip-plan-data-orders__order-operations"
                                    orderOperations={order.operations}
                                />
                                {props.errorOrders.includes(order) ? (
                                    <Icon
                                        className="trip-plan-data-orders__order-warning-icon"
                                        title={errorTitle(order.errors)}
                                        component={OrderWarningIconComponent}
                                    />
                                ) : undefined}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};
