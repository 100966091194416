import * as L from 'leaflet';

import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { IMapRendererPath } from '../IMapRendererPath';

export class LeafletPath implements IMapRendererPath {
    public readonly points: ReadonlyArray<IGeocode>;

    private map: L.Map | undefined;
    private readonly onClickHandlers: Array<() => void> = [];
    private readonly polyline: L.Polyline;
    private visibility = true;

    public constructor(points: ReadonlyArray<IGeocode>, color: string, width: number) {
        this.points = points;

        this.polyline = L.polyline(points.map((point: IGeocode) => [point.latitude, point.longitude]));

        this.setColor(color);
        this.setWidth(width);

        this.polyline.on('click', () => {
            for (const handler of this.onClickHandlers) {
                handler();
            }
        });
    }

    public addTo(map: L.Map): void {
        if (this.map !== undefined) {
            return;
        }

        this.map = map;
        this.polyline.addTo(this.map);
    }

    public onClick(handler: () => void): void {
        this.onClickHandlers.push(handler);
    }

    public remove(): void {
        if (this.map === undefined) {
            return;
        }

        this.onClickHandlers.length = 0;
        this.polyline.remove();
        this.map = undefined;
    }

    public setColor(color: string): void {
        this.polyline.setStyle({
            color,
        });
    }

    public setForeground(): void {
        if (this.map === undefined) {
            return;
        }

        this.polyline.removeFrom(this.map);
        this.polyline.addTo(this.map);
    }

    public setVisibility(visibility: boolean): void {
        if (this.map === undefined) {
            return;
        }

        if (visibility !== this.visibility) {
            if (visibility) {
                this.polyline.addTo(this.map);
            } else {
                this.polyline.removeFrom(this.map);
            }
            this.visibility = visibility;
        }
    }

    public setWidth(width: number): void {
        this.polyline.setStyle({
            weight: width,
        });
    }
}
