import { DateTime } from '../../Std/DateTime';
import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { Driver } from '../Driver/Driver';
import { IGeocode } from '../Geocode/IGeocode';
import { OrderOperations } from '../OrderOperations/OrderOperations';
import { DriverPlace } from '../Place/DriverPlace';
import { GeocodeQuality } from '../Place/GeocodeQuality';
import { Place } from '../Place/Place';
import { TripExecutor } from '../TripExecutor/TripExecutor';
import { DraftOrderErrorType } from './DraftOrderErrorType';

export class DraftOrder {
    public readonly additionalPhoneNumbers: string | undefined;
    public readonly clientName: string | undefined;
    public readonly publicId: string | undefined;
    public readonly code: string | undefined;
    public readonly comment: string | undefined;
    public readonly addressComment: string | undefined;
    public readonly corporate: boolean;
    public readonly desiredDateTimePeriod: DateTimePeriod | undefined;
    public readonly departPeriod: string | undefined;
    public executor: TripExecutor | undefined;
    public readonly expectedArrivalDateTime: DateTime | undefined;
    public readonly fullAddress: string;
    public readonly id: number;
    public readonly operations: OrderOperations;
    public readonly paymentMethod: string;
    public readonly phoneNumber: string | undefined;
    public readonly places: Place[];
    public readonly price: number;
    public driverPlace: DriverPlace | undefined;
    public errors = new Set<DraftOrderErrorType>();
    public readonly isInternal: boolean | undefined;

    public constructor(
        id: number,
        publicId: string | undefined,
        code: string | undefined,
        places: Place[],
        operations: OrderOperations,
        comment: string | undefined,
        addressComment: string | undefined,
        desiredDateTimePeriod: DateTimePeriod | undefined,
        expectedDateTime: DateTime | undefined,
        executor: TripExecutor | undefined,
        price: number,
        paymentMethod: string,
        phoneNumber: string | undefined,
        additionalPhoneNumbers: string | undefined,
        clientName: string | undefined,
        fullAddress: string,
        corporate: boolean,
        departPeriod: string | undefined,
        driverPlace: DriverPlace | undefined,
        isInternal: boolean | undefined
    ) {
        this.id = id;
        this.publicId = publicId;
        this.code = code;
        this.places = places;
        this.operations = operations;
        this.comment = comment;
        this.addressComment = addressComment;
        this.desiredDateTimePeriod = desiredDateTimePeriod;
        this.expectedArrivalDateTime = expectedDateTime;
        this.executor = executor;
        this.price = price;
        this.paymentMethod = paymentMethod;
        this.phoneNumber = phoneNumber;
        this.additionalPhoneNumbers = additionalPhoneNumbers;
        this.clientName = clientName;
        this.fullAddress = fullAddress;
        this.corporate = corporate;
        this.departPeriod = departPeriod;
        this.driverPlace = driverPlace;
        this.isInternal = isInternal
    }

    public setExecutor(executor: TripExecutor | undefined): void {
        this.executor = executor;
    }

    public cloneSetDriver(driver: Driver): DraftOrder {
        const tripExecutor = this.executor === undefined ? undefined : new TripExecutor(this.executor.car, driver, undefined);

        return new DraftOrder(
            this.id,
            this.publicId,
            this.code,
            this.places,
            this.operations,
            this.comment,
            this.addressComment,
            this.desiredDateTimePeriod,
            this.expectedArrivalDateTime,
            tripExecutor,
            this.price,
            this.paymentMethod,
            this.phoneNumber,
            this.additionalPhoneNumbers,
            this.clientName,
            this.fullAddress,
            this.corporate,
            this.departPeriod,
            this.driverPlace,
            this.isInternal
        );
    }

    public cloneSetValidPlace(place: Place): DraftOrder {
        return new DraftOrder(
            this.id,
            this.publicId,
            this.code,
            [
                new Place(
                    place.address,
                    place.fullAddress,
                    place.latitude,
                    place.longitude,
                    GeocodeQuality.JustValidated,
                    place.geocodeSource,
                    place.addressId,
                ),
            ],
            this.operations,
            this.comment,
            this.addressComment,
            this.desiredDateTimePeriod,
            this.expectedArrivalDateTime,
            this.executor,
            this.price,
            this.paymentMethod,
            this.phoneNumber,
            this.additionalPhoneNumbers,
            this.clientName,
            this.fullAddress,
            this.corporate,
            this.departPeriod,
            this.driverPlace,
            this.isInternal
        );
    }

    public needValidateDraftOrder(): boolean {
        let hasErrors = false;

        const hasInvalidGeocode = this.places.length !== 1 || this.places[0].isError();
        if (hasInvalidGeocode) {
            this.errors.add(DraftOrderErrorType.BadGeocode);
            hasErrors = true;
        } else {
            this.errors.delete(DraftOrderErrorType.BadGeocode);
        }

        const hasInvalidInterval = this.desiredDateTimePeriod &&
            this.desiredDateTimePeriod.end.compareTo(this.desiredDateTimePeriod.start) === -1;

        if (hasInvalidInterval) {
            this.errors.add(DraftOrderErrorType.BadInterval);
            hasErrors = true;
        } else {
            this.errors.delete(DraftOrderErrorType.BadInterval);
        }

        return hasErrors;
    }

    public getValidatedPlace(): Place | undefined {
        return this.places.find(p =>
            p.geocodeQuality === GeocodeQuality.Validated ||
            p.geocodeQuality === GeocodeQuality.JustValidated ||
            p.geocodeQuality === GeocodeQuality.Exactly)
    }

    public getCoordinates(): IGeocode | undefined {
        if (this.driverPlace) {
            return this.driverPlace.coords;
        }

        const place = this.places.find(p =>
            [GeocodeQuality.Validated, GeocodeQuality.JustValidated, GeocodeQuality.Exactly].includes(p.geocodeQuality));

        return place?.geocode || (this.places.length > 0 ? this.places[0].geocode : undefined);
    }

    public setDriverPlace(driverPlace: DriverPlace | undefined): void {
        this.driverPlace = driverPlace;
    }
}
