import {
    DraftApiDistrictsResponse,
    DraftApiDrivers,
    DraftApiPlanData,
} from '../../../ApiClient/YosoDraft/models';
import { District } from '../../../Model/District/District';
import { DraftPlanData } from '../../../Model/DraftPlanData/DraftPlanData';
import { Driver } from '../../../Model/Driver/Driver';
import { DateTime } from '../../../Std/DateTime';
import { Api } from '../Api';
import { dateTimeSerializer } from '../Mapper/DateTimeSerializer';
import {
    getDistrictsResponseDeserializer
} from './Methods/GetDistricts/GetDistrictsResponseDeserializer';

import { PlanDataMapper } from './Model/PlanDataMapper';

export class DraftApi {
    public static async getAdditionalPlanData(planId: number, driverCodes: string[] | undefined): Promise<DraftPlanData> {
        const apiPlanData = await Api.doRequest<DraftApiPlanData>(() =>
            Api.draftApi.getAdditionalPlanData({
                planId: planId,
                driverCodes: driverCodes
            }, Api.getRequestOptions()),
        );

        return PlanDataMapper.mapFromApi(apiPlanData);
    }

    public static async getPlanData(startAt: DateTime, finishAt: DateTime, driverCodes: string[] | undefined): Promise<DraftPlanData> {
        const apiPlanData = await Api.doRequest<DraftApiPlanData>(() =>
            Api.draftApi.getDraftPlanData(
                {
                    startAt: dateTimeSerializer.serialize(startAt),
                    finishAt: dateTimeSerializer.serialize(finishAt),
                    driverCodes: driverCodes
                },
                Api.getRequestOptions(),
            ),
        );

        return PlanDataMapper.mapFromApi(apiPlanData);
    }

    public static async getDrivers(startAt: DateTime, finishAt: DateTime): Promise<Driver[]> {
        const apiDrivers = await Api.doRequest<DraftApiDrivers>(() =>
            Api.draftApi.getDraftDrivers(
                dateTimeSerializer.serialize(startAt),
                dateTimeSerializer.serialize(finishAt),
                Api.getRequestOptions(),
            ),
        );

        return PlanDataMapper.mapDriversFromApi(apiDrivers);
    }

    public static async getAdditionalDrivers(planId: number): Promise<Driver[]> {
        const apiDrivers = await Api.doRequest<DraftApiDrivers>(() =>
            Api.draftApi.getAdditionalDrivers(
                planId,
                Api.getRequestOptions(),
            ),
        );

        return PlanDataMapper.mapDriversFromApi(apiDrivers);
    }

    public static async getDistricts(group: string): Promise<District[]> {
        const apiResponse = await Api.doRequest<DraftApiDistrictsResponse>(() =>
            Api.draftApi.getDistricts(group, Api.getRequestOptions())
        );

        return getDistrictsResponseDeserializer.deserialize(apiResponse);
    }
}
