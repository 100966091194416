import { CompanyApiCompanyProductResponse } from "../../../../../ApiClient/YosoCompany/models";
import { CompanyProduct } from "../../../../../Model/CompanyProduct/CompanyProduct";

export class CompanyProductDeserializer {
    public deserialize(product: CompanyApiCompanyProductResponse): CompanyProduct  {
        return new CompanyProduct(
            product.id,
            product.code,
            product.name,
            product.volume,
            product.popularity,
            product.addable,
            product.isMainBottle,
            product.isDefaultBottle,
            product.isEmptyBottle,
            product.isActive,
            product.shortName
        )
    }
}

export const companyProductDeserializer = new CompanyProductDeserializer();