import { DraftApiProduct } from '../../../../ApiClient/YosoDraft/models';
import { Product } from '../../../../Model/Product/Product';

export class ProductMapper {
    public static mapFromApi(apiProduct: DraftApiProduct): Product {
        return {
            id: apiProduct.id,
            code: apiProduct.code,
            name: apiProduct.name,
            volume: apiProduct.volume,
            addable: apiProduct.addable,
            isMainBottle: apiProduct.isMainBottle,
            isDefaultBottle: apiProduct.isDefaultBottle,
            isEmptyBottle: apiProduct.isEmptyBottle,
            isActive: apiProduct.isActive
        };
    }
}
