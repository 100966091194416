import { ApiPlace } from '../../../../../ApiClient/Yoso/models';
import { Place } from '../../../../../Model/Place/Place';

import { geocodeQualityDeserializer } from './GeocodeQualityDeserializer';
import { geocodeSourceDeserializer } from './GeocodeSourceDeserializer';

export class PlaceDeserializer {
    public deserialize(serializedObject: ApiPlace): Place {
        return new Place(
            serializedObject.address,
            serializedObject.fullAddress,
            serializedObject.latitude,
            serializedObject.longitude,
            geocodeQualityDeserializer.deserialize(serializedObject.geocodeQuality),
            geocodeSourceDeserializer.deserialize(serializedObject.geocodeSource),
            serializedObject.addressId,
        );
    }
}

export const placeDeserializer = new PlaceDeserializer();
