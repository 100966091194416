import * as React from 'react';
import { __RouterContext } from 'react-router';

import { StringUtils } from '../../Std/StringUtils';

import './EntityList.css';
import { EntityListFilter } from './EntityListFilter';
import { EntityListBase, EntityListItemAction, IListActionConfig, EntityListItemSafeAction, EntityListAction, IEntityListColumnConfig,
    IEntityListData } from './EntityListBase';

export enum EntityListColumnAlign {
    Center = 'center',
    Left = 'left',
    Right = 'right',
}

export interface IEntityListProps<TEntity, TEntityExtension = TEntity> {
    actions?: {
        item?: {
            click?: EntityListItemAction;
            delete?: IListActionConfig<EntityListItemSafeAction>;
        };
        list?: ReadonlyArray<IListActionConfig<EntityListAction>>;
    };
    className?: string;
    columns: ReadonlyArray<IEntityListColumnConfig<TEntity>>;
    data: ReadonlyArray<TEntity>;
    getEntityExtension?: (id: number | string) => Promise<TEntityExtension>;
    filter?: {
        additional?: { onClose: () => void; title: string }[];
        search?: {
            filter: (entity: TEntity, searchStringPart: string) => boolean;
            onSearch: (value: string) => void;
            searchString: string;
        };
        select?: {
            filter: (entity: TEntity, selectKey: string, selectValue: string) => boolean;
            items: Record<
                string,
                ReadonlyArray<{
                    title: string;
                    value: string;
                }>
            >;
            onChange: (values: Record<string, string>) => void;
            values: Record<string, string>;
        };
    };
    id: (entity: TEntity) => number | string;
    isEntityWithWarning?: (entity: TEntity) => boolean;
    loading?: boolean;
    pageSize?: number;
    renderRow?: (entity: TEntity) => JSX.Element;
    renderRowExtendedContent?: (entity: TEntityExtension) => React.ReactNode;
    rowColorMarker?: (entity: TEntity, index: number) => string;
    rowClass?: (entity: TEntity, index: number) => string;

    selectedItems?: TEntity | ReadonlyArray<TEntity>;
    small?: boolean;
    isRowReverse?: boolean;
}
export const EntityList = <TEntity extends unknown, TEntityExtension = TEntity>(props: IEntityListProps<TEntity, TEntityExtension>): JSX.Element => {

    const getEntities = (page: number, size: number, searchString?: string, filter?: Record<string, string>): Promise<IEntityListData<TEntity>> => {
        const entities = EntityListFilter.filter(
            props.data,
            !searchString
                ? ['']
                : StringUtils.split(searchString, [' ', ',', '.']),
            filter === undefined ? {} : filter,
            props.filter?.search?.filter,
            props.filter?.select?.filter,
        );

        const total = entities.length;

        return new Promise<IEntityListData<TEntity>>((resolve) => {
            resolve({
                entities: size > 0 ? entities.slice((page - 1) * size, page * size) : entities,
                total: total
            });
        })
    }


    return (
        <EntityListBase<TEntity, TEntityExtension>
            actions={props.actions}
            className={props.className}
            columns={props.columns}
            filter={props.filter}
            id={props.id}
            isEntityWithWarning={props.isEntityWithWarning}
            loading={props.loading}
            data={props.data}
            pageSize={props.pageSize}
            renderRow={props.renderRow}
            renderRowExtendedContent={props.renderRowExtendedContent}
            rowColorMarker={props.rowColorMarker}
            rowClass={props.rowClass}
            selectedItems={props.selectedItems}
            small={props.small}
            isRowReverse={props.isRowReverse}
            getEntities={getEntities}
            getEntityExtension={props.getEntityExtension}
        />
    );
};
