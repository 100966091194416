import React from "react";
import {Itinerary} from "../../Model/Itinerary/Itinerary";
import {ItineraryOrder} from "../../Model/Itinerary/ItineraryOrder";
import {ItineraryProductNote} from "../../Model/Itinerary/ItineraryProductNote";
import {formatDateTime} from "../../Std/formatDate";
import {formatTime} from "../../Std/formatTime";
import {EntityList, EntityListColumnAlign} from "../EntityList/EntityList";
import {IEntityListColumnConfig} from "../EntityList/EntityListBase";
import "./ItineraryReport.css";

interface IItineraryReportProps {
    itinerary: Itinerary | undefined;
}

export const ItineraryReport = (props: IItineraryReportProps) => {
    const {itinerary} = props;

    if (!itinerary) {
        return null;
    }

    const stringTimeLenght = -5;

    const getTypesBottles = React.useCallback(() => {
        const typesOfBottles: { productId: number, shortName: string }[] = [];

        itinerary.orders.forEach(o => {
            o.emptyBottlesCount.forEach(bottle => {
                const isNewType = typesOfBottles.some(el => el.productId === bottle.productId);
                if (!isNewType) {
                    typesOfBottles.push({
                        productId: bottle.productId,
                        shortName: bottle.productName
                    })
                }
            })
        })

        return typesOfBottles;
    }, [itinerary])

    const typesEmptyBottles = getTypesBottles();

    const checkOrderPaid = (order: ItineraryOrder) => order.isDone === true && order.price !== 0 && order.paid !== order.price;

    return (
        <div className="itinerary-report">

            <h2>Маршрутный
                лист {formatDateTime(itinerary.date).slice(0, stringTimeLenght)} {itinerary.driverName}</h2>

            <div className="itinerary-report__header">
                <div className="itinerary-report__movements">
                    <h3>Загрузки:</h3>
                    {itinerary.productMovements.map((pm, i) => (
                            <React.Fragment key={i}>
                                <div>{pm.title}</div>
                                <div>{pm.rows.map(row => (
                                    <div key={row.productId}
                                         className="itinerary-report__tab itinerary-report__movements_product-item">
                                        <span>{row.productName}</span>
                                        <span>x {row.quantity}</span>
                                    </div>
                                ))}
                                </div>
                            </React.Fragment>
                        )
                    )}
                </div>

                <div className="itinerary-report__movements">
                    <h3>Остаток:</h3>
                    <p>{`Общая сумма: ${itinerary.balance.paidAmount} руб.`}</p>
                    {itinerary.balance.cashPaidAmount > 0 && (
                        <p>{`Наличными: ${itinerary.balance.cashPaidAmount} руб.`}</p>
                    )}
                    {itinerary.balance.qrPaidAmount > 0 && (
                        <p>{`QR: ${itinerary.balance.qrPaidAmount} руб.`}</p>
                    )}
                    {itinerary.balance.terminalPaidAmount > 0 && (
                        <p>{`Терминал: ${itinerary.balance.terminalPaidAmount} руб.`}</p>
                    )}
                    {itinerary.balance.unknownPaidAmount > 0 && (
                        <p>{`Неизвестно: ${itinerary.balance.unknownPaidAmount} руб.`}</p>
                    )}
                    Товары:
                    <div>{itinerary.balance.rows?.map(row => (
                        <div key={row.productId}
                             className="itinerary-report__tab itinerary-report__movements_product-item">
                            <span>{row.productName}</span>
                            <span>x {row.quantity}</span>
                        </div>
                    ))}
                    </div>
                </div>

                <div className="itinerary-report__movements">
                    <h3>Доставленные продукты:</h3>
                    {itinerary.completedOrdersProducts.deliveredProducts.map(p => (
                        <div key={p.productId}
                             className="itinerary-report__tab itinerary-report__movements_product-item">
                            <span>{p.productName}</span>
                            <span>x {p.quantity}</span>
                        </div>
                    ))}
                    <h3>Полученные продукты:</h3>
                    {itinerary.completedOrdersProducts.returnedProducts.map(p => (
                        <div key={p.productId}
                             className="itinerary-report__tab itinerary-report__movements_product-item">
                            <span>{p.productName}</span>
                            <span>x {p.quantity}</span>
                        </div>
                    ))}
                </div>
            </div>

            <EntityList<ItineraryOrder>
                data={itinerary.orders}
                className="itinerary-report__entity-list"
                pageSize={0}
                id={(order) => order.orderId}
                columns={[
                    {
                        key: 'clientName',
                        render: (order) => (
                            <>{order.clientName} <br/> {order.additionalPhoneNumbers}</>
                        ),
                        title: 'Наименование / ФИО',
                    },
                    {
                        key: 'address',
                        render: (order) => (
                            <>{order.address}</>
                        ),
                        title: 'Адрес',
                    },
                    {
                        key: 'bottlesCount',
                        render: (order) => (
                            <>{order.bottlesCount || "\u2013"}</>
                        ),
                        title: 'Доставка',
                        align: EntityListColumnAlign.Center
                    },
                    ...typesEmptyBottles.map((item, index): IEntityListColumnConfig<ItineraryOrder> => (
                        {
                            key: `emptyBootlesCount-${index}`,
                            render: (order) => (
                                <>
                                    {order.emptyBottlesCount.find(el =>
                                        el.productId === item.productId)?.quantity || <>{"\u2013"}</>}
                                </>
                            ),
                            title: item.shortName,
                            align: EntityListColumnAlign.Center
                        }
                    )),
                    {
                        key: 'payment',
                        render: (order) => (
                            <div
                                className={`itinerary__order-payment ${checkOrderPaid(order) ? 'itinerary__order-payment_red' : ''}`}>
                                <div>{order.price === 0 ? '\u2013' : order.price}</div>
                                <div>/</div>
                                <div>{order.paid ? order.paid : '\u2013'}</div>
                            </div>
                        ),
                        title: 'Оплата',
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'paymentType',
                        render: (order) => (
                            <>{order.paymentMethod}</>
                        ),
                        title: 'Способ оплаты',
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'QR',
                        render: (order) => (
                            <>{order.isQR ? "\uFF0B" : ""}</>
                        ),
                        title: 'QR',
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'terminal',
                        render: (order) => (
                            <>{order.isTerminal ? "\uFF0B" : ""}</>
                        ),
                        title: 'Терминал',
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'intervalTime',
                        render: (order) => (
                            <>{`${formatTime(order.intervalBegin)} - ${formatTime(order.intervalEnd)}`}</>
                        ),
                        title: 'Желаемое время',
                        align: EntityListColumnAlign.Center,
                        noWrap: true
                    },
                    {
                        key: 'comment',
                        render: (order) => (
                            <>
                                {(order.comment || order.comment !== "") ??
                                    <p>{order.comment}</p>}
                                {order.shipmentNotes.length > 0 && (
                                    <div>
                                        {order.isActive ? "Доставлю" : "Доставил"}:
                                        {order.shipmentNotes.map(o => (
                                            <p
                                                className="itinerary-report__tab"
                                                key={o.productName}
                                            >
                                                x{o.quantity} - {o.productName}
                                            </p>
                                        ))}
                                    </div>
                                )}
                                {order.returnNotes.length > 0 && (
                                    <div>
                                        {order.isActive ? "Заберу" : "Забрал"}:
                                        {order.returnNotes.map(o => (
                                            <p
                                                className="itinerary-report__tab"
                                                key={o.productName}
                                            >
                                                x{o.quantity} - {o.productName}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </>
                        ),
                        title: 'Примечание',
                    }
                ]}
                rowClass={(order) => order.isDone === false
                    ? "itinerary__order_not-done"
                    : (order.isActive ? "itinerary__order_is-active" : "")}
            />
        </div>
    )
}