import { DateTime } from './DateTime';

export class DeserializerUtils {
    public readonly BYTE_MAX = 256;
    public readonly DATE_TIME_SIZE = 4;
    public readonly FLOAT7_SIZE = 4;
    public readonly INT32_SIZE = 4;

    private readonly DATE_TIME_FACTOR = 1000;
    private readonly FLOAT7_FACTOR = 10000000;

    public deserializeDateTime(serialized: string, offset: number): DateTime {
        return DateTime.fromDate(new Date(this.deserializeInt32(serialized, offset) * this.DATE_TIME_FACTOR));
    }

    public deserializeFloat7(serialized: string, offset: number): number {
        return this.deserializeInt32(serialized, offset) / this.FLOAT7_FACTOR;
    }

    public deserializeInt32(serialized: string, offset: number): number {
        let n = 0;
        for (let i = 0; i < this.INT32_SIZE; i += 1) {
            const code = serialized.charCodeAt(offset + i);
            n += code * Math.pow(this.BYTE_MAX, this.INT32_SIZE - 1 - i);
        }

        return n;
    }
}

export const deserializerUtils = new DeserializerUtils();
