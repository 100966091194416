import { ThunderboltOutlined } from "@ant-design/icons";
import React from "react";
import { Geocode } from "../../Model/Geocode/Geocode";
import { MapContext } from "../MapContainer/MapContextProvider";
import MapControl from "../MapContainer/MapControl";
import { Marker } from "../MapContainer/model/Marker";
import { MarkerTooltip } from "../MapContainer/model/MarkerTooltip";
import { Path } from "../MapContainer/model/Path";
import './GeocodePolyline.css'

export interface IGeocodePolylineProps<TEntity> {
    data: ReadonlyArray<TEntity>;
    getGeocode: ((item: TEntity, index: number) => Geocode);
    getTooltip?: ((item: TEntity, index: number) => string);
    getClassname?: ((item: TEntity, index: number) => string);
    isVisibilityControl?: boolean;
}

export const GeocodePolyline = <TEntity extends unknown>(props: IGeocodePolylineProps<TEntity>) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const drawContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const [markersOnMap, setMarkersOnMap] = React.useState<Marker[]>([]);
    const [pathOnMap, setPathOnMap] = React.useState<Path[]>([]);
    const [pathVisibility, setPathVisibility] = React.useState(true);

    React.useEffect(() => {
        createPolyline(props.data);

        if (props.data.length > 0) {
            mapContext.setCenter(new Geocode(props.getGeocode(props.data[0], 0)));
        }
    }, [props])

    React.useEffect(() => {
        drawContext.renderPath(pathOnMap);
    }, [pathOnMap])

    React.useEffect(() => {
        drawContext.renderMarkers(markersOnMap);
    }, [markersOnMap])

    React.useEffect(() => {
        drawContext.renderMarkers(pathVisibility ? markersOnMap : []);
        drawContext.renderPath(pathVisibility ? pathOnMap : []);
    }, [pathVisibility])

    const createPolyline = React.useCallback((arr: ReadonlyArray<TEntity>) => {
        const markers: Marker[] = [];
        const lines: Path[] = [];

        for (let i = 0; i < arr.length; i++) {
            const point1 = props.getGeocode(arr[i], i);

            let classname = "geocode-polyline__marker ";
            let tooltip: MarkerTooltip | undefined;

            if (props.getClassname) {
                classname += props.getClassname(arr[i], i);
            }

            if (props.getTooltip) {
                tooltip = { text: props.getTooltip(arr[i], i) };
            }

            if (i + 1 < arr.length) {
                const point2 = props.getGeocode(arr[i + 1], i + 1);
                const line: Path = new Path(
                    [point1, point2],
                    "geocode-polyline__default-line"
                );

                lines.push(line);
            }

            markers.push(new Marker(
                point1.latitude,
                point1.longitude,
                String(i + 1),
                true,
                tooltip || undefined,
                undefined,
                classname
            ))
        }

        setMarkersOnMap(markers);
        setPathOnMap(lines);
    }, [props.data, props.getGeocode, props.getTooltip, props.getClassname])

    return props.isVisibilityControl === true ? (
        <MapControl position='topleft' separate={false}>
            <a onClick={() => setPathVisibility(!pathVisibility)} style={{ fontSize: '17px' }}><ThunderboltOutlined /></a>
        </MapControl>
    ) : null;
}