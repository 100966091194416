import { Button, Input, Modal } from 'antd';
import * as React from 'react';
import { KeyOutlined } from '@ant-design/icons';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';
import { generatePassword } from '../../Std/PassGen';

import './DriverLoginEditor.css';

const passMinLen = 4;

export interface IDriverLoginEditorProps {
    driverCode: string;
    driverName: string;
    driverLogin?: string;
    onCancel: () => void;
    onOk: (driverCode: string, login: string) => void;
}

export const DriverLoginEditor = (props: IDriverLoginEditorProps): JSX.Element => {
    const { driverCode, onCancel, onOk } = props;

    const [login, setLogin] = React.useState(props.driverLogin as string | undefined);
    const [pass, setPass] = React.useState('');

    const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLogin(event.currentTarget.value);
        showError(undefined);
    };

    const handlePassChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPass(event.currentTarget.value);
        showError(undefined);
    };

    const emptyMessage = '&nbsp;';

    const [errorMessage, setErrorMessage] = React.useState(emptyMessage as string);

    const [loading, setLoading] = React.useState(false as boolean);

    const showError: (error: string | undefined) => void = React.useCallback((error: string | undefined): void => {
        if (error === undefined) {
            setErrorMessage(emptyMessage);
            setEditorClassName('driver-login-editor');
        } else {
            setErrorMessage(error);
            setEditorClassName('driver-login-editor driver-login-error');
        }
    }, []);

    const validateLogin: (loginStr: string | undefined) => boolean = React.useCallback(
        (loginStr: string | undefined): boolean => {
            if (loginStr === undefined) {
                return false;
            }

            const loginTemplate = /8\d{10}/;

            return loginTemplate.test(loginStr);
        },
        [],
    );

    const validatePass: (passStr: string) => boolean = React.useCallback((passStr: string): boolean => {
        if (passStr === undefined) {
            return false;
        }

        return passStr.length >= passMinLen;
    }, []);

    const handleOk: () => Promise<undefined> = React.useCallback(async (): Promise<undefined> => {
        if (!validateLogin(login)) {
            showError('Логином должен быть номер телефона в виде 8ХХХХХХХХХХ');

            return;
        }

        if (!validatePass(pass)) {
            showError('Пароль должен состоять хотя бы из 4 символов');

            return;
        }

        if (login === undefined) {
            return;
        }

        try {
            setLoading(true);
            await CompanyApi.upsertUserForDriver(driverCode, login, pass);
            onOk(driverCode, login);
        } catch (e) {
            showError('Произошла ошибка');
        } finally {
            setLoading(false);
        }

        return;
    }, [driverCode, login, pass, onOk, validateLogin, showError]);

    const [editorClassName, setEditorClassName] = React.useState('driver-login-editor' as string);

    React.useEffect(() => {
        setLogin(props.driverLogin);
        showError(undefined);
    }, [props.driverLogin, showError]);

    return (
        <Modal
            className={editorClassName}
            confirmLoading={loading}
            onCancel={onCancel}
            onOk={handleOk}
            okButtonProps={{ disabled: login === undefined || pass.length === 0 }}
            title="Введите логин/пароль для водителя"
            visible={true}
            width={600}
        >
            <div className="driver-login-editor__name">{props.driverName}</div>
            <div className="driver-login-editor__credentials">
                <Input
                    onChange={handleLoginChange}
                    placeholder="Номер телефона в виде 8ХХХХХХХХХХ"
                    value={login}
                    className="driver-login-editor__login"
                    disabled={props.driverLogin !== undefined}
                />
                <Button
                    title="Сгенерировать пароль"
                    className="driver-login-editor__passgen"
                    onClick={() => setPass(generatePassword(passMinLen))}
                >
                    <KeyOutlined />
                </Button>
                <Input
                    onChange={handlePassChange}
                    placeholder="Пароль, минимум 4 символа"
                    value={pass}
                    className="driver-login-editor__pass"
                />
            </div>
            <div className="driver-login-error-message">{errorMessage}</div>
        </Modal>
    );
};
