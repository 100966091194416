import 'antd/dist/antd.css';
import 'normalize.css/normalize.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './Components/App/App';
import { AppProviders } from './Components/AppProviders/AppProviders';
import { VersionController } from './Components/VersionController/VersionController';
import './styles.css';

ReactDOM.render(
    <BrowserRouter>
        <AppProviders>
            <VersionController />
            <App className="root__app" />
        </AppProviders>
    </BrowserRouter>,
    document.getElementById('root'),
);
