import React from 'react';
import { Button, Tabs } from 'antd';
import { ShoppingCartOutlined, WarningOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Spin from 'antd/lib/spin';
import { LatLngTuple } from 'leaflet';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';
import {
    IDeliveryPlanResponse
} from '../../Backend/Api/Delivery/Model/DeliveryPlanResponse/DeliveryPlanResponseDeserializer';
import { DraftApi } from '../../Backend/Api/Draft/DraftApi';
import { DraftDriver } from '../../Model/DraftDriver/DraftDriver';
import { DraftOrder } from '../../Model/DraftOrder/DraftOrder';
import { getLeafletBoundsFromIGeocodes } from '../../Std/LeafletUtils';
import {
    DistrictGroupCode,
    getDistrictGroupCodeFromValue,
    getDistrictGroupCodeName,
} from '../../Store/District/DistrictGroupCode';
import { useCompany } from '../AppProviders/Providers/CompanyProvider';
import MapContainer from '../MapContainer/MapContainer';
import MapControl from '../MapContainer/MapControl';
import { PointsOnMap } from '../PointsOnMap/PointsOnMap';
import { useTripPlanData } from '../TripPlanData/TripPlanDataProvider';
import { DistrictsOnMap } from '../TripPlanDetails/DistrictsOnMap';
import { Polygon } from '../MapContainer/model/Polygon';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { District } from '../../Model/District/District';
import DistrictValidation from './DistrictValidation';
import OrderValidation from './OrderValidation';
import { PlanValidationStore } from './PlanValidationStore';
import styles from './PlanValidation.module.css';

interface PlaceValidationProps {
    districtGroup: DistrictGroupCode | undefined;
    buildPlan: (newOrders?: DraftOrder[], districts?: District[]) => Promise<IDeliveryPlanResponse | undefined>
}

export const PlanValidation = observer((props: PlaceValidationProps): JSX.Element => {
    const maxBottleCountToDriver = 110;
    const minBottleCountToDriver = 50;
    const { center } = useCompany();
    const context = useTripPlanData();
    const {
        orders,
    } = context;

    const [mapBounds, setMapBounds] = React.useState<LatLngTuple[] | undefined>(undefined);

    const store = React.useMemo(() => new PlanValidationStore(
        orders ? [...orders] : [], props.districtGroup), []);

    React.useEffect(() => {
        store.setLoading(true);
        Promise.all([
            CompanyApi.getDriverList(),
            DraftApi.getDistricts(props.districtGroup ?? 'MORNING'),
        ])
            .then(response => {
                store.setDistricts(response[1]);
                store.setAllDrivers(response[0].map(i => new DraftDriver(i.driver.id, i.driver.name, !!i.driver.isActive)));
            })
            .finally(() => store.setLoading(false));
    }, []);

    const changeTab = (value: string) => {
        const districtGroupCode = getDistrictGroupCodeFromValue(value);
        store.setSelectedDistrictGroup(districtGroupCode);
    };

    const selectOrders = (points: DraftOrder[]) => {
        store.setSelectedOrders(points);
    }

    const buildPlan = () => {
        store.setLoading(true)
        if (!store.selectedDistrictGroup) {
            throw new Error('Не выбран DistrictGroup')
        }
        store.updateDistrictsColorByDriver();
        props.buildPlan(
            store.getActualOrders(),
            store.districts
        ).finally(() => store.setLoading(false));
    }

    const navigateToSuspiciousItem = () => {
        const error = store.showError();
        if (error instanceof District) {
            if (store.selectedDistrictGroup !== error.group) {
                store.setSelectedDistrictGroup(error.group);
            }
            setMapBounds(getLeafletBoundsFromIGeocodes(error.geocodes));
        }

        if (error instanceof DraftOrder) {
            const place = error.getValidatedPlace();
            if (!place) {
                return;
            }
            const group = store.getGroupByOrder(error);
            if (store.selectedDistrictGroup !== group) {
                store.setSelectedDistrictGroup(group);
            }
            setMapBounds(getLeafletBoundsFromIGeocodes([place.geocode!]));
        }
    }

    React.useEffect(() => {
        if (store.selectedDriver) {
            const districts = store.selectedGroupDistricts
                .filter(i => i.drivers.some(d => store.selectedDriver === d));

            setMapBounds(districts.length === 0
                ? undefined
                : districts.map(d => getLeafletBoundsFromIGeocodes(d.geocodes)).flat())
        } else {
            setMapBounds(undefined);
        }
    }, [store.selectedDriver])

    return (
        <Spin spinning={store.isLoading}>
            <div className={styles.wrapper}>
                {center &&
                    <div className={styles.container}>
                        <div className={styles.listWrapper}>
                            <div className={styles.list}>
                                {store.cityDistrictDrivers.map(i =>
                                    <div
                                        key={i.cityDistrict}
                                    >
                                        <div className={styles.cityDistrict}>
                                            {i.cityDistrict || 'Без группы'}
                                        </div>
                                        {Array.from(i.drivers)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map(driver => {

                                            const driverOrders = store.driverOrdersMap.get(driver)

                                            if (!driverOrders) {
                                                return <></>;
                                            }

                                            const shipment = TripExecutorsLoadingInfo.getLoading(driverOrders)

                                            return (
                                                <div
                                                    key={driver.id}
                                                    className={classNames(
                                                        styles.listItem, {
                                                            [styles.shipmentCountHigh]: shipment.loading > maxBottleCountToDriver,
                                                            [styles.shipmentCountLow]: shipment.loading < minBottleCountToDriver,
                                                            [styles.bold]: store.selectedDriver === driver
                                                        }
                                                    )}
                                                    onClick={() => store.setSelectedDriver(driver)}
                                                >
                                                    <div className={styles.driverColor} style={{backgroundColor: store.getDriverColor(driver)}} />
                                                    <div className={styles.driverName}>
                                                        {driver.name}
                                                    </div>
                                                    <div className={styles.ordersCount}>
                                                        <div className={styles.bold}>
                                                            {driverOrders.length}<ShoppingCartOutlined />
                                                        </div>
                                                        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
                                                        {store.districtsGroups.length === 2 &&
                                                            <div
                                                                className={styles.ordersCountByDepartPeriod}>
                                                                <div
                                                                    className={classNames(styles.bottle, styles.lightWater)}>
                                                                    {shipment.morningBottlesCount}
                                                                </div>
                                                                <span> / </span>
                                                                <div
                                                                    className={classNames(styles.bottle, styles.darkWater)}>
                                                                    {shipment.eveningBottlesCount}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <span className={styles.shipmentCount}>
                                                        <span title='Общая загрузка'>{shipment.loading}</span>
                                                        <span title='Количество бутылей'>({shipment.bottlesCount})</span>
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={styles.btnBuild}>
                                {store.validationError &&
                                    <WarningOutlined
                                        className={`${styles.warning} ${styles.iconLarge}`}
                                        title={store.validationError.title}
                                        onClick={navigateToSuspiciousItem}
                                    />
                                }
                                <Button
                                    type='primary'
                                    disabled={store.isLoading ||
                                        store.districts.some(d => d.drivers.length !== 1) ||
                                        store.ordersWithoutDriver.length > 0 ||
                                        store.validationError !== undefined
                                    }
                                    onClick={buildPlan}
                                >
                                    Построить план
                                </Button>
                            </div>
                        </div>
                        <div className={styles.mapTabs}>
                            {store.districtsGroups.length > 0 &&
                                <Tabs
                                    centered
                                    type='card'
                                    activeKey={store.selectedDistrictGroup}
                                    onChange={changeTab}
                                >
                                    {store.districtsGroups.map(dg =>
                                        <Tabs.TabPane
                                            key={dg}
                                            tab={getDistrictGroupCodeName(dg)}
                                        />,
                                    )}
                                </Tabs>
                            }
                            <div className={styles.map}>
                                <MapContainer
                                    center={[center.latitude, center.longitude]}
                                    bounds={mapBounds}
                                >
                                    <DistrictsOnMap
                                        polygons={store.selectedGroupDistricts.map(d => new Polygon(
                                                d.geocodes,
                                                d.drivers.length === 1 ? store.getDriverColor(d.drivers[0]) :  "#000",
                                                d.name,
                                                d === store.selectedDistrict
                                                    || store.selectedDriver && d.drivers.includes(store.selectedDriver),
                                                () => {
                                                    store.setSelectedDriver(undefined);
                                                    store.setSelectedDistrict(d);
                                                },
                                            ))}
                                    >
                                        <DistrictValidation store={store} />
                                    </DistrictsOnMap>
                                    <PointsOnMap<DraftOrder>
                                        points={store.selectedGroupOrders}
                                        getCoordinate={(x: DraftOrder) => x.getCoordinates()!}
                                        getColor={(x: DraftOrder) => store.getOrderColor(x)}
                                        getTooltip={(x: DraftOrder) => x.fullAddress}
                                        onClick={selectOrders}
                                        unionDistance={1e-3}
                                        getUnitableGroupKey={(x: DraftOrder) => store.getOrderDriver(x)}
                                    >
                                        <OrderValidation store={store} />
                                    </PointsOnMap>
                                    {store.validationError &&
                                        <MapControl position='topright' separate={true}>
                                            <a
                                                className={styles.buttonWarning}
                                                title={store.validationError.title}
                                                onClick={navigateToSuspiciousItem}
                                            >
                                                <WarningOutlined className={styles.warning} />
                                            </a>
                                        </MapControl>
                                    }
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Spin>
    );
});
