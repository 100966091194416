import { GeocodingApiValidatedAddress } from '../../../../../ApiClient/YosoGeocoding/models';
import { Place } from '../../../../../Model/Place/Place';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

import { geocodeSourceSerializer } from './GeocodeSourceSerializer';

export class ValidatedAddressSerializer implements IApiModelSerializer<Place, GeocodingApiValidatedAddress> {
    public serialize(obj: Place, orderSuspectPlaceId?: number): GeocodingApiValidatedAddress {
        return {
            address: obj.address,
            addressId: obj.addressId!,
            geocodeSource: geocodeSourceSerializer.serialize(obj.geocodeSource),
            latitude: obj.latitude!,
            longitude: obj.longitude!,
            orderSuspectPlaceId: orderSuspectPlaceId
        };
    }
}

export const validatedAddressSerializer = new ValidatedAddressSerializer();
