import {
    DeliveryApiDriverNoticeMail
} from '../../../../../ApiClient/Yoso/models';
import { NoticeMail } from '../../../../../Model/NoticeMail/NoticeMail';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class NoticeMailDeserializer {
    public deserialize(apiMail: DeliveryApiDriverNoticeMail): NoticeMail {
        return {
            id: apiMail.id,
            text: apiMail.mail,
            code: apiMail.code,
            date: dateTimeDeserializer.deserialize(apiMail.date),
            tripId: apiMail.tripId,
            planId: apiMail.planId
        }
    }
}

export const noticeMailDeserializer = new NoticeMailDeserializer()