import {
    DeliveryApiTripComparisonList
} from '../../../../../ApiClient/Yoso/models';
import {
    TripComparisonListItem
} from '../../../../../Model/TripComparison/TripComparisonListItem';
import {dateTimeDeserializer} from '../../../Mapper/DateTimeDeserializer';

export interface IGetTripComparisonsResponse {
    trips: TripComparisonListItem[];
    total: number;
}

export class GetTripComparisonsResponseDeserializer {
    public deserialize(response: DeliveryApiTripComparisonList): IGetTripComparisonsResponse {
        return {
            total: response.total,
            trips: response.trips.map(t => new TripComparisonListItem(
                t.id,
                t.tripId,
                t.name,
                dateTimeDeserializer.deserialize(t.date),
                dateTimeDeserializer.deserialize(t.createdAt)
            ))
        }
    }
}

export const getTripComparisonsDeserializer = new GetTripComparisonsResponseDeserializer();