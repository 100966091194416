import {Marker} from '../../Components/MapContainer/model/Marker';
import {MarkerTooltip} from '../../Components/MapContainer/model/MarkerTooltip';
import {IGeocode} from '../Geocode/IGeocode';

export class MarkerBuilder {
  private readonly _latitude: number;
  private readonly _longitude: number;
  private _content?: string;
  private _clickable = true;
  private _tooltip?: MarkerTooltip;
  private _style?: string;
  private _className = '';
  private _zIndex?: number;

  public constructor(geocode: IGeocode) {
    this._latitude = geocode.latitude;
    this._longitude = geocode.longitude;

    return this;
  }

  public setClickable(value: boolean): MarkerBuilder {
    this._clickable = value;

    return this;
  }

  public setContent(value: string): MarkerBuilder {
    this._content = value;

    return this;
  }

  public setTooltip(value: string): MarkerBuilder {
    this._tooltip = {text: value};

    return this;
  }

  public setStyle(value: string): MarkerBuilder {
    this._style = value;

    return this;
  }

  public setClassName(value: string): MarkerBuilder {
    this._className = value;

    return this;
  }

  public setZIndex(value: number): MarkerBuilder {
    this._zIndex = value;

    return this;
  }

  public build(): Marker {
    return new Marker(
      this._latitude,
      this._longitude,
      this._content,
      this._clickable,
      this._tooltip,
      this._style,
      this._className,
      this._zIndex
    );
  }
}