import {WorkingShift} from "../../../../../ApiClient/Yoso/models";
import {TripWorkingShift} from "../../../../../Model/Trip/TripWorkingShift";

export class TripWorkingShiftDeserializer {
    public deserialize(serializedObj: WorkingShift | undefined): TripWorkingShift | undefined {
        return serializedObj === WorkingShift.MORNING
            ? TripWorkingShift.MORNING
            : serializedObj === WorkingShift.EVENING
                ? TripWorkingShift.EVENING
                : serializedObj === WorkingShift.DAYOFF
                    ? TripWorkingShift.DAYOFF : undefined
    }
}

export const tripWorkingShiftDeserializer = new TripWorkingShiftDeserializer();
