import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthenticatedAppWrapper } from '../AuthenticatedAppWrapper/AuthenticatedAppWrapper';
import { UnauthenticatedApp } from '../UnauthenticatedApp/UnauthenticatedApp';

export interface IAppProps {
    className?: string;
}

export const App = (props: IAppProps): JSX.Element => (
    <Switch>
        <Route exact={true} path="/login" component={UnauthenticatedApp} />
        <Route component={() => <AuthenticatedAppWrapper className={props.className} />} />
    </Switch>
);
