import { StatsApiDriverResponse, StatsApiDriversResponse, StatsApiRatingStatisticsResponse } from "../../../ApiClient/YosoStats/models";
import {
    RatingStatistics
} from '../../../Model/RatingStatistics/RatingStatistics';
import { Api } from "../Api";
import { dateTimeSerializer } from "../Mapper/DateTimeSerializer";
import {
    RatingStatisticsDeserializer
} from './Models/RatingStatisticsDeserializer';

export class StatsApi {
    public static async getDriversArrivalStats(startDate: Date, endDate: Date): Promise<StatsApiDriversResponse> {
        return Api.doRequest<StatsApiDriversResponse>(() => Api.statsApi.getDriversArrivalStats(
            dateTimeSerializer.serializeDate(startDate),
            dateTimeSerializer.serializeDate(endDate),
            Api.getRequestOptions()));
    }

    public static async getDriversShipmentStats(startDate: Date, endDate: Date): Promise<RatingStatistics[]> {
        const response = await Api.doRequest<StatsApiRatingStatisticsResponse>(() => Api.statsApi.getRatingStatistics(
            dateTimeSerializer.serializeDate(startDate),
            dateTimeSerializer.serializeDate(endDate),
            Api.getRequestOptions()));

        return response.statistics.map(i => RatingStatisticsDeserializer.deserialize(i));
    }

    public static async getDriverStats(driverId: number, startDate: Date, endDate: Date): Promise<StatsApiDriverResponse> {
        return Api.doRequest<StatsApiDriverResponse>(() => Api.statsApi.getDriverStats(
            driverId,
            dateTimeSerializer.serializeDate(startDate),
            dateTimeSerializer.serializeDate(endDate),
            Api.getRequestOptions()));
    }
}