import { ApiDateTimePeriod } from '../../../../../ApiClient/Yoso/models';
import { DateTimePeriod } from '../../../../../Std/DateTimePeriod';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class DateTimePeriodDeserializer {
    public deserialize(serializedObject: ApiDateTimePeriod): DateTimePeriod {
        return new DateTimePeriod(
            dateTimeDeserializer.deserialize(serializedObject.start!),
            dateTimeDeserializer.deserialize(serializedObject.end!),
        );
    }
}

export const dateTimePeriodDeserializer = new DateTimePeriodDeserializer();
