import { Code, DraftApiDistrictGroup } from "../../../../ApiClient/YosoDraft/models";
import { DistrictGroup } from "../../../../Store/District/DistrictGroup";
import { DistrictGroupCode } from "../../../../Store/District/DistrictGroupCode";

export class DistrictGroupMapper {
    public static mapFromApi(apiGroups: DraftApiDistrictGroup): DistrictGroup {
        return new DistrictGroup(
            DistrictGroupMapper.mapFromApiDistrictGroup(apiGroups),
            apiGroups.name
        );
    }

    private static mapFromApiDistrictGroup(item: DraftApiDistrictGroup): DistrictGroupCode {
        switch (item.code) {
            case Code.MORNING:
                return DistrictGroupCode.Morning;
            case Code.EVENING:
                return DistrictGroupCode.Evening;
            case Code.DAYOFF:
                return DistrictGroupCode.DayOff;
            default:
                throw new Error("Error district group from server");
        }
    }
}
