import React from 'react'
import { observer } from 'mobx-react'
import { Tooltip } from 'react-leaflet'
import { LatLng, LatLngTuple } from 'leaflet'
import { IGeocode } from '../../Model/Geocode/IGeocode'
import { DistrictStrictIntervalStore } from '../../Store/DistrictStrictInterval/DistrictStrictIntervalStore'
import { DistrictStrictInterval } from '../../Store/DistrictStrictInterval/DistrictStrictInterval'
import { MapContext } from '../MapContainer/MapContextProvider'
import { MapMode } from '../MapContainer/model/MapMode'
import Polygon from '../MapContainer/components/Polygon'
import { ArrayUtils } from '../../Std/ArrayUtils'
import DistrictStrictIntervalPopup from './DistrictStrictIntervalPopup'
import './DistrictStrictIntervalMapEditor.css';

interface DistrictStrictIntervalMapEditorProps {
    store: DistrictStrictIntervalStore;
    centerCompany: LatLng;
    onSave: (district: DistrictStrictInterval) => Promise<void>;
}

const DistrictStrictIntervalMapEditor = observer((props: DistrictStrictIntervalMapEditorProps) => {
    const mapContext = React.useContext(MapContext);
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }
    const editorContext = mapContext.editorOnMap;

    const [popupPosition, setPopupPosition] = React.useState<IGeocode>({latitude: props.centerCompany.lat, longitude: props.centerCompany.lng});

    React.useEffect(() => {
        editorContext.setSelfIntersection(false);
        editorContext.enablePolygonDraw(true);
        editorContext.enableEditing(true);

        editorContext.onPolygonCreate((id: number, coords: IGeocode[], color: string) => {
            props.store.addEntity(id, coords, "", color, []);
        });
    }, []);

    React.useEffect(() => {
        editorContext.setPalette(props.store.entities.map((x) => x.color));
    }, []);

    React.useEffect(() => {
        props.store.setSelectedEntityId(undefined);
    }, [props.store.entities.length]);

    React.useEffect(() => {
        if (props.store.selectedEntity) {
            const centerEntity = props.store.selectedEntity.getCentroid();

            if (!centerEntity) {
                return undefined;
            }

            editorContext.setCenter(centerEntity);
            setPopupPosition(centerEntity);
        }
    }, [props.store.selectedEntityId, props.store.entities])

    const onPolygonClick = React.useCallback((e: any, districtId: number) => {
        if (editorContext.getCurrentMapMode() === MapMode.DeleteMode) {
            props.store.setSelectedEntityId(undefined);

            return;
        }
        
        if (districtId !== props.store.selectedEntityId) {
            props.store.setSelectedEntityId(districtId);
        }

        setPopupPosition({
            latitude: e.latlng.lat,
            longitude: e.latlng.lng
        });     
    }, [])

    const onPolygonEdit = React.useCallback((districtId: number, coords: IGeocode[]) => {
        props.store.editEntity(districtId, coords);
    }, [])

    const createTooltip = React.useCallback((district: DistrictStrictInterval): JSX.Element => (
        <>
            {district.getName() && (
                <>
                    {district.getName()}<br/>
                </>
            )}
            {ArrayUtils.sort(district.intervals, i => i.beginHour).map(i => (
                <span key={i.key}>
                    {i.formatTime()}<br/>
                </span>
            ))}
        </>
    ), [])

    return (
        <>
            {props.store.entities.map((district: DistrictStrictInterval) => 
                <Polygon
                    key={district.id}
                    coords={district.coords}
                    color={district.color}
                    onClick={(e) => onPolygonClick(e, district.id)}
                    onEdit={(coords) => onPolygonEdit(district.id, coords)}>
                        {(district.getName() || district.intervals.length) && (
                            <Tooltip permanent={true} direction="center">
                                {createTooltip(district)}
                            </Tooltip>
                        )}
                </Polygon>
            )}
            <DistrictStrictIntervalPopup
                position={popupPosition}
                store={props.store}
                onSave={props.onSave}
            />
        </>
    )
})

export default DistrictStrictIntervalMapEditor