import { MathUtils } from '../../Std/MathUtils';
import { Geocode } from '../Geocode/Geocode';

export class GeoVector {
    public readonly end: Geocode;
    public readonly start: Geocode;

    public constructor(start: Geocode, end: Geocode) {
        this.start = start;
        this.end = end;
    }

    public getDirectionAngle(): number {
        // Simplified to sphere version of https://planetcalc.ru/713 .

        const latitude1 = this.start.latitude;
        const latitude2 = this.end.latitude;
        const longitude1 = this.start.longitude;
        const longitude2 = this.end.longitude;

        const dLongitude = longitude2 - longitude1;
        const deltaLongitude =
            Math.abs(dLongitude) <= MathUtils.degreesInPi
                ? dLongitude
                : dLongitude < -MathUtils.degreesInPi
                ? MathUtils.degreesInCircle + dLongitude
                : dLongitude - MathUtils.degreesInCircle;

        const result = MathUtils.radiansToDegrees(
            Math.atan2(
                MathUtils.degreesToRadians(deltaLongitude),
                Math.log(
                    Math.tan(
                        MathUtils.degreesToRadians(
                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                            latitude2 / 2 + 45,
                        ),
                    ),
                ) -
                    Math.log(
                        Math.tan(
                            MathUtils.degreesToRadians(
                                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                latitude1 / 2 + 45,
                            ),
                        ),
                    ),
            ),
        );

        return MathUtils.round((result + MathUtils.degreesInCircle) % MathUtils.degreesInCircle);
    }
}
