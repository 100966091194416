import { CompanyApiCompanyProductsResponse } from "../../../../../ApiClient/YosoCompany/models";
import { CompanyProduct } from "../../../../../Model/CompanyProduct/CompanyProduct";
import { companyProductDeserializer } from "./CompanyProductDeserializer";

export class GetCompanyProductsDeserializer {
    public deserialize(responce: CompanyApiCompanyProductsResponse): CompanyProduct[] {
        return responce.products.map(product => companyProductDeserializer.deserialize(product));
    }
}

export const getCompanyProductsDeserializer = new GetCompanyProductsDeserializer();