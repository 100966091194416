export class RoundRobinColorPicker {
  private readonly palette: string[];
  private readonly paletteCounter: number[];
  private readonly usedColors: string[];

  public constructor(usedColors?: string[]) {
    this.palette = ['#0000fe', '#008001', '#ff7f00', '#fe0000', '#8c01fe', '#008081', '#a1b603',
      '#ffc000', '#ff4403', '#a81780', '#5a00d4', '#5050d4', '#509252', '#d39250', '#d65051',
      '#9851d5', '#509291', '#a1af50', '#d5b351', '#d57451', '#a75d92', '#7e50bd'];
    this.usedColors = usedColors || [];
    this.paletteCounter = new Array(this.palette.length).fill(0);
    this.usedColors.forEach((color: string) => {
      const paletteIndex = this.palette.findIndex((pc: string) => pc === color);
      this.paletteCounter[paletteIndex] += 1;
    });
  }

  public getNextColor(): string {
    const minCount = Math.min(...this.paletteCounter);
    const minCountIndex = this.paletteCounter.indexOf(minCount);
    const color = this.palette[minCountIndex];
    this.usedColors.push(color);
    this.paletteCounter[minCountIndex] += 1;

    return color;
  }

  public getColorByIndex(index: number): string {
    index = index % this.palette.length

    return this.palette[index];
  }
}