import { Type } from '../../../../../ApiClient/Yoso/models';
import { ProductOperationType } from '../../../../../Model/ProductOperation/ProductOperationType';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class ProductOperationTypeSerializer implements IApiModelSerializer<ProductOperationType, Type> {
    public serialize(obj: ProductOperationType): Type {
        const result =
            obj === ProductOperationType.Return
                ? Type.RETURN
                : obj === ProductOperationType.Shipment
                ? Type.SHIPMENT
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown ProductOperationType '${obj?.toString()}'`);
        }

        return result;
    }
}

export const productOperationTypeSerializer = new ProductOperationTypeSerializer();
