import {
    DeliveryApiPlanPredictionsResponse
} from '../../../../../ApiClient/Yoso/models';
import { TripPredictions } from '../../../../../Model/TripPredictions/TripPredictions';
import {
    tripPredictionDeserializer
} from '../../Model/TripPrediction/TripPredictionDeserializer';

export class GetPlanPredictionsDeserializer {
    public deserialize(response: DeliveryApiPlanPredictionsResponse): TripPredictions[] {
        return response.planPredictions.map(tripPredictions => ({
            tripId: tripPredictions.tripId,
            predictions: tripPredictions.predictions.map(i => tripPredictionDeserializer.deserialize(i))
        }))
    }
}

export const getPlanPredictionsDeserializer = new GetPlanPredictionsDeserializer();