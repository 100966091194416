import { LunchStatus } from '../../../../../ApiClient/Yoso/models';
import { LunchStatusActual } from '../../../../../Model/Lunch/LunchStatusActual';
import { Types } from '../../../../../Std/Types';

export class LunchStatusDeserializer {
    public deserialize(serializedObj: LunchStatus): LunchStatusActual {
        return serializedObj === LunchStatus.DONE
            ? LunchStatusActual.Done
            : serializedObj === LunchStatus.INPROGRESS
            ? LunchStatusActual.InProgress
            : serializedObj === LunchStatus.NONE
            ? LunchStatusActual.None
            : Types.assertUnreachable(serializedObj);
    }
}

export const lunchStatusDeserializer = new LunchStatusDeserializer();
