import React from 'react';
import {MapContext} from '../MapContainer/MapContextProvider';
import {Marker} from '../MapContainer/model/Marker';
import './PointsOnMap.css'
import { getNearPoints } from '../../helpers/intersections/nearPoints';
import { MathUtils } from '../../Std/MathUtils';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { IGeocode } from '../../Model/Geocode/IGeocode';

interface PointsOnMapProps<TEntity> {
    points: TEntity[];
    getCoordinate: (entity: TEntity) => IGeocode;
    getTooltip?: (entity: TEntity) => string | undefined;
    getColor?: (entity: TEntity) => string | undefined;
    unionDistance?: number;
    getUnitableGroupKey?: (entity: TEntity) => any;
    onClick?: (points: TEntity[]) => void;
    children?: React.ReactNode;
}

export const PointsOnMap = <TEntity extends unknown>(props: PointsOnMapProps<TEntity>): JSX.Element => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error('Map context is undefined');
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const {
        getCoordinate,
        getTooltip,
        getColor,
        unionDistance,
        getUnitableGroupKey,
        onClick
    } = props;

    const createMarker = (
        latitude: number,
        longitude: number,
        color?: string | undefined,
        points?: TEntity[] | undefined,
        content?: string,
        tooltip?: string | undefined,
        className?: string | undefined) => {
            const marker = new Marker(
                latitude,
                longitude,
                content,
                true,
                tooltip ? {
                    text: tooltip
                } :  undefined,
                color ? `border-color: ${color};` : undefined,
                `point-marker ${className ? className : ''}`)

            if (onClick && points) {
                marker.onClick(() => onClick(points))
            }

            return marker;
        }

    React.useEffect(() => {
        let markers: Marker[] = [];
        const traceMarkers: Marker[] = [];

        if (unionDistance) {
            ArrayUtils.groupBy(props.points, (x) => getUnitableGroupKey ? getUnitableGroupKey(x) : undefined)
                .forEach((entities: TEntity[]) => {
                    const nearCoords = getNearPoints(entities.map(x => props.getCoordinate(x)), unionDistance);
                    markers.push(...nearCoords.map((coords) => {
                        const tooltips = ArrayUtils.defined(coords.map(x => getTooltip && getTooltip(entities[x.index])));
                        
                        return createMarker(
                            MathUtils.average(coords.map(x => x.point.latitude)),
                            MathUtils.average(coords.map(x => x.point.longitude)),
                            props.getColor && props.getColor(entities[coords[0].index]),
                            coords.map(x => entities[x.index]),
                            `${coords.length > 1 ? coords.length : ' '}`,
                            tooltips.length > 0 ? tooltips.join("<br/>") : undefined,
                            coords.length > 1 ? 'with-content' : ''
                        )
                        }
                    ))

                    nearCoords.forEach((coords) => {
                        if (coords.length === 1) {
                            return;
                        }

                        coords.forEach((coord) => {
                            const entity = entities[coord.index];
                            traceMarkers.push(createMarker(
                                coord.point.latitude,
                                coord.point.longitude,
                                getColor && getColor(entity),
                                [entity],
                                undefined,
                                getTooltip && getTooltip(entity),
                                'trace'))
                        })
                    })
                });
        } else {
            markers = ArrayUtils.defined(props.points.map((point) => {
                const coords = getCoordinate(point);
                if (!coords) {
                    return undefined;
                }
                const color = getColor && getColor(point);
                const tooltip = getTooltip && getTooltip(point);

                return createMarker(
                    coords.latitude,
                    coords.longitude,
                    color,
                    [point],
                    ' ',
                    tooltip
                )
            }));
        }

        layoutContext.renderMarkers([...traceMarkers, ...markers]);
    }, [props.points])

    return (
        <>
            {props.children}
        </>
    );
};