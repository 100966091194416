import React from 'react'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { DistrictStrictIntervalStore } from '../../Store/DistrictStrictInterval/DistrictStrictIntervalStore';
import { DistrictStrictInterval } from '../../Store/DistrictStrictInterval/DistrictStrictInterval';
import './DistrictStrictIntervalEditor.css'

interface DistrictStrictIntervalEditorProps {
  store: DistrictStrictIntervalStore;
  onRemove: (district: DistrictStrictInterval) => Promise<void>;
}

const DistrictStrictIntervalEditor = observer((props: DistrictStrictIntervalEditorProps) => {
  const onRemove = (e: React.MouseEvent<HTMLElement>, district: DistrictStrictInterval) => {
    e.stopPropagation();

    Modal.confirm({
      title: 'Вы действительно хотите удалить район со строгим интервалом?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk: () => {
        props.onRemove(district);
      },
      onCancel: () => {
        /* */
      },
    });
  }

  return (
    <>
      {
        ArrayUtils.sort(props.store.entities, entity => entity.name.toUpperCase()).map((district) => (
          <div
            key={district.id}
            className={
              district.id === props.store.selectedEntityId
                ? 'district-strict-interval-list__item district-strict-interval-list__item_active'
                : 'district-strict-interval-list__item'
            }
            onClick={() => props.store.setSelectedEntityId(district.id)}
          >
            <div className='district-strict-interval-list__item_flex'>
              {
                district.name
                  ? district.name
                  : <span className='district-strict-interval-list__district-no-name'>Без названия</span>
              }
              <button
                className='ant-btn ant-btn-icon-only'>
                <DeleteOutlined onClick={(e) => onRemove(e, district)} />
              </button>
            </div>
          </div>
        ))
      }
    </>
  )
})

export default DistrictStrictIntervalEditor