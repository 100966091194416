import React from 'react';
import {observer} from "mobx-react";
import {DeleteOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {ReactComponent as StarIcon} from "../../../../icons/star.svg";
import {ArrayUtils} from "../../../../Std/ArrayUtils";
import {WarehouseStore} from "../../../../Store/WarehouseStore/WarehouseStore";
import {
    DeleteConfirmationDialog
} from "../../../DeleteConfirmationDialog/DeleteConfirmationDialog";
import './StoreListRoute.css'

interface StoreListProps {
    store: WarehouseStore;
}

const StoreList = observer((props: StoreListProps) => {
    const paramRoundNum = 5;
    const [markForDeletionWarehouse, setMarkForDeletionWarehouse] = React.useState<number | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const toggleIsMain = (e: React.MouseEvent<SVGElement>, id: number) => {
        e.stopPropagation();
        props.store.setMain(id);
    }

    const confirmDeleting = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        setMarkForDeletionWarehouse(id);
        setIsModalVisible(true);
    }

    const deleteWarehouse = () => {
        if (!markForDeletionWarehouse) {
            return
        }
        props.store.deleteWarehouse(markForDeletionWarehouse);
        setIsModalVisible(false);
    }

    return (
        <>
            {ArrayUtils.sort(props.store.warehouses, [w => w.id < 0 ? Number.MAX_SAFE_INTEGER : w.id])
                .filter(w => !w.isDeleted)
                .map(warehouse => (
                    <div key={warehouse.id} className='store-list__item'>
                        <div
                            onClick={() => props.store.setSelectedWarehouse(warehouse.id)}
                            className='store-list__item-header'
                        >
                            <div className='store-list__item-address'>
                                {warehouse.address
                                    ? warehouse.address
                                    :
                                    <span className='store-list__item_warning'>Введите адрес</span>
                                }
                            </div>
                            <div className='store-list__item-buttons'>
                                <StarIcon
                                    onClick={(e) => toggleIsMain(e, warehouse.id)}
                                    className={warehouse.isMain ? 'store-list__item-icon store-list__item-main-icon' : 'store-list__item-icon'}
                                />
                                <Button
                                    className='ant-btn ant-btn-icon-only store-list__item_delete'
                                    onClick={(e) => confirmDeleting(e, warehouse.id)}
                                    disabled={warehouse.isMain}
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </div>
                        </div>
                        {props.store.selectedWarehouse?.id === warehouse.id
                            ? (
                                <div className='store-list__item-coords'>
                                    Широта: {warehouse.coordinates.latitude.toFixed(paramRoundNum)},
                                    Долгота: {warehouse.coordinates.longitude.toFixed(paramRoundNum)}
                                </div>
                            )
                            : undefined
                        }
                    </div>
                ))}
            <DeleteConfirmationDialog
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={deleteWarehouse}
            />
        </>
    )
});

export default StoreList;