import { DateTime } from './DateTime';
import { IRange } from './IRange';
import { TimePeriod } from './TimePeriod';

export class DateTimePeriod implements IRange<DateTime> {
    public static readonly default = new DateTimePeriod(DateTime.default, DateTime.default);
    public readonly end: DateTime;
    public readonly start: DateTime;

    public constructor(start: DateTime, end: DateTime) {
        this.start = start;
        this.end = end;
    }

    public get totalSeconds(): number {
        return (this.end.toDate().valueOf() - this.start.toDate().valueOf()) / DateTime.millisecondsInSecond;
    }

    public get totalMinutes(): number {
        return this.totalSeconds / DateTime.secondsInMinute;
    }

    public getTimePeriod(): TimePeriod {
        return new TimePeriod(this.start, this.end);
    }

    public beforePeriod(dateTime: DateTime): boolean {
        return dateTime.toDate() < this.start.toDate();
    }

    public afterPeriod(dateTime: DateTime): boolean {
        return dateTime.toDate() > this.end.toDate();
    }

    public inPeriod(dateTime: DateTime): boolean {
        return !this.beforePeriod(dateTime) && !this.afterPeriod(dateTime);
    }
}
