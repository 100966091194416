import { CompanyApiInterval, CompanyApiTimeInterval } from '@/ApiClient/YosoCompany/models';
import { ArrayUtils } from '@/Std/ArrayUtils';
import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';

export class IntervalPatternStore {
    private _rows: CompanyApiInterval[] = [];

    public constructor(intervals: CompanyApiInterval[]) {
        this._rows = ArrayUtils.sort(intervals, (x) => Math.min(...x.daysOfWeek));
        for (const row of this._rows) {
            row.intervals = ArrayUtils.sort(row.intervals, (x) => x.begin);
        }
        makeAutoObservable(this);
    }

    public get rows(): CompanyApiInterval[] {
        return this._rows;
    }

    public get errors(): string[] {
        const err = [];

        const daysOfWeek = ArrayUtils.flatten(this._rows.map((x) => x.daysOfWeek));
        const intervals = ArrayUtils.flatten(this._rows.map((x) => x.intervals));

        if (daysOfWeek.length > ArrayUtils.unique(daysOfWeek, (a, b) => a === b).length) {
            err.push('Дни недели пересекаются');
        }

        for (const interval of intervals) {
            const begin = moment(interval.begin).set({ second: 0, millisecond: 0 });
            const end = moment(interval.end).set({ second: 0, millisecond: 0 });
            if (begin > end) {
                err.push('Есть невалидный интервал');
                break;
            }
        }

        return err;
    }

    public setDaysOfWeek = (row: CompanyApiInterval, value: number[]): void => {
        const foundRow = this.rows.find((x) => x === row);
        if (!foundRow) {
            return;
        }
        foundRow.daysOfWeek = value;
        this._rows = [...this._rows];
    };

    public removeInterval = (row: CompanyApiInterval, timeInterval: CompanyApiTimeInterval): void => {
        const foundInterval = this.rows.find((x) => x === row);
        if (!foundInterval) {
            return;
        }

        const index = foundInterval.intervals.indexOf(timeInterval);

        if (index > -1) {
            foundInterval.intervals.splice(index, 1);
        }

        this._rows = [...this._rows];
    };

    public setIntervalBegin = (row: CompanyApiInterval, timeInterval: CompanyApiTimeInterval, value: Moment): void => {
        const foundTimeInterval = this.findTimeInterval(row, timeInterval);
        if (!foundTimeInterval) {
            return;
        }
        foundTimeInterval.begin = value.toISOString();
        this._rows = [...this._rows];
    };

    public setIntervalEnd = (row: CompanyApiInterval, timeInterval: CompanyApiTimeInterval, value: Moment): void => {
        const foundTimeInterval = this.findTimeInterval(row, timeInterval);
        if (!foundTimeInterval) {
            return;
        }
        foundTimeInterval.end = value.toISOString();
        this._rows = [...this._rows];
    };

    public removeRow = (row: CompanyApiInterval): void => {
        const index = this._rows.indexOf(row);
        if (index > -1) {
            this._rows.splice(index, 1);
        }
    };

    public addRow = (): void => {
        this._rows.push({ id: 0, daysOfWeek: [], intervals: [] });
    };

    public addInterval = (row: CompanyApiInterval): void => {
        const foundRow = this.rows.find((x) => x === row);
        if (!foundRow) {
            return;
        }
        foundRow.intervals.push({
            begin: moment().set({ hour: 8, minute: 0 }).toISOString(),
            end: moment().set({ hour: 8, minute: 0 }).toISOString(),
        });
        this._rows = [...this._rows];
    };

    private findTimeInterval = (
        row: CompanyApiInterval,
        timeInterval: CompanyApiTimeInterval,
    ): CompanyApiTimeInterval | undefined => {
        const foundInterval = this.rows.find((x) => x === row);
        if (!foundInterval) {
            return undefined;
        }

        return foundInterval.intervals.find((x) => x === timeInterval);
    };
}
