import React from 'react';
import {HomeOutlined} from '@ant-design/icons';
import {ImportOrder} from '@/Model/ImportOrder/ImportOrder';
import { FormattedTimePeriod } from '../RouteInfo/RouteInfoFormattedValues/FormattedTimePeriod';
import styles from './ImportOrderAddressList.module.css';

interface ImportOrderAddressListProps {
    orders: ImportOrder[];
}

const ImportOrderAddressList = ({orders}: ImportOrderAddressListProps): JSX.Element => (
    <div className={styles.list}>
        {orders.map(o =>
            <div
                key={o.id}
                className={`${styles.item} ${!o.coords ? styles.warning : ''}`}
                data-coords={`${o.coords ? `${o.coords.latitude},${o.coords.longitude}` : ''}`}
            >
                <div className={styles.address}><HomeOutlined/> {o.address}</div>
                <div className={styles.period}><FormattedTimePeriod dateTimePeriod={o.period}/></div>
            </div>
        )}
    </div>
)

export default ImportOrderAddressList;