import { Car } from '../Car/Car';
import { Driver } from '../Driver/Driver';
import { TripWorkingShift } from '../Trip/TripWorkingShift';

export class TripExecutor {
    public static createDriverCarKey(driverCode: string, carNumber: string): string {
        return `${driverCode}---${carNumber}`;
    }

    public readonly car: Car;
    public readonly driver: Driver;
    public readonly workingShift: TripWorkingShift | undefined;

    public constructor(car: Car, driver: Driver, workingShift: TripWorkingShift | undefined) {
        this.car = car;
        this.driver = driver;
        this.workingShift = workingShift;
    }

    public get name(): string {
        return `${this.car.carNumber} ${this.driver.name}`;
    }

    public getId(): number {
        const offset = 1000000;
        const offset2 = 10;

        if (this.workingShift === undefined) {
            return this.car.id * offset + this.driver.id;
        }

        return (this.car.id * offset + this.driver.id) * offset2 + this.workingShift;
    }
}
