import { DeliveryApiCar } from '../../../../../ApiClient/Yoso/models';
import { Car } from '../../../../../Model/Car/Car';
import { StringUtils } from '../../../../../Std/StringUtils';

export class CarDeserializer {
    public deserialize(car: DeliveryApiCar): Car {
        if (car.id === undefined) {
            throw new Error('DeliveryApiCar id is undefined');
        }

        return new Car(car.id, StringUtils.trim(car.number), car.capacity);
    }
}

export const carDeserializer = new CarDeserializer();
