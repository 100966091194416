import * as React from 'react';
import { ProblemRouteType } from '@/Model/Trip/ProblemRouteType';
import { GatewayOutlined, ThunderboltOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import {
    LeafletMapRenderer,
} from '@/Map/Renderer/Leaflet/LeafletMapRenderer';
import { Route } from '@/Map/Route/Route';
import { OrderRoutePoint } from '@/Map/Route/RoutePoint/OrderRoutePoint';
import { RoutePoint } from '@/Map/Route/RoutePoint/RoutePoint';
import {
    CarTrackedLocations,
} from '@/Model/CarTrackedLocations/CarTrackedLocations';
import { District } from '@/Model/District/District';
import { IGeocode } from '@/Model/Geocode/IGeocode';
import { Store } from '@/Model/Store/Store';
import { TripPointType } from '@/Model/TripPointId/TripPointType';
import { ArrayUtils } from '@/Std/ArrayUtils';
import { dateTimeFormatWithSeconds } from '@/Std/dateTimeFormatWithSeconds';
import { formatTime } from '@/Std/formatTime';
import { formatTimePeriod } from '@/Std/FormatTimePeriod';
import { MapBounds } from '@/Std/MapBounds/MapBounds';
import { useCompany } from '../AppProviders/Providers/CompanyProvider';
import { Map } from '../Map/Map';
import { MapColor } from '../Map/MapColor';
import { MapElementState } from '../Map/MapElementState';
import { PlanMapCarMarker } from './PlanMapCarMarker';
import { PlanMapMarker } from './PlanMapMarker';
import { PlanMapPath } from './PlanMapPath';
import { PlanMapPolygon } from './PlanMapPolygon';
import './PlanMap.css';

export interface IPlanMapProps {
    actualRoutes: ReadonlyArray<CarTrackedLocations>;
    className?: string;
    routes: ReadonlyArray<Route | undefined>;
    stores?: ReadonlyArray<Store>,
    districts?: ReadonlyArray<District>,
    selectedItemIndex: number | undefined;
    selectedTripIndex: number | undefined;
    showOnlySelectedTrip?: boolean;
    withActualRoute: boolean;
    onItemSelect: (orderId: number) => void;
    onTripSelect?: (tripIndex: number) => void;
    setWithActualRoute: (value: boolean) => void;
}

export const PlanMap = (props: IPlanMapProps): JSX.Element => {
    const companyContext = useCompany();
    const { onItemSelect, onTripSelect } = props;

    const map: React.MutableRefObject<Map | null> = React.useRef(null);

    const getColor = (tripIndex: number): MapColor =>
            map.current === null ? MapColor.Blue : map.current.colorScheme.get(tripIndex)

    const getState = React.useCallback(
        (tripIndex: number, itemIndex: number | undefined): MapElementState => {
            if (itemIndex === undefined || props.selectedTripIndex === undefined) {
                return MapElementState.Normal;
            }
            if (tripIndex !== props.selectedTripIndex) {
                return MapElementState.Secondary;
            }
            if (props.selectedItemIndex === undefined) {
                return MapElementState.Normal;
            }
            if (itemIndex === props.selectedItemIndex) {
                return MapElementState.Selected;
            }

            return MapElementState.Normal;
        },
        [props.selectedTripIndex, props.selectedItemIndex],
    );

    const createDriverPlaceMarkers = (): (PlanMapMarker | PlanMapCarMarker)[] => {
      if (!props.routes || props.selectedTripIndex === undefined || !props.routes[props.selectedTripIndex]) {
        return [];
      }

      const route = props.routes[props.selectedTripIndex];
      if (!route) {
        return [];
      }

      const driverPlaceMarkers: (PlanMapMarker | PlanMapCarMarker)[] = [];
      for (const driverPoint of route.points) {
        if (!driverPoint) {
          continue;
        }

        if (driverPoint instanceof OrderRoutePoint && driverPoint.order.driverPlace) {
          driverPlaceMarkers.push(new PlanMapMarker(
            driverPoint.order.driverPlace,
            MapElementState.Normal,
            props.selectedTripIndex,
            getColor(props.selectedTripIndex),
            () => {/* */},
            undefined,
            true,
            true,
            undefined,
            'map__marker map___driver-place-marker'
          ))
        }
      }

      return driverPlaceMarkers;
    };

    const createMarkers = React.useCallback((): (PlanMapMarker | PlanMapCarMarker)[] => {
        const planRouteMarkers = ArrayUtils.flatten(
            props.routes.map((route, tripIndex) => {
                if (!route || props.showOnlySelectedTrip === true && tripIndex !== props.selectedTripIndex) {
                    return [];
                }

                return ArrayUtils.defined(route.points.map(
                    (point: RoutePoint, pointIndex: number): PlanMapMarker | undefined => {
                        if (props.stores && props.stores.length > 0 && (
                            point.type === TripPointType.Store ||
                            point.type === TripPointType.LoadingStore)) {
                            return undefined;
                        }

                        const itemIndex = route.all.indexOf(point);

                        const classes = ['map__marker'];
                        if (point.isNew) {
                            classes.push('map__marker_new');
                        }
                        if (!point.isOwn) {
                            classes.push('map__marker_not-own');
                        }

                        return new PlanMapMarker(
                            point.geocode,
                            getState(tripIndex, itemIndex),
                            tripIndex,
                            props.actualRoutes[tripIndex]?.locations.length > 0 && !point.actualArrivalDateTime
                             ? undefined
                             : getColor(tripIndex),
                            () => onItemSelect(point.originalId),
                            `${
                                point.expectedArrivalDateTime === undefined
                                    ? ''
                                    : `<b>${formatTime(point.expectedArrivalDateTime)}</b><br/>`
                            }
                            ${
                                point.desiredDateTimePeriod === undefined
                                    ? ''
                                    : formatTimePeriod(point.desiredDateTimePeriod.getTimePeriod())
                            }`,
                            true,
                            true,
                            point.hasLegs ? pointIndex : undefined,
                            classes.join(" ")
                        );
                    },
                ));
            }),
        );
        
        const actualRouteMilestoneMarkers = ArrayUtils.flatten(
            props.actualRoutes.map((actualRoute, tripIndex) => {
                if (props.showOnlySelectedTrip === true && tripIndex !== props.selectedTripIndex) {
                    return undefined;
                }

                return actualRoute.milestoneLocations.map((milestoneLocation, itemIndex): PlanMapMarker | undefined =>
                    tripIndex === props.selectedTripIndex && itemIndex !== actualRoute.milestoneLocations.length - 1
                        ? new PlanMapMarker(
                              milestoneLocation.geocode,
                              MapElementState.Secondary,
                              tripIndex,
                              getColor(tripIndex),
                              () => {
                                  /* */
                              },
                              dateTimeFormatWithSeconds.format(milestoneLocation.dateTime.toDate()),
                              false,
                              false,
                              undefined,
                              "map__marker map__geocode_marker"
                          )
                        : undefined,
                );
            }) as any,
        );

        const actualRouteCarMarkers = props.actualRoutes.map((actualRoute, tripIndex): PlanMapCarMarker | undefined => {
            if (
                props.showOnlySelectedTrip === true &&
                tripIndex !== props.selectedTripIndex &&
                props.selectedTripIndex !== undefined
            ) {
                return undefined;
            }

            const lastPoint = actualRoute.locations[actualRoute.locations.length - 1];
            if (lastPoint === undefined) {
                return undefined;
            }

            return new PlanMapCarMarker(
                lastPoint.geocode,
                MapElementState.Normal,
                tripIndex,
                getColor(tripIndex),
                () => {
                    if (onTripSelect !== undefined) {
                        onTripSelect(actualRoute.tripId);
                    }
                },
                actualRoute.carNumber
                ? `${actualRoute.carNumber}<br/>${actualRoute.driverName}`
                : actualRoute.driverName,
                false,
                true,
                0,
                actualRoute.problemRoute === ProblemRouteType.LATE30
                    ? 'orange'
                    : actualRoute.problemRoute === ProblemRouteType.LATE60
                        ? 'red' : undefined
            );
        }) as any;

        const storeMarkers = props.stores ? props.stores.map((store): PlanMapMarker => new PlanMapMarker(
                store.coordinates,
                MapElementState.Normal,
                props.selectedTripIndex || 0,
                undefined,
                () => {
                    //
                },
                store.address,
                true,
                true,
                undefined,
                "map__marker map__store_marker"
            )
        ) : [];

        return ArrayUtils.defined([...planRouteMarkers, ...actualRouteMilestoneMarkers, ...actualRouteCarMarkers, ...storeMarkers]);
    }, [
        props.routes,
        props.actualRoutes,
        props.showOnlySelectedTrip,
        props.selectedTripIndex,
        getState,
        getColor,
        onItemSelect,
        onTripSelect,
    ]);

    const [isRouteSimpleView, setRouteSimpleView] = React.useState(false);
    const [districtsVisible, setDistrictsVisible] = React.useState(false);

    const createDriverPlacePaths = React.useCallback((): PlanMapPath[] => {
      if (!props.routes || props.selectedTripIndex === undefined || !props.routes[props.selectedTripIndex]) {
        return [];
      }

      const driverPlacePaths: (PlanMapPath)[] = [];

      for (const driverPoint of props.routes[props.selectedTripIndex]!.points) {
        if (!driverPoint) {
          continue;
        }

        if (driverPoint instanceof OrderRoutePoint && driverPoint.order.driverPlace && driverPoint.order.place.geocode !== undefined) {
          const startGeocode = driverPoint.order.place.geocode;
          const endGeocode = driverPoint.order.driverPlace;
          const itemIndex = props.selectedTripIndex;
          const state = MapElementState.Thin;
          driverPlacePaths.push(new PlanMapPath(
            [{latitude: startGeocode.latitude, longitude: startGeocode.longitude},
              {latitude: endGeocode.latitude, longitude: endGeocode.longitude}],
            state,
            props.selectedTripIndex,
            itemIndex,
            getColor(props.selectedTripIndex),
            () => {
              // do nothing
            },
            true
          ))
        }
      }

      return driverPlacePaths;
    }, [
      props.routes,
      props.actualRoutes,
      props.showOnlySelectedTrip,
      props.selectedTripIndex,
      getState,
      getColor,
      onItemSelect,
    ]);

    const createPaths = React.useCallback((routeSimpleView : boolean): PlanMapPath[] => {
        const planRoutePaths = ArrayUtils.flatten(
            props.routes.map((trip, tripIndex) => {
                if (!trip || props.showOnlySelectedTrip === true && tripIndex !== props.selectedTripIndex) {
                    return [];
                }

                if (routeSimpleView) {
                    const tripPoints = trip.points.filter((x) => x.expectedArrivalDateTime);
                    const path = [];
                    for(let i = 1; i < tripPoints.length; ++i) {
                        const start = tripPoints[i-1];
                        const end = tripPoints[i];
                        const itemIndex = trip.all.indexOf(end);
                        const state = getState(tripIndex, itemIndex);
                        path.push(new PlanMapPath(
                            [
                                start.driverGeocode ? start.driverGeocode : start.geocode,
                                end.driverGeocode ? end.driverGeocode :end.geocode
                            ],
                            state,
                            tripIndex,
                            itemIndex,
                            props.actualRoutes[tripIndex]?.locations.length > 0 ? undefined : getColor(tripIndex),
                            () => {
                                // do nothing
                            },
                            true
                        ));
                    }

                    return path;
                }

                return ArrayUtils.defined(
                    trip.legs.map((leg) => {
                        if (leg === undefined) {
                            return undefined;
                        }
                        const itemIndex = trip.all.indexOf(leg);

                        const state = getState(tripIndex, itemIndex);

                        return new PlanMapPath(
                            leg.waypoints.map((waypoint) => waypoint),
                            state,
                            tripIndex,
                            itemIndex,
                            props.actualRoutes[tripIndex]?.locations.length > 0 ? undefined : getColor(tripIndex),
                            () => {
                                // if (onItemSelect !== undefined) {
                                //     onItemSelect(tripIndex, itemIndex);
                                // }
                            },
                            true,
                        );
                    }),
                );
            }),
        );
        const actualRoutePaths = ArrayUtils.defined(
            props.actualRoutes.map((actualRoute, tripIndex) => {
                if (props.showOnlySelectedTrip === true && tripIndex !== props.selectedTripIndex) {
                    return undefined;
                }

                return tripIndex === props.selectedTripIndex
                    ? new PlanMapPath(
                          actualRoute.locations.map((location) => location.geocode),
                          MapElementState.Normal,
                          tripIndex,
                          undefined,
                          getColor(tripIndex),
                          () => {
                              //
                          },
                          false,
                      )
                    : undefined;
            }),
        );

        return [...planRoutePaths, ...actualRoutePaths] as any;
    }, [
        props.routes,
        props.actualRoutes,
        props.showOnlySelectedTrip,
        props.selectedTripIndex,
        getState,
        getColor,
        onItemSelect,
    ]);

    const createPolygons = React.useCallback((): PlanMapPolygon[] => {
        const districts = props.districts && districtsVisible ? props.districts.map((district) => 
            new PlanMapPolygon(district.geocodes, district.color)) : [];

        return districts;
    }, [props.districts, districtsVisible]);

    const [markers, setMarkers] = React.useState([] as (PlanMapMarker | PlanMapCarMarker)[]);
    const [paths, setPaths] = React.useState(createPaths(isRouteSimpleView));
    const [polygons, setPolygons] = React.useState([] as PlanMapPolygon[]);

    React.useEffect(() => {
        markers.forEach((marker) => {
            marker.destroy();
        });
        setMarkers([...createMarkers(), ...createDriverPlaceMarkers()]);
        paths.forEach((path) => {
            path.destroy();
        });
        setPaths([...createPaths(isRouteSimpleView), ...createDriverPlacePaths()]);
    }, [
        props.showOnlySelectedTrip,
        props.routes,
        props.actualRoutes,
        props.selectedTripIndex,
        props.selectedItemIndex,
        isRouteSimpleView,
    ]);

    React.useEffect(() => {
        setPolygons(createPolygons());
    }, [props.districts, districtsVisible]);

    const [firstDataLoaded, setFirstDataLoaded] = React.useState(false as boolean);

    React.useEffect(() => {
        if (!firstDataLoaded && props.routes.length > 0 && props.actualRoutes.length > 0) {
            setTimeout(() => {
                map.current?.fitBounds();
            }, 0);
            setFirstDataLoaded(true);
        }
    }, [props.routes, props.actualRoutes]);

    React.useEffect(() => {
        if (props.selectedTripIndex !== undefined) {
            setFirstDataLoaded(false);
        }
    }, [props.selectedTripIndex])

    const switchRouteView = React.useCallback(() => {
        setRouteSimpleView(!isRouteSimpleView);
    }, [isRouteSimpleView]);

    const switchDistrictsVisibility = React.useCallback(() => {
        setDistrictsVisible(!districtsVisible);
    }, [districtsVisible]);

    const switchWithActualRoute = React.useCallback(() => {
        props.setWithActualRoute(!props.withActualRoute);
    }, [props.withActualRoute]);

    const getMapBounds = React.useCallback((): MapBounds | undefined => {
        let geocodes: IGeocode[] = props.routes.length === 0 && props.actualRoutes.length === 0
                        ? []
                        : ArrayUtils.defined(ArrayUtils.flatten([
                                props.routes.map((route) => route ? route.startPoint.geocode : undefined),
                                props.routes.map((route) => route ? route.finishPoint.geocode : undefined),
                                ...[
                                    ...props.routes.map((route) =>
                                        route ? route.routePoints.map((checkpoint) => checkpoint.geocode) : [],
                                    ),
                                    ...props.actualRoutes.map((actualRoute) =>
                                        actualRoute.locations.map((location) => location.geocode),
                                    ),
                                ],
                            ]));

        if (!geocodes.length && companyContext.company !== undefined) {
            geocodes = companyContext.company.getMapBounds();
        }

        return geocodes.length ? new MapBounds(geocodes) : undefined;
    }, [props.routes, props.actualRoutes]);

    return (
        <>
            <ThunderboltOutlined className={classNames(
                "plan-map__button",
                "plan-map__view-mode",
                isRouteSimpleView ? 'active' : ''
                )} onClick={switchRouteView}/>
            {props.districts && props.districts.length !== 0 &&
                <GatewayOutlined className={classNames(
                    "plan-map__button",
                    "plan-map__districts-visibility",
                    districtsVisible ? 'active' : ''
                )} onClick={switchDistrictsVisibility}/>
            }
            <span
                title='Подгрузить gps-трек'
                className={classNames(
                    "plan-map__button",
                    "map__button plan-map__actual-route-button",
                    props.withActualRoute ? 'active' : ''
                )} onClick={switchWithActualRoute}>GPS</span>
            <Map
                ref={map}
                className={props.className}
                markers={markers}
                paths={paths}
                polygons={polygons}
                startBounds={getMapBounds()}
                renderer={LeafletMapRenderer}
                isConfirmationMarker={false}
            />
        </>
    );
};
