import {
    DeliveryApiTripPrediction
} from '../../../../../ApiClient/Yoso/models';
import {
    TripPrediction
} from '../../../../../Model/TripPrediction/TripPrediction';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';


export class TripPredictionDeserializer {
    public deserialize(serializedObj: DeliveryApiTripPrediction): TripPrediction {
        return ({
            id: serializedObj.id,
            createdAt: dateTimeDeserializer.deserialize(serializedObj.createdAt)
        })
    }
}

export const tripPredictionDeserializer = new TripPredictionDeserializer();