import { CompanyApiDriverResponse } from '@/ApiClient/YosoCompany/models';
import { dateTimeDeserializer } from '@/Backend/Api/Mapper/DateTimeDeserializer';
import { Driver } from '@/Model/Driver/Driver';
import { Employee } from '@/Model/Employee/Employee';

export class EmployeeDeserializer {
    public mapFromApi(apiEmployee: CompanyApiDriverResponse): Employee {
        return ({
            driver: new Driver(
                apiEmployee.employee.id,
                apiEmployee.employee.code || '',
                apiEmployee.employee.name,
                apiEmployee.extra.driverProficiency,
                apiEmployee.employee.enableDeliveryPrediction,
                apiEmployee.employee.credentials?.username,
                apiEmployee.employee.deletedAt
                    ? dateTimeDeserializer.deserialize(apiEmployee.employee.deletedAt)
                    : undefined
                ).setIsActive(apiEmployee.employee.isActive),
            settings: ({
                driverProficiency: apiEmployee.extra.driverProficiency,
                unloadingTimeFactor: apiEmployee.extra.unloadingTimeFactor,
                loadingStoreId : apiEmployee.extra.loadingStoreId
            }),
            averageOrdersCountPerHour: apiEmployee.averageOrdersCountPerHour
                ? apiEmployee.averageOrdersCountPerHour.map(i => ({endTime: i.endTime, value: i.value}))
                : []
        })
    }
}

export const employeeDeserializer = new EmployeeDeserializer()