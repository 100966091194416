import React from 'react';
import { List, Popover, Spin } from 'antd';
import { TripEditor } from '@/Components/TripPlanRealtime/ui/TripEditor/TripEditor';
import { TripStatusComponent } from '@/Components/TripStatus';
import { ReactComponent as CalendarIcon } from '@/icons/calendar.svg';
import { ReactComponent as CheckpointIcon } from '@/icons/checkpoint.svg';
import { ReactComponent as FailedIcon } from '@/icons/failed.svg';
import { ReactComponent as OkIcon } from '@/icons/ok.svg';
import { Trip } from '@/Model';
import { TripStatus } from '@/Model/Trip/TripStatus';
import { TripExecutor } from '@/Model/TripExecutor/TripExecutor';
import { RightOutlined } from '@ant-design/icons';
import { isInViewport } from '@/helpers/isInViewport/isInViewport';
import {
    PlanAction,
    PlanActionTitles,
} from '../../types/tripPlanRealtimeTypes';
import { WorkingShiftIcon } from '../WorkingShiftIcon/WorkingShiftIcon';
import styles from './TripCard.module.css';

interface TripCardProps {
    color: string;
    trip: Trip;
    getItinerary: (id: number) => void;
    onResumeTrip: (id: number) => void;
    onSelect: (tripId: number) => void;
    loading: boolean;
    selectedTripId?: number;
    planDrivers: TripExecutor[];
    trips: Trip[];
    onReplaceDriverInTrip: (ripId: number, driverId: number) => void;
    onSelectOrder: (orderId: number) => void;
    selectedOrderId?: number;
    onTransferOrders: (toTripId: number, orderIds: number[]) => void;
}

export const TripCard = (props: TripCardProps) => {
    const {
        color,
        loading,
        trip,
        trips,
        planDrivers,
        selectedOrderId,
        onSelect,
        getItinerary,
        selectedTripId,
        onReplaceDriverInTrip,
        onSelectOrder,
        onTransferOrders,
        onResumeTrip
    } = props;

    const ref = React.useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        if (selectedTripId !== trip.id) {
            return undefined;
        }

        if (!ref.current) {
            return undefined;
        }

        if (!isInViewport(ref.current)) {
            ref.current?.scrollIntoView();
        }
    }, [selectedTripId])

    const handleOpenChange = (newOpen: boolean) => {
        setIsOpen(newOpen);
    };

    const openItinerary = (e: any) => {
        e.stopPropagation();
        setIsOpen(false)
        getItinerary(trip.id);
    };

    const resumeTrip = (e: any) => {
        e.stopPropagation();
        setIsOpen(false);
        onResumeTrip(trip.id);
    };

    const actions: PlanAction[] = [
        { name: PlanActionTitles.OpenItinerary, effect: openItinerary },
        { name: PlanActionTitles.ResumeTrip, effect: resumeTrip },
    ];

    const filteredActions = React.useMemo(() => {
        if (trip.status !== TripStatus.Done) {
            return actions.filter(i => i.name !== PlanActionTitles.ResumeTrip);
        }

        return actions;
    }, [trip.status]);

    return (
        <>
            <div
                key={trip.id}
                ref={ref}
                data-id={trip.id}
                className={styles.wrapper}
                onClick={() => onSelect(trip.id)}
            >
                <div className={styles.trip}>
                    <div className={styles.tripColor}>
                        <div style={{background: color}} className={styles.color} />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.status}>
                            <WorkingShiftIcon
                                workingShift={trip.workingShift} />
                            <TripStatusComponent status={trip.status} />
                        </div>

                        <div
                            className={styles.driver}
                            title={trip.executor.driver.name}
                        >
                            {trip.executor.driver.name}
                        </div>
                    </div>
                    <div className={styles.ordersCounter}>
                        <div>
                            <span><CheckpointIcon />{trip.ordersCount}</span>
                            <span><OkIcon />{trip.completedOrdersCount}</span>
                        </div>
                        <div>
                            <span><FailedIcon />{trip.failedOrdersCount}</span>
                            <span><CalendarIcon />{trip.rescheduledOrdersCount}</span>
                        </div>
                    </div>
                    <Popover
                        trigger='click'
                        visible={isOpen}
                        onVisibleChange={handleOpenChange}
                        content={
                            <List
                                size='small'
                                dataSource={filteredActions}
                                renderItem={item => (
                                    <List.Item
                                        onClick={item.effect}
                                        className={styles.action}
                                    >
                                        {item.name}
                                    </List.Item>
                                )}
                            />
                        }
                    >
                        <div
                            className={styles.arrow}
                            onClick={e => e.stopPropagation()}
                        >
                            <RightOutlined />
                        </div>
                    </Popover>
                </div>
            </div>
            {loading && selectedTripId === trip.id &&
                <div className={styles.spinner}>
                    <Spin size='large' />
                </div>
            }
            {!loading && trip.id === selectedTripId &&
                <TripEditor
                    trip={trip}
                    planDrivers={planDrivers}
                    allTrips={trips}
                    changeDriverTrip={onReplaceDriverInTrip}
                    setTripItemIndex={onSelectOrder}
                    selectedOrderId={selectedOrderId}
                    transferOrders={onTransferOrders}
                />
            }
        </>
    );
};
