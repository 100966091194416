import React from 'react';
import {RedoOutlined} from '@ant-design/icons';
import {Button, Modal, Pagination} from 'antd';
import {withRouter} from 'react-router-dom';
import {DeliveryApi} from '../../../Backend/Api/Delivery/DeliveryApi';
import {TripPlanSummary} from '../../../Model/TripPlan/TripPlanSummary';
import {ArrayUtils} from '../../../Std/ArrayUtils';
import {formatDate, formatDateReverse} from '../../../Std/formatDate';
import {formatDateTime} from '../../../Std/formatDateTime';
import {formatTimePeriod} from '../../../Std/FormatTimePeriod';
import {Page} from '../../Page/Page';
import './DeletedPlanListRoute.css'

interface IDeletedPlanList {
    arrPlans: TripPlanSummary[][];
    total: number;
}

export const DeletedPlanListRoute = withRouter(() => {
    const tableHeaderTitles = ['Дата рейса', 'Время', 'Количество машин', 'Время создания', ''];
    const numberOfDays = 7;

    const [plans, setPlans] = React.useState<IDeletedPlanList | undefined>(undefined);
    const [numPage, setNumPage] = React.useState(1);

    React.useEffect(() => {
        getDeletedPlans(numPage, numberOfDays);
    }, [numPage])

    const restorePlan = async (id: number) => {
        try {
            await DeliveryApi.restorePlan(id);
            await getDeletedPlans(numPage, numberOfDays);
        } catch (error) {
            alert(String(error));
        }
    }

    const confirmRestorePlan = (e: React.MouseEvent, id: number) => {
        Modal.confirm({
            title: 'Восстановить план',
            content: 'Вы действительно хотите восстановить удаленный план?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk: async () => {
                await restorePlan(id);
            }
        })
    }

    const getDeletedPlans = async (page: number, size: number) => {
        let loadingPlans: TripPlanSummary[] = [];
        let pages = 0;

        await DeliveryApi.getDeletedPlans(size, (page - 1) * size).then((response) => {
            loadingPlans = response.plans.map(plan => plan);
            pages = response.total;
        }).catch(error => {
            alert(String(error));
        });

        const groupsPlans = groupingPlansByStartDate(loadingPlans);

        setPlans({
            arrPlans: groupsPlans,
            total: pages
        });
    }

    const groupingPlansByStartDate = (arrPlans: TripPlanSummary[]) => {
        const groups: TripPlanSummary[][] = [];

        for (const element of arrPlans) {
            let isGroupExist = false;

            for (const group of groups) {
                const existingGroups = group.filter(
                    (g: TripPlanSummary) => formatDateReverse(g.dateTimePeriod.start) === formatDateReverse(element.dateTimePeriod.start)
                )

                if (existingGroups.length > 0) {
                    isGroupExist = true;
                    group.push(element);

                    break;
                }
            }

            if (!isGroupExist) {
                const newGroup: TripPlanSummary[] = [element];
                groups.push(newGroup);
            }
        }

        return ArrayUtils.sort(groups, g => formatDateReverse(g[0].dateTimePeriod.start), false, false);
    }

    return (
        <Page header='Удаленные планы'>

            <Pagination
                showSizeChanger={false}
                current={numPage}
                total={plans?.total}
                onChange={setNumPage}
            />

            <section className='deleted-plan-list__data'>
                <header
                    className='deleted-plan-list__plans-group-header'>
                    {tableHeaderTitles.map(header => <div
                        key={header}>{header}</div>
                    )}
                </header>
                {plans?.arrPlans.map(planGroups => (
                    <div
                        key={formatDate(planGroups[0].dateTimePeriod.start)}
                        className='deleted-plan-list__plans-group'
                    >
                        <div className='deleted-plan-list__plans-group-date'>
                            <span style={{marginRight: 20}}>
                                {formatDate(planGroups[0].dateTimePeriod.start)}
                            </span>
                        </div>
                        <div className='deleted-plan-list__plans'>
                            {planGroups.map(plan => (
                                <div
                                    key={plan.id}
                                    className='deleted-plan-list__plan'
                                >
                                    <div>{formatTimePeriod(plan.dateTimePeriod.getTimePeriod())}</div>
                                    <div>{plan.orderCount}</div>
                                    <div>{plan.createdAt !== undefined ? formatDateTime(plan.createdAt) : undefined}</div>
                                    <div>
                                        <Button
                                            onClick={(e) => confirmRestorePlan(e, plan.id)}
                                        >
                                            <RedoOutlined/>
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </Page>
    );
});
