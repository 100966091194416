import L from 'leaflet';
import React, { ReactNode } from 'react';
import { Marker, Popup } from 'react-leaflet';
import './PopupOnMap.css';

interface IPopupOnMapProps {
  position: L.LatLngExpression | undefined;
  children?: ReactNode;
  customIcon?: any;
  closeOnClick?: boolean;
}

const PopupOnMap = (props: IPopupOnMapProps) => {
  const ZERO: L.LatLngExpression = {lat: 0, lng: 0};
  const markerRef = React.useRef<L.Marker>(null);
  
  const icon = React.useMemo(() => new L.Icon({
    iconUrl: 'none',
    iconRetinaUrl: 'none',
    iconSize: new L.Point(0, 0),
    className: 'leaflet-div-icon'
  }), []);

  React.useEffect(() => {
    if (props.position) {
      markerRef.current?.openPopup();
    } else {
      markerRef.current?.closePopup();
    }
  }, [props.position])

  return props.position ? (
    <Marker
        icon={props.customIcon? props.customIcon : icon}
        position={props.position ? props.position : ZERO}
        ref={markerRef}
    >
      <Popup minWidth={200} closeButton={false} closeOnClick={props.closeOnClick} >
        {props.children}
      </Popup>
    </Marker>
  ) : null;
}

export default PopupOnMap