import { ApiItineraryProductMovementRow, DeliveryApiProduct } from "../../../../../ApiClient/Yoso/models";
import { ItineraryProductMovementRow } from "../../../../../Model/Itinerary/ItineraryProductMovementRow";

export class ProductMovementRowDeserializer {
    public deserialize(response: ApiItineraryProductMovementRow, products: DeliveryApiProduct[]): ItineraryProductMovementRow {
        return new ItineraryProductMovementRow(
            response.productId,
            products.find(p => p.id === response.productId)?.name!,
            response.quantity
        )
    }
}

export const productMovementRowDeserializer = new ProductMovementRowDeserializer();