import React from 'react';
import { observer } from 'mobx-react';
import {
    BranchesOutlined,
    CarOutlined,
    DeleteOutlined,
    FileTextOutlined,
    GatewayOutlined,
    GoldOutlined,
    LineChartOutlined,
    MessageOutlined,
    RiseOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { NoticeMailsStore } from '../../../Store/NoticeMailsStore/NoticeMailsStore';
import { useClientPreferences } from '../../AppProviders/Providers/ClientPreferencesProvider/ClientPreferencesProvider';
import { AppMenuProps } from '../AppMenu';
import Mailbox from './components/Mailbox';

const LogisticianAppMenu = observer((props: AppMenuProps) => {
    const updateInterval = 60000;

    const localStorage = useClientPreferences();

    const store = React.useMemo(() => new NoticeMailsStore(localStorage), []);

    const [isMailboxVisible, setIsMailboxVisible] = React.useState(false);

    React.useEffect(() => {
        if (!store.isMailboxInit) {
            return undefined;
        }

        let timerId: NodeJS.Timeout;
        const scheduleUpdate = () => {
            store.fetchNewMails();
            timerId = setTimeout(scheduleUpdate, updateInterval);
        };
        scheduleUpdate();

        return () => clearTimeout(timerId);
    }, [store.isMailboxInit]);

    return (
        <div>
            <Menu className="app-menu" mode="inline" theme="dark" selectedKeys={props.selectedKeys}>
                <Menu.Item key="/plans" className="app-menu__item">
                    <RiseOutlined />
                    <span>Планы рейсов</span>
                    <Link to="/plans" />
                </Menu.Item>
                <Menu.Item key="/zone" className="app-menu__item">
                    <GatewayOutlined />
                    <span>Зоны</span>
                    <Link to="/zone" />
                </Menu.Item>
                <Menu.Item key="/workingDays" className="app-menu__item">
                    <BranchesOutlined />
                    <span>Рабочие дни</span>
                    <Link to="/workingDays" />
                </Menu.Item>
                {/* <Menu.Item key="/placeValidation" className="app-menu__item">
                    <EnvironmentOutlined />
                    <span>Проблемные геокоды</span>
                    <Link to="/placeValidation" />
                </Menu.Item> */}
                <Menu.Item key="/drivers" className="app-menu__item">
                    <UserOutlined />
                    <span>Водители</span>
                    <Link to="/drivers" />
                </Menu.Item>
                <Menu.Item key="/cars" className="app-menu__item">
                    <CarOutlined />
                    <span>Автомобили</span>
                    <Link to="/cars" />
                </Menu.Item>
                <Menu.Item key="/products" className="app-menu__item">
                    <GoldOutlined />
                    <span>Продукты</span>
                    <Link to="/products" />
                </Menu.Item>
                <Menu.Item key="/charts" className="app-menu__item">
                    <LineChartOutlined />
                    <span>Статистика</span>
                    <Link to="/charts" />
                </Menu.Item>
                <Menu.Item key="/logs" className="app-menu__item">
                    <FileTextOutlined />
                    <span>Ошибки</span>
                    <Link to="/logs" />
                </Menu.Item>
                <Menu.Item key="/deletedPlans" className="app-menu__item app-menu__item_delimiter">
                    <DeleteOutlined />
                    <span>Удаленные планы</span>
                    <Link to="/deletedPlans" />
                </Menu.Item>
                {/*<Menu.Item key='/trip-comparisons'
                           className='app-menu__item app-menu__item_delimiter'>
                    <ScanOutlined />
                    <span>Сравнение маршрутов</span>
                    <Link to='/trip-comparisons' />
                </Menu.Item>*/}
                <Menu.Item key="/notice-mails" className="app-menu__item" onClick={() => setIsMailboxVisible(true)}>
                    <div className="app-menu__item_relative">
                        <MessageOutlined />
                        <span>Уведомления</span>
                        {store.hasUnreadMails && <div className="app-menu__message-counter" />}
                    </div>
                </Menu.Item>
            </Menu>
            <Mailbox isVisible={isMailboxVisible} onClose={() => setIsMailboxVisible(false)} store={store} />
        </div>
    );
});

export default LogisticianAppMenu;
