import { Skeleton } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { CompanyApiLogistResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { LogisticianEntityEdit } from '../../../LogisticianEntityEdit/LogisticianEntityEdit';
import { Page } from '../../../Page/Page';

export interface ILogisticianEditRouteProps extends RouteComponentProps {
    id: number | undefined;
}

export const LogisticianEditRoute = withRouter(
    (props: ILogisticianEditRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(props.id !== undefined);
        const [logistician, setLogistician] = React.useState(undefined as CompanyApiLogistResponse | undefined);

        const fetchData = async (id: number): Promise<undefined> => {
            setLoading(true);
            try {
                const response = await CompanyApi.getLogistician(id);
                if (destroyed.current) {
                    return;
                }
                setLogistician(response);
            } catch (error) {
                if (destroyed.current) {
                    return;
                }
                alert(String(error));
            }
            setLoading(false);
        };

        React.useEffect((): void => {
            if (props.id !== undefined) {
                fetchData(props.id).then(
                    () => {
                        //
                    },
                    () => {
                        //
                    },
                );
            }
        }, [props.id]);

        const handleSaveLogistician = React.useCallback((): void => {
            props.history.push(`/logisticians`);
        }, [props.history]);

        return (
            <Page
                header={
                    props.id === undefined
                        ? 'Новый логист'
                        : logistician === undefined
                        ? ''
                        : `Логист ${logistician.employee!.name}`
                }
            >
                {loading ? (
                    <Skeleton active round title={{ width: 400 }} />
                ) : (
                    <LogisticianEntityEdit
                        logistician={
                            logistician === undefined
                                ? undefined
                                : {
                                      logistician: logistician,
                                      id: props.id === undefined ? logistician.employee!.id : props.id,
                                  }
                        }
                        onSave={handleSaveLogistician}
                    />
                )}
            </Page>
        );
    },
);
