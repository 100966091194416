import React from 'react';
import classNames from 'classnames';
import { Button, Pagination } from 'antd';
import { Trip, TripWorkingShift } from '@/Model';
import { ReactComponent as PersonCardIconComponent } from '@/icons/person-card.svg';
import styles from './TransferOrder.module.css'

interface TransferOrderProps {
    trips: Trip[];
    onSubmit: (id: number) => void;
}

export const TransferOrder = (props: TransferOrderProps) => {
    const {trips, onSubmit} = props

    const countElementsOnPage = 15;

    const [pageNumber, setPageNumber] = React.useState(1);
    const [selectedTripId, setSelectedTripId] = React.useState<number | undefined>(undefined)

    const submit = () => {
        if (!selectedTripId) {
            return
        }
        onSubmit(selectedTripId)
    }

    return (
        <div>
            <div className={styles.title}>Выберите водителя</div>
            <Pagination
                current={pageNumber}
                pageSize={countElementsOnPage}
                total={trips.length}
                onChange={setPageNumber}
                className={styles.pagination}
            />
            {trips
                .slice((pageNumber - 1) * countElementsOnPage, pageNumber * countElementsOnPage)
                .map(i =>
                    <div
                        key={i.id}
                        onClick={() => setSelectedTripId(i.id)}
                        className={classNames(styles.item, {
                            [styles.gray]: i.workingShift === TripWorkingShift.EVENING,
                            [styles.selected]: selectedTripId === i.id,
                        })}
                    >
                        <div className={styles.iconWrapper}>
                            <PersonCardIconComponent className={styles.icon} />
                        </div>
                        <div className={styles.name}>{i.executor.driver.name}</div>
                    </div>
            )}
            <Button
                type="primary"
                className="change-executor__block__change-executor-button"
                onClick={submit}
                disabled={selectedTripId === undefined}
            >Передать заказ</Button>
        </div>
    );
};
