import { Skeleton } from 'antd';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { CompanyApiBotResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { BotEntityEdit } from '../../../BotEntityEdit/BotEntityEdit';
import { Page } from '../../../Page/Page';

export interface IBotEditRouteProps extends RouteComponentProps {
    id: number | undefined;
}

export const BotEditRoute = withRouter(
    (props: IBotEditRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(props.id !== undefined);
        const [bot, setBot] = React.useState(undefined as CompanyApiBotResponse | undefined);

        const fetchData = async (id: number): Promise<undefined> => {
            setLoading(true);
            try {
                const response = await CompanyApi.getLogistician(id);
                if (destroyed.current) {
                    return;
                }
                setBot(response);
            } catch (error) {
                if (destroyed.current) {
                    return;
                }
                alert(String(error));
            }
            setLoading(false);
        };

        React.useEffect((): void => {
            if (props.id !== undefined) {
                fetchData(props.id).then(
                    () => {
                        //
                    },
                    () => {
                        //
                    },
                );
            }
        }, [props.id]);

        const handleSaveBot = React.useCallback((): void => {
            props.history.push(`/bots`);
        }, [props.history]);

        return (
            <Page
                header={
                    props.id === undefined
                        ? 'Новый бот'
                        : bot === undefined
                        ? ''
                        : `Бот ${bot.employee!.name}`
                }
            >
                {loading ? (
                    <Skeleton active round title={{ width: 400 }} />
                ) : (
                    <BotEntityEdit
                        bot={
                            bot === undefined
                                ? undefined
                                : {
                                    bot: bot,
                                      id: props.id === undefined ? bot.employee!.id : props.id,
                                  }
                        }
                        onSave={handleSaveBot}
                    />
                )}
            </Page>
        );
    },
);
