import React from 'react';
import { observer } from 'mobx-react';
import {Geocode} from '../../Model/Geocode/Geocode';
import {Store} from '../../Model/Store/Store';
import {
    TripComparisonOrder
} from '../../Model/TripComparison/TripComparisonOrder';
import {TripComparisonStore} from '../../Store/TripComparisonStore/TripComparisonStore';
import {MapContext} from '../MapContainer/MapContextProvider';
import {Marker} from '../MapContainer/model/Marker';
import {Path} from '../MapContainer/model/Path';
import { formatTimePeriod } from '../../Std/FormatTimePeriod';
import styles from './DriverRouteEditor.module.css';

interface DriverRouteEditorProps {
    store: TripComparisonStore;
}

const DriverRouteEditor = observer((props: DriverRouteEditorProps) => {
    const {store} = props;

    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const drawContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const getCoordinates = (point: TripComparisonOrder | Store): Geocode => {
        if (point instanceof TripComparisonOrder) {
            if (point.driverPlace) {
                return point.driverPlace.coords;
            }

            return point.coords;
        }

        return point.coordinates;
    }

    React.useEffect(() => {
        if (!store.routeEditing) {
            return undefined;
        }

        let loadingStoreInSequence = false;

        const sequenceMarkers = store.routeEditing.sequence.map((p: TripComparisonOrder | Store, index: number) => {
            const geocode: Geocode = getCoordinates(p);
            let content: string;

            if (p instanceof TripComparisonOrder) {
                content = (index + 1).toString();
            } else {
                loadingStoreInSequence = true;
                content = 's';
            }

            const mapMarker = new Marker(
                geocode.latitude,
                geocode.longitude,
                content,
                true,
                undefined,
                undefined,
                styles.marker
            )

            mapMarker.onRightClick(() => store.routeEditing?.removeFromSequence(p));

            return mapMarker;
        })

        const groups: {[key: number]: TripComparisonOrder[]} = {};

        props.store.orders.forEach(o => {
            if (!o.driverPlace || !o.driverPlace.id) {
                return;
            }

            if (!(o.driverPlace.id in groups)) {
                groups[o.driverPlace.id] = [];
            }

            groups[o.driverPlace.id].push(o);
        })

        const markers = [];
        Object.keys(groups).forEach(driverPlaceId => {
            const groupDriverPlaces = groups[Number(driverPlaceId)];
            const order = groupDriverPlaces[0];
            const mapMarker = new Marker(
                order.driverPlace!.coords.latitude,
                order.driverPlace!.coords.longitude,
                "в",
                true,
                {
                    text: groupDriverPlaces.map(o => `[${formatTimePeriod(o.dateTimePeriod)}]`).join("")
                },
                undefined,
                styles.marker
            )

            markers.push(mapMarker);

            mapMarker.onClick(() => {
                groupDriverPlaces.forEach(i => store.routeEditing?.addToSequence(i))
            });
        });

        markers.push(...sequenceMarkers);

        if (store.routeEditing.startPoint && store.routeEditing.finishPoint) {
            markers.push(new Marker(
                store.routeEditing.startPoint.coords.latitude,
                store.routeEditing.startPoint.coords.longitude,
                'o',
                true,
                {text: 'старт'},
                undefined,
                styles.marker
            ))

            markers.push(new Marker(
                store.routeEditing.finishPoint.coords.latitude,
                store.routeEditing.finishPoint.coords.longitude,
                'x',
                true,
                {text: 'финиш'},
                undefined,
                styles.marker
            ))
        }

        if (store.routeEditing.loadingStore && !loadingStoreInSequence) {
            const mapMarker = new Marker(
                store.routeEditing.loadingStore.coordinates.latitude,
                store.routeEditing.loadingStore.coordinates.longitude,
                's',
                true,
                {text: store.routeEditing.loadingStore.address},
                undefined,
                styles.marker
            )

            mapMarker.onClick(() => {
                if (!store.routeEditing?.loadingStore) {
                    return;
                }
                store.routeEditing?.addToSequence(store.routeEditing.loadingStore);
            });

            markers.push(mapMarker);
        }

        const paths: Path[] = [];

        if (store.routeEditing.sequence.length > 0 && store.routeEditing.startPoint) {
            const geocode = getCoordinates(store.routeEditing.sequence[0]);
            paths.push(new Path([
                {latitude: store.routeEditing.startPoint.coords.latitude, longitude: store.routeEditing.startPoint.coords.longitude},
                {latitude: geocode.latitude, longitude: geocode.longitude}
            ], styles.path, '#090606'))
        }

        for (let i = 0; i < store.routeEditing.sequence.length; ++i) {
            if (i + 1 >= store.routeEditing.sequence.length) {
                continue;
            }
            const geocode1 = getCoordinates(store.routeEditing.sequence[i]);
            const geocode2 = getCoordinates(store.routeEditing.sequence[i + 1]);

            paths.push(new Path([
                {latitude: geocode1.latitude, longitude: geocode1.longitude},
                {latitude: geocode2.latitude, longitude: geocode2.longitude}
            ], styles.path, '#090606'))
        }

        if (store.routeEditing.isComplete(store.orders.length) && store.routeEditing.finishPoint) {
            const geocode = getCoordinates(store.routeEditing.sequence[store.routeEditing.sequence.length - 1]);
            paths.push(new Path([
                {
                    latitude: geocode.latitude,
                    longitude: geocode.longitude
                },
                {
                    latitude: store.routeEditing.finishPoint.coords.latitude,
                    longitude: store.routeEditing.finishPoint.coords.longitude
                }
            ], styles.path, '#090606'))
        }

        drawContext.renderPath(paths);
        drawContext.renderMarkers(markers);
    }, [store.routeEditing?.sequence.length, store.routeEditing?.loadingStore])

    React.useEffect(() =>  () => {
        drawContext.clear();        
    }, [drawContext])

    return null;
});

export default DriverRouteEditor;