import * as React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout, Tooltip } from 'antd';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Role } from '../../ApiClient/YosoAuth/models';
import { CarDailyTrip } from '../../Map/CarDailyTrip';
import { Car } from '../../Model/Car/Car';
import { TripPlan } from '../../Model/TripPlan/TripPlan';
import { User } from '../../Model/User/User';
import { DateTime } from '../../Std/DateTime';
import { AppMenu } from '../AppMenu/AppMenu';
import { IAuthenticatedAppWrapperProps } from '../AuthenticatedAppWrapper/AuthenticatedAppWrapper';
import { AuthenticatedUserInfo } from '../AuthenticatedUserInfo/AuthenticatedUserInfo';
import { OrderCountPredictionWidget } from '../OrderCountPredictionWidget/OrderCountPredictionWidget';
import { AccessDeniedRoute } from '../Routes/AccessDenied/AccessDeniedRoute';
import { AdminListRoute } from '../Routes/Admin/AdminListRoute/AdminListRoute';
import { CarEditRoute } from '../Routes/Car/CarEditRoute/CarEditRoute';
import { CarListRoute } from '../Routes/Car/CarListRoute/CarListRoute';
import { ChartRoute } from '../Routes/Chart/ChartRoute';
import { CompanyEditRoute } from '../Routes/Company/CompanyEditRoute/CompanyEditRoute';
import { CompanyListRoute } from '../Routes/Company/CompanyListRoute/CompanyListRoute';
import { CompanyAdminEditRoute } from '../Routes/CompanyAdmin/CompanyAdminEditRoute/CompanyAdminEditRoute';
import { DriverEditRoute } from '../Routes/Driver/DriverEditRoute/DriverEditRoute';
import { DriversRoute } from '../Routes/Driver/DriversRoute';
import { LogisticianEditRoute } from '../Routes/Logistician/LogisticianEditRoute/LogisticianEditRoute';
import { LogisticianListRoute } from '../Routes/Logistician/LogisticianListRoute/LogisticianListRoute';
import { BotEditRoute } from '../Routes/Bot/BotEditRoute/BotEditRoute';
import { BotListRoute } from '../Routes/Bot/BotListRoute/BotListRoute';
import { NotFoundRoute } from '../Routes/NotFound/NotFoundRoute';
import { PlaceValidationRoute } from '../Routes/PlaceValidation/PlaceValidationRoute';
import { SettingsRoute } from '../Routes/Settings/SettingsRoute';
import { TripComparisonsSelectorRoute } from '../Routes/TripComparisonsSelector/TripComparisonsSelectorRoute';
import { PlanListRoute } from '../Routes/TripPlan/PlanListRoute/PlanListRoute';
import { TripPlanItemEditRoute } from '../Routes/TripPlan/TripPlanItemEditRoute/TripPlanItemEditRoute';
import { TripPlanItemViewRoute } from '../Routes/TripPlan/TripPlanItemViewRoute/TripPlanItemViewRoute';
import { WorkingDayItemViewRoute } from '../Routes/WorkingDay/WorkingDayItemViewRoute/WorkingDayItemViewRoute';
import { TripPlanData1s } from '../TripPlanData/TripPlanData1s';
import { ZoneRoute } from '../Routes/Zone/ZoneRoute';
import { LogsRoute } from '../Routes/Logs/LogRoute';
import { ProductListRoute } from '../Routes/Product/ProductListRoute';
import { ProductEditRoute } from '../Routes/Product/ProductEditRoute';
import { ItinerariesListReports } from '../ItinerariesListReports/ItinerariesListReports';
import { DeletedPlanListRoute } from '../Routes/DeletedPlanListRoute/DeletedPlanListRoute';
import { StoreListRoute } from '../Routes/Store/StoreListRoute/StoreListRoute';
import { WorkingDayListRoute } from '../Routes/WorkingDay/WorkingDayListRoute/WorkingDayListRoute';
import { TripComparisonsRoute } from '../Routes/TripComparisons/TripComparisonsRoute';
import './AuthenticatedApp.css';
import { IntervalRoute } from '../Routes/Interval/IntervalRoute';

export interface IAuthenticatedAppProps extends IAuthenticatedAppWrapperProps {
    isSidebarCollapsed: boolean | undefined;
    onSidebarCollapsedChange?: (value: boolean | undefined) => void;
    user: User | undefined;
}

interface IAuthenticatedAppState {
    carCoefficients: (number | undefined)[] | undefined;
    carDailyTrips: ReadonlyArray<CarDailyTrip> | undefined;
    carDailyTripsLoading: boolean;
    carDetailsLoadingFlags: Record<number, boolean | undefined>;
    cars: ReadonlyArray<Car> | undefined;
    plan: TripPlan | undefined;
    selectedCarDailyTripIndex: number | undefined;
    selectedTripItemIndex: number | undefined;
    totalCoefficient: number | undefined;
}

export class AuthenticatedApp extends React.Component<IAuthenticatedAppProps, IAuthenticatedAppState> {
    private static readonly siderWidth: number = 200;
    private static validateId(idString: string | undefined): number | undefined {
        if (idString === undefined) {
            return undefined;
        }

        const match = idString.match(/^\d+$/);

        return match === null ? undefined : Number(idString);
    }
    public constructor(props: IAuthenticatedAppProps) {
        super(props);

        this.state = {
            cars: undefined,
            carCoefficients: undefined,
            carDailyTrips: undefined,
            carDailyTripsLoading: false,
            selectedCarDailyTripIndex: undefined,
            carDetailsLoadingFlags: {},
            plan: undefined,
            selectedTripItemIndex: undefined,
            totalCoefficient: undefined,
        };
    }

    public render() {
        if (this.props.user === undefined) {
            return <Redirect to="/login" />;
        }

        const userRole = this.props.user.role;

        return (
            <Layout className="authenticated-app">
                <Layout.Sider width={AuthenticatedApp.siderWidth} collapsed={this.props.isSidebarCollapsed}>
                    <AuthenticatedUserInfo collapsed={this.props.isSidebarCollapsed} user={this.props.user} />

                    <AppMenu />

                    <Tooltip
                        title={this.props.isSidebarCollapsed === true ? 'Развернуть' : 'Свернуть'}
                        placement="right"
                    >
                        <Button
                            className="authenticated-app__toggle-sider-button"
                            type="primary"
                            onClick={this.handleToggleSiderButtonClick}
                        >
                            {this.props.isSidebarCollapsed === true ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                    </Tooltip>
                </Layout.Sider>
                <Layout>
                    <Layout.Content className="authenticated-app__content">
                        {userRole.equals(Role.ROLELOGISTICIAN) && (
                            <div className="order-count-prediction">
                                <OrderCountPredictionWidget />
                            </div>
                        )}
                        {userRole.equals(Role.ROLESUPERADMIN) ? (
                            <Switch>
                                <Route exact={true} path="/companies" component={CompanyListRoute} />
                                <Route exact={true} path="/companies/new" component={this.renderCompanyNewItemRoute} />
                                <Route exact={true} path="/companies/:id" component={this.renderCompanyEditRoute} />
                                <Route
                                    exact={true}
                                    path="/companies/:companyId/admins/new"
                                    component={this.renderCompanyAdminNewItemRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/companies/:companyId/admins/:id"
                                    component={this.renderCompanyAdminEditRoute}
                                />
                                <Redirect exact={true} from="/" to="/companies" />
                                <Route component={NotFoundRoute} />
                            </Switch>
                        ) : userRole.equals(Role.ROLEADMIN) ? (
                            <Switch>
                                <Route exact={true} path="/admins" component={AdminListRoute} />
                                <Route exact={true} path="/admins/new" component={this.renderAdminNewItemRoute} />
                                <Route exact={true} path="/admins/:id" component={this.renderAdminEditRoute} />
                                <Route exact={true} path="/logisticians" component={LogisticianListRoute} />
                                <Route
                                    exact={true}
                                    path="/logisticians/new"
                                    component={this.renderLogisticianNewItemRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/logisticians/:id"
                                    component={this.renderLogisticianEditRoute}
                                />
                                <Route exact={true} path="/drivers" component={DriversRoute} />
                                <Route exact={true} path="/drivers/places" component={DriversRoute} />
                                <Route exact={true} path="/drivers/coefficient-offers" component={DriversRoute} />
                                <Route exact={true} path="/drivers/new" component={this.renderDriverNewItemRoute} />
                                <Route exact={true} path="/drivers/:id" component={this.renderDriverEditRoute} />
                                <Route exact={true} path="/drivers/:id/coefs" component={this.renderDriverEditRoute} />
                                <Route exact={true} path="/bots" component={BotListRoute} />
                                <Route exact={true} path="/bot/new" component={this.renderBotNewItemRoute} />
                                <Route exact={true} path="/interval" component={IntervalRoute} />
                                <Route exact={true} path="/interval/strict" component={IntervalRoute} />
                                <Route exact={true} path="/settings" component={SettingsRoute} />
                                <Route exact={true} path="/stores" component={StoreListRoute} />
                                <Redirect exact={true} from="/" to="/interval" />
                                <Route component={NotFoundRoute} />
                            </Switch>
                        ) : userRole.equals(Role.ROLELOGISTICIAN) ? (
                            <Switch>
                                <Route exact={true} path="/cars" component={CarListRoute} />
                                <Route exact={true} path="/cars/new" component={this.renderCarNewItemRoute} />
                                <Route exact={true} path="/cars/:id" component={this.renderCarEditRoute} />
                                <Route exact={true} path="/plans" component={PlanListRoute} />
                                <Route exact={true} path="/plans/new1s" render={this.renderTripPlanNew1sItemRoute} />
                                <Route
                                    exact={true}
                                    path="/plans/wizardNew1s"
                                    render={this.renderTripPlanNew1sItemRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/date/:date/drivers/:drivers"
                                    render={this.renderItineraryList}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/add-1s-orders"
                                    render={this.renderAddTripPlan1sOrdersRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/view"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/report"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/report/:reportTripId"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/realtime"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/analysis"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/analysis/:analysisTripId"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/alternative"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/itinerary/:itineraryId"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route
                                    exact={true}
                                    path="/plans/:id/where-my-water"
                                    component={this.renderTripPlanItemViewRoute}
                                />
                                <Route exact={true} path="/placeValidation" component={PlaceValidationRoute} />
                                <Route exact={true} path="/drivers" component={DriversRoute} />
                                <Route exact={true} path="/drivers/places" component={DriversRoute} />
                                <Route exact={true} path="/drivers/coefficient-offers" component={DriversRoute} />
                                <Route exact={true} path="/drivers/new" component={this.renderDriverNewItemRoute} />
                                <Route exact={true} path="/drivers/:id" component={this.renderDriverEditRoute} />
                                <Route exact={true} path="/drivers/:id/coefs" component={this.renderDriverEditRoute} />
                                <Route exact={true} path="/zone/districts" component={ZoneRoute} />
                                <Route exact={true} path="/zone/districts/evening" component={ZoneRoute} />
                                <Route exact={true} path="/zone/districts/day-off" component={ZoneRoute} />
                                <Route exact={true} path="/zone/service-zone" component={ZoneRoute} />
                                <Route exact={true} path="/charts" component={ChartRoute} />
                                <Route exact={true} path="/charts/arrivals" component={ChartRoute} />
                                <Route exact={true} path="/charts/driver/:driverId" component={ChartRoute} />
                                <Route exact={true} path="/products" component={ProductListRoute} />
                                <Route exact={true} path="/products/:id" component={this.renderProductEditRoute} />
                                <Route exact={true} path="/logs" component={LogsRoute} />
                                <Redirect exact={true} from="/zone" to="/zone/districts" />
                                <Route exact={true} path="/deletedPlans" component={DeletedPlanListRoute} />
                                <Route exact={true} path="/workingDays" component={WorkingDayListRoute} />
                                <Route
                                    exact={true}
                                    path="/workingDays/:id"
                                    component={this.renderWorkingDayItemViewRoute}
                                />
                                <Route exact={true} path="/trip-comparisons" component={TripComparisonsSelectorRoute} />
                                <Route
                                    exact={true}
                                    path="/trip-comparisons/:id/place-select"
                                    component={this.renderTripComparisons}
                                />
                                <Route
                                    exact={true}
                                    path="/trip-comparisons/:id/routes"
                                    component={this.renderTripComparisons}
                                />

                                <Redirect exact={true} from="/" to="/plans" />
                                <Route component={NotFoundRoute} />
                            </Switch>
                        ) : (
                            <Route component={AccessDeniedRoute} />
                        )}
                    </Layout.Content>
                    <Layout.Footer className="authenticated-app__footer">
                        &copy; ООО &laquo;Йо Солюшенс&raquo;, 2019&ndash;
                        {DateTime.now().year}.
                    </Layout.Footer>
                </Layout>
            </Layout>
        );
    }

    private readonly handleAddOrders = (plan: TripPlan, cars: ReadonlyArray<Car>): void => {
        this.setState({
            cars,
            plan: plan,
        });
    };

    private readonly handleSaveAndBuildPlan = (plan: TripPlan, cars: ReadonlyArray<Car>): void => {
        this.setState({
            cars,
            plan: plan,
        });
    };

    private readonly handleToggleSiderButtonClick = () => {
        if (this.props.onSidebarCollapsedChange !== undefined) {
            this.props.onSidebarCollapsedChange(this.props.isSidebarCollapsed !== true);
        }
    };

    private readonly handleTripPlanChanged = (plan: TripPlan | undefined): void => {
        this.setState({
            plan: plan,
        });
    };

    private readonly renderAddTripPlan1sOrdersRoute = (
        routeProps: RouteComponentProps<{ id?: string }>,
    ): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);
        const onAddOrders = (plan: TripPlan, responseCars: ReadonlyArray<Car>): void => {
            this.handleAddOrders(plan, responseCars);
            routeProps.history.push(`/plans/${plan.id}/view`);
        };
        const params = new URLSearchParams(routeProps.location.search);
        const returnUrl = params.get('returnUrl');

        return id === undefined ? (
            <NotFoundRoute />
        ) : (
            <TripPlanItemEditRoute
                id={Number(routeProps.match.params.id)}
                tripPlanData={
                    <TripPlanData1s
                        onSaveAndBuildPlan={onAddOrders}
                        planId={Number(routeProps.match.params.id)}
                        returnUrl={returnUrl || undefined}
                    />
                }
                tripPlan={this.state.plan}
            />
        );
    };

    private readonly renderAdminEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <CompanyAdminEditRoute companyId={undefined} id={id} />;
    };

    private readonly renderAdminNewItemRoute = (): JSX.Element => (
        <CompanyAdminEditRoute companyId={undefined} id={undefined} />
    );

    private readonly renderCarEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? (
            <NotFoundRoute />
        ) : (
            <CarEditRoute id={Number(routeProps.match.params.id)} car={undefined} />
        );
    };

    private readonly renderCarNewItemRoute = (): JSX.Element => <CarEditRoute id={undefined} car={undefined} />;

    private readonly renderCompanyAdminEditRoute = (
        routeProps: RouteComponentProps<{ companyId?: string; id?: string }>,
    ): JSX.Element => {
        const companyId = AuthenticatedApp.validateId(routeProps.match.params.companyId);
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return companyId === undefined || id === undefined ? (
            <NotFoundRoute />
        ) : (
            <CompanyAdminEditRoute companyId={companyId} id={Number(routeProps.match.params.id)} />
        );
    };

    private readonly renderCompanyAdminNewItemRoute = (
        routeProps: RouteComponentProps<{ companyId?: string }>,
    ): JSX.Element => {
        const companyId = AuthenticatedApp.validateId(routeProps.match.params.companyId);

        return companyId === undefined ? (
            <NotFoundRoute />
        ) : (
            <CompanyAdminEditRoute companyId={companyId} id={undefined} />
        );
    };

    private readonly renderCompanyEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <CompanyEditRoute id={Number(routeProps.match.params.id)} />;
    };

    private readonly renderCompanyNewItemRoute = (): JSX.Element => <CompanyEditRoute id={undefined} />;

    private readonly renderDriverEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <DriverEditRoute id={Number(routeProps.match.params.id)} />;
    };

    private readonly renderDriverNewItemRoute = (): JSX.Element => <DriverEditRoute id={undefined} />;

    private readonly renderProductEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <ProductEditRoute id={Number(routeProps.match.params.id)} />;
    };

    private readonly renderLogisticianEditRoute = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <LogisticianEditRoute id={id} />;
    };

    private readonly renderLogisticianNewItemRoute = (): JSX.Element => <LogisticianEditRoute id={undefined} />;

    private readonly renderBotNewItemRoute = (): JSX.Element => <BotEditRoute id={undefined} />;

    private readonly renderTripPlanItemViewRoute = (
        routeProps: RouteComponentProps<{
            analysisTripId?: string;
            id?: string;
            reportTripId?: string;
            itineraryId?: string;
        }>,
    ): JSX.Element => {
        const routeParams = routeProps.match.params;
        const id = AuthenticatedApp.validateId(routeParams.id);

        return id === undefined ? (
            <NotFoundRoute />
        ) : (
            <TripPlanItemViewRoute
                planId={Number(routeParams.id)}
                reportTripId={routeParams.reportTripId === undefined ? undefined : Number(routeParams.reportTripId)}
                analysisTripId={
                    routeParams.analysisTripId === undefined ? undefined : Number(routeParams.analysisTripId)
                }
                itineraryId={routeParams.itineraryId === undefined ? undefined : Number(routeParams.itineraryId)}
                cars={this.state.cars}
                tripPlan={this.state.plan}
                onPlanChanged={this.handleTripPlanChanged}
            />
        );
    };

    private readonly renderTripPlanNew1sItemRoute = (routeProps: RouteComponentProps): JSX.Element => {
        const onSaveAndBuildPlan = (plan: TripPlan, responseCars: ReadonlyArray<Car>): void => {
            this.handleSaveAndBuildPlan(plan, responseCars);
            routeProps.history.push(`/plans/${plan.id}/view`);
        };

        const params = new URLSearchParams(routeProps.location.search);
        const returnUrl = params.get('returnUrl');

        return (
            <TripPlanItemEditRoute
                id={undefined}
                tripPlanData={
                    <TripPlanData1s
                        onSaveAndBuildPlan={onSaveAndBuildPlan}
                        returnUrl={returnUrl || undefined}
                        isNewBuildPlan={routeProps.match.url.includes('wizardNew1s')}
                    />
                }
                tripPlan={undefined}
            />
        );
    };

    private readonly renderItineraryList = (
        routeProps: RouteComponentProps<{ date: string; drivers: string }>,
    ): JSX.Element => {
        const returnBack = (): void => {
            routeProps.history.push(`/plans`);
        };

        const date = routeProps.match.params.date;
        const drivers = routeProps.match.params.drivers;

        const driversArr = drivers.split(',').map((x) => +x);

        return <ItinerariesListReports date={date} driverIds={driversArr} returnBack={returnBack} />;
    };

    private readonly renderWorkingDayItemViewRoute = (
        routeProps: RouteComponentProps<{ id?: string }>,
    ): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? (
            <NotFoundRoute />
        ) : (
            <WorkingDayItemViewRoute workingDayId={Number(routeProps.match.params.id)} />
        );
    };

    private readonly renderTripComparisons = (routeProps: RouteComponentProps<{ id?: string }>): JSX.Element => {
        const id = AuthenticatedApp.validateId(routeProps.match.params.id);

        return id === undefined ? <NotFoundRoute /> : <TripComparisonsRoute id={Number(routeProps.match.params.id)} />;
    };
}
