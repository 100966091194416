import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Page } from '../../Page/Page';

export interface INotFoundRouteProps extends RouteComponentProps {}

export const NotFoundRoute = withRouter(
    (props: INotFoundRouteProps): JSX.Element => <Page header="Страница не найдена">404 :(</Page>,
);
