import React from 'react';
import {
    LocalStorageDriverReadMails
} from '../../../../Model/DriverReadMails/DriverReadMails';
import { DateTime } from '../../../../Std/DateTime';

import { ClientPreferencesLocalStorageStorage } from './ClientPreferencesStorage/ClientPreferencesLocalStorageStorage';

export interface IClientPreferencesContext {
    isSidebarCollapsed?: boolean;
    setIsSidebarCollapsed: (value: boolean | undefined) => void;
    dateLastReceivingNoticeMail: DateTime;
    setLastReceivedNoticeMailDate: (value: DateTime) => void;
    updateReadMailsIds: (values: LocalStorageDriverReadMails) => void;
    driversReadMailIds: LocalStorageDriverReadMails[],
    clearMailsBeforeDate: (value: DateTime) => void
}

export const ClientPreferencesContext = React.createContext<IClientPreferencesContext>({
    setIsSidebarCollapsed: () => undefined,
    dateLastReceivingNoticeMail: DateTime.now(),
    setLastReceivedNoticeMailDate: () => undefined,
    driversReadMailIds: [],
    updateReadMailsIds: () => undefined,
    clearMailsBeforeDate: () => undefined
});

export interface IClientPreferencesProviderProps {}

export const ClientPreferencesProvider = (props: IClientPreferencesProviderProps): JSX.Element => {
    const [storage, setStorage] = React.useState(new ClientPreferencesLocalStorageStorage());

    return (
        <ClientPreferencesContext.Provider
            value={{
                isSidebarCollapsed: storage.isSidebarCollapsed,
                setIsSidebarCollapsed: (value: boolean | undefined) => {
                    storage.isSidebarCollapsed = value;
                    setStorage(new ClientPreferencesLocalStorageStorage());
                },
                dateLastReceivingNoticeMail: storage.dateReceivingLastNoticeMail,
                setLastReceivedNoticeMailDate: (value: DateTime) => {
                    storage.setDateReceivingLastNoticeMail = value;
                },
                driversReadMailIds: storage.driversReadMailIds,
                updateReadMailsIds: (value: LocalStorageDriverReadMails) => {
                    storage.updateDriversReadMailIds = value;
                },
                clearMailsBeforeDate: (value: DateTime) => {
                    storage.clearMailsBeforeDate = value;
                }
            }}
            {...props}
        />
    );
};

export const useClientPreferences = () => React.useContext(ClientPreferencesContext);
