import React from 'react';
import L from 'leaflet'
import ReactDOM from 'react-dom';
import { MapContext } from './MapContextProvider';

interface MapCustomButtonProps {
    position: L.ControlPosition
    children?: React.ReactNode
    style?: React.CSSProperties
    separate: boolean
}

const positionClasses = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right'
};

export const MapControl = (props: MapCustomButtonProps) => {
    const containerContext = React.useContext(MapContext)?.containerRef;
    if (!containerContext) {
        throw new Error("Geometry context is undefined");
    }

    const [container, setContainer] = React.useState<HTMLElement>(document.createElement('div'))
    const positionClass = ((props.position && positionClasses[props.position]) || positionClasses.topleft);

    React.useEffect(() => {
        const div = containerContext.current;

        let targetDiv = div!.getElementsByClassName(positionClass)

        if (!props.separate) {
            targetDiv = targetDiv[0].getElementsByClassName("leaflet-app-control");
        }

        setContainer(targetDiv[0] as HTMLElement)
    }, [positionClass])

    const controlContainer = props.separate
        ? <div className='leaflet-control leaflet-bar' style={props.style}>{props.children}</div>
        : <React.Fragment>{props.children}</React.Fragment>

    L.DomEvent.disableClickPropagation(container);

    return ReactDOM.createPortal(controlContainer, container);
}

export default MapControl;