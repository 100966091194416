import React from 'react';
import { Button, Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { District } from '../../../../Store/District/District';
import { DistrictGroupCode } from '../../../../Store/District/DistrictGroupCode';
import { DistrictStore } from '../../../../Store/District/DistrictStore';
import { useCompany } from '../../../AppProviders/Providers/CompanyProvider';
import DistrictMapEditor from '../../../DistrictMapEditor/DistrictMapEditor';
import OrdersOnDate from '../../../DistrictMapEditor/OrdersOnDate/OrdersOnDate';
import MapContainer from '../../../MapContainer/MapContainer';
import DistrictGroups from './DistrictGroups';
import { DistrictListTypes } from './DistrictListTypes';
import DriverGroups from './DriverGroups';
import './DistrictList.css';

interface DistrictListProps {
    group: DistrictGroupCode;
    ordersGroup?: string;
    dateStart: Date | null;
    dateEnd: Date | null;
    onChangeDate: (dateStart: Date, dateEnd: Date) => void;
}

const DistrictList = observer((props: DistrictListProps) => {
    const { company, center } = useCompany();

    const [isOrdersVisible, setIsOrdersVisible] = React.useState(false);
    const [ordersGroup, setOrdersGroup] = React.useState<string>(props.ordersGroup !== undefined ? props.ordersGroup : props.group);

    const districtStore = React.useMemo(() => new DistrictStore(props.group), []);

    React.useEffect(() => {
        Promise.all([
            CompanyApi.getDriverList(),
            CompanyApi.getCompanyDistricts(
                props.group
            ),
            CompanyApi.getCompanyOrders(
                props.dateStart || new Date(),
                props.dateEnd || new Date(),
                ordersGroup,
            )
        ]).then((responses) => {
            const driversList = responses[0];
            const drivers = driversList.map((employee) => ({
                id: employee.driver.id,
                name: employee.driver.name,
                isActive: !!employee.driver.isActive,
            }));

            districtStore.initData(drivers,
                responses[1].districts,
                responses[2], // eslint-disable-line
                responses[1].controlDate);
        });
    }, [ordersGroup]);

    const onDistrictRemove = (district: District): Promise<void> | undefined => {
        if (!districtStore.controlDate) {
            return undefined;
        }

        if (district.isNew()) {
            districtStore.removeDistrict(district);

            return undefined;
        }

        CompanyApi.deleteDistrict(district.id,
            districtStore.controlDate
        ).then(res => {
            districtStore.removeDistrict(district);
            districtStore.controlDate = res;
        })
        .catch((e) => {
            Modal.error({
                content: e.message,
                onOk: () => window.location.reload(),
            })
        });
    }

    const save = () => {
        if (!districtStore.controlDate) {
            return
        }
        CompanyApi.postDistricts(districtStore.districts,
            districtStore.controlDate,
            districtStore.group,
            company?.cityName
        ).then(() => {
            Modal.success({
                content: 'Изменения сохранены',
                onOk: () => window.location.reload(),
                onCancel: () => window.location.reload(),
            });
        })
        .catch((e) => {
            Modal.error({
                content: e.message,
                onOk: () => window.location.reload(),
            })
        });
    };

    return (
        <div className="district-list__page">
            <Spin spinning={!districtStore.mapBounds}>
                <div className="district-list__container">
                    <div className="district-list__editor">
                        <Button
                            size="small"
                            className="district-list__toggle-list"
                            onClick={() => districtStore.toggleListType()}
                        >
                            {districtStore.selectedListType}
                        </Button>
                        <div className="district-list__content">
                            {districtStore.selectedListType === DistrictListTypes.District ? (
                                <DistrictGroups districtStore={districtStore} onDistrictRemove={onDistrictRemove} />
                            ) : (
                                <DriverGroups districtStore={districtStore} />
                            )}
                        </div>
                        <Button
                            type="primary"
                            className="district-list__button_save"
                            onClick={save}
                            disabled={districtStore.districts.some((d) => d.name.trim() === '')}
                        >
                            Сохранить изменения
                        </Button>
                    </div>
                    <div className="district-list__map-container">
                        {districtStore.mapBounds && center && (
                            <MapContainer
                                center={[center.latitude, center.longitude]}
                                bounds={districtStore.mapBounds.length === 0 ? undefined : districtStore.mapBounds}
                                fullscreen={true}
                                zoomValue={14}
                            >
                                <DistrictMapEditor districtStore={districtStore} isOrdersVisible={isOrdersVisible} />
                                <OrdersOnDate
                                    orders={districtStore.orders}
                                    dateStart={moment(props.dateStart || new Date())}
                                    dateEnd={moment(props.dateEnd || new Date())}
                                    onSelectDate={props.onChangeDate}
                                    isOrdersVisible={isOrdersVisible}
                                    setIsOrdersVisible={setIsOrdersVisible}
                                    ordersGroup={ordersGroup}
                                    setOrdersGroup={setOrdersGroup}
                                />
                            </MapContainer>
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default DistrictList;
