import { DeliveryApiPatchTripOrdersResponse, GetPlansOKResponse } from '../../../../../ApiClient/Yoso/models';
import { ILeg } from '../../../../Types/ILeg';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { legDeserializer } from '../../Model/Leg/LegDeserializer';

export interface IPatchTripOrdersResponse {
    legs: ReadonlyArray<ILeg>;
}

export class PatchTripOrdersResponseDeserializer
    implements IResponseDeserializer<DeliveryApiPatchTripOrdersResponse, IPatchTripOrdersResponse> {
    public deserialize(response: DeliveryApiPatchTripOrdersResponse): IPatchTripOrdersResponse {
        if (response.legs === undefined) {
            throw new Error('No legs in the response');
        }

        return {
            legs: response.legs.map((leg) => legDeserializer.deserialize(leg)),
        };
    }
}

export const patchOrdersResponseDeserializer = new PatchTripOrdersResponseDeserializer();
