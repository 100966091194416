import { Order } from '../../../Model/Order/Order';
import { OrderStatus } from '../../../Model/Order/OrderStatus';
import { TripPointType } from '../../../Model/TripPointId/TripPointType';
import {DateTime} from "../../../Std/DateTime";
import {Geocode} from '../../../Model/Geocode/Geocode';

import { RoutePoint } from './RoutePoint';

export class OrderRoutePoint extends RoutePoint {
    public readonly expectedDistanceTo: number | undefined;
    public readonly order: Order;

    public constructor(id: string, order: Order, expectedDistanceTo?: number, expectedDateTimeFrom?: DateTime, hasLegs = true) {
        super(
            order.id,
            id,
            TripPointType.Order,
            order.place.geocode!,
            order.driverPlace ? Geocode.fromLatLon(order.driverPlace.latitude, order.driverPlace.longitude) : undefined,
            order.fullAddress,
            order.expectedArrivalDateTime,
            expectedDateTimeFrom,
            order.actualArrivalDateTime,
            order.desiredDateTimePeriod,
            order._isAdded,
            order.isOwn(),
            hasLegs
        );

        this.order = order;
        this.expectedDistanceTo = expectedDistanceTo;
    }

    public hasAdjustments(): boolean {
        return this.order.adjustmentCount > 0;
    }

    public hasDeviationsFromPlan(): boolean {
        return this.hasAdjustments() || this.isFailed() || this.hasArrivalDelay();
    }

    public isFailed(): boolean {
        return this.order.status === OrderStatus.Failed;
    }
}
