import React from 'react'
import { Button, Modal } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite'; // eslint-disable-line
import { LatLngTuple } from 'leaflet';
import { TripPlan } from '../../Model/TripPlan/TripPlan';
import { AddOrdersBlock } from '../AddOrdersBlock/AddOrdersBlock';
import {useCompany} from '../AppProviders/Providers/CompanyProvider';
import { Sidebar } from '../Sidebar/Sidebar';
import { TripList2 } from '../TripList/TripList2';
import MapContainer from '../MapContainer/MapContainer';
import { TripExecutorsLoadingInfo } from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import { defaultColorPalette } from '../../Shared/ColorPalette';
import { PlaceSelect } from '../PlaceSelect/PlaceSelect';
import { Polygon } from '../MapContainer/model/Polygon';
import { PlanStore } from '../../Store/PlanStore/PlanStore';
import { TripWorkingShift } from '../../Model/Trip/TripWorkingShift';
import { DistrictGroupCode } from '../../Store/District/DistrictGroupCode';
import { StoresOnMap } from './StoresOnMap';
import { TripsOnMap } from './TripsOnMap';
import { DistrictsOnMap } from './DistrictsOnMap';

import './TripPlanDetails.css';

export interface ITripPlanDetailsProps {
    className?: string;
    onUpdatePlan: () => void;
    plan: TripPlan;
}

enum ViewMapMode { TripsPlan, PlacePicker }

const TripPlanDetails2 = observer((props: ITripPlanDetailsProps) => {
    const company = useCompany();
    const center: LatLngTuple | null = company.center ? [company.center.latitude, company.center.longitude] : null;

    const planStore = React.useMemo(() => new PlanStore(props.plan, props.onUpdatePlan), [props.plan]);

    const [viewMapMode, setViewMapMode] = React.useState<ViewMapMode>(ViewMapMode.TripsPlan);

    React.useEffect(() => {
        setViewMapMode(ViewMapMode.TripsPlan);
    }, [planStore.selectedTrip, planStore.selectedTripItemIndex])

    const sendPlanToDrivers = React.useCallback(() => {
        Modal.confirm({
            title: "Подтвердите действие",
            content: `${planStore.isFailedTrips() 
                ? "В списке есть непостроенные маршруты. Вы уверены, что хотите отправить?" 
                : "Отправить построенные маршруты водителям?"}`,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => {
                planStore.sendPlanToDrivers();
            },
            onCancel: () => {
                /* */
            },
        });
    }, [planStore])

    const getDistrictsByGroup = (): Polygon[] => {
        const selectedTripDistrictGroup = planStore.selectedTrip ? planStore.selectedTrip.workingShift : undefined;

        if (selectedTripDistrictGroup === undefined) {
            return  [];
        }

        let selectedDistrictGroupCode: DistrictGroupCode | undefined;

        switch (selectedTripDistrictGroup) {
            case TripWorkingShift.MORNING:
                selectedDistrictGroupCode = DistrictGroupCode.Morning;
                break;
            case TripWorkingShift.EVENING:
                selectedDistrictGroupCode = DistrictGroupCode.Evening;
                break;
            case TripWorkingShift.DAYOFF:
                selectedDistrictGroupCode = DistrictGroupCode.DayOff;
                break;
            default:
                throw new Error('Trip has unknown workingShift')
        }

        const filteredDistricts = props.plan.districts.filter(d => d.group === selectedDistrictGroupCode);

        return filteredDistricts.map(d => new Polygon(d.geocodes, d.color, d.name))
    }

    return (
        <div className="trip-plan-details">
            <div className={classNames('old-ui-route', props.className)}>
                <Sidebar className="old-ui-route__sidebar">
                    <>
                        <div className='trip-plan-details__button-group'>
                            <AddOrdersBlock
                                hrefExcel={`/plans/${props.plan.id}/add-orders`}
                                href1s={`/plans/${props.plan.id}/add-1s-orders?returnUrl=${window.location.pathname}`}
                            />
                        </div>
                        <div className="old-ui-route__car-daily-trips-container">
                            <div className="old-ui-route__car-daily-trips-inner-container">
                                <div className="trip-plan-details__trip-list-container">
                                    <TripList2
                                        planStore={planStore}
                                        className="trip-plan-details__trip-list"
                                        loadingInfo={
                                            new TripExecutorsLoadingInfo(
                                                props.plan.executors,
                                                props.plan.orders,
                                            )
                                        }
                                        colorScheme={defaultColorPalette}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            className="trip-plan-details__button"
                            onClick={sendPlanToDrivers}
                        >
                            {company.company?.buildRoutesOnApprove ? 'Построить и отправить водителям' : 'Отправить водителям'}
                        </Button>
                    </>
                </Sidebar>
                <div className="old-ui-route__map-container">
                    {viewMapMode === ViewMapMode.TripsPlan && (
                        <MapContainer center={center ?? [0, 0]}>
                            <TripsOnMap
                                planStore={planStore}
                                colorScheme={defaultColorPalette}
                                onEditPlace={() => setViewMapMode(ViewMapMode.PlacePicker)}
                            />
                            <StoresOnMap stores={props.plan.stores} />
                            <DistrictsOnMap polygons={getDistrictsByGroup()} />
                        </MapContainer>
                    )}
                    {viewMapMode === ViewMapMode.PlacePicker && planStore.selectedPlace && (
                        <MapContainer center={center ?? [0, 0]}>
                            <PlaceSelect
                                places={planStore.selectedPlace}
                                onSelectPlace={planStore.setPlaceToCurrentOrder}
                            />
                        </MapContainer>
                    )}
                </div>
            </div>
        </div >
    )
})

export default TripPlanDetails2
