import { ApiItineraryOrder, DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { ItineraryOrder } from '../../../../../Model/Itinerary/ItineraryOrder';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import { emptyBottleDeserializer } from './EmptyBottleDeserializer';
import { productNoteDeserializer } from './ProductNoteDeserializer';

export class OrderDeserializer {
    public deserialize(response: ApiItineraryOrder, products: DeliveryApiProduct[]): ItineraryOrder {
        return new ItineraryOrder(
            response.orderId,
            response.clientName,
            response.address,
            response.additionalPhoneNumbers,
            response.bottlesCount,
            response.emptyBottlesCount.map(item => emptyBottleDeserializer.deserialize(item, products)),
            dateTimeDeserializer.deserialize(response.intervalBegin),
            dateTimeDeserializer.deserialize(response.intervalEnd),
            response.isCorporate,
            response.paymentMethod,
            response.shipmentNotes.map(note => productNoteDeserializer.deserialize(note, products)),
            response.returnNotes.map(note => productNoteDeserializer.deserialize(note, products)),
            response.isDone,
            response.isActive,
            response.isQR,
            response.price,
            response.paid,
            response.comment,
            response.isTerminal
        )
    }
}

export const orderDeserializer = new OrderDeserializer();
