import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { Geocode } from '../Geocode/Geocode';

export class ImportOrder {
    public id: number;
    public address: string;
    public period: DateTimePeriod;
    public coords?: Geocode;
    public nominatimId?: number;
    public nominatimBoundingBox?: Geocode[];

    public constructor(
        id: number,
        address: string,
        period: DateTimePeriod,
        coords?: Geocode,
        nominatimId?: number,
        nominatimBoundingBox?: Geocode[],
    ) {
        this.id = id;
        this.address = address;
        this.period = period;
        this.coords = coords;
        this.nominatimId = nominatimId;
        this.nominatimBoundingBox = nominatimBoundingBox;
    }
}
