import React from "react";
import { CarMoveAndStopLocation } from "../../Model/CarMoveAndStopLocation/CarMoveAndStopLocation";
import { DateTime } from "../../Std/DateTime";
import { formatTime } from "../../Std/formatTime";
import { formatTimePeriod } from "../../Std/FormatTimePeriod";
import { TimePeriod } from "../../Std/TimePeriod";
import { MapContext } from "../MapContainer/MapContextProvider";
import { Path } from "../MapContainer/model/Path";
import { Marker } from "../MapContainer/model/Marker";
import './StopAndGoLocations.css';
import MapControl from "../MapContainer/MapControl";

interface IStopAndGoLocationsProps {
    moveAndStopRoute: ReadonlyArray<CarMoveAndStopLocation>;
    isVisibilityControl?: boolean;
}

export const StopAndGoLocations = (props: IStopAndGoLocationsProps) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const drawContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const pointMaxTimeGap = 300;

    const [markersOnMap, setMarkersOnMap] = React.useState<Marker[]>([]);
    const [pathOnMap, setPathOnMap] = React.useState<Path[]>([]);
    const [pathVisibility, setPathVisibility] = React.useState(false);

    React.useEffect(() => {
        setMarkersOnMap([]);
        setPathOnMap([]);

        if (props.moveAndStopRoute.length < 1) {
            return undefined;
        }

        createPath(props.moveAndStopRoute);
    }, [props.moveAndStopRoute])

    const createPath = React.useCallback((path: ReadonlyArray<CarMoveAndStopLocation>) => {
        const markers: Marker[] = [];
        const lines: Path[] = [];
        let spentTimeTo = 0;
        let lastDelay = false;
        let count = 0;

        for (let i = 0; i < path.length; i++) {
            let classname = "";
            let title: string | undefined;

            if (i + 1 < path.length) {
                const t1 = path[i].endTime || path[i].startTime;
                const t2 = path[i + 1].startTime;

                spentTimeTo = (t2.toDate().getTime() - t1.toDate().getTime()) / DateTime.millisecondsInSecond;

                const line = new Path(
                    [path[i].geocode, path[i + 1].geocode],
                    spentTimeTo > pointMaxTimeGap ? "place-select__map-path_red" : "place-select__map-path_orange"
                );

                lines.push(line);
            }

            if (path[i].endTime) {
                classname = "map-marker__content place-select__map-path-point_label";
                count += 1;
                title = formatStopInfo(path[i]);
            } else if (lastDelay || spentTimeTo > pointMaxTimeGap) {
                classname = "place-select__map-path-point_no-label";
                title = formatTime(path[i].startTime);
            }

            markers.push(new Marker(
                path[i].geocode.latitude,
                path[i].geocode.longitude,
                path[i].endTime && String(count),
                true,
                title ? { text: title } : undefined,
                undefined,
                classname)
            )

            lastDelay = spentTimeTo > pointMaxTimeGap;
        }

        setMarkersOnMap(markers);
        setPathOnMap(lines);
    }, [props.moveAndStopRoute])

    const formatStopInfo = (location: CarMoveAndStopLocation): string | undefined => {
        if (!location.endTime) {
            return undefined;
        }

        const stopDuration = (location.endTime.toDate().getTime()
            - location.startTime.toDate().getTime()) / (DateTime.secondsInMinute * DateTime.millisecondsInSecond);

        return `${formatTimePeriod(new TimePeriod(location.startTime, location.endTime))}<br>` +
            `${Math.round(stopDuration)} min`;
    };

    React.useEffect(() => {
        drawContext.renderMarkers(!props.isVisibilityControl || pathVisibility ? markersOnMap : []);
        drawContext.renderPath(!props.isVisibilityControl || pathVisibility ? pathOnMap : []);
    }, [markersOnMap, pathOnMap, pathVisibility])

    return props.isVisibilityControl === true ? (
        <MapControl position='topleft' separate={false}>
            <a style={{ fontSize: '13px', fontWeight: 700 }} onClick={() => setPathVisibility(!pathVisibility)}><span>gps</span></a>
        </MapControl>
    ) : null;
}