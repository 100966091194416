import React from 'react';
import { Route } from '@/Map/Route/Route';
import { Leg } from '@/Map/Route/Leg/Leg';
import { RoutePoint } from '@/Map/Route/RoutePoint/RoutePoint';
import RouteInfoPointItem from './RouteInfoPointItem';
import './RouteInfo.css';

export interface IRouteInfoProps {
    route: Route;
    selectedDistance?: Leg | undefined;
    selectedPoint?: RoutePoint | undefined;
    onSelectDistance?: (leg: Leg) => void;
    onSelectPoint: (orderId: number) => void;
}

export const RouteInfo = (props: IRouteInfoProps): JSX.Element => {
    const {
        route,
        onSelectDistance,
        onSelectPoint
    } = props;

    const firstScheduledOrder = React.useMemo(() => route.orders.find(point => point.order.canBeDone()), [route]);

    const handleSelectPoint = (e: React.MouseEvent<HTMLDivElement>, point: RoutePoint) => {
        e.stopPropagation()
        onSelectPoint(point.originalId)
    };

    const handleSelectDistance = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (onSelectDistance !== undefined) {
                e.stopPropagation();

                const pointIndex = e.currentTarget.dataset.pointIndex;
                if (pointIndex === undefined) {
                    return;
                }

                const leg = route.legs[Number(pointIndex) - 1];
                if (leg !== undefined) {
                    onSelectDistance(leg);
                }
            }
        },
        [onSelectDistance, route.legs],
    );

    return (
        <div className='route-info'>
            {route.noLegPoints.length > 0 &&
                <div style={{marginBottom: 30}}>
                    Заказы, не включенные в маршрут:
                    {route.noLegPoints.map(point => (
                        <RouteInfoPointItem
                            key={point.id}
                            point={point}
                            isClickable
                            handleSelectPoint={(e) => handleSelectPoint(e, point)}
                            isSelected={props.selectedPoint?.originalId === point.originalId}
                        />
                    ))}
                </div>
            }

            <div>Заказы в маршруте:</div>

            <RouteInfoPointItem
                point={route.startPoint}
                isFirst
            />
            {route.routePoints.map((point, index) => (
                <RouteInfoPointItem
                    key={point.id}
                    point={point}
                    isFirstScheduledOrder={firstScheduledOrder?.originalId === point.originalId}
                    isClickable
                    handleSelectPoint={e => handleSelectPoint(e, point)}
                    handleSelectDistance={handleSelectDistance}
                    distanceIsSelected={route.legs[index] === props.selectedDistance}
                    isSelected={props.selectedPoint?.originalId === point.originalId}
                />
            ))}
            <RouteInfoPointItem
                point={route.finishPoint}
                isLast
            />
        </div>
    );
};
