import { CompanyApiGeocode, CompanyApiServiceZone } from "../../../../ApiClient/YosoCompany/models";
import { IGeocode } from "../../../../Model/Geocode/IGeocode";

export class CompanySerializer {
    public serializeServiceZone(geocodes: IGeocode[]): CompanyApiServiceZone {
        return {
            geocodes: geocodes.map((geocode: IGeocode): CompanyApiGeocode => ({
                latitude: geocode.latitude,
                longitude: geocode.longitude  
            }))
        }
    }
}

export const companySerializer = new CompanySerializer();
