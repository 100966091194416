import * as React from 'react';
import classNames from 'classnames';
import {
    ReactComponent as SmallCancelIconComponent
} from '../../icons/small-cancel.svg';
import {ReactComponent as SmallOkIconComponent} from '../../icons/small-ok.svg';
import {FinishRoutePoint} from '../../Map/Route/RoutePoint/FinishRoutePoint';
import {LunchRoutePoint} from '../../Map/Route/RoutePoint/LunchRoutePoint';
import {OrderRoutePoint} from '../../Map/Route/RoutePoint/OrderRoutePoint';
import {RoutePoint} from '../../Map/Route/RoutePoint/RoutePoint';
import {StartRoutePoint} from '../../Map/Route/RoutePoint/StartRoutePoint';
import {
    LoadingStoreRoutePoint
} from '../../Map/Route/RoutePoint/LoadingStoreRoutePoint';
import {LunchStatusActual} from '../../Model/Lunch/LunchStatusActual';
import {OrderStatus} from '../../Model/Order/OrderStatus';
import {FormattedDistance} from './RouteInfoFormattedValues/FormattedDistance';


export interface IPointMarkersProps {
    distanceIsSelected: boolean | undefined;
    onDistanceClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    point: RoutePoint;
}

export const PointMarkers = (props: IPointMarkersProps): JSX.Element => {
    const {distanceIsSelected, onDistanceClick, point} = props;

    return (
        <div
            className={classNames(
                'route-info__item-line',
                (!point.hasLegs) && 'route-info__item-line_nolegs',
                (point instanceof FinishRoutePoint) &&
                point.expectedDistanceTo === undefined &&
                'route-info__item-line_no-distance',
            )}
        >
            <div className='route-info__item-line-dot'>
                {(point instanceof StartRoutePoint ||
                    (point instanceof OrderRoutePoint && point.order.status === OrderStatus.Done) ||
                    (point instanceof LunchRoutePoint && point.lunch.status === LunchStatusActual.Done)) && (
                    <SmallOkIconComponent
                        className='route-info__item-line-dot-icon route-info__item-line-dot-icon_done'/>
                )}
                {point instanceof OrderRoutePoint && (point.order.status === OrderStatus.Failed ||
                    point.order.status === OrderStatus.Rescheduled) && (
                    <SmallCancelIconComponent
                        className='route-info__item-line-dot-icon route-info__item-line-dot-icon_failed'/>
                )}
            </div>
            {point instanceof OrderRoutePoint ||
            point instanceof FinishRoutePoint ||
            point instanceof LunchRoutePoint ||
            point instanceof LoadingStoreRoutePoint ? (
                <FormattedDistance
                    className={classNames(
                        'route-info__item-distance',
                        distanceIsSelected === true && 'route-info__item-distance_selected',
                        onDistanceClick !== undefined && 'route-info__item-distance_clickable',
                    )}
                    distance={point.expectedDistanceTo}
                    onClick={onDistanceClick}
                />
            ) : undefined}
        </div>
    );
};
