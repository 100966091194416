import React from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import { CloseOutlined } from '@ant-design/icons';
import { Dictionary } from '../../../helpers/Dictionary/Dictionary';
import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { DriverPlace } from '../../../Model/Place/DriverPlace';
import { distanceBetweenCoords } from '../../../Std/GeocodeUtils';
import MapContent from '../../MapContainer/MapContent';
import { MapContext } from '../../MapContainer/MapContextProvider';
import { Marker } from '../../MapContainer/model/Marker';
import { Path } from '../../MapContainer/model/Path';
import { DriverPlaceStore } from './DriverPlaceStore';
import styles from './DriverPlaceRoute.module.css'

interface MapDriverPlaceProps {
    store: DriverPlaceStore;
}

const MapDriverPlace = observer((props: MapDriverPlaceProps) => {
    const {store} = props;
    const zIndexNormal = 500;
    const zIndexMax = 1000;

    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error('Map context is undefined');
    }

    const mapMarkerPaths = Dictionary();
    const mapDriverPlaceMarker = Dictionary();
    let markers: Marker[] = [];

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    React.useEffect(() => {
        const lines: Path[] = [];
        markers = [];
        mapMarkerPaths.clear();
        mapDriverPlaceMarker.clear();

        store.driverPlaces.forEach(place => {
            const driverPlaceMarker = new Marker(
                place.coords.latitude,
                place.coords.longitude,
                undefined,
                true,
                undefined,
                undefined,
                classNames(styles.driverPlaceMarker,
                    {
                        [styles.suspectedDriverPlaceMarker]: place.isSuspected,
                        [styles.selectedDriverPlace]: place === store.selectedDriverPlace
                    }),
                zIndexMax
            )
            driverPlaceMarker
                .setIsDraggable(true)
                .onDrag((draggableMarker: Marker, coords: IGeocode) => {
                    const distanceMarkers = 25;
                    const markersToUpdate: Marker[] = [];

                    for (const marker of markers) {
                        if (marker === draggableMarker || !marker.isDraggable) {
                            continue;
                        }
                        if (distanceBetweenCoords(coords.latitude, coords.longitude, marker.latitude, marker.longitude) < distanceMarkers) {
                            if (!marker.className['big-marker']) {
                                marker.className['big-marker'] = true;
                                markersToUpdate.push(marker);
                            }
                        } else {
                            marker.className['big-marker'] = false;
                            markersToUpdate.push(marker);
                        }
                    }

                    const paths = mapMarkerPaths.getValue(draggableMarker) as Path[];
                    paths.forEach(p => {
                        p.geocodes[0] = coords;
                    })

                    layoutContext.updateMarkers(markersToUpdate);
                    layoutContext.updatePaths(paths);
                })
                .onDragEnd((draggableMarker: Marker) => {
                    const draggableMarkerBoundingBox = layoutContext.getMarkersBoundingBox([draggableMarker])[0];
                    const driverPlaceMarkers = mapDriverPlaceMarker.values() as Marker[];
                    const draggableMarkerIndex = driverPlaceMarkers.findIndex(dp => dp === draggableMarker)
                    const markerBoundingBoxes = layoutContext.getMarkersBoundingBox(driverPlaceMarkers);

                    const intersectionMarkers: Marker[] = [];
                    for (let i = 0; i < markerBoundingBoxes.length; ++i) {
                        if (i === draggableMarkerIndex) {
                            continue;
                        }
                        if (!(markerBoundingBoxes[i].right < draggableMarkerBoundingBox.left ||
                            markerBoundingBoxes[i].left > draggableMarkerBoundingBox.right ||
                            markerBoundingBoxes[i].bottom < draggableMarkerBoundingBox.top ||
                            markerBoundingBoxes[i].top > draggableMarkerBoundingBox.bottom)) {
                            driverPlaceMarkers[i].className['big-marker'] = false;
                            intersectionMarkers.push(driverPlaceMarkers[i])
                        }
                    }
                    layoutContext.updateMarkers(intersectionMarkers)

                    if (intersectionMarkers.length === 0) {
                        layoutContext.updateMarkers([draggableMarker]);
                        const paths = mapMarkerPaths.getValue(draggableMarker) as Path[];
                        paths.forEach(p => {
                            p.geocodes[0] = {latitude: draggableMarker.latitude, longitude: draggableMarker.longitude};
                        })
                        layoutContext.updatePaths(paths);

                        return;
                    }
                    if (intersectionMarkers.length > 1) {
                        alert('Можно объединить только с одной точкой');

                        return;
                    }
                    const driverPlace = mapDriverPlaceMarker.getKey(draggableMarker) as DriverPlace;
                    const intersectionDriverPlace = mapDriverPlaceMarker.getKey(intersectionMarkers[0]) as DriverPlace;

                    Modal.confirm({
                        content: 'Объединить точки?',
                        onOk: () => store.unionDriverPlaces(driverPlace.id, intersectionDriverPlace.id)
                    })
                })
                .onClick(() => store.setSelectedDriverPlace(place));
            markers.push(driverPlaceMarker)
            mapDriverPlaceMarker.put(place, driverPlaceMarker);

            const driverPlaceLines: Path[] = [];

            if (place.addresses.length > 0) {
                place.addresses.forEach(a => {
                    const address = new Marker(
                        a.coords.latitude,
                        a.coords.longitude,
                        undefined,
                        true,
                        undefined,
                        undefined,
                        styles.marker,
                        zIndexNormal
                    )
                    const line = new Path(
                        [place.coords.getIGeocode(), a.coords.getIGeocode()],
                        'geocode-polyline__default-line')
                    line.pointId = place.id;

                    markers.push(address);
                    lines.push(line);
                    driverPlaceLines.push(line);
                })
            }
            mapMarkerPaths.put(driverPlaceMarker, driverPlaceLines);
        })

        layoutContext.renderPath(lines);
        layoutContext.renderMarkers(markers);
        if (store.driverPlaces.length > 0 && store.needMoveBounds) {
            mapContext.setBounds(markers.map(m => ({latitude: m.latitude, longitude: m.longitude})));
        }
    }, [store.driverPlaces, store.selectedDriverPlace])
    
    const deleteDriverPlace = () => {
        Modal.confirm({
            content: 'Подтвердите удаление водительской точки',
            onOk: () => store.deleteDriverPlace(),
        });
    }

    return (
        <MapContent position='topright'>
            {store.selectedDriverPlace && (
                <div className={styles.modal}>
                    <CloseOutlined
                        className={styles.infoClose}
                        onClick={() => store.setSelectedDriverPlace(null)}
                    />
                    <h4 className={styles.underline}>Водительская точка для адресов:</h4>
                    {store.selectedDriverPlace.addresses.map(a =>
                        <div key={a.address}>- {a.address}</div>)}
                    {store.selectedDriverPlace.comment &&
                        <div className={styles.comment}>
                            <div className={styles.underline}>Комментарий водителя:</div>
                            <div>{store.selectedDriverPlace.comment}</div>
                        </div>
                    }
                    <div className={styles.hiddenText}>{store.selectedDriverPlace.id}</div>
                    <Button
                        onClick={deleteDriverPlace}
                        className={styles.buttonDelete}
                    >
                        Удалить
                    </Button>
                </div>
            )}
        </MapContent>
    );
});

export default MapDriverPlace;