import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {DistrictStore} from "../../Store/District/DistrictStore";
import {District} from '../../Store/District/District';
import DistrictName from './DistrictName/DistrictName';
import './DistrictListItem.css'

interface DistrictListItemProps {
  districtStore: DistrictStore;
  district: District;
  onDistrictRemove: (district: District) => Promise<void> | undefined;
}

const DistrictListItem = observer((props: DistrictListItemProps) => {
  const {districtStore, district, onDistrictRemove} = props;

  const handleDistrictClick = React.useCallback((id: number) => {
    districtStore.setSelectedDistrictId(districtStore.selectedDistrictIds.some(i => i === id) ? [] : [id]);
  }, [districtStore.selectedDistrictIds]);

  const removeDistrict = (e: React.MouseEvent<HTMLElement>, removableDistrict: District) => {
    e.stopPropagation();

    Modal.confirm({
      title: 'Вы действительно хотите удалить район?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk: () => {
        if (districtStore.selectedDistrictIds.some(i => i === removableDistrict.id)) {
          districtStore.setSelectedDistrictId([]);
        }

        onDistrictRemove(removableDistrict)
      }
    });
  };

  return (
    <>
      <div className={`district-list-item ${districtStore.selectedDistrictIds.some(i => i === district.id) ? 'district-list-item_selected' : ''}`}>
        <div className='district-list-item__color' style={{background: district.color}} />
        <div className='district-list-item__container'
          onClick={() => handleDistrictClick(district.id)}
        >
          <div className='district-list-item__container_name'>
            <DistrictName district={district} />
          </div>
          <div className='district-list-item__button-group'>
            <button
                className='ant-btn ant-btn-icon-only'
                onClick={() => districtStore.setCurrentlyBeingEditedDistrict(district)}
            >
              <EditOutlined />
            </button>
            <button
                className='ant-btn ant-btn-icon-only district-list-item__button-margin'
                onClick={(e) => removeDistrict(e, district)}
            >
              <DeleteOutlined />
            </button>
          </div>
        </div>
      </div>
    </>
  )
})

export default DistrictListItem