import {Type2, Type3} from '../../../../../ApiClient/Yoso/models';
import {TripPointType} from '../../../../../Model/TripPointId/TripPointType';
import {Types} from '../../../../../Std/Types';

export class TripPointTypeDeserializer {
    public deserialize(serializedObj: Type2 | Type3): TripPointType {
        switch (serializedObj) {
            case Type2.LOADINGSTORE:
            case Type3.LOADINGSTORE:
                return TripPointType.LoadingStore;
            case Type2.ORDER:
            case Type3.ORDER:
                return TripPointType.Order;
            case Type2.LUNCHPOINT:
                return TripPointType.LunchPoint;
            default: Types.assertUnreachable(serializedObj);
        }
    }
}

export const tripPointTypeDeserializer = new TripPointTypeDeserializer();
