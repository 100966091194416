import { Place } from '../../../Model/Place/Place';
import { Api } from '../Api';
import { validatedAddressSerializer } from '../Delivery/Model/Place/ValidatedAddressSerializer';
import { GeocodingApiGeocodedPlace, GeocodingApiSuspectPlaces } from '../../../ApiClient/YosoGeocoding/models';
import { CarMoveAndStopLocations } from '../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocations';
import { OrderSuspectPlace } from './Model/OrderSuspectPlace';
import { OrderSuspectPlaceMapper } from './Mapper/OrderSuspectPlaceMapper';
import { CarMoveAndStopLocationsMapper } from './Mapper/CarMoveAndStopLocationsMapper';

export class GeocodingApi {
    public static async getOrderSuspectPlaces(size: number, page: number):
        Promise<{places: ReadonlyArray<OrderSuspectPlace>, carLocations: ReadonlyArray<CarMoveAndStopLocations>, total: number}> {

        const response = await Api.doRequest<GeocodingApiSuspectPlaces>(() =>
            Api.geocodingApi.getOrderSuspectPlaces(size, {
                ...Api.getRequestOptions(),
                skip: (page-1) * size
            }),
        );

        return {
            places: OrderSuspectPlaceMapper.mapArrayFromApi(response.places),
            carLocations: CarMoveAndStopLocationsMapper.mapArrayFromApi(response.carMoveAndStopLocations),
            total: response.total
        }
    }

    public static async postValidatedPlace(place: Place, orderSuspectPlaceId?: number): Promise<undefined> {
        await Api.doRequest<undefined>(() =>
            Api.geocodingApi.postValidatedPlace(validatedAddressSerializer.serialize(place, orderSuspectPlaceId), Api.getRequestOptions()),
        );

        return;
    }

    public static async skipOrderSuspectPlace(id: number): Promise<undefined> {
        await Api.doRequest<undefined>(() =>
            Api.geocodingApi.skipOrderSuspectPlace(id, Api.getRequestOptions()),
        );

        return;
    }

    public static async geocodeAddress(address: string): Promise<GeocodingApiGeocodedPlace> {
        return Api.doRequest<GeocodingApiGeocodedPlace>(() =>
            Api.geocodingApi.geocodeAddress(address, Api.getRequestOptions()),
        );
    }
}
