import React from 'react'
import { Tooltip, useMapEvents } from 'react-leaflet';
import { observer } from 'mobx-react'
import { ArrayUtils } from '../../Std/ArrayUtils'
import { IGeocode } from '../../Model/Geocode/IGeocode'
import { MapMode } from '../MapContainer/model/MapMode'
import { District } from '../../Store/District/District'
import { MapContext } from '../MapContainer/MapContextProvider'
import { DistrictStore } from '../../Store/District/DistrictStore'
import Polygon from '../MapContainer/components/Polygon'

interface DistrictEditorProps {
    districtStore: DistrictStore;
    isOrdersVisible: boolean;
}

const DistrictMapEditor = observer(({districtStore, isOrdersVisible}: DistrictEditorProps) => {
    const geometryContext = React.useContext(MapContext)?.editorOnMap;
    if (!geometryContext) {
        throw new Error('Geometry context is undefined');
    }

    const tooltipVisibilityZoomLevel = 12;

    const [clickedOnMapDistrictId, setClickedOnMapDistrictId] = React.useState<number | undefined>(undefined);
    const [zoomLevel, setZoomLevel] = React.useState(1);

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    React.useEffect(() => {
        geometryContext.setSelfIntersection(false);
        geometryContext.enablePolygonDraw(true);

        geometryContext.onPolygonCreate((id: number, coords: IGeocode[], color: string) => {
            districtStore.addDistrict(id, coords, '', color, []);
        });
    }, []);

    React.useEffect(() => {
        geometryContext.setPalette(
            districtStore.districts.map((x) => x.color)
        );
    }, []);

    React.useEffect(() => {
        if (districtStore.selectedDistrictIds.length === 0) {
            setClickedOnMapDistrictId(undefined);

            return undefined;
        }

        if (districtStore.selectedDistrictIds.some(i => i === clickedOnMapDistrictId)) {
            return undefined;
        }

        if (districtStore.selectedDistrictIds.length === 1) {
            setClickedOnMapDistrictId(districtStore.selectedDistrictIds[0]);
            const district = districtStore.districts.find((x) => x.id === districtStore.selectedDistrictIds[0]);
            const coord = district?.getCentroid();

            if (coord) {
                geometryContext.setCenter(coord);
            }
        }
    }, [districtStore.selectedDistrictIds, clickedOnMapDistrictId, districtStore.districts])

    const onPolygonClick = React.useCallback((e: any, district: District) => {
        if (geometryContext.getCurrentMapMode() === MapMode.DeleteMode) {
            setClickedOnMapDistrictId(undefined);

            return;
        }

        setClickedOnMapDistrictId(district.id);
        districtStore.setSelectedDistrictId([district.id]);
    }, [])

    const onPolygonEdit = React.useCallback((districtId: number, coords: IGeocode[]) => {
        districtStore.editDistrictCoords(districtId, coords);
    }, [])

    const createTooltip = React.useCallback((district: District, orderMode: boolean): JSX.Element => (
        <>
            {ArrayUtils.sort(district.drivers, d => d.name).map(d => (
              <div key={d.id} style={{lineHeight: 1}}>
                <div>{d.name}</div>
              </div>
            ))}

            {orderMode && district.drivers.length === 1 && district.orders.length > 0 &&
                <div style={{marginTop: 5, lineHeight: 1}}>
                    <div>Заказы: {district.orders.length}</div>
                     <div>Бутыли: {district.info.bottles}</div>
                     <div>Прочее: {district.info.nonBottles.toFixed(1)}</div>
                </div>
            }
        </>
    ), [])

    return (
      <>
          {districtStore.districts.map((district: District) => (
            <Polygon
              key={district.id}
              coords={district.coords}
              color={district.color}
              editModeByDoubleClick={true}
              isSelected={districtStore.selectedDistrictIds.some(i => i === district.id)
                || districtStore.selectedDistrictIds.length === 0 && !!district.cityDistrictName
                && districtStore.selectedCityDistricts.includes(district.cityDistrictName)}
              onClick={(e) => onPolygonClick(e, district)}
              onEdit={(coords) => onPolygonEdit(district.id, coords)}>
                {zoomLevel > tooltipVisibilityZoomLevel &&
                    <Tooltip permanent={true} direction='center'>
                        {createTooltip(district, isOrdersVisible)}
                    </Tooltip>
                }
            </Polygon>
          ))}
      </>
    )
})

export default DistrictMapEditor;