import { Typography } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import { OrderStatus } from '../../Model/Order/OrderStatus';
import { Trip } from '../../Model/Trip/Trip';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { formatDistanceInKilometers } from '../../Std/FormatDistanceInKilometers';
import { formatDurationHoursMinutes } from '../../Std/formatDurationHoursMinutes';
import { formatPrice } from '../../Std/formatPrice';
import { MathUtils } from '../../Std/MathUtils';
import { OrdersReport } from '../OrdersReport/OrdersReport';
import { TripStatusComponent } from '../TripStatus/TripStatusComponent';

import './TripReport.css';

export interface ITripReportProps {
    trip: Trip;
}

const getAdjustedOrderIndices = (trip: Trip) =>
    trip.route.orders
        .filter((checkpoint) => checkpoint.order.adjustmentCount > 0)
        .map((checkpoint) => trip.route.orders.indexOf(checkpoint));

export const TripReport = (props: ITripReportProps): JSX.Element => {
    const [selectedOrderIndices, setSelectedOrderIndices] = React.useState<ReadonlyArray<number>>(
        getAdjustedOrderIndices(props.trip),
    );

    React.useEffect(() => {
        setSelectedOrderIndices(getAdjustedOrderIndices(props.trip));
    }, [props.trip]);

    const handleClickOrder = React.useCallback(
        (order: OrderRoutePoint) => {
            const orderIndex = props.trip.route.orders.indexOf(order);
            setSelectedOrderIndices(
                selectedOrderIndices.includes(orderIndex)
                    ? selectedOrderIndices.filter((index) => index !== orderIndex)
                    : [...selectedOrderIndices, orderIndex],
            );
        },
        [props.trip.route.orders, selectedOrderIndices],
    );

    const failedOrderCount = ArrayUtils.count(
        props.trip.route.orders,
        (checkpoint) => checkpoint.order.status === OrderStatus.Failed,
    );

    const delayCount = ArrayUtils.count(props.trip.route.orders.filter(x => x.order.isOwn()), (checkpoint) => checkpoint.hasArrivalDelay());

    const adjustmentCount = ArrayUtils.count(
        props.trip.route.orders.filter(x => x.order.isOwn()),
        (checkpoint) => checkpoint.order.adjustmentCount > 0,
    );

    const expectedPrice = MathUtils.sum(props.trip.route.orders.filter(x => x.order.isOwn()).map((checkpoint) => checkpoint.order.price));

    const paid = MathUtils.sum(
        ArrayUtils.defined(
            props.trip.route.orders
                .filter(
                    (checkpoint) =>
                        checkpoint.order.status === OrderStatus.Done
                )
                .map((checkpoint) => checkpoint.order.paid),
        ),
    );

    return (
        <div className="trip-report">
            <div className="trip-report__summary">
                <div className="trip-report__summary-column">
                    <div className="trip-report__summary-header">Данные автомобиля</div>
                    <div className="trip-report__summary-item">{props.trip.executor.car.carNumber}</div>
                    <div className="trip-report__summary-item">{props.trip.executor.driver.name}</div>
                </div>
                <div className="trip-report__summary-column">
                    <div className="trip-report__summary-header">
                        Детали рейса
                        <span className="trip-report__trip-status-badge">
                            <TripStatusComponent status={props.trip.status} />
                        </span>
                    </div>
                    <div className="trip-report__summary-subcolumns-container">
                        <div className="trip-report__summary-subcolumn">
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Невыполненных заказов</div>
                                <div className="trip-report__summary-item-value">
                                    <span className={classNames(failedOrderCount > 0 && 'trip-report__warning-text')}>
                                        {failedOrderCount}
                                    </span>
                                </div>
                            </div>
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Заказов, привезённых невовремя</div>
                                <div className="trip-report__summary-item-value">
                                    <span className={classNames(delayCount > 0 && 'trip-report__warning-text')}>
                                        {delayCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="trip-report__summary-subcolumn">
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Изменённых заказов</div>
                                <div className="trip-report__summary-item-value">
                                    <span className={classNames(adjustmentCount > 0 && 'trip-report__warning-text')}>
                                        {adjustmentCount}
                                    </span>
                                </div>
                            </div>
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Деньги</div>
                                <div className="trip-report__summary-item-value">
                                    {formatPrice(expectedPrice)} /{' '}
                                    <span className={classNames(paid !== expectedPrice && 'trip-report__warning-text')}>
                                        {formatPrice(paid)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="trip-report__summary-subcolumn">
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Дистанция (план/факт)</div>
                                <div className="trip-report__summary-item-value">
                                    {props.trip.estimatedDistance === undefined
                                        ? '\u2013'
                                        : formatDistanceInKilometers(props.trip.estimatedDistance)}{' '}
                                    /{' '}
                                    <span
                                        className={classNames(
                                            props.trip.estimatedDistance !== undefined &&
                                                props.trip.actualDistance !== undefined &&
                                                props.trip.actualDistance > props.trip.estimatedDistance &&
                                                'trip-report__warning-text',
                                        )}
                                    >
                                        {props.trip.actualDistance === undefined
                                            ? '\u2013'
                                            : formatDistanceInKilometers(props.trip.actualDistance)}
                                    </span>
                                </div>
                            </div>
                            <div className="trip-report__summary-item">
                                <div className="trip-report__summary-item-title">Время (план/факт)</div>
                                <div className="trip-report__summary-item-value">
                                    {props.trip.estimatedDuration === undefined
                                        ? '\u2013'
                                        : formatDurationHoursMinutes(props.trip.estimatedDuration)}{' '}
                                    /{' '}
                                    <span
                                        className={classNames(
                                            props.trip.actualDuration !== props.trip.estimatedDuration &&
                                                'trip-report__warning-text',
                                        )}
                                    >
                                        {props.trip.actualDuration === undefined
                                            ? '\u2013'
                                            : formatDurationHoursMinutes(props.trip.actualDuration)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* tslint:disable-next-line: no-magic-numbers */}
            <Typography.Title className="trip-report__title" level={2}>
                Заказы
            </Typography.Title>
            <OrdersReport
                checkpoints={props.trip.route.orders}
                onClickOrder={handleClickOrder}
                selectedIndices={selectedOrderIndices}
            />
        </div>
    );
};
