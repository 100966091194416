import { Tabs } from 'antd';
import classNames from 'classnames';
import * as React from 'react';
import { Route, RouteComponentProps, StaticContext, withRouter } from 'react-router';
import { CacheRoute, CacheSwitch } from 'react-router-cache-route'

import './RoutedTabs.css';

export interface IRoutedTabsTab {
    loading?: boolean;
    cache?: boolean;
    onClose?: () => void;
    onClick?: () => void;
    title: string;
    url: string;
    render(routeComponentProps: RouteComponentProps<any, StaticContext, any>): JSX.Element;
}

export interface IRoutedTabsProps extends RouteComponentProps {
    className?: string;
    tabs: ReadonlyArray<IRoutedTabsTab>;
}

const RoutedTabsComponent = (props: IRoutedTabsProps): JSX.Element => {
    const createTabNodes = React.useCallback(
        () =>
            props.tabs.map((tab) => (
                <Tabs.TabPane
                    tab={`${tab.title}${tab.loading === true ? ' \u2026' : ''}`}
                    key={tab.url}
                    closable={tab.onClose !== undefined}
                />
            )),
        [props.tabs],
    );

    const [tabNodes, setTabNodes] = React.useState(createTabNodes());
    React.useEffect(() => {
        setTabNodes(createTabNodes());
    }, [createTabNodes, props.tabs]);

    const createRouteNodes = React.useCallback(
        () =>
            props.tabs.map((tab) => !tab.cache ?
                    <Route
                        path={tab.url}
                        exact={true}
                        key={tab.url}
                        render={(routeComponentProps) => tab.render(routeComponentProps)} />
                    :
                    <CacheRoute
                        path={tab.url}
                        exact={true}
                        key={tab.url}
                        render={(routeComponentProps) => tab.render(routeComponentProps)}/>
            ),
        [props.tabs],
    );

    const [routeNodes, setRouteNodes] = React.useState(createRouteNodes());
    React.useEffect(() => {
        setRouteNodes(createRouteNodes());
    }, [createRouteNodes, props.tabs]);

    const handleTabChange = React.useCallback(
        (activeKey: string) => {
            const params = new URLSearchParams(props.location.search);
            props.history.push(`${activeKey}?${params.toString()}`);
        },
        [props.history],
    );

    const handleTabEdit = React.useCallback(
        (targetKey: string | React.MouseEvent | React.KeyboardEvent, action: 'add' | 'remove') => {
            if (action === 'remove') {
                const tab = props.tabs.find((tabIterator) => tabIterator.url === targetKey);
                if (tab !== undefined && tab.onClose !== undefined) {
                    tab.onClose();
                }
            }
        },
        [props.tabs],
    );

    return (
        <div className={classNames('routed-tabs', props.className)}>
            <Tabs
                hideAdd
                type="editable-card"
                activeKey={props.history.location.pathname}
                onChange={handleTabChange}
                onEdit={handleTabEdit}
            >
                {tabNodes}
            </Tabs>
            <CacheSwitch>{routeNodes}</CacheSwitch>
        </div>
    );
};

export const RoutedTabs = withRouter(RoutedTabsComponent);
