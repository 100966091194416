import React from 'react';
import ReactDOM from 'react-dom';
import { MapContext } from './MapContextProvider';

interface IMapContentProps {
    position: L.ControlPosition;
    children?: React.ReactNode;
}

const positionClasses = {
    bottomleft: 'leaflet-map-content__bottom-left',
    bottomright: 'leaflet-map-content__bottom-right',
    topleft: 'leaflet-map-content__top-left',
    topright: 'leaflet-map-content__top-right',
};

export const MapContent = (props: IMapContentProps) => {
    const containerContext = React.useContext(MapContext)?.containerRef;
    if (!containerContext) {
        throw new Error("Geometry context is undefined");
    }

    const positionClass = ((props.position && positionClasses[props.position]) || positionClasses.topleft);

    const div = containerContext.current;

    const a = div!.getElementsByClassName(positionClass);

    return ReactDOM.createPortal(props.children, a[0]);
}

export default MapContent;