import * as React from 'react';
import { formatTime } from './formatTime';
import { TimePeriod } from './TimePeriod';

export const formatTimePeriod = (timePeriod: TimePeriod): string =>
    `${formatTime(timePeriod.start)} \u2013 ${formatTime(timePeriod.end)}`;

export const formatTimePeriodWithMarkup = (timePeriod: TimePeriod): JSX.Element => (
    <>
        <span className="time-start">{formatTime(timePeriod.start)}</span>
        &nbsp;&ndash;&nbsp;
        <span className="time-end">{formatTime(timePeriod.end)}</span>
    </>
);
