import { MarkerTooltip } from "../MapContainer/model/MarkerTooltip";
import { Marker } from "../MapContainer/model/Marker";
import "./PlaceSelectMarker.css";

export class PlaceSelectMarker extends Marker {
    public constructor(
        latitude: number,
        longitude: number,
        validated: boolean,
        selected: boolean,
        clickable: boolean,
        tooltip: MarkerTooltip | undefined
    ) {
        let className = 'place-select__map-marker';

        if (validated) {
            className += ' validated-marker';
        }

        if (selected) {
            className += ' selected-marker';
        }

        super(latitude, longitude, undefined, clickable, tooltip, undefined, className);
    }
}