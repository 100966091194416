import React from 'react';
import {
    NoticeMailsStore
} from '../../../../Store/NoticeMailsStore/NoticeMailsStore';
import styles from './Mailbox.module.css';

interface IndicatorProps {
    store: NoticeMailsStore;
    children: React.ReactNode;
}

const Indicator = ({store, children}: IndicatorProps): JSX.Element => {
    const containerRef = React.useRef(null);

    const handleIntersection = (entries: any, intersectionObserver: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                store.fetchArchiveMailsByDriverId()
            }
        });
    };

    React.useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const intersectionObserver = new IntersectionObserver(handleIntersection, options);

        if (containerRef.current) {
            intersectionObserver.observe(containerRef.current!);
        }

        return () => intersectionObserver.disconnect();
    }, [containerRef.current, store.selectedDriverId]);

    return (
        <div ref={containerRef} className={styles.indicator}>
            {children}
        </div>
    );
};

export default Indicator;