import { Button, Form, Input } from 'antd';
import * as React from 'react';

import { CompanyApiLogistResponse } from '../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';

import './LogisticianEntityEdit.css';

export interface IExistingLogistician {
    id: number;
    logistician: CompanyApiLogistResponse;
}

export interface ILogisticianEntityEditProps {
    logistician: IExistingLogistician | undefined;
    onSave: () => void;
}

export const LogisticianEntityEdit = (props: ILogisticianEntityEditProps): JSX.Element => {
    const { onSave } = props;

    const destroyed = React.useRef(false);
    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const [name, setName] = React.useState(
        props.logistician === undefined ? '' : props.logistician.logistician.employee!.name,
    );
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState(
        props.logistician === undefined ? '' : props.logistician.logistician.employee!.credentials!.username,
    );

    const [activity, setActivity] = React.useState(
        !props.logistician || !props.logistician.logistician.employee ? false : props.logistician.logistician.employee.isActive
    );

    const isDataValid = React.useCallback((): boolean => name !== undefined && name !== '', [name]);

    const handleUsernameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(e.currentTarget.value);
    }, []);

    const handlePasswordChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.currentTarget.value);
    }, []);

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setName(e.currentTarget.value);
    }, []);

    const [saving, setSaving] = React.useState(false as boolean);

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid()) {
            return;
        }

        setSaving(true);

        try {
            if (props.logistician === undefined) {
                await CompanyApi.postLogistician({
                    employee: {
                        credentials: {
                            password: password,
                            username: username,
                        },
                        name: name,
                        isActive: activity
                    },
                });
            } else {
                await CompanyApi.putLogistician({
                    employee: {
                        id: props.logistician.id,
                        credentials: {
                            password: password,
                        },
                        name: name,
                        isActive: activity
                    },
                });
            }
            if (destroyed.current) {
                return;
            }
            onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [isDataValid, name, onSave, password, props.logistician, username]);

    return (
        <div>
            <div className="logistician-entity-edit__block">
                <div className="logistician-entity-edit__form">
                    <Form.Item label="Логин">
                        <Input
                            defaultValue={username}
                            onChange={handleUsernameChange}
                            disabled={props.logistician !== undefined}
                        />
                    </Form.Item>
                    <Form.Item label="Пароль">
                        <Input defaultValue={password} onChange={handlePasswordChange} />
                    </Form.Item>
                    <Form.Item label="Имя">
                        <Input defaultValue={name} onChange={handleNameChange} />
                    </Form.Item>
                </div>
            </div>
            <div className="logistician-entity-edit__block">
                <Button type="primary" loading={saving} onClick={handleSaveButtonClick} disabled={!isDataValid()}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
