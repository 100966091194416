import { DeliveryApiTrip, DeliveryPlanResponse } from '../../../../../ApiClient/Yoso/models';
import { Car } from '../../../../../Model/Car/Car';
import { District } from '../../../../../Model/District/District';
import { Store } from '../../../../../Model/Store/Store';
import { Trip } from '../../../../../Model/Trip/Trip';
import { TripPlan } from '../../../../../Model/TripPlan/TripPlan';
import { districtSerializer } from '../../../Company/Model/District/DistrictSerializer';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { carDeserializer } from '../Car/CarDeserializer';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { lunchDeserializer } from '../Lunch/LunchDeserializer';
import { orderDeserializer } from '../Order/OrderDeserializer';
import { storeDeserializer } from '../Store/StoreDeserializer';
import { tripDeserializer } from '../Trip/TripDeserializer';
import { tripWorkingShiftDeserializer } from '../TripWorkingShift/TripWorkingShiftDeserializer';

export interface IDeliveryPlanResponse {
    cars: ReadonlyArray<Car>;
    plan: TripPlan;
}

export class DeliveryPlanResponseDeserializer
    implements IResponseDeserializer<DeliveryPlanResponse, IDeliveryPlanResponse> {
    public deserialize(response: DeliveryPlanResponse): IDeliveryPlanResponse {
        const tripById = new Map<number, DeliveryApiTrip>([]);
        for (const trip of response.plan!.trips) {
            tripById.set(trip.id, trip);
        }

        const planDateTimePeriod = dateTimePeriodDeserializer.deserialize(response.plan!.dateTimePeriod);

        return {
            cars: Car.sortCarArray(response.cars!.map((car) => carDeserializer.deserialize(car))),
            plan: new TripPlan(
                response.plan!.id,
                response.plan!.isPlanDayOff,
                planDateTimePeriod,
                response.orders!.map((order) => {
                    const trip = tripById.get(order.tripId);
                    if (trip === undefined) {
                        throw new Error(`Trip of order ${order.id.toString()} not found`);
                    }

                    return orderDeserializer.deserialize(
                        order,
                        response.cars!,
                        trip.carId,
                        response.drivers!,
                        trip.driverId,
                        response.products ?? [],
                        tripWorkingShiftDeserializer.deserialize(trip.workingShift)
                    );
                }),
                response.plan!.trips.map(
                    (trip): Trip =>
                        tripDeserializer.deserialize(
                            trip,
                            response.cars!,
                            response.drivers!,
                            response.orders!,
                            planDateTimePeriod,
                            response.products!,
                            response.loadingStores!,
                            response.lunches!,
                        ),
                ),
                response.stores!.map(
                    (store): Store => storeDeserializer.deserialize(store)
                ),
                response.districts!.map(
                    (district): District => districtSerializer.deserialize(district)
                ),
                response.lunches!.map((lunch) => {
                    const trip = tripById.get(lunch.tripId);
                    if (trip === undefined) {
                        throw new Error(`Trip of lunch ${lunch.id.toString()} not found`);
                    }

                    return lunchDeserializer.deserialize(lunch);
                }),
                response.alternativePlanId
            ),
        };
    }
}

export const deliveryPlanResponseDeserializer = new DeliveryPlanResponseDeserializer();
