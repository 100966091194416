import React from 'react';
import { observer } from 'mobx-react';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import { DistrictStore } from '../../../../Store/District/DistrictStore';
import DriverGroup from './DriverGroup';
import DriverListItem from './DriverListItem';

interface DriverGroupsProps {
    districtStore: DistrictStore;
}

const DriverGroups = observer((props: DriverGroupsProps) => {
    const { districtStore } = props;

    return (
        <>
            {ArrayUtils.sort(districtStore.unitedDistricts, (by) => by.name).map((union) =>
                <DriverGroup
                    key={union.name}
                    cityDistrict={union}
                    districtStore={districtStore}
                />,
            )}
            {districtStore.noUnitedDistricts.length > 0 &&
                districtStore.unitedDistricts.length > 0 &&
                <div className='district-list__space' />}
            {ArrayUtils.sort(districtStore.noUnitedDistrictsDrivers, (by) => by.name).map(d =>
                <DriverListItem key={d.name} districtStore={districtStore} driver={d} />
            )}
        </>
    );
});

export default DriverGroups;