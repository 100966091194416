import {
    DeliveryApiLoadingStore,
    DeliveryApiStore
} from '../../../../../ApiClient/Yoso/models';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { LoadingStore } from '../../../../../Model/Store/LoadingStore';
import { Store } from '../../../../../Model/Store/Store';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class StoreDeserializer {
    public deserialize(store: DeliveryApiStore): Store {
        return new Store(
            store.id,
            new Geocode({
                latitude: store.geocode.latitude!,
                longitude: store.geocode.longitude!
            }),
            store.address,
            store.isMain
        );
    }

    public deserializeLoadingStore(store: DeliveryApiLoadingStore): LoadingStore {
        return new LoadingStore(
            store.id,
            new Geocode({
                latitude: store.geocode.latitude!,
                longitude: store.geocode.longitude!
            }),
            store.address,
            store.stayDuration,
            store.expectedArrivalDateTime ? dateTimeDeserializer.deserialize(store.expectedArrivalDateTime) : undefined
        );
    }
}

export const storeDeserializer = new StoreDeserializer();
