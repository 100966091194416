import React from "react";
import {CRS, Map } from "leaflet";
import { MapContainer as LeafletMapContainer, TileLayer } from "react-leaflet";
import MapContextProvider from "./MapContextProvider";
import {MapSource} from './MapSource';
import './MapContainer.css';

export interface IMapContainerProps {
  children?: React.ReactNode;
  center?: L.LatLngExpression;
  bounds?: L.LatLngBoundsExpression;
  zoomValue?: number;
  fullscreen?: boolean;
  className?: string;
  mapSource?: MapSource
}

const positionClasses = [
  'leaflet-bottom leaflet-left',
  'leaflet-bottom leaflet-right',
  'leaflet-top leaflet-left',
  'leaflet-top leaflet-right'
];

const MapContainer = (props: IMapContainerProps): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const mapRef = React.useRef<Map|null>(null);
  const [mapRefAvailable, setMapRefAvailable] = React.useState(false);

  React.useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    const divContainer = containerRef.current;

    for (const classname of positionClasses) {
      const div = divContainer.getElementsByClassName(classname);
      const appControl = document.createElement("div");
      appControl.className = "leaflet-control leaflet-bar leaflet-app-control";
      div[0].appendChild(appControl);
    }
  }, [containerRef.current])

  React.useEffect(() => {
    if (mapRef && props.bounds) {
      mapRef.current?.fitBounds(props.bounds);
    }
  }, [mapRefAvailable, props.bounds]);

  return (
    <div className={`map-container ${props.className ? props.className : ''}`} ref={containerRef}>
      <LeafletMapContainer
        whenCreated={mapInstance => { mapRef.current = mapInstance; setMapRefAvailable(true); }}
        center={props.bounds ? undefined : props.center}
        zoom={props.zoomValue || 14} // eslint-disable-line
        zoomControl={true}
        className="map-container__leaflet-map"
        fullscreenControl={props.fullscreen !== undefined ? props.fullscreen : true}
        crs={props.mapSource === MapSource.Yandex ? CRS.EPSG3395 : CRS.EPSG3857}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={props.mapSource || MapSource.TwoGis}
        />
        <MapContextProvider containerRef={containerRef}>
          {props.children}
        </MapContextProvider>
      </LeafletMapContainer>
      <div className="leaflet-map-content__top-left" />
      <div className="leaflet-map-content__top-right" />
      <div className="leaflet-map-content__bottom-left" />
      <div className="leaflet-map-content__bottom-right" />
    </div>
  )
}

export default MapContainer