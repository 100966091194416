import * as React from 'react';
import { Skeleton } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CompanyApi } from '../../../Backend/Api/Company/CompanyApi';
import { Page } from '../../Page/Page';
import SettingsEdit from '../../SettingsEdit/SettingsEdit';

export interface ISettingsRouteProps extends RouteComponentProps {}

export const SettingsRoute = withRouter(
    (props: ISettingsRouteProps): JSX.Element => {
        const destroyed = React.useRef(false);

        React.useEffect(
            () => (): void => {
                destroyed.current = true;
            },
            [],
        );

        const [loading, setLoading] = React.useState(true as boolean);
        const [companyName, setCompanyName] = React.useState(undefined as string | undefined);
        const [proficiencyCoefficient, setProficiencyCoefficient] = React.useState(undefined as number | undefined);

        const fetchData = async (): Promise<undefined> => {
            setLoading(true);
            try {
                const response = await CompanyApi.getMyCompany();
                if (destroyed.current) {
                    return;
                }
                setCompanyName(response.company.name);
                setProficiencyCoefficient(response.company.proficiencyCoefficient);
            } catch (error) {
                if (destroyed.current) {
                    return;
                }
                alert(String(error));
            }
            setLoading(false);
        };

        React.useEffect((): void => {
            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );
        }, []);

        const handleSaveSettings = React.useCallback(
            (responseCompanyName: string, responseTotalCoefficient: number): void => {
                setCompanyName(responseCompanyName);
                setProficiencyCoefficient(responseTotalCoefficient);
            },
            [],
        );

        return (
            <Page header="Настройки">
                {loading ? (
                    <Skeleton active round title={false} />
                ) : (
                    <SettingsEdit
                        companyName={companyName}
                        proficiencyCoefficient={proficiencyCoefficient}
                        onSave={handleSaveSettings}
                    />
                )}
            </Page>
        );
    },
);
