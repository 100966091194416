import React from 'react';
import { useAllDrivers } from '@/hooks/useAllDrivers';
import { Employee } from '@/Model/Employee/Employee';
import { Trip } from '@/Model/Trip/Trip';
import { TripWorkingShift } from '@/Model/Trip/TripWorkingShift';
import { ArrayUtils } from '@/Std/ArrayUtils';

export const useAvailableDriversToAddPlan = (trips: Trip[] | undefined,
                                             isDayOff: boolean) => {
    const { drivers } = useAllDrivers();

    return React.useMemo(() => {
        if (!trips) {
            return [];
        }
        const sortedDrivers = ArrayUtils.sort(drivers, by => by.driver.name);
        const availableDrivers: {
            driver: Employee,
            workingShift: TripWorkingShift
        }[] = [];

        for (const driver of sortedDrivers) {
            const existingTrips = trips.filter(t => t.executor.driver.id === driver.driver.id);

            if (isDayOff) {
                if (existingTrips.length === 0) {
                    availableDrivers.push({
                        driver,
                        workingShift: TripWorkingShift.DAYOFF,
                    });
                }
            } else {
                if (!existingTrips.some(t => t.workingShift === TripWorkingShift.MORNING)) {
                    availableDrivers.push({
                        driver,
                        workingShift: TripWorkingShift.MORNING,
                    });
                }

                if (!existingTrips.some(t => t.workingShift === TripWorkingShift.EVENING)) {
                    availableDrivers.push({
                        driver,
                        workingShift: TripWorkingShift.EVENING,
                    });
                }
            }
        }

        return availableDrivers;
    }, [drivers, trips]);
}