import React from 'react';
import ru_RU from 'antd/es/locale/ru_RU';
import moment, {Moment} from 'moment';
import {Button, ConfigProvider, DatePicker, Select} from 'antd';
import {DollarCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {MapContext} from '../../MapContainer/MapContextProvider';
import { MarkerBuilder } from '../../../Model/MarkerBuilder/MarkerBuilder';
import { MapControl } from '../../MapContainer/MapControl';
import { DistrictOrder } from '../../../Store/District/DistrictOrder';
import { DistrictGroupCode } from '../../../Store/District/DistrictGroupCode';
import styles from './OrdersOnDate.module.css';

interface MapOrdersByDateProps {
  orders: DistrictOrder[];
  ordersGroup: string;
  dateStart: Moment;
  dateEnd: Moment;
  onSelectDate: (dateStart: Date, dateEnd: Date) => void;
  isOrdersVisible: boolean;
  setIsOrdersVisible: (value: boolean) => void;
  setOrdersGroup: (value: string) => void;
}

const disableClusteringAtZoom = 14;

const OrdersOnDate = (props: MapOrdersByDateProps) => {
  const mapContext = React.useContext(MapContext)?.map;
  if (!mapContext) {
    throw new Error('Map context is undefined');
  }  

  const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

  const [datePeriod, setDatePeriod] = React.useState<[Moment, Moment] | null>([props.dateStart, props.dateEnd]);

  const [ordersGroup, setOrdersGroup] = React.useState<string>(props.ordersGroup);

  React.useEffect(() => {
    layoutContext.clear();
    if (!props.isOrdersVisible) {
      return undefined;
    }

    const markers = props.orders
      .filter(o => o.coords.latitude && o.coords.longitude)
      .map(o => new MarkerBuilder(o.coords)
        .setClassName(styles.marker)
        .setTooltip(o.address)
        .build()
      )

    layoutContext.renderClusterMarkers(markers, disableClusteringAtZoom);
  }, [props.dateStart, props.dateEnd, props.isOrdersVisible])

  React.useEffect(() => {
    setOrdersGroup(props.ordersGroup === DistrictGroupCode.DayOff ? '' : props.ordersGroup)
  }, [props.ordersGroup])

  const handleChangeDate = (dateObj: any) => {
    if (dateObj === null) {
      return;
    }

    setDatePeriod([dateObj[0], dateObj[1]]);
  }

  const confirm = () => {
    const countPeriodDateValues = 2;
    if (datePeriod === null ||
      datePeriod.length !== countPeriodDateValues ||
      datePeriod[0] === null
      || datePeriod[1] === null
    ) {
      return;
    }
    props.onSelectDate(datePeriod[0].toDate(), datePeriod[1].toDate());
  }

  return (
    <>
      <MapControl position='topleft' separate={false}>
        <a
          className={styles.buttonIcon}
          onClick={() => props.setIsOrdersVisible(!props.isOrdersVisible)}
        >
          <DollarCircleOutlined className={props.isOrdersVisible ? styles.buttonIconActive : ''} />
        </a>
      </MapControl>
      <MapControl position='bottomleft' separate={false}>
        <ConfigProvider locale={ru_RU}>
          <DatePicker.RangePicker
            suffixIcon={null}
            style={{width: 225}}
            placeholder={["Начало", "Конец"]}            
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            disabledDate={(curr) => curr > moment().add(2, 'days') || curr < moment().subtract(1, 'month')}
            value={datePeriod}
            onChange={handleChangeDate}
          />
        </ConfigProvider>
        <Button onClick={confirm}>
          <SearchOutlined />
        </Button>
      </MapControl>
      <MapControl position='bottomright' separate={false}>
        <Select<string> value={ordersGroup} onChange={props.setOrdersGroup}>
          <Select.Option value=''>Все заказы</Select.Option>
          <Select.Option value={DistrictGroupCode.Morning}>Утренние+Дневные</Select.Option>
          <Select.Option value={DistrictGroupCode.Evening}>Вечерние</Select.Option>
        </Select>
      </MapControl>
    </>
  );
};

export default OrdersOnDate;