import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { useCompany } from '../../AppProviders/Providers/CompanyProvider';
import MapContainer from '../../MapContainer/MapContainer';
import { DriverPlaceStore } from './DriverPlaceStore';
import MapDriverPlace from './MapDriverPlace';
import styles from './DriverPlaceRoute.module.css';

export const DriverPlaceRoute = withRouter(observer(() => {
    const { center } = useCompany();

    const store = React.useMemo(() => new DriverPlaceStore(), []);

    return (
        <Spin spinning={store.loading}>
            <div className={styles.wrapper}>
                <div className={styles.list}>
                    {store.drivers?.map(d =>
                        <div
                            key={d.driver.id}
                            className={classNames(
                                styles.listItem,
                                {
                                    [styles.selectedListItem]: d.driver.id === store.selectedDriverId,
                                    [styles.inactive]: !d.driver.isActive,
                                },
                            )}
                            onClick={() => store.selectDriver(d.driver.id)}
                        >
                            <div className={styles.listItemContent}>{d.driver.name}</div>
                        </div>
                    )}
                </div>
                <div className={styles.map}>
                    {center &&
                        <MapContainer
                            center={[center.latitude, center.longitude]}
                            fullscreen={true}
                        >
                            <MapDriverPlace
                                store={store}
                            />
                        </MapContainer>
                    }
                </div>
            </div>
        </Spin>
    );
}));
