import {
    DeliveryApiOrderSearchDetails
} from '@/ApiClient/Yoso/models';
import {
    OrderSearchDetails
} from '@/Model/OrderSearchDetails/OrderSearchDetails';

export class OrderSearchDetailsDeserializer {
    public deserialize(apiOrder: DeliveryApiOrderSearchDetails): OrderSearchDetails {
        return ({
            orderId: apiOrder.orderId,
            tripId: apiOrder.tripId,
            clientName: apiOrder.clientName,
            phone: apiOrder.phone,
            address: apiOrder.address,
            driverName: apiOrder.driverName
        })
    }
}

export const orderSearchDetailsDeserializer = new OrderSearchDetailsDeserializer();