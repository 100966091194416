import React, { useMemo } from 'react';
import { Button } from 'antd';
import {
    CalendarOutlined,
    CarTwoTone,
    CloseOutlined,
    EditOutlined, RedoOutlined, StopOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { ProductOperationType } from '@/Model';
import { ReactComponent as UserIconComponent } from '@/icons/user.svg';
import { ReactComponent as BottleIconComponent } from '@/icons/bottle-product.svg';
import { ReactComponent as CommentBigIconComponent } from '@/icons/comment-big.svg';
import { ReactComponent as LocationBigIconComponent } from '@/icons/location-big.svg';
import { ReactComponent as PhoneBigIconComponent } from '@/icons/phone-big.svg';
import { ReactComponent as TimeBigIconComponent } from '@/icons/time-big.svg';
import { OrderRoutePoint } from '@/Map/Route/RoutePoint/OrderRoutePoint';
import { EditOrderDto } from '@/Model/Order/EditOrderDto';
import { OrderStatus } from '@/Model/Order/OrderStatus';
import { RescheduleOrderDto } from '@/Model/Order/RescheduleOrderDto';
import { ProductOperationDealType } from '@/Model/ProductOperation/ProductOperationDealType';
import { Trip } from '@/Model/Trip/Trip';
import { formatTime } from '@/Std/formatTime';
import { formatTimePeriod } from '@/Std/FormatTimePeriod';
import { RescheduleOrder } from '../../features/RescheduleOrder/RescheduleOrder';
import { CancelOrder } from '../../features/CancelOrder/CancelOrder';
import { OrderEditor } from '../../features/OrderEditor/OrderEditor';
import { TransferOrder } from '../../features/TransferOrder/TransferOrder'
import './OrderInfo.css';

export interface OrderInfoProps {
    checkpoint: OrderRoutePoint;
    onFailOrder: (orderId: number, reason: string, subReason?: string) => void;
    onRestoreOrder: (orderId: number) => void;
    onEditOrder: (orderId: number, dto: EditOrderDto) => void;
    onRescheduleOrder: (orderId: number, dto: RescheduleOrderDto) => void;
    onTransferOrder: (orderId: number, tripId: number) => void;
    onCloseOrderDetails: () => void;
    onEditPlace?: () => void;
    trip: Trip;
    trips: ReadonlyArray<Trip>;
}

enum OrderActionMode {
    Transfer,
    Reschedule,
    Cancel,
    EditDoneOrder
}

export const OrderInfo = (props: OrderInfoProps): JSX.Element => {
    const { checkpoint, onFailOrder, onTransferOrder, onEditPlace, onRescheduleOrder, onRestoreOrder, onEditOrder } = props;
    const order = checkpoint.order;

    const [orderActionMode, setOrderActionMode] = React.useState<OrderActionMode | null>(null)

    const shipmentOperations = order.operations.productOperations.filter(
        (productOperation) => productOperation.type === ProductOperationType.Shipment,
    );
    const returnOperations = order.operations.productOperations.filter(
        (productOperation) => productOperation.type === ProductOperationType.Return,
    );

    const failOrder = (reason: string, subReason?: string) => {
        onFailOrder(order.id, reason, subReason);
    }

    const editDoneOrder = React.useCallback((dto: EditOrderDto) => {
        onEditOrder(order.id, dto);
    }, [onEditOrder]);

    const rescheduleOrder = (dto: RescheduleOrderDto) => {
        onRescheduleOrder(order.id, dto)
    }

    const transferOrder = (toTripId: number) => {
        onTransferOrder(order.id, toTripId);
    };

    const handleEditCoordinatesButtonClick = () => {
        onEditPlace!();
    };

    React.useEffect(() => {
        setOrderActionMode(null);
    }, [props.checkpoint.order.id])

    const openRescheduleWindow = () => {
        if (!props.checkpoint.desiredDateTimePeriod) {
            return;
        }

        setOrderActionMode(OrderActionMode.Reschedule);
    };

    const tripListWithoutCurrent = useMemo(() => props.trips.filter(
        (trip: Trip) => trip.id !== props.trip!.id &&
            (trip.executor.driver.id === props.trip!.executor.driver.id ||
                trip.workingShift === props.trip?.workingShift ||
                trip?.workingShift === undefined
            )
    ), [props.trips, props.trip])

    const close = () => orderActionMode !== null
        ? setOrderActionMode(null)
        : props.onCloseOrderDetails()

    return (
        <div className="order-info">
            <CloseOutlined
                className="order-info__close-button"
                onClick={close}
            />
            {orderActionMode === OrderActionMode.Transfer &&
                <TransferOrder
                    trips={tripListWithoutCurrent}
                    onSubmit={transferOrder}
                />
            }
            {orderActionMode === OrderActionMode.Reschedule &&
                <RescheduleOrder
                    period={checkpoint.desiredDateTimePeriod!}
                    onRescheduleOrder={rescheduleOrder}
                />
            }
            {orderActionMode === OrderActionMode.EditDoneOrder &&
                <OrderEditor
                    order={order}
                    onSave={editDoneOrder}
                />
            }
            {orderActionMode === OrderActionMode.Cancel &&
                <CancelOrder onFailOrder={failOrder} />
            }
            {orderActionMode === null &&
            <>
                    <div className="order-info__block">
                        <div className="order-info__line">
                            <CarTwoTone twoToneColor="#70c1fb" className="order-info__line-icon" />
                            <div className="order-info__line-text order-info__line-text-client">
                                {props.trip?.executor.driver.name}
                            </div>
                        </div>
                        <div className="order-info__line">
                            <UserIconComponent className="order-info__line-icon" />
                            <div className="order-info__line-text order-info__line-text-client">{order.clientName}</div>
                        </div>
                        <div className="order-info__line">
                            <LocationBigIconComponent className="order-info__line-icon" />
                            <div className="order-info__line-text">{order.fullAddress}</div>

                            <span className="order-info__secondary-text">
                                {props.checkpoint.geocode.latitude}, {props.checkpoint.geocode.longitude}
                            </span>
                            {onEditPlace !== undefined && (
                                <Button
                                    type="link"
                                    size="small"
                                    className="order-info__edit-icon"
                                    onClick={handleEditCoordinatesButtonClick}
                                >
                                    <span />
                                </Button>
                            )}
                        </div>
                        <div className="order-info__line">
                            <TimeBigIconComponent className="order-info__line-icon" />
                            {order.expectedArrivalDateTime && (
                                <div className="order-info__line-text">
                                    {formatTime(order.expectedArrivalDateTime)}
                                </div>
                            )}
                            <div className="order-info__secondary-text">
                                {formatTimePeriod(order.desiredDateTimePeriod!.getTimePeriod())}
                            </div>
                        </div>
                        <div className="order-info__line">
                            <PhoneBigIconComponent className="order-info__line-icon" />
                            {order.phoneNumber ? (
                                <div className="order-info__line-text">{order.phoneNumber}</div>
                            ) : (
                                '\u2013'
                            )}
                        </div>
                        <div className="order-info__line">
                            <CommentBigIconComponent className="order-info__line-icon" />
                            {order.comment ? (
                                <div className="order-info__line-text">{order.comment}</div>
                            ) : (
                                '\u2013'
                            )}
                        </div>
                    </div>
                    <div className="order-info__block">
                        <div className="order-info__block">
                            <div className="order-info__block-subheader">Доставить</div>
                            {shipmentOperations.map((operation, index) => (
                                <div key={index} className="order-info__operation-block">
                                    <BottleIconComponent className="order-info__operation-block-icon" />
                                    <div className="order-info__operation-block-text">
                                        {operation.product.name}{' '}
                                        {operation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                                    </div>
                                    <div className="order-info__operation-block-text">x{operation.estimatedCount}</div>
                                </div>
                            ))}
                        </div>
                        {returnOperations.length > 0 &&
                            <div className="order-info__block">
                                <div className="order-info__block-subheader">Забрать</div>
                                {returnOperations.map((operation, index) => (
                                    <div key={index} className="order-info__operation-block">
                                        <BottleIconComponent className="order-info__operation-block-icon" />
                                        <div className="order-info__operation-block-text">
                                            {operation.product.name}{' '}
                                            {operation.dealType === ProductOperationDealType.Renting && '(Аренда)'}
                                        </div>
                                        <div className="order-info__operation-block-text">x{operation.estimatedCount}</div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div style={{color: "white", fontSize: "10px", lineHeight: "10px"}}>{order.code}</div>
                    <div>
                        {order.isDone() && !order.isInternal &&
                            <Button
                                type='link'
                                size='large'
                                title='Редактировать заказ'
                                onClick={() => setOrderActionMode(OrderActionMode.EditDoneOrder)}
                            >
                                <EditOutlined /> Редактировать заказ
                            </Button>
                        }
                        {!order.isActive() && order.status !== OrderStatus.Moved && (
                            <Button
                                type='link'
                                size='large'
                                title='Восстановить заказ'
                                disabled={tripListWithoutCurrent.length === 0}
                                onClick={() => onRestoreOrder(order.id)}
                            >
                                <RedoOutlined /> Восстановить заказ
                            </Button>
                        )}
                        {order.canBeTransferred() && (
                            <Button
                                type='link'
                                size='large'
                                disabled={tripListWithoutCurrent.length === 0}
                                onClick={() => setOrderActionMode(OrderActionMode.Transfer)}
                            >
                                <UserSwitchOutlined /> {order.isActive() ? 'Передать заказ' : 'Восстановить и передать заказ'}
                            </Button>
                        )}

                        {order.canBeRescheduled() && props.onRescheduleOrder && (
                            <Button
                                type='link'
                                size='large'
                                title='Перенести заказ на другой день'
                                onClick={openRescheduleWindow}
                            >
                                <CalendarOutlined /> Перенести заказ на другой день
                            </Button>
                        )}
                        {order.canBeCancelled() && (
                            <>
                                <Button
                                    type='link'
                                    size='large'
                                    danger
                                    title='Отменить заказ'
                                    onClick={() => setOrderActionMode(OrderActionMode.Cancel)}
                                >
                                    <StopOutlined /> Отменить заказ
                                </Button>
                            </>
                        )}
                    </div>
                </>
            }
        </div>
    );
};
