import {makeAutoObservable} from 'mobx';
import { Moment } from "moment";
import { RouteInfo } from "../../Backend/Api/Delivery/Model/RouteInfo/RouteInfo";
import { DriverPoint } from "../../Model/DriverPoint/DriverPoint";
import { Store } from "../../Model/Store/Store";
import { TripComparisonOrder } from "../../Model/TripComparison/TripComparisonOrder";

export class DriverRouteEditing {
    public selectedRoute: RouteInfo;
    public carCapacity: number;
    public shipmentCount: number;

    public startPoint: DriverPoint | undefined;
    public finishPoint: DriverPoint | undefined;
    public loadingStore: Store | undefined;
    public startTime: Moment | null;
    public sequence: (TripComparisonOrder | Store)[] = [];

    public constructor(
        selectedRoute: RouteInfo,
        startPoint: DriverPoint | undefined,
        finishPoint: DriverPoint | undefined,
        startTime: Moment | null
    ) {
        this.selectedRoute = selectedRoute;
        this.startPoint = startPoint;
        this.finishPoint = finishPoint;
        this.startTime = startTime;
        this.carCapacity = selectedRoute.carCapacity;
        this.shipmentCount = selectedRoute.shipmentCount;

        makeAutoObservable(this);
    }

    public setCarCapacity(value: number):void {
        this.carCapacity = value;
    }

    public setStartPoint(value: DriverPoint):void {
        this.startPoint = value;
    }

    public setFinishPoint(value: DriverPoint):void {
        this.finishPoint = value;
    }

    public setStartTime(value: Moment):void {
        this.startTime = value;
    }

    public setLoadingStore(value: Store):void {
        this.loadingStore = value;
    }

    public validPreconditions(): boolean {
        return this.startPoint !== undefined
            && this.finishPoint !== undefined
            && this.startTime !== undefined
            && (!this.selectedRoute.withLoadingStore || this.loadingStore !== undefined);
    }

    public isComplete(ordersCount: number): boolean {
        return (!this.selectedRoute.withLoadingStore && this.sequence.length === ordersCount)
            || (this.selectedRoute.withLoadingStore && this.sequence.length-1 === ordersCount) ;
    }

    public addToSequence(point: TripComparisonOrder | Store):void {
        if (!this.sequence.includes(point)) {
            this.sequence.push(point);
        }
    }

    public removeFromSequence(point: TripComparisonOrder | Store):void {
        this.sequence = this.sequence.filter(p => p !== point);
    }
}