export class MathUtils {
    public static readonly decimalRadix = 10;
    public static readonly degreesInCircle = 360;
    public static readonly degreesInPi = 180;

    public static clamp(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(value, max));
    }

    public static degreesToRadians(value: number): number {
        return (value / MathUtils.degreesInPi) * Math.PI;
    }

    public static getFractionSize(value: number): number {
        const valueString = String(value);
        const dotIndex = valueString.indexOf('.');

        return dotIndex > -1 ? valueString.length - dotIndex - 1 : 0;
    }

    public static getIntegerPart(value: number): number {
        return value >= 0 ? Math.floor(value) : Math.ceil(value);
    }

    public static getIntegerPartSize(value: number, radix: number = MathUtils.decimalRadix): number {
        return MathUtils.getIntegerPart(Math.abs(value)).toString(radix).length;
    }

    public static mod(dividend: number, divisor: number): number {
        return ((dividend % divisor) + divisor) % divisor;
    }

    public static numberToHexString(value: number, minIntegerPartSize?: number): string {
        const radix = MathUtils.hexadecimalRadix;

        const sign = Math.sign(value);
        const absoluteValueString = Math.abs(value).toString(radix);

        let insignificantZeros = '';
        if (minIntegerPartSize !== undefined) {
            const integerPartSize = MathUtils.getIntegerPartSize(value, radix);
            const insignificantZeroCount = Math.max(minIntegerPartSize - integerPartSize, 0);
            for (let i = 0; i < insignificantZeroCount; i += 1) {
                insignificantZeros += '0';
            }
        }

        return `${sign === -1 ? '-' : ''}${insignificantZeros}${absoluteValueString}`;
    }

    public static numberToString(value: number, fractionSize?: number): string {
        const valueString = String(value);

        if (fractionSize === undefined) {
            return valueString;
        } else {
            const actualFractionSize = MathUtils.getFractionSize(value);

            let resultValue = value;
            if (actualFractionSize > fractionSize) {
                resultValue = MathUtils.round(value, fractionSize);
            }

            return resultValue.toFixed(fractionSize);
        }
    }

    public static radiansToDegrees(value: number): number {
        return (MathUtils.degreesInPi / Math.PI) * value;
    }

    /**
     * Returns random number with specified `step` between `min` and (`max`). Maximum value is excluding.
     *
     * @param min Minimum value.
     * @param max Excluding maximum value.
     * @param step Step.
     * @param randomizerValue If the argument is specified, randomizer will return this argument's value instead of randomizing from 0 to (1).
     */
    public static random(
        min: number,
        max: number,
        step: number = 1,
        /* tslint:disable-next-line: insecure-random */
        randomizerValue: number = Math.random(),
    ): number {
        const range = max - min;
        const rangeRatio = range * randomizerValue;
        const rangeRatioRounded = Math.round(rangeRatio / step) * step;
        const result = min + rangeRatioRounded;

        return MathUtils.clamp(result, min, max - step);
    }

    public static round(value: number, fractionSize: number = 0): number {
        if (fractionSize === 0) {
            return Math.round(value);
        } else {
            const precisionInverted = Math.pow(MathUtils.decimalRadix, fractionSize);

            return Math.round(value * precisionInverted) / precisionInverted;
        }
    }

    public static sum(array: ReadonlyArray<number>): number {
        return array.reduce((accumulator, element) => accumulator + element, 0);
    }

    public static average(array: ReadonlyArray<number>): number {
        if (array.length === 0) {
            return NaN;
        }

        return array.reduce((accumulator, element) => accumulator + element) / array.length;
    }

    public static ratioToPercentage(value: number, n100 = 100, roundPrecision = 1): number {
        return MathUtils.round(value * n100, roundPrecision);
    }

    private static readonly hexadecimalRadix = 16;
}
