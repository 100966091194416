import { DateTime } from '../../../Std/DateTime';
import { IApiModelSerializer } from '../IApiModelSerializer';

export class DateTimeSerializer implements IApiModelSerializer<DateTime, string> {
    public serialize(obj: DateTime): string {
        return obj.toDate().toISOString();
    }

    public serializeDate(date: Date): string {
        return date.toISOString();
    }
}

export const dateTimeSerializer = new DateTimeSerializer();
