import { ItineraryBalanceRow } from "./ItineraryBalanceRow";

export class ItineraryCompletedOrdersProducts {
    public deliveredProducts: ItineraryBalanceRow[];
    public returnedProducts: ItineraryBalanceRow[];

    public constructor(
        deliveredProducts: ItineraryBalanceRow[],
        returnedProducts: ItineraryBalanceRow[]) {
        this.deliveredProducts = deliveredProducts;
        this.returnedProducts = returnedProducts;
    }
}