export class OrderTransfer {
    public readonly toDriverId: number;
    public readonly toDriverName: string | undefined;

    public constructor(
        toDriverId: number,
        toDriverName: string | undefined) {
            this.toDriverId = toDriverId;
            this.toDriverName = toDriverName;
    }
}