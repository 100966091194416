import { DeliveryDraftOrdersData } from '../../../../../ApiClient/Yoso/models';
import { Car } from '../../../../../Model/Car/Car';
import { District } from '../../../../../Model/District/District';
import { DraftOrder } from '../../../../../Model/DraftOrder/DraftOrder';
import { Driver } from '../../../../../Model/Driver/Driver';
import { Product } from '../../../../../Model/Product/Product';
import { IApiModelSerializer } from '../../../IApiModelSerializer';
import { draftCarSerializer } from '../DraftCar/DraftCarSerializer';
import {
    draftDistrictSerializer
} from '../DraftDistrict/DraftDistrictSerializer';
import { draftDriverSerializer } from '../DraftDriver/DraftDriverSerializer';
import { draftOrder2Serializer } from '../DraftOrder2/DraftOrder2Serializer';
import { draftProductSerializer } from '../DraftProduct/DraftProductSerializer';

export interface IDraftOrdersData {
    cars: ReadonlyArray<Car>;
    drivers: ReadonlyArray<Driver>;
    orders: ReadonlyArray<DraftOrder>;
    products: ReadonlyArray<Product>;
    departPeriods?: string[];
    districtGroup: string;
    districts?: District[]
}

export class DraftOrdersDataSerializer implements IApiModelSerializer<IDraftOrdersData, DeliveryDraftOrdersData> {
    public serialize(obj: IDraftOrdersData): DeliveryDraftOrdersData {
        return {
            orders: obj.orders.map((order) => draftOrder2Serializer.serialize(order)),
            cars: obj.cars.map((car) => draftCarSerializer.serialize(car)),
            drivers: obj.drivers.map((driver) => draftDriverSerializer.serialize(driver)),
            products: obj.products.map((product) => draftProductSerializer.serialize(product)),
            departPeriods: obj.departPeriods,
            districtGroup: obj.districtGroup,
            districts: obj.districts ? obj.districts.map(district => draftDistrictSerializer.serialize(district)) : undefined
        };
    }
}

export const draftOrdersDataSerializer = new DraftOrdersDataSerializer();
