import moment from 'moment';
import { useHistory } from "react-router-dom";
import * as React from 'react';
import { DraftApi } from '../../Backend/Api/Draft/DraftApi';
import { Driver } from '../../Model/Driver/Driver';

import { Car } from '../../Model/Car/Car';
import { TripPlan } from '../../Model/TripPlan/TripPlan';
import { DateTime } from '../../Std/DateTime';
import { Time } from '../../Std/Time';

import { useCompany } from '../AppProviders/Providers/CompanyProvider';
import { NewPlan } from './NewPlan';
import { useTripPlanData } from './TripPlanDataProvider';
import { TripPlanDriversPicker } from './TripPlanDriversPicker'

export interface ITripPlanData1sProps {
    onSaveAndBuildPlan: (plan: TripPlan, cars: ReadonlyArray<Car>) => void;
    planId?: number | undefined;
    returnUrl?: string | undefined;
    isNewBuildPlan?: boolean;
}

const TripPlanData1sComponent = (props: ITripPlanData1sProps): JSX.Element => {
    const history = useHistory();
    const { onSaveAndBuildPlan } = props;

    const context = useTripPlanData();
    const company = useCompany();
    const {
        loadPlanData,
        loadAdditionalPlanData,
        orders,
        setEndTime,
        endTime,
        setExecutionDate,
        executionDate,
        setStartTime,
        startTime,
        loading
    } = context;

    const planId = props.planId;
    const returnUrl = props.returnUrl;

    const [alternativePlanAllowedToUse, setAlternativePlanAllowedToUse] = React.useState<boolean | undefined>(undefined);
    const [needLoading, setNeedLoading] = React.useState(true);
    const [isChooseDriversModalVisible, setIsChooseDriversModalVisible] = React.useState(false);
    const [drivers, setDrivers] = React.useState([] as Driver[]);
    const [driversLoading, setDriversLoading] = React.useState(false);

    React.useEffect(() => {
        setAlternativePlanAllowedToUse(company.company?.alternativePlanAllowedToUse)
    }, [company])

    const load = React.useCallback(async (driverCodes: string[] | undefined) => {
        if (planId !== undefined) {
            if (!loading && orders === undefined) {
                await loadAdditionalPlanData(planId, driverCodes);
            }
        } else {
            if (!loading && needLoading) {
                await loadPlanData(executionDate!.cloneWithTime(startTime!), executionDate!.cloneWithTime(endTime!), driverCodes);
                setNeedLoading(false);
            }
        }
    }, [
        planId,
        executionDate,
        startTime,
        endTime,
        loadPlanData,
        loadAdditionalPlanData,
        loading,
        needLoading,
        orders,
    ]);

    const loadDrivers = async () => {
        const draftDrivers = await DraftApi.getDrivers(executionDate!.cloneWithTime(startTime!), executionDate!.cloneWithTime(endTime!));
        setDrivers(draftDrivers);
        setDriversLoading(false);
    }

    const loadAdditionalDrivers = async () => {
        if (planId === undefined) {
            return;
        }

        const draftDrivers = await DraftApi.getAdditionalDrivers(planId);
        setDrivers(draftDrivers);
        setDriversLoading(false);
    }

    const showDrivers = async (showModal: boolean, shouldLoadDrivers: boolean) => {
        setDrivers([]);

        if (showModal) {
            setIsChooseDriversModalVisible(true);
        }

        setNeedLoading(true);

        if (planId === undefined && !shouldLoadDrivers) {
            return;
        }

        setDriversLoading(true);
        if (planId === undefined) {
            await loadDrivers();
        } else {
            await loadAdditionalDrivers();
        }        
    };

    const handleChooseDriversOk = async (codes: string[]) => {
        setIsChooseDriversModalVisible(false);
        await load(codes);
    };

    const handleChooseDriversCancel = () => {
        if (returnUrl) {
            history.replace(returnUrl);
        } else {
            setIsChooseDriversModalVisible(false);
        }
    };

    React.useEffect(() => {
        if (!executionDate || !startTime || !endTime) {
            return undefined;
        }

        if (planId !== undefined && !context.tripPlan) {
            return undefined;
        }

        if (alternativePlanAllowedToUse === undefined) {
            return undefined;
        }

        showDrivers(!isChooseDriversModalVisible, !alternativePlanAllowedToUse);
    }, [executionDate, startTime, endTime, alternativePlanAllowedToUse]);

    React.useEffect(() => {
        if (!context.tripPlan) {
            return undefined;
        }
        setExecutionDate(context.tripPlan.dateTimePeriod.start);
    }, [context.tripPlan]);

    const handleExecutionDateChange: (date: moment.Moment | null) => Promise<undefined> = React.useCallback(
        async (date: moment.Moment | null): Promise<undefined> => {
            if (planId !== undefined) {
                return;
            }
            const executionDateNew = date === null ? undefined : DateTime.fromDate(date.toDate());
            setExecutionDate(executionDateNew);

            return;
        },
        [],
    );

    const handleStartTimeChange: (time: moment.Moment | null) => Promise<undefined> = React.useCallback(
        async (time: moment.Moment | null): Promise<undefined> => {
            const startTimeNew = time === null ? undefined : Time.fromDate(time.toDate());
            setStartTime(startTimeNew);           

            return;
        },
        [],
    );

    const handleEndTimeChange: (time: moment.Moment | null) => Promise<undefined> = React.useCallback(
        async (time: moment.Moment | null): Promise<undefined> => {
            const endTimeNew = time === null ? undefined : Time.fromDate(time.toDate());
            setEndTime(endTimeNew);

            return;
        },
        [],
    );

    return (
        <>
            <NewPlan
                handleExecutionDateChange={handleExecutionDateChange}
                handleStartTimeChange={handleStartTimeChange}
                handleEndTimeChange={handleEndTimeChange}
                onSaveAndBuildPlan={onSaveAndBuildPlan}
                isNewBuildPlan={props.isNewBuildPlan}
            />
            <TripPlanDriversPicker
                loading={driversLoading}
                drivers={drivers}
                isModalVisible={isChooseDriversModalVisible}
                handleOk={handleChooseDriversOk}
                handleCancel={handleChooseDriversCancel}
                handleExecutionDateChange={handleExecutionDateChange}
                handleStartTimeChange={handleStartTimeChange}
                handleEndTimeChange={handleEndTimeChange} />
        </>
    );
};

export const TripPlanData1s = (props: ITripPlanData1sProps): JSX.Element => <TripPlanData1sComponent {...props} />;
