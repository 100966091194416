import { TargetType } from "../../ApiClient/Yoso/models";
import { Geocode } from "../Geocode/Geocode";

export class Store {
    public readonly id: number;
    public readonly coordinates: Geocode;
    public address: string;
    public isMain: boolean;

    public constructor(
        id: number,
        coordinates: Geocode,
        address: string,
        isMain?: boolean
    ) {
        this.id = id;
        this.coordinates = coordinates;
        this.address = address;
        this.isMain = isMain ? isMain : false;
    }

    public getKey(): string {
        return `${this.id.toString()}_${TargetType.STORE.toString()}`;
    }
}