import { runInAction, makeAutoObservable } from "mobx";
import { Moment } from "moment";
import { StatsApi } from "../../Backend/Api/Stats/StatsApi";
import { StatsApiDriver, StatsApiDriverLogs, StatsApiDriverStats } from "../../ApiClient/YosoStats/models";
import {
    RatingStatistics
} from '../../Model/RatingStatistics/RatingStatistics';

export class ChartsStore {
    public loading: boolean;
    public startDate: Moment;
    public endDate: Moment;
    public selectedDriver: {id: number, name: string} | undefined;
    public shipmentStats: RatingStatistics[];
    public arrivalStats: StatsApiDriver[];
    public driverStats: StatsApiDriverStats[];
    public driverLogs: StatsApiDriverLogs[];
    public page: ChartPage | undefined;

    public constructor(startDate: Moment, endDate: Moment) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.shipmentStats = [];
        this.arrivalStats = [];
        this.driverStats = [];
        this.driverLogs = [];
        this.loading = false;
        makeAutoObservable(this);
    }    

    public loadChartPage = (page: ChartPage | undefined): void => {
        this.page = page;
        switch(page){
            case ChartPage.Shipment:
                this.getShipmentStats();
                break;
            case ChartPage.Arrivals:
                this.getArrivalStats();
                break;
            case ChartPage.Driver:
                if (this.selectedDriver) {
                    this.getDriverStats(this.selectedDriver.id);
                }
                break;
            default:
                return;
        }
    }

    public loadDriverPage = (id: number, name: string): void => {
        this.selectedDriver = {id: id, name: name};
        this.loadChartPage(ChartPage.Driver);
    }

    public closeDriverPage = (): void => {
        this.selectedDriver = undefined;
    }

    public setPeriod = (startDate: Moment, endDate: Moment): void => {
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadChartPage(this.page);        
    }

    public getDriverChartUrl = (driverId: number) => `/charts/driver/${driverId}`;

    private getShipmentStats() {
        this.loading = true;
        StatsApi.getDriversShipmentStats(this.startDate.toDate(), this.endDate.toDate())
            .then(response => runInAction(() => {this.shipmentStats = response}))
            .catch(error => alert(String(error)))
            .finally(() => runInAction(() => this.loading = false));
    }

    private async getArrivalStats() {
        this.loading = true;
        StatsApi.getDriversArrivalStats(this.startDate.toDate(), this.endDate.toDate())
            .then(response => runInAction(() => {this.arrivalStats = response.stats}))
            .catch(error => alert(String(error)))
            .finally(() => runInAction(() => this.loading = false));
    }

    private async getDriverStats(driverId: number) {
        this.loading = true;
        StatsApi.getDriverStats(driverId, this.startDate.toDate(), this.endDate.toDate())
            .then(response => runInAction(() => {
                this.driverStats = response.stats;
                this.driverLogs = response.logs;
                this.selectedDriver = {id: response.driverId, name: response.driverName};
            }))
            .catch(error => alert(String(error)))
            .finally(() => runInAction(() => this.loading = false));
    }
}

export enum ChartPage {
    Shipment,
    Arrivals,
    Driver
}