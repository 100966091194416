export enum OrderStatus {
    Cancelled,
    New,
    NewAssigned,
    NewAdded,
    Assigned,
    AssignedAdded,
    Scheduled,
    ScheduledAdded,
    Delivering,
    Done,
    Failed,
    Moved,
    Rescheduled
}
