import { DeliveryApiIntervalAdjustment, DeliveryApiInterval, State } from '@/ApiClient/Yoso/models';
import { ArrayUtils } from '@/Std/ArrayUtils';
import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';

interface Day {
    date: Moment;
    intervals: DeliveryApiInterval[];
    activeIntervals: DeliveryApiInterval[];
}

interface Week {
    date: Moment;
    days: Day[];
}

interface Month {
    date: Moment;
    weeks: Week[];
}

export class DeliveryIntervalStore {
    private _intervals: DeliveryApiInterval[] = [];
    private _adjustments: DeliveryApiIntervalAdjustment[] = [];
    private _beginTime: Moment;
    private _endTime: Moment;
    private _selectedDay: Day | undefined;
    private _selectedDayIntervals: DeliveryApiInterval[] = [];

    public constructor(intervals: DeliveryApiInterval[], adjustments: DeliveryApiIntervalAdjustment[]) {
        this._intervals = ArrayUtils.sort(intervals, (x) => x.beginTime);
        this._adjustments = ArrayUtils.sort(adjustments, (x) => x.beginTime);
        this._beginTime = (this._intervals.length > 0
            ? moment(this._intervals.reduce((prev, curr) => (prev.beginTime < curr.beginTime ? prev : curr)).beginTime)
            : moment()
        ).startOf('day');
        this._endTime = (this._intervals.length > 0
            ? moment(this._intervals.reduce((prev, curr) => (prev.beginTime > curr.beginTime ? prev : curr)).beginTime)
            : moment()
        ).endOf('day');
        makeAutoObservable(this);
    }

    public get intervals(): DeliveryApiInterval[] {
        return this._intervals;
    }

    public get adjustments(): DeliveryApiIntervalAdjustment[] {
        return this._adjustments;
    }

    public get adjustmentsByDate(): Map<string, DeliveryApiIntervalAdjustment[]> {
        console.debug('adjustmentsByDate recomputed');

        return ArrayUtils.groupBy(this._adjustments, (x) => moment(x.beginTime).format('YYYYMMDD'));
    }

    public get errors(): string[] {
        if (!this._selectedDay) {
            return [];
        }

        const err = [];

        for (const interval of this._selectedDayIntervals) {
            const begin = moment(interval.beginTime).set({ second: 0, millisecond: 0 });
            const end = moment(interval.endTime).set({ second: 0, millisecond: 0 });
            if (begin > end) {
                err.push('Есть невалидный интервал');
                break;
            }
        }

        return err;
    }

    public get calendar(): Month[] {
        if (!this._intervals.length) {
            return [];
        }
        const monthMap = new Map<string, Month>();
        const weekMap = new Map<string, Week>();
        const dayMap = new Map<string, Day>();

        const beginTime = this._beginTime.clone().startOf('month');
        const endTime = this._endTime.clone().endOf('month');

        const result: Month[] = [];

        let date = beginTime;
        for (date; date.isBefore(endTime); date.add(1, 'day')) {
            const monthDate = date.clone().startOf('month');
            const weekDate = date.clone().startOf('isoWeek');
            const dayDate = date.clone();

            const monthKey = monthDate.toISOString();
            const weekKey = monthKey + weekDate.toISOString();
            const dayKey = date.toISOString();

            let month: Month;
            if (!monthMap.has(monthKey)) {
                month = { date: monthDate, weeks: [] };
                monthMap.set(monthKey, month);
                result.push(month);
            }
            month = monthMap.get(monthKey)!;

            let week: Week;
            if (!weekMap.has(weekKey)) {
                week = { date: weekDate, days: [] };
                weekMap.set(weekKey, week);
                month.weeks.push(week);
            }
            week = weekMap.get(weekKey)!;

            const day: Day = { date: dayDate, intervals: [], activeIntervals: [] };
            dayMap.set(dayKey, day);
            week.days.push(day);
        }

        this._intervals.forEach((x) => {
            date = moment(x.beginTime).startOf('day');
            const dayKey = date.toISOString();

            const day: Day = dayMap.get(dayKey)!;
            if (!day) {
                console.log(dayKey);

                return;
            }
            day.intervals.push(x);
            if (x.state === State.Active) {
                day.activeIntervals.push(x);
            }
        });

        return result;
    }

    public dateIsEditable = (date: Moment): boolean => date >= this._beginTime && date <= this._endTime;

    public get selectedDay(): Day | undefined {
        return this._selectedDay;
    }

    public get selectedDayIntervals(): DeliveryApiInterval[] {
        return this._selectedDayIntervals;
    }

    public setSelectedDay = (day: Day | undefined): void => {
        this._selectedDay = day;
        this._selectedDayIntervals = day
            ? day.intervals.map((x) => ({
                  id: x.id,
                  beginTime: x.beginTime,
                  endTime: x.endTime,
                  state: x.state,
              }))
            : [];
    };

    public switchSelectedDayIntervalAvailability = (interval: DeliveryApiInterval): void => {
        const foundInterval = this._selectedDayIntervals.find((x) => x === interval);
        if (!foundInterval) {
            return;
        }

        if (foundInterval.state === State.Active) {
            foundInterval.state = State.Disabled;
        } else if (foundInterval.state === State.Disabled) {
            foundInterval.state = State.Active;
        }

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public disableSelectedDayAllIntervals = (): void => {
        this._selectedDayIntervals.forEach((interval) => {
            if (interval.state === State.Active) {
                interval.state = State.Disabled;
            }
        });

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public enableSelectedDayAllIntervals = (): void => {
        this._selectedDayIntervals.forEach((interval) => {
            if (interval.state === State.Disabled) {
                interval.state = State.Active;
            }
        });

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public removeSelectedDayInterval = (interval: DeliveryApiInterval): void => {
        const index = this._selectedDayIntervals.findIndex((x) => x === interval);
        if (index > -1) {
            this._selectedDayIntervals.splice(index, 1);
        }

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public addSelectedDayInterval = (): void => {
        this._selectedDayIntervals.push({
            id: 0,
            beginTime: moment().set({ hour: 8, minute: 0 }).toISOString(),
            endTime: moment().set({ hour: 8, minute: 0 }).toISOString(),
            state: State.Active,
        });

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public onSelectedDayIntervalBeginChange = (interval: DeliveryApiInterval, v: Moment): void => {
        const foundInterval = this._selectedDayIntervals.find((x) => x === interval);
        if (foundInterval) {
            foundInterval.beginTime = v.toISOString();
        }

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public onSelectedDayIntervalEndChange = (interval: DeliveryApiInterval, v: Moment): void => {
        const foundInterval = this._selectedDayIntervals.find((x) => x === interval);
        if (foundInterval) {
            foundInterval.endTime = v.toISOString();
        }

        this._selectedDayIntervals = [...this._selectedDayIntervals];
    };

    public selectedDayDisabled = (): boolean => {
        for (const interval of this._selectedDayIntervals) {
            if (interval.state === State.Active) {
                return false;
            }
        }

        return true;
    };
}
