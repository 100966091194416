import {observer} from 'mobx-react';
import React from 'react';
import {Select} from 'antd';
import { Geocode } from '../../Model/Geocode/Geocode';
import {DeliveryApi} from '../../Backend/Api/Delivery/DeliveryApi';
import {Address} from '../../Model/Address/Address';
import styles from './SearchAddress.module.css';

export interface SearchAddressSuggestions {
    id?: string;
    address: string;
    coords: Geocode;
}

interface SearchAddressProps {
    value?: SearchAddressSuggestions;
    addresses: SearchAddressSuggestions[];
    onSelect: (id: string | undefined, address: string, coords: Geocode) => void;
}

const SearchAddress = observer((props: SearchAddressProps) => {
    const interval = 500;

    const [data, setData] = React.useState<Address[]>([]);
    const [value, setValue] = React.useState<string>(props.value?.address ?? '');

    let timeout: ReturnType<typeof setTimeout> | null;
    let currentValue: string;

    const fetch = (str: string) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        currentValue = str;

        const getAddresses = () => {
            DeliveryApi.suggestAddress(str)
                .then(response => {
                    if (currentValue === str) {
                        setData(response);
                    }
                });
        };

        timeout = setTimeout(getAddresses, interval);
    };

    const handleSearch = (newValue: string) => {
        if (newValue) {
            fetch(newValue);
        } else {
            setData([]);
        }
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleSelect = (e: string) => {
        const predefinedAddress = props.addresses.find(a => a.address === e);
        const newAddress = data.find(i => i.address === e);

        if (predefinedAddress) {
            props.onSelect(predefinedAddress.id, predefinedAddress.address, predefinedAddress.coords);
        } else if (newAddress) {
            props.onSelect(undefined, newAddress.address, newAddress.coords);
        }
    }

    return (
        <>
            <Select
                showSearch
                value={value}
                placeholder='Ввод адреса'
                className={styles.select}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                onSelect={handleSelect}
                notFoundContent={null}
                options={[
                    {
                        label: 'Поиск в интернете',
                        options: data.map(a => ({value: a.address}))
                    },
                    {
                        label: 'Использованные ранее',
                        options: props.addresses.map(a => ({value: a.address}))
                    }
                ]}
            />
            <div className={styles.result}>Выбрано: {value ?? '...'}</div>
        </>
    );
});

export default SearchAddress;