import * as React from 'react';
import moment, {Moment} from 'moment';
import { Skeleton, TimePicker, Button, Input } from 'antd';
import { DateTime } from '../../Std/DateTime';
import styles from './AverageOrderCountPerHourEdit.module.css';

export interface PeriodWithValue {
    endTime: DateTime,
    averageCount: number
}

export interface IAverageOrderCountPerHourEditProps {
    loading: boolean;
    values: PeriodWithValue[];
    setValues: (values: PeriodWithValue[]) => void;
}

const defaultHour = 12;
const defaultValue = 6;
const maxHour = 23;
const lastMinute = 59;

export const AverageOrderCountPerHourEdit = (props: IAverageOrderCountPerHourEditProps): JSX.Element => {
    const addAverageOrderCount = React.useCallback((endTimeHour: number, endTimeMinute: number, value: number) => {
        const now = new Date();
        now.setHours(endTimeHour);
        now.setMinutes(endTimeMinute);
        props.setValues([...props.values, {endTime: DateTime.fromDate(now), averageCount: value}]);
    }, [props.values, props.setValues]);

    const deleteAverageOrderCount = React.useCallback((value: PeriodWithValue) => {
        const index = props.values.indexOf(value);
        if (index < 0) {
            return;
        }
        props.values.splice(index, 1);
        props.setValues([...props.values]);
    }, [props.values, props.setValues]);

    const changeAverageOrderCountHour = React.useCallback((value: PeriodWithValue, hour: number, minute: number) => {
        const now = new Date();
        now.setHours(hour);
        now.setMinutes(minute);
        value.endTime = DateTime.fromDate(now);
        props.setValues([...props.values]);      
    }, [props.values, props.setValues]);

    const changeAverageOrderCountValue = React.useCallback((value: PeriodWithValue, count: number) => {
        value.averageCount = count;
        props.setValues([...props.values]);        
    }, [props.values, props.setValues]);

    const addValue = React.useCallback(() => {
        if (!props.values.length) {
            addAverageOrderCount(defaultHour, 0, defaultValue);
        } else {
            const hour = props.values[props.values.length - 1].endTime.hours + 1;
            if (hour > maxHour) {
                return;
            }
            const minute = hour === maxHour ? lastMinute : 0;
            addAverageOrderCount(hour, minute, defaultValue);
        }
    }, [props.values, addAverageOrderCount]);


    const deleteValue = React.useCallback((value: PeriodWithValue) => {
        deleteAverageOrderCount(value);
    }, [deleteAverageOrderCount]);

    const onHourChange = React.useCallback((value: PeriodWithValue, date: Moment | null) => {
        if (!date) {
            return;
        }

        changeAverageOrderCountHour(value, date.hours(), date.minutes());
    }, [changeAverageOrderCountHour]);

    const onCountChange = React.useCallback((value: PeriodWithValue, event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(event.target.valueAsNumber)) {
            return;
        }
        changeAverageOrderCountValue(value, event.target.valueAsNumber);
    }, [changeAverageOrderCountValue]);

    return (
    <>
        {props.loading ? (
            <Skeleton active round title={false} />
        ) : (
            <>
                {props.values.map(x => (
                    <div key={x.endTime.toDate().getTime()} className={styles.row}>
                        до&nbsp;
                        <TimePicker
                            onChange={(v) => onHourChange(x, v)}
                            format="HH:mm"
                            value={moment(x.endTime.toDate())}
                            clearIcon={false}
                            className={styles.time}
                        />&nbsp;часов&nbsp;&mdash;&nbsp;
                        <Input
                            onChange={(v) => onCountChange(x, v)}
                            type="number"
                            value={x.averageCount}
                            required={true}
                            className={styles.value}
                        />&nbsp;
                        <Button type='primary' onClick={() => deleteValue(x)}>&ndash;</Button>
                    </div>
                ))}
                <Button type='default' onClick={addValue} className={styles.addButton}>Добавить</Button>
            </>
        )}
    </>
)}