import * as React from 'react';
import { Duration } from '../../Std/Duration';

export interface IColumnCellBarProps {
    value: number | undefined;
    maxValue: number;
    valueFormatter?: (value: any) => string;
}

export const ColumnCellBar = (props: IColumnCellBarProps): JSX.Element => {
    const p100 = 100;

    const getCssWidth = (dividend: number, divider: number): string => {
        if (divider === 0) {
            return "0";
        }

        return `${dividend / divider * p100}%`
    }

    const value = props.valueFormatter && props.value !== undefined ? props.valueFormatter(props.value) : `${props.value}`;

    return (
        <>
            {props.value !== undefined && (
                <div className="column-cell-bar" style={{
                    width: getCssWidth(props.value, props.maxValue)
                }} title={value}>
                    {value}
                </div>
            )}
        </>
    )
}