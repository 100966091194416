import { DeliveryPlanResponse } from '../../../../../ApiClient/Yoso/models';
import { Product } from '../../../../../Model/Product/Product';
import { TripPlan } from '../../../../../Model/TripPlan/TripPlan';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { planDeserializer } from '../../Model/Plan/PlanDeserializer';
import { productDeserializer } from '../../Model/Product/ProductDeserializer';

export interface IGetPlanResponse {
    plan: TripPlan;
    products: ReadonlyArray<Product>;
}

export class GetPlanResponseDeserializer implements IResponseDeserializer<DeliveryPlanResponse, IGetPlanResponse> {
    public deserialize(response: DeliveryPlanResponse): IGetPlanResponse {
        return {
            plan: planDeserializer.deserialize(
                response.plan!,
                response.stores!,
                response.cars!,
                response.drivers!,
                response.orders!,
                response.products!,
                response.districts!,
                response.lunches!,
                response.loadingStores!,
                response.alternativePlanId,
            ),
            products: response.products!.map((product) => productDeserializer.deserialize(product)),
        };
    }
}

export const getPlanResponseDeserializer = new GetPlanResponseDeserializer();
