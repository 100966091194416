import { ItineraryBalanceRow } from "./ItineraryBalanceRow";

export class ItineraryBalance {
    public paidAmount: number;
    public cashPaidAmount: number;
    public qrPaidAmount: number;
    public terminalPaidAmount: number;
    public unknownPaidAmount: number;
    public rows: ItineraryBalanceRow[];

    public constructor(paidAmount: number, 
        cashPaidAmount: number,
        qrPaidAmount: number,
        terminalPaidAmount: number,
        unknownPaidAmount: number,
        rows: ItineraryBalanceRow[]) {
        this.paidAmount = paidAmount;
        this.cashPaidAmount = cashPaidAmount;
        this.qrPaidAmount = qrPaidAmount;
        this.terminalPaidAmount = terminalPaidAmount;
        this.unknownPaidAmount = unknownPaidAmount;
        this.rows = rows;
    }
}