import { makeAutoObservable } from "mobx";
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { DistrictInterval } from "./DistrictInterval";

export class DistrictStrictInterval {
  public id: number;
  public color: string;
  public coords: IGeocode[];
  public name: string;
  public intervals: DistrictInterval[];

  public constructor(id: number, coords: IGeocode[], name: string, color: string, intervals: DistrictInterval[]) {
    makeAutoObservable(this);
    this.id = id;
    this.coords = coords;
    this.intervals = intervals;
    this.color = color;
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string) {
    this.name = value;
  }

  public isNew(): boolean {
    return this.id <= 0;
  }

  public getCentroid(): IGeocode | undefined {
    if (!this.coords.length) {
      return undefined;
    }

    const latSum = this.coords.map((x) => x.latitude).reduce((a, b) => a + b, 0);
    const lonSum = this.coords.map((x) => x.longitude).reduce((a, b) => a + b, 0);

    return {
      latitude: latSum / this.coords.length,
      longitude: lonSum / this.coords.length
    }
  }
}