import { ApiItinerary } from "../../../../../ApiClient/Yoso/models";
import { Itinerary } from "../../../../../Model/Itinerary/Itinerary";
import { dateTimeDeserializer } from "../../../Mapper/DateTimeDeserializer";
import { balanceDeserializer } from "./BalanceDeserializer";
import { completedOrdersProductsDeserializer } from "./CompletedOrdersProductsDeserializer";
import { orderDeserializer } from "./OrderDeserializer";
import { productMovementDeserializer } from "./ProductMovementDeserializer";

export class GetItineraryResponseDeserializer {
    public deserialize(response: ApiItinerary): Itinerary {
        return new Itinerary(
            response.driverName,
            dateTimeDeserializer.deserialize(response.date),
            response.productMovements.map(
                productMovement => productMovementDeserializer.deserialize(productMovement, response.products)
            ),
            response.orders.map(order => orderDeserializer.deserialize(order, response.products)),
            balanceDeserializer.deserialize(response.balance, response.products),
            completedOrdersProductsDeserializer.deserialize(response.completedOrdersProducts)
        )
    }
}

export const getItineraryResponseDeserializer = new GetItineraryResponseDeserializer();