import * as React from 'react';
import { TripAnalysisSummary } from '../../Model/TripAnalysisSummary/TripAnalysisSummary';

export interface IAnalysisOrdersDistributionProps {
    maxOrdersCount: number;
    tripAnalysis: TripAnalysisSummary
}

export const AnalysisOrdersDistribution = (props: IAnalysisOrdersDistributionProps): JSX.Element => {
    const ta = props.tripAnalysis;
    const p100 = 100;
    const ordersCount = (ta.arrivalLTMinus30 !== undefined ? ta.arrivalLTMinus30 : 0)
        + (ta.arrivalBtwMinus30Plus30 !== undefined ? ta.arrivalBtwMinus30Plus30 : 0)
        + (ta.arrivalBtwPlus31Plus60 !== undefined ? ta.arrivalBtwPlus31Plus60 : 0)
        + (ta.arrivalGTPlus60 !== undefined ? ta.arrivalGTPlus60 : 0);

    const getCssWidth = (dividend: number, divider: number): string => {
        if (divider === 0) {
            return "0";
        }

        return `${dividend / divider * p100}%`
    }

    return (
        <>
            {ta.arrivalLTMinus30 !== undefined && ta.arrivalBtwMinus30Plus30 !== undefined && props.maxOrdersCount > 0
                && ta.arrivalBtwPlus31Plus60 !== undefined && ta.arrivalGTPlus60 !== undefined && ordersCount > 0 && (
                <div className="orders-distribution" style={{
                    display: "flex",
                    width: getCssWidth(ordersCount, props.maxOrdersCount)
                }}>
                    <div className="arrival arrival-lt-minus30" style={{
                        width: getCssWidth(ta.arrivalLTMinus30, ordersCount)
                    }}>{ta.arrivalLTMinus30}</div>
                    <div className="arrival arrival-btw-minus30-plus30" style={{
                        width: getCssWidth(ta.arrivalBtwMinus30Plus30, ordersCount)
                    }}>{ta.arrivalBtwMinus30Plus30}</div>
                    <div className="arrival arrival-btw-plus31-plus60" style={{
                        width: getCssWidth(ta.arrivalBtwPlus31Plus60, ordersCount)
                    }}>{ta.arrivalBtwPlus31Plus60}</div>
                    <div className="arrival arrival-gt-plus60" style={{
                        width: getCssWidth(ta.arrivalGTPlus60, ordersCount)
                    }}>{ta.arrivalGTPlus60}</div>
                </div>
            )}
        </>
    )
}