import { DateTime } from "../../Std/DateTime";
import {
    ItineraryCompletedOrdersProducts
} from "./ItineraryCompletedOrdersProducts";
import { ItineraryProductMovement } from "./ItineraryProductMovement";
import { ItineraryOrder } from "./ItineraryOrder";
import { ItineraryBalance } from "./ItineraryBalance";

export class Itinerary {
    public driverName: string;
    public date: DateTime;
    public productMovements: ItineraryProductMovement[];
    public orders: ItineraryOrder[];
    public balance: ItineraryBalance;
    public completedOrdersProducts: ItineraryCompletedOrdersProducts;

    public constructor(
        driverName: string,
        date: DateTime,
        productMovements: ItineraryProductMovement[],
        orders: ItineraryOrder[],
        balance: ItineraryBalance,
        completedOrdersProducts: ItineraryCompletedOrdersProducts
    ) {
        this.driverName = driverName;
        this.date = date;
        this.productMovements = productMovements;
        this.orders = orders;
        this.balance = balance;
        this.completedOrdersProducts = completedOrdersProducts;
    }
}