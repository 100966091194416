import * as React from 'react';
import { LunchRoutePoint } from '../../../Map/Route/RoutePoint/LunchRoutePoint';
import { FormattedTime } from '../RouteInfoFormattedValues/FormattedTime';
import { FormattedTimePeriod } from '../RouteInfoFormattedValues/FormattedTimePeriod';
import '../RouteInfo.css';

export interface ILunchRoutePointContentProps {
    point: LunchRoutePoint;
}

export const LunchRoutePointContent = (props: ILunchRoutePointContentProps): JSX.Element => {
    const { point } = props;

    return (
        <>
            <div className="route-info__address">{point.address} (Обед)</div>
            <div>
                <FormattedTime date={point.lunch.expectedArrivalDateTime} />
                <FormattedTimePeriod dateTimePeriod={point.lunch.desiredDateTimePeriod.getTimePeriod()} />
            </div>
        </>
    );
};
