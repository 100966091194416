import { Button, Input, Pagination, Select, Tag, Skeleton, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import * as React from 'react';
import { __RouterContext } from 'react-router';
import { Link } from 'react-router-dom';

import { ArrayUtils } from '../../Std/ArrayUtils';
import { DictionaryUtils } from '../../Std/DictionaryUtils';
import { formatNumber } from '../../Std/FormatNumber';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog/DeleteConfirmationDialog';

import './EntityList.css';

export type EntityListActionResult = undefined | Promise<undefined>;

export type EntityListSafeAction = () => EntityListActionResult;
export type EntityListAction = string | EntityListSafeAction;

export type EntityListItemSafeAction = (id: number) => EntityListSafeAction;
export type EntityListItemAction = EntityListItemSafeAction | ((id: number) => EntityListAction);

export const PreloadedRowsNumber = 7;
const spinIcon = <LoadingOutlined spin />;

export interface IListActionConfig<TAction> {
    action: TAction;
    icon?: string;
    title?: string;
    warningText?: string;
}

export enum EntityListColumnAlign {
    Center = 'center',
    Left = 'left',
    Right = 'right',
}

export interface IEntityListData<TEntity> {
    entities: ReadonlyArray<TEntity>;
    total: number;
}

export interface IEntityListColumnConfig<TEntity> {
    align?: EntityListColumnAlign;
    key: string;
    noWrap?: boolean;
    render: (entity: TEntity) => React.ReactNode;
    stretch?: boolean;
    // eslint-disable-next-line @typescript-eslint/tslint/config
    title?: string | React.ReactNode;
}

export interface IEntityListProps<TEntity, TEntityExtension = undefined> {
    actions?: {
        item?: {
            click?: EntityListItemAction;
            delete?: IListActionConfig<EntityListItemSafeAction>;
        };
        list?: ReadonlyArray<IListActionConfig<EntityListAction>>;
    };
    className?: string;
    columns: ReadonlyArray<IEntityListColumnConfig<TEntity>>;
    filter?: {
        additional?: { onClose: () => void; title: string }[];
        search?: {
            filter: (entity: TEntity, searchStringPart: string) => boolean;
            onSearch: (value: string) => void;
            searchString: string;
        };
        select?: {
            filter: (entity: TEntity, selectKey: string, selectValue: string) => boolean;
            items: Record<
                string,
                ReadonlyArray<{
                    title: string;
                    value: string;
                }>
            >;
            onChange: (values: Record<string, string>) => void;
            values: Record<string, string>;
        };
    };
    // оставил loading и data для обратной совместимости
    loading?: boolean;
    data?: ReadonlyArray<TEntity>;
    getEntities: (page: number, size: number, searchString?: string, filter?: Record<string, string>) => Promise<IEntityListData<TEntity>>;
    getEntityExtension?: (id: number | string) => Promise<TEntityExtension>;
    id: (entity: TEntity) => number | string;
    isEntityWithWarning?: (entity: TEntity) => boolean;
    pageSize?: number;
    renderRow?: (entity: TEntity) => JSX.Element;
    renderRowExtendedContent?: (entity: TEntityExtension) => React.ReactNode;
    rowColorMarker?: (entity: TEntity, index: number) => string;
    rowClass?: (entity: TEntity, index: number) => string;

    selectedItems?: TEntity | ReadonlyArray<TEntity>;
    small?: boolean;
    isRowReverse?: boolean;
    refresh?: number
}

const defaultPageSize = 15;

const isLinkAction = (action: EntityListAction): action is string => typeof action === 'string';
const isButtonAction = (action: EntityListAction): action is () => EntityListActionResult =>
    typeof action === 'function';

// eslint-disable-next-line complexity
export const EntityListBase = <TEntity extends unknown, TEntityExtension extends unknown>(props: IEntityListProps<TEntity, TEntityExtension>): JSX.Element => {
    const { isEntityWithWarning } = props;

    const pageSize = props.pageSize === undefined ? defaultPageSize : props.pageSize;
    const routerContext = React.useContext(__RouterContext);

    const [loading, setLoading] = React.useState(false);
    const [extensionLoading, setExtensionLoading] = React.useState(false);
    const [entityExtension, setEntityExtension] = React.useState(undefined as TEntityExtension | undefined);
    const [deletingEntityId, setDeletingEntityId] = React.useState(undefined as number | undefined);

    const renderCellContent = React.useCallback((columnConfig, entity) => {
        const value = columnConfig.render(entity);

        return value === undefined ? '\u2013' : typeof value === 'number' ? formatNumber(value) : value;
    }, []);

    const renderDefaultRow = React.useCallback(
        (entity: TEntity) =>
            props.columns.map((column) => (
                <td
                    key={column.key}
                    className={classNames(
                        'entity-list__cell',
                        `entity-list__cell_align-${column.align ?? 'left'}`,
                        'entity-list__data-cell',
                        column.noWrap === true && 'entity-list__cell_no-wrap',
                        column.stretch === true && 'entity-list__cell_stretch',
                        `column-${column.key}`
                    )}
                >
                    {renderCellContent(column, entity)}
                </td>
            )),
        [props.columns, renderCellContent],
    );

    const renderSingleRow = props.renderRow === undefined ? renderDefaultRow : props.renderRow;

    const [isTextSelected, setIsTextSelected] = React.useState(false as boolean);

    const handleTextSelection = () => {
        const isSelectionDone = !window.getSelection()!.isCollapsed;

        if (isSelectionDone) {
            setIsTextSelected(true);
        }
    };

    const doAction = React.useCallback(
        (action: EntityListAction): undefined | Promise<undefined> => {
            if (action === undefined) {
                return undefined;
            }

            if (isLinkAction(action)) {
                routerContext?.history.push(action);

                return undefined;
            } else if (isButtonAction(action)) {
                return action();
            }
        },
        [routerContext?.history],
    );

    const handleItemDeleteButtonClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setDeletingEntityId(Number(e.currentTarget.dataset.id));
    }, []);

    const handleDeleteConfirmationDialogOk = React.useCallback((): void => {
        setDeletingEntityId(undefined);
    }, []);

    const handleDeleteConfirmationDialogCancel = React.useCallback(() => {
        setDeletingEntityId(undefined);
    }, []);

    const getEntities = () => {
        setLoading(true);
        props.getEntities(
            currentPageNumber,
            pageSize,
            currentFilterSearchSearchString,
            currentFilterSelectValues)
        .then((data: IEntityListData<TEntity>) => {            
            setEntities(data.entities);
            setTotal(data.total);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const itemDeleteAction = props.actions?.item?.delete?.action;

    const getItemDeletionPromise = React.useCallback((): undefined | Promise<undefined> => {
        if (deletingEntityId === undefined) {
            return undefined;
        }
        const promise = itemDeleteAction === undefined ? undefined : itemDeleteAction(deletingEntityId)();
        if (promise === undefined) {
            return undefined;
        }

        if (props.data) {
            // обновление данных происходит по старому
            return promise;
        } else {
            // по-новому мы сами обновляем список, т.к. у нас для этого есть метод
            return new Promise<undefined>((resolve) => {
                promise.then(() => {
                    getEntities();
                    resolve(undefined);
                });
            })
        }
    }, [deletingEntityId, itemDeleteAction]);

    const renderListActionButton = React.useCallback(
        (action: IListActionConfig<EntityListAction> | undefined, index) => {
            if (action === undefined) {
                return undefined;
            }
            const icon = action.icon ?? <PlusOutlined />;
            const title = action.title ?? 'Добавить';
            if (isLinkAction(action.action)) {
                return (
                    <Link to={action.action} key={index}>
                        <Button type="primary" icon={icon}>
                            {title}
                        </Button>
                    </Link>
                );
            }
            if (isButtonAction(action.action)) {
                return (
                    <Button key={index} type="primary" icon={icon} onClick={action.action}>
                        {title}
                    </Button>
                );
            }

            return undefined;
        },
        [],
    );

    const [filterSelectValues, setFilterSelectValues] = React.useState(props.filter?.select?.values);

    const currentFilterSelect = props.filter?.select;
    const currentFilterSelectValues = currentFilterSelect?.values;
    React.useEffect(() => {
        setFilterSelectValues(currentFilterSelectValues);
    }, [currentFilterSelectValues]);

    const currentFilterSelectItems = props.filter?.select?.items;
    const currentFilterSelectOnChange = props.filter?.select?.onChange;
    const handleFilterSelectChange = React.useCallback(
        (filterItemKey: string, value: string): void => {
            if (currentFilterSelectItems !== undefined && filterSelectValues !== undefined) {
                const newFilterSelectValues = DictionaryUtils.objectFromPairs(
                    Object.keys(currentFilterSelectItems).map((filterSelectItemKey) =>
                        filterSelectItemKey === filterItemKey
                            ? [filterSelectItemKey, value]
                            : [filterSelectItemKey, filterSelectValues[filterSelectItemKey]],
                    ),
                );
                setFilterSelectValues(newFilterSelectValues);
                if (currentFilterSelectOnChange !== undefined) {
                    currentFilterSelectOnChange(newFilterSelectValues);
                }
            }
        },
        [currentFilterSelectItems, currentFilterSelectOnChange, filterSelectValues],
    );

    const currentFilterSearch = props.filter?.search;
    const currentFilterSearchOnSearch = currentFilterSearch?.onSearch;
    const handleSearchFieldChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            if (currentFilterSearchOnSearch !== undefined) {
                currentFilterSearchOnSearch(e.currentTarget.value);
            }
        },
        [currentFilterSearchOnSearch],
    );

    const currentActionsItemClick = props.actions?.item?.click;
    const handleRowClick = React.useCallback(
        (event: React.MouseEvent<HTMLTableRowElement>) => {
            if (!isTextSelected) {
                const id = Number(event.currentTarget.dataset.id);
                if (currentActionsItemClick !== undefined) {
                    doAction(currentActionsItemClick(id));
                }
            }
            setIsTextSelected(false);
        },
        [currentActionsItemClick, doAction, isTextSelected],
    );

    React.useEffect(() => {
        if (!props.getEntityExtension || !props.selectedItems || Array.isArray(props.selectedItems)) {
            return undefined;
        }
        
        const entityId = props.id(props.selectedItems as TEntity);

        setExtensionLoading(true);
        setEntityExtension(undefined);

        props.getEntityExtension(entityId).then((value) => {
            setEntityExtension(value);
        }).finally(() => {
            setExtensionLoading(false);
        }); 
    }, [props.selectedItems]);

    const hasHeader = props.columns.some((columnConfig) => columnConfig.title !== undefined);

    const currentFilterSearchSearchString = currentFilterSearch?.searchString;
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
    const [entities, setEntities] = React.useState([] as ReadonlyArray<TEntity>);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        getEntities();
    }, [currentPageNumber, currentFilterSearchSearchString, currentFilterSearch?.filter, currentFilterSelectValues, props.data]);

    React.useEffect(() => {
        if (props.refresh) {
            getEntities();
        }
    }, [props.refresh]);

    const handlePaginationChange = React.useCallback((pageNumber) => {
        setCurrentPageNumber(pageNumber);
    }, []);

    const currentActionsItemDelete = props.actions?.item?.delete;
    const getVisibleColumnCount = React.useCallback(() => {
        let result = props.columns.length;

        if (currentActionsItemDelete !== undefined) {
            result += 1;
        }

        if (props.rowColorMarker !== undefined) {
            result += 1;
        }

        return result;
    }, [props.columns.length, currentActionsItemDelete, props.rowColorMarker]);

    const isEntitySelected = React.useCallback(
        (entity: TEntity): boolean => {
            if (props.selectedItems === undefined) {
                return false;
            }

            return Array.isArray(props.selectedItems)
                ? props.selectedItems.includes(entity)
                : entity === props.selectedItems;
        },
        [props.selectedItems],
    );

    const isEntityMarkedWithWarning = React.useCallback(
        (entity: TEntity): boolean => isEntityWithWarning !== undefined && isEntityWithWarning(entity),
        [isEntityWithWarning],
    );

    const getEntityId = props.id;
    const renderRowExtendedContent = props.renderRowExtendedContent;
    const getRowMarkerColor = props.rowColorMarker;
    const getRowClass = props.rowClass;

    const renderSkeletonRows = React.useCallback(
        () => (
            <>
                {[...Array(PreloadedRowsNumber)].map((element, index) => (
                    <tr key={index} className={classNames('entity-list__row')}>
                        {props.columns.map((column) => (
                            <td
                                key={column.key}
                                className={classNames(
                                    'entity-list__cell',
                                    `entity-list__cell_align-${column.align ?? 'left'}`,
                                    'entity-list__data-cell',
                                    column.noWrap === true && 'entity-list__cell_no-wrap',
                                    column.stretch === true && 'entity-list__cell_stretch',
                                )}
                            >
                                <Skeleton active round title={false} />
                            </td>
                        ))}
                    </tr>
                ))}
            </>
        ),
        [],
    );

    const renderRows = React.useCallback(
        () =>
            ArrayUtils.flatten(
                entities.map((entity: TEntity, index: number) =>
                    ArrayUtils.defined([
                        <tr
                            key={getEntityId(entity)}
                            data-id={getEntityId(entity)}
                            className={classNames(
                                'entity-list__row',
                                isEntityMarkedWithWarning(entity) && 'entity-list__row_warning',
                                'entity-list__data-row',
                                currentActionsItemClick !== undefined && 'entity-list__data-row_clickable',
                                isEntitySelected(entity) &&
                                    renderRowExtendedContent === undefined &&
                                    'entity-list__data-row_selected-without-extension',
                                getRowClass && getRowClass(entity, index)
                            )}
                            onClick={handleRowClick}
                        >
                            {getRowMarkerColor === undefined ? undefined : (
                                <th
                                    className={classNames(
                                        'entity-list__cell',
                                        'entity-list__data-cell',
                                        'entity-list__data-cell-row-color-marker',
                                    )}
                                >
                                    <span
                                        className="entity-list__row-color-marker"
                                        style={{
                                            backgroundColor: getRowMarkerColor(entity, index),
                                        }}
                                    />
                                </th>
                            )}
                            {renderSingleRow(entity)}
                            {currentActionsItemDelete !== undefined && (
                                <td
                                    className={classNames(
                                        'entity-list__cell',
                                        'entity-list__data-cell',
                                        'entity-list__cell_no-wrap',
                                        'entity-list__cell_align-right',
                                    )}
                                >
                                    <Button
                                        icon={<DeleteOutlined />}
                                        title={currentActionsItemDelete.title ?? 'Удалить'}
                                        data-id={getEntityId(entity)}
                                        onClick={handleItemDeleteButtonClick}
                                    />
                                </td>
                            )}
                        </tr>,
                        isEntitySelected(entity) && renderRowExtendedContent !== undefined ? (
                            <tr
                                key={`${getEntityId(entity)}_extension`}
                                className={classNames(isEntityMarkedWithWarning(entity) && 'entity-list__row_warning')}
                            >
                                <td colSpan={getVisibleColumnCount()} className="entity-list__extension-cell">
                                    {props.getEntityExtension ? (
                                        <Spin spinning={extensionLoading} size="small" indicator={spinIcon}>
                                            {entityExtension && renderRowExtendedContent(entityExtension)}
                                        </Spin>
                                    ) : (
                                        renderRowExtendedContent(entity as TEntityExtension)
                                    )}                                    
                                </td>
                            </tr>
                        ) : undefined,
                    ]),
                ),
            ),
        [
            entities,
            getEntityId,
            currentActionsItemClick,
            isEntitySelected,
            renderRowExtendedContent,
            isEntityMarkedWithWarning,
            handleRowClick,
            getRowMarkerColor,
            currentActionsItemDelete,
            handleItemDeleteButtonClick,
            getVisibleColumnCount,
            renderSingleRow,
            extensionLoading,
            entityExtension,
        ],
    );

    const currentActionCommands = props.actions?.list;
    const currentFilterAdditional = props.filter?.additional;

    return (
        <div
            className={classNames(
                'entity-list',
                props.className,
                currentActionsItemClick !== undefined && 'entity-list_clickable-items',
                props.small === true && 'entity-list__small',
            )}
        >
            {currentActionCommands !== undefined && currentActionCommands.length > 0 && (
                <div className="entity-list__actions">{currentActionCommands.map(renderListActionButton)}</div>
            )}
            {(currentFilterSelect !== undefined ||
                currentFilterSearch !== undefined ||
                currentFilterAdditional !== undefined) && (
                <div
                    className={classNames('entity-list__filters', props.isRowReverse && 'entity-list__filters-reverse')}
                >
                    <div className="entity-list__select-filters">
                        {currentFilterSelectItems !== undefined &&
                            Object.keys(currentFilterSelectItems).map((selectItemKey) => (
                                <Select<string>
                                    className={classNames(
                                        'entity-list__select-filter-item',
                                        (filterSelectValues !== undefined && filterSelectValues[selectItemKey]) !==
                                            undefined &&
                                            (filterSelectValues !== undefined && filterSelectValues[selectItemKey]) !==
                                                props.filter!.select!.items[selectItemKey][0].value &&
                                            'entity-list__select-filter-item_not-empty',
                                    )}
                                    dropdownMatchSelectWidth={props.isRowReverse !== undefined}
                                    key={selectItemKey}
                                    value={
                                        filterSelectValues === undefined
                                            ? props.filter!.select!.items[selectItemKey][0].value
                                            : filterSelectValues[selectItemKey]
                                    }
                                    onChange={(value) => {
                                        handleFilterSelectChange(selectItemKey, value);
                                    }}
                                >
                                    {props.filter!.select!.items[selectItemKey].map((selectItem) => (
                                        <Select.Option key={selectItem.value} value={selectItem.value}>
                                            {selectItem.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        {currentFilterAdditional !== undefined &&
                            currentFilterAdditional.map((additionalFilter, index) => (
                                <Tag
                                    className="entity-list__select-filter-item-tag"
                                    key={index}
                                    closable={true}
                                    onClose={additionalFilter.onClose}
                                >
                                    {additionalFilter.title}
                                </Tag>
                            ))}
                    </div>
                    <div className="entity-list__search-filters">
                        {currentFilterSearch !== undefined && (
                            <Input
                                className={classNames(
                                    'entity-list__search-field',
                                    Boolean(currentFilterSearch.searchString) && 'entity-list__search-field_not-empty',
                                )}
                                value={currentFilterSearch.searchString}
                                onChange={handleSearchFieldChange}
                                placeholder="Поиск"
                                allowClear={true}
                            />
                        )}
                    </div>
                </div>
            )}
            {pageSize > 0 && total > pageSize && (
                <Pagination
                    pageSize={pageSize}
                    showSizeChanger={false}
                    className="entity-list__pagination"
                    current={currentPageNumber}
                    total={total}
                    onChange={handlePaginationChange}
                />
            )}
            <table onMouseUp={handleTextSelection} className="entity-list__table">
                {hasHeader && (
                    <thead className="entity-list__table-header">
                        <tr className={classNames('entity-list__row', 'entity-list__header-row')}>
                            {props.rowColorMarker === undefined ? undefined : (
                                <th
                                    className={classNames(
                                        'entity-list__cell',
                                        'entity-list__header-cell',
                                        'entity-list__data-cell-row-color-marker',
                                    )}
                                >
                                    <span className="entity-list__row-color-marker" />
                                </th>
                            )}
                            {props.columns.map((column) =>
                                column.title === undefined ? undefined : (
                                    <th
                                        className={classNames(
                                            'entity-list__cell',
                                            `entity-list__cell_align-${column.align ?? 'left'}`,
                                            'entity-list__header-cell',
                                            column.noWrap === true && 'entity-list__cell_no-wrap',
                                            column.stretch === true && 'entity-list__cell_stretch',
                                            `column-${column.key}`,
                                        )}
                                        key={column.key}
                                    >
                                        {column.title}
                                    </th>
                                ),
                            )}
                            {currentActionsItemDelete !== undefined && (
                                <td
                                    className={classNames(
                                        'entity-list__cell',
                                        'entity-list__header-cell',
                                        'entity-list__cell_align-right',
                                    )}
                                />
                            )}
                        </tr>
                    </thead>
                )}
                <tbody>{loading || props.loading ? renderSkeletonRows() : renderRows()}</tbody>
            </table>
            {currentActionsItemDelete !== undefined && (
                <DeleteConfirmationDialog
                    title={currentActionsItemDelete.title}
                    warningText={currentActionsItemDelete.warningText}
                    visible={deletingEntityId !== undefined}
                    onOk={handleDeleteConfirmationDialogOk}
                    onCancel={handleDeleteConfirmationDialogCancel}
                    getOnOkPromise={getItemDeletionPromise}
                />
            )}
        </div>
    );
};
