import { DateTime } from '@/Std/DateTime';
import { IDriver } from './IDriver';

export class Driver implements IDriver {
    public readonly code: string;
    public readonly deletedAt: DateTime | undefined;
    public readonly id: number;
    public login: string | undefined;
    public readonly name: string;
    public readonly proficiency: number;
    public readonly enableDeliveryPrediction: boolean;
    public isActive?: boolean;

    public constructor(
        id: number,
        code: string,
        name: string,
        proficiency: number,
        enableDeliveryPrediction: boolean,
        login: string | undefined,
        deletedAt: DateTime | undefined,
    ) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.proficiency = proficiency;
        this.enableDeliveryPrediction = enableDeliveryPrediction;
        this.login = login;
        this.deletedAt = deletedAt;
    }

    public setIsActive(value: boolean) {
        this.isActive = value;

        return this;
    }

    public cloneSetLogin(login: string): Driver {
        return new Driver(this.id, this.code, this.name, this.proficiency, this.enableDeliveryPrediction, login, this.deletedAt);
    }

    public compareDriverNames(driver: Driver): number {
        const driverName1 = this.name.toUpperCase();
        const driverName2 = driver.name.toUpperCase();

        return driverName1 === driverName2 ? this.id - driver.id : driverName1 < driverName2 ? -1 : 1;
    }
}
