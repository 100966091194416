import React from 'react';
import { Tag } from 'antd';
import {OrderStatus} from '../../../Model/Order/OrderStatus';
import {formatDate} from '../../../Std/formatDate';
import { ReactComponent as CalendarIconComponent } from '../../../icons/calendar.svg';
import {OrderBadgeStatus} from '../../OrderBadgeStatus/OrderBadgeStatus';
import { OrderRoutePoint } from '../../../Map/Route/RoutePoint/OrderRoutePoint';
import {FormattedTime, FormattedTimeStr} from '../RouteInfoFormattedValues/FormattedTime';
import { FormattedTimePeriod } from '../RouteInfoFormattedValues/FormattedTimePeriod';
import '../RouteInfo.css';

export interface IOrderRoutePointContentProps {
    point: OrderRoutePoint;
}

export const OrderRoutePointContent = (props: IOrderRoutePointContentProps): JSX.Element => {
    const { point } = props;

    return (
        <>
            <div className='route-info__order-status-wrap'>
                <OrderBadgeStatus
                    className="route-info__order-status"
                    deferred={point.order.deferred}
                    status={point.order.status}
                />
                {point.order.transfer?.toDriverName !== undefined && point.order.status === OrderStatus.Moved && (
                    <Tag className="order-transferred-to">{point.order.transfer.toDriverName}</Tag>
                )}
            </div>
            <div className="route-info__client-name">{point.order.clientName}</div>
            <div className="route-info__address" title={`Всего: ${point.order.totalShipmentCount}`}>{point.address}</div>
            <div className="route-info__timings">
                {point.hasLegs &&
                    <>
                        <span
                            title={point.actualArrivalDateTime
                                ? FormattedTimeStr(point.actualArrivalDateTime)
                                : undefined
                            }
                        >
                            <FormattedTime date={point.expectedArrivalDateTime}/>
                        </span>
                        &nbsp;&ndash;&nbsp;
                        <span>
                            <FormattedTime date={point.expectedDepartureDateTime} />
                        </span>
                    </>
                }
                <FormattedTimePeriod dateTimePeriod={point.order.desiredDateTimePeriod?.getTimePeriod()} />
            </div>
            {point.order.status === OrderStatus.Rescheduled && point.order.rescheduledDateTimePeriod !== undefined && (
                <div className="route-info__rescheduled">
                    <CalendarIconComponent /> {formatDate(point.order.rescheduledDateTimePeriod.start)}
                </div>
            )}
        </>
    );
};
