import React from 'react';
import {FinishRoutePoint} from '../../../Map/Route/RoutePoint/FinishRoutePoint';
import { formatNumber } from '../../../Std/FormatNumber';
import { MathUtils } from '../../../Std/MathUtils';
import { formatDistanceInKilometers } from '../../../Std/FormatDistanceInKilometers';
import { FormattedTime } from '../RouteInfoFormattedValues/FormattedTime';
import '../RouteInfo2.css';

interface RouteInfoFinishPointProps {
    point: FinishRoutePoint;
}

export const RouteInfoFinishPoint = (props: RouteInfoFinishPointProps) => {
    const { point } = props;

    const formatDistance = (distanceInMeters: number): string =>
    `${formatNumber(MathUtils.round(distanceInMeters))}м`;

    const renderDistance = (distance: number) => {
        const metersInkilometer = 1000;

        return distance >= metersInkilometer
            ? `${formatDistanceInKilometers(distance, 1, false)}км`
            : `${formatDistance(distance)}`
    }


    return (
        <>
            <div className="route-info-block__marker_end">
                <div className="route-info-block__wrapper-circle"><div className="route-info-block__inner-circle" /></div>
                <div className="route-info-block__distance">
                    {point.expectedDistanceTo && renderDistance(point.expectedDistanceTo)}
                </div>
            </div>
            <div className="route-info-block__drag" />
            <div className="route-info-block__content route-info-block__content_end">
                <div>
                    <div>{point.address}</div>
                    <div>
                        <FormattedTime date={point.expectedArrivalDateTime} />
                    </div>
                </div>
            </div>
        </>
    )
}