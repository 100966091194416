import { OrderOperations } from '../Model/OrderOperations/OrderOperations';
import { ProductOperation } from '../Model/ProductOperation/ProductOperation';
import { IFormatter } from './IFormatter';
import { productOperationFormatter } from './ProductOperationFormatter';
import { stringArrayFormatter } from './StringArrayFormatter';

export class OrderOperationsFormatter implements IFormatter<OrderOperations> {
    public default(obj: OrderOperations): string {
        const productOperations = obj.productOperations.map((productOperation: ProductOperation) =>
            productOperationFormatter.default(productOperation),
        );

        return stringArrayFormatter.default(productOperations);
    }
}

export const orderOperationsFormatter = new OrderOperationsFormatter();
