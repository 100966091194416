import * as React from 'react';
import { dateTimeDeserializer } from '../../Backend/Api/Mapper/DateTimeDeserializer';
import { formatDate } from '../../Std/formatDate';
import { formatTimeSec } from '../../Std/formatTime';
import { LazyEntityList as EntityList } from '../EntityList/LazyEntityList';

import './CompanyLogs.css';
import { CompanyApiDateLog } from '../../ApiClient/YosoCompany/models';
import { Result } from '../../Model/PaginatedEntities/Result';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';

export interface ICompanyLogsProps {
}

export const CompanyLogs = (props: ICompanyLogsProps): JSX.Element => {
    const [logs, setLogs] = React.useState([] as CompanyApiDateLog[]);
    const [selectedLogs, setSelectedLogs] = React.useState(undefined as CompanyApiDateLog | undefined);

    const getLogs = (page: number, size: number): Promise<Result<CompanyApiDateLog>> =>
        new Promise<Result<CompanyApiDateLog>>((resolve, reject) => {
            CompanyApi.getLogs(size, (page - 1) * size).then((response) => {
                setLogs(response.logsByDate);
                resolve({
                    entities: response.logsByDate,
                    total: response.totalDates
                });
            }).catch((reason: any) => {
                alert(String(reason));
                reject();
            });
        });

    const countUnique = (arr: any[]) => new Set(arr).size;

    return (
        <>
            <EntityList<CompanyApiDateLog> 
                className='company-logs'
                getEntities={getLogs}
                id={(dateLog) => dateLog.timestamp}
                columns={[{
                        key: 'date',
                        title: 'Дата',
                        render: (dateLog) => formatDate(dateTimeDeserializer.deserialize(dateLog.date)),
                    },{
                        key: 'count',
                        title: 'Количество',
                        render: (dateLog) => dateLog.logs.length,
                    },{
                        key: 'countUsers',
                        title: 'Количество пользователей',
                        render: (dateLog) => countUnique(dateLog.logs.map((l) => l.username)),
                }]}
                actions={{
                    item: {
                        click: (timestamp: number) => (): undefined => {
                            const selected = logs.find((l) => l.timestamp === timestamp);
                            setSelectedLogs(selected === selectedLogs ? undefined : selected);

                            return;
                        }
                    }
                }}
                selectedItems={selectedLogs}
                renderRowExtendedContent={(log) => (
                    log.logs.map((record) => (
                        <div key={record.id} className="company-logs__ext-content">
                            <div className="company-logs__text">
                                <div className="company-logs__username">
                                    {record.username}
                                    {record.name && record.username !== record.name && (
                                        <span className="company-logs__name">&nbsp;({record.name})</span>
                                    )}
                                </div>
                                {record.uri && (
                                    <div className="company-logs__uri">
                                        {record.method && (
                                            <span className={`company-logs__method ${record.method}`}>{record.method}&nbsp;</span>
                                        )}
                                        {record.uri}
                                    </div>
                                )}
                                <div className="company-logs__message">{record.message}</div>
                                <pre className="company-logs__stack-trace">{record.stackTrace}</pre>
                            </div>
                            <div className="company-logs__time">
                                {formatTimeSec(dateTimeDeserializer.deserialize(record.createdAt))}
                            </div>
                        </div>
                    ))
                )}
            />
        </>
    )
}