import React from 'react';
import Icon from '@ant-design/icons';
import {Alert, Button, Select, Spin} from 'antd';
import {Moment} from 'moment';
import {Prompt} from 'react-router';
import {
    ReactComponent as OrderWarningIconComponent
} from '../../icons/order-warning.svg';
import {Car} from '../../Model/Car/Car';
import { District } from '../../Model/District/District';
import {DraftOrder} from '../../Model/DraftOrder/DraftOrder';
import {TripExecutor} from '../../Model/TripExecutor/TripExecutor';
import {
    TripExecutorsLoadingInfo
} from '../../Model/TripExecutor/TripExecutorsLoadingInfo';
import {TripPlan} from '../../Model/TripPlan/TripPlan';
import {DateTime} from '../../Std/DateTime';
import {Time} from '../../Std/Time';
import {DistrictGroup} from '../../Store/District/DistrictGroup';
import {DistrictGroupCode} from '../../Store/District/DistrictGroupCode';
import {useCompany} from '../AppProviders/Providers/CompanyProvider';
import {PlanValidation} from '../PlanValidation/PlanValidation';
import {ValidationList} from '../ValidationList/ValidationList';
import {useTripPlanData} from './TripPlanDataProvider';
import {TripPlanDatePicker} from './TripPlanDatePicker';
import './NewPlan.css';

export interface INewPlanProps {
    handleExecutionDateChange: (date: Moment | null) => void;
    handleStartTimeChange: (date: Moment | null) => void;
    handleEndTimeChange: (time: Moment | null) => void;
    onSaveAndBuildPlan: (plan: TripPlan, cars: ReadonlyArray<Car>) => void;
    isNewBuildPlan?: boolean;
}

// eslint-disable-next-line complexity
export const NewPlan = (props: INewPlanProps): JSX.Element => {
    const {Option} = Select;
    const companyContext = useCompany();
    const {company} = companyContext;

    const context = useTripPlanData();
    const {
        addOrders,
        applySelectedOrderPlace,
        loadPlan,
        saveAndBuildRoutes,
        cancelAddressGeocodeSelection,
        setExecutionDate,
    } = context;

    const defaultDepartPeriodAmount: Record<string, number> = {
        MORNING: 0, // eslint-disable-line
        AFTERNOON: 0, // eslint-disable-line
        EVENING: 0, // eslint-disable-line
        null: 0,
        all: 0
    };

    const strAll = 'all';

    const [step, setStep] = React.useState(1);
    const [orders, setOrders] = React.useState(context.orders);
    const [executors, setExecutors] = React.useState(context.executors);
    const [executorsLoadingInfo, setExecutorsLoadingInfo] = React.useState(context.executorsLoadingInfo);
    const [departPeriods, setDepartPeriods] = React.useState([] as string[]);
    const [initialDepartPeriodsLength, setInitialDepartPeriodsLength] = React.useState(0 as number);
    const [departPeriodAmount, setDepartPeriodAmount] = React.useState(defaultDepartPeriodAmount);
    const [districtGroups, setDistrictGroups] = React.useState<DistrictGroup[] | undefined>(undefined);
    const [warningMessages, setWarningMessages] = React.useState(context.warningMessages);
    const [selectedDistrictGroup, setSelectedDistrictGroup] = React.useState(undefined as DistrictGroup | undefined);

    React.useEffect(() => {
        const modifiedDistrictGroups = context.districtGroups?.map(dg => (
            dg.key === DistrictGroupCode.Morning || dg.key === DistrictGroupCode.Evening
                ? new DistrictGroup(DistrictGroupCode.Morning, 'День / Вечер')
                : dg
        ));

        const uniqueModifiedDistrictGroups = modifiedDistrictGroups?.filter((value, i, self) =>
            i === self.findIndex(t => (t.key === value.key)))

        setDistrictGroups(uniqueModifiedDistrictGroups);
    }, [context.districtGroups]);

    React.useEffect(() => {
        setWarningMessages(context.warningMessages);
    }, [context.warningMessages]);

    React.useEffect(() => {
        const periods = context.tripPlan ? [...context.tripPlan.departPeriods] : [];
        setDepartPeriods(periods);
        setOrders(filterOrders(periods));
        setInitialDepartPeriodsLength(periods.length);
    }, [context.tripPlan, context.orders]);

    React.useEffect(() => {
        if (!orders) {
            return undefined;
        }
        const execs = createExecutors(orders);
        setExecutors(execs);
        setExecutorsLoadingInfo(new TripExecutorsLoadingInfo(execs, orders));
    }, [orders])

    const createExecutors = (ords: ReadonlyArray<DraftOrder>): TripExecutor[] => {
        const execs: Record<number, TripExecutor> = {};
        ords.forEach((order) => {
            const executorId: number = order.executor!.getId();
            if (order.executor !== undefined && !(executorId in execs)) {
                execs[executorId] = new TripExecutor(order.executor.car, order.executor.driver, undefined);
            }
        });

        return Object.values(execs).sort((executor1, executor2) => executor1.driver.compareDriverNames(executor2.driver));
    };

    React.useEffect(() => {
        const tempPeriodAmount = defaultDepartPeriodAmount;
        context.orders?.map((order) => {
            const processedPeriod = order.departPeriod ? order.departPeriod : 'null';

            tempPeriodAmount[processedPeriod] += 1;
            tempPeriodAmount[strAll] += 1
        });
        setDepartPeriodAmount(tempPeriodAmount);
    }, [context.orders]);

    const handleSaveAndBuildRoutesButtonClick = async (newOrders?: DraftOrder[], districts?: District[]): Promise<undefined> => {
        const districtGroupCode = selectedDistrictGroup ? selectedDistrictGroup.key : DistrictGroupCode.Morning;
        const action = context.planId !== undefined
            ? addOrders(orders!)
            : saveAndBuildRoutes(newOrders ? newOrders : orders!, departPeriods, districtGroupCode, districts);

        const response = await action;
        if (response !== undefined) {
            props.onSaveAndBuildPlan(response.plan, response.cars);
            await loadPlan(undefined);
            setExecutionDate(DateTime.now().cloneWithTime(new Time(0, 0)))
            cancelAddressGeocodeSelection();
        }

        return;
    }

    const filterOrders = React.useCallback((periods: string[]): ReadonlyArray<DraftOrder> | undefined =>
            context.orders?.filter((order) => {
                const processedPeriod = order.departPeriod ? order.departPeriod : 'null';

                return (periods.length === 0 && initialDepartPeriodsLength < 1) || periods.includes(processedPeriod);
            })
        , [context.orders, initialDepartPeriodsLength]);

    const handleDepartPeriodChange = React.useCallback(
        (period: string) => {
            setDepartPeriods(period === 'ALL' ? [] : [period])
            setOrders(period === 'ALL' ? filterOrders([]) : filterOrders([period]));
        },
        [context.orders, orders, setOrders],
    );

    const handleDistrictGroupChange = React.useCallback((e: string) => {
        if (!districtGroups) {
            return;
        }

        const group = districtGroups.find((el: DistrictGroup) => el.key === e);

        setSelectedDistrictGroup(group);
    }, [districtGroups]);

    React.useEffect(() => {
        if (!districtGroups || !districtGroups.length) {
            return undefined;
        }
        setSelectedDistrictGroup(districtGroups[0]);
    }, [districtGroups])

    return (
        <Spin spinning={context.loading}>
            {!context.loading && step === 1 && (
                <>
                    <div className="new-plan__block">
                        <div className="new-plan__filters">
                            {context.planId === undefined && (
                                <TripPlanDatePicker
                                    executionDate={context.executionDate}
                                    handleExecutionDateChange={props.handleExecutionDateChange}
                                    startTime={context.startTime}
                                    handleStartTimeChange={props.handleStartTimeChange}
                                    endTime={context.endTime}
                                    handleEndTimeChange={props.handleEndTimeChange}
                                    isAlternativePlanAllowed={company?.alternativePlanAllowedToUse}
                                />
                            )}
                            <div className='new-plan__filters-column'>
                                <div>
                                    {districtGroups && districtGroups.length > 0 && (
                                        <div className='new-plan__filters-column_select'>
                                            <span
                                                className='new-plan__filters-column_title'
                                            >
                                                Зоны:
                                            </span>
                                            <Select
                                                defaultValue={districtGroups[0].key}
                                                onChange={handleDistrictGroupChange}>
                                                {
                                                    districtGroups.map(item =>
                                                        <Option key={item.label}
                                                                value={item.key}>{item.label}
                                                        </Option>)
                                                }
                                            </Select>
                                        </div>
                                    )}
                                    {context.departPeriods
                                        && (context.departPeriods!.length > 1 || initialDepartPeriodsLength > 0 || companyContext.company?.alternativePlanAllowedToUse)
                                        && (
                                            <div className='new-plan__filters-column_select'>
                                                <span className='new-plan__filters-column_title'>Заказы:</span>
                                                <Select
                                                    defaultValue={'ALL'}
                                                    onChange={handleDepartPeriodChange}
                                                >
                                                    <Option value='ALL'>
                                                        Все заказы {`(${departPeriodAmount[strAll]})`}
                                                    </Option>
                                                    {
                                                        context.departPeriods.map(item =>
                                                            <Option
                                                                key={item.value}
                                                                value={item.value}
                                                            >
                                                                {item.title} {`(${departPeriodAmount[item.value]})`}
                                                            </Option>
                                                        )
                                                    }
                                                </Select>
                                           </div>
                                        )}
                                </div>
                            </div>
                        </div>
                        {warningMessages && !props.isNewBuildPlan && warningMessages.map((warning) => (
                            <Alert message={warning.value} key={warning.value}
                                   type="warning" showIcon closable/>
                        ))}
                    </div>
                    <div className="new-plan__block">
                        <ValidationList
                            orders={orders}
                            errorOrders={context.errorOrders!}
                            executors={executors}
                            executorsLoadingInfo={executorsLoadingInfo}
                            handleSelectAddressPlace={applySelectedOrderPlace}
                            needSelectFilter={true}
                        />
                    </div>
                    <div className="new-plan__block">
                        <Button
                            type="primary"
                            loading={context.saving}
                            onClick={props.isNewBuildPlan ? () => setStep(2) : () => handleSaveAndBuildRoutesButtonClick()} // eslint-disable-line
                            disabled={!context.isInputDataValid() || !context.isOrdersValid()}
                        >
                            {props.isNewBuildPlan
                                ? 'Следующий шаг'
                                : context.planId === undefined
                                    ? (!company?.buildRoutesOnApprove ? 'Сохранить и построить маршруты' : 'Сохранить заказы')
                                    : 'Добавить заказы'}
                        </Button>

                        {context.planId === undefined && !context.isInputDataValid() && (
                            <Icon
                                className="new-plan__saving-warning-icon"
                                title="Некорректный временной интервал"
                                component={OrderWarningIconComponent}
                            />
                        )}

                        {context.orders !== undefined && context.orders.length > 0 && !context.isOrdersValid() && (
                            <Icon
                                className="new-plan__saving-warning-icon"
                                title="Остались непровалидированные заказы"
                                component={OrderWarningIconComponent}
                            />
                        )}
                    </div>
                </>
            )}
            {!context.loading && step === 2 && ( // eslint-disable-line
                <PlanValidation
                    districtGroup={selectedDistrictGroup?.key}
                    buildPlan={handleSaveAndBuildRoutesButtonClick}
                />
            )}
            <Prompt
                when={context.isDirty}
                message="Если вы покинете страницу, не построив маршрут, изменения не будут сохранены. Уйти со страницы?"
            />
        </Spin>
    );
};
