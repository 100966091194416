import * as React from 'react';
import { withRouter } from 'react-router';
import { Page } from '../../Page/Page';
import { RoutedTabs } from '../../RoutedTabs/RoutedTabs';
import { DriverPlaceRoute } from '../DriverPlace/DriverPlaceRoute';
import { DriverCoefficientsOffers }
    from './DriverCoefficientsOffers/DriverCoefficientsOffers';
import DriverList from './DriversRoute/DriverList';

export const DriversRoute = withRouter(() => (
    <Page header="Водители">
        <RoutedTabs
            className='fullheight'
            tabs={[
                {
                    title: 'Водители',
                    url: `/drivers`,
                    render: () => <DriverList />,
                },
                {
                    title: 'Водительские точки',
                    url: `/drivers/places`,
                    render: () => <DriverPlaceRoute />,
                },
                {
                    title: 'Изменить коэффициенты',
                    url: `/drivers/coefficient-offers`,
                    render: () => <DriverCoefficientsOffers />,
                },
            ]}
        />
    </Page>
));