import { DateTime } from './DateTime';

export class TimePeriod {
    public readonly end: DateTime;
    public readonly start: DateTime;

    public constructor(start: DateTime, end: DateTime) {
        this.start = start;
        this.end = end;
    }
}
