import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import {
    IClientPreferencesContext
} from '../../Components/AppProviders/Providers/ClientPreferencesProvider/ClientPreferencesProvider';
import {
    DriverNoticeMails
} from '../../Model/DriverNoticeMails/DriverNoticeMails';
import { NoticeMail } from '../../Model/NoticeMail/NoticeMail';
import { DateTime } from '../../Std/DateTime';

export class NoticeMailsStore {
    public isMailboxInit = false
    public selectedDriverId?: number;
    public selectedDriverMails: NoticeMail[] = [];
    public dateFetching: DateTime;
    public hasUnreadMails: boolean | undefined;
    public driverMails: DriverNoticeMails[] = [];
    public loadingMails = false;
    public driversWhomArchiveReceived = new Map<number, number>();

    private take = 20; // eslint-disable-line
    private week = 7; // eslint-disable-line
    private _allArchiveDriverMailsUploaded = new Map<number, boolean>();
    private localStorageContext: IClientPreferencesContext;

    public constructor(localStorageContext: IClientPreferencesContext) {
        const date = new Date();
        date.setDate(date.getDate() - this.week);

        this.localStorageContext = localStorageContext;
        localStorageContext.clearMailsBeforeDate(DateTime.fromDate(date));
        this.dateFetching = localStorageContext.dateLastReceivingNoticeMail;
        this.fetchDriversWithMails();

        makeAutoObservable(this);

        reaction(
            () => this.selectedDriverMails,
            (newValues) => {
                if (!this.selectedDriverId || newValues === []) {
                    return
                }

                this.localStorageContext.updateReadMailsIds({
                    driverId: this.selectedDriverId,
                    mails: newValues.map(i => ({id: i.id, date: i.date.toDate()}))
                })
            }
        );
    }

    public setSelectedDriverId = (id: number): void => {
        const driverMails = this.driverMails.find(i => i.driver.id === id);

        if (!driverMails) {
            throw new Error(`No mails for driver #${id}.`)
        }

        if (driverMails) {
            this.selectedDriverId = id;
            this.selectedDriverMails = driverMails.mails;
            driverMails.hasUnreadMails = false;
        }

        this.determineUnreadMails();
    }

    public fetchDriversWithMails = (): void => {
        const date = new Date();
        date.setDate(date.getDate() - this.week);
        const dateTime = DateTime.fromDate(date);

        DeliveryApi.getDriversWithNoticeMails().then(res => runInAction(() => {
            this.driverMails = res;
            this.dateFetching = this.getLastDateTimeMessage(res);
            this.localStorageContext.setLastReceivedNoticeMailDate(this.dateFetching);
            this.isMailboxInit = true;

            for (const driverMails of this.driverMails) {
                const foundDriver = this.localStorageContext.driversReadMailIds.find(d => d.driverId === driverMails.driver.id);

                if (!foundDriver) {
                    driverMails.hasUnreadMails = true;
                    continue;
                }

                const foundDriverMailIds = foundDriver.mails.map(i => i.id);
                const freshMails = driverMails.mails.filter(i => i.date.isMore(dateTime));

                driverMails.hasUnreadMails = !freshMails.every(i => foundDriverMailIds.includes(i.id));
            }
            this.determineUnreadMails();
        }))}

    public fetchNewMails = (): void => {
        DeliveryApi.getNoticeMailsFromDate(this.dateFetching)
            .then(res => runInAction(() => {
                this.dateFetching = this.getLastDateTimeMessage(res);
                this.localStorageContext.setLastReceivedNoticeMailDate(this.dateFetching);

                const sortedRes = this.sortMailsByDate(res);
                for (const driverMessages of sortedRes) {
                    const found = this.driverMails.find(d => d.driver.id === driverMessages.driver.id);

                    if (found) {
                        found.mails = [...found.mails, ...driverMessages.mails]
                        if (found.driver.id === this.selectedDriverId) {
                            this.selectedDriverMails = found.mails;
                        } else {
                            this.hasUnreadMails = true;
                            found.hasUnreadMails = true;
                        }
                    } else {
                        this.driverMails.push(driverMessages);
                        this.hasUnreadMails = true;
                        driverMessages.hasUnreadMails = true;
                    }
                }
            }))
    }

    public fetchArchiveMailsByDriverId = async (): Promise<void> => {
        if (!this.selectedDriverId) {
            return
        }

        if (this._allArchiveDriverMailsUploaded.get(this.selectedDriverId) === true) {
            return;
        }

        this.loadingMails = true;
        const oldMessageDate = this.selectedDriverMails.reduce((min, current) =>
                (current.date.isLess(min) ? current.date : min), this.selectedDriverMails[0].date);

        const mails = await DeliveryApi.getArchiveDriverNoticeMails(this.selectedDriverId, oldMessageDate, this.take)

        this._allArchiveDriverMailsUploaded.set(this.selectedDriverId, mails.length < this.take);

        const countArchiveFetch = this.driversWhomArchiveReceived.get(this.selectedDriverId);
        this.driversWhomArchiveReceived.set(this.selectedDriverId, countArchiveFetch ? countArchiveFetch + 1 : 1);

        const found = this.driverMails.find(d => d.driver.id === this.selectedDriverId);
        if (found) {
            const sortedMails = mails.sort((a, b) => a.date.compareTo(b.date));
            runInAction(() => {
                found.mails = [...sortedMails, ...found.mails];
                this.selectedDriverMails = found.mails;
            })
        }
        runInAction(() => this.loadingMails = false);
    }

    public get selectedDriverMailsGroupedByDate(): {date: string, mails: NoticeMail[]}[] {
        const groupedByDate = new Map<string, NoticeMail[]>();

        this.selectedDriverMails.forEach(curr => {
            const dateString = `${curr.date.year} ${curr.date.getMonthTitle()} ${curr.date.date}`;

            if (groupedByDate.has(dateString)) {
                groupedByDate.get(dateString)?.push(curr);
            } else {
                groupedByDate.set(dateString, [curr]);
            }
        });

        return Array.from(groupedByDate, ([date, mails]) => ({ date, mails }))
    }

    private sortMailsByDate = (arr: DriverNoticeMails[]) =>
        arr.map((i) =>
            ({
                ...i,
                mails: [...i.mails].sort((a, b) => a.date.compareTo(b.date))
            }))

    private getLastDateTimeMessage = (arr: DriverNoticeMails[]): DateTime => {
        let maxDate = this.dateFetching;
        for (const item of arr) {
            const lastMessage = item.mails[item.mails.length - 1]

            if (lastMessage.date.isMore(maxDate)) {
                maxDate = lastMessage.date;
            }
        }

        return maxDate;
    }

    private determineUnreadMails = () => {
        for (const mails of this.driverMails) {
            if (mails.hasUnreadMails === true) {
                runInAction(() => this.hasUnreadMails = true)

                return;
            }
        }
        runInAction(() => this.hasUnreadMails = false);
    }
}