import { DateTime } from '../../Std/DateTime';
import { Geocode } from '../Geocode/Geocode';

export class CarMoveAndStopLocation {
    public readonly startTime: DateTime;
    public readonly endTime?: DateTime;
    public readonly geocode: Geocode;
    public readonly label: number;

    public constructor(startTime: DateTime, endTime: DateTime | undefined, geocode: Geocode, label: number) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.geocode = geocode;
        this.label = label;
    }
}
