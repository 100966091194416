import { CompanyApiOrders } from '../../../../../ApiClient/YosoCompany/models';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { DistrictOrder } from '../../../../../Store/District/DistrictOrder';

export class DistrictOrderSerializer {
    public deserializeForMap(res: CompanyApiOrders): DistrictOrder[] {
        return res.orders.map(o => new DistrictOrder(
            o.id,
            o.addressName,
            new Geocode({ latitude: o.latitude, longitude: o.longitude }),
            o.shipmentBottlesCount,
            o.shipmentNonBottlesCount,
        ))
    }
}

export const districtOrdersSerializer = new DistrictOrderSerializer();
