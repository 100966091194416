import { PaidMethod1 as PaidMethod } from '../../../../../ApiClient/Yoso/models';
import { OrderPaidMethod } from '../../../../../Model/Order/OrderPaidMethod';
import { Types } from '../../../../../Std/Types';

export class OrderPaidMethodSerializer {
    public serialize(serializedObj: OrderPaidMethod): PaidMethod {
        return serializedObj === OrderPaidMethod.Cash
            ? PaidMethod.CASH
            : serializedObj === OrderPaidMethod.Qr
            ? PaidMethod.QR
            : serializedObj === OrderPaidMethod.Terminal
            ? PaidMethod.TERMINAL
            : Types.assertUnreachable(serializedObj);
    }
}

export const orderPaidMethodSerializer = new OrderPaidMethodSerializer();
