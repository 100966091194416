import {LatLngTuple} from 'leaflet';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {DeliveryApi} from '../../../Backend/Api/Delivery/DeliveryApi';
import { formatDate } from '../../../Std/formatDate';
import {getLeafletBoundsFromGeocodes} from '../../../Std/LeafletUtils';
import {TripComparisonStore} from '../../../Store/TripComparisonStore/TripComparisonStore';
import {useCompany} from '../../AppProviders/Providers/CompanyProvider';
import {DriverPlaceChoice} from '../../DriverPlaceChoice/DriverPlaceChoice';
import {Page} from '../../Page/Page';
import {RoutedTabs} from '../../RoutedTabs/RoutedTabs';
import {TripComparisons} from '../../TripComparisons/TripComparisons';

interface TripComparisonsRouteProps extends RouteComponentProps {
    id: number
}

export const TripComparisonsRoute = withRouter((props: TripComparisonsRouteProps) => {
    const placeStore = React.useMemo(() => new TripComparisonStore(), []);

    const {center} = useCompany();
    const [bounds, setBounds] = React.useState<LatLngTuple[] | undefined>(undefined);

    React.useEffect(() => {
        const getTripComparison = async () => {
            const response = await DeliveryApi.getTripComparison(props.id)
            placeStore.setTrip(response)
            setBounds(
                getLeafletBoundsFromGeocodes(placeStore.orders.map(o => o.coords))
            );
        };

        getTripComparison();
    }, [])

    return (
        <Page header={placeStore.name && placeStore.date ? `${formatDate(placeStore.date)} ${placeStore.name}` : ''}>
            <RoutedTabs
                className='fullheight'
                tabs={[
                    {
                        title: 'Ввод точек',
                        url: `/trip-comparisons/${props.id}/place-select`,
                        render: () => (
                            <DriverPlaceChoice
                                tripId={props.id}
                                center={center ? [center.latitude, center.longitude] : undefined}
                                bounds={bounds}
                                store={placeStore}
                            />
                        ),
                        cache: true,
                    },
                    {
                        title: 'Маршруты',
                        url: `/trip-comparisons/${props.id}/routes`,
                        render: () =>
                            <TripComparisons
                                tripId={props.id}
                                center={center ? [center.latitude, center.longitude] : undefined}
                                bounds={bounds}
                                store={placeStore}
                            />,
                        cache: true,
                    }
                ]}
            />
        </Page>
    )
});