import { Button, Form, Input } from 'antd';
import * as React from 'react';

import { CompanyApiAdminResponse } from '../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';

import './CompanyAdminEntityEdit.css';

export interface IExistingCompanyAdmin {
    companyAdmin: CompanyApiAdminResponse;
    id: number;
}

export interface ICompanyAdminEntityEditProps {
    companyAdmin: IExistingCompanyAdmin | undefined;
    companyId: number | undefined;
    onSave: () => void;
}

export const CompanyAdminEntityEdit = (props: ICompanyAdminEntityEditProps): JSX.Element => {
    const { onSave } = props;

    const destroyed = React.useRef(false);

    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const [name, setName] = React.useState(
        props.companyAdmin === undefined ? '' : props.companyAdmin.companyAdmin.employee!.name,
    );
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState(
        props.companyAdmin === undefined ? '' : props.companyAdmin.companyAdmin.employee!.credentials!.username,
    );

    const [activity, setActivity] = React.useState(
        !props.companyAdmin || !props.companyAdmin.companyAdmin.employee ? false : props.companyAdmin.companyAdmin.employee.isActive
    );

    const isDataValid = React.useCallback((): boolean => Boolean(name), [name]);

    const handleUsernameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(e.currentTarget.value);
    }, []);

    const handlePasswordChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.currentTarget.value);
    }, []);

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setName(e.currentTarget.value);
    }, []);

    const [saving, setSaving] = React.useState(false as boolean);

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid()) {
            return;
        }

        setSaving(true);

        try {
            if (props.companyAdmin === undefined) {
                if (props.companyId === undefined) {
                    await CompanyApi.postAdmin({
                        employee: {
                            credentials: {
                                password: password,
                                username: username,
                            },
                            name: name,
                            isActive: activity
                        },
                    });
                } else {
                    await CompanyApi.postCompanyAdmin(props.companyId, {
                        employee: {
                            credentials: {
                                password: password,
                                username: username,
                            },
                            name: name,
                            isActive: activity
                        },
                    });
                }
            } else {
                if (props.companyId === undefined) {
                    await CompanyApi.putAdmin({
                        employee: {
                            id: props.companyAdmin.id,
                            credentials: {
                                password: password,
                            },
                            name: name,
                            isActive: activity
                        },
                    });
                } else {
                    await CompanyApi.putCompanyAdmin(props.companyId, {
                        employee: {
                            id: props.companyAdmin.id,
                            credentials: {
                                password: password,
                            },
                            name: name,
                            isActive: activity
                        },
                    });
                }
            }
            if (destroyed.current) {
                return;
            }
            onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [isDataValid, props.companyAdmin, props.companyId, onSave, password, username, name]);

    return (
        <div>
            <div className="company-admin-entity-edit__block">
                <div className="company-admin-entity-edit__form">
                    <Form.Item label="Логин">
                        <Input
                            defaultValue={username}
                            onChange={handleUsernameChange}
                            disabled={props.companyAdmin !== undefined}
                        />
                    </Form.Item>
                    <Form.Item label="Пароль">
                        <Input defaultValue={password} onChange={handlePasswordChange} />
                    </Form.Item>
                    <Form.Item label="Имя">
                        <Input defaultValue={name} onChange={handleNameChange} />
                    </Form.Item>
                </div>
            </div>
            <div className="company-admin-entity-edit__block">
                <Button type="primary" loading={saving} onClick={handleSaveButtonClick} disabled={!isDataValid()}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
