import React from 'react';
import {ReactComponent as CheckpointIcon} from '../../icons/checkpoint.svg';
import {Basket as BasketModel} from '../../Model/Basket/Basket';
import styles from './Basket.module.css'

interface BasketProps {
    basket: BasketModel;
    color: string;
    onClick?: () => void;
}

const Basket = ({basket, onClick, color}: BasketProps): JSX.Element => {
    const click = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div
            className={styles.basket}
            onClick={click}
        >
            <div className={styles.markSection} style={{background: color}}/>
            <div className={styles.name}>{basket.driverName}</div>
            <div
                className={styles.counter}
                title='Количество заказов'
            >
                {basket.ordersCount}
                <CheckpointIcon className={styles.icon}/>
            </div>
        </div>
    )
}

export default Basket;
