import { DeliveryApiTrainingOrder } from '../../../../../ApiClient/Yoso/models';
import {
    TrainingOrder
} from '../../../../../Model/TrainingOrder/TrainingOrder';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class TrainingOrderDeserializer {
    public deserialize(serializedObject: DeliveryApiTrainingOrder): TrainingOrder {
        return ({
            id: serializedObject.id,
            actualTime: dateTimeDeserializer.deserialize(serializedObject.actualTime),
            expectedTime: dateTimeDeserializer.deserialize(serializedObject.expectedTime)
        })
    }
}

export const trainingOrderDeserializer = new TrainingOrderDeserializer();