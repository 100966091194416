export class DraftOrderDepartPeriodCode {
    public static readonly morning = 'MORNING';
    public static readonly afternoon = 'AFTERNOON';
    public static readonly evening = 'EVENING';

    public static getName(code: string | undefined): string {
        switch (code) {
            case DraftOrderDepartPeriodCode.morning:
                return 'Утренние';
            case DraftOrderDepartPeriodCode.afternoon:
                return 'Дневные';
            case DraftOrderDepartPeriodCode.evening:
                return 'Вечерние';
            default:
                return 'Не указано';
        }
    }
}
