import { makeAutoObservable } from 'mobx';
import { Car } from '../../Model/Car/Car';
import { District } from '../../Model/District/District';
import { DraftDriver } from '../../Model/DraftDriver/DraftDriver';
import { DraftOrder } from '../../Model/DraftOrder/DraftOrder';
import { Driver } from '../../Model/Driver/Driver';
import { TripExecutor } from '../../Model/TripExecutor/TripExecutor';
import {
    DistrictGroupCode,
} from '../../Store/District/DistrictGroupCode';
import { RoundRobinColorPicker } from '../MapContainer/model/RoundRobinColorPicker';
import { ArrayUtils } from '../../Std/ArrayUtils';

export class PlanValidationStore {
    public districts: District[] = [];
    public driverOrdersMap = new Map<DraftDriver, DraftOrder[]>();

    private _orderDriverMap = new Map<DraftOrder, DraftDriver | undefined>();
    private _groupDistrictsMap = new Map<DistrictGroupCode, District[]>();
    private _groupOrdersMap = new Map<DistrictGroupCode, DraftOrder[]>();
    private _districtOrdersMap = new Map<District, DraftOrder[]>();
    private _driverColorMap = new Map<DraftDriver, string>();
    private _allDrivers: DraftDriver[] = [];
    private _selectedDistrictGroup: DistrictGroupCode | undefined;
    private _selectedDistrict: District | undefined;
    private _selectedDriver: DraftDriver | undefined;
    private _selectedOrders: DraftOrder[] = [];
    private _isLoading = false;
    private readonly _orders: DraftOrder[];
    private _colorPicker: RoundRobinColorPicker = new RoundRobinColorPicker();
    private _cityDistrictDrivers = new Map<string | undefined, Set<DraftDriver>>();

    public constructor(orders: DraftOrder[], selected: DistrictGroupCode | undefined) {
        this._orders = orders;
        this._selectedDistrictGroup = selected;
        makeAutoObservable(this);
    }

    public setAllDrivers(allDrivers: DraftDriver[]): void {
        /* Должен быть вызван после setDistricts */
        const existingDriversSet = new Set<DraftDriver>();
        for (const district of this.districts) {
            for (const driver of district.drivers) {
                existingDriversSet.add(driver)
            }
        }

        const existingDrivers = Array.from(existingDriversSet);
        allDrivers = allDrivers.map(driver => existingDrivers.find(existingDriver => existingDriver.id === driver.id) || driver);
        this._allDrivers = ArrayUtils.sort(allDrivers, [x => !x.isActive, x => x.name]);
        this.fillCityDistrictDrivers();
    }

    public setDistricts(districts: District[]): void {
        this.districts = districts;
        this.fillDistrictByGroupCode(districts);
        this.fillOrdersByDistrictGroup(districts);
        this.fillDriverOrdersMap(districts);
        this.fillDriverColorMap(districts);
        this.setSelectedDistrictGroup(districts.length > 0 ? districts[0].group : undefined);
    }

    public setSelectedDistrictGroup(value: DistrictGroupCode | undefined): void {
        this._selectedDistrictGroup = value;
        this._selectedDistrict = undefined;
        this._selectedOrders = [];
    }

    public setLoading(value: boolean): void {
        this._isLoading = value;
    }

    public get cityDistrictDrivers(): {cityDistrict: string | undefined, drivers: Set<DraftDriver>}[] {
        return Array.from(this._cityDistrictDrivers)
            .map(i => ({cityDistrict: i[0], drivers: i[1]}))
            .sort((a, b) => {
                if (a.cityDistrict === undefined && b.cityDistrict === undefined) {
                    return 0;
                }
                if (a.cityDistrict === undefined) {
                    return 1;
                }
                if (b.cityDistrict === undefined) {
                    return -1;
                }

                return a.cityDistrict.localeCompare(b.cityDistrict);
            });
    }

    public setSelectedDistrict(district: District | undefined): void {
        this._selectedDriver = undefined;
        this._selectedOrders = [];
        this._selectedDistrict = this._selectedDistrict === district
            ? undefined
            : district;
    }

    public setSelectedDriver(driver: DraftDriver | undefined): void {
        this._selectedDriver = this._selectedDriver === driver
            ? undefined
            : driver;
    }

    public setSelectedOrders(orders: DraftOrder[]): void {
        this._selectedDistrict = undefined;
        this._selectedOrders = orders;
    }

    public setOrdersToDriver(driver: DraftDriver | undefined, orders: DraftOrder[]): void {
        const ordersByDriver = this.groupOrdersByDriver(orders);

        ordersByDriver.forEach((draftOrders, oldDriver) => {
            if (oldDriver) {
                const remainOrders = this.driverOrdersMap.get(oldDriver)?.filter(i => !orders.includes(i));
                this.driverOrdersMap.set(oldDriver, remainOrders || []);
            }
        })

        if (driver) {
            driver = this.requireDriver(driver);
            const currentOrders = this.driverOrdersMap.get(driver) || [];
            this.driverOrdersMap.set(driver, [...currentOrders, ...orders]);
        }
        for (const order of orders) {
            this._orderDriverMap.set(order, driver);
        }

        if (this._selectedDistrictGroup) {
            this._groupOrdersMap.set(
                this._selectedDistrictGroup,
                [...(this._groupOrdersMap.get(this._selectedDistrictGroup) || [])]
            )
        }
    }

    public setDriverToSelectedDistrict(driver: DraftDriver): void {
        if (!this._selectedDistrict) {
            return;
        }

        this._selectedDistrict.setDriver(driver);
        if (!this._selectedDistrict) {
            return;
        }

        const orders = this._districtOrdersMap.get(this._selectedDistrict);
        if (orders) {
            this.setOrdersToDriver(driver, orders);
        }
    }

    public removeDriverFromSelectedDistrict(driver: DraftDriver): void {
        if (!this._selectedDistrict) {
            return;
        }
        this._selectedDistrict.removeDriver(driver);
        const orders = this._districtOrdersMap.get(this._selectedDistrict);

        if (!orders) {
            return;
        }

        const selectedDistrictDriver = this._selectedDistrict.drivers.length > 0 ? this._selectedDistrict.drivers[0] : undefined;
        this.setOrdersToDriver(selectedDistrictDriver, orders);
    }

    public updateDistrictsColorByDriver(): void {
        this.districts.forEach((district: District) => {
            if (district.drivers.length === 0) {
                return;
            }

            const color = this.getDriverColor(district.drivers[0]);
            if (color) {
                district.setColor(color)
            }
        });
    }

    public get districtsGroups(): DistrictGroupCode[] {
        return Array.from(this._groupDistrictsMap.keys());
    }

    public get driverOrders(): [DraftDriver, DraftOrder[]][] {
        return ArrayUtils.sort(Array.from(this.driverOrdersMap)
            .filter(x => x[1].length > 0), (x) => x[0].name);
    }

    public get suspiciousOrders(): DraftOrder[] {
        const orderWithSuspiciousDriver: DraftOrder[] = [];
        for (const [driver, orders] of this.driverOrdersMap) {
            if (orders.length === 0) {
                continue;
            }
            let isDriverInDistrict = false;
            for (const district of this.districts) {
                if (district.drivers.length > 0 && district.drivers[0] === driver) {
                    isDriverInDistrict = true;
                    break;
                }
            }

            if (!isDriverInDistrict) {
                orderWithSuspiciousDriver.push(...orders);
            }
        }

        return orderWithSuspiciousDriver;
    }

    public get selectedDistrict(): District | undefined {
        return this._selectedDistrict;
    }

    public get selectedDriver(): DraftDriver | undefined {
        this._selectedDistrict = undefined;

        return this._selectedDriver;
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public get selectedDistrictGroup(): DistrictGroupCode | undefined {
        return this._selectedDistrictGroup;
    }

    public get selectedGroupDistricts(): District[] {
        if (!this._selectedDistrictGroup) {
            return [];
        }

        return this._groupDistrictsMap.get(this._selectedDistrictGroup) ?? [];
    }

    public get selectedGroupOrders(): DraftOrder[] {
        if (!this._selectedDistrictGroup) {
            return [];
        }

        return this._groupOrdersMap.get(this._selectedDistrictGroup) ?? [];
    }

    public get allDrivers(): DraftDriver[] {
        return this._allDrivers;
    }

    public get selectedOrdersDrivers(): { order: DraftOrder, driver: DraftDriver }[] {
        const res: { order: DraftOrder, driver: DraftDriver }[] = [];
        for (const order of this._selectedOrders) {
            const driver = this._orderDriverMap.get(order);
            if (driver) {
                res.push({ order, driver });
            }
        }

        return res;
    }

    public get ordersWithoutDriver(): DraftOrder[] {
        return  Array.from(this._orderDriverMap).filter(i => i[1] === undefined).map(i => i[0]);
    }

    public getDriverColor(driver: DraftDriver): string | undefined {
        if (!this._driverColorMap.has(driver)) {
            this._driverColorMap.set(driver, this._colorPicker.getNextColor())
        }

        return this._driverColorMap.get(driver);
    }

    public getGroupByOrder(order: DraftOrder): DistrictGroupCode {
        for (const [group, orders] of this._groupOrdersMap) {
            if (orders.some(o => o === order)) {
                return group;
            }
        }
        throw new Error('Заказа нет ни в одной группе');
    }

    public getOrderColor(order: DraftOrder): string | undefined {
        const driver = this.getOrderDriver(order);

        return driver ? this.getDriverColor(driver) : undefined;
    }

    public getOrderDriver(order: DraftOrder): DraftDriver | undefined {
        return this._orderDriverMap.get(order);
    }

    public getActualOrders(): DraftOrder[] {
        const car = new Car(0, '', 1);

        const orders: DraftOrder[] = [];
        this._orderDriverMap.forEach((driver, order) => {
            if (!driver) {
                throw new Error(`На заказ ${order.id} не назначен водитель`)
            }

            const tripExecutorDriver = new Driver(driver.id, '', '' , 1, false, undefined, undefined);
            const executor = new TripExecutor(car, tripExecutorDriver, undefined);
            order.setExecutor(executor)

            orders.push(order)
        })

        return orders;
    }

    public get validationError(): {title: string, value: District | DraftOrder} | undefined {
        const wrongDistrict = this.districts.find(d => d.drivers.length !== 1)
        if (wrongDistrict) {
            return {title: 'В районе должен быть один водитель.', value: wrongDistrict}
        }
        if (this.suspiciousOrders.length > 0) {
            return {title: 'Водитель с заказами должен быть включен в район.', value: this.suspiciousOrders[0]}
        }

        return undefined;
    }

    public showError(): District | DraftOrder | undefined {
        if (!this.validationError) {
            return undefined;
        }
        if (this.validationError.value instanceof District) {
            this.setSelectedDistrict(this.validationError.value);

            return this.validationError.value
        }
        this.setSelectedOrders([this.validationError.value]);

        return this.validationError.value
    }

    private fillDistrictByGroupCode(districts: District[]): void {
        const groupDistrictsMap = new Map<DistrictGroupCode, District[]>();
        for (const district of districts) {
            if (groupDistrictsMap.has(district.group)) {
                groupDistrictsMap.get(district.group)?.push(district)
            } else {
                groupDistrictsMap.set(district.group, [district])
            }
        }
        this._groupDistrictsMap = groupDistrictsMap;
    }

    private fillCityDistrictDrivers(): void {
        const cityDistrictDrivers = new Map<string | undefined, Set<DraftDriver>>();
        for (const district of this.districts) {
            const drivers = cityDistrictDrivers.get(district.cityDistrict);

            if (drivers) {
                for (const driver of district.drivers) {
                    drivers.add(driver)
                }
            } else {
                cityDistrictDrivers.set(district.cityDistrict, new Set<DraftDriver>(district.drivers))
            }
        }
        this._cityDistrictDrivers = cityDistrictDrivers;
    }

    private fillOrdersByDistrictGroup(districts: District[]): void {
        for (const district of districts) {
            if (!this._groupOrdersMap.has(district.group)) {
                this._groupOrdersMap.set(district.group, []);
            }
        }
        for (const order of this._orders) {
            const orderDistrictGroup = this.getOrderDistrictGroup(order);
            this._groupOrdersMap.get(orderDistrictGroup)?.push(order);
        }
    }

    private fillDriverOrdersMap(districts: District[]): void {
        const driverOrdersMap = new Map<DraftDriver, DraftOrder[]>();
        const orderDriverMap = new Map<DraftOrder, DraftDriver | undefined>();

        for (const order of this._orders) {
            let orderDistrict: District | undefined;
            const orderCoords = order.getCoordinates();

            if (!orderCoords) {
                continue;
            }

            const distances: number[] = [];
            for (const district of districts) {
                if (this.getOrderDistrictGroup(order) !== district.group) {
                    distances.push(Number.MAX_VALUE);
                    continue;
                }
                if (district.isPointInsideDistrict(orderCoords)) {
                    distances.push(Number.MAX_VALUE);
                    orderDistrict = district;
                    break;
                }

                const minDistanceToDistrict = district.findMinDistanceToDistrict(orderCoords);
                distances.push(minDistanceToDistrict);
            }

            if (!orderDistrict) {
                const minDistanceIndex = Math.min(...distances);
                const index = distances.indexOf(minDistanceIndex);
                orderDistrict = districts[index];
            }

            if (this._districtOrdersMap.get(orderDistrict)) {
                this._districtOrdersMap.get(orderDistrict)?.push(order)
            } else {
                this._districtOrdersMap.set(orderDistrict, [order])
            }

            if (!orderDistrict) {
                continue;
            }

            const firstDriver = orderDistrict.drivers[0];
            orderDriverMap.set(order, firstDriver);
            if (firstDriver) {
                const orders = driverOrdersMap.get(firstDriver);
                if (orders) {
                    orders.push(order);
                } else {
                    driverOrdersMap.set(orderDistrict.drivers[0], [order])
                }
            }
        }

        this.driverOrdersMap = driverOrdersMap;
        this._orderDriverMap = orderDriverMap;
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    private fillDriverColorMap(districts: District[]): void {
        const driverLatLon = new Map<DraftDriver, number[]>();
        for (const district of districts) {
            if (district.drivers.length === 0) {
                continue;
            }

            for (const driver of district.drivers) {
                const bbox = driverLatLon.get(driver) || [0, 0];
                const latitudes = district.geocodes.map(x => x.latitude);
                const longitudes = district.geocodes.map(x => x.longitude);

                bbox[0] = Math.max(bbox[0], Math.max(...latitudes));
                bbox[1] = Math.min(bbox[1], Math.min(...longitudes));

                driverLatLon.set(driver, bbox);
            }
        }

        const driverLatLonArr: any[] = [];
        driverLatLon.forEach((bbox: number[], driver: DraftDriver) => {
            driverLatLonArr.push([
                ...bbox,
                driver
            ]);
        });

        driverLatLonArr.sort((a, b) => b[0] - a[0] || a[1] - b[0]);

        const driverColorMap = new Map<DraftDriver, string>();
        for (const row of driverLatLonArr) {
            driverColorMap.set(row[2], this._colorPicker.getNextColor());
        }

        this._driverColorMap = driverColorMap;
    }
    /* eslint-enable @typescript-eslint/no-magic-numbers */


    private getOrderDistrictGroup(draftOrder: DraftOrder): DistrictGroupCode {
        if (this._groupOrdersMap.has(DistrictGroupCode.DayOff)) {
            return DistrictGroupCode.DayOff;
        }

        return draftOrder.departPeriod === "EVENING" && this._groupOrdersMap.has(DistrictGroupCode.Evening)
            ? DistrictGroupCode.Evening
            : DistrictGroupCode.Morning;
    }

    private groupOrdersByDriver(orders: DraftOrder[]): Map<DraftDriver | undefined, DraftOrder[]> {
        return ArrayUtils.groupBy(orders, (order) => this._orderDriverMap.get(order));
    }

    private requireDriver(driver: DraftDriver): DraftDriver {
        const foundDriver = this._allDrivers.find(d => d === driver);
        if (!foundDriver) {
            throw new Error('Driver not found');
        }

        return foundDriver;
    }
}