import * as msRest from '@azure/ms-rest-js';

import { ApiError } from '../../ApiClient/Yoso/models';
import { YosoAPI } from '../../ApiClient/Yoso/yosoAPI';
import { YosoAuthAPI } from '../../ApiClient/YosoAuth/yosoAuthAPI';
import { YosoCompanyAPI } from '../../ApiClient/YosoCompany/yosoCompanyAPI';
import { YosoDraftAPI } from '../../ApiClient/YosoDraft/yosoDraftAPI';
import { YosoGeocodingAPI } from '../../ApiClient/YosoGeocoding/yosoGeocodingAPI';
import { YosoStatsAPI } from '../../ApiClient/YosoStats/yosoStatsAPI';
import { AuthLocalStorageStorage } from '../../Components/AppProviders/Providers/AuthProvider/AuthStorage/AuthLocalStorageStorage';
import { IAuthStorage } from '../../Components/AppProviders/Providers/AuthProvider/AuthStorage/IAuthStorage';

import { UnauthorizedApiError } from './UnauthorizedApiError';

export interface IResponse {
    _response: msRest.HttpResponse;
}

export class Api {
    public static readonly authApi: YosoAuthAPI = new YosoAuthAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static readonly companyApi: YosoCompanyAPI = new YosoCompanyAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static readonly draftApi: YosoDraftAPI = new YosoDraftAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static readonly yosoApi: YosoAPI = new YosoAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static readonly geocodingApi: YosoGeocodingAPI = new YosoGeocodingAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static readonly statsApi: YosoStatsAPI = new YosoStatsAPI({
        baseUri: '/',
        noRetryPolicy: true,
    });

    public static async doRequest<TOkResponse>(getResponse: () => Promise<IResponse>): Promise<TOkResponse> {
        let response;
        try {
            response = await getResponse();
        } catch (error) {
            let errorMessage = '';
            if (error.statusCode !== undefined) {
                errorMessage += `${String(error.statusCode)}: `;
            }
            if (error.message !== undefined) {
                errorMessage += error.message;
            } else {
                errorMessage += Api.unknownErrorMessage;
            }

            throw new Error(errorMessage);
        }

        try {
            Api.handleUnauthorizedError(response);
        } catch (e) {
            if (e instanceof UnauthorizedApiError) {
                const authStorage: IAuthStorage = new AuthLocalStorageStorage();
                authStorage.user = undefined;
                document.location.href = '/login';
                throw e;
            }
        }
        Api.handleRuntimeError(response);

        return (response as unknown) as TOkResponse;
    }

    public static async doUnauthenticatedRequest<TOkResponse = never>(
        getResponse: () => Promise<IResponse>,
    ): Promise<TOkResponse> {
        const response = await getResponse();

        Api.handleUnauthorizedError(response);
        Api.handleRuntimeError(response);

        return (response as any) as TOkResponse;
    }

    public static getRequestOptions(): msRest.RequestOptionsBase {
        const authStorage: IAuthStorage = new AuthLocalStorageStorage();

        return authStorage.user === undefined
            ? {}
            : {
                  customHeaders: {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      'X-Yoso-Authorization': `Bearer ${authStorage.user.token}`,
                  },
              };
    }

    private static readonly unknownErrorMessage = 'Unknown error';

    private static handleRuntimeError(response: IResponse): void {
        // TODO: try use some mapper with `response._response.status` ?
        // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-magic-numbers
        if (response._response.status >= 400) {
            const errorResponse = response as ApiError;
            const errorMessage = errorResponse.message !== null ? errorResponse.message : Api.unknownErrorMessage;
            throw new Error(errorMessage);
        }
    }

    private static handleUnauthorizedError(response: IResponse): void {
        // TODO: try use some mapper with `response._response.status` ?
        // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-magic-numbers
        if (response._response.status === 401) {
            throw new UnauthorizedApiError();
        }
    }
}
