import React from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Pagination } from 'antd';
import moment from 'moment';
import { EditFilled } from '@ant-design/icons';
import { DeliveryApi } from '../../../../Backend/Api/Delivery/DeliveryApi';
import { WorkingDay } from '../../../../Model/WorkingDay/WorkingDay';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import { EntityListColumnAlign } from '../../../EntityList/EntityListBase';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './WorkingDayListRoute.css';
import { WorkingDayEditor } from '../../../WorkingDayEditor/WorkingDayEditor';

const today = new Date();

export const WorkingDayListRoute = withRouter(() => {
    const countElementsOnPage = 20;

    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [workingDays, setWorkingDays] = React.useState<WorkingDay[]>([]);
    const [workingDayToEdit, setWorkingDayToEdit] = React.useState(undefined as WorkingDay | undefined);

    React.useEffect(() => {
        getWorkingDays(countElementsOnPage, pageNumber);
    }, [pageNumber]);

    const getWorkingDays = async (size: number, page: number) => {
        try {
            const response = await DeliveryApi.getWorkingDays(size, (page - 1) * size);

            setTotalPages(response.total);
            setWorkingDays(
                ArrayUtils.sort(
                    response.workingDays.map((w) => w),
                    (w) => w.date.toISOString(),
                    false,
                    false,
                ),
            );
        } catch (e) {
            alert(e);
        }
    };

    return (
        <Page header="Рабочие дни">
            <div className="working-day-info">
                <Alert
                    message={
                        <>
                            ВЫХОДНОЙ ДЕНЬ - водители <b>не могут переносить</b> заказ на этот день
                        </>
                    }
                />
                <Alert
                    message={
                        <>
                            КОРОТКИЙ ДЕНЬ - <b>подсказки интервалов</b> высчитываются на основе нарезки районов{' '}
                            <b>короткого дня</b>
                        </>
                    }
                />
            </div>
            <Pagination
                showSizeChanger={false}
                current={pageNumber}
                pageSize={countElementsOnPage}
                total={totalPages}
                onChange={setPageNumber}
                className="working-day-list__pagination"
            />
            <EntityList<WorkingDay>
                data={workingDays}
                pageSize={countElementsOnPage}
                id={(workingDay) => workingDay.id}
                // actions={{
                //     item: {
                //         click: (id) => `/workingDays/${id}`,
                //     },
                // }}
                columns={[
                    {
                        key: 'date',
                        title: 'День',
                        render: (workingDay) => moment(workingDay.date).format('DD MMMM, ddd'),
                    },
                    {
                        key: 'notes',
                        title: '',
                        render: (workingDay) => {
                            const notes = [];
                            if (workingDay.isShort) {
                                notes.push('короткий день');
                            }

                            if (workingDay.isRestDay) {
                                notes.push('выходной день');
                            }

                            return notes.join(', ');
                        },
                    },
                    {
                        key: 'actions',
                        title: '',
                        render: (workingDay) => (
                            <Button
                                title="Редактировать"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setWorkingDayToEdit(workingDay);
                                }}
                            >
                                <EditFilled />
                            </Button>
                        ),
                        align: EntityListColumnAlign.Center,
                    },
                    {
                        key: 'createdAt',
                        title: 'Время создания',
                        render: (workingDay) => workingDay.createdAt?.replace(/T/, ' ').replace(/\..+/, ''),
                    },
                ]}
                rowClass={(entity: WorkingDay) => {
                    if (entity.isRestDay) {
                        return 'working-day-list__rest_day';
                    }

                    if (entity.isShort) {
                        return 'working-day-list__short_day';
                    }

                    if (entity.date.toDateString() === today.toDateString()) {
                        return 'working-day-list__today';
                    }

                    return '';
                }}
            />
            {workingDayToEdit !== undefined && (
                <WorkingDayEditor
                    workingDay={workingDayToEdit}
                    onCancel={() => setWorkingDayToEdit(undefined)}
                    onOk={() => window.location.reload()}
                />
            )}
        </Page>
    );
});
