import React from 'react';
import { Button } from 'antd';
import { RouteInfo } from '@/Components/RouteInfo/RouteInfo';
import { Leg } from '@/Map/Route/Leg/Leg';
import { Trip, TripStatus } from '@/Model';

interface TripRouteProps {
    trip: Trip;
    selectedOrderId?: number;
    isSelectionMode: boolean;
    setSelectMode: () => void;
    setTripItemIndex: (value: number) => void;
    setDriverReplacementMode: () => void;
}

export const TripRoute = (props: TripRouteProps) => {
    const {trip, selectedOrderId, setSelectMode, setTripItemIndex, setDriverReplacementMode} = props

    const selectedDistance =
        // tripItemIndex && trip.route.all[tripItemIndex] instanceof Leg
        //     ? (trip.route.all[tripItemIndex] as Leg)
        //     :
            undefined;

    const selectedPoint = trip.isRouteSet()
        ? trip.route.points.find(i => i.originalId === selectedOrderId)
        : undefined;

    const startOrderTransferring = React.useCallback(() => {
        setSelectMode();
    }, []);

    const handleSelectRouteInfoDistance = (item: Leg): void => {
        const itemIndex = (trip.route.all ?? []).findIndex(
            (element) => element !== undefined && element.id === item.id,
        );
        if (itemIndex > -1) {
            setTripItemIndex(itemIndex);
        }
    }

    return (
        <>
            <div
                className="trip-plan-realtime__buttons-route-info-container">
                <Button
                    type="link"
                    onClick={startOrderTransferring}
                >
                    Передать заказы
                </Button>
                {trip.status !== TripStatus.Done && trip.status !== TripStatus.InProgress && (
                    <Button
                        type="link"
                        onClick={setDriverReplacementMode}
                    >
                        Изменить водителя
                    </Button>
                )}
            </div>
            {trip.isRouteSet() &&
                <RouteInfo
                    route={trip.route}
                    onSelectDistance={handleSelectRouteInfoDistance}
                    onSelectPoint={setTripItemIndex}
                    selectedDistance={selectedDistance}
                    selectedPoint={selectedPoint}
                />
            }
        </>
    );
};
