import * as React from 'react';

export const VersionController = () => {
    const [currentVersion, setCurrentVersion] = React.useState(0);
    const [newVersion, setNewVersion] = React.useState(0);

    const oneMinuteInterval = 60000;

    const reloadPage = (): void => {
        window.location.reload();
    };

    React.useEffect(() => {
        const getVersion = async () => {
            const handleError = (error: any) => {
                console.log(error);

                return 0;
            };

            return fetch('/version.json')
                .then((res) => res.json())
                .then((resJson) => resJson.version)
                .catch(handleError);
        };

        const updateVersions = async () => {
            const updatedVersion = await getVersion();
            setNewVersion(updatedVersion);
            if (currentVersion === 0) {
                setCurrentVersion(updatedVersion);
            }
        };

        const interval = setInterval(() => {
            updateVersions();
            if (currentVersion !== newVersion) {
                setCurrentVersion(newVersion);
                reloadPage();
            }
        }, oneMinuteInterval);

        return (): void => {
            clearInterval(interval);
        };
    }, [newVersion, currentVersion]);

    return null;
};
