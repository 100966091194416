import { formatNumber } from './FormatNumber';
import { DateTime } from './DateTime';

const formatTimeComponent = (value: number): string =>
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    formatNumber(value).padStart(2, '0');

const adjustDateTimeForView = (date: DateTime): DateTime => {
    const d = date.toDate();
    d.setTime(d.getTime() + DateTime.millisecondsInSecond * DateTime.getCompanyAndLocalDiff());

    return DateTime.fromDate(d);
};

export const formatTime = (date: DateTime): string => {
    date = adjustDateTimeForView(date);
    const time = date.getTime();

    return `${formatTimeComponent(time.hours)}:${formatTimeComponent(time.minutes)}`;
};

export const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);

    return formatTime(DateTime.fromDate(date));
};

export const formatTimeSec = (date: DateTime): string => {
    date = adjustDateTimeForView(date);
    const time = date.getTime();

    return `${formatTimeComponent(time.hours)}:${formatTimeComponent(time.minutes)}:${formatTimeComponent(
        time.seconds,
    )}`;
};
