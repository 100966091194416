import { makeAutoObservable } from "mobx";
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { DistrictGroupCode } from "./DistrictGroupCode";
import {DistrictOrder} from './DistrictOrder';

export class District {
  public id: number;
  public color: string;
  public group: DistrictGroupCode;
  public coords: IGeocode[];
  public name: string;
  public drivers: Driver[];
  public orders: DistrictOrder[] = [];
  public info = {bottles: 0, nonBottles: 0};
  public cityDistrictName?: string;

  public constructor(id: number,
                     coords: IGeocode[],
                     name: string,
                     color: string,
                     group: DistrictGroupCode,
                     drivers: Driver[],
                     cityDistrictName?: string) {
    makeAutoObservable(this);
    this.id = id;
    this.coords = coords;
    this.drivers = drivers;
    this.group = group;
    this.color = color;
    this.name = name;
    this.cityDistrictName = cityDistrictName;
  }

  public setCityDistrictName(value: string | undefined): void {
    this.cityDistrictName = value !== '' ? value : undefined;
  }

  public getCityDistrictName(): string | undefined {
    return this.cityDistrictName;
  }

  public setColor(color: string): void {
    this.color = color;
  }

  public setCoords(coords: IGeocode[]): void {
    this.coords = coords;
  }

  public setOrder(order: DistrictOrder): void {
    this.info = {
      ...this.info,
      bottles: this.info.bottles + order.shipmentBottlesCount,
      nonBottles: this.info.nonBottles + order.shipmentNonBottlesCount
    }
    this.orders.push(order);
  }

  public clearOrders(): void {
    this.orders = [];
    this.info = {...this.info, bottles: 0, nonBottles: 0};
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string): void {
    this.name = value;
  }

  public setDrivers(drivers: Driver[]): void {
    this.drivers = drivers;
  }

  public isNew(): boolean {
    return this.id <= 0;
  }

  public getCentroid(): IGeocode | undefined {
    if (!this.coords.length) {
      return undefined;
    }

    const latSum = this.coords.map((x) => x.latitude).reduce((a, b) => a + b, 0);
    const lonSum = this.coords.map((x) => x.longitude).reduce((a, b) => a + b, 0);

    return {
      latitude: latSum / this.coords.length,
      longitude: lonSum / this.coords.length
    }
  }
}

export interface Driver {
  id: number;
  name: string;
  isActive: boolean;
}