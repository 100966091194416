import { Employee } from '@/Model/Employee/Employee';
import { makeAutoObservable, runInAction } from 'mobx';
import { CompanyApi } from '../../../Backend/Api/Company/CompanyApi';
import { DeliveryApi } from '../../../Backend/Api/Delivery/DeliveryApi';
import { DriverPlace } from '../../../Model/Place/DriverPlace';
import { ArrayUtils } from '../../../Std/ArrayUtils';

export class DriverPlaceStore {
    public drivers: Employee[] = [];
    public selectedDriverId: number | null = null;
    public loading = true;
    public driverPlaces: DriverPlace[] = [];
    public selectedDriverPlace: DriverPlace | null = null;
    public needMoveBounds = false;

    public constructor() {
        makeAutoObservable(this);
        this.fetchDrivers();
    }

    public setDrivers(drivers: Employee[]): void {
        this.drivers = drivers;
    }

    public setLoading(value: boolean): void {
        this.loading = value;
    }

    public selectDriver(id: number): void {
        if (this.selectedDriverId === id) {
            return;
        }
        this.needMoveBounds = true;
        this.selectedDriverId = id;
        this.selectedDriverPlace = null;
        this.fetchDriverPlaces();
    }

    public setSelectedDriverPlace(driverPlace: DriverPlace | null): void {
        this.selectedDriverPlace = driverPlace;
    }

    public unionDriverPlaces(idToDelete: number, idToUnion: number): void {
        this.setLoading(true);
        DeliveryApi.unionDriverPlace(idToDelete, idToUnion)
            .then(() => {
                this.fetchDriverPlaces();
            })
            .catch(alert)
            .finally(() => {
                this.setLoading(false);
            });
    }

    public deleteDriverPlace(): void {
        if (!this.selectedDriverPlace) {
            return;
        }

        this.setLoading(true)
        DeliveryApi.deleteDriverPlace(this.selectedDriverPlace.id)
            .then(() => {
                this.fetchDriverPlaces();
            })
            .catch(alert)
            .finally(() => {
                runInAction(() => this.selectedDriverPlace = null)
                this.setLoading(false);
            });
    }

    private fetchDrivers(): void {
        this.setLoading(true);
        CompanyApi.getDriverList()
            .then(response => {
                const sorted = ArrayUtils.sort(response, [
                    (d) => !d.driver.isActive,
                    (d) => d.driver.name
                ]);
                this.setDrivers(sorted)
            })
            .catch(alert)
            .finally(() => {
                this.setLoading(false);
            });
    }

    private fetchDriverPlaces(): void {
        if (!this.selectedDriverId) {
            return;
        }
        this.setLoading(true)
        DeliveryApi.getDriverPlaces(this.selectedDriverId)
            .then(r => runInAction(() => this.driverPlaces = r))
            .finally(() => {
                this.setLoading(false);
                this.needMoveBounds = false;
            });
    }
}