import { Input, Modal } from 'antd';
import * as React from 'react';

import './DeleteConfirmationDialog.css';

export interface IDeleteConfirmationDialogProps {
    getOnOkPromise?: () => undefined | Promise<undefined>;
    title?: string;
    visible: boolean;
    warningText?: string;
    onCancel(): void;
    onOk(): void;
}

export const DeleteConfirmationDialog = (props: IDeleteConfirmationDialogProps): JSX.Element => {
    const { getOnOkPromise, onOk } = props;

    const [inputValue, setInputValue] = React.useState('' as string);
    const [isOkAvailable, setIsOkAvailable] = React.useState(false as boolean);
    const [loading, setLoading] = React.useState(false as boolean);

    const input: React.MutableRefObject<Input | null> = React.useRef(null);

    const handleOk = React.useCallback(() => {
        if (!isOkAvailable) {
            return;
        }

        if (getOnOkPromise === undefined) {
            onOk();
        } else {
            const promise = getOnOkPromise();
            if (promise instanceof Promise) {
                setLoading(true);
                promise
                    .then(() => {
                        onOk();
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                onOk();
            }
        }
    }, [isOkAvailable, getOnOkPromise, onOk]);

    const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value);
    }, []);

    const handleInputKeyPress = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const enterKeyCode = 13;
            if (event.keyCode === enterKeyCode) {
                handleOk();
            }
        },
        [handleOk],
    );

    React.useEffect(() => {
        setInputValue('');

        // Focus input on second and next modal openings
        if (input.current !== null) {
            input.current.focus();
        }
    }, [props.visible]);

    React.useEffect(() => {
        setIsOkAvailable(inputValue.toLowerCase() === 'удалить');
    }, [inputValue]);

    return (
        <Modal
            title={props.title ?? 'Удаление'}
            visible={props.visible}
            // tslint:disable-next-line: no-unbound-method
            onOk={handleOk}
            // tslint:disable-next-line: no-unbound-method
            onCancel={props.onCancel}
            okText="Удалить"
            okButtonProps={{ disabled: !isOkAvailable }}
            cancelText="Отменить"
            confirmLoading={loading}
        >
            <div className="delete-confirmation-dialog__content">
                <p>
                    {props.warningText ? `${props.warningText} ` : ''}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Для подтверждения удаления введите "Удалить" в поле ввода.
                </p>
                <Input
                    ref={input}
                    // Focus input on first modal opening
                    autoFocus={true}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyPress}
                />
            </div>
        </Modal>
    );
};
