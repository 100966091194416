import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import { CompanyApiLogistResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './LogisticianListRoute.css';

export interface ILogisticianListRouteProps extends RouteComponentProps {}

export const LogisticianListRoute = withRouter(
    (props: ILogisticianListRouteProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [logisticians, setLogisticians] = React.useState([] as CompanyApiLogistResponse[]);

        const deleteLogistician = React.useCallback(
            (id: number): (() => Promise<undefined>) => async (): Promise<undefined> => {
                setLoading(true);

                try {
                    await CompanyApi.deleteLogistician(id);
                    const response = await CompanyApi.getLogisticianList();
                    setLogisticians(response);
                } catch (error) {
                    alert(String(error));
                }

                setLoading(false);

                return;
            },
            [],
        );

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getLogisticianList();
                    if (cancelled) {
                        return;
                    }

                    setLogisticians(response);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, []);

        return (
            <Page header="Логисты">
                <Link to="/logisticians/new">
                    <Button type="primary" className="logistician-list-route__button" icon={<PlusOutlined />}>
                        Добавить логиста
                    </Button>
                </Link>

                <EntityList<CompanyApiLogistResponse>
                    data={logisticians}
                    loading={loading}
                    id={(logistician) => logistician.employee!.id}
                    actions={{
                        item: {
                            click: (id) => `/logisticians/${id}`,
                            delete: {
                                action: deleteLogistician,
                                title: 'Удалить логиста',
                            },
                        },
                    }}
                    columns={[
                        {
                            key: 'name',
                            title: 'Имя',
                            render: (logistician) => logistician.employee!.name,
                        },
                        {
                            key: 'username',
                            title: 'Логин',
                            render: (logistician) => logistician.employee!.credentials!.username,
                        },
                    ]}
                />
            </Page>
        );
    },
);
