export class EntityListFilter {
    public static filter<TEntity>(
        data: ReadonlyArray<TEntity>,
        searchStringParts: ReadonlyArray<string>,
        selectValues: Record<string, string>,
        filterBySearchStringPart: ((entity: TEntity, searchStringPart: string) => boolean) | undefined,
        filterBySelectValue: ((entity: TEntity, selectKey: string, selectValue: string) => boolean) | undefined,
    ): TEntity[] {
        return data.filter((entity) => {
            if (filterBySearchStringPart !== undefined && searchStringParts.length > 0) {
                if (!searchStringParts.some((searchStringPart) => filterBySearchStringPart(entity, searchStringPart))) {
                    return false;
                }
            }

            if (filterBySelectValue !== undefined) {
                const selectValueKeys = Object.keys(selectValues);
                if (
                    !selectValueKeys.some((selectValueKey) =>
                        filterBySelectValue(entity, selectValueKey, selectValues[selectValueKey]),
                    )
                ) {
                    return false;
                }
            }

            return true;
        });
    }
}
