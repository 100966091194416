import { Role } from '../../ApiClient/YosoAuth/models';

export class UserRole {
    public static fromString(value: string): UserRole {
        const roleIndex = Object.values(UserRole.roleStringValues).indexOf(value);
        if (roleIndex === -1) {
            throw new Error(`Unknown role ${value.toString()}`);
        }

        return new UserRole(Object.keys(UserRole.roleStringValues)[roleIndex] as Role);
    }

    private static readonly roleStringValues: Record<Role, string> = {
        [Role.ROLEADMIN]: 'admin',
        [Role.ROLEDRIVER]: 'driver',
        [Role.ROLELOGISTICIAN]: 'logistician',
        [Role.ROLESUPERADMIN]: 'superAdmin',
    };

    private static validateRole(role: Role) {
        if (!Object.keys(UserRole.roleStringValues).includes(role)) {
            throw new Error(`Unknown role: ${role}`);
        }
    }

    public readonly role: Role;

    public constructor(role: Role) {
        UserRole.validateRole(role);

        this.role = role;
    }

    public equals(role: Role): boolean {
        UserRole.validateRole(role);

        return this.role === role;
    }

    public roleToString(): string {
        return UserRole.roleStringValues[this.role];
    }
}
