export class Basket {
    public id: number;
    public driverName: string;
    public departPeriod: string;
    public ordersCount: number;
    public index: number;

    public constructor(
        id: number,
        driverName: string,
        departPeriod: string,
        ordersCount: number,
        index: number
    ) {
        this.id = id;
        this.driverName = driverName;
        this.departPeriod = departPeriod;
        this.ordersCount = ordersCount;
        this.index = index;
    }
}