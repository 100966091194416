import { ProductOperation } from '../Model/ProductOperation/ProductOperation';

import { IFormatter } from './IFormatter';
import { productFormatter } from './ProductFormatter';
import { productOperationTypeFormatter } from './ProductOperationTypeFormatter';

export class ProductOperationFormatter implements IFormatter<ProductOperation> {
    public default(obj: ProductOperation): string {
        const type = productOperationTypeFormatter.default(obj.type);
        const product = productFormatter.default(obj.product);
        const count = obj.estimatedCount;
        const price = obj.price;

        return `[${price}] ${type}: ${product} x${count}`;
    }
}

export const productOperationFormatter = new ProductOperationFormatter();
