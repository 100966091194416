import React from 'react';
import {Moment} from 'moment';
import {observer} from 'mobx-react';
import {Input, Modal, Radio, RadioChangeEvent, Select, TimePicker} from 'antd';
import {LatLngExpression, LatLngTuple} from 'leaflet';
import {DeliveryApi} from '../../Backend/Api/Delivery/DeliveryApi';
import {RouteInfo} from '../../Backend/Api/Delivery/Model/RouteInfo/RouteInfo';
import {DriverPoint} from '../../Model/DriverPoint/DriverPoint';
import {DriverPointType} from '../../Model/DriverPoint/DriverPointType';
import {Geocode} from '../../Model/Geocode/Geocode';
import {TripComparisonStore} from '../../Store/TripComparisonStore/TripComparisonStore';
import DriverRouteEditor from '../DriverRouteEditor/DriverRouteEditor';
import WaypointsOnMap from '../DriverRouteEditor/WaypointsOnMap/WaypointsOnMap';
import MapContainer from '../MapContainer/MapContainer';
import {MapSource} from '../MapContainer/MapSource';
import SearchAddress from '../SearchAddress/SearchAddress';
import { TripComparisonOrder } from '../../Model/TripComparison/TripComparisonOrder';
import { ArrayUtils } from '../../Std/ArrayUtils';
import TripComparisonsRoutes
    from './TripComparisonsRoutes/TripComparisonsRoutes';
import styles from './TripComparisons.module.css';

interface TripComparisonsProps {
    tripId: number;
    center?: LatLngExpression;
    bounds?: LatLngTuple[];
    store: TripComparisonStore;
}

export const TripComparisons = observer((props: TripComparisonsProps) => {
    const {store, center, bounds} = props;

    const [routeEditingModal, setRouteEditingModal] = React.useState<boolean>(false);
    const [routeBuilding, setRouteBuilding] = React.useState<RouteInfo | undefined>(undefined);
    const [saving, setSaving] = React.useState<boolean>(false);

    const handleModalOk = () => {
        if (!store.routeEditing) {
            return;
        }

        if (store.routeEditing.validPreconditions()) {
            setRouteEditingModal(false);
        }
    }

    const handleRouteEditingCancel = () => {
        store.selectRouteEditing(undefined);
        setRouteEditingModal(false);
    }

    const handleConfirmOrder = async () => {
        if (!store.routeEditing) {
            return;
        }
        setSaving(true);
        await DeliveryApi.postTripComparisonRoute(
            props.tripId,
            store.routeEditing
        ).finally(() => setSaving(false));

        window.location.reload();
    }

    const handleBuildRoute = async (route: RouteInfo) => {
        setRouteBuilding(route)
    }

    const handleRouteBuildingCancel = () => {
        setRouteBuilding(undefined)
    }

    const handleFirstPointSelect = (value: string | null) => {
        if (value === null) {
            store.setFirstPoint(undefined);

            return;
        }
        const id = Number(value);
        const firstPoint: DriverPoint | TripComparisonOrder | undefined = id
            ? store.orders.find(x => x.id === id)
            : store.intermediatePoints.find(x => x.getTypeAndId() === value);
            
        store.setFirstPoint(firstPoint);
    }

    const handleRouteBuildingOk = async () => {
        if (!routeBuilding) {
            return;
        }
        setSaving(true)
        await DeliveryApi.buildTripComparisonRoute(
            props.tripId,
            routeBuilding.withDriverPlace,
            routeBuilding.withLoadingStore,
            routeBuilding.timeMatrixSource,
            (store.firstPoint instanceof TripComparisonOrder) ? store.firstPoint.id : store.firstPoint
        ).finally(() => {
            setSaving(false)
            setRouteBuilding(undefined)
        })

        window.location.reload()
    }

    const findOrCreateDriverPoint = (id: string | undefined, address: string, coords: Geocode, points: DriverPoint[]): DriverPoint => {
        let point : DriverPoint | undefined;
        if (id) {
            point = points.find(p => p.id === id);
        }
        if (!point) {
            point = new DriverPoint(undefined, DriverPointType.DriverPoint, address, coords);
        }

        return point;
    }

    const setStartPoint = (id: string | undefined, address: string, coords: Geocode) => {
        const startPoint = findOrCreateDriverPoint(id, address, coords, store.startPoints);
        store.routeEditing?.setStartPoint(startPoint);
    }

    const setFinishPoint = (id: string | undefined, address: string, coords: Geocode) => {
        const finishPoint = findOrCreateDriverPoint(id, address, coords, store.finishPoints);
        store.routeEditing?.setFinishPoint(finishPoint);
    }

    const setStartTime = (time: Moment | null) => {
        if (!time) {
            return;
        }
        store.routeEditing?.setStartTime(time)
    }

    const setLoadingStore = (id: string | undefined) => {
        if (!isNaN(Number(id))) {
            const loadingStore = store.stores.find(s => s.id === Number(id));
            if (!loadingStore) {
                return;
            }
            store.routeEditing?.setLoadingStore(loadingStore);
        }
    }

    const setLoadingCar = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!store.routeEditing) {
            return;
        }
        if (!isNaN(e.target.valueAsNumber)) {
            store.routeEditing.setCarCapacity(e.target.valueAsNumber);
        }
    }

    const onProviderChange = (e: RadioChangeEvent) => {
        store.isYandexRoutes = e.target.value;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.list}>
                    <div className={styles.listTop}>
                        <div className={styles.total}>
                            Точек: {store.orderDriverPlaces.length}, Заказов: {store.orders.length}
                        </div>
                        {/* <div className={styles.providers}>
                            <Radio.Group onChange={onProviderChange} value={store.isYandexRoutes}>
                                <Radio value={true}>Yandex</Radio>
                                <Radio value={false}>TGis</Radio>
                            </Radio.Group>
                        </div> */}
                    </div>
                    <TripComparisonsRoutes
                        store={store}
                        saving={saving}
                        handleConfirmOrder={handleConfirmOrder}
                        handleBuildRoute={handleBuildRoute}
                        enableModal={setRouteEditingModal}
                    />
                </div>
                {center && (
                    <div className={styles.map}>
                        <MapContainer
                            center={center}
                            bounds={bounds}
                            mapSource={MapSource.Yandex}
                        >
                            {!!store.routeEditing &&
                                <DriverRouteEditor
                                    store={store}
                                />
                            }
                            {!store.routeEditing && store.selectedRoute &&
                                <WaypointsOnMap
                                    route={store.routeBySequence}
                                />
                            }
                        </MapContainer>
                    </div>
                )}
            </div>
            {store.routeEditing &&
                <Modal
                    title='Настройки маршрута'
                    visible={routeEditingModal}
                    onCancel={handleRouteEditingCancel}
                    onOk={handleModalOk}
                >
                    <div className={styles.modalLabels}>
                        <label>
                            Начальная точка
                            <SearchAddress
                                value={store.routeEditing.startPoint}
                                addresses={store.startPoints}
                                onSelect={setStartPoint}
                            />
                        </label>
                        <label>
                            Конечная точка
                            <SearchAddress
                                value={store.routeEditing.finishPoint}
                                addresses={store.finishPoints}
                                onSelect={setFinishPoint}
                            />
                        </label>
                        <hr />
                        <label>
                            Время выезда
                            <TimePicker
                                format="HH:mm"
                                className={styles.timePicker}
                                value={store.routeEditing.startTime}
                                onSelect={setStartTime}
                            />
                        </label>
                        {store.routeEditing.selectedRoute.withLoadingStore &&
                            <>
                                <hr />
                                <label>
                                    Склад дозагрузки
                                    <SearchAddress
                                        addresses={store.stores.map(s => ({
                                            id: s.id.toString(),
                                            address: s.address,
                                            coords: s.coordinates
                                        }))}
                                        onSelect={setLoadingStore}
                                    />
                                </label>
                                <label>
                                    Загрузка машины
                                    <Input
                                        className={styles.loadingCar}
                                        type='number' value={store.routeEditing.carCapacity}
                                        onChange={setLoadingCar}
                                    /> бут.
                                </label>
                                <label>
                                    Общая загрузка {store.routeEditing.shipmentCount}
                                </label>
                            </>
                        }
                    </div>
                </Modal>
            }
            <Modal
                title='Настройки маршрута'
                visible={routeBuilding !== undefined}
                onCancel={handleRouteBuildingCancel}
                onOk={handleRouteBuildingOk}
            >
                <div className={styles.modalLabels}>
                    <label>
                        Начать
                        <Select
                            value={store.firstPoint?.address}
                            placeholder='Ввод адреса'
                            className={styles.buildSelect}
                            allowClear={true}
                            onSelect={handleFirstPointSelect}
                            onClear={() => handleFirstPointSelect(null)}
                            options={[
                                {
                                    label: 'Через',
                                    options: store.intermediatePoints.map(x =>
                                        ({label: x.address, value: x.getTypeAndId()}))
                                },
                                {
                                    label: 'С заказа',
                                    options: ArrayUtils.sort(store.orders, x => x.address).map(x =>
                                        ({label: `${x.shipmentCount} бут., ${x.address}`, value: x.id}))
                                }
                            ]}
                        />
                    </label>
                </div>
            </Modal>
        </div>
    );
});