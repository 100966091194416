import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import { CompanyApiAdminResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './AdminListRoute.css';

export interface IAdminListRouteProps extends RouteComponentProps {}

export const AdminListRoute = withRouter(
    (props: IAdminListRouteProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [admins, setAdmins] = React.useState([] as CompanyApiAdminResponse[]);

        const deleteAdmin = React.useCallback(
            (id: number): (() => Promise<undefined>) => async (): Promise<undefined> => {
                setLoading(true);

                try {
                    await CompanyApi.deleteAdmin(id);
                    const response = await CompanyApi.getAdminList();
                    setAdmins(response);
                } catch (error) {
                    alert(String(error));
                }

                setLoading(false);

                return;
            },
            [],
        );

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getAdminList();
                    if (cancelled) {
                        return;
                    }

                    setAdmins(response);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, []);

        return (
            <Page header="Администраторы">
                <Link to="/admins/new">
                    <Button type="primary" className="admin-list-route__button" icon={<PlusOutlined />}>
                        Добавить администратора
                    </Button>
                </Link>

                <EntityList<CompanyApiAdminResponse>
                    data={admins}
                    loading={loading}
                    id={(admin) => admin.employee!.id}
                    actions={{
                        item: {
                            click: (id) => `/admins/${id}`,
                            delete: {
                                action: deleteAdmin,
                                title: 'Удалить администратора',
                            },
                        },
                    }}
                    columns={[
                        {
                            key: 'name',
                            title: 'Имя',
                            render: (admin) => admin.employee!.name,
                        },
                        {
                            key: 'username',
                            title: 'Логин',
                            render: (admin) => admin.employee!.credentials!.username,
                        },
                    ]}
                />
            </Page>
        );
    },
);
