import {EntityType} from '../../../../../ApiClient/Yoso/models';
import {
    DriverPointType
} from '../../../../../Model/DriverPoint/DriverPointType';

export class DriverPointTypeDeserializer {
    public deserialize(value: EntityType): DriverPointType {
        switch (value) {
            case EntityType.STORE:
                return DriverPointType.Store;
            case EntityType.DRIVERPOINT:
                return DriverPointType.DriverPoint;
            default:
                throw new Error('unknown entity type')
        }
    }
}

export const driverPointTypeDeserializer = new DriverPointTypeDeserializer();