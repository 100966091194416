import { TripPointType } from './TripPointType';

export class TripPointId {
    public readonly id: number;
    public readonly type: TripPointType;

    public constructor(id: number, type: TripPointType) {
        this.id = id;
        this.type = type;
    }
}
