import React from 'react';
import {CloseOutlined, EditOutlined, SaveOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import {observer} from "mobx-react";
import {ArrayUtils} from '../../../../Std/ArrayUtils';
import {District} from '../../../../Store/District/District';
import {
  CityDistrict, DistrictStore
} from '../../../../Store/District/DistrictStore';
import DistrictListItem from '../../../DistrictListEditor/DistrictListItem';
import './DistrictList.css'

interface DistrictGroupProps {
  districtStore: DistrictStore;
  cityDistrict: CityDistrict;
  onDistrictRemove: (district: District) => Promise<void> | undefined;
}

const DistrictGroup = observer((props: DistrictGroupProps): JSX.Element => {
  const {
    districtStore,
    cityDistrict,
    onDistrictRemove
  } = props;

  const [name, setName] = React.useState<string>(cityDistrict.name);
  const [renameMode, setRenameMode] = React.useState(false);

  React.useEffect(() => {
    setRenameMode(false)
  }, [districtStore.selectedCityDistricts])

  const renameDistrict = (e: React.MouseEvent) => {
    e.stopPropagation();
    setRenameMode(true);
  }

  const save = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setRenameMode(false);

    districtStore.renameCityDistrict(cityDistrict.name, name);
  }

  const cancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setRenameMode(false);
    setName(props.cityDistrict.name || '');
  }

  const toggleVisibility = (value: string) => {
    if (!renameMode) {
      districtStore.toggleSelectedCityDistricts(value)
    }
  }

  return (
    <div key={cityDistrict.name}>
      <div
        className={`district-list__city-district ${districtStore.selectedCityDistricts.some(i => i === cityDistrict.name) 
            ? 'district-list__city-district_selected' : ''}`}
        onClick={() => toggleVisibility(cityDistrict.name)}
      >
        {!renameMode &&
          <>
            <div>{cityDistrict.name}</div>
            <button
              className='ant-btn ant-btn-icon-only'
              disabled={renameMode}
              onClick={renameDistrict}
            >
              <EditOutlined/>
            </button>
          </>
        }
        {renameMode &&
          <>
            <div className='district-list__city-district-name'>
              <Input
                value={name}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='district-list__city-district-buttons-group'>
              <button
                className='ant-btn ant-btn-icon-only'
                disabled={name.trim() === ''}
                onClick={save}
              >
                <SaveOutlined/>
              </button>
              <button
                className='ant-btn ant-btn-icon-only'
                onClick={cancel}
              >
                <CloseOutlined />
              </button>
            </div>
          </>
        }
      </div>
      {districtStore.selectedCityDistricts.some(d => d === cityDistrict.name) &&
        <div>
          {ArrayUtils.sort(cityDistrict.districts, d => d.name.toUpperCase()).map(d => (
            <DistrictListItem
              key={d.id}
              district={d}
              districtStore={districtStore}
              onDistrictRemove={onDistrictRemove}
            />))}
        </div>}
    </div>
  );
});

export default DistrictGroup;