import {
    DeliveryApiTripAnalysisSummary,
    TripAnalysisSummaryWorkingShift,
} from '../../../../../ApiClient/Yoso/models';
import { TripWorkingShift } from '../../../../../Model/Trip/TripWorkingShift';
import { TripAnalysisSummary } from '../../../../../Model/TripAnalysisSummary/TripAnalysisSummary';
import { Duration } from '../../../../../Std/Duration';
import { carDeserializer } from '../Car/CarDeserializer';
import { driverDeserializer } from '../Driver/DriverDeserializer';

export class TripAnalysisSummaryDeserializer {
    public deserialize(serializedObj: DeliveryApiTripAnalysisSummary): TripAnalysisSummary {
        return new TripAnalysisSummary(
            serializedObj.id,
            driverDeserializer.deserialize(serializedObj.driver),
            carDeserializer.deserialize(serializedObj.car),
            serializedObj.estimatedDistance,
            Duration.fromSeconds(serializedObj.estimatedDuration),
            serializedObj.changed,
            serializedObj.distance,
            Duration.fromSeconds(serializedObj.duration),
            serializedObj.actualCompleted,
            serializedObj.actualDistance,
            Duration.fromSeconds(serializedObj.actualDuration),
            serializedObj.arrivalLTMinus30,
            serializedObj.arrivalBtwMinus30Plus30,
            serializedObj.arrivalBtwPlus31Plus60,
            serializedObj.arrivalGTPlus60,
            serializedObj.completedOrdersCount,
            serializedObj.failedOrdersCount,
            serializedObj.rescheduledOrdersCount,
            this.deserializeWorkingShift(serializedObj.tripAnalysisSummaryWorkingShift)
        );
    }

    private deserializeWorkingShift(workingShift: TripAnalysisSummaryWorkingShift | undefined): TripWorkingShift | undefined {
        switch (workingShift) {
            case TripAnalysisSummaryWorkingShift.MORNING:
                return TripWorkingShift.MORNING
            case TripAnalysisSummaryWorkingShift.EVENING:
                return TripWorkingShift.EVENING
            case TripAnalysisSummaryWorkingShift.DAYOFF:
                return TripWorkingShift.DAYOFF
            default:
                return undefined;
        }
    }
}

export const tripAnalysisSummaryDeserializer = new TripAnalysisSummaryDeserializer();
