import { Type } from '../../../../../ApiClient/Yoso/models';
import { ProductOperationType } from '../../../../../Model/ProductOperation/ProductOperationType';
import { Types } from '../../../../../Std/Types';

export class ProductOperationTypeDeserializer {
    public deserialize(serializedObj: Type): ProductOperationType {
        return serializedObj === Type.RETURN
            ? ProductOperationType.Return
            : serializedObj === Type.SHIPMENT
            ? ProductOperationType.Shipment
            : Types.assertUnreachable(serializedObj);
    }

    public deserializeFromString(serializedObj: string): ProductOperationType {
        const result =
            serializedObj === 'RETURN'
                ? ProductOperationType.Return
                : serializedObj === 'SHIPMENT'
                ? ProductOperationType.Shipment
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown serialized ProductOperationType '${serializedObj?.toString()}'`);
        }

        return result;
    }
}

export const productOperationTypeDeserializer = new ProductOperationTypeDeserializer();
