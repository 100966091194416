import { makeAutoObservable } from "mobx";

export class DistrictInterval {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public allDays = [1, 2, 3, 4, 5, 6, 7];

    public key: number;
    public beginHour: number;
    public beginMinute: number;
    public endHour: number;
    public endMinute: number;
    private _daysOfWeek: number[];

    public constructor(beginHour: number,
                       beginMinute: number,
                       endHour: number,
                       endMinute: number,
                       daysOfWeek?: number[]) {
        makeAutoObservable(this);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.key = beginHour * 1e6 + beginMinute * 1e4 + endHour * 1e2 + endMinute;
        this.beginHour = beginHour;
        this.beginMinute = beginMinute;
        this.endHour = endHour;
        this.endMinute = endMinute;
        this._daysOfWeek = daysOfWeek
            ? daysOfWeek.filter(i => this.allDays.includes(i))
            : this.allDays;
    }

    public formatTime(): string {
        const pad = 2;
        const bh = this.beginHour.toString().padStart(pad, '0');
        const bm = this.beginMinute.toString().padStart(pad, '0');
        const eh = this.endHour.toString().padStart(pad, '0');
        const em = this.endMinute.toString().padStart(pad, '0');

        return `${bh}:${bm} - ${eh}:${em}`;
    }

    public updateDaysOfWeek = (days: number[]) => {
        this._daysOfWeek = days;
    }

    public get daysOfWeek() {
        return this._daysOfWeek || this.allDays;
    }
}
