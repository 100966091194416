import * as React from 'react';
import {Role} from "../../../ApiClient/YosoAuth/models";
import {CompanyApi} from '../../../Backend/Api/Company/CompanyApi';
import {Company} from '../../../Model/Company/Company';
import {IGeocode} from '../../../Model/Geocode/IGeocode';
import {DateTime} from '../../../Std/DateTime';
import {useUser} from './UserProvider';

export const CompanyContext = React.createContext({
    company: undefined as Company | undefined,
    center: undefined as IGeocode | undefined
});

export interface ICompanyProviderProps {}

export const CompanyProvider = (props: ICompanyProviderProps) => {
    const [company, setCompany] = React.useState(undefined as Company | undefined);

    const user = useUser().user;

    React.useEffect(() => {
        if (user !== undefined && !user.role.equals(Role.ROLESUPERADMIN)) {
            CompanyApi.getMyCompany().then(
                (response) => {
                    setCompany(response.company);

                    if (response.company.timeZoneOffset) {
                        // В Date, например, зона "+03:00" возвращает значени -180, отрицательное
                        const companyAndLocalDiff = response.company.timeZoneOffset + (new Date()).getTimezoneOffset();
                        DateTime.setCompanyAndLocalDiff(DateTime.secondsInMinute * companyAndLocalDiff);
                    }
                },
                () => {
                    //
                },
            );
        }
    }, [user]);

    return (
        <CompanyContext.Provider
            value={{
                company: company,
                center: company
                  ? {latitude: company.centerLatitude, longitude: company.centerLongitude}
                  : undefined
            }}
            {...props}
        />
    );
};

export const useCompany = () => React.useContext(CompanyContext);
