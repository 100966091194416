import React from 'react'
import { GatewayOutlined } from '@ant-design/icons';
import { Tooltip } from 'react-leaflet';
import Polygon from '../MapContainer/components/Polygon';
import { Polygon as PolygonModel } from '../MapContainer/model/Polygon';
import MapControl from '../MapContainer/MapControl';
import styles from './DistrictsOnMap.module.css';

interface DistrictsOnMapProps {
    polygons: ReadonlyArray<PolygonModel>;
}

export const DistrictsOnMap: React.FC<DistrictsOnMapProps> = (props) => {
    const [isDistrictsVisible, setIsDistrictsVisible] = React.useState(true);

    return (
        <>
            {props.polygons.length > 0 &&
                <MapControl position='topleft' separate={true}>
                    <a
                        className={styles.icon}
                        onClick={() => setIsDistrictsVisible(!isDistrictsVisible)}
                    >
                        <GatewayOutlined className={isDistrictsVisible ? styles.active : ''} />
                    </a>
                </MapControl>
            }
            {isDistrictsVisible && props.polygons.map(p =>
                <Polygon
                    key={p.name}
                    coords={p.geocodes}
                    color={p.color}
                    isSelected={p.isSelected}
                    onClick={() => p.onClick && p.onClick()}
                >
                    {p.name &&
                        <Tooltip>
                            {p.name}
                        </Tooltip>
                    }
                </Polygon>)}
            {props.children}
        </>
    )

}