import { Time } from './Time';

export class Duration {
    public static fromSeconds(seconds: number): Duration {
        let remainingSeconds = seconds;

        const millisecondsInHour = Time.secondsInMinute * Time.minutesInHour;

        const hours = Math.floor(remainingSeconds / millisecondsInHour);
        remainingSeconds -= hours * millisecondsInHour;

        const minutes = Math.floor(remainingSeconds / Time.secondsInMinute);
        remainingSeconds -= minutes * Time.secondsInMinute;

        return new Duration(hours, minutes, remainingSeconds);
    }

    public readonly hours: number;
    public readonly milliseconds: number;
    public readonly minutes: number;
    public readonly seconds: number;

    public constructor(hours: number, minutes: number = 0, seconds: number = 0, milliseconds: number = 0) {
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.milliseconds = milliseconds;
    }

    public compareTo(another: Duration): number {
        if (this.hours > another.hours) {
            return 1;
        }
        if (this.hours < another.hours) {
            return -1;
        }
        if (this.minutes > another.minutes) {
            return 1;
        }
        if (this.minutes < another.minutes) {
            return -1;
        }
        if (this.seconds > another.seconds) {
            return 1;
        }
        if (this.seconds < another.seconds) {
            return -1;
        }
        if (this.milliseconds > another.milliseconds) {
            return 1;
        }
        if (this.milliseconds < another.milliseconds) {
            return -1;
        }

        return 0;
    }

    public toSeconds(): number {
        return (
            this.hours * Time.minutesInHour * Time.secondsInMinute + this.minutes * Time.secondsInMinute + this.seconds
        );
    }
}
