import { PaidMethod } from '../../../../../ApiClient/Yoso/models';
import { OrderPaidMethod } from '../../../../../Model/Order/OrderPaidMethod';
import { Types } from '../../../../../Std/Types';

export class OrderPaidMethodDeserializer {
    public deserialize(serializedObj: PaidMethod): OrderPaidMethod {
        return serializedObj === PaidMethod.CASH
            ? OrderPaidMethod.Cash
            : serializedObj === PaidMethod.QR
            ? OrderPaidMethod.Qr
            : serializedObj === PaidMethod.TERMINAL
            ? OrderPaidMethod.Terminal
            : Types.assertUnreachable(serializedObj);
    }
}

export const orderPaidMethodDeserializer = new OrderPaidMethodDeserializer();
