import React from 'react'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import { PlanStore } from '../../Store/PlanStore/PlanStore';
import { RoutePoint } from '../../Map/Route/RoutePoint/RoutePoint';
import { StartRoutePoint } from '../../Map/Route/RoutePoint/StartRoutePoint';
import { FinishRoutePoint } from '../../Map/Route/RoutePoint/FinishRoutePoint';
import { OrderRoutePoint } from '../../Map/Route/RoutePoint/OrderRoutePoint';
import { LunchRoutePoint } from '../../Map/Route/RoutePoint/LunchRoutePoint';
import {RouteInfoLunchPoint} from './RouteInfoPointType/RouteInfoLunchPoint';
import {RouteInfoStartPoint} from './RouteInfoPointType/RouteInfoStartPoint';
import { RouteInfoFinishPoint } from './RouteInfoPointType/RouteInfoFinishPoint';
import RouteInfoOrderPoint from './RouteInfoPointType/RouteInfoOrderPoint';
import './RouteInfo2.css'

interface RouteInfo {
    planStore: PlanStore;
}

const RouteInfo2 = observer((props: RouteInfo) => {
    const [routePoints, setRoutePoints] = React.useState<ReadonlyArray<RoutePoint> | undefined>(props.planStore.selectedRoute?.routePoints);
    const [firstScheduledOrderIndex, setFirstScheduledOrderIndex] = React.useState(undefined as number | undefined);

    React.useEffect(() => {
        setRoutePoints(props.planStore.selectedRoute?.routePoints)
    }, [props.planStore.selectedRoute, props.planStore.selectedTripItemIndex]);

    React.useEffect(() => {
        if (!routePoints) {
            return undefined;
        }

        const allPoints = [props.planStore.selectedRoute?.startPoint, ...routePoints, props.planStore.selectedRoute?.finishPoint];

        if (!allPoints) {
            return undefined;
        }

        const foundFirstScheduledOrderIndex = allPoints.findIndex((point) => point instanceof OrderRoutePoint && point.order.canBeDone());

        setFirstScheduledOrderIndex(foundFirstScheduledOrderIndex !== -1 ? foundFirstScheduledOrderIndex : undefined)

    }, [routePoints]);

    const handleOnDragEnd = React.useCallback(async (result: DropResult) => {
        if (!routePoints || !result.destination) {
            return;
        }

        const items = Array.from(routePoints);
        const [reorderedItem] = items.splice(result.source.index, 1);

        if (result.destination) {
            items.splice(result.destination.index, 0, reorderedItem);
            setRoutePoints(items);
            props.planStore.reorderLegsInSelectedTrip(items)
                .catch(() => {
                    setRoutePoints(routePoints);
                });
        }
    }, [routePoints]);

    const onClick = (index: number) => {
        if (props.planStore.selectedTripId) {
            props.planStore.updateSelectedTripItemIndex(props.planStore.selectedTripId, index);
        }
    }

    const renderPointDetails = (point?: RoutePoint, index?: number, dragHandleProps?: DraggableProvidedDragHandleProps): JSX.Element => {
        const scheduled = index !== undefined && index + 1 === firstScheduledOrderIndex;

        return (
            <>
                {point instanceof StartRoutePoint && <RouteInfoStartPoint point={point} />}
                {point instanceof FinishRoutePoint && <RouteInfoFinishPoint point={point} />}
                {point instanceof OrderRoutePoint
                    && <RouteInfoOrderPoint point={point} dragHandleProps={dragHandleProps!} scheduled={scheduled} />}
                {point instanceof LunchRoutePoint
                    && <RouteInfoLunchPoint point={point} dragHandleProps={dragHandleProps!} />}
            </>
        )
    }

    return (
        <>
            <div className="route-info-block">{renderPointDetails(props.planStore.selectedRoute?.startPoint)}</div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="points">
                    {(provided) => (
                        <div className="route-info__points-list" {...provided.droppableProps} ref={provided.innerRef}>
                            {routePoints?.map((point, index) => (
                                <Draggable
                                    key={point.id}
                                    draggableId={point.id}
                                    index={index}
                                    isDragDisabled={point instanceof OrderRoutePoint && !point.order.canBeDone()}
                                >
                                    {(provided2) => (
                                        <div ref={provided2.innerRef} {...provided2.draggableProps}
                                            onClick={() => onClick(index)}
                                            className={
                                                index === props.planStore.selectedTripItemIndex
                                                    ? "route-info-block route-info-block_active"
                                                    : "route-info-block"
                                            }
                                        >
                                            {renderPointDetails(point, index, provided2.dragHandleProps)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="route-info-block">{renderPointDetails(props.planStore.selectedRoute?.finishPoint)}</div>
        </>
    )
})

export default RouteInfo2