import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ArrayUtils } from '../../../Std/ArrayUtils';
import { Page } from '../../Page/Page';
import { RoutedTabs } from '../../RoutedTabs/RoutedTabs';
import { DistrictGroupCode } from '../../../Store/District/DistrictGroupCode';
import { CompanyApi } from '../../../Backend/Api/Company/CompanyApi';
import DistrictList from './DistrictList/DistrictList';
import ServiceZone from './ServiceZone/ServiceZone';

export interface IZoneRouteRouteProps extends RouteComponentProps {}

export const ZoneRoute = withRouter(
    (props: IZoneRouteRouteProps): JSX.Element => {
        const urlParams = new URLSearchParams(window.location.search);

        const queryDateStart = urlParams.get('dateStart');
        const queryDateEnd = urlParams.get('dateEnd');

        const datePeriodStart = queryDateStart ? new Date(queryDateStart.slice(0)) : null;
        const datePeriodEnd = queryDateEnd ? new Date(queryDateEnd.slice(0)) : null;

        const setDatePeriod = (newDateStart: Date, newDateEnd: Date) => {
            window.location.replace(
                `${props.history.location.pathname}?dateStart=${encodeURIComponent(
                    newDateStart.toISOString(),
                )}&dateEnd=${encodeURIComponent(newDateEnd.toISOString())}`,
            );
        };

        const [loaded, setLoaded] = React.useState<boolean>(false);
        const [morningOrdersGroup, setMorningOrdersGroup] = React.useState<string>(DistrictGroupCode.Morning);

        React.useEffect(() => {
            setLoaded(false);
            CompanyApi.eveningDistrictsExists()
                .then((exist) => {
                    if (!exist) {
                        setMorningOrdersGroup('');
                    }
                })
                .finally(() => {
                    setLoaded(true);
                });
        }, []);

        return (
            <Page header="Зоны">
                {loaded && (
                    <RoutedTabs
                        className="fullheight"
                        tabs={ArrayUtils.defined([
                            {
                                title: 'День',
                                url: `/zone/districts`,
                                render: () => (
                                    <DistrictList
                                        group={DistrictGroupCode.Morning}
                                        ordersGroup={morningOrdersGroup}
                                        dateStart={datePeriodStart}
                                        dateEnd={datePeriodEnd}
                                        onChangeDate={setDatePeriod}
                                    />
                                ),
                                cache: true,
                            },
                            {
                                title: 'Вечер',
                                url: `/zone/districts/evening`,
                                render: () => (
                                    <DistrictList
                                        group={DistrictGroupCode.Evening}
                                        dateStart={datePeriodStart}
                                        dateEnd={datePeriodEnd}
                                        onChangeDate={setDatePeriod}
                                    />
                                ),
                                cache: true,
                            },
                            {
                                title: 'Короткий день',
                                url: `/zone/districts/day-off`,
                                render: () => (
                                    <DistrictList
                                        group={DistrictGroupCode.DayOff}
                                        dateStart={datePeriodStart}
                                        dateEnd={datePeriodEnd}
                                        onChangeDate={setDatePeriod}
                                    />
                                ),
                                cache: true,
                            },
                            {
                                title: 'Зона обслуживания',
                                url: '/zone/service-zone',
                                render: () => <ServiceZone />,
                                cache: true,
                            },
                        ])}
                    />
                )}
            </Page>
        );
    },
);
