import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import { BasketsStore } from '../../../../Store/BasketsStore/BasketsStore';
import { useCompany } from '../../../AppProviders/Providers/CompanyProvider';
import Basket from '../../../Basket/Basket';
import ImportOrderAddressList from '../../../ImportOrderAddressList/ImportOrderAddressList';
import MapContainer from '../../../MapContainer/MapContainer';
import { RoundRobinColorPicker } from '../../../MapContainer/model/RoundRobinColorPicker';
import { Page } from '../../../Page/Page';
import { PointsOnMap } from '../../../PointsOnMap/PointsOnMap';
import { RectanglesOnMap } from '../../../RectanglesOnMap/RectanglesOnMap';
import './WorkingDayItemViewRoute.css';
import { ImportOrder } from '../../../../Model/ImportOrder/ImportOrder';

interface WorkingDayItemViewRouteProps extends RouteComponentProps {
    workingDayId: number;
}

export const WorkingDayItemViewRoute = withRouter(
    observer(({ workingDayId }: WorkingDayItemViewRouteProps) => {
        const { center } = useCompany();

        const basketsStore = React.useMemo(() => new BasketsStore(), []);
        const colorPalette = React.useMemo(() => new RoundRobinColorPicker(), []);

        React.useEffect(() => {
            basketsStore.setBasketsByWorkingDayId(workingDayId);
        }, [workingDayId]);

        return (
            <Page header={`День ${basketsStore.date ? moment(basketsStore.date.toDate()).format('D MMMM') : ''}`}>
                <div className="working-day-item">
                    <div className="working-day-item__basket-list">
                        {basketsStore.baskets.map((b, index) => (
                            <React.Fragment key={b.id}>
                                <Basket
                                    basket={b}
                                    color={colorPalette.getColorByIndex(index)}
                                    onClick={() => basketsStore.setSelectedBasket(b)}
                                />
                                {b.id === basketsStore.selectedBasket?.id && basketsStore.orders.length > 0 && (
                                    <ImportOrderAddressList
                                        orders={ArrayUtils.sort(basketsStore.orders, (o) => o.address)}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    {center && (
                        <MapContainer
                            center={[center.latitude, center.longitude]}
                            bounds={
                                basketsStore.ordersBounds
                                    ? basketsStore.ordersBounds.getLatLngBoundsExpression()
                                    : undefined
                            }
                            className="working-day-item__map-container"
                        >
                            <RectanglesOnMap
                                rectangles={basketsStore.ordersBoundingBoxes.map((bbox) => ({
                                    p1: bbox[0],
                                    p2: bbox[1],
                                    color: 'green',
                                }))}
                            />
                            <PointsOnMap<ImportOrder>
                                points={ArrayUtils.defined(basketsStore.orders.map((o) => (o.coords ? o : undefined)))}
                                getCoordinate={(o: ImportOrder) => o.coords!}
                                getTooltip={(o: ImportOrder) => o.address}
                                getColor={() =>
                                    basketsStore.getSelectedBasketIndex === undefined
                                        ? undefined
                                        : colorPalette.getColorByIndex(basketsStore.getSelectedBasketIndex)
                                }
                                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                unionDistance={2e-4}
                            />
                        </MapContainer>
                    )}
                </div>
            </Page>
        );
    }),
);
