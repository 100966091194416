import { DealType } from '../../../../../ApiClient/Yoso/models';
import { ProductOperationDealType } from '../../../../../Model/ProductOperation/ProductOperationDealType';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class ProductOperationDealTypeSerializer implements IApiModelSerializer<ProductOperationDealType, DealType> {
    public serialize(obj: ProductOperationDealType): DealType {
        const result =
            obj === ProductOperationDealType.Renting
                ? DealType.RENTING
                : obj === ProductOperationDealType.Selling
                ? DealType.SELLING
                : undefined;
        if (result === undefined) {
            throw new Error(`Unknown ProductOperationDealType '${obj?.toString()}'`);
        }

        return result;
    }
}

export const productOperationDealTypeSerializer = new ProductOperationDealTypeSerializer();
