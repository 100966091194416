import React, { useEffect, useState } from 'react';
import { DeliveryApi } from '../../../../Backend/Api/Delivery/DeliveryApi';
import {
    DriverCoefficientOffer
} from '../../../../Model/DriverCoefficientOffer/DriverCoefficientOffer';
import { ArrayUtils } from '../../../../Std/ArrayUtils';
import { EntityList } from '../../../EntityList/EntityList';

export const DriverCoefficientsOffers = () => {
    const [offers, setOffers] = useState<DriverCoefficientOffer[]>([])

    useEffect(() => {
        DeliveryApi.getDriverCoefficientsOffers()
            .then(res => setOffers(ArrayUtils.sort(res, by => by.driverName)))
    }, [])

    return (
        <EntityList<DriverCoefficientOffer>
            columns={[
                {
                    key: 'name',
                    title: 'Водитель',
                    render: (offer) => offer.driverName,
                },
                {
                    key: 'currentDriverProficiency',
                    title: 'Текущий коэф. движения',
                    render: (offer) => offer.currentDriverProficiency,
                },
                {
                    key: 'currentUnloadingTimeFactor',
                    title: 'Текущий коэф. разгрузки',
                    render: (offer) => offer.currentUnloadingTimeFactor,
                },
                {
                    key: 'offerDriverProficiency',
                    title: 'Предложенный коэф. движения',
                    render: (offer) => offer.offerDriverProficiency,
                },
                {
                    key: 'offerUnloadingTimeFactor',
                    title: 'Предложенный коэф. разгрузки',
                    render: (offer) => offer.offerUnloadingTimeFactor,
                },
            ]}
            data={offers}
            id={(offer) => offer.driverId}
            actions={{
                item: {
                    click: (id) => `/drivers/${id}`
                },
            }}
        />
    );
};
