import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Page } from '../../Page/Page';

export interface IAccessDeniedRouteProps extends RouteComponentProps {}

export const AccessDeniedRoute = withRouter(
    (props: IAccessDeniedRouteProps): JSX.Element => <Page header="Доступ запрещён">:(</Page>,
);
