import { Lunch } from '../../../Model/Lunch/Lunch';
import { TripPointType } from '../../../Model/TripPointId/TripPointType';
import {DateTime} from "../../../Std/DateTime";
import { RoutePoint } from './RoutePoint';

export class LunchRoutePoint extends RoutePoint {
    public readonly expectedDistanceTo: number | undefined;
    public readonly lunch: Lunch;

    public constructor(id: string, lunch: Lunch, expectedDistanceTo: number | undefined, expectedDateTimeFrom: DateTime | undefined) {
        super(
            lunch.id,
            id,
            TripPointType.LunchPoint,
            lunch.geocode,
            undefined,
            lunch.address,
            lunch.expectedArrivalDateTime,
            expectedDateTimeFrom,
            lunch.expectedArrivalDateTime,
            lunch.desiredDateTimePeriod,
            false
        );

        this.lunch = lunch;
        this.expectedDistanceTo = expectedDistanceTo;
    }
}
