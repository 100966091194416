import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { List, Modal, Popover } from 'antd';
import styles from './DropdownList.module.css'

interface DropdownListProps<T extends unknown> {
    list: T[];
    getItem: (i: T) => string;
    getClassname?: (i: T) => string;
    onSelect: (i: T) => void;
    children: React.ReactNode;
}

const DropdownList = <T extends unknown>(props: DropdownListProps<T>): JSX.Element => {
    const [open, setOpen] = React.useState(false);

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const click = (e: any, item: T) => {
        e.stopPropagation();
        setOpen(false);

        Modal.confirm({
            title: 'Подтвердите выбор',
            icon: <ExclamationCircleOutlined />,
            okText: 'Да',
            okType: 'primary',
            cancelText: 'Нет',
            onOk: () => {
                props.onSelect(item);
            },
            onCancel: () => {
                /* */
            },
        });
    };

    return (
        <Popover
            trigger="click"
            visible={open}
            onVisibleChange={handleOpenChange}
            content={
                <List
                    size='small'
                    className={styles.list}
                    dataSource={props.list}
                    renderItem={(item) => (
                        <List.Item
                            className={`${styles.item } ${ props.getClassname ? props.getClassname(item) : ''}`}
                            onClick={e => click(e, item)}
                        >
                            {props.getItem(item)}
                        </List.Item>
                    )}
                />
            }
        >
            {props.children}
        </Popover>
    );
};

export default DropdownList;