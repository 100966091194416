import React from 'react';
import { Select, Spin } from 'antd';
import { DeliveryApi } from '@/Backend/Api/Delivery/DeliveryApi';
import { debounce } from '@/helpers/debounce/debounce';
import OrderSearchDetailsOption
    from '../OrderSearchDetailsOption/OrderSearchDetailsOption';

interface PlanOrderSearcherProps {
    planId: number;
    onSelectTrip: (tripId: number) => void;
    onSelectOrder: (orderId: number) => void;
}

export const PlanOrderSearcher = (props: PlanOrderSearcherProps): JSX.Element => {
    const minSearchTextLength = 3;
    const debounceTimeout = 500;

    const [data, setData] = React.useState<any[]>([]);
    const [value, setValue] = React.useState<string | undefined>(undefined);
    const [fetching, setFetching] = React.useState(false);

    const getOrders = (searchText: string) => {
        setFetching(true)
        DeliveryApi.searchOrders(props.planId, searchText).then(res => {
            const options = res.map(i => ({
                label: <OrderSearchDetailsOption order={i} />,
                value: `Заказ - ${i.orderId} Маршрут - ${i.tripId}`
            }))
            setData(options);
        }).finally(() => setFetching(false))
    }

    const debounceFetchOrders = debounce(getOrders, debounceTimeout)

    const handleSearch = (newValue: string) => {
        if (newValue.length > minSearchTextLength) {
            debounceFetchOrders(newValue)
        }
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };

    const handleSelect = (text: string, option: any) => {
        setValue('');
        setData([])
        props.onSelectTrip(option.label.props.order.tripId);
        props.onSelectOrder(option.label.props.order.orderId);
    }

    return (
        <Select
            showSearch
            value={value}
            placeholder={'Введите адрес'}
            style={{width: '95%'}}
            defaultActiveFirstOption={false}
            optionLabelProp="label"
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onSelect={handleSelect}
            onBlur={() => setData([])}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={data}
        />
    );
};
