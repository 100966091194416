import * as L from 'leaflet';

import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { IMapRendererPolygon } from '../IMapRendererPolygon';

export class LeafletPolygon implements IMapRendererPolygon {
    public readonly points: ReadonlyArray<IGeocode>;

    private map: L.Map | undefined;
    private readonly polygon: L.Polygon;
    private visibility = true;

    public constructor(points: ReadonlyArray<IGeocode>, color: string, width: number) {
        this.points = points;

        this.polygon = L.polygon(points.map((point: IGeocode) => [point.latitude, point.longitude]));

        this.setColor(color);
        this.setWidth(width);
    }

    public addTo(map: L.Map): void {
        if (this.map !== undefined) {
            return;
        }

        this.map = map;
        this.polygon.addTo(this.map);
    }

    public remove(): void {
        if (this.map === undefined) {
            return;
        }

        this.polygon.remove();
        this.map = undefined;
    }

    public setColor(color: string): void {
        this.polygon.setStyle({
            color: color,
            fillOpacity: 0.4
        });
    }

    public setVisibility(visibility: boolean): void {
        if (this.map === undefined) {
            return;
        }

        if (visibility !== this.visibility) {
            if (visibility) {
                this.polygon.addTo(this.map);
            } else {
                this.polygon.removeFrom(this.map);
            }
            this.visibility = visibility;
        }
    }

    public setWidth(width: number): void {
        this.polygon.setStyle({
            weight: width,
        });
    }
}
