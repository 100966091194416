import { DeliveryApiDraftProduct } from '../../../../../ApiClient/Yoso/models';
import { Product } from '../../../../../Model/Product/Product';
import { IApiModelSerializer } from '../../../IApiModelSerializer';

export class DraftProductSerializer implements IApiModelSerializer<Product, DeliveryApiDraftProduct> {
    public serialize(obj: Product): DeliveryApiDraftProduct {
        return {
            code: obj.code,
            id: obj.id,
            name: obj.name,
            volume: obj.volume,
            addable: obj.addable,
            isMainBottle: obj.isMainBottle,
            isDefaultBottle: obj.isDefaultBottle,
            isEmptyBottle: obj.isEmptyBottle,
            isActive: obj.isActive
        };
    }
}

export const draftProductSerializer = new DraftProductSerializer();
