import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';

interface FormDynamicList2Props {
    formName: string;
}

const FormDynamicList = (props: FormDynamicList2Props): JSX.Element => (
    <Form.List name={props.formName}>
        {(fields, { add, remove }) => (
            <>
                {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex' }} align="baseline">
                        <Form.Item {...restField} name={name}>
                            <Input placeholder="Email" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                ))}
                <Form.Item>
                    <Button type="dashed" onClick={() => add('')} block icon={<PlusOutlined />}>
                        Добавить получателя
                    </Button>
                </Form.Item>
            </>
        )}
    </Form.List>
);

export default FormDynamicList;
