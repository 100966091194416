import {GetWorkingDaysOKResponse} from "../../../../../ApiClient/Yoso/models";
import {WorkingDay} from "../../../../../Model/WorkingDay/WorkingDay";
import {IResponseDeserializer} from "../../../IResponseDeserializer";
import {
    workingDayInfoDeserializer
} from "../../Model/WorkingDayInfo/WorkingDayInfoDeserializer";

export interface IGetWorkingDaysResponse {
    workingDays: ReadonlyArray<WorkingDay>;
    total: number;
}

export class GetWorkingDaysResponseDeserializer implements IResponseDeserializer<GetWorkingDaysOKResponse, IGetWorkingDaysResponse> {
    public deserialize(response: GetWorkingDaysOKResponse): IGetWorkingDaysResponse {
        return {
            workingDays: response.workingDays.map((w) => workingDayInfoDeserializer.deserialize(w)),
            total: response.total,
        };
    }
}

export const getWorkingDaysResponseDeserializer = new GetWorkingDaysResponseDeserializer();