import { DateTime } from '@/Std/DateTime';
import { LatLngBoundsLiteral } from 'leaflet';
import { makeAutoObservable } from "mobx";
import * as turf from '@turf/turf';
import {
  DistrictListTypes
} from '../../Components/Routes/Zone/DistrictList/DistrictListTypes';
import { IGeocode } from "../../Model/Geocode/IGeocode";
import { getLeafletBoundsFromArrGeocodes } from '../../Std/LeafletUtils';
import { District, Driver } from "./District";
import { DistrictGroupCode } from "./DistrictGroupCode";
import { DistrictOrder } from './DistrictOrder';

export interface CityDistrict {
  name: string;
  districts: District[]
}

export interface DistrictsDriver {
  id: number;
  name: string;
  districts: District[];
}

export class DistrictStore {
  public driverMap = new Map<number, Driver>();
  public modifiedDistricts: District[] = [];
  public orders: DistrictOrder[] = [];
  public group: DistrictGroupCode;
  public unitedDistricts: CityDistrict[] = [];
  public noUnitedDistricts: District[] = [];
  public selectedDistrictIds: number[] = [];
  public selectedCityDistricts: string[] = [];
  public currentlyBeingEditedDistrict: District | undefined;
  public selectedListType = DistrictListTypes.District;
  public mapBounds: LatLngBoundsLiteral | null = null;
  public controlDate: DateTime | null = null;

  public constructor(group: DistrictGroupCode) {
    this.group = group;
    makeAutoObservable(this);
  }

  public initData(drivers: Driver[], allDistricts: District[], orders: DistrictOrder[], controlDate: DateTime): void {
    this.reset()
    this.initDrivers(drivers)
    this.initDistricts(allDistricts)
    this.initOrders(orders)
    this.controlDate = controlDate
  }

  public initDrivers(drivers: Driver[]): void {
    drivers.forEach(d => {
      this.driverMap.set(d.id, d);
    });
  }

  public initDistricts(allDistricts: District[]): void {
    const unitedDistrictsMap = new Map<string, District[]>();
    const coordsDistricts: IGeocode[][] = []

    allDistricts.forEach(d => {
      coordsDistricts.push(d.coords)
      if (!d.cityDistrictName) {
        this.noUnitedDistricts.push(d);
      } else {
        const districtArray = unitedDistrictsMap.get(d.cityDistrictName) ?? [];
        districtArray.push(d);
        unitedDistrictsMap.set(d.cityDistrictName, districtArray);
      }
    })

    this.unitedDistricts = [...unitedDistrictsMap.entries()].map(([name, districts]) => ({name, districts}));
    this.mapBounds = getLeafletBoundsFromArrGeocodes(coordsDistricts);
  }

  public initOrders(orders: DistrictOrder[]): void {
    this.orders = orders;
    setTimeout(() => {
      this.setOrdersToDistrict();
      this.recalculateDistrictOrders();
    }, 0)
  }

  public setCurrentlyBeingEditedDistrict = (district: District | undefined): void => {
    this.currentlyBeingEditedDistrict = district;
  }

  public setSelectedDistrictId = (ids: number[]): void => {
    this.selectedDistrictIds = ids;
  }

  public toggleListType = (): void => {
    this.selectedListType = this.selectedListType === DistrictListTypes.District
            ? DistrictListTypes.Driver
            : DistrictListTypes.District;

    this.setSelectedDistrictId([]);
  }

  public toggleSelectedCityDistricts = (name: string): void => {
    this.selectedCityDistricts = !this.selectedCityDistricts.includes(name)
        ? [...this.selectedCityDistricts, name]
        : this.selectedCityDistricts.filter(i => i !== name);
    this.selectedDistrictIds = [];
  }

  public get districts(): District[] {
    return [...this.unitedDistricts.flatMap(i => i.districts), ...this.noUnitedDistricts];
  }

  public get districtDrivers(): DistrictsDriver[] {
    const districtsByDrivers: DistrictsDriver[] = [];
    const driverDistrictsMap = new Map<number, District[]>();

    this.districts.forEach((district) => {
      const driverId = district.drivers.length > 0 ? district.drivers[0].id : 0;

      if (driverDistrictsMap.has(driverId)) {
        driverDistrictsMap.get(driverId)?.push(district);
      } else {
        driverDistrictsMap.set(driverId, [district])
      }
    });

    Array.from(driverDistrictsMap.keys()).forEach((driverId) => {
      const item: DistrictsDriver = {
        id: driverId,
        name: this.driverMap.get(driverId)?.name || 'Нет водителя',
        districts: driverDistrictsMap.get(driverId) || [],
      };
      districtsByDrivers.push(item);
    });

    return districtsByDrivers;
  }

  public get noUnitedDistrictsDrivers(): DistrictsDriver[] {
    const drivers = this.noUnitedDistricts.flatMap(d => d.drivers);

    return this.districtDrivers.filter(d => drivers.some(i => i.id === d.id) || d.id === 0);
  }

  public renameCityDistrict = (oldName: string, newName: string): void => {
    const districtGroupToRename = this.unitedDistricts.find(district => district.name === oldName);
    if (districtGroupToRename) {
      districtGroupToRename.name = newName;
      districtGroupToRename.districts.forEach(d => {
        d.cityDistrictName = newName
        this.updateModifiedDistricts(d);
      });
    }
  }

  public addDistrict = (id: number, coords: IGeocode[], name: string, color: string, drivers: Driver[]): void => {
    const newDistrict = new District(id, coords, name, color, this.group, drivers);
    this.noUnitedDistricts.push(newDistrict);
    this.modifiedDistricts = [...this.modifiedDistricts, newDistrict];
  }

  public editDistrictCoords = (id: number, newCoords: IGeocode[]): void => {
    const district = this.districts.find(d => d.id === id);
    if (district) {
      district.setCoords(newCoords);
      this.setOrdersToDistrict();
      this.recalculateDistrictOrders();
      this.updateModifiedDistricts(district);
    }
  }

  public editDistrictFields = (district: District,
                               name: string,
                               color: string,
                               drivers: Driver[],
                               newCityDistrictName?: string): void => {
    district.setName(name);
    district.setColor(color);
    district.setDrivers(drivers);
    this.updateModifiedDistricts(district);

    if (newCityDistrictName === district.cityDistrictName) {
      return;
    }

    if (!newCityDistrictName) {
      for (const item of this.unitedDistricts) {
        item.districts = item.districts.filter(d => d !== district);
      }
      district.setCityDistrictName(newCityDistrictName);
      this.noUnitedDistricts.push(district);
    } else {
      if (district.cityDistrictName) {
        const foundGroupForRemoveDistrict = this.unitedDistricts.find(d => d.name === district.cityDistrictName);
        if (foundGroupForRemoveDistrict) {
          foundGroupForRemoveDistrict.districts = foundGroupForRemoveDistrict.districts.filter(item => item !== district);
        }
        this.addDistrictToCityDistrict(district, newCityDistrictName);
      } else {
        this.noUnitedDistricts = this.noUnitedDistricts.filter(d => d !== district);
        this.addDistrictToCityDistrict(district, newCityDistrictName);
      }
      district.setCityDistrictName(newCityDistrictName)
    }
  }

  public removeDistrict = (district: District): void => {
    if (district.cityDistrictName) {
      const found = this.unitedDistricts.find(d => d.name === district.cityDistrictName);
      if (found) {
        found.districts = found.districts.filter(d => d.id !== district.id)
      }
    } else {
      this.noUnitedDistricts = this.noUnitedDistricts.filter(d => d.id !== district.id);
    }
  }

  private addDistrictToCityDistrict(district: District, newCityDistrictName: string) {
    const foundGroupForAddDistrict = this.unitedDistricts.find(d => d.name === newCityDistrictName);
    if (foundGroupForAddDistrict) {
      foundGroupForAddDistrict.districts.push(district)
    } else {
      this.unitedDistricts.push({name: newCityDistrictName, districts: [district]})
    }
    this.updateModifiedDistricts(district);
  }

  private updateModifiedDistricts(updatedDistrict: District) {
    const modifiedDistrict = this.modifiedDistricts.find(d => d === updatedDistrict);
    if (!modifiedDistrict) {
      this.modifiedDistricts.push(updatedDistrict);
    }
  }

  private setOrdersToDistrict() {
    const turfMap = new Map<number, any>();
    this.districts.forEach(d => {
      d.clearOrders()
      const polygon = turf.polygon([[
        ...d.coords.map(coord => ([coord.latitude, coord.longitude])),
        [d.coords[0].latitude, d.coords[0].longitude]
      ]]);

      turfMap.set(d.id, polygon)
    })

    for (const o of this.orders) {
      if (!o.coords.latitude || !o.coords.longitude) {
        continue;
      }

      for (const d of this.districts) {
        const point = turf.point([o.coords.latitude, o.coords.longitude])

        const turfPolygon = turfMap.get(d.id)
        const isInPolygon = turf.booleanPointInPolygon(point, turfPolygon);

        if (isInPolygon) {
          d.setOrder(o);
          break;
        }
      }
    }
  }

  private recalculateDistrictOrders() {
    const used: number[] = [];

    for (let i = 0; i < this.districts.length; ++i) {
      if (used.some(u => u === i)) {
        continue;
      }

      used.push(i);

      for (let j = i + 1; j < this.districts.length; ++j) {
        if (this.districts[i].drivers.length === 1 &&
          this.districts[j].drivers.length === 1 &&
          this.districts[i].drivers[0].name === this.districts[j].drivers[0].name
        ) {
          this.districts[i].info.bottles += this.districts[j].info.bottles;
          this.districts[i].info.nonBottles += this.districts[j].info.nonBottles;
          this.districts[i].orders.push(...this.districts[j].orders);

          this.districts[j].clearOrders();

          used.push(j);
        }
      }
    }
  }

  private reset() {
    this.unitedDistricts = [];
    this.noUnitedDistricts = [];
    this.orders = [];
    this.driverMap = new Map<number, Driver>();
  }
}