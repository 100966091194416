import {
    DeliveryApiCar,
    DeliveryApiDriver,
    DeliveryApiTripStatsSummary,
    TripStatsSummaryWorkingShift,
} from '../../../../../ApiClient/Yoso/models';
import { TripWorkingShift } from '../../../../../Model/Trip/TripWorkingShift';
import { TripStatsSummary } from '../../../../../Model/TripStatsSummary/TripStatsSummary';
import { Duration } from '../../../../../Std/Duration';
import { carDeserializer } from '../Car/CarDeserializer';
import { driverDeserializer } from '../Driver/DriverDeserializer';
import { tripStatusDeserializer } from '../TripStatus/TripStatusDeserializer';

export class TripStatsSummaryDeserializer {
    public deserialize(
        serializedObj: DeliveryApiTripStatsSummary,
        cars: ReadonlyArray<DeliveryApiCar>,
        drivers: ReadonlyArray<DeliveryApiDriver>,
    ): TripStatsSummary {
        const car = cars.find((carIterator) => carIterator.id === serializedObj.carId);
        if (car === undefined) {
            throw new Error(`Car with id=${serializedObj.carId} was not found`);
        }

        const driver = drivers.find((driverIterator) => driverIterator.id === serializedObj.driverId);
        if (driver === undefined) {
            throw new Error(`Car with id=${serializedObj.carId} was not found`);
        }

        return new TripStatsSummary(
            serializedObj.id,
            carDeserializer.deserialize(car),
            driverDeserializer.deserialize(driver),
            tripStatusDeserializer.deserialize2(serializedObj.status),
            serializedObj.scheduledOrderCount,
            serializedObj.doneOrderCount,
            serializedObj.failedOrderCount,
            serializedObj.adjustmentCount,
            serializedObj.estimatedDistance,
            serializedObj.actualDistance,
            Duration.fromSeconds(serializedObj.estimatedDuration),
            Duration.fromSeconds(serializedObj.actualDuration),
            serializedObj.estimatedProductCount,
            serializedObj.doneProductCount,
            serializedObj.failedProductCount,
            serializedObj.delayCount,
            serializedObj.estimatedPrice,
            serializedObj.actualPrice,
            serializedObj.movedOrdersCount,
            serializedObj.receivedOrdersCount,
            this.deserializeWorkingShift(serializedObj.tripStatsSummaryWorkingShift)
        );
    }

    private deserializeWorkingShift(workingShift: TripStatsSummaryWorkingShift | undefined) {
        return workingShift === TripStatsSummaryWorkingShift.MORNING
            ? TripWorkingShift.MORNING
            : workingShift === TripStatsSummaryWorkingShift.EVENING
                ? TripWorkingShift.EVENING
                : workingShift === TripStatsSummaryWorkingShift.DAYOFF
                    ? TripWorkingShift.DAYOFF : undefined
    }
}

export const tripStatsSummaryDeserializer = new TripStatsSummaryDeserializer();
