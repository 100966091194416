import * as React from 'react';
import { observer } from 'mobx-react';
import { StatsApiDriver } from '../../ApiClient/YosoStats/models';
import { MathUtils } from '../../Std/MathUtils';
import { CellBarChart } from '../EntityList/CellBarChart';
import { ColumnCellBar } from '../EntityList/ColumnCellBar';
import { EntityList } from '../EntityList/EntityList';

import './ChartDriverList.css';
import { ChartPage, ChartsStore } from '../../Store/ChartsStore/ChartsStore';

export interface IChartDriverListProps {
    store: ChartsStore
}

export const ChartDriverList = observer((props: IChartDriverListProps): JSX.Element => {
    const [maxCompletedOrdersCount, setMaxCompletedOrdersCount] = React.useState(0);
    const [maxNotCompletedOrdersCount, setMaxNotCompletedOrdersCount] = React.useState(0);
    const [maxErrorActionsCount, setMaxErrorActionsCount] = React.useState(0);
    const [maxArrivalGTPlus60Ratio, setMaxArrivalGTPlus60Ratio] = React.useState(0);
    const [maxArrivalBtwPlus31Plus60Ratio, setMaxArrivalBtwPlus31Plus60Ratio] = React.useState(0);
    const [maxArrivalBtwMinus30Plus30Ratio, setMaxArrivalBtwMinus30Plus30Ratio] = React.useState(0);
    const [maxArrivalLTMinus30Ratio, setMaxArrivalLTMinus30Ratio] = React.useState(0);
    const arrivalGTPlus60RatioThreshold = 0.03;
    const maxActualRouteSimilarity = 1;

    React.useEffect(() => {
        props.store.loadChartPage(ChartPage.Arrivals)
    }, []);

    React.useEffect(() => {
        const stats = props.store.arrivalStats;
        setMaxCompletedOrdersCount(Math.max(...stats.map((ds) => ds.completedOrdersCount)));
        setMaxNotCompletedOrdersCount(Math.max(...stats.map((ds) => ds.failedOrdersCount + ds.rescheduledOrdersCount)));
        setMaxErrorActionsCount(Math.max(...stats.map((ds) => ds.errorActionsCount)));
        setMaxArrivalGTPlus60Ratio(Math.max(...stats.map((ds) => ds.arrivalGTPlus60Ratio)));
        setMaxArrivalBtwPlus31Plus60Ratio(Math.max(...stats.map((ds) => ds.arrivalBtwPlus31Plus60Ratio)));
        setMaxArrivalBtwMinus30Plus30Ratio(Math.max(...stats.map((ds) => ds.arrivalBtwMinus30Plus30Ratio)));
        setMaxArrivalLTMinus30Ratio(Math.max(...stats.map((ds) => ds.arrivalLTMinus30Ratio)));
    }, [props.store.arrivalStats]);

    const formatRatio = (value: number) => `${MathUtils.ratioToPercentage(value)}%`;

    const last10days = "Последние 10 дней";
    
    return (
        <EntityList<StatsApiDriver>
            data={props.store.arrivalStats}
            id={(ds) => ds.id}
            className="chart-driver-list"
            columns={[
                {
                    key: 'name',
                    title: 'Имя',
                    render: (ds) => ds.name,
                },{
                    key: 'actualRouteSimilarity',
                    title: 'Соответствие маршруту, %',
                    render: (ds) => <ColumnCellBar
                        value={Number(ds.actualRouteSimilarity.toFixed(1))}
                        maxValue={maxActualRouteSimilarity}
                        valueFormatter={formatRatio}/>,
                },{
                    key: 'errorActionsCount',
                    title: 'Ошибок в приложении',
                    render: (ds) => <ColumnCellBar value={ds.errorActionsCount} maxValue={maxErrorActionsCount}/>,
                },{
                    key: 'completedOrdersCount',
                    title: 'Заказов выполненных',
                    render: (ds) => <ColumnCellBar value={ds.completedOrdersCount} maxValue={maxCompletedOrdersCount}/>,
                },{
                    key: 'notCompletedOrdersCount',
                    title: 'Не выполненных/перенесенных',
                    render: (ds) => <ColumnCellBar value={ds.failedOrdersCount + ds.rescheduledOrdersCount} maxValue={maxNotCompletedOrdersCount}/>,
                },{
                    key: 'arrivalLTMinus30Ratio',
                    title: 'Раньше',
                    render: (ds) => <ColumnCellBar value={ds.arrivalLTMinus30Ratio} maxValue={maxArrivalLTMinus30Ratio} valueFormatter={formatRatio} />,
                    noWrap: true,
                },
                {
                    key: 'lastArrivalLTMinus30s',
                    title: '',
                    render: (ds) => <CellBarChart values={ds.lastArrivalLTMinus30s} title={last10days} />,
                    noWrap: true,
                },
                {
                    key: 'arrivalBtwMinus30Plus30Ratio',
                    title: 'Вовремя',
                    render: (ds) => <ColumnCellBar value={ds.arrivalBtwMinus30Plus30Ratio} maxValue={maxArrivalBtwMinus30Plus30Ratio} valueFormatter={formatRatio} />,
                    noWrap: true,
                },
                {
                    key: 'lastArrivalBtwMinus30Plus30s',
                    title: '',
                    render: (ds) => <CellBarChart values={ds.lastArrivalBtwMinus30Plus30s} title={last10days} />,
                    noWrap: true,
                }
                ,{
                    key: 'arrivalBtwPlus31Plus60Ratio',
                    title: 'До получаса',
                    render: (ds) => <ColumnCellBar value={ds.arrivalBtwPlus31Plus60Ratio} maxValue={maxArrivalBtwPlus31Plus60Ratio} valueFormatter={formatRatio} />,
                },
                {
                    key: 'lastArrivalBtwPlus31Plus60s',
                    title: '',
                    render: (ds) => <CellBarChart values={ds.lastArrivalBtwPlus31Plus60s} title={last10days} />,
                    noWrap: true,
                },{
                    key: 'arrivalGTPlus60Ratio',
                    title: 'Дольше получаса',
                    render: (ds) => <ColumnCellBar value={ds.arrivalGTPlus60Ratio} maxValue={maxArrivalGTPlus60Ratio} valueFormatter={formatRatio} />,
                    noWrap: true,
                },
                {
                    key: 'lastArrivalGTPlus60s',
                    title: '',
                    render: (ds) => <CellBarChart values={ds.lastArrivalGTPlus60s} title={last10days} />,
                    noWrap: true,
                }
            ]}
            isEntityWithWarning={(ds) => ds.arrivalGTPlus60Ratio >= arrivalGTPlus60RatioThreshold}
            actions={{
                item: {
                    click: (id: number) => (): undefined => {
                        const arrivalStat = props.store.arrivalStats.find((ds) => ds.id === id);
                        if (arrivalStat) {
                            props.store.loadDriverPage(arrivalStat.id, arrivalStat.name);
                        }
                        
                        return;
                    }
                },
            }}
            loading={props.store.loading}
        />
    )
})