import { Color } from '../Color/Color';
import { MathUtils } from '../MathUtils';

type ColorPair<T> = [T, Color];

export class ByIndexColorPicker<T> {
    private static readonly selectedColorDarken = 0.2;
    private static readonly translucentColorAlpha = 0.65;

    private readonly colors: ReadonlyArray<ColorPair<T>>;
    private readonly defaultColor: Color;

    public constructor(colors: ReadonlyArray<[T, Color]>, defaultColor: Color = Color.rgba('#303030')) {
        this.colors = colors;
        this.defaultColor = defaultColor;
    }

    public get(index: number): T {
        const colorIndex: number = MathUtils.mod(index, this.colors.length);

        return this.colors[colorIndex][0];
    }

    public renderColor(colorKey: T | undefined, translucent: boolean = false, selected: boolean = false): Color {
        let color: Color | undefined;
        if (colorKey !== undefined) {
            const foundPair = this.colors.find((colorPair) => colorPair[0] === colorKey);
            if (foundPair !== undefined) {
                color = foundPair[1];
            }
        }

        if (color === undefined) {
            color = this.defaultColor;
        }

        if (translucent) {
            color = color.setAlpha(ByIndexColorPicker.translucentColorAlpha);
        }
        if (selected) {
            color = color.darken(ByIndexColorPicker.selectedColorDarken);
        }

        return color;
    }
}
