import {
    LocalStorageDriverReadMails
} from '../../../../../Model/DriverReadMails/DriverReadMails';
import { DateTime } from '../../../../../Std/DateTime';
import { IClientPreferencesStorage } from './IClientPreferencesStorage';

enum ClientPreferencesLocalStorageKey {
    IsSidebarCollapsed = 'yoso_is_sidebar_collapsed',
    DateReceivingLastNoticeMail = 'yoso_date_receiving_last_notice_mail',
    ReadMailIds = 'yoso_read_mail_ids',
}

export class ClientPreferencesLocalStorageStorage implements IClientPreferencesStorage {
    private static getValue(key: ClientPreferencesLocalStorageKey): string | undefined {
        const value = window.localStorage.getItem(key);

        return value === null ? undefined : value;
    }

    private static removeValue(key: ClientPreferencesLocalStorageKey): void {
        window.localStorage.removeItem(key);
    }

    private static setValue(key: ClientPreferencesLocalStorageKey, value: string | undefined): void {
        window.localStorage.setItem(key, value === undefined ? '' : value);
    }

    private _isSidebarCollapsed: boolean | undefined;
    private _dateReceivingLastNoticeMail: DateTime;
    private _driversReadMailIds: LocalStorageDriverReadMails[];

    public constructor() {
        const isSidebarCollapsed = ClientPreferencesLocalStorageStorage.getValue(
            ClientPreferencesLocalStorageKey.IsSidebarCollapsed,
        );
        if (isSidebarCollapsed !== undefined) {
            this._isSidebarCollapsed = isSidebarCollapsed === '1';
        }

        const date = ClientPreferencesLocalStorageStorage.getValue(
            ClientPreferencesLocalStorageKey.DateReceivingLastNoticeMail
        );

        let parsedDate;

        if (date) {
            parsedDate = new Date(date);
            if (isNaN(parsedDate.getTime())) {
                parsedDate = new Date();
                parsedDate.setHours(0, 0, 0, 0);
            }
        } else {
            parsedDate = new Date();
            parsedDate.setHours(0, 0, 0, 0);
        }

        this._dateReceivingLastNoticeMail = DateTime.fromDate(parsedDate);

        const mails = ClientPreferencesLocalStorageStorage.getValue(
            ClientPreferencesLocalStorageKey.ReadMailIds
        );
        this._driversReadMailIds = mails ? JSON.parse(mails) : [];
    }

    public get isSidebarCollapsed(): boolean | undefined {
        return this._isSidebarCollapsed;
    }

    public set isSidebarCollapsed(value: boolean | undefined) {
        this._isSidebarCollapsed = value;

        if (this._isSidebarCollapsed === undefined) {
            ClientPreferencesLocalStorageStorage.removeValue(ClientPreferencesLocalStorageKey.IsSidebarCollapsed);
        } else {
            ClientPreferencesLocalStorageStorage.setValue(
                ClientPreferencesLocalStorageKey.IsSidebarCollapsed,
                this._isSidebarCollapsed ? '1' : '0',
            );
        }
    }

    public get dateReceivingLastNoticeMail(): DateTime {
        return this._dateReceivingLastNoticeMail;
    }

    public set setDateReceivingLastNoticeMail(value: DateTime) {
        this._dateReceivingLastNoticeMail = value;

        ClientPreferencesLocalStorageStorage.setValue(
            ClientPreferencesLocalStorageKey.DateReceivingLastNoticeMail,
            value.toDate().toString()
        );
    }

    public get driversReadMailIds(): LocalStorageDriverReadMails[] {
        return this._driversReadMailIds;
    }

    public set updateDriversReadMailIds(value: LocalStorageDriverReadMails) {
        const found = this._driversReadMailIds.find(i => i.driverId === value.driverId);
        if (found) {
            const existingIds = found.mails.map(i => i.id);
            const set = new Set(existingIds);

            for (const mail of value.mails) {
                if (!set.has(mail.id)) {
                    found.mails.push(mail)
                }
            }
        } else {
            this._driversReadMailIds.push(value);
        }

        ClientPreferencesLocalStorageStorage.setValue(
            ClientPreferencesLocalStorageKey.ReadMailIds,
            JSON.stringify(this._driversReadMailIds)
        );
    }

    public set clearMailsBeforeDate(value: DateTime) {
        for (const driverMails of this._driversReadMailIds) {
            driverMails.mails = driverMails.mails.filter(mail => new Date(mail.date).getTime() > value.toDate().getTime());
        }

        ClientPreferencesLocalStorageStorage.setValue(
            ClientPreferencesLocalStorageKey.ReadMailIds,
            JSON.stringify(this._driversReadMailIds)
        );
    }
}
