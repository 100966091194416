import { ItineraryProductMovementRow } from "./ItineraryProductMovementRow";

export class ItineraryProductMovement {
    public title: string;
    public rows: ItineraryProductMovementRow[];

    public constructor(title: string, rows: ItineraryProductMovementRow[]) {
        this.title = title;
        this.rows = rows;
    }
}