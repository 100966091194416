import { Spin } from 'antd';
import * as React from 'react';
import { Redirect } from 'react-router';

import { useAuth } from '../AppProviders/Providers/AuthProvider/AuthProvider';
import { useClientPreferences } from '../AppProviders/Providers/ClientPreferencesProvider/ClientPreferencesProvider';
import { useUser } from '../AppProviders/Providers/UserProvider';
import { AuthenticatedApp } from '../AuthenticatedApp/AuthenticatedApp';

import './AuthenticatedAppWrapper.css';

export interface IAuthenticatedAppWrapperProps {
    className?: string;
}

export const AuthenticatedAppWrapper = (props: IAuthenticatedAppWrapperProps): JSX.Element => {
    const authContext = useAuth();
    const userContext = useUser();
    const clientPreferencesContext = useClientPreferences();

    const { checkToken } = authContext;
    const { setIsSidebarCollapsed } = clientPreferencesContext;

    const haveToken = authContext.data.user !== undefined;

    const [haveValidToken, setHaveValidToken] = React.useState(false as boolean);

    const validateToken = React.useCallback(async (): Promise<undefined> => {
        await checkToken();

        setHaveValidToken(true);

        return;
    }, [checkToken]);

    const handleSidebarCollapsedChange = React.useCallback(
        (value: boolean | undefined) => {
            setIsSidebarCollapsed(value);
        },
        [setIsSidebarCollapsed],
    );

    React.useEffect(() => {
        if (haveToken) {
            validateToken();
        }
    }, [haveToken, validateToken]);

    if (!haveToken) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="authenticated-app-wrapper">
            {haveValidToken ? (
                <AuthenticatedApp
                    {...props}
                    user={userContext.user}
                    isSidebarCollapsed={clientPreferencesContext.isSidebarCollapsed}
                    onSidebarCollapsedChange={handleSidebarCollapsedChange}
                />
            ) : (
                <Spin className="authenticated-app-wrapper__spin" />
            )}
        </div>
    );
};
