import { TimeMatrixSource1 as ApiTimeMatrixSource } from "../../../../../ApiClient/Yoso/models";
import { TimeMatrixSource } from "./TimeMatrixSource";


export class TimeMatrixSourceSerializer {
    public serialize(value: TimeMatrixSource): ApiTimeMatrixSource {
        switch (value) {
            case TimeMatrixSource.OSM:
                return ApiTimeMatrixSource.OSM;
            case TimeMatrixSource.Yandex:
                return ApiTimeMatrixSource.YANDEX;
            case TimeMatrixSource.TGis:
                return ApiTimeMatrixSource.TGIS;
            default:
                return ApiTimeMatrixSource.NONE;
        }
    }
}

export const timeMatrixSourceSerializer = new TimeMatrixSourceSerializer();