import {
    DeliveryApiDistrict,
    Group1,
} from '../../../../../ApiClient/Yoso/models';
import {
    CompanyApiDistrict,
    CompanyApiDistricts,
    CompanyApiGeocode,
    Group
} from "../../../../../ApiClient/YosoCompany/models";
import {District} from '../../../../../Model/District/District';
import {IGeocode} from '../../../../../Model/Geocode/IGeocode';
import {
    District as DistrictOnMap
} from "../../../../../Store/District/District";
import {
    DistrictGroupCode
} from '../../../../../Store/District/DistrictGroupCode';


export class DistrictSerializer {
    public serializeFromMap(district: DistrictOnMap): CompanyApiDistrict {
        if (!district.name) {
            throw new Error('District name is undefined');
        }

        const geocodes: CompanyApiGeocode[] = [];

        district.coords.forEach((coord: IGeocode) => {
            geocodes.push({ latitude: coord.latitude, longitude: coord.longitude });
        })

        return {
            id: district.isNew() ? undefined : district.id,
            name: district.name,
            color: district.color,
            group: this.serializeDistrictGroup(district.group),
            polygonPoints: geocodes,
            drivers: district.drivers.map((districtDriver) => (
                {
                    id: districtDriver.id,
                    name: districtDriver.name,
                    isActive: districtDriver.isActive
                }
            )),
            cityDistrict: district.getCityDistrictName()
        };
    }

    public deserializeForMap(apiDistricts: CompanyApiDistrict[]): DistrictOnMap[] {
        return apiDistricts.map((apiDistrict) => {
            if (apiDistrict.id === undefined) {
                throw new Error('Error. No districtId in BD');
            }

            const districtDrivers = apiDistrict.drivers.map((driver) => (
              {
                  id: driver.id,
                  name: driver.name,
                  selected: true,
                  isActive: driver.isActive
              }
            ));

            return new DistrictOnMap(
              apiDistrict.id,
              apiDistrict.polygonPoints,
              apiDistrict.name,
              apiDistrict.color,
              this.deserializeDistrictGroup(apiDistrict.group),
              districtDrivers,
              apiDistrict.cityDistrict
            );
        })
    }

    public deserialize(apiDistrict: DeliveryApiDistrict): District {
        return new District(
            apiDistrict.name,
            apiDistrict.geocodes.map((geocode) => ({
                latitude: geocode.latitude!,
                longitude: geocode.longitude!
            })),
            apiDistrict.color,
            this.deserializeDistrictGroup(apiDistrict.group));
    }

    private deserializeDistrictGroup(group: Group | Group1): DistrictGroupCode {
        switch(group) {
            case Group.MORNING || Group1.MORNING:
                return DistrictGroupCode.Morning;
            case Group.EVENING || Group1.EVENING:
                return DistrictGroupCode.Evening;
            case Group.DAYOFF || Group1.DAYOFF:
                return DistrictGroupCode.DayOff;
            default:
                throw new Error(`Unknown district group ${group}`)
        }
    }

    private serializeDistrictGroup(group: DistrictGroupCode): Group {
        switch(group) {
            case DistrictGroupCode.Morning:
                return Group.MORNING;
            case DistrictGroupCode.Evening:
                return Group.EVENING;
            case DistrictGroupCode.DayOff:
                return Group.DAYOFF;
            default:
                throw new Error(`Unknown district group ${group}`)
        }
    }
}

export const districtSerializer = new DistrictSerializer();
