import React from 'react';
import {observer} from 'mobx-react';
import { Button } from 'antd';
import {LatLngExpression, LatLngTuple} from 'leaflet';
import {DeliveryApi} from '../../Backend/Api/Delivery/DeliveryApi';
import {TripComparisonStore} from '../../Store/TripComparisonStore/TripComparisonStore';
import DriverPlaceSelector from '../DriverPlaceSelector/DriverPlaceSelector';
import Order from '../DriverPlaceSelector/Order/Order';
import MapContainer from '../MapContainer/MapContainer';
import {MapSource} from '../MapContainer/MapSource';
import { ArrayUtils } from '../../Std/ArrayUtils';
import styles from './DriverPlaceChoice.module.css';

interface TripComparisonsProps {
    tripId: number;
    center?: LatLngExpression;
    bounds?: LatLngTuple[];
    store: TripComparisonStore;
}

export const DriverPlaceChoice = observer((props: TripComparisonsProps) => {
    const save = async () => {
        const ordersWithDriverPlace = props.store.orders.filter(o => o.driverPlace);
        await DeliveryApi.saveAnalyzedDriverPlaces(props.tripId,
            ordersWithDriverPlace.map(o => ({orderId: o.id, driverPlace: o.driverPlace!}))
        );

        window.location.reload();
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.ordersContainer}>
                    <div className={styles.orderList}>
                        {ArrayUtils.sort(props.store.orders, o => o.address).map(o => (
                            <Order
                                key={o.id}
                                order={o}
                                isSelected={o.id === props.store.selectedOrder?.id}
                                hasDriverPoint={!!o.driverPlace}
                                onClick={() => props.store.selectOrder(o.id)}
                            />
                        ))}
                    </div>
                    <Button
                        type='primary'
                        className={styles.button}
                        disabled={!props.store.orders.find(o => o.driverPlace)}
                        onClick={save}
                    >
                        Подтвердить
                    </Button>
                </div>
                {props.center && (
                    <div className={styles.map}>
                        <MapContainer
                            center={props.center}
                            bounds={props.bounds}
                            mapSource={MapSource.Yandex}
                        >
                            <DriverPlaceSelector
                                store={props.store}
                            />
                        </MapContainer>
                    </div>
                )}
            </div>
        </div>
    );
});