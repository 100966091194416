import { CarMoveAndStopLocation } from "./CarMoveAndStopLocation";

export class CarMoveAndStopLocations {
    public readonly tripId: number;
    public readonly locations: ReadonlyArray<CarMoveAndStopLocation>;

    public constructor(tripId: number, locations: CarMoveAndStopLocation[]) {
        this.tripId = tripId;
        this.locations = locations;
    }
}