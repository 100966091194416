export class CompanyProduct {
    public id: number;
    public code: string;
    public name: string;
    public shortName?: string;
    public volume: number;
    public popularity: number;
    public addable: boolean;
    public isMainBottle: boolean;
    public isDefaultBottle: boolean;
    public isEmptyBottle: boolean;
    public isActive: boolean;

    public constructor(
        id: number,
        code: string,
        name: string,
        volume: number,
        popularity: number,
        addable: boolean,
        isMainBottle: boolean,
        isDefaultBottle: boolean,
        isEmptyBottle: boolean,
        isActive: boolean,
        shortName?: string,
    ) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.volume = volume;
        this.popularity = popularity;
        this.addable = addable;
        this.isMainBottle = isMainBottle;
        this.isDefaultBottle= isDefaultBottle;
        this.isEmptyBottle = isEmptyBottle;
        this.isActive = isActive;
        this.shortName = shortName;
    }
}