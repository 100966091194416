import { DraftApiDriver } from '../../../../ApiClient/YosoDraft/models';
import { Driver } from '../../../../Model/Driver/Driver';

export class DriverMapper {
    public static mapFromApi(apiDriver: DraftApiDriver): Driver {
        return new Driver(
            apiDriver.id,
            apiDriver.code,
            apiDriver.name,
            apiDriver.experienceCoefficient,
            apiDriver.enableDeliveryPrediction,
            apiDriver.login,
            undefined,
        );
    }
}
