import * as React from 'react';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import { DatePicker } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReloadOutlined } from '@ant-design/icons';

import './ChartRoute.css';

import { ArrayUtils } from '../../../Std/ArrayUtils';
import { ChartDriver } from '../../ChartPages/ChartDriver';
import { ChartDriverList } from '../../ChartPages/ChartDriverList';
import { Page } from '../../Page/Page';
import { RoutedTabs } from '../../RoutedTabs/RoutedTabs';
import { ChartDriverRatingList } from '../../ChartPages/ChartDriverRatingList';
import { ChartPage, ChartsStore } from '../../../Store/ChartsStore/ChartsStore';

interface TabDriver {
    id: number;
    name: string;
}

export interface IChartRouteRouteProps extends RouteComponentProps<{
    driverId?: string
}> {
}

export const ChartRoute = withRouter(observer((props: IChartRouteRouteProps): JSX.Element => {
    const chartsStore = React.useMemo(() => new ChartsStore(moment().startOf('month'), moment().endOf('day')), []);
    const [datePeriod, setDatePeriod] = React.useState<[Moment, Moment] | null>([chartsStore.startDate, chartsStore.endDate]);

    const isShipmentPage = () => /^\/charts$/.test(props.history.location.pathname);
    const isArrivalPage = () => /^\/charts\/arrivals$/.test(props.history.location.pathname);
    const isDriverPage = () => /^\/charts\/driver\/\d+$/.test(props.history.location.pathname);

    React.useEffect(() => {
        const path = props.history.location.pathname;
        if (isDriverPage()) {
            const driverId = props.match.params.driverId;
            if (driverId !== undefined) {
                chartsStore.loadDriverPage(Number(driverId), "Загрузка...");
            }
        }
    }, []);

    const handleReload = () => {
        if (isShipmentPage()) {
            chartsStore.loadChartPage(ChartPage.Shipment);
        } else if (isArrivalPage()) {
            chartsStore.loadChartPage(ChartPage.Arrivals);
        } else if (isDriverPage()) {
            chartsStore.loadChartPage(ChartPage.Driver);
        }
    }

    React.useEffect(() => {
        if (chartsStore.selectedDriver) {
            props.history.push(chartsStore.getDriverChartUrl(chartsStore.selectedDriver.id));
        }
    }, [chartsStore.selectedDriver]);

    const handleChangeDate = (dateObj: any) => {
        if (dateObj === null) {
          return;
        }
    
        chartsStore.setPeriod(dateObj[0], dateObj[1]);
        setDatePeriod([chartsStore.startDate, chartsStore.endDate]);
    }

    return (
        <Page header="Статистика">
            <div className='chart-route__container'>
                <div className='chart-route__date-period-picker'>
                    <DatePicker.RangePicker
                        placeholder={["Начало", "Конец"]}
                        value={datePeriod}
                        onChange={handleChangeDate}
                    />
                    <ReloadOutlined title='Обновить' onClick={handleReload} className='chart-route__reload' />
                </div>
                <RoutedTabs
                    className='chart-route__tabs-container'
                    tabs={ArrayUtils.defined([
                        {
                            title: 'Рейтинг',
                            url: `/charts`,
                            render: () => <ChartDriverRatingList store={chartsStore}/>,
                            cache: true,
                        },
                        {
                            title: 'Отклонение от sms-интервала',
                            url: `/charts/arrivals`,
                            render: () => <ChartDriverList store={chartsStore}/>,
                            cache: true,
                        },
                        chartsStore.selectedDriver ?
                        {
                            title: chartsStore.selectedDriver.name,
                            url: chartsStore.getDriverChartUrl(chartsStore.selectedDriver.id),
                            render: () => <ChartDriver store={chartsStore} />,
                            cache: true
                        }
                        : undefined,
                    ])}
                />
            </div>
        </Page>
    )
}))