import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { CloseOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { DraftDriver } from '../../Model/DraftDriver/DraftDriver';
import MapContent from '../MapContainer/MapContent';
import { PlanValidationStore } from './PlanValidationStore';
import styles from './PlanValidation.module.css';

interface DistrictValidationProps {
    store: PlanValidationStore;
}

const DistrictValidation = ({store}: DistrictValidationProps) => {
    const [open, setOpen] = React.useState(false);

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    if (!store.selectedDistrict) {
        return null;
    }

    const driverList = (): DraftDriver[] => {
        if (store.selectedDistrict?.drivers.length !== 1) {
            return store.allDrivers;
        }

        return store.allDrivers.filter(d => store.selectedDistrict?.drivers[0] !== d);
    }

    const select = (driver: DraftDriver) => {
        setOpen(false)
        store.setDriverToSelectedDistrict(driver);
    }

    return (
        <MapContent position='topright'>
            <CloseOutlined
                className={styles.infoClose}
                onClick={() => store.setSelectedDistrict(undefined)}
            />
            <div className={styles.infoContainer}>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>Район:</div>
                    <div className={styles.infoName}>{store.selectedDistrict.name}</div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>Водители:</div>
                    <div>
                        {store.selectedDistrict.drivers.map(d => (
                            <div key={d.id} className={styles.infoItem}>
                                <div>{d.name}</div>
                                <MinusOutlined
                                    onClick={() => store.removeDriverFromSelectedDistrict(d)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <Popover
                    trigger="click"
                    visible={open}
                    onVisibleChange={handleOpenChange}
                    content={
                        <div className={styles.popover}>
                            {driverList().map(d =>
                                <div
                                    key={d.id}
                                    className={classNames(styles.popoverItem, !d.isActive ? styles.popoverInactive : undefined)}
                                    onClick={() => select(d)}
                                >
                                    {d.name}
                                </div>
                            )}
                        </div>
                    }
                >
                    <Button>Установить водителя</Button>
                </Popover>
            </div>
        </MapContent>
    )
};

export default observer(DistrictValidation);