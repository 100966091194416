import * as React from 'react';

import {
    CartesianGrid,
    ResponsiveContainer,
    Bar,
    BarChart,
    XAxis,
    Tooltip,
    LabelList,
    Label,
    YAxis
} from 'recharts';
import { ISeriesProp } from './Model/ISeriesProps';

import './Chart.css';
import { ChartProps } from './ChartProps';

export interface IStackedBarChartProps<TElement> extends ChartProps {
    data: TElement[];
    bars: ISeriesProp[];
    xLabel: (el: TElement) => string;
    xLabelClick?: (e: any) => void;
    isYAxis?: boolean;
    isExpand?: boolean;
}

export const StackedBarChart = <TElement extends unknown>(props: IStackedBarChartProps<TElement>): JSX.Element => {
    const chartDefaultHeight = 400;
    const xLabelAngle = -40;

    const tooltipContent = (e: any): JSX.Element => {
        const active: boolean = e.active;
        const payload = e.payload;        

        if (!active || !payload || !payload.length) {
            return <></>;
        }

        const len = payload.length-1;

        return (
            <div className="chart-tooltip">
                <div className="label">{e.label}</div>
                {payload.reverse().map((p: any, i: number) => (
                    <div key={len-i} style={{
                        color: props.bars[len-i].fill
                    }}>
                        {props.bars[len-i].name}: {p.value}
                    </div>
                ))}
            </div>
        )
    }

    const renderLabel = (prps: any) => {
        const { content, ...rest } = prps;

        rest.value = rest.value ? rest.value : undefined;
        
        return <Label {...rest} fontSize="11" fill="#FFFFFF" fontWeight="bold" />;
    };

    return (
        <div className="chart">
            {props.title !== undefined && (
                <h3 className="chart-title">{props.title}</h3>
            )}
            {props.data.length > 0 && (
                <ResponsiveContainer width={props.chartWidth || '100%'} height={props.chartHeight || chartDefaultHeight}>
                    <BarChart data={props.data} stackOffset={props.isExpand ? 'expand' : 'none'}>
                        <XAxis
                            type="category"
                            dataKey={props.xLabel}
                            angle={xLabelAngle}
                            textAnchor="end"
                            fontSize={12}
                            textDecoration={props.xLabelClick ? "underline" : undefined}
                            cursor={props.xLabelClick ? "pointer" : undefined}
                            tick={{fill: props.xLabelClick ? "#1890ff" : undefined}}
                            onClick={props.xLabelClick}/>

                        {props.isYAxis && <YAxis domain={[0, 1]} />}

                        <CartesianGrid stroke="#f5f5f5" />

                        <Tooltip content={tooltipContent} />

                        {props.bars.map((serie: ISeriesProp, index: number) => (
                            <Bar
                                key={index}
                                dataKey={serie.key}
                                fill={serie.fill}
                                stackId="stack">
                                <LabelList
                                    dataKey={serie.key}
                                    position="center"
                                    content={renderLabel}
                                />
                            </Bar>
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}