export class WorkingDay {
    public readonly id: number;
    public readonly date: Date;
    public readonly isShort: boolean;
    public readonly isRestDay: boolean;
    public readonly createdAt?: string;

    public constructor(id: number, date: Date, isShort: boolean, isRestDay: boolean, createdAt?: string) {
        this.id = id;
        this.date = date;
        this.isShort = isShort;
        this.isRestDay = isRestDay;
        this.createdAt = createdAt;
    }
}