import { DeliveryApiDriver } from '../../../../../ApiClient/Yoso/models';
import { Driver } from '../../../../../Model/Driver/Driver';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';

export class DriverDeserializer {
    public deserialize(serializedObject: DeliveryApiDriver): Driver {
        return new Driver(
            serializedObject.id!,
            serializedObject.code,
            serializedObject.name,
            serializedObject.proficiency,
            serializedObject.enableDeliveryPrediction,
            serializedObject.login,
            serializedObject.deletedAt === undefined
                ? undefined
                : dateTimeDeserializer.deserialize(serializedObject.deletedAt),
        );
    }
}

export const driverDeserializer = new DriverDeserializer();
