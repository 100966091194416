import {DateTime} from '../../Std/DateTime';

export class TripComparisonListItem {
    public id: number;
    public tripId: number;
    public name: string;
    public date: DateTime;
    public createdAt: DateTime;

    public constructor(
        id: number,
        tripId: number,
        name: string,
        date: DateTime,
        createdAt: DateTime
    ) {
        this.id = id;
        this.tripId = tripId;
        this.name = name;
        this.date = date;
        this.createdAt = createdAt;
    }
}