import React from 'react';
import classNames from 'classnames';
import styles from './DaysOfWeekSelector.module.css';

export const daysOfWeekOptions = [
    { label: 'Пн', value: 1 },
    { label: 'Вт', value: 2 },
    { label: 'Ср', value: 3 },
    { label: 'Чт', value: 4 },
    { label: 'Пт', value: 5 },
    { label: 'Сб', value: 6 },
    { label: 'Вс', value: 7 },
];

interface DaysOfWeekSelectorProps {
    onChange: (days: number[]) => void;
    allowEmpty?: boolean;
    values: number[];
}

const DaysOfWeekSelector = (props: DaysOfWeekSelectorProps) => {
    const { values, onChange } = props;
    const allowEmpty = props.allowEmpty === undefined ? false : props.allowEmpty;

    const handleCheckboxChange = (dayValue: number) => {
        if (!allowEmpty && values.length === 1 && values.includes(dayValue)) {
            return;
        }

        const newValue = values.includes(dayValue) ? values.filter((v) => v !== dayValue) : [...values, dayValue];
        onChange(newValue);
    };

    return (
        <div className={styles.daysOfWeekGroup}>
            {daysOfWeekOptions.map((day) => (
                <div
                    key={day.value}
                    className={classNames({
                        [styles.checkboxWrapper]: true,
                        [styles.checkboxChecked]: values?.includes(day.value),
                        [styles.checkboxDisabled]: !allowEmpty && values?.includes(day.value) && values?.length === 1,
                    })}
                    onClick={() => handleCheckboxChange(day.value)}
                >
                    <span className={styles.checkboxLabel}>{day.label}</span>
                </div>
            ))}
        </div>
    );
};

export default DaysOfWeekSelector;
