import { TargetType } from "../../ApiClient/Yoso/models";
import { DateTime } from "../../Std/DateTime";
import { Geocode } from "../Geocode/Geocode";

export class LoadingStore {
    public readonly id: number;
    public readonly coordinates: Geocode;
    public readonly address: string;
    public readonly stayDuration: number;
    public readonly expectedArrivalDateTime: DateTime | undefined;

    public constructor(
        id: number,
        coordinates: Geocode,
        address: string,
        stayDuration: number,
        expectedArrivalDateTime: DateTime | undefined
    ) {
        this.id = id;
        this.coordinates = coordinates;
        this.address = address;
        this.stayDuration = stayDuration;
        this.expectedArrivalDateTime = expectedArrivalDateTime;
    }

    public getKey(): string {
        return `${this.id.toString()}_${TargetType.LOADINGSTORE.toString()}`;
    }
}