import { DeliveryApiDraftProductOperation } from '../../../../../ApiClient/Yoso/models';
import { ProductOperation } from '../../../../../Model/ProductOperation/ProductOperation';
import { IApiModelSerializer } from '../../../IApiModelSerializer';
import { productOperationDealTypeSerializer } from '../ProducOperationDealType/ProductOperationDealTypeSerializer';
import { productOperationTypeSerializer } from '../ProductOperationType/ProductOperationTypeSerializer';

export class DraftProductOperationSerializer
    implements IApiModelSerializer<ProductOperation, DeliveryApiDraftProductOperation> {
    public serialize(obj: ProductOperation): DeliveryApiDraftProductOperation {
        return {
            count: obj.estimatedCount,
            price: obj.price,
            productId: obj.product.id,
            type: productOperationTypeSerializer.serialize(obj.type),
            dealType: productOperationDealTypeSerializer.serialize(obj.dealType),
        };
    }
}

export const draftProductOperationSerializer = new DraftProductOperationSerializer();
