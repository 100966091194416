export enum MapColor {
    Blue,
    Green,
    Yellow,
    Orange,
    Red,
    Purple,
    BlueGreen,
    YellowGreen,
    YellowOrange,
    RedOrange,
    RedPurple,
    BluePurple,
    BluePale,
    GreenPale,
    YellowPale,
    OrangePale,
    RedPale,
    PurplePale,
    BlueGreenPale,
    YellowGreenPale,
    YellowOrangePale,
    RedOrangePale,
    RedPurplePale,
    BluePurplePale,
}
