import {TargetType} from '@/ApiClient/Yoso/models';
import {ILeg} from '@/Backend/Types/ILeg';
import {Geocode} from '@/Model/Geocode/Geocode';
import {IGeocode} from '@/Model/Geocode/IGeocode';
import {Lunch} from '@/Model/Lunch/Lunch';
import {Order} from '@/Model/Order/Order';
import {LoadingStore} from '@/Model/Store/LoadingStore';
import {DateTime} from '@/Std/DateTime';
import {IIdentifiablePoint} from '../IIdentifiablePoint';
import {Leg} from './Leg/Leg';
import {FinishRoutePoint} from './RoutePoint/FinishRoutePoint';
import {LoadingStoreRoutePoint} from './RoutePoint/LoadingStoreRoutePoint';
import {LunchRoutePoint} from './RoutePoint/LunchRoutePoint';
import {OrderRoutePoint} from './RoutePoint/OrderRoutePoint';
import {RoutePoint} from './RoutePoint/RoutePoint';
import {StartRoutePoint} from './RoutePoint/StartRoutePoint';

export class Route {
    public get all(): ReadonlyArray<RoutePoint | Leg | undefined> {
        return this._all;
    }

    public get orders(): ReadonlyArray<OrderRoutePoint> {
        return this._orders;
    }

    public get routePoints(): ReadonlyArray<RoutePoint> {
        return this._routePoints;
    }

    public get finishPoint(): FinishRoutePoint {
        if (this._finishPoint === undefined) {
            throw new Error('The route has not finish point');
        }

        return this._finishPoint;
    }

    public get legs(): ReadonlyArray<Leg | undefined> {
        return this._legs;
    }

    public get points(): ReadonlyArray<RoutePoint> {
        return this._points;
    }

    public get startPoint(): StartRoutePoint {
        if (this._startPoint === undefined) {
            throw new Error('The route has not start point');
        }

        return this._startPoint;
    }

    public get noLegPoints(): OrderRoutePoint[] {
        return this._noLegPoints;
    }

    public get isProblemRoute(): boolean | undefined {
        return this._isProblemRoute
    }

    private static createOrderRoutePoint(order: Order, legToCheckpoint?: ILeg, legFromCheckpoint?: ILeg): OrderRoutePoint {
        return new OrderRoutePoint(
            `point_${order.getKey()}`,
            order, legToCheckpoint?.distance,
            legFromCheckpoint?.period?.start,
            !!(legToCheckpoint && legFromCheckpoint)
        );
    }

    private static createRoutePoint(routePoint: Order | Lunch | LoadingStore,
                                    legToCheckpoint?: ILeg, legFromCheckpoint?: ILeg): RoutePoint {
        const key = `point_${routePoint.getKey()}`;
        const result =
            routePoint instanceof Order
                ? new OrderRoutePoint(key, routePoint, legToCheckpoint?.distance, legFromCheckpoint?.period?.start)
                : routePoint instanceof Lunch
                    ? new LunchRoutePoint(key, routePoint, legToCheckpoint?.distance, legFromCheckpoint?.period?.start)
                    : new LoadingStoreRoutePoint(key, routePoint, legToCheckpoint?.distance, legFromCheckpoint?.period?.start);

        return result;
    }

    private static getOrder(orders: ReadonlyArray<Order>, id: number): Order | undefined {
        return orders.find((order) => order.id === id);
    }

    private static getLunch(lunches: ReadonlyArray<Lunch>, id: number): Lunch | undefined {
        return lunches.find((lunch) => lunch.id === id);
    }

    private static getLoadingStore(stores: ReadonlyArray<LoadingStore>, id: number): LoadingStore | undefined {
        return stores.find((store) => store.id === id);
    }

    private static iterate(
        start: IIdentifiablePoint,
        finish: IIdentifiablePoint,
        orders: ReadonlyArray<Order>,
        lunches: ReadonlyArray<Lunch>,
        loadingStores: ReadonlyArray<LoadingStore>,
        legs: ReadonlyArray<Readonly<ILeg>>,
        handleStartPoint: (startPoint: IIdentifiablePoint, legFrom: ILeg | undefined) => void,
        handleFinishPoint: (startPoint: IIdentifiablePoint, legTo: ILeg | undefined) => void,
        handleRoutePoint: (routePoint: Order | Lunch | LoadingStore, legToCheckpoint: ILeg, legFromCheckpoint: ILeg) => void,
        handleNoLegPoint: (routePoint: Order) => void,
        handleLeg: (leg: ILeg, index: number) => void,
    ): void {
        const startLeg = legs[0];
        const finishLeg = legs[legs.length - 1];

        handleStartPoint(start, startLeg);

        let legToCurrentPoint: ILeg;
        let currentPoint: Order | Lunch | LoadingStore | undefined;
        let legFromCurrentPoint: ILeg;

        for (let index = 0; index < legs.length - 1; ++index) {
            legToCurrentPoint = legs[index];
            legFromCurrentPoint = legs[index + 1];

            if (legToCurrentPoint.targetId !== legFromCurrentPoint.startId
                || legToCurrentPoint.targetType.toString() !== legFromCurrentPoint.startType.toString()) {
                throw new Error('Legs are not valid or not sorted');
            }

            currentPoint = legToCurrentPoint.targetType === TargetType.LUNCHPOINT
                ? Route.getLunch(lunches, legToCurrentPoint.targetId)
                : legToCurrentPoint.targetType === TargetType.LOADINGSTORE
                    ? Route.getLoadingStore(loadingStores, legToCurrentPoint.targetId)
                    : Route.getOrder(orders, legToCurrentPoint.targetId);
            if (currentPoint === undefined) {
                throw new Error(`Can not find point with id ${legToCurrentPoint.targetId}`);
            }

            handleLeg(legToCurrentPoint, index);
            handleRoutePoint(currentPoint, legToCurrentPoint, legFromCurrentPoint);
            handleLeg(legFromCurrentPoint, index + 1);
        }

        const targets = legs.filter(l => l.targetType === TargetType.ORDER).map(l => l.targetId);
        const noLegOrders = orders.filter(x => !targets.includes(x.id));

        for (const item of noLegOrders) {
            handleNoLegPoint(item);
        }

        handleFinishPoint(finish, finishLeg);
    }

    private readonly _all: (RoutePoint | Leg | undefined)[] = [];
    private _finishPoint: FinishRoutePoint | undefined;
    private readonly _legs: (Leg | undefined)[] = [];
    private readonly _orders: OrderRoutePoint[] = [];
    private readonly _points: RoutePoint[] = [];
    private _startPoint: StartRoutePoint | undefined;
    private readonly _routePoints: RoutePoint[] = [];
    private readonly _loadingStores: ReadonlyArray<LoadingStore> = [];
    private readonly _lunches: ReadonlyArray<Lunch> = [];
    private readonly _noLegPoints: OrderRoutePoint[] = [];
    private readonly _isProblemRoute?: boolean

    // tslint:disable-next-line: max-func-body-length
    public constructor(
        start: IIdentifiablePoint,
        finish: IIdentifiablePoint,
        orders: ReadonlyArray<Order>,
        loadingStores: ReadonlyArray<LoadingStore>,
        lunches: ReadonlyArray<Lunch>,
        legs: ReadonlyArray<Readonly<ILeg>>
    ) {
        this._loadingStores = loadingStores;
        this._lunches = lunches || [];
        Route.iterate(
            start,
            finish,
            orders,
            this._lunches,
            this._loadingStores,
            legs,
            (startPoint: IIdentifiablePoint, legFrom: ILeg | undefined) => {
                this.addStartPoint(
                    new StartRoutePoint(
                        startPoint.id,
                        `point_start_${startPoint.id}`,
                        startPoint.type,
                        new Geocode({
                            latitude: startPoint.latitude,
                            longitude: startPoint.longitude,
                        }),
                        undefined,
                        startPoint.address,
                        startPoint.expectedArrivalDateTime,
                        legFrom?.period?.start,
                        startPoint.actualArrivalDateTime,
                        undefined,
                        false
                    ),
                );
            },
            (finishPoint: IIdentifiablePoint, legTo: ILeg | undefined) => {
                this.addFinishPoint(
                    new FinishRoutePoint(
                        finishPoint.id,
                        `point_finish_${finishPoint.id}`,
                        finishPoint.type,
                        new Geocode({
                            latitude: finishPoint.latitude,
                            longitude: finishPoint.longitude,
                        }),
                        finishPoint.address,
                        finishPoint.expectedArrivalDateTime,
                        finishPoint.actualArrivalDateTime,
                        legTo ? legTo.distance : 0,
                    ),
                );
            },
            (routePoint: Order | Lunch | LoadingStore, legToCheckpoint: ILeg, legFromCheckpoint: ILeg) => {
                this.addRoutePoint(Route.createRoutePoint(routePoint, legToCheckpoint, legFromCheckpoint));
            },
            (routePoint: Order) => {
                this.addNoLegPoint(Route.createOrderRoutePoint(routePoint))
            },
            (leg: ILeg, index: number) => {
                const id = `leg_${index}`;

                const existingLeg: Leg | undefined = this._legs.find(
                    (legIterator) => legIterator !== undefined && legIterator.id === id,
                );

                if (existingLeg) {
                    return;
                }

                this.addLeg(new Leg(
                    id,
                    leg.waypoints.map((point: IGeocode) => new Geocode(
                        {
                            latitude: point.latitude,
                            longitude: point.longitude,
                        }),
                    ),
                    leg.distance
                ));
            }
        );

        orders
            .filter(
                (checkpoint) =>
                    this._orders.find((orderRoutePoint) => orderRoutePoint.originalId === checkpoint.id) ===
                    undefined,
            )
            .reverse()
            .forEach((checkpoint) => {
                this.addUnusedCheckpoint(Route.createOrderRoutePoint(checkpoint));
            });
    }

    public setLegs(legs: ReadonlyArray<ILeg>): Route {
        const getExpectedArrivalDateTime = (checkpoint: OrderRoutePoint): DateTime | undefined => {
            const legToCheckpoint = legs.find((leg) => leg.targetId === checkpoint.originalId);
            if (legToCheckpoint === undefined) {
                return checkpoint.expectedArrivalDateTime;
            }

            if (legToCheckpoint.period === undefined) {
                throw new Error(`Unknown time of leg arrival to target ${checkpoint.originalId}`);
            }

            return legToCheckpoint.period.end;
        };

        return new Route(
            {
                actualArrivalDateTime: this.startPoint.actualArrivalDateTime,
                address: this.startPoint.address,
                expectedArrivalDateTime: this.startPoint.expectedArrivalDateTime,
                id: this.startPoint.originalId,
                type: this.startPoint.type,
                latitude: this.startPoint.geocode.latitude,
                longitude: this.startPoint.geocode.longitude,
            },
            {
                actualArrivalDateTime: this.finishPoint.actualArrivalDateTime,
                address: this.finishPoint.address,
                expectedArrivalDateTime: this.finishPoint.expectedArrivalDateTime,
                id: this.finishPoint.originalId,
                type: this.finishPoint.type,
                latitude: this.finishPoint.geocode.latitude,
                longitude: this.finishPoint.geocode.longitude,
            },
            this._orders.map((orderRoutePoint) =>
                orderRoutePoint.order.setExpectedArrivalDateTime(getExpectedArrivalDateTime(orderRoutePoint)),
            ),
            this._loadingStores,
            this._lunches,
            legs
        );
    }

    private addFinishPoint(finishPoint: FinishRoutePoint): void {
        this._all.push(finishPoint);
        this._points.push(finishPoint);
        this._finishPoint = finishPoint;
    }

    private addLeg(leg: Leg): void {
        this._all.push(leg);
        this._legs.push(leg);
    }

    private addRoutePoint(checkpoint: RoutePoint): void {
        this._all.push(checkpoint);
        this._points.push(checkpoint);
        this._routePoints.push(checkpoint);
    }

    private addNoLegPoint(checkpoint: OrderRoutePoint): void {
        this._all.push(checkpoint);
        this._points.push(checkpoint);
        this._noLegPoints.push(checkpoint);
    }

    private addStartPoint(startPoint: StartRoutePoint): void {
        this._all.push(startPoint);
        this._points.push(startPoint);
        this._startPoint = startPoint;
    }

    private addUnusedCheckpoint(checkpoint: OrderRoutePoint): void {
        const index = this._startPoint === undefined ? 0 : 1;

        this._all.splice(index, 0, checkpoint);
        this._all.splice(index, 0, undefined);

        this._orders.unshift(checkpoint);
    }
}
