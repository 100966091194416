import { IMapRendererMarkerTooltip } from '../../Map/Renderer/IMapRendererMarkerTooltip';
import { IGeocode } from '../../Model/Geocode/IGeocode';
import { IMapMarker } from '../Map/IMapMarker';
import { MapColor } from '../Map/MapColor';
import { MapElementState } from '../Map/MapElementState';

export class PlanMapMarker implements IMapMarker {
    public get state(): MapElementState {
        return this._state;
    }

    public set state(value: MapElementState) {
        if (value !== this._state) {
            this._state = value;
            for (const stateChangeHandler of this._stateChangeHandlers) {
                stateChangeHandler(this._state);
            }
        }
    }

    public readonly className: string | undefined;
    public readonly clickable: boolean;
    public readonly color: MapColor | undefined;
    public readonly geocode: IGeocode;
    public readonly getStyle: undefined;

    public readonly tooltip: IMapRendererMarkerTooltip | undefined;
    public readonly translucent: boolean;

    public readonly tripIndex: number;

    private readonly _clickHandlers: (() => void)[] = [];
    private _state: MapElementState;
    private readonly _stateChangeHandlers: ((state: MapElementState) => void)[] = [];
    private readonly indexNumber: number | undefined;

    public constructor(
        geocode: IGeocode,
        state: MapElementState,
        tripIndex: number,
        color: MapColor | undefined,
        onClick: () => void,
        title: string | undefined,
        translucent: boolean,
        clickable: boolean = true,
        indexNumber?: number,
        className?: string
    ) {
        this.geocode = geocode;
        this._state = state;
        this.tripIndex = tripIndex;
        this.color = color;
        this.onClick(onClick);
        this.translucent = translucent;
        this.clickable = clickable;
        this.indexNumber = indexNumber;
        this.className = className;
        this.tooltip = undefined;

        if (title) {
            this.tooltip = {
                className: 'plan-map__marker-tooltip',
                isPermanent: false,
                text: title,
            };
        }
    }

    public get iconElementString(): string | undefined {
        return this.indexNumber === undefined || this.state === MapElementState.Secondary
            ? undefined
            : String(this.indexNumber);
    }

    public destroy(): void {
        this._clickHandlers.length = 0;
        this._stateChangeHandlers.length = 0;
    }

    public handleClick(): void {
        if (this.clickable) {
            for (const clickHandler of this._clickHandlers) {
                clickHandler();
            }
        }
    }

    public onChangeState(handler: (state: MapElementState) => void): void {
        this._stateChangeHandlers.push(handler);
    }

    private onClick(handler: () => void): void {
        this._clickHandlers.push(handler);
    }
}
