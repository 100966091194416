import { GeocodingApiApiCarMoveAndStopLocation, GeocodingApiCarMoveAndStopLocations } from "../../../../ApiClient/YosoGeocoding/models";
import { CarMoveAndStopLocation } from "../../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocation";
import { CarMoveAndStopLocations } from "../../../../Model/CarMoveAndStopLocation/CarMoveAndStopLocations";
import { Geocode } from "../../../../Model/Geocode/Geocode";
import { dateTimeDeserializer } from "../../Mapper/DateTimeDeserializer";

export class CarMoveAndStopLocationsMapper {
    public static mapArrayFromApi(locations: GeocodingApiCarMoveAndStopLocations[]): CarMoveAndStopLocations[] {
        return locations.map(CarMoveAndStopLocationsMapper.mapFromApi);
    }

    public static mapFromApi(locations: GeocodingApiCarMoveAndStopLocations): CarMoveAndStopLocations {
        return new CarMoveAndStopLocations(
            locations.tripId,
            locations.locations.map(CarMoveAndStopLocationsMapper.mapLocationsFromApi)
        );
    }

    public static mapLocationsFromApi(location: GeocodingApiApiCarMoveAndStopLocation): CarMoveAndStopLocation {
        return new CarMoveAndStopLocation(
            dateTimeDeserializer.deserialize(location.startTime),
            location.endTime ? dateTimeDeserializer.deserialize(location.endTime) : undefined,
            new Geocode({
                latitude: location.geocode.latitude,
                longitude: location.geocode.longitude,
            }),
            location.label
        );
    }
}