import {
    DeliveryApiCompletedOrdersProductResponse
} from "../../../../../ApiClient/Yoso/models";
import {
    ItineraryBalanceRow
} from "../../../../../Model/Itinerary/ItineraryBalanceRow";
import {
    ItineraryCompletedOrdersProducts
} from "../../../../../Model/Itinerary/ItineraryCompletedOrdersProducts";


export class CompletedOrdersProductsDeserializer {
    public deserialize(response: DeliveryApiCompletedOrdersProductResponse): ItineraryCompletedOrdersProducts {
        return new ItineraryCompletedOrdersProducts(
            response.shipment.map(p => new ItineraryBalanceRow(p.id, p.name, p.count)),
            response.returnProperty.map(p => new ItineraryBalanceRow(p.id, p.name, p.count))
        )
    }
}

export const completedOrdersProductsDeserializer = new CompletedOrdersProductsDeserializer();
