import {RouteInfo} from '../../Backend/Api/Delivery/Model/RouteInfo/RouteInfo';
import {DateTime} from '../../Std/DateTime';
import { DriverPoint } from '../DriverPoint/DriverPoint';
import {Store} from '../Store/Store';
import {TripComparisonOrder} from './TripComparisonOrder';
import {TripDriverPlace} from './TripDriverPlace';

export class TripComparison {
    private readonly tripId: number;
    private readonly name: string;
    private readonly date: DateTime;
    private readonly createdAt?: DateTime;
    private readonly orders: TripComparisonOrder[];
    private readonly driverPlaces: TripDriverPlace[];
    private readonly stores: Store[];
    private readonly startPoints: DriverPoint[];
    private readonly finishPoints: DriverPoint[];
    private readonly intermediatePoints: DriverPoint[];
    private readonly startPoint: DriverPoint | undefined;
    private readonly finishPoint: DriverPoint | undefined;
    private readonly firstPoint: DriverPoint | TripComparisonOrder | undefined;
    private readonly startTime: DateTime | undefined;
    private readonly routes: RouteInfo[];

    public constructor(
        tripId: number,
        name: string,
        date: DateTime,
        createdAt: DateTime | undefined,
        orders: TripComparisonOrder[],
        driverPlaces: TripDriverPlace[],
        stores: Store[],
        startPoints: DriverPoint[],
        finishPoints: DriverPoint[],
        intermediatePoints: DriverPoint[],
        startPoint: DriverPoint | undefined,
        finishPoint: DriverPoint | undefined,
        firstPoint: DriverPoint | TripComparisonOrder | undefined,
        startTime: DateTime | undefined,
        routes: RouteInfo[]
    ) {
        this.tripId = tripId;
        this.name = name;
        this.date = date;
        this.createdAt = createdAt;
        this.orders = orders;
        this.driverPlaces = driverPlaces;
        this.stores = stores;
        this.startPoints = startPoints;
        this.finishPoints = finishPoints;
        this.intermediatePoints = intermediatePoints;
        this.startPoint = startPoint;
        this.finishPoint = finishPoint;
        this.startTime = startTime;
        this.routes = routes;
        this.firstPoint = firstPoint;
    }

    public getName(): string {
        return this.name;
    }

    public getDate(): DateTime {
        return this.date;
    }

    public getOrders(): TripComparisonOrder[] {
        return this.orders;
    }

    public getDriverPlaces(): TripDriverPlace[] {
        return this.driverPlaces;
    }

    public getRoutes(): RouteInfo[] {
        return this.routes;
    }

    public getStartPoints(): DriverPoint[] {
        return this.startPoints;
    }

    public getFinishPoints(): DriverPoint[] {
        return this.finishPoints;
    }

    public getIntermediatePoints(): DriverPoint[] {
        return this.intermediatePoints;
    }

    public getStartPoint(): DriverPoint | undefined {
        return this.startPoint;
    }

    public getFinishPoint(): DriverPoint | undefined {
        return this.finishPoint;
    }

    public getFirstPoint(): DriverPoint | TripComparisonOrder | undefined {
        return this.firstPoint;
    }

    public getStartTime(): DateTime | undefined {
        return this.startTime;
    }

    public getStores(): Store[] {
        return this.stores
    }
}