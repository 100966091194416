import {
    DeliveryApiCar,
    DeliveryApiDistrict,
    DeliveryApiDriver,
    DeliveryApiLoadingStore,
    DeliveryApiLunch,
    DeliveryApiOrder,
    DeliveryApiPlan,
    DeliveryApiProduct,
    DeliveryApiStore,
} from '@/ApiClient/Yoso/models';
import { District } from '@/Model/District/District';
import { Store } from '@/Model/Store/Store';
import { Trip } from '@/Model/Trip/Trip';
import { TripPlan } from '@/Model/TripPlan/TripPlan';
import { districtSerializer } from '../../../Company/Model/District/DistrictSerializer';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { lunchDeserializer } from '../Lunch/LunchDeserializer';
import { orderDeserializer } from '../Order/OrderDeserializer';
import { storeDeserializer } from '../Store/StoreDeserializer';
import { tripDeserializer } from '../Trip/TripDeserializer';
import { tripWorkingShiftDeserializer } from '../TripWorkingShift/TripWorkingShiftDeserializer';

export class PlanDeserializer {
    public deserialize(
        serializedObject: DeliveryApiPlan,
        stores: ReadonlyArray<DeliveryApiStore>,
        cars: ReadonlyArray<DeliveryApiCar>,
        drivers: ReadonlyArray<DeliveryApiDriver>,
        orders: ReadonlyArray<DeliveryApiOrder>,
        products: ReadonlyArray<DeliveryApiProduct>,
        districts: ReadonlyArray<DeliveryApiDistrict>,
        lunches: ReadonlyArray<DeliveryApiLunch>,
        loadingStores: ReadonlyArray<DeliveryApiLoadingStore>,
        alternativePlanId?: number
    ): TripPlan {
        const planDateTimePeriod = dateTimePeriodDeserializer.deserialize(serializedObject.dateTimePeriod);

        return new TripPlan(
            serializedObject.id,
            serializedObject.isPlanDayOff,
            planDateTimePeriod,
            orders.map((order) => {
                const orderTrip = serializedObject.trips.find((trip) => trip.id === order.tripId);
                if (orderTrip === undefined) {
                    throw new Error(`Can not find trip with id ${order.tripId}`);
                }

                return orderDeserializer.deserialize(
                    order,
                    cars,
                    orderTrip.carId,
                    drivers,
                    orderTrip.driverId,
                    products,
                    tripWorkingShiftDeserializer.deserialize(orderTrip.workingShift)
                );
            }),
            serializedObject.trips
                .map(
                    (trip): Trip =>
                        tripDeserializer.deserialize(
                            trip,
                            cars,
                            drivers,
                            orders,
                            planDateTimePeriod,
                            products,
                            loadingStores,
                            lunches,
                        ),
                )
                .sort((trip1, trip2) => trip1.executor.driver.compareDriverNames(trip2.executor.driver)),
            stores.map(
                (store): Store => storeDeserializer.deserialize(store)
            ),
            districts.map(
                (district): District => districtSerializer.deserialize(district)
            ),
            lunches.map((lunch) => {
                const lunchTrip = serializedObject.trips.find((trip) => trip.id === lunch.tripId);
                if (lunchTrip === undefined) {
                    throw new Error(`Can not find trip with id ${lunch.tripId}`);
                }

                return lunchDeserializer.deserialize(lunch);
            }),
            alternativePlanId,
            serializedObject.departPeriods
        );
    }
}

export const planDeserializer = new PlanDeserializer();
