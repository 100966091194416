import React from 'react'
import { LatLngExpression } from 'leaflet'
import { IGeocode } from '../../Model/Geocode/IGeocode'
import { MapContext } from '../MapContainer/MapContextProvider';
import Polygon from '../MapContainer/components/Polygon';
import ZonePopup from './ZonePopup'

interface ZoneEditorProps {
    zone: IGeocode[],
    setZone: (zone: IGeocode[]) => void;
    onZoneSave: () => Promise<void>;
    onZoneDelete: () => Promise<void>;
}

const ZoneMapEditor = (props: ZoneEditorProps) => {
    const geometryContext = React.useContext(MapContext)?.editorOnMap;
    if (!geometryContext) {
        throw new Error("Geometry context is undefined");
    }

    const [popupPosition, setPopupPosition] = React.useState<LatLngExpression | undefined>(undefined);

    React.useEffect(() => {
        geometryContext.setSelfIntersection(false);
        geometryContext.onPolygonCreate((id: number, coords: IGeocode[], color: string) => {
            props.setZone(coords);
        });
    }, [])

    React.useEffect(() => {
        geometryContext.enablePolygonDraw(props.zone.length === 0);
        geometryContext.enableEditing(props.zone.length > 0);
        if (!props.zone) {
            setPopupPosition(undefined);
        }
    }, [props.zone]);

    const onPolygonClick = React.useCallback((e: any) => {
        setPopupPosition(e.latlng);
    }, []);

    const onPolygonEdit = React.useCallback((coords: IGeocode[]) => {
        props.setZone(coords);
    }, []);

    return (
        <>
            <Polygon
                coords={props.zone}
                onClick={onPolygonClick}
                onEdit={onPolygonEdit}/>
            <ZonePopup
                position={popupPosition}
                onSave={props.onZoneSave}
                onDelete={props.onZoneDelete}
            />
        </>
    );
}

export default ZoneMapEditor;