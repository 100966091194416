import React from 'react';
import {
    ReactComponent as LinkIconComponent
} from '../../../../icons/external_link.svg';
import styles from './Mailbox.module.css';

interface MailProps {
    text: string;
    dateStr: string;
    link?: () => void;
}

const Mail = (props: MailProps): JSX.Element => {
    const {text, link, dateStr} = props

    return (
        <div className={styles.mail}>
            <div>
                {text}
                {link &&
                    <span className={styles.link}>
                        {' '}<LinkIconComponent onClick={link} />
                    </span>
                }
            </div>
            <div className={styles.time}>{dateStr}</div>
        </div>
    );
};

export default Mail;