import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import * as React from 'react';
import {Link, RouteComponentProps, withRouter} from 'react-router-dom';

import { CompanyApiBotResponse } from '../../../../ApiClient/YosoCompany/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './BotListRoute.css';

export interface IBotListRouteProps extends RouteComponentProps {}

export const BotListRoute = withRouter(
    (props: IBotListRouteProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [bots, setBots] = React.useState([] as CompanyApiBotResponse[]);

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getBotList();
                    if (cancelled) {
                        return;
                    }

                    setBots(response);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, []);

        return (
            <Page header="Боты">
                <Link to="/bot/new">
                    <Button type="primary" className="bot-list-route__button" icon={<PlusOutlined />}>
                        Добавить бота
                    </Button>
                </Link>

                <EntityList<CompanyApiBotResponse>
                    data={bots}
                    loading={loading}
                    id={(bot) => bot.employee!.id}
                    columns={[
                        {
                            key: 'name',
                            title: 'Имя',
                            render: (bot) => bot.employee!.name,
                        },
                        {
                            key: 'username',
                            title: 'Логин',
                            render: (bot) => bot.employee!.credentials!.username,
                        },
                    ]}
                />
            </Page>
        );
    },
);
