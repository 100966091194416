import { Route } from '@/Map/Route/Route';
import { ProblemRouteType } from '@/Model/Trip/ProblemRouteType';
import { DateTimePeriod } from '@/Std/DateTimePeriod';
import { Duration } from '@/Std/Duration';
import { CarTrackedLocation } from '../CarTrackedLocation/CarTrackedLocation';
import { TripExecutor } from '../TripExecutor/TripExecutor';

import { TripStatus } from './TripStatus';
import {TripWorkingShift} from "./TripWorkingShift";

export class Trip {
    public readonly actualDistance: number | undefined;
    public readonly actualDuration: Duration | undefined;
    public readonly actualRoute: ReadonlyArray<CarTrackedLocation>;
    public readonly dateTimePeriod: DateTimePeriod;
    public readonly ordersCount: number;
    public readonly completedOrdersCount: number;
    public readonly failedOrdersCount: number;
    public readonly rescheduledOrdersCount: number;
    public readonly estimatedDistance: number | undefined;
    public readonly estimatedDuration: Duration | undefined;
    public readonly executor: TripExecutor;
    public readonly id: number;
    public readonly isRouteBuilt: boolean;
    public readonly status: TripStatus;
    public readonly workingShift: TripWorkingShift | undefined;
    public readonly problemRoute: ProblemRouteType | undefined
    private _route: Route | undefined;

    public constructor(
        id: number,
        executor: TripExecutor,
        ordersCount: number,
        completedOrdersCount: number,
        failedOrdersCount: number,
        rescheduledOrdersCount: number,
        route: Route | undefined,
        isRouteBuilt: boolean,
        actualRoute: ReadonlyArray<CarTrackedLocation>,
        estimatedDistance: number | undefined,
        actualDistance: number | undefined,
        dateTimePeriod: DateTimePeriod,
        estimatedDuration: Duration | undefined,
        actualDuration: Duration | undefined,
        status: TripStatus,
        problemRoute: ProblemRouteType | undefined,
        workingShift?: TripWorkingShift | undefined
    ) {
        this.executor = executor;
        this.ordersCount = ordersCount;
        this.completedOrdersCount = completedOrdersCount;
        this.failedOrdersCount = failedOrdersCount;
        this.rescheduledOrdersCount = rescheduledOrdersCount;
        this.id = id;
        this._route = route;
        this.isRouteBuilt = isRouteBuilt;
        this.actualRoute = actualRoute;
        this.estimatedDistance = estimatedDistance;
        this.actualDistance = actualDistance;
        this.dateTimePeriod = dateTimePeriod;
        this.estimatedDuration = estimatedDuration;
        this.actualDuration = actualDuration;
        this.status = status;
        this.workingShift = workingShift;
        this.problemRoute = problemRoute;
    }

    public get route(): Route {
        if (this._route === undefined) {
            throw new Error('The trip has no route');
        }

        return this._route;
    }

    public isRouteSet(): boolean {
        return this._route !== undefined;
    }

    public setRoute(route: Route): void {
        this._route = route;
    }

    public resetRoute(): void {
        this._route = undefined;
    }

    public compareTrips(trip: Trip) {
        if (this.status !== trip.status) {
            if (this.status === TripStatus.RouteBuildFailed) {
                return -1;
            }
            if (trip.status === TripStatus.RouteBuildFailed) {
                return 1;
            }
        }

        const driverNameComparing = this.executor.driver.compareDriverNames(trip.executor.driver);
        if (!driverNameComparing) {
            return Number(this.workingShift) - Number(trip.workingShift);
        }

        return driverNameComparing;
    }
}
