import React from 'react';
import {observer} from 'mobx-react';
import {Button, Modal, Spin} from 'antd';
import {LatLngExpression} from 'leaflet';
import {withRouter} from 'react-router-dom';
import {useCompany} from '../../../AppProviders/Providers/CompanyProvider';
import MapContainer from '../../../MapContainer/MapContainer';
import {Page} from '../../../Page/Page';
import StoreSelect from '../../../StoreSelect/StoreSelect';
import {WarehouseStore} from '../../../../Store/WarehouseStore/WarehouseStore';
import StoreList from './StoreList';
import './StoreListRoute.css'

export const StoreListRoute = withRouter(observer(() => {
    const companyContext = useCompany();
    const {company} = companyContext;

    const warehouseStore = React.useMemo(() => new WarehouseStore(), []);

    const [center, setCenter] = React.useState<LatLngExpression | undefined>(undefined);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        warehouseStore.fetchWarehouses();
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (!company) {
            return undefined;
        }

        setCenter({
            lat: company.centerLatitude,
            lng: company.centerLongitude
        });
    }, [company]);

    const save = async () => {
        await warehouseStore.save();
        Modal.success({
            content: 'Изменения сохранены'
        });
    }

    return (
        <Page header='Склады'>
            <Spin spinning={loading}>
                <div className='store-list__container'>
                    <div className='store-list__editor'>
                        <StoreList store={warehouseStore}/>
                        <Button
                            type='primary'
                            className='store-list__save-button'
                            onClick={save}
                            disabled={warehouseStore.warehouses.some(w => w.address === '')}
                        >
                            Сохранить изменения
                        </Button>
                    </div>
                    {center && (
                        <div className='store-list__map-container'>
                            <MapContainer
                                center={center}
                                zoomValue={12}
                            >
                                <StoreSelect store={warehouseStore}/>
                            </MapContainer>
                        </div>
                    )}
                </div>
            </Spin>
        </Page>
    );
}))
