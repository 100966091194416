export class Car {
    public static sortCarArray(array: ReadonlyArray<Car>): ReadonlyArray<Car> {
        const result = array.slice();
        result.sort((car1, car2) => car1.compareCarNumbers(car2));

        return result;
    }

    public readonly capacity: number;
    public readonly carNumber: string;
    public readonly id: number;

    public constructor(id: number, carNumber: string, capacity: number) {
        this.id = id;
        this.carNumber = carNumber;
        this.capacity = capacity;
    }

    public compareCarNumbers(car: Car): number {
        const carNumber1 = this.getNormalizedCarNumber();
        const carNumber2 = car.getNormalizedCarNumber();

        return carNumber1 === carNumber2 ? 0 : carNumber1 < carNumber2 ? -1 : 1;
    }

    private getNormalizedCarNumber(): string {
        return this.carNumber.replace('_', '');
    }
}
