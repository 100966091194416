import { Duration } from '../../Std/Duration';
import { Car } from '../Car/Car';
import { Driver } from '../Driver/Driver';
import { TripWorkingShift } from '../Trip/TripWorkingShift';

export class TripAnalysisSummary {
    public readonly actualCompleted: boolean;
    public readonly actualDistance: number;
    public readonly actualDuration: Duration;
    public readonly car: Car;
    public readonly changed: boolean;
    public readonly distance: number;
    public readonly driver: Driver;
    public readonly duration: Duration;
    public readonly estimatedDistance: number;
    public readonly estimatedDuration: Duration;
    public readonly tripId: number;
    public readonly arrivalLTMinus30: number | undefined;
    public readonly arrivalBtwMinus30Plus30: number | undefined;
    public readonly arrivalBtwPlus31Plus60: number | undefined;
    public readonly arrivalGTPlus60: number | undefined;
    public readonly completedOrdersCount: number;
    public readonly failedOrdersCount: number;
    public readonly rescheduledOrdersCount: number;
    public readonly workingShift?: TripWorkingShift;

    public constructor(
        tripId: number,
        driver: Driver,
        car: Car,
        estimatedDistance: number,
        estimatedDuration: Duration,
        changed: boolean,
        distance: number,
        duration: Duration,
        actualCompleted: boolean,
        actualDistance: number,
        actualDuration: Duration,
        arrivalLTMinus30: number | undefined,
        arrivalBtwMinus30Plus30: number | undefined,
        arrivalBtwPlus31Plus60: number | undefined,
        arrivalGTPlus60: number | undefined,
        completedOrdersCount: number,
        failedOrdersCount: number,
        rescheduledOrdersCount: number,
        workingShift?: TripWorkingShift
    ) {
        this.tripId = tripId;
        this.actualCompleted = actualCompleted;
        this.actualDistance = actualDistance;
        this.actualDuration = actualDuration;
        this.car = car;
        this.driver = driver;
        this.estimatedDistance = estimatedDistance;
        this.estimatedDuration = estimatedDuration;
        this.changed = changed;
        this.distance = distance;
        this.duration = duration;
        this.arrivalLTMinus30 = arrivalLTMinus30;
        this.arrivalBtwMinus30Plus30 = arrivalBtwMinus30Plus30;
        this.arrivalBtwPlus31Plus60 = arrivalBtwPlus31Plus60;
        this.arrivalGTPlus60 = arrivalGTPlus60;
        this.completedOrdersCount = completedOrdersCount;
        this.failedOrdersCount = failedOrdersCount;
        this.rescheduledOrdersCount = rescheduledOrdersCount;
        this.workingShift = workingShift;
    }

    public getArrivalOrdersCount(): number | undefined {
        if (this.arrivalLTMinus30 === undefined
            || this.arrivalBtwMinus30Plus30 === undefined
            || this.arrivalBtwPlus31Plus60 === undefined
            || this.arrivalGTPlus60 === undefined) {
                return undefined;
        }

        return this.arrivalLTMinus30 + this.arrivalBtwMinus30Plus30 + this.arrivalBtwPlus31Plus60 + this.arrivalGTPlus60;
    }

    public compareTrips(trip: TripAnalysisSummary) {
        const driverNameComparing = this.driver.compareDriverNames(trip.driver);
        if (!driverNameComparing) {
            return this.tripId - trip.tripId;
        }

        return driverNameComparing;
    }
}
