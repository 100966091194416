import {
    DeliveryApiImportOrderBindingsResponse
} from '@/ApiClient/Yoso/models';
import {Geocode} from '@/Model/Geocode/Geocode';
import {ImportOrder} from '@/Model/ImportOrder/ImportOrder';
import { dateTimePeriodDeserializer } from '../../Model/DateTimePeriod/DateTimePeriodDeserializer';

export class GetImportOrdersResponseDeserializer {
    public deserialize(response: DeliveryApiImportOrderBindingsResponse): ImportOrder[] {
        return response.importOrders.map(i =>
            new ImportOrder(
                i.id,
                i.address,
                dateTimePeriodDeserializer.deserialize(i.dateTimePeriod),
                i.latitude && i.longitude
                    ? new Geocode(
                        {latitude: i.latitude, longitude: i.longitude}
                    )
                    : undefined,
                    i.nominatimId,
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    i.nominatimBoundingBox && i.nominatimBoundingBox.length === 4
                    ? [
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        Geocode.fromLatLon(i.nominatimBoundingBox[0], i.nominatimBoundingBox[1]),
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        Geocode.fromLatLon(i.nominatimBoundingBox[2], i.nominatimBoundingBox[3])]
                    : undefined
            )
        )
    }
}

export const getImportOrdersResponseDeserializer = new GetImportOrdersResponseDeserializer();