import { ProductOperationType } from '../Model/ProductOperation/ProductOperationType';

import { Formatter } from './Formatter';
import { IFormatter } from './IFormatter';

export class ProductOperationTypeFormatter implements IFormatter<ProductOperationType> {
    public default(obj: ProductOperationType): string {
        return obj === ProductOperationType.Return
            ? 'Возврат'
            : obj === ProductOperationType.Shipment
            ? 'Доставка'
            : Formatter.unknownEnumValue(obj);
    }
}

export const productOperationTypeFormatter = new ProductOperationTypeFormatter();
