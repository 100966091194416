import { ArrayUtils } from './ArrayUtils';

export class StringUtils {
    public static split(value: string, separators: string[]): ReadonlyArray<string> {
        let values = [value];

        separators.forEach((separator) => {
            const splittedValues = values.map((valuesItem) =>
                valuesItem.split(separator).filter((part) => part !== ''),
            );
            values = ArrayUtils.flatten(splittedValues);
        });

        return values;
    }

    public static trim(value: string): string {
        return value.trim();
    }
}
