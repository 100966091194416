import classNames from 'classnames';
import * as React from 'react';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { ReactComponent as MoonImageComponent } from '../../icons/moon.svg';
import { ReactComponent as SunImageComponent } from '../../icons/sun.svg';
import { TripWorkingShift } from '../../Model/Trip/TripWorkingShift';
import {
    TripStatsSummary,
} from '../../Model/TripStatsSummary/TripStatsSummary';
import { ArrayUtils } from '../../Std/ArrayUtils';
import {
    formatDurationHoursMinutes,
} from '../../Std/formatDurationHoursMinutes';
import { formatNumber } from '../../Std/FormatNumber';
import { MathUtils } from '../../Std/MathUtils';
import { EntityList } from '../EntityList/EntityList';
import { LoadingInfo } from '../LoadingInfo/LoadingInfo';
import { Map } from '../Map/Map';
import { TripStatusComponent } from '../TripStatus/TripStatusComponent';

import './TripPlanReport.css';

export interface ITripPlanReportProps {
    id: number;
    onTripClick?: (tripId: number) => void;
}

export const TripPlanReport = (props: ITripPlanReportProps): JSX.Element => {
    const [tripPlanStats, setTripPlanStats] = React.useState([] as TripStatsSummary[]);
    const [loading, setLoading] = React.useState(tripPlanStats === undefined);

    const loadData = React.useCallback(async () => {
        setLoading(true);

        try {
            setTripPlanStats((await DeliveryApi.getPlanStatistics(props.id)).tripStats);
        } catch (error) {
            alert(String(error));
        }

        setLoading(false);
    }, [props.id]);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        loadData();
    }, [loadData]);

    return (
        <div className="trip-plan-report">
            <EntityList<TripStatsSummary>
                data={ArrayUtils.sort(tripPlanStats, [by => by.driver.name, by => by.workingShift === TripWorkingShift.MORNING ? 0 : 1])}
                loading={loading}
                actions={{
                    item: {
                        click:
                            props.onTripClick === undefined
                                ? undefined
                                : (id: number) => (): undefined => {
                                      if (props.onTripClick !== undefined) {
                                          props.onTripClick(id);

                                          return;
                                      }
                                  },
                    },
                }}
                id={(tripStatsSummary) => tripStatsSummary.id}
                rowColorMarker={
                    Map.colorScheme === undefined
                        ? undefined
                        : (trip, index) =>
                              Map.colorScheme.renderColor(Map.colorScheme.get(index)).rgbaString
                }
                columns={[
                    {
                        key: 'car',
                        render: (tripStatsSummary) => (
                            <div className='trip-plan-report__driver-block'>
                                <div>
                                    {tripStatsSummary.workingShift === TripWorkingShift.MORNING &&
                                        <SunImageComponent className='trip-plan-report__icon trip-plan-report__icon_yellow' />}
                                    {tripStatsSummary.workingShift === TripWorkingShift.EVENING &&
                                        <MoonImageComponent className='trip-plan-report__icon trip-plan-report__icon_blue' />}
                                </div>
                                <div>
                                    {tripStatsSummary.car.carNumber && (
                                        <>
                                            {tripStatsSummary.car.carNumber}
                                            <br />
                                        </>
                                    )}
                                    {tripStatsSummary.driver.name}
                                </div>
                            </div>
                        ),
                        title: 'Водитель',
                    },
                    {
                        key: 'orders',
                        render: (tripStatsSummary) => (
                            <LoadingInfo
                                actual={tripStatsSummary.doneOrderCount + tripStatsSummary.failedOrderCount}
                                total={tripStatsSummary.scheduledOrderCount}
                                content={
                                    <span>
                                        {tripStatsSummary.scheduledOrderCount} ({tripStatsSummary.doneOrderCount}/
                                        <span
                                            className={classNames(
                                                tripStatsSummary.failedOrderCount > 0 &&
                                                    'trip-plan-report__warning-text',
                                            )}
                                        >
                                            {tripStatsSummary.failedOrderCount}
                                        </span>
                                        )
                                    </span>
                                }
                            />
                        ),
                        title: 'Заказы',
                    },
                    {
                        key: 'products',
                        render: (tripStatsSummary) => (
                            <span>
                                {tripStatsSummary.estimatedProductCount} ({tripStatsSummary.doneProductCount}/
                                <span
                                    className={classNames(
                                        tripStatsSummary.failedProductCount > 0 && 'trip-plan-report__warning-text',
                                    )}
                                >
                                    {tripStatsSummary.failedProductCount}
                                </span>
                                )
                            </span>
                        ),
                        title: 'Товары',
                    },
                    {
                        key: 'delayCount',
                        render: (tripStatsSummary) => (
                            <span
                                className={classNames(
                                    tripStatsSummary.delayCount > 0 && 'trip-plan-report__warning-text',
                                )}
                            >
                                {tripStatsSummary.delayCount === 0 ? '\u2013' : tripStatsSummary.delayCount}
                            </span>
                        ),
                        title: 'Опоздания',
                    },
                    {
                        key: 'adjustmentCount',
                        render: (tripStatsSummary) => (
                            <span
                                className={classNames(
                                    tripStatsSummary.adjustmentCount > 0 && 'trip-plan-report__warning-text',
                                )}
                            >
                                {tripStatsSummary.adjustmentCount === 0 ? '\u2013' : tripStatsSummary.adjustmentCount}
                            </span>
                        ),
                        title: <span>Изменённых <br/> заказов</span>,
                    },
                    {
                      key: 'movedCount',
                      render: (tripStatsSummary) => (
                        <span
                          className={classNames(
                            tripStatsSummary.movedOrdersCount > 0 && 'trip-plan-report__warning-text',
                          )}
                        >
                                  {tripStatsSummary.movedOrdersCount === 0 ? '\u2013' : tripStatsSummary.movedOrdersCount}
                              </span>
                      ),
                        title: <span>Переданных <br/> заказов</span>,
                    },
                    {
                      key: 'receivedCount',
                      render: (tripStatsSummary) => (
                        <span
                          className={classNames(
                            tripStatsSummary.receivedOrdersCount > 0 && 'trip-plan-report__warning-text',
                          )}
                        >
                                  {tripStatsSummary.receivedOrdersCount === 0 ? '\u2013' : tripStatsSummary.receivedOrdersCount}
                              </span>
                      ),
                        title: <span>Принятых <br/> заказов</span>,
                    },
                    {
                        key: 'distance',
                        render: (tripStatsSummary) => (
                            <span>
                                {formatNumber(
                                    MathUtils.round(
                                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                        tripStatsSummary.estimatedDistance / 1000,
                                    ),
                                )}{' '}
                                /{' '}
                                <span
                                    className={classNames(
                                        MathUtils.round(
                                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                            tripStatsSummary.actualDistance / 1000,
                                        ) !==
                                            MathUtils.round(
                                                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                                tripStatsSummary.estimatedDistance / 1000,
                                            ) && 'trip-plan-report__warning-text',
                                    )}
                                >
                                    {formatNumber(
                                        MathUtils.round(
                                            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                                            tripStatsSummary.actualDistance / 1000,
                                        ),
                                    )}
                                </span>
                            </span>
                        ),
                        title: 'Пробег,\nплан/факт (км)',
                    },
                    {
                        key: 'duration',
                        render: (tripStatsSummary) => (
                            <span>
                                {formatDurationHoursMinutes(tripStatsSummary.estimatedDuration)} / <br />
                                <span
                                    className={classNames(
                                        tripStatsSummary.actualDuration !== tripStatsSummary.estimatedDuration &&
                                            'trip-plan-report__warning-text',
                                    )}
                                >
                                    {formatDurationHoursMinutes(tripStatsSummary.actualDuration)}
                                </span>
                            </span>
                        ),
                        title: 'Время,\nплан/факт',
                    },
                    {
                        key: 'money',
                        render: (tripStatsSummary) => (
                            <span>
                                {formatNumber(tripStatsSummary.estimatedPrice)} /{' '}
                                <span
                                    className={classNames(
                                        tripStatsSummary.actualPrice !== tripStatsSummary.estimatedPrice &&
                                            'trip-plan-report__warning-text',
                                    )}
                                >
                                    {formatNumber(tripStatsSummary.actualPrice)}
                                </span>
                            </span>
                        ),
                        title: 'Деньги,\nплан/факт (руб)',
                    },
                    {
                        key: 'status',
                        render: (tripStatsSummary) => <TripStatusComponent status={tripStatsSummary.status} />,
                        title: 'Статус',
                    },
                ]}
                pageSize={0}
            />
        </div>
    );
};
