import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AuthApi } from '../../../../Backend/Api/Auth/AuthApi';
import { User } from '../../../../Model/User/User';
import { UserRole } from '../../../../Model/User/UserRole';

import { AuthLocalStorageStorage } from './AuthStorage/AuthLocalStorageStorage';

export interface IAuthData {
    user: User | undefined;
}

export const AuthContext = React.createContext({
    checkToken: (undefined as any) as () => Promise<undefined>,
    data: (undefined as any) as IAuthData,
    login: (undefined as any) as (userName: string, password: string) => Promise<undefined>,
    logout: (undefined as any) as () => Promise<undefined>,
});

export interface IAuthProviderProps extends RouteComponentProps {}

export const AuthProvider = withRouter(
    (props: IAuthProviderProps): JSX.Element => {
        const [storage] = React.useState(new AuthLocalStorageStorage());

        const checkToken = async (): Promise<undefined> => {
            await AuthApi.checkToken();

            return;
        };

        const login = async (username: string, password: string): Promise<undefined> => {
            const loginResponse = await AuthApi.login({
                username: username,
                password: password,
            });

            storage.user = new User(username, loginResponse.token, new UserRole(loginResponse.role));
            props.history.replace('/');

            return;
        };

        const logout = async (): Promise<undefined> => {
            storage.user = undefined;
            props.history.replace('/login');

            return;
        };

        const data: IAuthData = {
            user: storage.user,
        };

        return <AuthContext.Provider value={{ data, login, logout, checkToken }} {...props} />;
    },
);

export const useAuth = () => React.useContext(AuthContext);
