/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import {ThunderboltOutlined} from '@ant-design/icons';
import {Geocode} from '../../../Model/Geocode/Geocode';
import { IGeocode } from '../../../Model/Geocode/IGeocode';
import { Duration } from '../../../Std/Duration';
import { formatDistanceInKilometers } from '../../../Std/FormatDistanceInKilometers';
import { formatDurationHoursMinutes } from '../../../Std/formatDurationHoursMinutes';
import {MapContext} from '../../MapContainer/MapContextProvider';
import {MapControl} from '../../MapContainer/MapControl';
import {Marker} from '../../MapContainer/model/Marker';
import {Path} from '../../MapContainer/model/Path';
import styles from './WaypointsOnMap.module.css'

interface RouteItem { point: Geocode, pathToPoint: Geocode[], content?: string, tooltip?: string, distance: number, duration: number }

interface WaypointsOnMapProps {
    route: RouteItem[];
}

enum PathType {
    Line,
    Polyline
}

const WaypointsOnMap = (props: WaypointsOnMapProps) => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error("Map context is undefined");
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    const [selectedRouteItem, setSelectedRouteItem] = React.useState<RouteItem | undefined>(undefined);
    const [pathType, setPathType] = React.useState<PathType>(PathType.Polyline);

    React.useEffect(() => {
        const markers: Marker[] = []
        const paths: Path[] = []

        const groups: {[key: string]: RouteItem[]} = {};

        const getPointKey = (point: Geocode): string => {
            const precision = 1e5;
            const lat = Math.round(point.latitude * precision) / precision;
            const lon = Math.round(point.longitude * precision) / precision;

            return `${lat}-${lon}`;
        }

        const getMarkerOffset = (index: number, count: number): IGeocode => {
            const offset = 5e-5;
            if (count === 2) {
                if(index === 0) {
                    return {latitude: 0, longitude: -offset}
                }
                if(index === 1) {
                    return {latitude: 0, longitude: +offset}
                }
            } else if (count > 2) {
                console.log(count);
            }

            return {latitude: 0, longitude: 0};
        }

        props.route.forEach(item => {
            const key = getPointKey(item.point);

            if (!(key in groups)) {
                groups[key] = [];
            }

            groups[key].push(item);
        })

        props.route.forEach(item => {
            const key = getPointKey(item.point);
            const markerOffset = getMarkerOffset(groups[key].indexOf(item), groups[key].length);
            const selected = item === selectedRouteItem;

            const marker = new Marker(
                item.point.latitude + markerOffset.latitude,
                item.point.longitude + markerOffset.longitude,
                item.content,
                true,
                selected
                    ? {
                        text: `${formatDistanceInKilometers(item.distance)}<br/>${formatDurationHoursMinutes(Duration.fromSeconds(item.duration))}`,
                        isPermanent: true
                    }
                    : item.tooltip
                        ? {
                            text: item.tooltip
                        } : undefined,
                undefined,
                styles.marker
            )

            marker.onClick(() => setSelectedRouteItem(selectedRouteItem !== item ? item : undefined))

            markers.push(marker);
            paths.push(new Path(
              pathType === PathType.Polyline
                ? item.pathToPoint
                : [item.pathToPoint[0], item.point],
              selected ? styles.selectedPath : styles.path,
              '#7B7BFFA5'
            ))
        })

        layoutContext.renderMarkers(markers);
        layoutContext.renderPath(paths);
    }, [props.route, selectedRouteItem, pathType])

    React.useEffect(() =>  () => {
        layoutContext.clear();        
    }, [layoutContext])

    return (
      <MapControl position='topleft' separate={false}>
          <a
            className={styles.mapControl}
            onClick={() => setPathType(pathType === PathType.Line ? PathType.Polyline : PathType.Line)}
          >
              <ThunderboltOutlined />
          </a>
      </MapControl>
    );
};

export default WaypointsOnMap;