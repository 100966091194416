import { DateTime } from '../../Std/DateTime';
import { Car } from '../Car/Car';
import { DraftOrder } from '../DraftOrder/DraftOrder';
import { Driver } from '../Driver/Driver';
import { Product } from '../Product/Product';
import { WarningMessage } from '../WarningMessage/WarningMessage';
import { DistrictGroup } from '../../Store/District/DistrictGroup';

export class DraftPlanData {
    public readonly cars: Car[];
    public readonly drivers: Driver[];
    public readonly orders: DraftOrder[];
    public readonly products: Product[];
    public readonly districtsGroups: DistrictGroup[];
    public readonly warningMessages: WarningMessage[];
    public readonly uploadedAt: DateTime;

    public constructor(orders: DraftOrder[],
                       products: Product[],
                       drivers: Driver[],
                       cars: Car[],
                       districtsGroups: DistrictGroup[],
                       warningMessages: WarningMessage[],
                       uploadedAt: DateTime
    ) {
        this.cars = cars;
        this.drivers = drivers;
        this.orders = orders;
        this.products = products;
        this.districtsGroups = districtsGroups;
        this.warningMessages = warningMessages;
        this.uploadedAt = uploadedAt;
    }
}
