import { DeliveryApiLunch } from '../../../../../ApiClient/Yoso/models';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { Lunch } from '../../../../../Model/Lunch/Lunch';
import { dateTimeDeserializer } from '../../../Mapper/DateTimeDeserializer';
import { dateTimePeriodDeserializer } from '../DateTimePeriod/DateTimePeriodDeserializer';
import { lunchStatusDeserializer } from '../../Model/LunchStatus/LunchStatusDeserializer';

export class LunchDeserializer {
    public deserialize(serializedObject: DeliveryApiLunch): Lunch {
        return new Lunch(
            serializedObject.id,
            serializedObject.lunchStatus === undefined
                ? undefined
                : lunchStatusDeserializer.deserialize(serializedObject.lunchStatus),
            serializedObject.address,
            new Geocode({
                latitude: serializedObject.geocode.latitude!,
                longitude: serializedObject.geocode.longitude!,
            }),
            dateTimePeriodDeserializer.deserialize(serializedObject.dateTimePeriod),
            serializedObject.expectedArrivalDateTime === undefined
                ? undefined
                : dateTimeDeserializer.deserialize(serializedObject.expectedArrivalDateTime),
            serializedObject.completionDateTime === undefined
                ? undefined
                : dateTimeDeserializer.deserialize(serializedObject.completionDateTime),
            serializedObject.tripId,
        );
    }
}

export const lunchDeserializer = new LunchDeserializer();
