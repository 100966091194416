import React from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Pagination} from 'antd';
import {DeliveryApi} from '../../../../Backend/Api/Delivery/DeliveryApi';
import {TripPlanSummary} from '../../../../Model/TripPlan/TripPlanSummary';
import {
    formatDate,
    formatDateReverse,
    formatDateTime
} from '../../../../Std/formatDate';
import {formatTimePeriod} from '../../../../Std/FormatTimePeriod';
import {Page} from '../../../Page/Page';
import {ArrayUtils} from '../../../../Std/ArrayUtils';
import {
    ReactComponent as LinkIconComponent
} from '../../../../icons/external_link.svg';
import DriverPicker from '../../../DriverPicker/DriverPicker';
import {TripPlan} from '../../../../Model/TripPlan/TripPlan';
import {TripExecutor} from '../../../../Model/TripExecutor/TripExecutor';
import './PlanListRoute.css'

interface IPlanList {
    arrPlans: TripPlanSummary[][];
    total: number;
}

export const PlanListRoute = withRouter(() => {
    const history = useHistory();

    const numberOfDays = 7;
    const tableHeaderTitles = ['Дата рейса', 'Время', 'Количество машин', 'Время создания', ''];

    const [plans, setPlans] = React.useState<IPlanList | undefined>(undefined);
    const [selectedPlans, setSelectedPlans] = React.useState<number[]>([]);
    const [numPage, setNumPage] = React.useState(1);
    const [drivers, setDrivers] = React.useState<TripExecutor[]>([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    React.useEffect(() => {
        getPlans(numPage, numberOfDays);
    }, [numPage])

    const deletePlan = async (id: number) => {
        try {
            await DeliveryApi.deletePlan(id);
            await getPlans(numPage, numberOfDays);
        } catch (error) {
            alert(String(error));
        }
    }

    const confirmDeletePlan = (e: React.MouseEvent, id: number) => {
        e.stopPropagation();

        Modal.confirm({
            title: 'Удалить план',
            content: 'Все данные о маршрутах и доставке будут удалены.',
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk: async () => {
                await deletePlan(id);
            }
        })
    }

    const getPlans = async (page: number, size: number) => {
        let loadingPlans: TripPlanSummary[] = [];
        let pages = 0;

        await DeliveryApi.getPlans(size, (page - 1) * size).then((response) => {
            loadingPlans = response.plans.map(plan => plan);
            pages = response.total;
        }).catch(error => {
            alert(String(error));
        });

        const groupsPlans = groupingPlansByStartDate(loadingPlans);

        setPlans({
            arrPlans: groupsPlans,
            total: pages
        });
    }

    const groupingPlansByStartDate = (arrPlans: TripPlanSummary[]) => {
        const groups: TripPlanSummary[][] = [];

        for (const element of arrPlans) {
            let isGroupExist = false;

            for (const group of groups) {
                const existingGroups = group.filter(
                    (g: TripPlanSummary) => formatDateReverse(g.dateTimePeriod.start) === formatDateReverse(element.dateTimePeriod.start)
                )

                if (existingGroups.length > 0) {
                    isGroupExist = true;
                    group.push(element);

                    break;
                }
            }

            if (!isGroupExist) {
                const newGroup: TripPlanSummary[] = [element];
                groups.push(newGroup);
            }
        }

        return ArrayUtils.sort(groups, g => formatDateReverse(g[0].dateTimePeriod.start), false, false);
    }

    const selectionDrivers = async (arrPlans: TripPlanSummary[]) => {
        setIsModalVisible(true);
        const plansIds = arrPlans.map(plan => plan.id);
        const loadingDrivers = await loadDrivers(plansIds);
        setDrivers(loadingDrivers);
        setSelectedPlans(plansIds);
    }

    const loadDrivers = async (ids: number[]) => {
        const tripPlans: TripPlan[] = [];

        for (const id of ids) {
            await DeliveryApi.getPlan(id)
                .then(response => tripPlans.push(response.plan))
                .catch(error => alert(String(error)))
        }

        const allTripExecutors = tripPlans.reduce<TripExecutor[]>((acc, tripPlan) => {
            const tripExecutors = tripPlan.trips.map(trip => trip.executor);

            return [...acc, ...tripExecutors];
        }, [])

        const uniqueExecutors = allTripExecutors.reduce<TripExecutor[]>((acc, tripExecutor) => {
            const found = acc.findIndex(driver => driver.driver.id === tripExecutor.driver.id);

            return found < 0 ? [...acc, tripExecutor] : acc;
        }, [])

        return uniqueExecutors;
    }

    const getItineraries = async (driverIds: Set<number>) => {
        let foundPlan: TripPlanSummary | undefined;

        if (!plans) {
            return;
        }

        for (const planGroup of plans?.arrPlans) {
            foundPlan = planGroup.find(plan => plan.id === selectedPlans[0]);

            if (foundPlan) {
                break;
            }
        }

        if (!foundPlan) {
            return;
        }

        const date = formatDateReverse(foundPlan.dateTimePeriod.start);
        const driversList = [...driverIds].join(',');

        const url = '/plans/date/'.concat(date, '/drivers/', driversList);

        setIsModalVisible(false);

        history.push(url);
    }

    return (
        <Page header='Планы рейсов'>
            <div className='plan-list-route__buttons-group'>
                <Button
                        type='primary'
                        onClick={() => history.push(`/plans/new1s?returnUrl=${window.location.pathname}`)}
                >
                    <PlusOutlined/> Новый план из 1С
                </Button>
                <Button
                    type='ghost'
                    onClick={() => history.push(`/plans/wizardNew1s?returnUrl=${window.location.pathname}`)}
                >
                    <PlusOutlined/> Новый план из 1С NEW
                </Button>
            </div>

            <Pagination
                showSizeChanger={false}
                current={numPage}
                total={plans?.total}
                onChange={setNumPage}
            />

            <section className='plan-list-route__data'>
                <header className='plan-list-route__plans-group-header'>
                    {tableHeaderTitles.map(header => <div
                        key={header}>{header}</div>)}
                </header>
                {plans?.arrPlans.map(planGroups => (
                    <div
                        key={formatDate(planGroups[0].dateTimePeriod.start)}
                        className='plan-list-route__plans-group'
                    >
                        <div className='plan-list-route__plans-group-date'>
                            <div>
                                <span className='plan-list-route__text_margin'>
                                    {formatDate(planGroups[0].dateTimePeriod.start)}
                                </span>
                                <LinkIconComponent
                                    onClick={() => selectionDrivers(planGroups)}
                                    className='plan-list-route__plans-group-button'
                                />
                            </div>
                        </div>
                        <div className='plan-list-route__plans'>
                            {planGroups.map(plan => (
                                <div
                                    key={plan.id}
                                    className='plan-list-route__plan'
                                    onClick={() => history.push(`/plans/${plan.id}/view`)}
                                >
                                    <div>{formatTimePeriod(plan.dateTimePeriod.getTimePeriod())}</div>
                                    <div>{plan.orderCount}</div>
                                    <div>{plan.createdAt !== undefined ? formatDateTime(plan.createdAt) : undefined}</div>
                                    <div>
                                        <Button
                                            onClick={(e) => confirmDeletePlan(e, plan.id)}
                                        >
                                            <DeleteOutlined/>
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </section>
            <DriverPicker
                isModalVisible={isModalVisible}
                drivers={drivers}
                handleOk={getItineraries}
                handleCancel={() => setIsModalVisible(false)}
            />
        </Page>
    );
});
