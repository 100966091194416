import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { DeliveryApiCar } from '../../../../ApiClient/Yoso/models';
import { CompanyApi } from '../../../../Backend/Api/Company/CompanyApi';
import { EntityList } from '../../../EntityList/EntityList';
import { Page } from '../../../Page/Page';
import './CarListRoute.css';

export interface ICarListRouteProps extends RouteComponentProps {}

export const CarListRoute = withRouter(
    (props: ICarListRouteProps): JSX.Element => {
        const [loading, setLoading] = React.useState(true as boolean);
        const [cars, setCars] = React.useState([] as DeliveryApiCar[]);

        const deleteCar = React.useCallback(
            (id: number): (() => Promise<undefined>) => async (): Promise<undefined> => {
                setLoading(true);

                try {
                    await CompanyApi.deleteCar(id);
                    const response = await CompanyApi.getCarList();
                    setCars(response.cars.slice());
                } catch (error) {
                    alert(String(error));
                }

                setLoading(false);

                return;
            },
            [],
        );

        React.useEffect((): (() => void) => {
            let cancelled = false;

            const fetchData = async (params: any = {}): Promise<undefined> => {
                setLoading(true);

                try {
                    const response = await CompanyApi.getCarList();
                    if (cancelled) {
                        return;
                    }

                    setCars(response.cars);
                } catch (error) {
                    alert(String(error));
                } finally {
                    setLoading(false);
                }
            };

            fetchData().then(
                () => {
                    //
                },
                () => {
                    //
                },
            );

            return (): void => {
                cancelled = true;
            };
        }, []);

        const [carSearchString, setCarSearchString] = React.useState('' as string);

        const handleCarSearch = React.useCallback((value: string) => {
            setCarSearchString(value);
        }, []);

        const carSearchFilter = React.useCallback(
            (car: DeliveryApiCar, searchStringPart: string) =>
                car.number.toLowerCase().includes(searchStringPart.toLowerCase()),
            [],
        );

        return (
            <Page header="Автомобили">
                <Link to="/cars/new">
                    <Button type="primary" className="car-list-route__button" icon={<PlusOutlined/>}>
                        Добавить автомобиль
                    </Button>
                </Link>

                <EntityList<DeliveryApiCar>
                    data={cars}
                    loading={loading}
                    id={(car) => car.id!}
                    filter={{
                        search: {
                            filter: carSearchFilter,
                            onSearch: handleCarSearch,
                            searchString: carSearchString,
                        },
                    }}
                    actions={{
                        item: {
                            click: (id) => `/cars/${id}`,
                            delete: {
                                action: deleteCar,
                                title: 'Удалить автомобиль',
                            },
                        },
                    }}
                    columns={[
                        {
                            key: 'carNumber',
                            title: 'Номер',
                            render: (car) => car.number,
                        },
                    ]}
                />
            </Page>
        );
    },
);
