import React from 'react';
import { Employee } from '@/Model/Employee/Employee';
import { Modal, Select } from 'antd';
import { useAllDrivers } from '@/hooks/useAllDrivers';
import { Trip } from "@/Model/Trip/Trip";
import { TripExecutor } from '@/Model/TripExecutor/TripExecutor';
import { ArrayUtils } from "@/Std/ArrayUtils";

export interface DriverChangeConfirmProps {
    isModalVisible: boolean;
    selectedTrip: Trip;
    executors: ReadonlyArray<TripExecutor>;
    onCancel: () => void;
    onConfirm: (tripId: number, driverId: number) => void;
}

export const DriverReplacementConfirm = (props: DriverChangeConfirmProps) => {
    const [drivers, setDrivers] = React.useState<Employee[]>([]);
    const [selectedDriverId, setSelectedDriverId] = React.useState<number | undefined>(undefined);

    const {drivers: allDrivers} = useAllDrivers()

    React.useEffect(() => {
        const activeDrivers = allDrivers.filter(d =>
            d.driver.isActive
            && !props.executors.some(e => e.driver.id === d.driver.id && e.workingShift === props.selectedTrip.workingShift));
        const sortedActiveDrivers = ArrayUtils.sort(activeDrivers, d => d.driver.name);
        setSelectedDriverId(sortedActiveDrivers[0]?.driver.id);
        setDrivers(sortedActiveDrivers);
    }, [props.selectedTrip, allDrivers])

    const handleConfirm = () => {
        if (props.selectedTrip && selectedDriverId) {
            props.onConfirm(props.selectedTrip.id, selectedDriverId);
            props.onCancel()
        } else {
            props.onCancel();
        }
    }

    return (
        <Modal
            width={350}
            title="Установите водителя для маршрута:"
            visible={props.isModalVisible}
            onOk={handleConfirm}
            onCancel={props.onCancel}
        >
            <Select
                style={{ width: '100%' }}
                value={selectedDriverId}
                onChange={setSelectedDriverId}
            >
                {drivers.map(employee => (
                    <Select.Option key={employee.driver.id} value={employee.driver.id}>
                        {employee.driver.name}
                    </Select.Option>
                ))}
            </Select>
        </Modal>
    );
};
