import * as React from 'react';
import { District } from '@/Model/District/District';
import { TripWorkingShift } from '@/Model/Trip/TripWorkingShift';
import { DistrictGroupCode } from '@/Store/District/DistrictGroupCode';

export const useDistrictsBySelectedGroup = (
    districts: readonly District[],
    workingShift?: TripWorkingShift) => React.useMemo(() => {

    if (workingShift === undefined) {
        return undefined;
    }

    let selectedDistrictGroupCode: DistrictGroupCode | undefined;

    switch (workingShift) {
        case TripWorkingShift.MORNING:
            selectedDistrictGroupCode = DistrictGroupCode.Morning;
            break;
        case TripWorkingShift.EVENING:
            selectedDistrictGroupCode = DistrictGroupCode.Evening;
            break;
        case TripWorkingShift.DAYOFF:
            selectedDistrictGroupCode = DistrictGroupCode.DayOff;
            break;
        default:
            throw new Error('Trip has unknown workingShift ')
    }

    return districts.filter(d => d.group === selectedDistrictGroupCode)
}, [workingShift, districts])