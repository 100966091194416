import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IMapRendererMarkerTooltip} from '@/Map/Renderer/IMapRendererMarkerTooltip';
import { IGeocode } from '@/Model/Geocode/IGeocode';
import { ByIndexColorPicker } from '@/Std/ColorScheme/ByIndexColorPicker';
import { CarFilled } from '@ant-design/icons';
import { IMapMarker } from '../Map/IMapMarker';
import { MapColor } from '../Map/MapColor';
import { MapElementState } from '../Map/MapElementState';

const container = document.createElement('div');
ReactDOM.render(React.createElement(CarFilled), container);
const carIconElementHtml = container.innerHTML;

const tooltipClasses = {
    orange: 'plan-map__car-marker-tooltip_orange',
    red: 'plan-map__car-marker-tooltip_red'
}

export class PlanMapCarMarker implements IMapMarker {
    public get state(): MapElementState {
        return this._state;
    }

    public set state(value: MapElementState) {
        if (value !== this._state) {
            this._state = value;
            for (const stateChangeHandler of this._stateChangeHandlers) {
                stateChangeHandler(this._state);
            }
        }
    }

    public readonly className: string | undefined = 'plan-map__car-marker';
    public readonly clickable: boolean;
    public readonly color: MapColor | undefined;
    public readonly geocode: IGeocode;

    public readonly getStyle: (colorScheme: ByIndexColorPicker<MapColor>) => string;
    public readonly iconElementString: string = carIconElementHtml;
    public readonly itemIndex: number = -1;

    public readonly tooltip: IMapRendererMarkerTooltip;
    public readonly translucent: boolean;

    public readonly tripIndex: number;

    private readonly _clickHandlers: (() => void)[] = [];
    private _state: MapElementState;
    private readonly _stateChangeHandlers: ((state: MapElementState) => void)[] = [];

    public constructor(
        geocode: IGeocode,
        state: MapElementState,
        tripIndex: number,
        color: MapColor,
        onClick: () => void,
        title: string,
        translucent: boolean,
        clickable: boolean,
        directionAngle: number,
        tooltipColor?: 'orange' | 'red'
    ) {
        this.geocode = geocode;
        this._state = state;
        this.tripIndex = tripIndex;
        this.color = color;
        this.onClick(onClick);
        this.translucent = translucent;
        this.clickable = clickable;
        this.getStyle = (colorScheme) =>
            `svg {
                transform: rotate(${directionAngle}deg); color: ${colorScheme.renderColor(this.color).rgbaString}}`;

        this.tooltip = {
            className: `plan-map__car-marker-tooltip ${tooltipColor ? tooltipClasses[tooltipColor] : ''}`,
            isPermanent: true,
            isInteractive: true,
            text: title,
        };
    }

    public destroy(): void {
        this._clickHandlers.length = 0;
        this._stateChangeHandlers.length = 0;
    }

    public handleClick(): void {
        if (this.clickable) {
            for (const clickHandler of this._clickHandlers) {
                clickHandler();
            }
        }
    }

    public onChangeState(handler: (state: MapElementState) => void): void {
        this._stateChangeHandlers.push(handler);
    }

    private onClick(handler: () => void): void {
        this._clickHandlers.push(handler);
    }
}
