import React from 'react';
import { DeliveryApi } from '../Backend/Api/Delivery/DeliveryApi';
import { AreaChartCoords, ChartCoords } from '../Model/ChartCoords/ChartCoords';
import { PredictionChartData } from '../Model/PredictionChartData/PredictionChartData';
import { ArrayUtils } from '../Std/ArrayUtils';

export const usePredictionChart = () => {
    const millisecondsPerSecond = 1000;

    const [predictionChartData, setPredictionChartData] = React.useState<PredictionChartData | null>(null);
    const [loadingPredictionChartData, setLoadingPredictionChartData] = React.useState<boolean>(false);

    const fetchPrediction = (predictionId: number) => {
        setLoadingPredictionChartData(true);
        DeliveryApi.getPrediction(predictionId)
            .then((result) => {
                const createdAt = result.createdAt.toDate().getTime();
                const trainingPoints: ChartCoords[] = result.trainingOrders.map((order) => ({
                    x: order.expectedTime.toDate().getTime(),
                    y:
                        (order.actualTime.toDate().getTime() - order.expectedTime.toDate().getTime()) /
                        millisecondsPerSecond,
                    color: 'green',
                }));

                const predictedPoints = result.predictedOrders.map((order) => ({
                    x: order.expectedTime.toDate().getTime(),
                    y:
                        (order.predictedTime.toDate().getTime() - order.expectedTime.toDate().getTime()) /
                        millisecondsPerSecond,
                    color: 'red',
                }));

                const completedPredictedOrders = result.predictedOrders.filter((i) => i.actualArrivalTime);
                const completedPredictedPoints = completedPredictedOrders.map((order) => ({
                    x: order.expectedTime.toDate().getTime(),
                    y:
                        (order.actualArrivalTime!.toDate().getTime() - order.expectedTime.toDate().getTime()) /
                        millisecondsPerSecond,
                    color: 'blue',
                }));

                const area: AreaChartCoords[] = result.predictedOrders
                    .sort((a, b) => a.expectedTime.toDate().getTime() - b.expectedTime.toDate().getTime())
                    .map((order) => ({
                        x: order.expectedTime.toDate().getTime(),
                        y: [
                            (order.predictedTimeBegin.toDate().getTime() - order.expectedTime.toDate().getTime()) /
                                millisecondsPerSecond,
                            (order.predictedTimeEnd.toDate().getTime() - order.expectedTime.toDate().getTime()) /
                                millisecondsPerSecond,
                        ],
                        color: 'gray',
                    }));

                const allPoints = [...trainingPoints, ...completedPredictedPoints, ...predictedPoints];
                const allLinePoints = ArrayUtils.sort([...trainingPoints, ...predictedPoints], (by) => by.x);

                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                if (allLinePoints.length < 2) {
                    throw new Error('Not enough data for charts');
                }

                const corneredLinePoints = [allLinePoints[0], allLinePoints[allLinePoints.length - 1]];

                const linePoints = [];
                for (const point of corneredLinePoints) {
                    const x = point.x;
                    const y = result.coefficient * (x / millisecondsPerSecond - result.xOrigin) + result.bias;

                    linePoints.push({ x, y });
                }

                setPredictionChartData({
                    createdAt,
                    allPoints,
                    trainingPoints,
                    predictedPoints,
                    area,
                    completedPredictedPoints,
                    linePoints,
                });
            })
            .finally(() => setLoadingPredictionChartData(false));
    };

    return {
        predictionChartData,
        fetchPrediction,
        loadingPredictionChartData,
    };
};
