import { Product } from '../Model/Product/Product';

import { IFormatter } from './IFormatter';

export class ProductFormatter implements IFormatter<Product> {
    public default(obj: Product): string {
        return `${obj.code} ${obj.name}`;
    }

    public inOrderList(obj: Product): string {
        return obj.name;
    }
}

export const productFormatter = new ProductFormatter();
