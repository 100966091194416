import * as React from 'react';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { ReactComponent as MoonImageComponent } from '../../icons/moon.svg';
import { ReactComponent as SunImageComponent } from '../../icons/sun.svg';
import { TripWorkingShift } from '../../Model/Trip/TripWorkingShift';
import {
    TripAnalysisSummary,
} from '../../Model/TripAnalysisSummary/TripAnalysisSummary';
import { ArrayUtils } from '../../Std/ArrayUtils';
import {
    formatDistanceInKilometers,
} from '../../Std/FormatDistanceInKilometers';
import {
    formatDurationHoursMinutes,
} from '../../Std/formatDurationHoursMinutes';
import { EntityList } from '../EntityList/EntityList';
import { Map } from '../Map/Map';
import { AnalysisCellBars } from './AnalysisCellBars';
import { AnalysisOrdersDistribution } from './AnalysisOrdersDistribution';
import './TripPlanAnalysis.css';

export interface ITripPlanAnalysisProps {
    onTripClick?: (tripId: number) => void;
    planId: number;
}

export const TripPlanAnalysis = (props: ITripPlanAnalysisProps): JSX.Element => {
    const [tripAnalyses, setTripAnalyses] = React.useState([] as TripAnalysisSummary[]);
    const [loading, setLoading] = React.useState(tripAnalyses === undefined);
    const [maxOrdersCount, setMaxOrdersCount] = React.useState(0);
    const loadData = React.useCallback(async () => {
        setLoading(true);

        try {
            const ta = (await DeliveryApi.getPlanAnalysis(props.planId)).tripAnalyses;
            setTripAnalyses(ta);

            setMaxOrdersCount(Math.max(...ta.map((el): number => {
                const ordersCount = el.getArrivalOrdersCount();
                if (ordersCount === undefined) {
                    return 0;
                }

                return ordersCount;
            })));
        } catch (error) {
            alert(String(error));
        }

        setLoading(false);
    }, [props.planId]);

    React.useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        loadData();
    }, [loadData]);

    return (
        <div className="trip-plan-analysis">
            <EntityList<TripAnalysisSummary>
                data={ArrayUtils.sort(tripAnalyses, [by => by.driver.name, by => by.workingShift === TripWorkingShift.MORNING ? 0 : 1])}
                loading={loading}
                actions={{
                    item: {
                        click:
                            props.onTripClick === undefined
                                ? undefined
                                : (id: number) => (): undefined => {
                                      if (props.onTripClick !== undefined) {
                                          props.onTripClick(id);

                                          return;
                                      }
                                  },
                    },
                }}
                id={(tripAnalysis) => tripAnalysis.tripId}
                rowColorMarker={
                    Map.colorScheme === undefined
                        ? undefined
                        : (trip, index) =>
                              Map.colorScheme.renderColor(Map.colorScheme.get(index)).rgbaString
                }
                columns={[
                    {
                        key: 'car',
                        render: (tripAnalysis) => (
                            <div className='trip-plan-analysis__car-block'>
                                <div className='trip-plan-analysis__icon-block'>
                                    {tripAnalysis.workingShift === TripWorkingShift.MORNING &&
                                        <SunImageComponent className='trip-plan-analysis__icon trip-plan-analysis__icon_yellow' />}
                                    {tripAnalysis.workingShift === TripWorkingShift.EVENING &&
                                        <MoonImageComponent className='trip-plan-analysis__icon trip-plan-analysis__icon_blue' />}
                                </div>
                                <div>
                                    {tripAnalysis.car.carNumber && (
                                        <>
                                            {tripAnalysis.car.carNumber}
                                            <br />
                                        </>
                                    )}
                                    {tripAnalysis.driver.name}
                                </div>
                            </div>
                        ),
                        title: 'Маршрут',
                    },
                    {
                        key: 'doneOrdersCount',
                        render: (tripAnalysis) => tripAnalysis.completedOrdersCount,
                        title: 'Заказы выполненные',
                    },
                    {
                        key: 'notDoneOrdersCount',
                        render: (tripAnalysis) => tripAnalysis.failedOrdersCount + tripAnalysis.rescheduledOrdersCount,
                        title: 'Не выполненные/перенесенные',
                    },
                    {
                        key: 'arrivalDistribution',
                        render: (tripAnalysis) => (
                            <AnalysisOrdersDistribution
                                maxOrdersCount={maxOrdersCount}
                                tripAnalysis={tripAnalysis}
                            />
                        ),
                        title: 'Распределение по опозданиям',
                    },
                    {
                        key: 'distance',
                        render: (tripAnalysis) => (
                            <AnalysisCellBars
                                values={[tripAnalysis.estimatedDistance, tripAnalysis.distance, tripAnalysis.actualDistance]}
                                valueFormatter={formatDistanceInKilometers}
                                labels={['оригинал', 'водитель', 'факт']}
                            />
                        ),
                        title: 'Пробег',
                    },
                    {
                        key: 'duration',
                        render: (tripAnalysis) => (
                            <AnalysisCellBars
                                values={[tripAnalysis.estimatedDuration,
                                        tripAnalysis.duration,
                                        tripAnalysis.actualDuration]}
                                valueFormatter={formatDurationHoursMinutes}
                            />
                        ),
                        title: 'Время в пути',
                    },
                ]}
                pageSize={0}
            />
        </div>
    );
};
