import { DeliveryPlanStatsResponse } from '../../../../../ApiClient/Yoso/models';
import { TripStatsSummary } from '../../../../../Model/TripStatsSummary/TripStatsSummary';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import { tripStatsSummaryDeserializer } from '../../Model/TripStatsSummary/TripStatsSummaryDeserializer';

export interface IGetPlanStatisticsResponse {
    tripStats: TripStatsSummary[];
}

export class GetPlanStatisticsResponseDeserializer
    implements IResponseDeserializer<DeliveryPlanStatsResponse, IGetPlanStatisticsResponse> {
    public deserialize(response: DeliveryPlanStatsResponse): IGetPlanStatisticsResponse {
        return {
            tripStats: response.tripStats
                .map((tripStatsSummary) =>
                    tripStatsSummaryDeserializer.deserialize(tripStatsSummary, response.cars, response.drivers),
                )
                .sort((tripStatsSummary1, tripStatsSummary2) =>
                    tripStatsSummary1.driver.compareDriverNames(tripStatsSummary2.driver),
                ),
        };
    }
}

export const getPlanStatisticsResponseDeserializer = new GetPlanStatisticsResponseDeserializer();
