import { DeliveryApiDriverPlaces } from '../../../../../ApiClient/Yoso/models';
import { Address } from '../../../../../Model/Address/Address';
import { Geocode } from '../../../../../Model/Geocode/Geocode';
import { DriverPlace } from '../../../../../Model/Place/DriverPlace';

export class GetDriverPlacesDeserializer {
    public deserialize(response: DeliveryApiDriverPlaces): DriverPlace[] {
        const driverPlaces: DriverPlace[] = response.driverPlaces.map(p =>
            new DriverPlace(
                p.id,
                new Geocode({
                    latitude: p.latitude,
                    longitude: p.longitude,
                }),
            ).setIsSuspected(p.isSuspected).setComment(p.comment)
        )

        response.addresses.forEach(a => {
            const address = new Address(a.address, new Geocode({latitude: a.latitude, longitude: a.longitude}), a.driverPlaceId);
            const dp = driverPlaces.find(p => p.id === address.driverPlaceId);
            if (dp) {
                dp.addAddress(address);
            }
        })

        return driverPlaces;
    }
}

export const getDriverPlacesDeserializer = new GetDriverPlacesDeserializer();