import { Trip } from '@/Model';
import { ProblemRouteType } from '@/Model/Trip/ProblemRouteType';
import { DateTime } from '../../Std/DateTime';
import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { CarTrackedLocation } from '../CarTrackedLocation/CarTrackedLocation';
import { GeoVector } from '../GeoVector/GeoVector';

export class CarTrackedLocations {
    private static readonly milestoneTimeIntervalSeconds = DateTime.secondsInMinute;

    private static createMilestoneLocations(
        locations: ReadonlyArray<CarTrackedLocation>,
    ): ReadonlyArray<CarTrackedLocation> {
        const result: CarTrackedLocation[] = [];

        let previousLocation: CarTrackedLocation | undefined;
        locations.forEach((location, index) => {
            if (
                previousLocation === undefined ||
                index === locations.length - 1 ||
                new DateTimePeriod(previousLocation.dateTime, location.dateTime).totalSeconds >=
                    CarTrackedLocations.milestoneTimeIntervalSeconds
            ) {
                result.push(location);
                previousLocation = location;
            }
        });

        return result;
    }
    public readonly carNumber: string;
    public readonly driverName: string;
    public readonly problemRoute?: ProblemRouteType
    public readonly locations: ReadonlyArray<CarTrackedLocation>;
    public readonly milestoneLocations: ReadonlyArray<CarTrackedLocation>;
    public readonly tripId: number;

    public constructor(trip: Trip, carNumber: string, driverName: string, problemRoute?: ProblemRouteType) {
        this.tripId = trip.id;
        this.locations = trip.actualRoute;
        this.carNumber = carNumber;
        this.driverName = driverName;
        this.problemRoute = problemRoute;
        this.milestoneLocations = CarTrackedLocations.createMilestoneLocations(trip.actualRoute);
    }

    public getLastVector(): GeoVector | undefined {
        const lastButOneLocation: CarTrackedLocation | undefined = this.locations[this.locations.length - 1 - 1];
        const lastLocation: CarTrackedLocation | undefined = this.locations[this.locations.length - 1];

        return lastButOneLocation === undefined || lastLocation === undefined
            ? undefined
            : new GeoVector(lastButOneLocation.geocode, lastLocation.geocode);
    }
}
