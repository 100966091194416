import { DeliveryApiProduct } from '../../../../../ApiClient/Yoso/models';
import { Product } from '../../../../../Model/Product/Product';

export class ProductDeserializer {
    public deserialize(product: DeliveryApiProduct): Product {
        return new Product(
            product.id,
            product.code,
            product.name,
            product.volume,
            product.addable,
            product.isMainBottle,
            product.isDefaultBottle,
            product.isEmptyBottle,
            product.isActive
        );
    }
}

export const productDeserializer = new ProductDeserializer();
