import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { DateTime } from '../../Std/DateTime';

export class TripPlanSummary {
    public readonly dateTimePeriod: DateTimePeriod;
    public readonly id: number;
    public readonly orderCount: number;
    public readonly createdAt?: DateTime;

    public constructor(id: number, dateTimePeriod: DateTimePeriod, orderCount: number, createdAt?: DateTime) {
        this.id = id;
        this.dateTimePeriod = dateTimePeriod;
        this.orderCount = orderCount;
        this.createdAt = createdAt;
    }
}
