import React from 'react';
import { Modal, Select } from 'antd';
import { Trip } from '@/Model/Trip/Trip';
import { TripWorkingShift } from '@/Model/Trip/TripWorkingShift';
import './MassOrderTransferConfirm.css';

export interface IOrderTransferConfirmProps {
    isVisible: boolean;
    className?: string;
    fromTrip: Trip;
    onCancel: () => void;
    onConfirm: (toTripId: number) => void;
    selectedOrderCount: number;
    trips: Trip[];
}

export const MassOrderTransferConfirm = (props: IOrderTransferConfirmProps): JSX.Element => {
    const { trips, fromTrip, onCancel, onConfirm } = props;

    const [selectedTripId, setSelectedTripId] = React.useState<number | undefined>(undefined);

    const handleConfirm = () => {
        if (selectedTripId && selectedTripId !== fromTrip.id) {
            onConfirm(selectedTripId);
            onCancel();
        } else {
            onCancel();
        }
    }

    const tripListWithoutCurrent = React.useMemo(() => trips.filter(trip =>
        trip.id !== fromTrip.id &&
        (trip.executor.driver.id === fromTrip.executor.driver.id ||
            trip.workingShift === fromTrip.workingShift ||
            trip.workingShift === undefined)
    ), [trips, fromTrip])

    React.useEffect(() => {
        if (tripListWithoutCurrent.length > 0) {
            setSelectedTripId(tripListWithoutCurrent[0].id)
        }
    }, [])

    return (
        <Modal
            visible={props.isVisible}
            width={352}
            className={props.className}
            cancelText='Отмена'
            okText='Перенести заказы'
            onOk={handleConfirm}
            onCancel={props.onCancel}
        >
            <div className="trip-selector-transfer-orders__title">Перенос заказов</div>
            <div className="trip-selector-transfer-orders__info">Выбранных заказов: {props.selectedOrderCount}</div>
            <Select
                className="trip-selector-transfer-orders__control"
                value={selectedTripId}
                onChange={setSelectedTripId}
            >
                {tripListWithoutCurrent.map((trip) => (
                    <Select.Option
                        key={trip.id}
                        value={trip.id}
                        className={trip.workingShift === TripWorkingShift.EVENING
                            ? 'trip-selector-transfer-orders__control_dark-item' : ''}
                    >
                        {trip.executor.car.carNumber} {trip.executor.driver.name}
                    </Select.Option>
                ))}
            </Select>
        </Modal>

    );
};
