import * as React from 'react';

import './Sidebar.css';

export interface ISidebarProps {
    children: React.ReactNode;
    className?: string;
    header?: JSX.Element | undefined;
}

export const Sidebar = (props: ISidebarProps): JSX.Element => {
    const childrenArray: React.ReactNode[] = React.Children.toArray(props.children);

    return (
        <div className={`sidebar ${props.className}`}>
            {childrenArray.filter(Boolean).map((child: React.ReactNode) => (
                <div key={childrenArray.indexOf(child)} className="sidebar__item">
                    {child}
                </div>
            ))}
        </div>
    );
};
