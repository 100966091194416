import { IGeocode } from '../../Model/Geocode/IGeocode';
import { IMapPath } from '../Map/IMapPath';
import { MapColor } from '../Map/MapColor';
import { MapElementState } from '../Map/MapElementState';

export class PlanMapPath implements IMapPath {
    public get state(): MapElementState {
        return this._state;
    }

    public set state(value: MapElementState) {
        if (value !== this._state) {
            this._state = value;
            for (const stateChangeHandler of this._stateChangeHandlers) {
                stateChangeHandler(this._state);
            }
        }
    }
    public readonly clickable: boolean = true;
    public readonly color: MapColor | undefined;
    public readonly geocodes: ReadonlyArray<IGeocode>;
    public readonly itemIndex: number | undefined;
    public readonly translucent: boolean;
    public readonly tripIndex: number;
    private readonly _clickHandlers: (() => void)[] = [];
    private _state: MapElementState;

    private readonly _stateChangeHandlers: ((state: MapElementState) => void)[] = [];

    public constructor(
        geocodes: ReadonlyArray<IGeocode>,
        state: MapElementState,
        tripIndex: number,
        itemIndex: number | undefined,
        color: MapColor | undefined,
        onClick: () => void,
        translucent: boolean,
    ) {
        this.geocodes = geocodes;
        this._state = state;
        this.tripIndex = tripIndex;
        this.itemIndex = itemIndex;
        this.color = color;
        this.onClick(onClick);
        this.translucent = translucent;
    }

    public destroy(): void {
        this._clickHandlers.length = 0;
        this._stateChangeHandlers.length = 0;
    }

    public handleClick(): void {
        if (this.clickable) {
            for (const clickHandler of this._clickHandlers) {
                clickHandler();
            }
        }
    }

    public onChangeState(handler: (state: MapElementState) => void): void {
        this._stateChangeHandlers.push(handler);
    }

    private onClick(handler: () => void): void {
        this._clickHandlers.push(handler);
    }
}
