import {makeAutoObservable} from "mobx";
import {Geocode} from "../Geocode/Geocode";

export class Warehouse {
    public readonly id: number;
    public readonly coordinates: Geocode;
    public address: string;
    public isMain: boolean;
    public isDeleted: boolean;

    public constructor(
        id: number,
        coordinates: Geocode,
        address: string,
        isMain: boolean,
        isDeleted: boolean
    ) {
        this.id = id;
        this.coordinates = coordinates;
        this.address = address;
        this.isMain = isMain;
        this.isDeleted = isDeleted;
        makeAutoObservable(this);
    }

    public setIsMain(value: boolean) {
        this.isMain = value;
    }

    public setAddress(address: string) {
        this.address = address;
    }

    public setDeleted() {
        this.isDeleted = true;
    }
}