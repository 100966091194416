import { Time } from '../../../Std/Time';
import { IApiModelSerializer } from '../IApiModelSerializer';

export class TimeSerializer implements IApiModelSerializer<Time, string> {
    public serialize(obj: Time): string {
        return obj.toDate().toISOString();
    }
}

export const timeSerializer = new TimeSerializer();
