import {Button, Form, Input} from 'antd';
import * as React from 'react';

import {CompanyApiBotResponse} from '../../ApiClient/YosoCompany/models';
import {CompanyApi} from '../../Backend/Api/Company/CompanyApi';

import './BotEntityEdit.css';

export interface IExistingBot {
    id: number;
    bot: CompanyApiBotResponse;
}

export interface IBotEntityEditProps {
    bot: IExistingBot | undefined;
    onSave: () => void;
}

export const BotEntityEdit = (props: IBotEntityEditProps): JSX.Element => {
    const { onSave } = props;

    const destroyed = React.useRef(false);
    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const [name, setName] = React.useState(
        props.bot === undefined ? '' : props.bot.bot.employee!.name,
    );
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState(
        props.bot === undefined ? '' : props.bot.bot.employee!.credentials!.username,
    );

    const [activity, setActivity] = React.useState(
        !props.bot || !props.bot.bot.employee ? false : props.bot.bot.employee.isActive
    );

    const isDataValid = React.useCallback((): boolean => name !== undefined && name !== '', [name]);

    const handleUsernameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(e.currentTarget.value);
    }, []);

    const handlePasswordChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(e.currentTarget.value);
    }, []);

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setName(e.currentTarget.value);
    }, []);

    const [saving, setSaving] = React.useState(false as boolean);

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid()) {
            return;
        }

        setSaving(true);

        try {
                await CompanyApi.postBot({
                    employee: {
                        credentials: {
                            password: password,
                            username: username,
                        },
                        name: name,
                        isActive: activity
                    },
                });
            if (destroyed.current) {
                return;
            }
            onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [isDataValid, name, onSave, password, props.bot, username]);

    return (
        <div>
            <div className="bot-entity-edit__block">
                <div className="bot-entity-edit__form">
                    <Form.Item label="Логин">
                        <Input
                            defaultValue={username}
                            onChange={handleUsernameChange}
                            disabled={props.bot !== undefined}
                        />
                    </Form.Item>
                    <Form.Item label="Пароль">
                        <Input defaultValue={password} onChange={handlePasswordChange} />
                    </Form.Item>
                    <Form.Item label="Имя">
                        <Input defaultValue={name} onChange={handleNameChange} />
                    </Form.Item>
                </div>
            </div>
            <div className="bot-entity-edit__block">
                <Button type="primary" loading={saving} onClick={handleSaveButtonClick} disabled={!isDataValid()}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
