import { LoadingStore } from '../../../Model/Store/LoadingStore';
import { TripPointType } from '../../../Model/TripPointId/TripPointType';
import {DateTime} from "../../../Std/DateTime";
import { RoutePoint } from './RoutePoint';

export class LoadingStoreRoutePoint extends RoutePoint {
    public readonly expectedDistanceTo: number | undefined;
    public readonly store: LoadingStore;

    public constructor(id: string, store: LoadingStore, expectedDistanceTo: number | undefined, expectedDateTimeFrom: DateTime | undefined) {
        super(
            store.id,
            id,
            TripPointType.LoadingStore,
            store.coordinates,
            undefined,
            store.address,
            store.expectedArrivalDateTime,
            expectedDateTimeFrom,
            undefined,
            undefined,
            false,
        );

        this.store = store;
        this.expectedDistanceTo = expectedDistanceTo;
    }
}
