import {Button, Form, Input, Typography} from 'antd';
import * as React from 'react';

import {CompanyApiCompanyResponse} from '../../ApiClient/YosoCompany/models';
import {CompanyApi} from '../../Backend/Api/Company/CompanyApi';
import {CompanyAdminList} from '../CompanyAdminList/CompanyAdminList';

import './CompanyEntityEdit.css';

export interface IExistingCompany {
    company: CompanyApiCompanyResponse;
    id: number;
}

export interface ICompanyEntityEditProps {
    company: IExistingCompany | undefined;
    companyIdTemp: number;
    onSave: () => void;
}

export const CompanyEntityEdit = (props: ICompanyEntityEditProps): JSX.Element => {
    const {onSave} = props;

    const destroyed = React.useRef(false);
    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const [name, setName] = React.useState(props.company === undefined ? '' : props.company.company.company.name);
    const [cityName, setCityName] = React.useState(props.company === undefined ? '' : props.company.company.company.cityName);
    const [proficiencyCoefficient, setProficiencyCoefficient] = React.useState(props.company === undefined ? 0 : props.company.company.company.proficiencyCoefficient);

    const isDataValid = React.useCallback((): boolean => Boolean(name) || Boolean(cityName), [name, cityName]);

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setName(e.currentTarget.value);
    }, []);

    const handleCityNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setCityName(e.currentTarget.value);
    }, []);

    const handleProficiencyCoefficientChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setProficiencyCoefficient(Number(e.currentTarget.value));
    }, []);

    const [saving, setSaving] = React.useState(false as boolean);

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid()) {
            return;
        }

        setSaving(true);

        try {
            if (props.company === undefined) {
                await CompanyApi.postCompany({
                    company: {
                        name: name,
                        cityName: cityName,
                        proficiencyCoefficient: proficiencyCoefficient
                    },
                });
            } else {
                await CompanyApi.putCompany(props.company.id, {
                    id: props.company.id,
                    name: name,
                });
            }
            if (destroyed.current) {
                return;
            }
            onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [isDataValid, props.company, onSave, name, cityName]);

    return (
        <div>
            {props.companyIdTemp === undefined ? (
                <>
                    <div className="company-entity-edit__block">
                        <div className="company-entity-edit__form">
                            <Form.Item label="Название">
                                <Input
                                    defaultValue={name}
                                    onChange={handleNameChange}
                                />
                            </Form.Item>
                            <Form.Item label="Город">
                                <Input
                                    defaultValue={cityName}
                                    onChange={handleCityNameChange}
                                />
                            </Form.Item>
                            <Form.Item label="Коэффициент">
                                <Input
                                    type='number'
                                    max={2}
                                    defaultValue={proficiencyCoefficient}
                                    onChange={handleProficiencyCoefficientChange}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="company-entity-edit__block">
                        <Button type="primary" loading={saving}
                                onClick={handleSaveButtonClick}
                                disabled={!isDataValid()}>
                            Сохранить
                        </Button>
                    </div>
                </>
            ) : (
                <div className="company-entity-edit__block">
                    {/* tslint:disable-next-line: no-magic-numbers */}
                    <Typography.Title level={4}>Администраторы
                        компании</Typography.Title>
                    <CompanyAdminList companyId={props.companyIdTemp}/>
                </div>
            )}
        </div>
    );
};
