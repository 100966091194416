import { Time } from './Time';

const defaultYear = 2000;

export class DateTime {
    public static readonly default = new DateTime(defaultYear, 0, 1);
    public static readonly millisecondsInSecond = 1000;
    public static readonly secondsInMinute = 60;

    public static setCompanyAndLocalDiff(seconds: number) {
        DateTime.companyAndLocalDiffInSeconds = seconds;
    }

    public static getCompanyAndLocalDiff(): number {
        return DateTime.companyAndLocalDiffInSeconds;
    }

    public static fromDate(date: Date): DateTime {
        return new DateTime(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds(),
        );
    }

    public static now(): DateTime {
        return DateTime.fromDate(new Date());
    }

    private static companyAndLocalDiffInSeconds = 0;

    public readonly date: number;
    public readonly hours: number;
    public milliseconds: number;
    public readonly minutes: number;
    public readonly month: number;
    public readonly seconds: number;
    public readonly year: number;

    public constructor(
        year: number,
        month: number,
        date: number,
        hours: number = 0,
        minutes: number = 0,
        seconds: number = 0,
        milliseconds: number = 0,
    ) {
        this.year = year;
        this.month = month;
        this.date = date;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
        this.milliseconds = milliseconds;
    }

    public compareTo(dateTime: DateTime): number {
        const value = this.toDate().valueOf();
        const anotherValue = dateTime.toDate().valueOf();

        return value === anotherValue ? 0 : value > anotherValue ? 1 : -1;
    }

    public isMore(dateTime: DateTime): boolean {
        return this.toDate().valueOf() > dateTime.toDate().valueOf();
    }

    public isLess(dateTime: DateTime): boolean {
        return this.toDate().valueOf() < dateTime.toDate().valueOf();
    }

    public equals(dateTime: DateTime): boolean {
        return this.toDate().valueOf() === dateTime.toDate().valueOf();
    }

    public getTime(): Time {
        return new Time(this.hours, this.minutes, this.seconds, this.milliseconds);
    }

    public setMidnight(): DateTime {
        return this.cloneWithTime(new Time(0, 0));
    }

    public cloneWithTime(time: Time): DateTime {
        return new DateTime(
            this.year,
            this.month,
            this.date,
            time.hours,
            time.minutes,
            time.seconds,
            time.milliseconds,
        );
    }

    public toDate(): Date {
        return new Date(this.year, this.month, this.date, this.hours, this.minutes, this.seconds, this.milliseconds);
    }

    public getMonthTitle(): string {
        const monthTitles: { [key: number]: string } = {
            0: 'Январь',
            1: 'Февраль',
            2: 'Март',
            3: 'Апрель',
            4: 'Май',
            5: 'Июнь',
            6: 'Июль',
            7: 'Август',
            8: 'Сентябрь',
            9: 'Октябрь',
            10: 'Ноябрь',
            11: 'Декабрь',
        };

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.month >= 0 && this.month <= 11) {
            return monthTitles[this.month];
        } else {
            throw new Error('Недопустимый месяц. Значение должно быть от 0 до 11.');
        }
    }
}
