import React, { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { Modal, TimePicker } from 'antd';
import { ExclamationCircleOutlined, MinusOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './DeliveryTimeIntervalAdjustment.module.css';

interface DeliveryTimeIntervalAdjustmentProps<TEntity> {
    intervals: TEntity[];
    getBegin: (entity: TEntity) => Moment | Date | string;
    getEnd: (entity: TEntity) => Moment | Date | string;
    onRemove: (interval: TEntity) => void;
    canRemove: (interval: TEntity) => boolean;
    switchAvailability: (interval: TEntity) => void;
    isAvailable: (interval: TEntity) => boolean;
    canSwitchAvailability: (interval: TEntity) => boolean;
    onBeginChange: (interval: TEntity, value: Moment) => void;
    onEndChange: (interval: TEntity, value: Moment) => void;
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const beginDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 22, 23];
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const endDisabledHours = [0, 1, 2, 3, 4, 5, 6, 7];

const DeliveryTimeIntervalAdjustment = <TEntity extends unknown>(
    props: DeliveryTimeIntervalAdjustmentProps<TEntity>,
) => {
    const {
        intervals,
        getBegin,
        getEnd,
        onRemove,
        canRemove,
        switchAvailability,
        isAvailable,
        canSwitchAvailability,
        onBeginChange,
        onEndChange,
    } = props;

    const onBeginChangeWrap = useCallback((interval: TEntity, time: Moment | null) => {
        if (!time) {
            return;
        }
        onBeginChange(interval, time);
    }, []);

    const onEndChangeWrap = useCallback((interval: TEntity, time: Moment | null) => {
        if (!time) {
            return;
        }
        onEndChange(interval, time);
    }, []);

    return (
        <div className={styles.container}>
            {intervals.map((x, i) => (
                <div className={styles.item} key={i}>
                    {canRemove(x) ? (
                        <>
                            <MinusOutlined className={styles.removeInterval} onClick={() => onRemove(x)} />
                            <TimePicker
                                format="HH:mm"
                                value={moment(getBegin(x))}
                                onChange={(time) => onBeginChangeWrap(x, time)}
                                allowClear={false}
                                minuteStep={30}
                                showNow={false}
                                disabledHours={() => beginDisabledHours}
                            />
                            &nbsp;&ndash;&nbsp;
                            <TimePicker
                                format="HH:mm"
                                value={moment(getEnd(x))}
                                onChange={(time) => onEndChangeWrap(x, time)}
                                allowClear={false}
                                minuteStep={30}
                                showNow={false}
                                disabledHours={() => endDisabledHours}
                            />
                        </>
                    ) : (
                        <div className={styles.notEditableInterval}>
                            {canSwitchAvailability(x) && (
                                <>
                                    {isAvailable(x) ? (
                                        <PauseCircleOutlined
                                            className={styles.disableInterval}
                                            title="Отключить"
                                            onClick={() => switchAvailability(x)}
                                        />
                                    ) : (
                                        <PlayCircleOutlined
                                            className={styles.enableInterval}
                                            title="Включить"
                                            onClick={() => switchAvailability(x)}
                                        />
                                    )}
                                </>
                            )}
                            <div
                                className={classNames({
                                    [styles.notEditableTimeContainer]: true,
                                    [styles.notAvailable]: !isAvailable(x),
                                })}
                            >
                                <div className={styles.notEditableTime}>{moment(getBegin(x)).format('HH:mm')}</div>
                                &nbsp;&ndash;&nbsp;
                                <div className={styles.notEditableTime}>{moment(getEnd(x)).format('HH:mm')}</div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default DeliveryTimeIntervalAdjustment;
