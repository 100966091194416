import { Product } from '../Product/Product';

import { ProductOperationDealType } from './ProductOperationDealType';
import { ProductOperationType } from './ProductOperationType';

export class ProductOperation {
    public readonly actualCount: number | undefined;
    public readonly adjustedPrice: number | undefined;
    public readonly estimatedCount: number;
    public readonly price: number;
    public readonly product: Product;
    public readonly type: ProductOperationType;
    public readonly dealType: ProductOperationDealType;
    public departPeriod: string | undefined;

    public constructor(
        type: ProductOperationType,
        product: Product,
        estimatedCount: number,
        actualCount: number | undefined,
        price: number,
        adjustedPrice: number | undefined,
        dealType: ProductOperationDealType,
    ) {
        this.type = type;
        this.product = product;
        this.actualCount = actualCount;
        this.estimatedCount = estimatedCount;
        this.price = price;
        this.adjustedPrice = adjustedPrice;
        this.dealType = dealType;
    }

    public get isCountAdjusted(): boolean {
        return this.actualCount !== undefined;
    }

    public get isPriceAdjusted(): boolean {
        return this.adjustedPrice !== undefined;
    }

    public get totalPrice(): number {
        return this.estimatedCount * this.price;
    }

    public get totalActualPrice(): number | undefined {
        return this.actualCount === undefined ? undefined : this.actualCount * this.actualPrice;
    }

    private get actualPrice(): number {
        return this.adjustedPrice ?? this.price;
    }
}
