import { Button, Form, Input, InputNumber } from 'antd';
import * as React from 'react';

import { DeliveryApiCar } from '../../ApiClient/Yoso/models';
import { CompanyApi } from '../../Backend/Api/Company/CompanyApi';

import './CarEntityEdit.css';

export interface ICarEntityEditProps {
    car: DeliveryApiCar | undefined;
    onSave: () => void;
}

export const CarEntityEdit = (props: ICarEntityEditProps): JSX.Element => {
    const { onSave } = props;

    const destroyed = React.useRef(false);
    React.useEffect(
        () => (): void => {
            destroyed.current = true;
        },
        [],
    );

    const [carNumber, setCarNumber] = React.useState(props.car === undefined ? '' : props.car.number);
    const [carCapacity, setCarCapacity] = React.useState(props.car === undefined ? undefined : props.car.capacity);

    const isDataValid = React.useCallback((): boolean => Boolean(carNumber), [carNumber]);

    const handleNumberChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setCarNumber(e.currentTarget.value);
    }, []);

    // eslint-disable-next-line @typescript-eslint/tslint/config
    const handleCapacityChange = React.useCallback((newCapacity: string | number | undefined | null): void => {
        if (typeof newCapacity === 'string') {
            setCarCapacity(undefined);
        } else {
            setCarCapacity(newCapacity ?? undefined);
        }
    }, []);

    const [saving, setSaving] = React.useState(false as boolean);

    const handleSaveButtonClick = React.useCallback(async (): Promise<undefined> => {
        if (!isDataValid()) {
            return;
        }

        setSaving(true);

        try {
            if (carNumber === undefined || carCapacity === undefined) {
                throw new Error('Number or Capacity not set');
            }
            if (props.car === undefined) {
                await CompanyApi.createCar({
                    // eslint-disable-next-line id-blacklist
                    number: carNumber,
                    capacity: carCapacity,
                });
            } else {
                await CompanyApi.updateCar(props.car.id!, {
                    // eslint-disable-next-line id-blacklist
                    number: carNumber,
                    capacity: carCapacity,
                });
            }
            if (destroyed.current) {
                return;
            }
            onSave();
        } catch (error) {
            if (destroyed.current) {
                return;
            }
            alert(String(error));
        }
    }, [carCapacity, carNumber, isDataValid, onSave, props.car]);

    return (
        <div>
            <div className="car-entity-edit__block">
                <div className="car-entity-edit__form">
                    <Form.Item label="Номер">
                        <Input defaultValue={carNumber} onChange={handleNumberChange} />
                    </Form.Item>
                    <Form.Item label="Вместимость">
                        <InputNumber defaultValue={carCapacity} onChange={handleCapacityChange} precision={0} />
                    </Form.Item>
                </div>
            </div>
            <div className="car-entity-edit__block">
                <Button type="primary" loading={saving} onClick={handleSaveButtonClick} disabled={!isDataValid()}>
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
