import React, { ReactNode } from "react";
import L from 'leaflet';
import { Polygon as LeafletPolygon} from "react-leaflet"
import { IGeocode } from "../../../Model/Geocode/IGeocode";

interface PolygonProps {
    children?: ReactNode;
    coords: IGeocode[];
    onClick?: (e: any) => void;
    onEdit?: (coords: IGeocode[]) => void;
    color?: string;
    isSelected?: boolean;
    editModeByDoubleClick?: boolean;
}

const Polygon = (props: PolygonProps): JSX.Element => {
    const defaultFillOpacity = 0.4;
    const defaultBorder = 2;
    const boldBorder = 5;

    const polygonRef = React.useRef<any>();
    const [newCoords, setNewCoords] = React.useState<IGeocode[] | undefined>(undefined)

    React.useEffect(() => {
        polygonRef.current.on('pm:edit', (e: any) => {
            const coords: L.LatLngExpression[] = e.layer.getLatLngs()[0];
            setNewCoords(coords.map((item: any) => ({ latitude: item.lat, longitude: item.lng })));
        });
    }, []);

    React.useEffect(() => {
        if (!props.onEdit || !newCoords) {
            return undefined;
        }
        props.onEdit(newCoords);
    }, [newCoords])

    React.useEffect(() => {
        polygonRef.current.setStyle({weight: props.isSelected ? boldBorder : defaultBorder})
    }, [props.isSelected])

    React.useEffect(() => {
        polygonRef.current.setStyle({color: props.color || "#3388ff"})
    }, [props.color])

    const mapCoords = React.useCallback((coords: IGeocode[]): L.LatLngExpression[] =>
        coords.map(x => ({lat: x.latitude, lng: x.longitude})), []);

    const edit = () => {
        if (props.editModeByDoubleClick) {
            polygonRef.current.pm.toggleEdit()
        }
    }

    return (
        <LeafletPolygon
            ref={polygonRef}
            positions={mapCoords(props.coords)}
            fillOpacity={defaultFillOpacity}
            weight={defaultBorder}
            eventHandlers={{
                click: props.onClick,
                dblclick: edit
            }}>
            {props.children}
        </LeafletPolygon>
    )
}

export default Polygon