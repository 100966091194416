import { ArrayUtils } from '../../Std/ArrayUtils';
import { DraftOrder } from '../DraftOrder/DraftOrder';
import {
    DraftOrderDepartPeriodCode
} from '../DraftOrder/DraftOrderDepartPeriodCode';
import { Order } from '../Order/Order';
import { ProductOperationType } from '../ProductOperation/ProductOperationType';

import { TripExecutor } from './TripExecutor';

interface DriverLoadingVolume {
    loading : number;
    bottlesCount: number;
    morningBottlesCount: number;
    eveningBottlesCount: number;
}

export class TripExecutorsLoadingInfo {
    public static getLoading(orders: (DraftOrder | Order)[]): DriverLoadingVolume {
        const productOperations = ArrayUtils.flatten(
            orders.map((order) => {
                if (order instanceof DraftOrder) {
                    order.operations.productOperations.forEach(i => i.departPeriod = order.departPeriod);
                }

                return order.operations.productOperations;
            }),
        );
        let totalLoadingVolume = 0;
        let totalBottlesCount = 0;
        let morningBottlesCount = 0;
        let eveningBottlesCount = 0;

        productOperations.forEach((productOperation) => {
            if (productOperation.type === ProductOperationType.Shipment) {
                const productVolume = productOperation.product.volume;
                if (productVolume > 0 && productOperation.estimatedCount > 0) {
                    totalLoadingVolume += productOperation.estimatedCount / productVolume;
                }
                
                if (productOperation.product.isMainBottle) {
                    totalBottlesCount += productOperation.estimatedCount;

                    if (productOperation.departPeriod === DraftOrderDepartPeriodCode.morning || productOperation.departPeriod === DraftOrderDepartPeriodCode.afternoon) {
                        morningBottlesCount += productOperation.estimatedCount;
                    }

                    if (productOperation.departPeriod === DraftOrderDepartPeriodCode.evening) {
                        eveningBottlesCount += productOperation.estimatedCount;
                    }
                }
            }
        });

        return {
            loading: Math.ceil(totalLoadingVolume),
            bottlesCount: Math.ceil(totalBottlesCount),
            morningBottlesCount: Math.ceil(morningBottlesCount),
            eveningBottlesCount: Math.ceil(eveningBottlesCount)
        }
    }
    
    private readonly data: WeakMap<TripExecutor, number>;
    private readonly bottlesData: WeakMap<TripExecutor, number>;

    public constructor(tripExecutors: ReadonlyArray<TripExecutor>, orders: ReadonlyArray<DraftOrder | Order>) {
        const result = new WeakMap<TripExecutor, number>();
        const bottlesResult = new WeakMap<TripExecutor, number>();

        tripExecutors.forEach((tripExecutor) => {
            const executorOrders = orders.filter(
                (order) =>
                    order.executor!.getId() === tripExecutor.getId(),
            );
            
            const {loading, bottlesCount} = TripExecutorsLoadingInfo.getLoading(executorOrders)

            result.set(tripExecutor, loading);
            bottlesResult.set(tripExecutor, bottlesCount);
        });

        this.data = result;
        this.bottlesData = bottlesResult;
    }

    public get(executor: TripExecutor): number | undefined {
        return this.data.get(executor);
    }

    public getBottles(executor: TripExecutor): number | undefined {
        return this.bottlesData.get(executor);
    }
}
