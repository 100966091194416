import React from 'react'
import {Button, Checkbox, Modal, Spin} from "antd";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {TripExecutor} from "../../Model/TripExecutor/TripExecutor";
import {ArrayUtils} from "../../Std/ArrayUtils";
import './DriverPicker.css'

interface DriverPickerProps {
    isModalVisible: boolean;
    drivers: TripExecutor[];
    handleOk: (driverIds: Set<number>) => void;
    handleCancel: () => void;
}

const DriverPicker = (props: DriverPickerProps) => {
    const [selectedExecutors, setSelectedExecutors] = React.useState<Set<number>>(new Set());

    React.useEffect(() => {
        setSelectedExecutors(new Set());
    }, [props.drivers])

    const selectDriver = React.useCallback((e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            selectedExecutors.add(e.target.value);
        } else {
            selectedExecutors.delete(e.target.value);
        }
        setSelectedExecutors(new Set(selectedExecutors));
    }, [selectedExecutors]);

    const handleOk = () => {
        props.handleOk(selectedExecutors);
    }

    const selectAll = () => {
        setSelectedExecutors(new Set(props.drivers.map((driver: TripExecutor) => driver.driver.id)));
    }

    const unselectAll = () => {
        setSelectedExecutors(new Set());
    }

    return (
        <Modal
            title="Выберите водителей"
            visible={props.isModalVisible}
            onOk={handleOk}
            onCancel={props.handleCancel}
            okButtonProps={{disabled: selectedExecutors.size === 0}}
        >
            <Spin spinning={props.drivers.length === 0}>
                <div className='driver-picker__button-group'>
                    <Button type="link" size="small" onClick={selectAll}>Выбрать всех</Button>
                    <Button type="link" size="small" onClick={unselectAll}>Сбросить</Button>
                </div>

                <div>
                    {ArrayUtils.sort(props.drivers, d => d.driver.name, false).map(executor => (
                        <div key={executor.driver.id}>
                            <Checkbox
                                checked={selectedExecutors.has(executor.driver.id)}
                                value={executor.driver.id}
                                onChange={selectDriver}
                            >
                                {executor.driver.name}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </Spin>
        </Modal>
    )
}

export default DriverPicker