import React from 'react';
import { ReactComponent as SmallOkIconComponent } from '../../../icons/small-ok.svg';
import { StartRoutePoint } from '../../../Map/Route/RoutePoint/StartRoutePoint';
import { FormattedTime } from '../RouteInfoFormattedValues/FormattedTime';
import '../RouteInfo2.css';

interface RouteInfoStartPointProps {
    point: StartRoutePoint;
}

export const RouteInfoStartPoint = (props: RouteInfoStartPointProps) => {
    const { point } = props;

    return (
        <>
            <div className="route-info-block__marker_start">
                <div className="route-info-block__wrapper-circle">
                    <div className="route-info-block__inner-circle route-info-block__inner-circle_done">
                        <SmallOkIconComponent className="route-info-block__inner-icon" />
                    </div>
                </div>
            </div>
            <div className="route-info-block__drag" />
            <div className="route-info-block__content">
                <div>
                    <div>{point.address}</div>
                    <div>
                        <FormattedTime date={point.expectedArrivalDateTime} />
                    </div>
                </div>
            </div>
        </>
    )
}