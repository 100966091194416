import { ArrayUtils } from '../../Std/ArrayUtils';
import { Geocode } from '../Geocode/Geocode';

import { GeocodeQuality } from './GeocodeQuality';
import { GeocodeSource } from './GeocodeSource';

export class Place {
    public static getGeocodes(places: ReadonlyArray<Place>): Geocode[] {
        return ArrayUtils.defined(places.map((place) => place.geocode));
    }

    public static multipleGeocodes(places: ReadonlyArray<Place>): boolean {
        return Place.getGeocodes(places).length > 1;
    }

    public readonly address: string;
    public readonly prettyAddress: string | undefined;
    public readonly addressId: string | undefined;
    public readonly fullAddress: string | undefined;
    public readonly geocode: Geocode | undefined;
    public readonly geocodeQuality: GeocodeQuality;
    public readonly geocodeSource: GeocodeSource;
    public readonly latitude: number | undefined;
    public readonly longitude: number | undefined;
    public readonly title: string | undefined;
    private readonly suspect: boolean;

    public constructor(
        address: string,
        fullAddress: string | undefined,
        latitude: number | undefined,
        longitude: number | undefined,
        geocodeQuality: GeocodeQuality,
        geocodeSource: GeocodeSource,
        addressId: string | undefined,
        suspect: boolean = false,
        title: string | undefined = undefined,
        prettyAddress?: string,
    ) {
        this.address = address;
        this.fullAddress = fullAddress;
        this.latitude = latitude;
        this.longitude = longitude;
        this.suspect = suspect;
        this.geocode =
            latitude === undefined || longitude === undefined
                ? undefined
                : new Geocode({
                      latitude: latitude,
                      longitude: longitude,
                  });
        this.geocodeQuality = geocodeQuality;
        this.geocodeSource = geocodeSource;
        this.addressId = addressId;
        this.title = title;
        this.prettyAddress = prettyAddress;
    }

    public isError(): boolean {
        if (this.suspect) {
            return true;
        }
        let error = true;
        if (this.geocode === undefined) {
            error = true;
        } else if (this.geocodeQuality === GeocodeQuality.Validated) {
            error = false;
        } else if (this.geocodeQuality === GeocodeQuality.JustValidated) {
            error = false;
        } else {
            error = true;
        }

        return error;
    }
}
