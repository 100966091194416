import classNames from 'classnames';
import { css } from '@emotion/css';
import * as React from 'react';

import { IMapRenderer } from '../../Map/Renderer/IMapRenderer';
import { IMapRendererMarker } from '../../Map/Renderer/IMapRendererMarker';
import { IMapRendererPath } from '../../Map/Renderer/IMapRendererPath';
import { IMapRendererPolygon } from '../../Map/Renderer/IMapRendererPolygon';
import { IGeocode } from '../../Model/Geocode/IGeocode';
import { ArrayUtils } from '../../Std/ArrayUtils';
import { ByIndexColorPicker } from '../../Std/ColorScheme/ByIndexColorPicker';
import { Constructor0 } from '../../Std/Constructor0';
import { MapBounds } from '../../Std/MapBounds/MapBounds';

import { defaultColorPalette as colorScheme } from '../../Shared/ColorPalette'
import { IMapMarker } from './IMapMarker';
import { IMapPath } from './IMapPath';
import { IMapPolygon } from './IMapPolygon';
import './Map.css';
import { MapColor } from './MapColor';
import { MapElementState } from './MapElementState';

export interface IMapProps {
    className?: string;
    clickable?: boolean;
    isConfirmationMarker: boolean;
    markers: ReadonlyArray<IMapMarker>;
    paths: ReadonlyArray<IMapPath>;
    polygons: ReadonlyArray<IMapPolygon>;
    renderer: Constructor0<IMapRenderer>;
    startBounds?: MapBounds;
    onSetMarker?(geocode: IGeocode): void;
}

export class Map extends React.Component<IMapProps> {
    public static readonly colorScheme: ByIndexColorPicker<MapColor> = colorScheme;

    public static defaultProps: Partial<IMapProps> = {
        markers: [],
        paths: [],
        polygons: [],
    };
    private static readonly latitudePaddingRatio: number = 0.1;

    // To preserve space for expanding sidebar
    private static readonly longitudePaddingRatio: number = 0.4;
    private static readonly markerClassName: string = 'map__marker';
    private static readonly markerClassNameSelected: string = 'map__marker_selected';
    private static readonly markerHeight: number = 18;
    private static readonly markerOpacity: number = 1;
    private static readonly markerPinX: number = 5;
    private static readonly markerPinY: number = 5;

    private static readonly markerWidth: number = 18;

    private static readonly pathWidth: number = 3;
    private static readonly polygonWidth: number = 2;
    private static readonly secondaryMarkerHeight: number = 8;

    private static readonly secondaryMarkerOpacity: number = 0.7;
    private static readonly secondaryMarkerWidth: number = 8;

    private static readonly selectedMarkerHeight: number = 18;
    private static readonly selectedMarkerPinX: number = 5;
    private static readonly selectedMarkerPinY: number = 5;

    private static readonly selectedMarkerWidth: number = 18;

    private static readonly selectedPathWidth = 8;
    private static readonly thinPathWidth = 1.5;

    private static setMarkerSecondary(marker: IMapRendererMarker, secondary: boolean): void {
        marker.setOpacity(secondary ? Map.secondaryMarkerOpacity : Map.markerOpacity);
    }

    private static setPathSecondary(path: IMapRendererPath, secondary: boolean): void {
        path.setVisibility(!secondary);
    }

    public readonly colorScheme: ByIndexColorPicker<MapColor> = colorScheme;

    private readonly markerClassNameSelected: string;
    private container: HTMLElement | null = null;
    private markers: IMapRendererMarker[] = [];
    private paths: IMapRendererPath[] = [];
    private polygons: IMapRendererPolygon[] = [];
    private renderer: IMapRenderer;

    public constructor(props: IMapProps) {
        super(props);

        this.renderer = new props.renderer();
        this.markerClassNameSelected = props.isConfirmationMarker ? 'map__marker_confirmed' : 'map__marker_selected';
    }

    public async componentDidMount(): Promise<undefined> {
        await this.init();

        this.fitBounds();

        return;
    }

    public async componentDidUpdate(prevProps: Readonly<IMapProps>): Promise<undefined> {
        if (this.props.renderer !== prevProps.renderer) {
            this.renderer.destroy();
            if (this.container !== null) {
                this.renderer = new this.props.renderer();
                await this.renderer.init(this.container);
                this.renderer.onClick((geocode: IGeocode): void => {
                    if (this.props.clickable !== false) {
                        // TODO
                        if (this.props.onSetMarker !== undefined) {
                            this.props.onSetMarker(geocode);
                        }
                    }
                });
            }
        }
        if (
            !ArrayUtils.equals(this.props.markers, prevProps.markers) ||
            !ArrayUtils.equals(this.props.paths, prevProps.paths) ||
            !ArrayUtils.equals(this.props.polygons, prevProps.polygons)
        ) {
            this.renderer.clear();
            this.updateRenderer();
        }

        return;
    }

    public componentWillUnmount(): void {
        this.renderer.destroy();
    }

    public fitBounds(geocodes?: ReadonlyArray<IGeocode>): void {
        const bounds =
            geocodes === undefined
                ? this.props.startBounds === undefined
                    ? this.createBoundsByData()
                    : this.props.startBounds
                : new MapBounds(geocodes);

        if (bounds !== undefined) {
            this.renderer.fitBounds(bounds.getCorners(Map.latitudePaddingRatio, Map.longitudePaddingRatio));
        }
    }

    public handleResize(): void {
        window.dispatchEvent(new Event('resize'));
    }

    public render() {
        return (
            <div className={classNames('map', this.props.className)}>
                <div className="map__map_container" ref={(ref) => (this.container = ref)} />
            </div>
        );
    }

    private createBoundsByData(): MapBounds | undefined {
        const markerGeocodes = this.markers.map((marker) => ({
            latitude: marker.latitude,
            longitude: marker.longitude,
        }));
        const pathGeocodes = ArrayUtils.flatten(
            this.paths.map(
                (path): ReadonlyArray<IGeocode> =>
                    path.points.map((pathPoint) => ({
                        latitude: pathPoint.latitude,
                        longitude: pathPoint.longitude,
                    })),
            ),
        );

        const geocodes = [...markerGeocodes, ...pathGeocodes];

        return geocodes.length === 0 ? undefined : new MapBounds(geocodes);
    }

    private async init(): Promise<undefined> {
        if (this.container !== null) {
            this.renderer = new this.props.renderer();
            await this.renderer.init(this.container);
            this.renderer.onClick((geocode: IGeocode): void => {
                if (this.props.onSetMarker !== undefined) {
                    if (this.props.clickable !== false) {
                        // TODO
                        this.props.onSetMarker(geocode);
                    }
                }
            });
            this.renderer.clear();
            this.updateRenderer();
        }

        return;
    }

    // tslint:disable-next-line: max-func-body-length
    private updateRenderer(): void {
        const markers: IMapRendererMarker[] = [];
        this.props.markers.forEach((marker: IMapMarker): void => {
            const initialColor = this.colorScheme.renderColor(
                marker.color,
                marker.translucent,
                marker.state === MapElementState.Selected,
            ).rgbaString;

            const rendererMarker = this.renderer.addMarker(
                marker.geocode,
                marker.iconElementString,
                marker.state === MapElementState.Secondary ? Map.secondaryMarkerWidth : Map.markerWidth,
                Map.markerHeight,
                Map.markerPinX,
                Map.markerPinY,
                classNames(
                    marker.className ?? Map.markerClassName,
                    marker.state === MapElementState.Selected && this.markerClassNameSelected,
                    css({
                        borderColor: initialColor,
                    }),
                    css(marker.getStyle !== undefined && marker.getStyle(this.colorScheme)),
                ),
                undefined,
                marker.clickable,
                marker.tooltip === undefined || marker.state === MapElementState.Secondary
                    ? undefined
                    : {
                          ...marker.tooltip,
                          className: marker.tooltip.className,
                      },
            );

            const updateState = (): void => {
                Map.setMarkerSecondary(rendererMarker, marker.state === MapElementState.Secondary);
                const color = this.colorScheme.renderColor(
                    marker.color,
                    marker.translucent,
                    marker.state === MapElementState.Selected,
                ).rgbaString;
                if (marker.state === MapElementState.Selected) {
                    rendererMarker.setIcon(
                        marker.iconElementString,
                        Map.selectedMarkerWidth,
                        Map.selectedMarkerHeight,
                        Map.selectedMarkerPinX,
                        Map.selectedMarkerPinY,
                        classNames(
                            marker.className ?? Map.markerClassName,
                            this.markerClassNameSelected,
                            css({
                                borderColor: color,
                                boxShadow: `0 0 8px 2px ${color}; ${String(marker.getStyle)}`,
                            }),
                            css(marker.getStyle !== undefined && marker.getStyle(this.colorScheme)),
                        ),
                        undefined,
                    );
                } else {
                    rendererMarker.setIcon(
                        marker.iconElementString,
                        marker.state === MapElementState.Secondary ? Map.secondaryMarkerWidth : Map.markerWidth,
                        marker.state === MapElementState.Secondary ? Map.secondaryMarkerHeight : Map.markerHeight,
                        Map.markerPinX,
                        Map.markerPinY,
                        classNames(
                            marker.className ?? Map.markerClassName,
                            css({
                                borderColor: color,
                            }),
                            css(marker.getStyle !== undefined && marker.getStyle(this.colorScheme)),
                        ),
                        undefined,
                    );
                }
                rendererMarker.setForeground(marker.state === MapElementState.Selected);
            };

            updateState();

            marker.onChangeState(updateState);

            rendererMarker.onClick((): void => {
                marker.handleClick();
            });

            markers.push(rendererMarker);
        });
        this.markers = markers;

        const paths: IMapRendererPath[] = [];
        this.props.paths.forEach((path: IMapPath): void => {
            const rendererPath = this.renderer.addPath(
                path.geocodes,
                this.colorScheme.renderColor(path.color, path.translucent, path.state === MapElementState.Selected)
                    .rgbaString,
                Map.pathWidth,
            );

            const updateState = (): void => {
                Map.setPathSecondary(rendererPath, path.state === MapElementState.Secondary);
                const color = this.colorScheme.renderColor(
                    path.color,
                    path.translucent,
                    path.state === MapElementState.Selected,
                );
                rendererPath.setColor(color.rgbaString);
                if (path.state === MapElementState.Selected) {
                    rendererPath.setForeground();
                    rendererPath.setWidth(Map.selectedPathWidth);
                } else if (path.state === MapElementState.Thin) {
                    rendererPath.setWidth(Map.thinPathWidth);
                } else {
                    try {
                        rendererPath.setWidth(Map.pathWidth);
                    } catch (e) {
                        // HACK
                    }
                }
            };

            updateState();

            path.onChangeState(updateState);

            rendererPath.onClick((): void => {
                path.handleClick();
            });

            paths.push(rendererPath);
        });

        this.paths = paths;

        const polygons: IMapRendererPolygon[] = [];
        this.props.polygons.forEach((path: IMapPolygon): void => {
            const rendererPolygon = this.renderer.addPolygon(
                path.geocodes,
                path.color,
                Map.polygonWidth,
            );

            polygons.push(rendererPolygon);
        });

        this.polygons = polygons;
    }
}
