import { DeliveryPlanResponse } from '../../../../../ApiClient/Yoso/models';
import { IResponseDeserializer } from '../../../IResponseDeserializer';
import {
    deliveryPlanResponseDeserializer,
    IDeliveryPlanResponse,
} from '../../Model/DeliveryPlanResponse/DeliveryPlanResponseDeserializer';

export class BuildPlanResponseDeserializer
    implements IResponseDeserializer<DeliveryPlanResponse, IDeliveryPlanResponse> {
    public deserialize(response: DeliveryPlanResponse): IDeliveryPlanResponse {
        return deliveryPlanResponseDeserializer.deserialize(response);
    }
}

export const buildPlanResponseDeserializer = new BuildPlanResponseDeserializer();
