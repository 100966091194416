import { Geocode } from "../Geocode/Geocode";

export class TripDriverPlace {
    public id?: number
    public coords: Geocode;

    public constructor(coords: Geocode, id?: number) {
        this.id = id;
        this.coords = coords;
    }
}