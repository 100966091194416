import { TargetType } from '../../ApiClient/Yoso/models';
import { DateTime } from '../../Std/DateTime';
import { DateTimePeriod } from '../../Std/DateTimePeriod';
import { Geocode } from '../Geocode/Geocode';
import { LunchStatusActual } from './LunchStatusActual';

export class Lunch {
    public readonly id: number;
    public readonly status: LunchStatusActual | undefined;
    public readonly address: string;
    public readonly geocode: Geocode;

    public readonly desiredDateTimePeriod: DateTimePeriod;
    public readonly expectedArrivalDateTime: DateTime | undefined;
    public readonly completionDateTime: DateTime | undefined;
    public readonly tripId: number;

    public constructor(
        id: number,
        status: LunchStatusActual | undefined,
        address: string,
        geocode: Geocode,
        dateTimePeriod: DateTimePeriod,
        expectedArrivalDateTime: DateTime | undefined,
        completionDateTime: DateTime | undefined,
        tripId: number,
    ) {
        this.id = id;
        this.status = status;
        this.address = address;
        this.geocode = geocode;
        this.desiredDateTimePeriod = dateTimePeriod;
        this.expectedArrivalDateTime = expectedArrivalDateTime;
        this.completionDateTime = completionDateTime;
        this.tripId = tripId;
    }

    public getKey(): string {
        return `${this.id.toString()}_${TargetType.LUNCHPOINT.toString()}`;
    }

    public setExpectedArrivalDateTime(expectedArrivalDateTime: DateTime | undefined): Lunch {
        return new Lunch(
            this.id,
            this.status,
            this.address,
            this.geocode,
            this.desiredDateTimePeriod,
            expectedArrivalDateTime,
            this.completionDateTime,
            this.tripId,
        );
    }
}
