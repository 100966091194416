import { Modal } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { CompanyApi } from "../../../Backend/Api/Company/CompanyApi";
import { CompanyProduct } from "../../../Model/CompanyProduct/CompanyProduct";
import { Page } from "../../Page/Page";
import { ProductEntityEdit } from "../../ProductEntityEdit/ProductEntityEdit";

export interface IProductEditRouteProps extends RouteComponentProps {
    id: number;
}

export const ProductEditRoute = withRouter((props: IProductEditRouteProps) => {
    React.useEffect((): void => {
        fetchData(props.id);
    }, [props.id]);

    const [product, setProduct] = React.useState<CompanyProduct | undefined>(undefined);

    const fetchData = async (id: number): Promise<void> => {
        try {
            const response = await CompanyApi.getCompanyProduct(id);

            setProduct(response);
        } catch (error) {
            alert(String(error))
        }
    };

    const handleSaveProduct = React.useCallback(() => {
        props.history.push(`/products`);
        Modal.success({
            content: 'Изменения сохранены',
        });
    }, [props.history])

    return (
        <Page header={`Продукт ${product && product.name}`}>
            <ProductEntityEdit product={product} onSave={handleSaveProduct} />
        </Page>
    )
})