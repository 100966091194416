import React, {ChangeEvent} from 'react';
import {Button, Divider, Input, Modal, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {observer} from "mobx-react";
import {DistrictStore} from "../../Store/District/DistrictStore";
import {District} from "../../Store/District/District";
import {ArrayUtils} from "../../Std/ArrayUtils";
import DriversList from "./DriverList/DriverList";
import styles from './DistrictItemModal.module.css';

interface DistrictItemModalProps {
    district: District;
    districtStore: DistrictStore;
}

const DistrictItemModal = observer((props: DistrictItemModalProps): JSX.Element => {
    const withoutGroup = 'Без группы';
    const {district, districtStore} = props;
    const inputRef = React.useRef<Input>(null);

    const isColorHexPattern = (value: string) => {
        const reg = /^#[0-9a-f]{6}$/i;

        return reg.test(value);
    }

    const createSelectOptions = (): {label: string, value: string}[] => {
        const options = ArrayUtils.sort(districtStore.unitedDistricts
            .map(d => ({label: d.name, value: d.name})), (by) => by.label);

        return options.concat({label: withoutGroup, value: withoutGroup})
    }

    const [districtName, setDistrictName] = React.useState(district.name);
    const [districtColor, setDistrictColor] = React.useState(district.color);
    const [newCityDistrictName, setNewCityDistrictName] = React.useState('');
    const [cityDistrictNames, setCityDistrictNames] = React.useState(createSelectOptions());
    const [selectedCityDistrictName, setSelectedCityDistrictName] =
        React.useState(district.cityDistrictName || withoutGroup);
    const [drivers, setDrivers] = React.useState(props.district?.drivers);
    const [renameMode, setRenameMode] = React.useState(false);
    const [isValidColor, setIsValidColor] = React.useState(isColorHexPattern(district.color));

    const cancel = () => {
        districtStore.setCurrentlyBeingEditedDistrict(undefined);
    }

    const submit = () => {
        districtStore.editDistrictFields(
            district,
            districtName,
            districtColor,
            drivers,
            selectedCityDistrictName !== withoutGroup ? selectedCityDistrictName : undefined
        );
        districtStore.setCurrentlyBeingEditedDistrict(undefined);
    }

    const createNewCityDistrict = (e: React.MouseEvent) => {
        e.preventDefault();

        if (cityDistrictNames.some(i => i.label === newCityDistrictName)) {
            return
        }

        setCityDistrictNames(ArrayUtils.sort(
            [...cityDistrictNames, {label: newCityDistrictName, value: newCityDistrictName}],
            (by) => by.label));
        setNewCityDistrictName('');
    };

    const changeDistrictColor = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setDistrictColor(value);
        setIsValidColor(isColorHexPattern(value));
    }

    return (
        <Modal
            visible={!!districtStore.currentlyBeingEditedDistrict}
            width={350}
            closable={false}
            footer={[
                <Button key="cancel" onClick={cancel}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    disabled={renameMode || districtName.trim() === '' || !isValidColor}
                    onClick={submit}
                >
                    Подтвердить
                </Button>
            ]}
        >
            <div className={styles.container}>
                <h6 className={styles.title}>Название:</h6>
                {!renameMode &&
                    <div
                        className={styles.districtTitle}
                        onClick={() => setRenameMode(true)}
                    >
                        {districtName !== '' ? districtName : <span className={styles.warningText}>Без названия</span>}
                    </div>}
                {renameMode &&
                    <Input
                        autoFocus={true}
                        onBlur={() => setRenameMode(false)}
                        value={districtName}
                        onChange={(e) => setDistrictName(e.target.value)}
                    />}
            </div>
            <div className={styles.container}>
                <h6 className={styles.title}>Цвет:</h6>
                <div className={styles.color}>
                    <Input
                        type='color'
                        className={styles.colorPicker}
                        value={districtColor}
                        onChange={changeDistrictColor}
                    />
                    <div className={styles.colorCode}>
                        <Input
                            value={districtColor}
                            onChange={changeDistrictColor}
                            className={styles.colorText}
                            maxLength={7}
                        />
                        {!isValidColor && <span className={styles.colorWarning}>Формат цвета: #d65ff</span>}
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <h6 className={styles.title}>Водитель:</h6>
                <DriversList
                    allDrivers={Array.from(districtStore.driverMap.values())}
                    districtDrivers={drivers}
                    setDistrictDrivers={setDrivers}
                />
            </div>
            <div className={styles.container}>
                <h6 className={styles.title}>Группа</h6>
                <Select
                    className={styles.select}
                    defaultValue={selectedCityDistrictName}
                    onChange={setSelectedCityDistrictName}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider className={styles.divider}/>
                            <Space className={styles.space}>
                                <Input
                                    placeholder="Имя новой группы"
                                    ref={inputRef}
                                    value={newCityDistrictName}
                                    onChange={(e) => setNewCityDistrictName(e.target.value)}
                                />
                                <Button
                                    type="text"
                                    icon={<PlusOutlined/>}
                                    onClick={createNewCityDistrict}
                                >
                                    Создать
                                </Button>
                            </Space>
                        </>
                    )}
                    options={cityDistrictNames}
                />
            </div>
        </Modal>
    );
});

export default DistrictItemModal;