import React from 'react';
import {Geocode} from '../../Model/Geocode/Geocode';
import { IGeocode } from '../../Model/Geocode/IGeocode';
import {MapContext} from '../MapContainer/MapContextProvider';
import { Polygon } from '../MapContainer/model/Polygon';

interface RectanglesOnMapProps {
    rectangles: RectangleOnMap[];
}

export interface RectangleOnMap {
    p1: IGeocode;
    p2: IGeocode;
    color: string
}

export const RectanglesOnMap = (props: RectanglesOnMapProps): null => {
    const mapContext = React.useContext(MapContext)?.map;
    if (!mapContext) {
        throw new Error('Map context is undefined');
    }

    const layoutContext = React.useMemo(() => mapContext.getDrawContext(), []);

    React.useEffect(() => {
        layoutContext.renderPolygons(props.rectangles.map(rect => (
            new Polygon(
                [
                    rect.p1,
                    Geocode.fromLatLon(rect.p1.latitude, rect.p2.longitude),
                    rect.p2,
                    Geocode.fromLatLon(rect.p2.latitude, rect.p1.longitude),
                ],
                rect.color
            )
        )))
    }, [props.rectangles])

    return null;
};