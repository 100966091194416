import React from 'react';
import { BankOutlined, ColumnWidthOutlined, RobotOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { AppMenuProps } from '../AppMenu';

const AdminAppMenu = (props: AppMenuProps): JSX.Element => {
    const { selectedKeys } = props;

    return (
        <Menu className="app-menu" mode="inline" theme="dark" selectedKeys={selectedKeys}>
            <Menu.Item key="/interval" className="app-menu__item">
                <ColumnWidthOutlined />
                <span>Интервалы</span>
                <Link to="/interval" />
            </Menu.Item>
            <Menu.Item key="/stores" className="app-menu__item">
                <BankOutlined />
                <span>Склады</span>
                <Link to="/stores" />
            </Menu.Item>
            <Menu.Item key="/settings" className="app-menu__item">
                <SettingOutlined />
                <span>Настройки</span>
                <Link to="/settings" />
            </Menu.Item>
            <Menu.Item key="/admins" className="app-menu__item">
                <UserOutlined />
                <span>Администраторы</span>
                <Link to="/admins" />
            </Menu.Item>
            <Menu.Item key="/logisticians" className="app-menu__item">
                <UserOutlined />
                <span>Логисты</span>
                <Link to="/logisticians" />
            </Menu.Item>
            <Menu.Item key="/drivers" className="app-menu__item">
                <UserOutlined />
                <span>Водители</span>
                <Link to="/drivers" />
            </Menu.Item>
            <Menu.Item key="/bots" className="app-menu__item">
                <RobotOutlined />
                <span>Боты</span>
                <Link to="/bots" />
            </Menu.Item>
        </Menu>
    );
};

export default AdminAppMenu;
