import { IGeocode } from "../../Model/Geocode/IGeocode";
import { IMapPolygon } from "../Map/IMapPolygon";

export class PlanMapPolygon implements IMapPolygon {
    public readonly color: string;
    public readonly geocodes: ReadonlyArray<IGeocode>;

    public constructor(
        geocodes: ReadonlyArray<IGeocode>,
        color: string,
    ) {
        this.geocodes = geocodes;
        this.color = color;
    }
}