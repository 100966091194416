import {ApiGeocode} from '../../../../../ApiClient/Yoso/models';
import {Address} from '../../../../../Model/Address/Address';
import {Geocode} from '../../../../../Model/Geocode/Geocode';

export class AddressDeserializer {
    public deserialize(value: ApiGeocode): Address {
        return new Address(
            value.address ? value.address : '',
            new Geocode({latitude: value.latitude!, longitude: value.longitude!})
        )
    }
}

export const addressDeserializer = new AddressDeserializer();