import { ApiDateTimePeriod } from '../../../../../ApiClient/Yoso/models';
import { DateTimePeriod } from '../../../../../Std/DateTimePeriod';
import { IApiModelSerializer } from '../../../IApiModelSerializer';
import { dateTimeSerializer } from '../../../Mapper/DateTimeSerializer';

export class DateTimePeriodSerializer implements IApiModelSerializer<DateTimePeriod, ApiDateTimePeriod> {
    public serialize(obj: DateTimePeriod): ApiDateTimePeriod {
        return {
            start: dateTimeSerializer.serialize(obj.start),
            end: dateTimeSerializer.serialize(obj.end),
        };
    }
}

export const dateTimePeriodSerializer = new DateTimePeriodSerializer();
