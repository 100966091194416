import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import { CompanyApi } from "../../../Backend/Api/Company/CompanyApi";
import { CompanyProduct } from "../../../Model/CompanyProduct/CompanyProduct";
import { ArrayUtils } from "../../../Std/ArrayUtils";
import { EntityList, EntityListColumnAlign } from "../../EntityList/EntityList";
import { Page } from "../../Page/Page";
import './ProductListRoute.css'

export const ProductListRoute = withRouter(() => {
    const [products, setProducts] = React.useState<CompanyProduct[]>([]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await CompanyApi.getCompanyProducts();

                setProducts(ArrayUtils.sort(response, [
                    (x) => -x.isDefaultBottle,
                    (x) => -x.isMainBottle,
                    (x) => -x.isEmptyBottle,
                    (x) => -x.addable,
                    (x) => -x.isActive,
                    (x) => -x.popularity
                ], false));
            } catch (error) {
                alert(String(error));
            }
        }

        fetchData();
    }, [])

    const [productSearchString, setProductSearchString] = React.useState("");

    const handleDriverSearch = React.useCallback((value: string) => {
        setProductSearchString(value);
    }, []);

    const productSearchFilter = React.useCallback(
        (product: CompanyProduct, searchStringPart: string) => (
            product.name.toLocaleLowerCase().includes(searchStringPart.toLowerCase())
        ), []);

    return (
        <Page header="Продукты">
            <EntityList<CompanyProduct>
                className="product-list-route__table"
                data={products}
                id={(product) => product.id}
                filter={{
                    search: {
                        filter: productSearchFilter,
                        onSearch: handleDriverSearch,
                        searchString: productSearchString,
                    },
                }}
                actions={{
                    item: {
                        click: (id) => `/products/${id}`,
                    }
                }}
                columns={[
                    {
                        key: 'name',
                        title: 'Наименование',
                        render: (product) => product.name,
                    },{
                        key: 'shortName',
                        title: 'Короткое',
                        render: (product) => product.shortName || "",
                    },
                    {
                        key: 'code',
                        title: 'Код',
                        render: (product) => product.code,
                    },
                    {
                        key: 'addable',
                        title: 'Можно добавлять в заказ',
                        render: (product) => product.addable ? <CheckOutlined style={{ color: "green" }} /> : "",
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'isMainBottle',
                        title: 'Бутыль с водой 18,9 л',
                        render: (product) => product.isMainBottle ? <CheckOutlined style={{ color: "green" }} /> : "",
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'isDefaultBottle',
                        title: 'По умолчанию',
                        render: (product) => product.isDefaultBottle ? <CheckOutlined style={{ color: "green" }} /> : "",
                        align: EntityListColumnAlign.Center
                    },
                    {
                        key: 'isEmptyBottle',
                        title: 'Пустая бутыль',
                        render: (product) => product.isEmptyBottle ? <CheckOutlined style={{ color: "green" }} /> : "",
                        align: EntityListColumnAlign.Center
                    }
                ]}
                rowClass={(product, index) => product.isActive ? "" : "product-list-route__entity-list_inactive-row"}
            />
        </Page>
    )
})