import { LoginCredentials, LoginResponse } from '../../../ApiClient/YosoAuth/models';
import { Api } from '../Api';

export class AuthApi {
    public static async checkToken(): Promise<undefined> {
        await Api.doRequest<undefined>(() => Api.authApi.checkToken(Api.getRequestOptions()));

        return;
    }

    public static async login(request: LoginCredentials): Promise<LoginResponse> {
        return Api.doUnauthenticatedRequest<LoginResponse>(() => Api.authApi.login(request));
    }
}
